import { drawerAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  closeButton: {
    w: 10,
    h: 10,
    right: 'unset',
    top: '40px',
    left: '-40px',
    fontSize: 'xs',
    color: 'white',
    bgColor: 'action',
    _hover: { backgroundColor: 'actionDark' },
    borderRightRadius: 0,
  },
});

const doubleWide = defineStyle({
  maxW: '1048px',
  w: 'full',
});

const sizes = {
  doubleWide: definePartsStyle({ dialog: doubleWide }),
};

export const drawerTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
});
