import { HStack, StackProps } from '@chakra-ui/react';
import React from 'react';

export default function LogItemLabel({ children, ...rest }: StackProps) {
  return (
    <HStack fontWeight="bold" {...rest}>
      {children}
    </HStack>
  );
}
