import axios from 'axios';
import consola from 'consola';
import { Config } from './config';
import DefaultConfig from './default';

class ConfigManager {
  private config: Config = DefaultConfig;

  private isFinishedLoading: boolean = false;

  public load() {
    return axios
      .get<Partial<Config>>('/config.json')
      .then((response) => {
        this.config = { ...DefaultConfig, ...response.data };
      })
      .finally(() => {
        this.isFinishedLoading = true;
        return true;
      });
  }

  public set(newConfig: Partial<Config>) {
    this.config = { ...this.config, ...newConfig };
    this.isFinishedLoading = true;
  }

  public get(): Config {
    if (!this.isFinishedLoading) {
      consola.warn('Config accessed before loading was finished.');
    }

    return this.config;
  }
}

export default new ConfigManager();
