import { Box, Button, Flex, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import AuthenticationService from '../../../../lib/services/AuthenticationService';
import Icon from '../../../core/Icon/Icon';
import { IconImage } from '../../../core/Icon/IconConfig';
import SectionContainer from '../../../core/SectionContainer/SectionContainer';
import EnableTwoFactorModal from './EnableTwoFactorModal/EnableTwoFactorModal';
import VerifyCodeModal from './VerifyCodeModal/VerifyCodeModal';

interface TwoFactorDisabledProps {
  onEnabledClicked: () => void;
}

function TwoFactorDisabled({ onEnabledClicked }: TwoFactorDisabledProps) {
  return (
    <>
      <HStack mb="6">
        <Icon
          color="white"
          backgroundColor="gray.600"
          borderRadius="50%"
          borderColor="gray.600"
          borderWidth="thick"
          boxSize="26px"
          iconImage={IconImage.cancel}
        />
        <Text color="gray.600" fontWeight="semibold">
          Not currently enabled
        </Text>
      </HStack>
      <Button onClick={onEnabledClicked}>Enable Now</Button>
    </>
  );
}

interface TwoFactorEnabledProps {
  onManageClicked: () => void;
  onDisabledClicked: () => void;
}

function TwoFactorEnabled({ onManageClicked, onDisabledClicked }: TwoFactorEnabledProps) {
  return (
    <>
      <HStack mb="6">
        <Icon
          color="white"
          backgroundColor="success"
          borderRadius="50%"
          borderColor="success"
          borderWidth="thick"
          boxSize="26px"
          iconImage={IconImage.checkmark}
        />
        <Text fontWeight="semibold">Enabled</Text>
      </HStack>
      <Flex>
        <Button variant="outline" mr="6" onClick={onManageClicked}>
          Manage
        </Button>
        <Button variant="outline" onClick={onDisabledClicked}>
          Disable
        </Button>
      </Flex>
    </>
  );
}

interface TwoFactorAuthProps {
  mfaEnabled: boolean;
  onSuccess: (result: 'enabled' | 'disabled') => void;
}

export default function TwoFactorAuth({ mfaEnabled, onSuccess }: TwoFactorAuthProps) {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { onOpen: disableOnOpen, isOpen: disableIsOpen, onClose: disableOnClose } = useDisclosure();
  const [secret, setSecret] = useState<string>('');
  const [enabled, setEnabled] = useState<boolean>(mfaEnabled);
  const enableMFA = async () => {
    await AuthenticationService.associateSoftwareToken().then(setSecret);
    onOpen();
  };

  const disableMFA = () => {
    disableOnOpen();
  };

  const onResult = async (result: 'enabled' | 'disabled') => {
    await AuthenticationService.isSoftwareTokenMfaEnabled().then(setEnabled);
    onSuccess(result);
  };

  return (
    <Box w="320px" data-testid="TwoFactorAuthentication">
      <SectionContainer title="Two-factor authentication" mb="10">
        <Text mb="4">
          We recommend enabling two-factor authentication for added account security.
        </Text>
        {enabled ? (
          <TwoFactorEnabled onManageClicked={enableMFA} onDisabledClicked={disableMFA} />
        ) : (
          <TwoFactorDisabled onEnabledClicked={enableMFA} />
        )}
        <VerifyCodeModal
          enableCode={false}
          onSuccess={onResult}
          isOpen={disableIsOpen}
          onClose={disableOnClose}
        />
        <EnableTwoFactorModal
          secret={secret}
          onSuccess={onResult}
          isOpen={isOpen}
          onClose={onClose}
        />
      </SectionContainer>
    </Box>
  );
}
