import { Button, HStack, Stack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import { ISODateString } from '../../../lib/model/common';
import { formatNumber } from '../../../lib/utils/number-utils';
import DataRefreshFluctuationChip from '../components/DataRefreshFluctuationChip/DataRefreshFluctuationChip';

interface TotalDataRecordsProps {
  sourceId: string;
  totalRecords: number;
  lastIngestedAt?: ISODateString;
}
export default function TotalDataRecords({
  sourceId,
  totalRecords,
  lastIngestedAt,
}: TotalDataRecordsProps) {
  return (
    <SectionContainer variant="box">
      <Stack>
        <HStack>
          <Text fontSize="md" fontWeight="bold" as="span">
            {formatNumber(totalRecords)}
          </Text>
          {lastIngestedAt && <DataRefreshFluctuationChip value={lastIngestedAt} />}
        </HStack>
        <Text as="span">total data records</Text>
        <Button
          fontWeight="semibold"
          textColor="action"
          as={Link}
          to={`/sources/${sourceId}/data-explorer`}
          variant="link"
          alignSelf="start"
        >
          Explore all data records
        </Button>
      </Stack>
    </SectionContainer>
  );
}
