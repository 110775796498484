import {
  Alert as ChakraAlert,
  AlertDescription,
  AlertProps as ChakraAlertProps,
  AlertTitle,
  CloseButton,
  Flex,
  HStack,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import Icon from '../Icon/Icon';
import { IconImage } from '../Icon/IconConfig';

const CONFIG = {
  success: {
    solid: {
      bg: 'success',
      icon: <Icon boxSize={5} color="white" iconImage={IconImage.success} />,
      color: 'white',
      borderColor: 'success',
    },
    subtle: {
      bg: 'white',
      icon: <Icon boxSize={5} color="success" iconImage={IconImage.success} />,
      color: 'inherit',
      borderColor: 'success',
    },
  },
  error: {
    solid: {
      bg: 'error',
      icon: <Icon boxSize={5} color="white" iconImage={IconImage.error} />,
      color: 'white',
      borderColor: 'error',
    },
    subtle: {
      bg: 'white',
      icon: <Icon boxSize={5} color="error" iconImage={IconImage.error} />,
      color: 'inherit',
      borderColor: 'error',
    },
  },
  warning: {
    solid: {
      bg: 'warning',
      icon: <Icon boxSize={5} color="white" iconImage={IconImage.warning} />,
      color: 'white',
      borderColor: 'warning',
    },
    subtle: {
      bg: 'white',
      icon: <Icon boxSize={5} color="warning" iconImage={IconImage.warning} />,
      color: 'inherit',
      borderColor: 'warning',
    },
  },
  info: {
    solid: {
      bg: 'actionDark',
      icon: <Icon boxSize={5} color="white" iconImage={IconImage.info} />,
      color: 'white',
      borderColor: 'actionDark',
    },
    subtle: {
      bg: 'white',
      icon: <Icon boxSize={5} color="actionDark" iconImage={IconImage.info} />,
      color: 'inherit',
      borderColor: 'actionDark',
    },
  },
  loading: {
    solid: {
      bg: 'actionDark',
      icon: <Spinner boxSize={5} color="action" emptyColor="gray.100" />,
      color: 'white',
      borderColor: 'actionDark',
    },
    subtle: {
      bg: 'white',
      icon: <Spinner boxSize={5} color="action" />,
      color: 'inherit',
      borderColor: 'actionDark',
    },
  },
};

export type AlertStatus = 'success' | 'warning' | 'error' | 'info' | 'loading';

interface Props {
  status: AlertStatus;
  title?: string;
  description?: string;
  variant?: 'solid' | 'subtle';
  closeable?: boolean;
  onClose?: () => void;
}

export type AlertProps = Props & Omit<ChakraAlertProps, 'variant'>;

function Alert({
  status,
  title,
  description,
  closeable,
  variant = 'solid',
  onClose,
  ...rest
}: AlertProps) {
  const { isOpen, onClose: onCloseDisclosure } = useDisclosure({ defaultIsOpen: true });
  const alertStatusConfig = status ? CONFIG[status] : CONFIG.success;
  let alertConfig = alertStatusConfig.solid;
  if (variant) {
    alertConfig = alertStatusConfig[variant];
  }

  if (!isOpen) {
    return <div />;
  }

  return (
    <ChakraAlert
      data-testid="Alert"
      borderRadius="6px"
      borderStyle="solid"
      borderWidth="2px"
      borderColor={alertConfig.borderColor}
      p={2}
      bg={alertConfig.bg}
      color={alertConfig.color}
      status={status}
      {...rest}
    >
      <Flex alignItems="center" w="full" justifyContent="space-between">
        <HStack spacing={2}>
          {alertConfig.icon}
          <Flex direction="column" alignItems="start" lineHeight={6}>
            {title && <AlertTitle fontWeight="bold">{title}</AlertTitle>}
            {description && <AlertDescription lineHeight={5}>{description}</AlertDescription>}
          </Flex>
        </HStack>
        {closeable && (
          <CloseButton
            p="0"
            w="6"
            sx={{ '--close-button-size': '0' }}
            justifySelf="end"
            onClick={() => {
              if (onClose) {
                onClose();
              }
              onCloseDisclosure();
            }}
          />
        )}
      </Flex>
    </ChakraAlert>
  );
}
export default Alert;
