import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Select,
  Stack,
  Switch,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import TimeSeriesFilter, {
  filterConfig,
  SelectedTimeSeriesFilter,
} from '../../../../components/shared/visualizations/TimeSeriesFilter/TimeSeriesFilter';
import DataEventSummarySection from './DataEventSummarySection';
import DataIngestionChart from './DataIngestionChart';

export default function DataIngestionChartDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filterState, setFilterState] = useState<{
    selectedMetric: string;
    selectedTimeFilter: SelectedTimeSeriesFilter;
    showSources: boolean;
  }>({
    selectedMetric: 'sum:recordEvents',
    selectedTimeFilter: {
      interval: 'day',
      config: filterConfig.day[0],
    },
    showSources: false,
  });

  return (
    <>
      <Button alignSelf="flex-start" variant="outline" onClick={onOpen}>
        Explore all activity
      </Button>
      <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="doubleWide">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Ingestion activity across all data sources</DrawerHeader>
          <DrawerBody>
            <Stack spacing="6">
              <HStack spacing="4">
                <Select
                  onChange={(e) => {
                    setFilterState((previous: any) => ({
                      ...previous,
                      selectedMetric: e.target.value,
                    }));
                  }}
                  w="auto"
                >
                  <option value="sum:recordEvents">Show all activity</option>
                  <option value="sum:recordEventsIngested">Show adds & updates</option>
                  <option value="sum:recordsDeleted">Show deletes</option>
                </Select>
                <TimeSeriesFilter
                  hideHourFilter
                  onFilterChanged={(e) =>
                    setFilterState((previous: any) => ({
                      ...previous,
                      selectedTimeFilter: {
                        ...e,
                      },
                    }))
                  }
                />
                <HStack>
                  <Switch
                    onChange={(e) =>
                      setFilterState((prev) => ({ ...prev, showSources: e.target.checked }))
                    }
                  />
                  <Text as="span">Show breakdown by data source</Text>
                </HStack>
              </HStack>
              <DataIngestionChart
                selectedTimeFilter={filterState.selectedTimeFilter}
                selectedMetric={filterState.selectedMetric as any}
                showSources={filterState.showSources}
              />
              <DataEventSummarySection />
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
