import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability';
import { UserGroup } from '../api-client/authentication/model/UserGroup';

// 'manage' can do everything CRUD
type Actions = 'create' | 'read' | 'update' | 'delete' | 'manage';
type Subjects =
  | 'AdminConfig'
  | 'DataRepair'
  | 'DataSource'
  | 'SourceDataRecord'
  | 'ConsumerProfile'
  | 'SeviinAdmin';

export type AppAbilityType = Ability<[Actions, Subjects]>;
const AppAbility = Ability as AbilityClass<AppAbilityType>;

function defineRulesFor(groups: UserGroup[] = []) {
  const { can, rules } = new AbilityBuilder(AppAbility);

  if (groups.includes('manager-app/admin')) {
    can('manage', 'AdminConfig');
    can('manage', 'DataRepair');
    can('manage', 'DataSource');
    can('update', 'SourceDataRecord');
    can('update', 'ConsumerProfile');
  }

  if (groups.includes('ai.seviin/admin')) {
    can('manage', 'SeviinAdmin');
  }

  return rules;
}

const AuthorizationService = {
  getRoleLabelFromUserGroups(groups: UserGroup[] = []): string {
    if (groups.includes('manager-app/admin')) {
      return 'Team Admin';
    }

    return 'Team Member';
  },

  buildDefaultAbility() {
    return AuthorizationService.buildAbilityForGroups(AuthorizationService.getDefaultGroups());
  },

  getDefaultGroups(): UserGroup[] {
    return ['manager-app/member'];
  },

  buildAbilityForGroups(groups: UserGroup[]) {
    return new AppAbility(defineRulesFor(groups), {
      // eslint-disable-next-line no-underscore-dangle
      detectSubjectType: (object: any) => object!.__type,
    });
  },
};
export default AuthorizationService;
