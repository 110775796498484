import { Badge, Box, chakra, HStack, Stack, Tooltip } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Link, To } from 'react-router-dom';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';

interface DashboardStatProps {
  label: string;
  value: string;
  iconImage: IconImage;
  badge?: ReactNode;
  to?: string;
  toTransactionReportSummary?: To;
  totalTimeSpendValue?: string;
  profilesTooltipLabel?: string;
  transactionTooltipLabel?: string;
  badgeValue?: number;
}

export default function DashboardStat({
  label,
  value,
  iconImage,
  badge,
  to = '/',
  toTransactionReportSummary = '/',
  totalTimeSpendValue,
  profilesTooltipLabel,
  transactionTooltipLabel,
  badgeValue,
}: DashboardStatProps) {
  return (
    <SectionContainer variant="box">
      <Link to={to}>
        <HStack alignItems="start" justifyContent="space-between" spacing={4}>
          <HStack>
            <Stack direction="row">
              <Icon boxSize="44px" iconImage={iconImage} />
              {badge && <Box alignSelf="end">{badge}</Box>}
            </Stack>
            <Tooltip label={profilesTooltipLabel} placement="top">
              <Stack
                spacing={0}
                _hover={{
                  textColor: 'action',
                }}
              >
                <chakra.span fontSize="2xl" fontWeight="bold" lineHeight={8}>
                  {value}
                </chakra.span>
                <chakra.span fontSize="sm" lineHeight={5}>
                  {label}
                </chakra.span>
              </Stack>
            </Tooltip>
          </HStack>
        </HStack>
      </Link>
      <Tooltip label={transactionTooltipLabel} placement="auto" w="220px">
        <Link to={toTransactionReportSummary}>
          <Stack
            spacing={0}
            ml={badge ? '58px' : '50px'}
            _hover={{
              textColor: 'action',
            }}
          >
            <HStack>
              <chakra.span fontSize="2xl" fontWeight="bold" lineHeight={8}>
                {totalTimeSpendValue}
              </chakra.span>
              {badgeValue && (
                <Badge w="fit-content" h="6" pl="2" pr="2" pt="0.5" fontSize="12" rounded="xl">
                  {badgeValue}%
                </Badge>
              )}
            </HStack>
            <chakra.span fontSize="sm" lineHeight={5}>
              total all-time spend
            </chakra.span>
          </Stack>
        </Link>
      </Tooltip>
    </SectionContainer>
  );
}
