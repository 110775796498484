import { CellContext } from '@tanstack/react-table';
import { getIdentityTagValueLabel } from '../../../../lib/utils/scoring-utils';

export default function ProfileScoreCell({ cellContext }: { cellContext: CellContext<any, any> }) {
  const tag = cellContext.getValue();
  let value;
  if (tag) {
    value = `${getIdentityTagValueLabel(tag.value, tag.key)} (${(tag.score * 100).toFixed(2)})`;
  }
  return <div>{value}</div>;
}
