import {
  Box,
  Button,
  chakra,
  Code,
  Collapse,
  Grid,
  GridItem,
  Highlight,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { parseISO } from 'date-fns';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Icon from '../../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../../components/core/Icon/IconConfig';
import {
  ExportAuditEvent,
  getExportAuditEventTypeLabel,
} from '../../../../../lib/api-client/sources/sources.model';
import LogItem from '../../../components/LogItem/LogItem';
import LogItemLabel from '../../../components/LogItem/LogItemLabel';
import LogItemLink from '../../../components/LogItem/LogItemLink';

interface PublishAuditLogItemProps {
  event: ExportAuditEvent;
  externalSourceLink?: string;
  searchText?: string;
}

export default function PublishAuditLogItem({
  event,
  externalSourceLink,
  searchText,
}: PublishAuditLogItemProps) {
  const { isOpen, onToggle } = useDisclosure();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <LogItem fontSize="sm" key={event.eventId}>
      <Grid templateColumns="1fr max-content">
        <Stack spacing={1}>
          <LogItemLabel>
            {!event.success && (
              <Box display="inline-flex">
                <Icon iconImage={IconImage.error} boxSize={5} color="error" />
                <chakra.span color="error" fontWeight="bold" ml={1}>
                  Error
                </chakra.span>
              </Box>
            )}
            <chakra.span>{getExportAuditEventTypeLabel(event.eventType)}</chakra.span>
          </LogItemLabel>
          <HStack spacing={10}>
            <chakra.span>
              {formatInTimeZone(parseISO(event.timestamp), timezone, 'MMM dd, yyyy h:mm a zzz')}
            </chakra.span>
            <LogItemLink as={Link} to={`/profiles/${event.pin}`} target="_blank">
              PIN:{' '}
              <Highlight query={searchText ?? ''} styles={{ bg: 'highlightHover' }}>
                {event.pin}
              </Highlight>
            </LogItemLink>
            {externalSourceLink ? (
              <LogItemLink href={externalSourceLink} target="_blank">
                Source record ID:{' '}
                <Highlight query={searchText ?? ''} styles={{ bg: 'highlightHover' }}>
                  {event.sourceRecordId}
                </Highlight>
              </LogItemLink>
            ) : (
              <chakra.span>
                Source record ID:{' '}
                <Highlight query={searchText ?? ''} styles={{ bg: 'highlightHover' }}>
                  {event.sourceRecordId}
                </Highlight>
              </chakra.span>
            )}
          </HStack>
        </Stack>
        <GridItem alignSelf="center">
          <Button
            minW="105px"
            variant="ghost"
            rightIcon={
              <motion.div initial={{ rotate: 0 }} animate={{ rotate: isOpen ? -180 : 0 }}>
                <Icon boxSize={4} iconImage={IconImage.accordionDown} />
              </motion.div>
            }
            onClick={onToggle}
          >
            {isOpen ? 'Hide details' : 'See details'}
          </Button>
        </GridItem>
      </Grid>
      <Collapse in={isOpen} animateOpacity>
        {!event.success && (
          <Text mt={3} fontSize="sm" color="error">
            {event.errorMessage}
          </Text>
        )}
        <Code
          w="full"
          mt={3}
          p="3"
          borderRadius="md"
          border="1px"
          borderColor="gray.200"
          fontSize="sm"
          maxH="300px"
          overflowY="auto"
        >
          <pre>{JSON.stringify(JSON.parse(event.payload), undefined, 2) ?? event.payload}</pre>
        </Code>
      </Collapse>
    </LogItem>
  );
}
