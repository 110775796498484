import { Button, Flex, HStack, Stack, Text } from '@chakra-ui/react';
import {
  createColumnHelper,
  PaginationState,
  SortingState,
  VisibilityState,
} from '@tanstack/react-table';
import React, { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';
import DataExplorer from '../../../../components/shared/data-explorer/DataExplorer/DataExplorer';
import DataExplorerSkeleton from '../../../../components/shared/data-explorer/DataExplorer/DataExplorerSkeleton';
import { useAuth } from '../../../../context/AuthenticationContext';
import { useSearchIdentities } from '../../../../lib/api-client/identity/IdentityData';
import {
  IdentitySearchRequest,
  IdentitySearchResult,
} from '../../../../lib/api-client/identity/identity.model';
import { IdentityTag } from '../../../../lib/api-client/identity/model/Identity';
import { SearchOperator } from '../../../../lib/api-client/identity/model/SourceIdentityRecordSearch';
import { useDataSources } from '../../../../lib/api-client/sources/SourceData';
import { DataSource } from '../../../../lib/api-client/sources/model/DataSource';
import { Nullable } from '../../../../lib/model/common';
import { Page } from '../../../../lib/model/common/Page';
import LocalStorageService from '../../../../lib/services/LocalStorageService';
import { getIdentityTagValueLabel } from '../../../../lib/utils/scoring-utils';
import ExportCsvButton from '../ExportCsvButton/ExportCsvButton';
import ProfileLinkCell from '../ProfileLinkCell/ProfileLinkCell';
import ProfileScoreCell from '../ProfileScoreCell/ProfileScoreCell';
import ReportExplorer from '../ReportExplorer/ReportExplorer';
import { mapIdentitySearchToReportQuery } from './ConsumerProfileExplorer.utils';
import ConsumerProfileExplorerFilterDrawer, {
  ConsumerProfileFilterForm,
} from './ConsumerProfileExplorerFilterDrawer';

const defaultColumnVisibility: VisibilityState = {
  tagScore: true,
  'seVIIn.ai PIN': true,
  Name: true,
  'Email address': true,
  'Phone number': true,
  Address: true,
  'Date of birth': true,
};

const columnHelper = createColumnHelper<any>();
const columns = [
  columnHelper.accessor((data) => data.tags?.find((t: IdentityTag) => t.key === 'authenticity'), {
    header: 'Profile certification',
    minSize: 20,
    enableSorting: true,
    id: 'tagScore',
    cell: (cellContext) => <ProfileScoreCell cellContext={cellContext} />,
  }),
  columnHelper.accessor((data) => data.pin, {
    header: 'PIN',
    minSize: 180,
    enableSorting: false,
    cell: (cellContext) => <ProfileLinkCell cellContext={cellContext} />,
  }),
  columnHelper.accessor(
    (data) => data.identityAttributes?.name?.primaryValue?.components?.[0]?.value,
    {
      header: 'First name',
      minSize: 180,
      enableSorting: false,
    }
  ),
  columnHelper.accessor(
    (data) => data.identityAttributes?.name?.primaryValue?.components?.[1]?.value,
    {
      header: 'Middle name',
      minSize: 180,
      enableSorting: false,
    }
  ),
  columnHelper.accessor(
    (data) => data.identityAttributes?.name?.primaryValue?.components?.[2]?.value,
    {
      header: 'Last name',
      minSize: 180,
      enableSorting: false,
    }
  ),
  columnHelper.accessor(
    (data) => data.identityAttributes?.name?.primaryValue?.components?.[4]?.value,
    {
      header: 'Name suffix',
      minSize: 180,
      enableSorting: false,
    }
  ),
  columnHelper.accessor((data) => data.identityAttributes?.emailAddress?.primaryValue?.value, {
    header: 'Email address',
    minSize: 240,
    enableSorting: false,
  }),
  columnHelper.accessor((data) => data.identityAttributes?.phone?.primaryValue?.value, {
    header: 'Phone number',
    minSize: 20,
    enableSorting: false,
  }),
  columnHelper.accessor((data) => data.identityAttributes?.mobilePhone?.primaryValue?.value, {
    header: 'Mobile phone',
    minSize: 20,
    enableSorting: false,
  }),
  columnHelper.accessor((data) => data.identityAttributes?.homePhone?.primaryValue?.value, {
    header: 'Home phone',
    minSize: 20,
    enableSorting: false,
  }),
  columnHelper.accessor((data) => data.identityAttributes?.workPhone?.primaryValue?.value, {
    header: 'Work phone',
    minSize: 20,
    enableSorting: false,
  }),
  columnHelper.accessor(
    (data) =>
      data.identityAttributes?.address?.primaryValue?.components?.find(
        (v: any) => v.type === 'streetAddress'
      )?.value,
    {
      header: 'Street address',
      minSize: 20,
      enableSorting: false,
    }
  ),
  columnHelper.accessor(
    (data) =>
      data.identityAttributes?.address?.primaryValue?.components?.find(
        (v: any) => v.type === 'city'
      )?.value,
    {
      header: 'City',
      minSize: 20,
      enableSorting: false,
    }
  ),
  columnHelper.accessor(
    (data) =>
      data.identityAttributes?.address?.primaryValue?.components?.find(
        (v: any) => v.type === 'localMunicipality'
      )?.value,
    {
      header: 'Local municipality',
      minSize: 20,
      enableSorting: false,
    }
  ),
  columnHelper.accessor(
    (data) =>
      data.identityAttributes?.address?.primaryValue?.components?.find(
        (v: any) => v.type === 'governingDistrict'
      )?.value,
    {
      header: 'Governing district',
      minSize: 20,
      enableSorting: false,
    }
  ),
  columnHelper.accessor(
    (data) =>
      data.identityAttributes?.address?.primaryValue?.components?.find(
        (v: any) => v.type === 'postalCode'
      )?.value,
    {
      header: 'Postal code',
      minSize: 20,
      enableSorting: false,
    }
  ),
  columnHelper.accessor(
    (data) =>
      data.identityAttributes?.address?.primaryValue?.components?.find(
        (v: any) => v.type === 'countryCode'
      )?.value,
    {
      header: 'Country code',
      minSize: 20,
      enableSorting: false,
    }
  ),
  columnHelper.accessor((data) => data.identityAttributes?.dateOfBirth?.primaryValue?.value, {
    header: 'Date of birth',
    minSize: 20,
    enableSorting: false,
  }),
  columnHelper.accessor((data) => data.identityAttributes?.gender?.primaryValue?.value, {
    header: 'Gender',
    minSize: 20,
    enableSorting: false,
  }),
];

function storeColumnVisibility(visibility: VisibilityState, tenantId = 'unknown') {
  const { select, ...rest } = visibility;
  LocalStorageService.setItem(`seviin.${tenantId}.cora.columns`, JSON.stringify(rest));
}

function getColumnVisibilityFromStorage(tenantId = 'unknown'): VisibilityState {
  const storedValue = LocalStorageService.getItem(`seviin.${tenantId}.cora.columns`);

  try {
    let parsedJson;
    if (storedValue != null) {
      parsedJson = JSON.parse(storedValue);
    }

    return parsedJson ?? defaultColumnVisibility;
  } catch (err) {
    return defaultColumnVisibility;
  }
}

type FetchParams = {
  search: IdentitySearchRequest;
  pagination: PaginationState;
  sorting: SortingState;
};

function mapFormToUrlParams(form: ConsumerProfileFilterForm) {
  const params: [string, any][] = [];
  if (form.sourceId) {
    params.push(['sourceId', form.sourceId]);
  }

  if (form.pinType) {
    params.push(['pinType', form.pinType]);
  }

  if (form.authenticityValue) {
    params.push(['authenticityValue', form.authenticityValue]);
    if (form.authenticityValue === 'specific') {
      params.push(['authenticityScore', form.authenticityScore]);
      params.push(['authenticityScoreValue1', form.authenticityScoreValue1]);
      if (form.authenticityScoreValue2 != null && !Number.isNaN(form.authenticityScoreValue2))
        params.push(['authenticityScoreValue2', form.authenticityScoreValue2]);
    }
  }

  if (form.identityRecordCount) {
    params.push(['identityRecordCount', form.identityRecordCount]);
    if (form.identityRecordCount === 'specific') {
      params.push(['identityRecordCountOperator', form.identityRecordCountOperator]);
      params.push(['identityRecordCountValue1', form.identityRecordCountValue1]);

      if (form.identityRecordCountValue2 != null && !Number.isNaN(form.identityRecordCountValue2)) {
        params.push(['identityRecordCountValue2', form.identityRecordCountValue2]);
      }
    }
  }

  return Object.values(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
}

const getScoreLabel = (
  authenticityScore?: Nullable<string>,
  value1?: Nullable<number>,
  value2?: Nullable<number>
) => {
  switch (authenticityScore) {
    case 'greaterThanOrEqual':
      return `Greater than or equal ${value1}`;
    case 'greaterThan':
      return `Greater than ${value1}`;
    case 'lessThanOrEqual':
      return `Less than or equal ${value1}`;
    case 'lessThan':
      return `Less than ${value1}`;
    case 'equal':
      return `Equals ${value1}`;
    case 'between':
      return `Between ${value1} & ${value2}`;
    default:
      return '';
  }
};

function mapFormToDescriptions(form: ConsumerProfileFilterForm, sources: DataSource[]) {
  const descriptions: string[] = [];

  let pinType = 'All';
  if (form.pinType) {
    switch (form.pinType) {
      case 'household':
        pinType = 'Household';
        break;
      case 'business':
        pinType = 'Business';
        break;
      case 'person':
        pinType = 'Individual consumer';
        break;
      default:
        pinType = 'Unknown';
    }
  }
  descriptions.push(`Profile type: ${pinType}`);

  let authenticityValue = 'All';
  if (form.authenticityValue) {
    switch (form.authenticityValue) {
      case 'authentic':
      case 'suspicious':
      case 'unauthentic':
        authenticityValue =
          getIdentityTagValueLabel(form.authenticityValue, 'authenticity') ??
          form.authenticityValue;
        break;
      case 'specific':
        authenticityValue = getScoreLabel(
          form.authenticityScore,
          form.authenticityScoreValue1,
          form.authenticityScoreValue2
        );
        break;
      default:
    }
  }
  descriptions.push(`Profile score: ${authenticityValue}`);

  let source = 'All sources';
  if (form.sourceId) {
    source = `Source system contribution: ${
      sources.find((s) => s.id === form.sourceId)?.name ?? form.sourceId
    }`;
  }
  descriptions.push(source);

  if (form.identityRecordCount) {
    let recordCount = 'All';

    if (form.identityRecordCount) {
      recordCount = getScoreLabel(
        form.identityRecordCountOperator,
        form.identityRecordCountValue1,
        form.identityRecordCountValue2
      );
    }
    descriptions.push(`Records in cluster: ${recordCount}`);
  }

  return descriptions;
}

function getFullUrl(form: ConsumerProfileFilterForm) {
  const { protocol, host, pathname } = window.location;
  return `${protocol}//${host}${pathname}?${mapFormToUrlParams(form)}`;
}

function mapFormToSearch(form: ConsumerProfileFilterForm): IdentitySearchRequest {
  let identityRecordCount: any;
  if (form.identityRecordCount) {
    identityRecordCount = {
      operator: form.identityRecordCountOperator,
      values: [form.identityRecordCountValue1, form.identityRecordCountValue2].filter(
        (v) => v != null && !Number.isNaN(v)
      ),
    };
  }

  let authenticityParams;
  if (form.authenticityValue) {
    if (form.authenticityValue === 'specific') {
      authenticityParams = {
        scoreOperator: form.authenticityScore as SearchOperator,
        scoreValues: [form.authenticityScoreValue1, form.authenticityScoreValue2]
          .filter((v) => v != null && !Number.isNaN(v))
          .map((v) => v!! / 100) as any,
      };
    } else {
      authenticityParams = {
        values: [form.authenticityValue],
      };
    }
  }

  return {
    pinType: form.pinType || undefined,
    sourceId: form.sourceId,
    tags: authenticityParams
      ? [
          {
            key: 'authenticity',
            ...(authenticityParams as any),
          },
        ]
      : [],
    identityRecordCount,
  };
}

export default function ConsumerProfileExplorer() {
  const { tenantId } = useAuth();
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    ...getColumnVisibilityFromStorage(tenantId),
  });
  const [queryParams] = useSearchParams();
  const { data: sources, loading: sourcesLoading } = useDataSources();
  const [form, setForm] = useState<ConsumerProfileFilterForm>({
    pinType: queryParams.get('pinType') as any,
    sourceId: queryParams.get('sourceId'),
    authenticityValue: queryParams.get('authenticityValue'),
    authenticityScore: queryParams.get('authenticityScore') ?? 'greaterThanOrEqual',
    authenticityScoreValue1: parseInt(queryParams.get('authenticityScoreValue1') ?? '0', 10) ?? 0,
    authenticityScoreValue2:
      parseInt(queryParams.get('authenticityScoreValue2') ?? '', 10) ?? undefined,
    identityRecordCount: queryParams.get('identityRecordCount'),
    identityRecordCountOperator:
      queryParams.get('identityRecordCountOperator') ?? 'greaterThanOrEqual',
    identityRecordCountValue1:
      parseInt(queryParams.get('identityRecordCountValue1') ?? '0', 10) ?? 0,
    identityRecordCountValue2:
      parseInt(queryParams.get('identityRecordCountValue2') ?? '', 10) ?? undefined,
  });
  const [pageUrl, setPageUrl] = useState(getFullUrl(form));
  const [showReportView, setShowReportView] = useState(false);
  const search = mapFormToSearch(form);
  const reportQuery = mapIdentitySearchToReportQuery(search);

  const [fetchParams, setFetchParams] = useState<FetchParams>({
    search: mapFormToSearch(form),
    pagination: {
      pageIndex: 0,
      pageSize: 100,
    },
    sorting: [],
  });
  const results = useSearchIdentities(fetchParams.search, {
    page: fetchParams.pagination.pageIndex,
    size: fetchParams.pagination.pageSize,
    sort: fetchParams.sorting.map((p) => `${p.id},${p.desc ? 'desc' : 'asc'}`),
  });

  const onFetcherParamChange = useCallback((params: FetchParams) => {
    setFetchParams(params);
  }, []);

  if (results.loading || !results.data) {
    return <DataExplorerSkeleton />;
  }

  const totalResults = results.data.totalResults ?? 0;
  const totalPages =
    totalResults === 0 ? 0 : Math.ceil(totalResults / fetchParams.pagination.pageSize);
  const page: Page<IdentitySearchResult> = {
    content: results.data.results ?? [],
    totalPages,
    totalElements: totalResults,
    first: fetchParams.pagination.pageIndex === 0,
    last: totalPages === 0 || fetchParams.pagination.pageIndex + 1 === totalPages,
    size: fetchParams.pagination.pageSize,
    number: fetchParams.pagination.pageIndex,
    numberOfElements: results.data.results.length,
  };

  let filterDescriptions: string[] = [];
  if (sources?.content) {
    filterDescriptions = mapFormToDescriptions(form, sources?.content);
  }

  return (
    <Flex data-testid="ConsumerProfileExplorer" flexDir="column" h="full">
      {sources && !sourcesLoading && !showReportView && (
        <>
          <SectionContainer variant="box" mb={4}>
            <HStack>
              <Icon iconImage={IconImage.info} color="highlightDark" />
              <Flex w="full" justify="space-between">
                <Stack spacing={0}>
                  <Text fontWeight="bold">
                    Use this tool to explore a sample set of consumer profiles that meet the filter
                    selections below.
                  </Text>
                  <Text>
                    If you’d like to access the full set of consumer profiles, you can opt to load
                    it here or export a CSV.
                  </Text>
                </Stack>
                <HStack>
                  <Button variant="outline" onClick={() => setShowReportView(true)}>
                    Load all profiles
                  </Button>
                  <ExportCsvButton reportParams={reportQuery} />
                </HStack>
              </Flex>
            </HStack>
          </SectionContainer>
          <DataExplorer
            data={page}
            columns={columns}
            pageUrl={pageUrl}
            filterDescriptions={filterDescriptions}
            defaultPagination={fetchParams.pagination}
            defaultColumnVisibility={columnVisibility}
            onFetcherParamChange={(params) =>
              onFetcherParamChange({ ...params, search: fetchParams.search })
            }
            onColumnVisibilityChange={(visibility) => {
              setColumnVisibility(visibility);
              storeColumnVisibility(visibility, tenantId);
            }}
            icon={{
              label: 'consumer profiles',
            }}
            hideEditMode
          >
            <ConsumerProfileExplorerFilterDrawer
              sources={sources.content}
              form={form}
              onFilterChange={(filter) => {
                const url = getFullUrl(filter);
                setPageUrl(url);
                setForm(filter);
                onFetcherParamChange({
                  ...fetchParams,
                  pagination: { pageIndex: 0, pageSize: 100 },
                  search: mapFormToSearch(filter),
                });
                window.history.replaceState(undefined, '', url);
              }}
            />
          </DataExplorer>
        </>
      )}
      {showReportView && sources && (
        <ReportExplorer
          reportId="PROFILES"
          reportParams={reportQuery}
          filterLabel="Selected filters"
          filterDescriptions={filterDescriptions}
        >
          <ConsumerProfileExplorerFilterDrawer
            sources={sources.content}
            form={form}
            onFilterChange={(filter) => {
              const url = getFullUrl(filter);
              setPageUrl(url);
              setForm(filter);
              onFetcherParamChange({
                ...fetchParams,
                pagination: { pageIndex: 0, pageSize: 100 },
                search: mapFormToSearch(filter),
              });
              window.history.replaceState(undefined, '', url);
            }}
          />
        </ReportExplorer>
      )}
    </Flex>
  );
}
