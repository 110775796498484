import { defineStyleConfig } from '@chakra-ui/react';

export default defineStyleConfig({
  baseStyle: {
    label: {
      color: 'gray.600',
      fontSize: 'sm',
      lineHeight: 4,
      whiteSpace: 'nowrap',
    },
    icon: {
      size: '3',
    },
  },
  sizes: {
    md: {
      label: {
        fontSize: 'md',
        lineHeight: 5,
      },
    },
  },
});
