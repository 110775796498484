import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useBoolean,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { AuthenticationConfiguration } from '../../../../lib/api-client/authentication/model/AuthenticationConfiguration';
import AuthenticationService from '../../../../lib/services/AuthenticationService';
import FormLogoHeader from '../FormLogoHeader/FormLogoHeader';
import FormTermsFooter from '../FormTermsFooter/FormTermsFooter';

interface AccountRouterFormProps {
  onSuccess: (authConfig: AuthenticationConfiguration, emailAddress: string) => void;
  onError: () => void;
}

const accountRouterFormSchema = yup
  .object({
    email: yup.string().required('Field is required').email('Enter a valid email address'),
  })
  .required();

export default function AccountRouterForm({ onError, onSuccess }: AccountRouterFormProps) {
  const [isLoading, setLoading] = useBoolean(false);
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<{ email: string }>({
    resolver: yupResolver(accountRouterFormSchema),
    mode: 'onBlur',
  });
  const onSubmit: SubmitHandler<{ email: string }> = async (data) => {
    setLoading.on();
    AuthenticationService.getAuthenticationConfig(data.email)
      .then((config) => {
        setLoading.off();
        if (!config) {
          setError('email', { type: 'custom', message: 'Enter a valid email address' });
        } else {
          onSuccess(config, data.email);
        }
      })
      .catch(() => {
        setLoading.off();
        onError();
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box h="504px">
        <FormLogoHeader />
        <Box paddingX="170px">
          <FormControl isInvalid={!!errors.email}>
            <FormLabel fontWeight="semibold" htmlFor="email">
              Email address
            </FormLabel>
            <Input id="email" type="email" {...register('email')} />
            <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
          </FormControl>
        </Box>
        <Button margin="40px" type="submit" w="200px" h="48px" isLoading={isLoading}>
          Continue
        </Button>
        <FormTermsFooter />
      </Box>
    </form>
  );
}
