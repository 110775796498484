import {
  chakra,
  forwardRef,
  HTMLChakraProps,
  ThemingProps,
  useStyleConfig,
} from '@chakra-ui/react';
import { defaultPercentageColorStrategy } from '../../../../lib/utils/color-utils';

interface StatBadgeProps extends HTMLChakraProps<'span'>, ThemingProps {
  rawValue: number;
  formattedValue?: string;
}

const StatBadge = forwardRef<StatBadgeProps, 'span'>(
  ({ rawValue, formattedValue = '', variant, ...props }, ref) => {
    const styles = useStyleConfig('StatBadge', { variant });
    const raw = rawValue * 100;

    const additionalStyles: { bgColor?: string } = {};
    if (rawValue || rawValue === 0) {
      if (variant === 'dynamic') {
        additionalStyles.bgColor = defaultPercentageColorStrategy(rawValue); // defaultPercentToColorToken(raw, 'gray.200');
      } else if (variant === 'dynamicReverse') {
        additionalStyles.bgColor = defaultPercentageColorStrategy(rawValue, true); // defaultPercentToColorToken(raw, 'gray.200', true);
      }
    }

    return (
      <chakra.span ref={ref} __css={styles} {...additionalStyles} {...props}>
        {formattedValue || raw}
      </chakra.span>
    );
  }
);

export default StatBadge;
