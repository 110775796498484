import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthenticationContext';

interface NonAuthenticationGuardProps {
  children: ReactNode;
}

export default function NonAuthenticationGuard({
  children,
}: NonAuthenticationGuardProps): JSX.Element {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return children as JSX.Element;
}
