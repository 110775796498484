import { Box, chakra, HStack, Tooltip } from '@chakra-ui/react';
import { CellContext } from '@tanstack/react-table';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import { useDataExplorer } from '../../../../components/shared/data-explorer/DataExplorer/DataExplorerContext';
import { SourceIdentityRecordSearchResponse } from '../../../../lib/api-client/identity/model/SourceIdentityRecordSearchResponse';
import { IdentityDataKeys } from '../../../../lib/api-client/identity/model/UpdateIdentityRecord';

interface IdentityRecordCellProps {
  cellContext: CellContext<SourceIdentityRecordSearchResponse, IdentityDataKeys | undefined>;
  preferNormalizedValue?: boolean;
}
export default function IdentityRecordCell({
  cellContext,
  preferNormalizedValue,
}: IdentityRecordCellProps) {
  const value = cellContext.getValue();
  const isModified = value && value.modified != null;
  const { editMode } = useDataExplorer();

  return (
    <HStack spacing={1} justify="space-between">
      {preferNormalizedValue ? (
        <chakra.span overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {value?.normalized}
        </chakra.span>
      ) : (
        <chakra.span overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {value?.modified ? value.modified : value?.raw ?? value?.normalized}
        </chakra.span>
      )}
      {isModified && editMode && (
        <Tooltip hasArrow placement="top" label="This data field value has been edited.">
          <Box>
            <Icon color="gray.800" iconImage={IconImage.edit} />
          </Box>
        </Tooltip>
      )}
    </HStack>
  );
}
