import { useFetch, useSchema } from '@hazae41/xswr';
import { TimeSeriesIntervalName, TimeSeriesQueryMultiValueResponse } from '../../model/common';
import { getApiClientSchema } from '../ApiClient';

export function getIngestTimeSeries(
  metric: 'sum:recordEventsIngested' | 'sum:recordEvents' | 'sum:recordsDeleted',
  startDate: Date,
  endDate: Date,
  timezone: string,
  intervalName: TimeSeriesIntervalName
) {
  return getApiClientSchema<TimeSeriesQueryMultiValueResponse>({
    method: 'get',
    url: '/ingest-time-series',
    params: {
      metric,
      startDate,
      endDate,
      timezone,
      intervalName,
    },
  });
}

export function useGetIngestTimeSeries(
  metric: 'sum:recordEventsIngested' | 'sum:recordEvents' | 'sum:recordsDeleted',
  startDate: Date,
  endDate: Date,
  timezone: string,
  intervalName: TimeSeriesIntervalName
) {
  const query = useSchema(getIngestTimeSeries, [
    metric,
    startDate,
    endDate,
    timezone,
    intervalName,
  ]);
  useFetch(query);
  return query;
}
