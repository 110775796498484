import { SourceIdentityRecordSearch } from '../../../../lib/api-client/identity/model/SourceIdentityRecordSearch';
import {
  mapSearchOperatorToReportPredicateOperator,
  ReportExecutionFilter,
  StartReportExecution,
} from '../../../../lib/api-client/reports/report.model';

// eslint-disable-next-line import/prefer-default-export
export function mapIdentityRecordSearchToReportQuery(
  sourceId: string,
  search: Omit<SourceIdentityRecordSearch, 'sourceId'>
): StartReportExecution {
  const predicates: ReportExecutionFilter['predicates'] = [];
  const sorts: StartReportExecution['sortOrder'] = [];

  if (search.recordScore) {
    predicates.push({
      columnName: 'recordScore',
      operator: mapSearchOperatorToReportPredicateOperator(search.recordScore.operator),
      arguments: search.recordScore.values.map((v) => `${v}`),
    });
  }

  if (search.recordCompleteness) {
    predicates.push({
      columnName: 'completeRecord',
      operator: mapSearchOperatorToReportPredicateOperator(search.recordCompleteness.operator),
      arguments: search.recordCompleteness.values.map((v) => `${v === 1}`),
    });
  }

  if (search.pinned != null) {
    predicates.push({
      columnName: 'pin',
      operator: search.pinned ? 'IS_NOT_NULL' : 'IS_NULL',
    });
  }

  if (search.duplicates != null) {
    predicates.push({
      columnName: 'duplicateCount',
      operator: search.duplicates ? 'GREATER_THAN' : 'EQUALS',
      arguments: ['0'],
    });
    sorts.push({
      columnName: 'pin',
      descending: false,
    });
  }

  if (search.recordCategories && search.recordCategories.length > 0) {
    predicates.push({
      columnName: 'categories',
      operator: 'CONTAINS',
      arguments: search.recordCategories,
    });
  }

  if (search.modifications != null) {
    predicates.push({
      columnName: 'lastModifiedBy',
      operator: search.modifications ? 'IS_NOT_NULL' : 'IS_NULL',
    });
  }

  const additionalFilters: ReportExecutionFilter[] = [];
  if (search.attributeScores) {
    search.attributeScores.forEach((score) => {
      if (score.attribute === 'phone') {
        additionalFilters.push({
          conjunction: 'OR',
          predicates: [
            {
              columnName: 'mobilePhoneScore',
              operator: mapSearchOperatorToReportPredicateOperator(score.operator),
              arguments: score.values.map((v) => `${v}`),
            },
            {
              columnName: 'workPhoneScore',
              operator: mapSearchOperatorToReportPredicateOperator(score.operator),
              arguments: score.values.map((v) => `${v}`),
            },
            {
              columnName: 'homePhoneScore',
              operator: mapSearchOperatorToReportPredicateOperator(score.operator),
              arguments: score.values.map((v) => `${v}`),
            },
          ],
        });
      } else {
        predicates.push({
          columnName: `${score.attribute}Score`,
          operator: mapSearchOperatorToReportPredicateOperator(score.operator),
          arguments: score.values.map((v) => `${v}`),
        });
      }
    });
  }

  if (search.attributeCompleteness) {
    search.attributeCompleteness.forEach((completeness) => {
      if (completeness.attribute === 'phone') {
        const operator = completeness.values[0] === 1 ? 'IS_NOT_NULL' : 'IS_NULL';
        additionalFilters.push({
          conjunction: 'OR',
          predicates: [
            {
              columnName: 'mobilePhoneScore',
              operator,
            },
            {
              columnName: 'workPhoneScore',
              operator,
            },
            {
              columnName: 'homePhoneScore',
              operator,
            },
          ],
        });
      } else {
        predicates.push({
          columnName: completeness.attribute,
          operator: completeness.values[0] === 1 ? 'IS_NOT_NULL' : 'IS_NULL',
        });
      }
    });

    if (search.optInAttributes) {
      search.optInAttributes.forEach((item) => {
        if (item.value != null) {
          predicates.push({
            columnName: item.attribute,
            operator: 'EQUALS',
            arguments: [`${item.value}`],
          });
        }
      });
    }
  }

  return {
    reportType: 'IDENTITY_RECORDS',
    filter:
      predicates.length > 0 || additionalFilters.length > 0
        ? {
            conjunction: 'AND',
            predicates,
            filters: additionalFilters.length > 0 ? additionalFilters : undefined,
          }
        : undefined,
    options: [
      {
        optionName: 'sourceList',
        arguments: {
          sourceIds: [sourceId],
        },
      },
    ],
    sortOrder: sorts.length > 0 ? sorts : undefined,
  };
}
