import { useToast } from '@chakra-ui/react';
import * as yup from 'yup';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import {
  AttributeDomainType,
  DataModel,
} from '../../../lib/api-client/data-model/data-model.model';
import {
  filterAttributeDomainsFromDataModel,
  filterPrimaryDomainFromDataModel,
} from '../../../lib/api-client/data-model/data-model.utils';
import { DataSource } from '../../../lib/api-client/sources/model/DataSource';
import {
  DataSourceAttributeMapping,
  DataSourceNormalization,
} from '../../../lib/api-client/sources/model/DataSourceAttributeMapping';
import { DataSourceConnectionTypes } from '../../../lib/api-client/sources/model/DataSourceConnectionType';
import { UpdateDataSource } from '../../../lib/api-client/sources/model/UpdateDataSource';
import SessionStorageService from '../../../lib/services/SessionStorageService';

export type DataSourceAttributeMultiValueMapping = Omit<
  DataSourceAttributeMapping,
  'sourceAttribute' | 'normalizations'
> & {
  sourceAttributes: { value: string; normalizations?: DataSourceNormalization[] }[];
};

export type UpdateDataSourceForm = Omit<UpdateDataSource, 'attributeMappings'> & {
  attributeMappings: DataSourceAttributeMultiValueMapping[];
};

export function groupAttributes(attributeMappings: DataSource['attributeMappings']) {
  const groupedAttributes: Record<string, DataSourceAttributeMultiValueMapping> = {};

  attributeMappings.forEach((mapping) => {
    const key = `${mapping.canonicalAttribute}_${mapping.persistedValueName || ''}`;
    if (groupedAttributes[key]) {
      groupedAttributes[key]?.sourceAttributes.push({
        value: mapping.sourceAttribute,
        normalizations: mapping.normalizations,
      });
    } else {
      const { sourceAttribute, persistedValueName, ...rest } = mapping;
      groupedAttributes[key] = {
        ...rest,
        persistedValueName:
          mapping.canonicalAttribute === 'persistedValue' ? persistedValueName! : undefined,
        sourceAttributes: [{ value: sourceAttribute, normalizations: mapping.normalizations }],
      };
    }
  });

  return Object.values(groupedAttributes);
}

export function flattenAttributes(
  attributeMappings: DataSourceAttributeMultiValueMapping[]
): DataSourceAttributeMapping[] {
  return attributeMappings.flatMap(({ sourceAttributes, ...am }) =>
    sourceAttributes.map((sa) => ({
      ...am,
      sourceAttribute: sa.value,
      normalizations: sa.normalizations,
    }))
  );
}

/**
 * Ensure a source has all required attribute mappings.
 *
 * @param source
 * @param dataModel
 */
export function ensureRequiredMappingsForSource(
  source: DataSource,
  dataModel: DataModel
): DataSourceAttributeMultiValueMapping[] {
  const filteredPrimaryDataModel = filterPrimaryDomainFromDataModel(dataModel, source.domainType);

  if (['OUT', 'BOTH'].includes(source.syncDirection)) {
    const { domains } = filterAttributeDomainsFromDataModel(dataModel, 'EXPORT');
    filteredPrimaryDataModel.domains = [...filteredPrimaryDataModel.domains, ...domains];
  }

  const requiredMappings = filteredPrimaryDataModel.domains.reduce((previous, current) => {
    const updated = { ...previous };
    updated[current.id] = current.attributes
      .filter(
        (a) =>
          source.domainType === 'IDENTITY' ||
          !(current.id === 'IDENTITY' && a.id === 'sourceRecordId')
      )
      .filter((attribute) => attribute.required)
      .map((attribute) => attribute.id);
    return updated;
  }, {} as Record<AttributeDomainType, string[]>);

  // Update attribute metadata to ensure we only have the minimum number of required
  // fields flagged as required.
  const sourceMappings = groupAttributes(source.attributeMappings).map((attributeMapping) => {
    let required = false;

    const idx =
      requiredMappings[(attributeMapping.domainType as AttributeDomainType)!]?.indexOf(
        attributeMapping.canonicalAttribute
      ) ?? -1;

    if (idx > -1) {
      required = true;
      requiredMappings[(attributeMapping.domainType as AttributeDomainType)!].splice(idx, 1);
    }

    let { metadata } = attributeMapping;
    if (!metadata) {
      metadata = {
        id: attributeMapping.canonicalAttribute,
        displayName: attributeMapping.canonicalAttribute,
        required,
      };
    }

    return { ...attributeMapping, metadata: { ...metadata, required } };
  });

  const missingMappings: DataSourceAttributeMultiValueMapping[] = [];
  Object.entries(requiredMappings).forEach(([key, value]) => {
    if (value.length > 0) {
      value.forEach((v) => {
        missingMappings.push({
          mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
          domainType: key as AttributeDomainType,
          canonicalAttribute: v,
          sourceAttributes: [{ value: '' }],
          metadata: {
            id: v,
            displayName: v,
            required: true,
          },
        });
      });
    }
  });

  return [...sourceMappings, ...missingMappings].sort((a, b) => {
    if (!!a.metadata?.required && !!b.metadata?.required) {
      return 0;
    }

    return !!a.metadata?.required && !b.metadata?.required ? -1 : 1;
  });
}

export function getSyncDirectionIcon(syncDirection: DataSourceConnectionTypes) {
  switch (syncDirection) {
    case 'BOTH':
      return IconImage.dataTransfer;
    case 'OUT':
      return IconImage.arrowBack;
    case 'IN':
    default:
      return IconImage.arrowForward;
  }
}

export const attributeMappingSchema = yup.array().of(
  yup.object({
    mappingType: yup.string().required('Mapping type is required'),
    parameters: yup
      .object()
      .nullable()
      .when('mappingType', {
        is: 'CONSTANT_VALUE',
        then: () =>
          yup.object({
            value: yup.string().required('Value is required'),
          }),
      }),
    sourceAttributes: yup
      .array()
      .of(yup.object({ value: yup.string() }))
      .when('mappingType', {
        is: 'SINGLE_SOURCE_ATTRIBUTE',
        then: () =>
          yup
            .array()
            .of(
              yup.object({
                value: yup.string().min(1, 'Source attribute is required'),
                normalizations: yup.array().nullable(),
              })
            )
            .min(1, 'Source attribute is required'),
      }),
    canonicalAttribute: yup
      .string()
      .required('Standard data field is required')
      .min(1, 'Standard data field is required'),
  })
);

export function showAttributeOrderToast(toast: ReturnType<typeof useToast>) {
  const attributeAdded = SessionStorageService.getItem('seviin.notify.attribute.added');
  if (!attributeAdded) {
    toast({
      title: 'Order matters!',
      description:
        'When mapping multiple attributes to one harpin AI data field, be sure to list them in priority order.',
      isClosable: true,
    });
    SessionStorageService.setItem('seviin.notify.attribute.added', 'true');
  }
}
