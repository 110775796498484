import { Box, Tooltip as CharkaTooltip } from '@chakra-ui/react';
import { Target as TargetSvg } from '../../../core/Icon/svg';

function ReferenceLineTooltip({ viewBox: { x, y }, referenceContent, color }: any) {
  const transform = `translate(${x - 8} ${y - 8}) scale(0.666 0.666)`;
  return (
    <CharkaTooltip
      label={<Box>{referenceContent}</Box>}
      placement="top-start"
      bg="gray.800"
      p="2"
      borderRadius="6px"
      offset={[-4, 10]}
      hasArrow
      w="320px"
    >
      <g transform={transform}>
        <TargetSvg color={color} />
      </g>
    </CharkaTooltip>
  );
}

export default ReferenceLineTooltip;
