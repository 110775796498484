import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Text,
  VStack,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import consola from 'consola';
import { useEffect, useState } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import WebHookCard from '../../../components/core/WebHookCard/WebHookCard';
import DataSourceIcon from '../../../components/features/connections/DataSourceIcon/DataSourceIcon';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import SourceClient from '../../../lib/api-client/sources/SourceClient';
import { useGetWebHooks } from '../../../lib/api-client/sources/SourceData';
import { WebHookItems } from '../../../lib/api-client/sources/model/webHook';
import ConnectionManagementHeader from '../components/ConnectionManagementHeader/ConnectionManagementHeader';
import { useCurrentDataSource } from '../context/CurrentDataSourceContext';

export default function WebhookManagement() {
  const { id = '' } = useParams();
  const { dataSource } = useCurrentDataSource();
  const { data, loading, refetch } = useGetWebHooks(dataSource.id);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedWebhook, setSelectedWebhook] = useState<WebHookItems | null>(null);
  const [webhookdataById, setWebhookdataById] = useState<WebHookItems | null>(null);
  const [isLargerThan800] = useMediaQuery('(min-height: 856px)');
  const formSchema = yup.object().shape({
    name: yup.string().required('Display name is required'),
    signatureKey: yup.string().optional(),
    headers: yup.array().when('signatureKey', {
      is: (signatureKey: any) => !signatureKey,
      then: () =>
        yup.array().of(
          yup.object().shape({
            name: yup.string().required('This is a required field '),
            value: yup.string().required('This is a required field '),
          })
        ),
      otherwise: () => yup.array().notRequired(),
    }),
  });

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    resolver: yupResolver(formSchema),
    mode: 'onBlur',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'headers',
  });

  const handleClose = () => {
    reset();
    onClose();
  };
  const onSubmit: SubmitHandler<WebHookItems> = async (item) => {
    try {
      const updatedItem = item.headers?.filter(
        (header: any) => header.name.trim() !== '' && header.value.trim() !== ''
      );
      const signatureKey = item.signatureKey || undefined;

      if (selectedWebhook) {
        const updatedWebhook: WebHookItems = {
          ...selectedWebhook,
          ...item,
          signatureKey,
        };
        await SourceClient.updateWebhook(dataSource.id, updatedWebhook, updatedWebhook?.id);
      } else {
        await SourceClient.createWebhook(dataSource.id, {
          ...item,
          headers: updatedItem,
          signatureKey,
        });
      }
      await refetch();
      onClose();
      reset();
    } catch (err) {
      consola.error(err);
    }
  };

  const handleDeleteWebhook = async (webhookId: string) => {
    try {
      await SourceClient.deleteWebhook(dataSource.id, webhookId);
      await refetch();
    } catch (err) {
      consola.error(err);
    }
  };

  const handleGetDataById = async (webhookId: string) => {
    try {
      const value = await SourceClient.getWebhookById(dataSource.id, webhookId);
      setWebhookdataById(value);
      onOpen();
    } catch (err) {
      consola.error(err);
    }
  };

  const handleOpenDrawer = (webhook: WebHookItems) => {
    setSelectedWebhook(webhook);
    handleGetDataById(webhook.id);
    onOpen();
  };

  const handleAddHeader = () => {
    setSelectedWebhook(null);
    append({ name: '', value: '' });
    onOpen();
  };

  const handleAddHeaderDrawer = () => {
    append({ name: '', value: '' });
    onOpen();
  };

  useEffect(() => {
    setValue('name', (webhookdataById && webhookdataById.name) || '');
    setValue('signatureKey', (webhookdataById && webhookdataById.signatureKey) || '');

    if (webhookdataById && webhookdataById.headers) {
      webhookdataById.headers.forEach((header) => {
        append({
          name: header.name,
          value: header.value,
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webhookdataById]);

  return (
    <PageLayout
      pageViewEvent={{ page: 'webhook management', pageId: id }}
      header={
        <Header
          icon={<DataSourceIcon sourceSystem={dataSource.sourceSystem} />}
          title={`${dataSource.name} webhook management`}
          back={{
            label: 'Back to connection management',
            to: `/sources/${dataSource.id}/manage`,
          }}
        />
      }
      loading={loading}
    >
      <ConnectionManagementHeader source={dataSource} />

      <SectionContainer title="Webhooks">
        {data?.content.map((webhook: WebHookItems) => (
          <WebHookCard
            key={`${webhook.name}-${webhook.id}`}
            url={webhook.url}
            label={webhook.name}
            deleteWebhook={() => handleDeleteWebhook(webhook.id)}
            cutomizeWebhook={() => handleOpenDrawer(webhook)}
          />
        ))}
        {data && data?.content.length >= 1 && (
          <Button
            onClick={handleAddHeader}
            variant="link"
            mt={2}
            rightIcon={<Icon iconImage={IconImage.add} boxSize={4} />}
          >
            {' '}
            Add a webhook
          </Button>
        )}
        {data?.content.length === 0 && (
          <>
            <Text>There are currently no webhooks for this source system.</Text>
            <Button onClick={handleAddHeader} mt={4}>
              {' '}
              Add a webhook
            </Button>
          </>
        )}

        <Drawer isOpen={isOpen} placement="right" onClose={handleClose} size="md">
          <DrawerOverlay />
          <DrawerContent p={4} display="flex" flexDirection="column" flexGrow="1">
            <DrawerCloseButton
              size="lg"
              right="unset"
              left="-40px"
              fontSize="xs"
              color="white"
              top={10}
              bgColor="action"
              _hover={{ backgroundColor: 'actionDark' }}
              borderTopRightRadius={0}
              borderBottomRightRadius={0}
            />
            <DrawerHeader>
              Customize webhook parameters
              <Text fontWeight="normal" fontSize="14px" fontStyle="normal" lineHeight="20px">
                To enable the webhook, either a signature key or at least one HTTP header is
                required.
              </Text>
            </DrawerHeader>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DrawerBody>
                <Box overflowY="auto" overflow="hidden" h="82vh">
                  <VStack
                    alignItems="start"
                    spacing={4}
                    p={2}
                    height="fit-content"
                    css={{
                      '&::-webkit-scrollbar': {
                        display: 'none',
                      },
                    }}
                    scrollbar-width="none"
                    overflowY="scroll"
                    sx={{ '.-webkit-scrollbar': { display: 'none' } }}
                    h={isLargerThan800 ? '95%' : '88%'}
                  >
                    <FormControl isInvalid={!!errors.name}>
                      <FormLabel>Display name</FormLabel>
                      <Input {...register('name')} />
                      <FormErrorMessage>{String(errors?.name?.message)}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={
                        !!(
                          !(watch('signatureKey')?.length > 0) &&
                          errors?.headers?.[0]?.name?.message &&
                          errors?.headers?.[0]?.value?.message
                        )
                      }
                    >
                      <FormLabel>Signature key</FormLabel>
                      <Text
                        fontWeight="normal"
                        fontSize="14px"
                        fontStyle="normal"
                        lineHeight="20px"
                        mb={2}
                      >
                        If supported by the source system, this is used to verify the payload of the
                        webhook.
                      </Text>
                      <Input
                        {...register('signatureKey')}
                        type={webhookdataById ? 'password' : 'text'}
                      />

                      <FormErrorMessage>
                        {String(errors?.headers?.[0]?.name?.message) &&
                          String(errors?.headers?.[0]?.value?.message) &&
                          String('Signature or at least 1 header is required')}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Expected HTTP headers</FormLabel>
                      <Text
                        fontWeight="normal"
                        fontSize="14px"
                        fontStyle="normal"
                        lineHeight="20px"
                        mb={2}
                      >
                        Incoming webhook requests will only be processed if they include all header
                        and value configurations entered below.
                      </Text>
                      <Divider mb={2} color="gray.300" borderWidth="thin" />
                      {fields.map((field, index) => (
                        <>
                          <FormControl key={field.id} isInvalid={!!errors?.headers?.[index]?.name}>
                            <FormLabel mt={2}>Header name</FormLabel>
                            <HStack>
                              <Input {...register(`headers[${index}].name`)} />
                              <IconButton
                                variant="outline"
                                disabled={fields.length === 1}
                                aria-label="remove source attribute"
                                icon={<Icon iconImage={IconImage.delete} />}
                                onClick={() => remove(index)}
                              />
                            </HStack>
                            <FormErrorMessage>
                              {String(errors?.headers?.[index]?.name?.message)}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl isInvalid={!!errors?.headers?.[index]?.value} mt={2}>
                            <FormLabel>Header value</FormLabel>
                            <Input
                              {...register(`headers[${index}].value`)}
                              w="392px"
                              type={webhookdataById ? 'password' : 'text'}
                            />
                            <FormErrorMessage>
                              {String(errors?.headers?.[index]?.value?.message)}
                            </FormErrorMessage>
                          </FormControl>
                        </>
                      ))}
                      <Text fontWeight="sm" fontSize="12px" lineHeight="20px" mt={1}>
                        All values are encrypted and stored securely
                      </Text>
                    </FormControl>
                    <Divider mb={2} color="gray.300" borderWidth="thin" />
                    <Button
                      variant="link"
                      mt={2}
                      rightIcon={<Icon iconImage={IconImage.add} boxSize={4} />}
                      onClick={handleAddHeaderDrawer}
                    >
                      {' '}
                      Add another header
                    </Button>
                  </VStack>
                </Box>
              </DrawerBody>
              <DrawerFooter
                bottom={0}
                backgroundColor="white"
                sx={{ position: 'fixed' }}
                w="full"
                borderTop="2px solid"
                borderColor="gray.100"
              >
                <Button type="submit" w="full" isLoading={isSubmitting}>
                  Save parameters
                </Button>
              </DrawerFooter>
            </form>
          </DrawerContent>
        </Drawer>
      </SectionContainer>
    </PageLayout>
  );
}
