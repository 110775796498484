import { Button, ButtonProps } from '@chakra-ui/react';
import SourceClient from '../../../../lib/api-client/sources/SourceClient';
import { useExternalDataTypeModel } from '../../../../lib/api-client/sources/SourceData';

interface MicrosoftTestAccessButtonProps {
  sourceId: string;
  onSuccess: () => void;
  onError: () => void;
}
export default function MicrosoftTestAccessButton({
  sourceId,
  onSuccess,
  onError,
  children,
  ...props
}: MicrosoftTestAccessButtonProps & ButtonProps) {
  const { refetch, loading } = useExternalDataTypeModel(sourceId);

  const onClick = async () => {
    const state = await refetch();
    if (state?.error) {
      onError();
      return;
    }

    if ((state?.data?.length ?? 0) > 0) {
      try {
        await SourceClient.updateDataSourceProperties(sourceId, { microsoftExportTest: 'true' });
        onSuccess();
      } catch (err) {
        onError();
      }
    }
  };

  return (
    <Button {...props} onClick={onClick} isLoading={loading}>
      {children ?? 'Test access'}
    </Button>
  );
}
