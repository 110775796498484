import { Page } from '../../model/common/Page';
import ApiClient from '../ApiClient';
import { Events } from './model/Events';
import { Profile } from './model/Profile';
import { Transaction } from './model/Transaction';
import { TransactionsByRecord } from './model/TransactionsByRecord';

/**
 * API Client to retrieve profile data for a given consumer.
 */
const ProfileClient = {
  getTransactionsByPin(
    pin: string,
    sort?: ('startTimestamp,desc' | 'startTimestamp,asc')[],
    page?: number,
    size?: number
  ): Promise<Page<Transaction>> {
    const params = new URLSearchParams();
    if (size) {
      params.append('size', size.toString());
    }

    if (page) {
      params.append('page', page.toString());
    }

    if (sort) {
      sort.forEach((s) => {
        params.append('sort', s);
      });
    }

    return ApiClient.get<Page<Transaction>>(`/profiles/${pin}/transactions`, {
      params,
    }).then((res) => res.data);
  },

  getEventsByPin(
    pin: string,
    eventType?: string,
    page?: number,
    size?: number
  ): Promise<Page<Events>> {
    const params = new URLSearchParams();
    if (eventType) {
      params.append('eventType', eventType);
    }
    if (size) {
      params.append('size', size.toString());
    }

    if (page) {
      params.append('page', page.toString());
    }

    return ApiClient.get<Page<Events>>(`/profiles/${pin}/events`, {
      params,
    }).then((res) => res.data);
  },

  getTransactionsByRecordId(
    pin: string,
    recordId: string,
    sort?: ('startTimestamp,desc' | 'startTimestamp,asc')[],
    page?: number,
    size?: number
  ): Promise<TransactionsByRecord> {
    const params = new URLSearchParams();
    if (size) {
      params.append('size', size.toString());
    }

    if (page) {
      params.append('page', page.toString());
    }

    if (sort) {
      sort.forEach((s) => {
        params.append('sort', s);
      });
    }

    return ApiClient.get<TransactionsByRecord>(`/profiles/${pin}/transactions/${recordId}`, {
      params,
    }).then((res) => res.data);
  },
  getProfileByPin(pin: string): Promise<Profile> {
    return ApiClient.get<Profile>(`/profiles/${pin}`).then((res) => res.data);
  },
};
export default ProfileClient;
