import { useBoolean } from '@chakra-ui/react';
import { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';
import LocalStorageService from '../lib/services/LocalStorageService';

export interface PreviewContext {
  showPreviewPage: boolean;
  hasPreviewPage: boolean;
  previewKey: string;
  togglePreviewPage: () => void;
}

const Context = createContext<PreviewContext>({
  showPreviewPage: false,
  hasPreviewPage: false,
  previewKey: '',
  togglePreviewPage: () => {},
} as PreviewContext);

interface PreviewProviderProps {
  showPreviewPage?: boolean;
  previewKey: string;
  children: ReactNode;
}

export function PreviewProvider({
  showPreviewPage: showPreviewPageProp = false,
  previewKey,
  children,
}: PreviewProviderProps) {
  const defaultValue = LocalStorageService.getItem(previewKey) !== null || showPreviewPageProp;
  const [showPreviewPage, setShowPreviewPage] = useBoolean(defaultValue);

  useEffect(() => {
    if (showPreviewPage) {
      LocalStorageService.setItem(previewKey, `${showPreviewPage}`);
    } else {
      LocalStorageService.removeItem(previewKey);
    }
  }, [showPreviewPage, previewKey]);

  const memo = useMemo<PreviewContext>(
    () => ({
      showPreviewPage,
      previewKey,
      hasPreviewPage: true,
      togglePreviewPage: () => {
        setShowPreviewPage.toggle();
      },
    }),
    [previewKey, showPreviewPage, setShowPreviewPage]
  );

  return <Context.Provider value={memo}>{children}</Context.Provider>;
}

export function usePreviewPage(): PreviewContext {
  return useContext(Context);
}
