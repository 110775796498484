import {
  Button,
  ButtonGroup,
  chakra,
  FormControl,
  FormLabel,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import Icon from '../../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../../components/core/Icon/IconConfig';
import { formatDateString } from '../../../../../lib/utils/date-time-utils';

const DATE_FORMAT = 'MM/dd/yyyy';

function Datepicker({ onTimeChange }: any) {
  const { handleSubmit, register, reset } = useForm();
  const [isOpen, setIsOpen] = useState(true);

  const [label, setLabel] = useState('Select dates & times');
  const handleData = () => {};

  const onSubmit = (data: any) => {
    if (data?.startDate && data?.endDate) {
      onTimeChange(data);
      const startDate = formatDateString(data.startDate, DATE_FORMAT);
      const endDate = formatDateString(data.endDate, DATE_FORMAT);
      setLabel(`${startDate} - ${endDate}`);
    } else {
      onTimeChange({});
    }
  };

  const today = new Date();
  const currentTime = `${today.getHours()}:${today.getMinutes()}`;

  return (
    <chakra.form onSubmit={handleSubmit(onSubmit)}>
      <Popover isLazy>
        <PopoverTrigger>
          <Button
            textColor="gray.800"
            fontWeight="normal"
            variant="outline"
            display="flex"
            justifyContent="space-between"
            w="256px"
            rightIcon={<Icon boxSize={4} iconImage={IconImage.input} color="action" />}
            borderColor="gray.300"
            onClick={() => setIsOpen(true)}
          >
            {label}
          </Button>
        </PopoverTrigger>
        {isOpen ? (
          <PopoverContent w="310px" boxShadow="base">
            <PopoverBody bgColor="gray.50" h="max-content">
              <SimpleGrid columns={2} spacing={2}>
                <FormControl>
                  <FormLabel fontWeight="semibold">Start date</FormLabel>
                  <Input
                    {...register('startDate')}
                    type="date"
                    placeholder="MM/DD/YYYY"
                    fontSize="12"
                    bgColor="white"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight="semibold">Start time</FormLabel>
                  <Input
                    fontSize="12"
                    type="time"
                    placeholder="00:00"
                    {...register('startTime')}
                    defaultValue={currentTime}
                    bgColor="white"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight="semibold">End date</FormLabel>
                  <Input
                    type="date"
                    placeholder="MM/DD/YYYY"
                    fontSize="12"
                    {...register('endDate')}
                    bgColor="white"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel fontWeight="semibold">End time</FormLabel>
                  <Input
                    bgColor="white"
                    fontSize="12"
                    type="time"
                    placeholder="00:00"
                    {...register('endTime')}
                    defaultValue={currentTime}
                  />
                </FormControl>
              </SimpleGrid>
              <ButtonGroup w="full" mt="1">
                <Button w="full" mt="1" type="submit" onClick={() => handleData()}>
                  Save
                </Button>
                <Button
                  w="full"
                  mt="1"
                  variant="outline"
                  type="reset"
                  onClick={() => {
                    setLabel('Select dates & times');
                    setIsOpen(false);
                    reset();
                    onSubmit({});
                  }}
                >
                  Reset
                </Button>
              </ButtonGroup>
            </PopoverBody>
          </PopoverContent>
        ) : null}
      </Popover>
    </chakra.form>
  );
}

export default Datepicker;
