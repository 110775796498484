import { Button, HStack, Stack } from '@chakra-ui/react';
import { useXSWR } from '@hazae41/xswr';
import axios from 'axios';
import { useCallback, useState } from 'react';
import FilePicker from '../../../../components/core/FilePicker/FilePicker';
import SourceClient from '../../../../lib/api-client/sources/SourceClient';
import { getSourceUploadSchema } from '../../../../lib/api-client/sources/SourceData';
import { UploadPath } from '../../../../lib/api-client/sources/model/UploadPath';

export type UploadFlatFileType = 'UPLOAD_ONLY' | 'UPLOAD_AND_INGEST';

export type OnFileUploadProps = {
  files: File[];
  setLoadingText: (loading: string) => void;
  uploadPath: UploadPath;
  ingestStarted: boolean;
};

interface UploadFlatFileProps {
  sourceId: string;
  type: UploadFlatFileType;
  onUpload?: (props: OnFileUploadProps) => Promise<void>;
  onUploadError?: () => void;
}
export default function UploadFlatFile({
  type,
  sourceId,
  onUpload,
  onUploadError,
}: UploadFlatFileProps) {
  const { make } = useXSWR();
  const [clickedButton, setClickedButton] = useState(type);
  const [loadingText, setLoadingText] = useState('Uploading');
  const [files, setFiles] = useState<File[]>([]);
  const hasFile = files.length > 0;
  const [isUploading, setUploading] = useState(false);

  const upload = useCallback(
    async (uploadAction: UploadFlatFileType) => {
      setUploading(true);
      const uploadPath = await SourceClient.createSourceUpload(sourceId, files[0].name);
      let ingestStarted = false;

      try {
        await axios.request({
          method: uploadPath.httpMethod,
          url: uploadPath.url,
          headers: {
            'Content-Type': 'text/csv',
          },
          data: files[0],
          onUploadProgress: (event) => {
            if (event.total) {
              setLoadingText(`Uploading ${Math.round((100 * event.loaded) / event.total)}%`);
            }
          },
        });
      } catch (err) {
        setUploading(false);
        if (onUploadError) {
          onUploadError();
        }
        return;
      }

      if (uploadAction === 'UPLOAD_AND_INGEST') {
        setLoadingText('Analyzing upload');
        try {
          await new Promise<void>((resolve, reject) => {
            const iid = setInterval(async () => {
              const state = await make(getSourceUploadSchema(sourceId, uploadPath.id)).fetch();

              if (state) {
                if (
                  state.error ||
                  state.data?.status === 'failed' ||
                  state.data?.errorMessage != null
                ) {
                  reject(state.data?.errorMessage);
                  clearInterval(iid);
                } else if (state.data?.status === 'analysisCompleted') {
                  resolve();
                  clearInterval(iid);
                }
              }
            }, 5000);
          });
        } catch (e) {
          setUploading(false);
          if (onUploadError) {
            onUploadError();
          }
          return;
        }

        await SourceClient.updateSourceUploadStatus(sourceId, uploadPath.id, 'importRequested');
        ingestStarted = true;
      }

      if (onUpload) {
        await onUpload({
          files,
          setLoadingText,
          uploadPath,
          ingestStarted,
        });
      }

      setUploading(false);
    },
    [sourceId, onUpload, onUploadError, make, files]
  );

  const onClick = useCallback(
    async (uploadAction: UploadFlatFileType) => {
      setClickedButton(uploadAction);
      await upload(uploadAction);
    },
    [upload]
  );

  return (
    <Stack>
      <FilePicker onFilesAccepted={setFiles} />
      <HStack>
        {type === 'UPLOAD_AND_INGEST' && (
          <Button
            isDisabled={!hasFile || isUploading}
            isLoading={clickedButton === 'UPLOAD_AND_INGEST' && isUploading}
            loadingText={loadingText}
            onClick={async () => {
              await onClick('UPLOAD_AND_INGEST');
            }}
          >
            Upload file and ingest data
          </Button>
        )}
        <Button
          isDisabled={!hasFile || isUploading}
          variant={type === 'UPLOAD_ONLY' ? 'solid' : 'outline'}
          isLoading={clickedButton === 'UPLOAD_ONLY' && isUploading}
          loadingText={loadingText}
          onClick={async () => {
            await onClick('UPLOAD_ONLY');
          }}
        >
          {type === 'UPLOAD_ONLY' ? 'Upload file' : 'Upload file only'}
        </Button>
      </HStack>
    </Stack>
  );
}
