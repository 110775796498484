import { chakra, forwardRef, HTMLChakraProps, ThemingProps } from '@chakra-ui/react';
import { useCardStyles } from './Card';

interface CardTitleProps extends HTMLChakraProps<'h3'>, ThemingProps {}

const CardTitle = forwardRef<CardTitleProps, 'h3'>((props, ref) => {
  const styles = useCardStyles();
  return <chakra.h3 ref={ref} __css={styles.title} {...props} />;
});

export default CardTitle;
