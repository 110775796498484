import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Switch,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useDataModel } from '../../../../lib/api-client/data-model/DataModelData';
import SourceClient from '../../../../lib/api-client/sources/SourceClient';
import { useDataSources } from '../../../../lib/api-client/sources/SourceData';
import { CreateDataSource } from '../../../../lib/api-client/sources/model/CreateDataSource';
import { DataSource } from '../../../../lib/api-client/sources/model/DataSource';
import { DataSourceConnectionTypes } from '../../../../lib/api-client/sources/model/DataSourceConnectionType';
import { DataSourcePrimaryDomainType } from '../../../../lib/api-client/sources/model/DataSourcePrimaryDomainType';
import { SourceTypes } from '../../../../lib/api-client/sources/model/SourceTypes';
import { UpdateDataSource } from '../../../../lib/api-client/sources/model/UpdateDataSource';
import Alert from '../../../core/Alert/Alert';
import Icon from '../../../core/Icon/Icon';
import { IconImage } from '../../../core/Icon/IconConfig';
import DeleteConnectionSection from './DeleteConnectionSection/DeleteConnectionSection';

type PageState = 'error';

interface ConnectNewSystemForm {
  sourceSystem: string;
  name: string;
  category: string;
  syncDirection?: DataSourceConnectionTypes;
  domainType?: DataSourcePrimaryDomainType;
  eventType?: string;
  primary?: boolean;
}

const formSchema = yup.object({
  category: yup.string().required('Category is required'),
  name: yup.string().required('Display name is required'),
  sourceSystem: yup.string().required('Source system is required'),
  syncDirection: yup.string().nullable(),
  domainType: yup.string().required('Domain Type is required'),
  eventType: yup
    .string()
    .nullable()
    .when('domainType', {
      is: 'CONSUMER_EVENT',
      then: () => yup.string().required('Event data name is required'),
      otherwise: () => yup.string().nullable(),
    }),
  primary: yup.boolean().nullable(),
});

interface EditSourceSystemModalProps {
  sourceTypes: SourceTypes;

  source?: Pick<
    DataSource,
    | 'id'
    | 'sourceSystem'
    | 'name'
    | 'category'
    | 'syncDirection'
    | 'attributeMappings'
    | 'domainType'
    | 'eventType'
    | 'primary'
  >;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (dataSource: DataSource) => void;
}
function EditSourceSystemModal({
  sourceTypes,
  source,
  isOpen,
  onClose,
  onSuccess,
}: EditSourceSystemModalProps) {
  const [error, setError] = useState<boolean>();
  const [pageState, setPageState] = useState<PageState>();
  const isEdit = !!source;
  const [selectedOption, setSelectedOption] = useState('');
  const [showEventDataInput, setShowEventDataInput] = useState(false);
  const { data: sources } = useDataSources();
  const { data: dataModel } = useDataModel();
  const primaryDomains =
    dataModel?.domains
      .filter((d) => d.primaryDomain)
      .sort((a, b) => a.displayName.localeCompare(b.displayName)) ?? [];
  const primary = sources?.content.find((s) => s.primary && s.id !== source?.id);
  const hasAnotherPrimary = primary != null;
  const primaryTooltip = hasAnotherPrimary
    ? `${primary.name} is currently configured as the primary source system. `
    : 'The primary source system data will take precedence over values from any other source system in profiles and reports.';

  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<ConnectNewSystemForm>({
    resolver: yupResolver(formSchema),
    mode: 'onBlur',
    defaultValues: source
      ? {
          category: source.category,
          name: source.name,
          syncDirection: source.syncDirection,
          sourceSystem: source.sourceSystem,
          domainType: source.domainType,
          eventType: source.eventType,
          primary: source.primary,
        }
      : undefined,
  });

  const onSubmit: SubmitHandler<ConnectNewSystemForm> = async (data) => {
    setError(false);
    try {
      if (source) {
        const dto: UpdateDataSource = { ...source, ...data };
        const result = await SourceClient.updateDataSource(source.id, dto);
        onSuccess(result);
      } else {
        const createDataSource: CreateDataSource = {
          ...data,
          syncDirection: data.syncDirection ?? 'IN',
          attributeMappings: [],
        };
        const created = await SourceClient.createDataSource(createDataSource);
        onSuccess(created);
      }
    } catch (err) {
      setError(true);
    }
  };

  const resetForm = () => {
    reset();
    setError(false);
  };
  const handleOptionChange = (event: any) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    if (selectedValue === 'CONSUMER_EVENT') {
      setShowEventDataInput(true);
    } else {
      setShowEventDataInput(false);
      setValue('eventType', undefined);
    }
  };

  const watchedSourceSystem = watch('sourceSystem');

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        resetForm();
        onClose();
      }}
      isCentered
      closeOnOverlayClick={!isSubmitting}
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>{isEdit ? 'Edit source system' : 'Connect a new source system'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={8} w="320px" data-testid="EditSourceSystemModal">
              <Stack spacing={1}>
                <FormControl isInvalid={!!errors.sourceSystem}>
                  <FormLabel>Source system</FormLabel>
                  <Select
                    autoFocus={!isEdit}
                    width="full"
                    {...register('sourceSystem', {
                      onChange: (e) => {
                        if (e.target.value !== 'MicrosoftDynamics') {
                          setValue('syncDirection', undefined);
                        }
                      },
                    })}
                    placeholder="Select one"
                    disabled={isEdit}
                  >
                    {sourceTypes.systemTypes.map((sourceType) => (
                      <option key={sourceType.id} value={sourceType.id}>
                        {sourceType.displayName}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{errors?.sourceSystem?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isDisabled={hasAnotherPrimary}>
                  <HStack spacing={1}>
                    <Switch {...register('primary')} />
                    <FormLabel fontWeight="normal">Primary source system</FormLabel>
                    <Tooltip hasArrow label={primaryTooltip} placement="right">
                      <span>
                        <Icon
                          ml="4px"
                          boxSize="4"
                          iconImage={IconImage.info}
                          cursor="pointer"
                          color="action"
                        />
                      </span>
                    </Tooltip>
                  </HStack>
                </FormControl>
              </Stack>
              <FormControl isInvalid={!!errors.category}>
                <FormLabel>System type</FormLabel>
                <Select {...register('category')} placeholder="Select one">
                  {sourceTypes.categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.displayName}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors?.category?.message}</FormErrorMessage>
              </FormControl>
              <VStack alignItems="start">
                <FormControl isInvalid={!!errors.domainType}>
                  <FormLabel>Data type</FormLabel>
                  <Select
                    autoFocus={isEdit}
                    width="full"
                    {...register('domainType')}
                    placeholder="Select one"
                    disabled={isEdit}
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    {primaryDomains.map((primaryDomain) => (
                      <option key={primaryDomain.id} value={primaryDomain.id}>
                        {primaryDomain.displayName}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{errors?.domainType?.message}</FormErrorMessage>
                </FormControl>
                {showEventDataInput && (
                  <InputGroup>
                    <Tooltip
                      label="This is used to label and organize event data on the Consumer Profile."
                      placement="top"
                    >
                      <InputRightElement pr={2}>
                        <Icon iconImage={IconImage.info} color="action" boxSize={4} />
                      </InputRightElement>
                    </Tooltip>
                    <FormControl isInvalid={!!errors.eventType}>
                      <Input {...register('eventType')} placeholder="Event data display name" />
                      <FormErrorMessage>{errors?.eventType?.message}</FormErrorMessage>
                    </FormControl>
                  </InputGroup>
                )}
              </VStack>
              {watchedSourceSystem === 'MicrosoftDynamics' && (
                <FormControl isInvalid={!!errors.syncDirection}>
                  <FormLabel>Connection type</FormLabel>
                  <Select
                    autoFocus={isEdit}
                    width="full"
                    {...register('syncDirection')}
                    placeholder="Select one"
                  >
                    <option value="IN">Ingest only</option>
                    {/*<option value="OUT">Export only (OUT)</option>*/}
                    <option value="BOTH">Ingest and publish</option>
                  </Select>
                  <FormErrorMessage>{errors?.syncDirection?.message}</FormErrorMessage>
                </FormControl>
              )}
              <FormControl isInvalid={!!errors.name}>
                <FormLabel>Display name</FormLabel>
                <Input {...register('name')} />
                <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter justifyContent="start">
            <VStack alignItems="self-start">
              <Box mb="4">
                <Button isLoading={isSubmitting} type="submit">
                  {isEdit ? 'Save' : 'Save and continue'}
                </Button>
              </Box>
              {source && (
                <DeleteConnectionSection
                  sourceId={source.id}
                  sourceName={source.name}
                  onError={() => setPageState('error')}
                />
              )}
            </VStack>
            {pageState && (
              <Alert
                ml={6}
                width="full"
                status="error"
                description="There was a problem deleting your connection"
              />
            )}
            {error && (
              <Alert
                ml={6}
                width="full"
                status="error"
                description={`There was a problem ${isEdit ? 'saving' : 'connecting'}.`}
              />
            )}
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
export default EditSourceSystemModal;
