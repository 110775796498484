import { Box, chakra, Heading, HStack } from '@chakra-ui/react';
import { Identity, ProfileTags } from '../../../../lib/api-client/identity/model/Identity';
import ProfileAuthenticityBadge from '../ProfileAuthenticityBadge/ProfileAuthenticityBadge';
import ProfileType from '../ProfileType/ProfileType';

interface ProfileHeaderProps {
  identity: Pick<Identity, 'identityAttributes' | 'pin' | 'pinType'>;
  authenticityTag?: ProfileTags;
}

function ProfileHeader({ identity, authenticityTag }: ProfileHeaderProps) {
  return (
    <Box data-testid="ProfileHeader">
      <Heading fontSize="xl" as="h3" paddingBottom={1}>
        {identity.identityAttributes.name?.primaryValue.value ?? ''}
      </Heading>
      <HStack spacing={2.5}>
        <chakra.span fontSize="sm">PIN: {identity.pin}</chakra.span>
        {authenticityTag && <ProfileAuthenticityBadge tag={authenticityTag} />}
        {identity && <ProfileType pinType={identity.pinType} />}
      </HStack>
    </Box>
  );
}
export default ProfileHeader;
