import { FormControl, FormLabel, HStack, Input, Select, Stack } from '@chakra-ui/react';
import { addDays, differenceInDays, formatISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ReportExecutionOption,
  ReportPredicateOperator,
} from '../../../../lib/api-client/reports/report.model';

function getControlValues(optionValue?: ReportExecutionOption) {
  if (!optionValue) {
    return null;
  }

  const { startRange, endRange } = optionValue.arguments ?? {};

  let operator: ReportPredicateOperator = 'GREATER_THAN_OR_EQUAL';
  if (startRange && endRange) {
    if (Math.abs(differenceInDays(new Date(startRange), new Date(endRange))) > 1) {
      operator = 'BETWEEN';
    } else {
      operator = 'EQUALS';
    }
  } else if (optionValue.arguments!.endRange) {
    operator = 'LESS_THAN_OR_EQUAL';
  }

  return {
    value: 'CUSTOM',
    operator,
    filterValue: startRange ?? endRange ?? '',
    betweenFilterValue: operator === 'BETWEEN' ? endRange : '',
  };
}

interface DateRangeQueryOptionProps {
  label: string;
  customLabel?: string;
  name: string;
  index: number;
  optionValue?: ReportExecutionOption;
}

export default function DateRangeQueryOption({
  label,
  customLabel = 'Custom date or date range',
  name,
  index,
  optionValue,
}: DateRangeQueryOptionProps) {
  const controlValue = getControlValues(optionValue);
  const [showCustom, setShowCustom] = useState(optionValue != null);
  const [showBetweenValue, setShowBetweenValue] = useState(controlValue?.operator === 'BETWEEN');
  const [operator, setOperator] = useState<ReportPredicateOperator>(
    controlValue?.operator ?? 'GREATER_THAN_OR_EQUAL'
  );
  const [filterValue, setFilterValue] = useState(controlValue?.filterValue ?? '');
  const [betweenFilterValue, setBetweenFilterValue] = useState(controlValue?.betweenFilterValue);
  const form = useFormContext();
  const field = `options[${index}]`;

  const buildFormValue = (startRange?: string, endRange?: string) => {
    let params = '';

    if (startRange) {
      params = `_startRange.${startRange}`;
    }

    if (endRange) {
      params = `${params}_endRange.${endRange}`;
    }

    return `${name}${params}`;
  };

  useEffect(() => {
    if (optionValue) {
      const { startRange, endRange } = optionValue.arguments ?? {};
      form.setValue(field, buildFormValue(startRange, endRange));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack w="full">
      <FormControl>
        <FormLabel>{label}</FormLabel>
        <Select
          placeholder="All"
          value={showCustom ? 'CUSTOM' : ''}
          onChange={(event) => {
            if (event.target.value === 'CUSTOM') {
              setShowCustom(true);
            } else {
              setShowCustom(false);
              form.setValue(field, '');
            }
          }}
        >
          <option value="CUSTOM">{customLabel}</option>
        </Select>
      </FormControl>
      {showCustom && (
        <FormControl>
          <Select
            value={operator}
            onChange={(event) => {
              const { value } = event.target;
              setOperator(value as ReportPredicateOperator);

              if (value === 'BETWEEN') {
                setShowBetweenValue(true);
              } else {
                setShowBetweenValue(false);
                setBetweenFilterValue('');

                if (filterValue) {
                  if (value === 'GREATER_THAN_OR_EQUAL') {
                    form.setValue(field, `${name}_startRange.${filterValue}`);
                  } else if (value === 'LESS_THAN_OR_EQUAL') {
                    form.setValue(
                      field,
                      `${name}_endRange.${formatISO(addDays(new Date(filterValue), 1), {
                        representation: 'date',
                      })}`
                    );
                  } else if (value === 'EQUALS') {
                    form.setValue(
                      field,
                      `${name}_startRange.${filterValue}_endRange.${formatISO(
                        addDays(new Date(filterValue), 1),
                        {
                          representation: 'date',
                        }
                      )}`
                    );
                  }
                }
              }
            }}
          >
            <option value="GREATER_THAN_OR_EQUAL">On or after</option>
            <option value="LESS_THAN_OR_EQUAL">On or before</option>
            <option value="EQUALS">On</option>
            <option value="BETWEEN">Between</option>
          </Select>
        </FormControl>
      )}
      {showCustom && (
        <HStack>
          <Input
            type="date"
            w="full"
            placeholder="mm/dd/yyyy"
            value={filterValue}
            onChange={(event) => {
              const { valueAsDate, value } = event.target;
              if (valueAsDate) {
                setFilterValue(event.target.value);

                if (operator === 'GREATER_THAN_OR_EQUAL') {
                  form.setValue(field, `${name}_startRange.${value}`);
                } else if (operator === 'LESS_THAN_OR_EQUAL' && valueAsDate) {
                  form.setValue(
                    field,
                    `${name}_endRange.${formatISO(addDays(valueAsDate, 1), {
                      representation: 'date',
                    })}`
                  );
                } else if (operator === 'EQUALS') {
                  form.setValue(
                    field,
                    `${name}_startRange.${value}_endRange.${formatISO(addDays(valueAsDate, 1), {
                      representation: 'date',
                    })}`
                  );
                } else if (operator === 'BETWEEN' && betweenFilterValue) {
                  form.setValue(
                    field,
                    `${name}_startRange.${value}_endRange.${betweenFilterValue}`
                  );
                }
              }
            }}
          />
          {showBetweenValue && (
            <Input
              type="date"
              w="full"
              placeholder="mm/dd/yyyy"
              value={betweenFilterValue}
              onChange={(event) => {
                const { valueAsDate, value } = event.target;
                if (valueAsDate) {
                  setBetweenFilterValue(value);

                  if (valueAsDate) {
                    form.setValue(
                      field,
                      `${name}_startRange.${filterValue}_endRange.${formatISO(
                        addDays(valueAsDate, 1),
                        {
                          representation: 'date',
                        }
                      )}`
                    );
                  }
                }
              }}
            />
          )}
        </HStack>
      )}
    </Stack>
  );
}
