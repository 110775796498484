import { Circle, HStack, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import ConnectNewSystemButton from '../../../components/features/connections/ConnectNewSystemButton/ConnectNewSystemButton';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import { useAuth } from '../../../context/AuthenticationContext';
import { useDataSources } from '../../../lib/api-client/sources/SourceData';
import SourceConnectionHealthCard from './SourceConnectionHealthCard';

function SourceConnectionsDashboard() {
  const { data: useDataSourcesData, loading } = useDataSources();
  const sources = useDataSourcesData?.content ?? [];
  const { tenant } = useAuth();

  const completedIdentityConnections = sources.filter(
    (item) => item.domainType === 'IDENTITY' && item.configState === 'COMPLETE'
  );
  const incompleteIdentityConnections = sources.filter(
    (item) => item.domainType === 'IDENTITY' && item.configState !== 'COMPLETE'
  );

  const completedActivityDataSources = sources.filter(
    (item) => item.domainType !== 'IDENTITY' && item.configState === 'COMPLETE'
  );
  const incompleteActivityDataSources = sources.filter(
    (item) => item.domainType !== 'IDENTITY' && item.configState !== 'COMPLETE'
  );

  const totalCompleteConnections =
    completedIdentityConnections.length + completedActivityDataSources.length;
  const totalIdentityConnections =
    completedIdentityConnections.length + incompleteIdentityConnections.length;
  const totalActivityConnections =
    completedActivityDataSources.length + incompleteActivityDataSources.length;

  if (!tenant) {
    return null;
  }

  return (
    <PageLayout
      pageViewEvent={{ page: 'Source connections dashboard' }}
      header={
        <Header
          title="Source connections dashboard"
          icon={<Icon iconImage={IconImage.databases} />}
          noSearch
        />
      }
      loading={loading}
    >
      <HStack justify="space-between">
        <Text lineHeight={5} fontWeight="600">
          There are {totalCompleteConnections} connections between harpin AI and {tenant.name}.
        </Text>

        <ConnectNewSystemButton variant="outline" />
      </HStack>

      <Stack spacing={6} mt={6}>
        <SectionContainer
          header={
            <HStack justify="space-between">
              <Text fontSize="md" lineHeight={5} fontWeight="bold">
                Identity data sources
              </Text>
              <HStack>
                {incompleteIdentityConnections?.length > 0 && (
                  <HStack>
                    <Text fontSize="xs" fontWeight="semibold" color="gray.600">
                      {incompleteIdentityConnections?.length ?? 0} incomplete
                    </Text>
                    <Circle size="1" bg="gray.600" />
                  </HStack>
                )}
                <Text fontSize="xs" fontWeight="semibold" color="gray.600">
                  {completedIdentityConnections?.length ?? 0} connections
                </Text>
              </HStack>
            </HStack>
          }
        >
          {totalIdentityConnections > 0 && (
            <SimpleGrid columns={3} spacing={5} mt={5}>
              {sources
                .filter((item) => item.domainType === 'IDENTITY')
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((source) => (
                  <SourceConnectionHealthCard source={source} />
                ))}
            </SimpleGrid>
          )}
          {totalIdentityConnections === 0 && (
            <Text>There are currently no identity data sources connected.</Text>
          )}
        </SectionContainer>

        <SectionContainer
          header={
            <HStack justify="space-between">
              <Text fontSize="md" lineHeight={5} fontWeight="bold">
                Activity data sources
              </Text>
              <HStack>
                {incompleteActivityDataSources.length > 0 && (
                  <HStack>
                    <Text fontSize="xs" fontWeight="semibold" color="gray.600">
                      {incompleteActivityDataSources.length} incomplete{' '}
                    </Text>
                    <Circle size="1" bg="gray.600" />
                  </HStack>
                )}
                <Text fontSize="xs" fontWeight="semibold" color="gray.600">
                  {completedActivityDataSources.length} connections
                </Text>
              </HStack>
            </HStack>
          }
        >
          {totalActivityConnections > 0 && (
            <SimpleGrid columns={3} spacing={5} mt={5}>
              {sources
                .filter((item) => item.domainType !== 'IDENTITY')
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((source) => (
                  <SourceConnectionHealthCard
                    key={`source-system-connection-${source.id}`}
                    source={source}
                  />
                ))}
            </SimpleGrid>
          )}
          {totalActivityConnections === 0 && (
            <Text>There are currently no activity data sources connected.</Text>
          )}
        </SectionContainer>
      </Stack>
    </PageLayout>
  );
}
export default SourceConnectionsDashboard;
