import { chakra, HStack, Stack, Tooltip } from '@chakra-ui/react';
import Icon from '../../../../components/core/Icon/Icon';
import {
  IdentityAttributesWithComponentKeys,
  IdentityAttributeValue,
} from '../../../../lib/api-client/identity/model/Identity';
import { Nullable } from '../../../../lib/model/common';
import {
  getIdentityAttributeScoreLabel,
  getRecordScoreIcon,
} from '../../../../lib/utils/scoring-utils';
import ProfileDataRecordScoreBadge from '../ProfileDataRecordScoreBadge/ProfileDataRecordScoreBadge';

function TooltipLine({
  score,
  type,
  label,
}: {
  type: IdentityAttributesWithComponentKeys;
  label: string;
  score?: Nullable<number>;
}) {
  return (
    <HStack spacing={1}>
      <Icon
        boxSize={4}
        iconImage={getRecordScoreIcon(score).icon}
        color={getRecordScoreIcon(score).color}
      />
      <chakra.span>
        {label} is {getIdentityAttributeScoreLabel(score, type).toLowerCase()}
      </chakra.span>
    </HStack>
  );
}

interface ProfileAttributeScoreBadgeProps {
  attribute: IdentityAttributesWithComponentKeys;
  attributeValue: IdentityAttributeValue;
}

export default function ProfileAttributeScoreBadge({
  attribute,
  attributeValue,
}: ProfileAttributeScoreBadgeProps) {
  const score = attributeValue.components?.[0]?.score;

  if (
    attribute !== 'emailAddress' &&
    attribute !== 'address' &&
    !attributeValue.components.some((value) => value.score != null)
  ) {
    return null;
  }

  if (attribute === 'name' && attributeValue.components.length > 0) {
    const lowestNameComponent = attributeValue.components
      .filter((value) => value.score != null)
      .sort((a, b) => a.score! - b.score!)[0];

    return (
      <Tooltip
        label={
          <Stack spacing={1} fontSize="xs" data-testid="NameTooltip">
            <chakra.span fontWeight="semibold" color="whiteAlpha.700">
              Score breakdown
            </chakra.span>
            {attributeValue.components[0].score != null && (
              <TooltipLine
                label="First name"
                type={attributeValue.components[0].type as any}
                score={attributeValue.components[0].score}
              />
            )}
            {attributeValue.components[1].score != null && (
              <TooltipLine
                label="Middle name"
                type={attributeValue.components[1].type as any}
                score={attributeValue.components[1].score}
              />
            )}
            {attributeValue.components[2].score != null && (
              <TooltipLine
                label="Last name"
                type={attributeValue.components[2].type as any}
                score={attributeValue.components[2].score}
              />
            )}
          </Stack>
        }
        shouldWrapChildren
        placement="top"
        hasArrow
      >
        <ProfileDataRecordScoreBadge
          score={lowestNameComponent.score}
          label={getIdentityAttributeScoreLabel(
            lowestNameComponent.score,
            lowestNameComponent.type as any
          )}
        />
      </Tooltip>
    );
  }

  return (
    <ProfileDataRecordScoreBadge
      score={score}
      label={getIdentityAttributeScoreLabel(score, attribute)}
    />
  );
}
