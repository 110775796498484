import { Text } from '@chakra-ui/react';
import { CellContext } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { SourceIdentityRecordSearchResponse } from '../../../../lib/api-client/identity/model/SourceIdentityRecordSearchResponse';
import { IdentityDataKeys } from '../../../../lib/api-client/identity/model/UpdateIdentityRecord';
import { formatPhoneNumber } from '../../../../lib/utils/format-utils';
import { isDefined } from '../../../../lib/utils/utils';

export default function PhoneCell({
  cellContext,
}: {
  cellContext: CellContext<SourceIdentityRecordSearchResponse, IdentityDataKeys | undefined>;
}) {
  const value = useMemo(() => {
    const v = cellContext.getValue();
    if (!v) {
      return null;
    }

    return isDefined(v.normalized) ? formatPhoneNumber(v.normalized) : '';
  }, [cellContext]);
  return <Text>{value}</Text>;
}
