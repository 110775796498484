/**
 * Format a number in a locale specific format
 *
 * @param value number to format
 * @param options whether or not to hide fractional digits
 * @param locale local to use, en-US default
 */
export function formatNumber(
  value: number,
  options?: Intl.NumberFormatOptions,
  locale: string = 'en-US'
): string {
  if (options) {
    return Intl.NumberFormat(locale, options).format(value);
  }

  return Intl.NumberFormat(locale).format(value);
}

/**
 * Format a number with a compact style
 * i.e. 7.7K, 7.7M, 7.7B where (K = thousand, M = million, B= billion, etc...)
 *
 * @param number
 */
export function formatCompactNumber(number: number) {
  if (number >= 1000 && number < 1_000_000) {
    return `${(number / 1000).toFixed(1)}K`;
  }
  if (number >= 1_000_000 && number < 1_000_000_000) {
    return `${(number / 1_000_000).toFixed(1)}M`;
  }
  if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
    return `${(number / 1_000_000_000).toFixed(1)}B`;
  }
  if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
    return `${(number / 1_000_000_000_000).toFixed(1)}T`;
  }

  return number.toFixed(0);
}

/**
 * Format bytes up to string format, B, KB, MB, GB
 * @param number
 */
export function formatBytes(number: number): string {
  let value: number;
  let units: string;

  if (number < 1024) {
    value = number;
    units = 'B';
  } else if (number < 1024 * 1024) {
    value = number / 1024;
    units = 'KB';
  } else if (number < 1024 * 1024 * 1024) {
    value = number / (1024 * 1024);
    units = 'MB';
  } else {
    value = number / (1024 * 1024 * 1024);
    units = 'GB';
  }

  return `${formatNumber(value, {
    useGrouping: false,
    maximumFractionDigits: 1,
  })} ${units}`;
}

/**
 * Format a number as a currency
 *
 * @param value number to format
 * @param currency currency string
 * @param hideFractionDigits true hides fractional digits, false shows them
 * @param locale locale to use, en-US by default
 */
export function formatCurrency(
  value: number,
  currency: string,
  hideFractionDigits = false,
  locale: string = 'en-US'
): string {
  if (hideFractionDigits) {
    return Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  }

  return Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(value);
}

function formatPercentageInternal(value: number | undefined, maximumFractionDigits: number) {
  if (value == null) {
    return '';
  }

  return formatNumber(value, {
    useGrouping: false,
    style: 'percent',
    maximumFractionDigits,
  });
}

export function formatPercentage(
  num?: number,
  maximumFractionDigits = 0,
  displayFormat: 'default' | 'closePrecision' = 'default'
) {
  if (displayFormat === 'closePrecision' && num != null && maximumFractionDigits > 0) {
    const isNegative = num < 0;
    const percentage = Math.abs(num * 100);
    const precision = 1 / 10 ** maximumFractionDigits;

    if (percentage !== 0 && percentage < precision) {
      const percentageStr = `${precision * (isNegative ? -1 : 1)}`;
      const formattedStr = percentageStr.substring(
        0,
        percentageStr.indexOf('.') + 1 + maximumFractionDigits
      );
      return `<${formattedStr}%`;
    }

    const ceiling = Math.ceil(percentage);
    const diff = ceiling - percentage;

    if (diff !== 0 && diff < precision) {
      const percentageStr = `${(ceiling - precision) * (isNegative ? -1 : 1)}`;
      const formattedStr = percentageStr.substring(
        0,
        percentageStr.indexOf('.') + 1 + maximumFractionDigits
      );
      return `>${formattedStr}%`;
    }
  }

  return formatPercentageInternal(num, maximumFractionDigits);
}
