import * as Sentry from '@sentry/react';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  UserData,
} from 'amazon-cognito-identity-js';
import { NewPasswordChallengeRequest } from './model/NewPasswordChallengeRequest';
import { SignInRequest } from './model/SignInRequest';

let currentUser: CognitoUser;

/**
 * AuthenticationClient is a set of functions which manages calling the cognito APIs.
 * The Cognito SDK is stateful, so using the API requires the same instance of CognitoUser to
 * work correctly.
 */
const AuthenticationClient = {
  signIn(
    request: SignInRequest
  ): Promise<CognitoUserSession | 'newPasswordRequired' | 'totpRequired'> {
    const authenticationDetails = new AuthenticationDetails({
      Username: request.email,
      Password: request.password,
    });
    const userPool = new CognitoUserPool({
      UserPoolId: request.userPool.id,
      ClientId: request.userPool.clientId,
    });
    const cognitoUser = new CognitoUser({
      Username: request.email,
      Pool: userPool,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (session) => {
          currentUser = cognitoUser;
          resolve(session);
        },
        onFailure: (err) => reject(err),
        newPasswordRequired: () => {
          currentUser = cognitoUser;
          resolve('newPasswordRequired');
        },
        totpRequired: () => {
          currentUser = cognitoUser;
          resolve('totpRequired');
        },
      });
    });
  },

  handleNewPasswordChallenge(request: NewPasswordChallengeRequest): Promise<CognitoUserSession> {
    if (!currentUser) {
      return Promise.reject();
    }

    return new Promise((resolve, reject) => {
      currentUser.completeNewPasswordChallenge(request.newPassword, null, {
        onSuccess: (session) => resolve(session),
        onFailure: reject,
      });
    });
  },

  verifySession(userPoolId: string, clientId: string): Promise<CognitoUserSession> {
    const userPool = new CognitoUserPool({
      UserPoolId: userPoolId,
      ClientId: clientId,
    });

    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();

      if (!cognitoUser) {
        reject();
        return;
      }
      currentUser = cognitoUser;

      currentUser.getSession((error: any, session: CognitoUserSession) => {
        if (error) {
          reject(error);
          return;
        }

        resolve(session);
      });
    });
  },

  signOut(): Promise<void> {
    return new Promise((resolve) => {
      Sentry.configureScope((scope) => scope.setUser(null));

      if (!currentUser) {
        resolve();
        return;
      }

      currentUser.signOut(() => resolve());
    });
  },

  forgotPassword(userPoolId: string, clientId: string, email: string): Promise<void> {
    const userPool = new CognitoUserPool({
      UserPoolId: userPoolId,
      ClientId: clientId,
    });

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    return new Promise<void>((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: () => {
          resolve();
        },
        onFailure: (err) => {
          reject(err);
        },
        inputVerificationCode: () => {
          resolve();
        },
      });
    });
  },

  confirmNewPassword(
    userPoolId: string,
    clientId: string,
    email: string,
    verificationCode: string,
    newPassword: string
  ): Promise<void> {
    const userPool = new CognitoUserPool({
      UserPoolId: userPoolId,
      ClientId: clientId,
    });

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    return new Promise<void>((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: () => {
          resolve();
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  },

  associateSoftwareToken(): Promise<string> {
    if (!currentUser) {
      return Promise.reject();
    }

    return new Promise<string>((resolve, reject) => {
      currentUser.associateSoftwareToken({
        associateSecretCode: (secretCode) => {
          resolve(secretCode);
        },
        onFailure: reject,
      });
    });
  },

  verifySoftwareToken(totp: string): Promise<void> {
    if (!currentUser) {
      return Promise.reject();
    }

    return new Promise<void>((resolve, reject) => {
      currentUser.verifySoftwareToken(totp, 'MFA device', {
        onSuccess: () => resolve(),
        onFailure: reject,
      });
    });
  },

  sendMFACode(code: string): Promise<CognitoUserSession> {
    if (!currentUser) {
      return Promise.reject();
    }

    return new Promise((resolve, reject) => {
      currentUser.sendMFACode(
        code,
        {
          onSuccess: (session) => resolve(session),
          onFailure: reject,
        },
        'SOFTWARE_TOKEN_MFA'
      );
    });
  },

  enableSoftwareTokenMfa(enabled: boolean): Promise<void> {
    if (!currentUser) {
      return Promise.reject();
    }
    return new Promise<void>((resolve, reject) => {
      currentUser.setUserMfaPreference(
        null,
        { PreferredMfa: enabled, Enabled: enabled },
        (err, result) => {
          if (err || result !== 'SUCCESS') {
            reject(err);
            return;
          }

          resolve();
        }
      );
    });
  },

  getUserData(params?: { bypassCache?: boolean }): Promise<UserData | undefined> {
    return new Promise((resolve, reject) => {
      currentUser.getUserData((err, data) => {
        if (err) {
          reject(err);
          return;
        }

        resolve(data);
      }, params);
    });
  },

  changePassword(oldPassword: string, newPassword: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      currentUser.changePassword(oldPassword, newPassword, (err, result) => {
        if (err || result !== 'SUCCESS') {
          reject(err);
          return;
        }

        resolve();
      });
    });
  },
  getUserDetails(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      currentUser.getUserAttributes((err, result) => {
        if (err) {
          reject(err);
          return;
        }

        resolve(result);
      });
    });
  },
  updateProfile(firstName: string, lastName: string): Promise<CognitoUserSession> {
    const userFirstName = new CognitoUserAttribute({
      Name: 'given_name',
      Value: `${firstName}`,
    });
    const userLastName = new CognitoUserAttribute({
      Name: 'family_name',
      Value: `${lastName}`,
    });
    return new Promise<any>((resolve, reject) => {
      currentUser.updateAttributes([userFirstName, userLastName], (err, result) => {
        if (err) {
          reject(err);
          return;
        }

        resolve(result);
      });
    });
  },
};

export default AuthenticationClient;
