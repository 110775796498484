import { Page } from '../../model/common/Page';
import ApiClient from '../ApiClient';
import { CreateDataSource } from './model/CreateDataSource';
import { CreateExportJob } from './model/CreateExportJob';
import { DataSource, DataSourceProperties } from './model/DataSource';
import { DataSourceConfig } from './model/DataSourceConfig';
import { ExportJobDetails } from './model/ExportJobDetails';
import { ExternalDataModel } from './model/ExternalDataModel';
import { SourceMergeRules } from './model/MergeRules';
import { PublishEventRecord } from './model/PublishEventRecord';
import { SourceTypes } from './model/SourceTypes';
import { UpdateDataSource } from './model/UpdateDataSource';
import { UpdateSecret } from './model/UpdateSecret';
import { Upload } from './model/Upload';
import { UploadPath } from './model/UploadPath';
import { WebHookItems } from './model/webHook';
import { SourceSystemConfigAttribute } from './sources.model';

const SourceClient = {
  createExportJob(sourceId: string, dto: CreateExportJob): Promise<ExportJobDetails> {
    return ApiClient.post<any>(`/sources/${sourceId}/exports`, dto).then((res) => res.data);
  },

  getDataSources(): Promise<Pick<Page<DataSource>, 'content'>> {
    return ApiClient.get<Pick<Page<DataSource>, 'content'>>('/sources').then((res) => res.data);
  },

  getDataSource(
    sourceId: string,
    params?: {
      includeRecommendedAttributeMappings?: boolean;
      uploadId?: string;
    }
  ): Promise<DataSource> {
    const urlSearchParams = new URLSearchParams();
    if (params?.includeRecommendedAttributeMappings) {
      urlSearchParams.append(
        'includeRecommendedAttributeMappings',
        `${params.includeRecommendedAttributeMappings}`
      );
    }

    if (params?.uploadId) {
      urlSearchParams.append('uploadId', `${params.uploadId}`);
    }

    return ApiClient.get<DataSource>(`/sources/${sourceId}`, { params: urlSearchParams }).then(
      (res) => res.data
    );
  },

  updateDataSource(sourceId: string, dto: UpdateDataSource): Promise<DataSource> {
    return ApiClient.put<DataSource>(`/sources/${sourceId}`, dto).then((res) => res.data);
  },

  updateDataSourceProperties(
    sourceId: string,
    dto: Partial<DataSourceProperties>
  ): Promise<DataSourceProperties> {
    return ApiClient.patch<DataSourceProperties>(`/sources/${sourceId}/properties`, dto).then(
      (res) => res.data
    );
  },

  updateSecret(sourceId: string, dto: UpdateSecret): Promise<void> {
    return ApiClient.put<any>(`/sources/${sourceId}/secret`, dto)
      .then((res) => res.data)
      .then(() => {
        // do nothing
      });
  },

  deleteDataSource(sourceId: string): Promise<void> {
    return ApiClient.delete<DataSource>(`/sources/${sourceId}`).then(() => {
      // do nothing
    });
  },

  getDataSourceConfig(sourceId: string): Promise<DataSourceConfig> {
    return ApiClient.get<DataSourceConfig>(`/sources/${sourceId}/config-url`).then(
      (res) => res.data
    );
  },

  getSourceTypes(): Promise<SourceTypes> {
    return ApiClient.get<SourceTypes>(`/source-types`).then((res) => res.data);
  },

  setDataSourceEnabled(sourceId: string, enabled: boolean): Promise<DataSource> {
    return ApiClient.put<DataSource>(`/sources/${sourceId}/enabled`, {
      enabled,
    }).then((res) => res.data);
  },

  createDataSource(createDataSource: CreateDataSource): Promise<DataSource> {
    return ApiClient.post<DataSource>(`/sources`, createDataSource).then((res) => res.data);
  },

  createSourceUpload(sourceId: string, fileName: string): Promise<UploadPath> {
    return ApiClient.post<UploadPath>(`/sources/${sourceId}/uploads`, {
      fileName,
    }).then((res) => res.data);
  },

  updateSourceUploadStatus(
    sourceId: string,
    uploadId: string,
    status: 'importRequested' | 'testImportRequested'
  ): Promise<Upload> {
    return ApiClient.put<Upload>(`/sources/${sourceId}/uploads/${uploadId}/status`, {
      status,
    }).then((res) => res.data);
  },

  getSourceUpload(sourceId: string, uploadId: string): Promise<Upload> {
    return ApiClient.get<Upload>(`/sources/${sourceId}/uploads/${uploadId}`).then(
      (res) => res.data
    );
  },

  getSourceUploads(sourceId: string): Promise<Pick<Page<Upload>, 'content'>> {
    return ApiClient.get<Pick<Page<Upload>, 'content'>>(`/sources/${sourceId}/uploads`).then(
      (res) => res.data
    );
  },

  getTestRecordsFromSource(sourceId: string, sampleId?: string): Promise<Page<PublishEventRecord>> {
    const urlSearchParams = new URLSearchParams();
    if (sampleId) {
      urlSearchParams.append('sampleId', sampleId);
    }
    return ApiClient.get(`/sources/${sourceId}/tests`, { params: urlSearchParams }).then(
      (res) => res.data
    );
  },

  updateSourceMergeRules(sourceId: string, rules: SourceMergeRules): Promise<ExternalDataModel> {
    return ApiClient.put<ExternalDataModel>(`/sources/${sourceId}/merge-rules`, rules).then(
      (res) => res.data
    );
  },

  getWebhookById(sourceId: string, webhookId: string): Promise<WebHookItems> {
    return ApiClient.get<WebHookItems>(`/sources/${sourceId}/webhooks/${webhookId}`).then(
      (res) => res.data
    );
  },

  createWebhook(sourceId: string, dto: WebHookItems): Promise<any> {
    return ApiClient.post<any>(`/sources/${sourceId}/webhooks`, dto).then((res) => res.data);
  },

  deleteWebhook(sourceId: string, webhookId: string): Promise<void> {
    return ApiClient.delete<WebHookItems>(`/sources/${sourceId}/webhooks/${webhookId}`).then(() => {
      // do nothing
    });
  },

  updateWebhook(sourceId: string, dto: WebHookItems, webhookId: string): Promise<any> {
    return ApiClient.put<WebHookItems>(`/sources/${sourceId}/webhooks/${webhookId}`, dto).then(
      (res) => res.data
    );
  },

  async updateSourceSystemConfigurationAttributes(
    sourceId: string,
    dto: SourceSystemConfigAttribute[]
  ): Promise<SourceSystemConfigAttribute[]> {
    return ApiClient.put<SourceSystemConfigAttribute[]>(
      `/sources/${sourceId}/config-attributes`,
      dto
    ).then((res) => res.data);
  },

  async copyAttributeMappings(sourceId: string, fromSourceId: string): Promise<any[]> {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('fromSourceId', fromSourceId);
    return ApiClient.put<any[]>(`/sources/${sourceId}/attribute-mappings`, undefined, {
      params: urlSearchParams,
    }).then((res) => res.data);
  },
};
export default SourceClient;
