import {
  chakra,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Stack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReportExecutionPredicate } from '../../../../lib/api-client/reports/report.model';

function getControlValues(
  options: { label: string; value: string }[],
  predicateValue?: ReportExecutionPredicate
) {
  if (!predicateValue) {
    return null;
  }

  const value = options.find((option) => option.value === `${predicateValue.arguments?.[0] ?? ''}`);

  return {
    value: value?.value ?? 'CUSTOM',
    filterValue: predicateValue.arguments?.[0] ?? '',
  };
}

interface RelativeDaysQueryFilterProps {
  label: string;
  customLabel?: string;
  helperText?: string;
  columnName: string;
  index: number;
  options?: { label: string; value: string }[];
  predicateValue?: ReportExecutionPredicate;
}

const defaultOptions = [
  { label: '7 days or more', value: '7' },
  { label: '14 days or more', value: '14' },
  { label: '30 days or more', value: '30' },
  { label: '60 days or more', value: '60' },
  { label: '90 days or more', value: '90' },
  { label: '365 days or more', value: '365' },
];

export default function RelativeDaysQueryFilter({
  label,
  customLabel = 'Custom count',
  helperText,
  columnName,
  index,
  options = defaultOptions,
  predicateValue,
}: RelativeDaysQueryFilterProps) {
  const controlValues = getControlValues(options, predicateValue);
  const [showCustom, setShowCustom] = useState(controlValues?.value === 'CUSTOM');
  const [listValue, setListValue] = useState(controlValues?.value);
  const [filterValue, setFilterValue] = useState(controlValues?.filterValue ?? '');
  const form = useFormContext();
  const field = `filters[${index}]`;

  const buildFormValue = (value: string) => `${columnName}.GREATER_THAN_OR_EQUAL.${value}`;

  useEffect(() => {
    if (predicateValue && predicateValue.arguments != null) {
      form.setValue(field, buildFormValue(predicateValue.arguments[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack w="full">
      <FormControl>
        <FormLabel>{label}</FormLabel>
        <Select
          placeholder="All"
          value={listValue}
          onChange={(event) => {
            const { value } = event.target;
            setListValue(value);
            if (event.target.value === 'CUSTOM') {
              setFilterValue('');
              form.setValue(field, '');
              setShowCustom(true);
            } else if (value) {
              form.setValue(field, buildFormValue(value));
              setShowCustom(false);
            } else {
              form.setValue(field, '');
            }
          }}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
          <option value="CUSTOM">{customLabel}</option>
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
      {showCustom && (
        <SimpleGrid columns={2} gap={4} alignItems="center">
          <Input
            w="full"
            type="number"
            placeholder="Enter a number"
            value={filterValue}
            onChange={(event) => {
              const { value } = event.target;
              setFilterValue(event.target.value);
              form.setValue(field, buildFormValue(value));
            }}
          />
          <chakra.span>days or more</chakra.span>
        </SimpleGrid>
      )}
    </Stack>
  );
}
