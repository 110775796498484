import {
  Box,
  Button,
  chakra,
  Code,
  HStack,
  IconButton,
  Table,
  TableContainer,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBoolean,
} from '@chakra-ui/react';
import React, { CSSProperties, useState } from 'react';
import Alert from '../../../components/core/Alert/Alert';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import { useDataModel } from '../../../lib/api-client/data-model/DataModelData';
import { DomainModel } from '../../../lib/api-client/data-model/data-model.model';
import { DataSourceDomainTypes } from '../../../lib/api-client/sources/model/DataSourceDomainTypes';
import {
  TestIngestionAttribute,
  TestIngestionRecord,
} from '../../../lib/api-client/sources/sources.model';

const truncateStyle: CSSProperties = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: 0,
  minWidth: '357px',
};

interface TestMappingTabProps {
  mappings: (TestIngestionAttribute & { label: string })[];
}

function TestMappingTab({ mappings }: TestMappingTabProps) {
  const [showEmpty, setShowEmpty] = useBoolean(false);
  const hasEmptyValues = !!mappings.find((v) => v.value == null);

  if (mappings.length < 1) {
    return <div />;
  }

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead bgColor="gray.100">
          <Tr>
            <Th>Source System Attribute</Th>
            <Th>Harpin ai Data Field</Th>
            <Th>Field Value</Th>
          </Tr>
        </Thead>
        <Tbody>
          {mappings &&
            mappings
              .filter((a) => a.value != null || showEmpty)
              .map((attribute, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <Tr key={index}>
                  <Td style={truncateStyle}>{attribute.sourceAttribute ?? ''}</Td>
                  <Td style={truncateStyle}>
                    {attribute?.persistedValueName
                      ? `${attribute.label} (${attribute.persistedValueName ?? ''})`
                      : attribute.label}
                  </Td>
                  {attribute?.value != null ? (
                    <Td style={truncateStyle}>{`${attribute.value}`}</Td>
                  ) : (
                    <Td style={truncateStyle} textColor="gray.400">
                      Not on record
                    </Td>
                  )}
                </Tr>
              ))}
        </Tbody>
      </Table>
      <Button
        mt={2}
        variant="ghost"
        onClick={setShowEmpty.toggle}
        disabled={!hasEmptyValues}
        rightIcon={
          <Icon
            iconImage={showEmpty ? IconImage.accordionUp : IconImage.accordionDown}
            boxSize={4}
          />
        }
      >
        {showEmpty ? 'Hide' : 'Show'} empty fields
      </Button>
    </TableContainer>
  );
}

interface JsonBlockProps {
  data: any;
}

function JsonBlock({ data }: JsonBlockProps) {
  const jsonString = JSON.stringify(data, null, 2);
  return <pre>{jsonString}</pre>;
}

interface TestDataMappingsSectionProps {
  records: TestIngestionRecord[];
}

function TestDataMappingsSection({ records }: TestDataMappingsSectionProps) {
  const totalRecords = records.length;
  const { data } = useDataModel();
  let domainDataAttributeLength = -1;
  const [currentPage, setCurrentPage] = useState(0);

  const currentRecord: any = records[currentPage];
  const activeTabs = [
    'CONSUMER_EVENT',
    'IDENTITY',
    'TRANSACTION',
    'TRANSACTION_LINE_ITEM',
    'LOYALTY_PROGRAM',
  ];
  const currentTab: any = activeTabs.filter((tab) => {
    const domainData = currentRecord?.domainData[tab as DataSourceDomainTypes];
    if (domainData?.attributes?.length > 0 || domainData?.errors !== null) {
      domainDataAttributeLength += domainData?.attributes?.length ?? null;
      if (domainData?.attributes?.length === 0) {
        domainDataAttributeLength = -1;
      }
      return tab;
    }
    return null;
  });

  if (!data) {
    return null;
  }

  const domainModel = Object.values(data.domains).reduce((prev, value) => {
    const updated = { ...prev };
    updated[value.id] = value;
    return updated;
  }, {} as Record<string, DomainModel>);

  const newArray = [];
  const customObject = { ...currentRecord?.sourceData?.event?.data } ?? {};
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in customObject) {
    newArray.push({
      canonicalAttribute: key,
      sourceAttribute: key,
      value: currentTab.map(
        (tab: string) =>
          currentRecord?.domainData[tab as string]?.attributes?.map(
            (rData: TestIngestionAttribute) => rData.canonicalAttribute === key
          )?.value ?? null
      ),
      label: currentTab.map(
        (tab: string) =>
          domainModel[tab as DataSourceDomainTypes].attributes.find(
            (metadata) => metadata.id === key
          )?.displayName ?? key.charAt(0).toUpperCase() + key.slice(1)
      ),
    });
  }

  const dataError = currentTab.flatMap((tab: string) => {
    const domainData = currentRecord?.domainData[tab];
    return domainData?.errors;
  });

  return (
    <>
      <SectionContainer
        mt="6"
        header={
          <HStack justify="space-between">
            <Text fontSize="md" lineHeight={5} fontWeight="bold">
              Data mappings
            </Text>
            <HStack>
              <Box>
                <IconButton
                  aria-label="Previous record"
                  disabled={currentPage === 0}
                  onClick={() => setCurrentPage(Math.max(currentPage - 1, 0))}
                  icon={<Icon iconImage={IconImage.chevronBack} boxSize={4} />}
                />
              </Box>
              <chakra.span fontSize="xs" fontWeight="semibold">
                {totalRecords > 0 ? currentPage + 1 : 0} of {records.length}
              </chakra.span>
              <Box>
                <IconButton
                  aria-label="Next record"
                  icon={<Icon iconImage={IconImage.chevronForward} boxSize={4} />}
                  disabled={totalRecords === 0 || totalRecords === currentPage + 1}
                  onClick={() => setCurrentPage(Math.min(currentPage + 1, totalRecords))}
                />
              </Box>
            </HStack>
          </HStack>
        }
      >
        {dataError && dataError.some((error: string) => error != null) && (
          <Alert status="error" description={dataError} variant="subtle" />
        )}
        <Tabs variant="line" mt={2}>
          {records && (
            <TabPanels>
              {currentRecord && (
                <TestMappingTab
                  mappings={
                    domainDataAttributeLength >= 0
                      ? currentTab.flatMap((tab: string) => {
                          const domainData = currentRecord?.domainData[tab];
                          if (!domainData) {
                            return [];
                          }
                          return domainData.attributes.map((a: TestIngestionAttribute) => ({
                            ...a,
                            label:
                              domainModel[tab as DataSourceDomainTypes].attributes.find(
                                (metadata) => metadata.id === a.canonicalAttribute
                              )?.displayName ?? a.canonicalAttribute,
                          }));
                        })
                      : newArray.map((a: TestIngestionAttribute) => ({
                          ...a,
                          label: currentTab.map(
                            (tab: string) =>
                              domainModel[tab as DataSourceDomainTypes].attributes.find(
                                (metadata) => metadata.id === a.canonicalAttribute
                              )?.displayName ?? a.canonicalAttribute
                          ),
                        }))
                  }
                />
              )}
            </TabPanels>
          )}
        </Tabs>
      </SectionContainer>
      {currentRecord && (
        <SectionContainer title="Source data" mt="10" key={`source-data-${currentPage}`}>
          <Code
            w="full"
            mt={3}
            p="3"
            borderRadius="md"
            border="1px"
            borderColor="gray.200"
            fontSize="sm"
            maxH="200px"
            dir="column"
            overflowY="auto"
          >
            <JsonBlock data={currentRecord?.sourceData?.event?.data ?? {}} />
          </Code>
        </SectionContainer>
      )}
    </>
  );
}
export default TestDataMappingsSection;
