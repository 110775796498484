import { Tooltip, chakra } from '@chakra-ui/react';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';

function getProfileTypeIcon(pinType: any) {
  if (!pinType) {
    return { label: '', colorScheme: '' };
  }

  if (pinType === 'person') {
    return {
      icon: IconImage.user,
      message: 'This profile represents an individual consumer',
    };
  }

  if (pinType === 'unknown') {
    return {
      icon: IconImage.unknown,
      message: 'We are unable to identify this profile type using the identity data on record.',
    };
  }

  if (pinType === 'household') {
    return {
      icon: IconImage.household,
      message: 'This profile represents a household',
    };
  }

  if (pinType === 'business') {
    return {
      icon: IconImage.office,
      message: 'This profile represents a business',
    };
  }

  return {
    icon: IconImage.office,
    message: 'This profile represents a business',
  };
}

export default function ProfileTypeIcon({ pinType }: { pinType: any }) {
  const { icon, message } = getProfileTypeIcon(pinType);

  return (
    <Tooltip hasArrow placement="top" label={message}>
      <chakra.span>{icon && <Icon boxSize={5} iconImage={icon} />}</chakra.span>
    </Tooltip>
  );
}
