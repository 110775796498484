import { Stack, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useReportMetadataForReport } from '../../../lib/api-client/reports/ReportExecutionData';
import { ReportMetadata } from '../../../lib/api-client/reports/report.model';
import DataSourceQueryOption from '../components/ReportExplorer/DataSourceQueryOption';
import NumericalQueryFilter from '../components/ReportExplorer/NumericalQueryFilter';
import QueryLimit from '../components/ReportExplorer/QueryLimit';
import QuerySorts from '../components/ReportExplorer/QuerySorts';
import RelativeDaysQueryFilter from '../components/ReportExplorer/RelativeDaysQueryFilter';
import { buildReportParametersFromSearchParams } from '../components/ReportExplorer/ReportExplorer.utils';
import ReportExplorerFilterDrawer from '../components/ReportExplorer/ReportExplorerFilterDrawer';

function extractSourceListFromReportMetadata(reportMetadata?: ReportMetadata) {
  return (
    reportMetadata?.options
      ?.find((o) => o.name === 'sourceList')
      ?.arguments.find((l) => l.name === 'sourceIds')
      ?.values?.map((v) => ({ value: v.value, label: v.displayName })) ?? []
  );
}

function extractColumnsFromReportMetadata(reportMetadata?: ReportMetadata) {
  return (
    reportMetadata?.columns?.map((column) => ({
      label: column.displayName,
      value: column.name,
    })) ?? []
  );
}

export default function DuplicateSummaryReportFilterDrawer() {
  const [queryParams, setQueryParams] = useSearchParams();
  const { data: reportMetadata } = useReportMetadataForReport('DUPLICATES_ACROSS_SOURCES', true);
  const reportParams = buildReportParametersFromSearchParams(queryParams);
  const sortOptions = extractColumnsFromReportMetadata(reportMetadata);
  const sourceList = extractSourceListFromReportMetadata(reportMetadata);
  const form = useForm<any>({});

  return (
    <FormProvider {...form}>
      <ReportExplorerFilterDrawer
        onQueryParameterChange={(value) => {
          const newParams: any = { execute: 'true' };
          if (value.filters) {
            newParams.filters = value.filters.filter((filter: any) => !!filter);
          }
          if (value.limit) {
            newParams.limit = value.limit;
          }
          if (value.options) {
            newParams.options = value.options.filter((option: any) => !!option);
          }
          if (value.sort) {
            newParams.sort = value.sort.map((s: any) => `${s.column},${s.sort}`);
          }
          setQueryParams(newParams);
        }}
      >
        <Text fontWeight="bold" fontSize="xl" pt={4}>
          Query parameters
        </Text>
        <Stack spacing={4} pt={5} pb={6}>
          <DataSourceQueryOption
            index={1}
            name="sourceList"
            options={sourceList}
            optionValue={reportParams.options?.find((option) => option.optionName === 'sourceList')}
          />
          <NumericalQueryFilter
            index={2}
            columnName="totalSpend"
            label="Total all-time spend"
            predicateValue={reportParams.filter?.predicates?.find(
              (p) => p.columnName === 'totalSpend'
            )}
            customLabel="Custom amount"
            options={[
              { label: 'Less than $1,000', value: 'LESS_THAN.1000' },
              { label: '$1,000 or greater', value: 'GREATER_THAN_OR_EQUAL.1000' },
              { label: '$5,000 or greater', value: 'GREATER_THAN_OR_EQUAL.5000' },
              { label: '$10,000 or greater', value: 'GREATER_THAN_OR_EQUAL.10000' },
              { label: '$15,000 or greater', value: 'GREATER_THAN_OR_EQUAL.15000' },
              { label: '$20,000 or greater', value: 'GREATER_THAN_OR_EQUAL.20000' },
            ]}
          />
          <RelativeDaysQueryFilter
            index={3}
            label="Transaction frequency"
            helperText="This is the average number of days between transactions"
            columnName="totalSpend"
          />
        </Stack>
        <Text fontWeight="bold" fontSize="xl">
          Sort query results
        </Text>
        <Text>
          If multiple sort parameters are selected, they will be applied to the data report in the
          order in which they are selected.
        </Text>
        <Stack spacing={4} py={5}>
          <QuerySorts sortOptions={sortOptions} sortValue={reportParams.sortOrder} />
          <QueryLimit />
        </Stack>
      </ReportExplorerFilterDrawer>
    </FormProvider>
  );
}
