import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Alert from '../../../../../components/core/Alert/Alert';
import Icon from '../../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../../components/core/Icon/IconConfig';
import SourceClient from '../../../../../lib/api-client/sources/SourceClient';
import { useDataSources } from '../../../../../lib/api-client/sources/SourceData';
import { useCurrentDataSource } from '../../../context/CurrentDataSourceContext';

interface DuplicateMappingsButtonProps {
  onSuccess: () => void;
  onError: () => void;
}

export default function DuplicateMappingsButton({
  onSuccess,
  onError,
}: DuplicateMappingsButtonProps) {
  const { dataSource, refetchDataSource } = useCurrentDataSource();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: sources } = useDataSources();
  const filteredSources = (sources?.content || []).filter(
    (source) => source.id !== dataSource.id && source.attributeMappings.length > 1
  );
  const [selectedSource, setSelectedSource] = useState<string>('');
  const [isLoading, setLoading] = useState(false);

  const onApplyMappings = async () => {
    setLoading(true);
    try {
      await SourceClient.copyAttributeMappings(dataSource.id, selectedSource);
      await refetchDataSource();
      onSuccess();
    } catch (e) {
      onError();
    } finally {
      setLoading(false);
      onClose();
    }
  };

  useEffect(() => {
    if (!selectedSource && filteredSources.length > 0) {
      setSelectedSource(filteredSources[0].id);
    }
  }, [filteredSources, selectedSource]);

  if (filteredSources.length < 1) {
    return null;
  }

  return (
    <>
      <Button
        variant="ghost"
        size="sm"
        onClick={onOpen}
        rightIcon={<Icon iconImage={IconImage.duplicate} boxSize={4} color="action" />}
      >
        Duplicate mappings from another data source
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Duplicate mappings from another data source</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Expedite the mappings configuration for {dataSource.name} by applying the mappings
              already configured for another data source.
            </Text>
            <FormControl mt="6">
              <FormLabel>Sources</FormLabel>
              <Select
                defaultValue={selectedSource}
                onChange={(e) => setSelectedSource(e.target.value)}
              >
                {filteredSources.map((source) => (
                  <option key={source.id} value={source.id}>
                    {source.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Stack w="full" spacing="4">
              <Box>
                <Button onClick={onApplyMappings} justifySelf="flex-start" isLoading={isLoading}>
                  Apply mappings
                </Button>
              </Box>
              <Alert
                variant="subtle"
                status="warning"
                description="Note that this will replace all existing mappings."
              />
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
