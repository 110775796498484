import {
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import Alert from '../../../components/core/Alert/Alert';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import { useAuth } from '../../../context/AuthenticationContext';
import AdminTenantClient from '../../../lib/api-client/tenant/AdminTenantClient';
import { Tenant } from '../../../lib/api-client/tenant/model/Tenant';

interface SwitchTenantPageForm {
  tenantId: string;
  password: string;
}

const schema = yup.object({
  tenantId: yup.string().required('Tenant is required'),
  password: yup.string().required('Password is required'),
});

function SwitchTenantPage() {
  const [queryParams] = useSearchParams();
  const tenantId = queryParams.get('tenantId') ?? '';
  const [error, setError] = useState<string>();
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const navigate = useNavigate();
  const {
    tenantId: currentTenantId,
    tenantName: currentTenantName,
    signIn,
    currentUser,
  } = useAuth();
  const {
    handleSubmit,
    register,
    // resetField,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<SwitchTenantPageForm>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      tenantId,
    },
  });

  useEffect(() => {
    AdminTenantClient.getTenants().then(({ content }) => {
      setTenants(
        content
          .filter((c) => c.id !== currentTenantId)
          .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      );

      reset();
    });
  }, [currentTenantId, reset, tenantId]);

  const onSubmit: SubmitHandler<SwitchTenantPageForm> = async (data) => {
    setError(undefined);
    const tenant = tenants.find((t) => t.id === data.tenantId);
    try {
      if (!tenant || !currentUser) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('There was a issue during authentication. Please try again.');
      }

      await signIn({
        email: currentUser.emailAddress,
        password: data.password,
        userPool: {
          id: tenant.userPoolId,
          clientId: tenant.authClientId,
        },
      });
      navigate('/');
      window.location.reload();
    } catch (err: any) {
      setError(err.message);
    }
  };

  return (
    <PageLayout
      pageViewEvent={{ page: 'Switch Tenants' }}
      header={
        <Header
          icon={<Icon iconImage={IconImage.dataTransfer} />}
          title="Switch Tenants"
          back={{ label: 'Back to harpin AI tools', to: '/seviin-tools' }}
        />
      }
      data-testid="SwitchTenantPage"
    >
      <Text mb="10">Use this tool to switch over to easily switch over to a new tenant.</Text>
      <chakra.form w="md" onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={5} alignItems="start">
          {error && <Alert status="error" description={error} />}
          <FormControl>
            <FormLabel>Current tenant</FormLabel>
            <Input value={currentTenantName} disabled />
          </FormControl>
          <FormControl isInvalid={!!errors.tenantId}>
            <FormLabel>Tenant</FormLabel>
            <Select placeholder="Select a tenant" {...register('tenantId')}>
              {tenants.map((tenant) => (
                <option key={tenant.id} value={tenant.id}>
                  {tenant.name}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.tenantId?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.password}>
            <FormLabel>Password</FormLabel>
            <Input type="password" {...register('password')} />
            <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
          </FormControl>
          <Button isLoading={isSubmitting} type="submit">
            Switch tenant
          </Button>
          <Box fontSize="xs">
            NOTE: Switching tenant will navigate you to the new tenant dashboard
          </Box>
        </VStack>
      </chakra.form>
    </PageLayout>
  );
}
export default SwitchTenantPage;
