export enum SourceIdentityRecordGroup {
  EmailIssues = 'emailIssues',
  PhoneIssues = 'phoneIssues',
  NonConsumer = 'nonConsumer',
  // Business = 'business',
  // Household = 'household',
  NameIssues = 'nameIssues',
  Duplicates = 'duplicates',
  DateOfBirthIssues = 'dateOfBirthIssues',
  AddressIssues = 'addressIssues',
}

export function recordGroupToLabel(group: SourceIdentityRecordGroup | string) {
  switch (group) {
    case SourceIdentityRecordGroup.EmailIssues:
      return 'Email issues';
    case SourceIdentityRecordGroup.PhoneIssues:
      return 'Phone issues';
    case SourceIdentityRecordGroup.NameIssues:
      return 'Name issues';
    case SourceIdentityRecordGroup.NonConsumer:
      return 'Non-consumer data records';
    // case SourceIdentityRecordGroup.Business:
    //   return 'Businesses';
    // case SourceIdentityRecordGroup.Household:
    // return 'Households';
    case SourceIdentityRecordGroup.Duplicates:
      return 'Duplicates';
    case SourceIdentityRecordGroup.DateOfBirthIssues:
      return 'Date of birth issues';
    case SourceIdentityRecordGroup.AddressIssues:
      return 'Address issues';
    default:
      return group;
  }
}
