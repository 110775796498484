import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { PersistedValues } from '../../../lib/api-client/profile/model/Events';
import { formatDateString } from '../../../lib/utils/date-time-utils';
import Icon from '../Icon/Icon';
import { IconImage } from '../Icon/IconConfig';

export interface ActivityTrackerDetailsCardProps {
  eventName?: string;
  locationDetailedName?: string;
  locationName?: string;
  sourceId?: string;
  sourceRecordId?: string;
  sourceRecordTimestamp?: string;
  persistedValues?: PersistedValues;
}
export default function ActivityTrackerDetailsCard({
  eventName,
  sourceId,
  sourceRecordId,
  locationDetailedName,
  locationName,
  sourceRecordTimestamp,
  persistedValues,
}: ActivityTrackerDetailsCardProps) {
  const persistedValuesItem = persistedValues && Object.keys(persistedValues);
  return (
    <Box
      data-testid="PurchaseDetailsCard"
      width="710px"
      key={`${sourceId}-${sourceRecordId}`}
      border="1px solid"
      borderRadius="6px"
      borderColor="gray.100"
      shadow="md"
    >
      <Text px="5" mt="1.5" fontWeight="semibold" fontSize="12">
        {eventName}
      </Text>
      <Flex px="4">
        <HStack spacing={2.5}>
          <Icon iconImage={IconImage.location} boxSize={5} />
          <VStack spacing={-2} w="324px" alignItems="start">
            <Text fontSize="14" fontWeight="400" pt={1} pb={1}>
              {locationName}
            </Text>
            <Text fontSize="14" fontWeight="400" pt={1} pb={1}>
              {locationDetailedName}
            </Text>
          </VStack>
        </HStack>
        <HStack spacing={3}>
          <Icon iconImage={IconImage.calendar} />
          <VStack spacing="0" alignItems="start">
            <HStack spacing="1">
              {sourceRecordTimestamp && (
                <Text w="100px">
                  {formatDateString(sourceRecordTimestamp, 'MMM dd, yyyy h:mm a')}
                </Text>
              )}
            </HStack>
          </VStack>
        </HStack>
      </Flex>
      <Accordion defaultIndex={[1]} allowMultiple border="transparent" pb="4">
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <AccordionPanel paddingInlineStart="0" paddingInlineEnd="0">
                <Text px="12" fontWeight="semibold" fontSize="small" textColor="#4A5568">
                  Details
                </Text>
                <Box position="relative" pl="12" pr="4">
                  <Divider orientation="horizontal" />
                </Box>
                {persistedValues &&
                  persistedValuesItem?.map((item: any, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Text key={index} px="12" mt={2} fontWeight="400" fontSize="sm">
                      {item} : {persistedValues[item]}
                    </Text>
                  ))}
                <Text px="12" fontWeight="semibold" fontSize="small" textColor="#4A5568" mt={4}>
                  Source record ID
                </Text>
                <Box position="relative" pl="12" pr="4">
                  <Divider color="gray.300" orientation="horizontal" />
                </Box>
                <Text px="12" mt={2}>
                  {sourceRecordId}
                </Text>
              </AccordionPanel>
              <AccordionButton
                color="action"
                fontWeight="semibold"
                width="fit-content"
                pb="0"
                pt="0"
                pl="46px"
              >
                {isExpanded ? 'Hide details' : 'Show details'}
                <AccordionIcon
                  ml="2"
                  color="action"
                  borderRadius="50%"
                  borderColor="action"
                  borderWidth="thin"
                  boxSize="22px"
                />
              </AccordionButton>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </Box>
  );
}
