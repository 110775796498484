import { Box, Button, Text } from '@chakra-ui/react';
import ErrorContainer from '../../../core/ErrorContainer/ErrorContainer';

function SystemErrorDetails() {
  return (
    <ErrorContainer>
      <Box data-testid="SystemErrorDetails">
        <Text fontSize="3xl" lineHeight="9" fontWeight="bold">
          A system error occurred.
        </Text>
        <Text mt="2" fontSize="md" lineHeight="6">
          We could not load this page due to a system failure. Please try refreshing the page.
        </Text>
        <Button mt="6" onClick={() => window.location.reload()}>
          Refresh page
        </Button>
      </Box>
    </ErrorContainer>
  );
}
export default SystemErrorDetails;
