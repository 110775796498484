import { Text } from '@chakra-ui/react';
import React from 'react';
import SectionContainer from '../../../../../components/core/SectionContainer/SectionContainer';
import TrayConfigurationButton from '../../../../../components/features/connections/TrayConfigurationButton/TrayConfigurationButton';
import { Can } from '../../../../../context/AuthorizationContext';
import { useCurrentDataSource } from '../../../../sources/context/CurrentDataSourceContext';

function ManageExternalConfigSection() {
  const { dataSource, refetchDataSource } = useCurrentDataSource();
  return (
    <Can I="update" a="DataSource">
      <SectionContainer
        title="Connection configuration"
        titleFontSize="sm"
        variant="box"
        data-testid="ManageSecurityCredentialsSection"
      >
        <Text fontSize="sm" mb={8}>
          This connects harpin AI to the data source for seamless data ingestion and processing.
        </Text>
        <TrayConfigurationButton
          labelType="config"
          hasCredentials={dataSource.configured}
          sourceId={dataSource.id}
          onSuccess={() => () => refetchDataSource()}
        />
      </SectionContainer>
    </Can>
  );
}
export default ManageExternalConfigSection;
