import { useFetch, useSchema } from '@hazae41/xswr';
import ApiClient, { getApiClientSchema } from '../ApiClient';
import { IdentityMetrics, IdentityDataSourceMetrics } from './model/IdentityDataSourceMetrics';

const IdentitySourceMetricsClient = {
  getIdentitySources(): Promise<IdentityMetrics> {
    return ApiClient.get<IdentityMetrics>(`/identity-source-metrics`).then((res) => res.data);
  },
  getIdentitySourceMetrics(sourceId: string): Promise<IdentityDataSourceMetrics> {
    return ApiClient.get<IdentityDataSourceMetrics>(`/identity-source-metrics/${sourceId}`).then(
      (res) => res.data
    );
  },
};

export default IdentitySourceMetricsClient;

export function getSystemIdentitySourceMetricsSchema(enabled: boolean = true) {
  return getApiClientSchema<IdentityMetrics>(
    {
      method: 'get',
      url: `/identity-source-metrics`,
    },
    enabled
  );
}

export function useSystemIdentitySourceMetrics(enabled: boolean = true) {
  const query = useSchema(getSystemIdentitySourceMetricsSchema, [enabled]);
  useFetch(query);
  return query;
}

export function getIdentitySourceMetricsSchema(sourceId: string) {
  return getApiClientSchema<IdentityDataSourceMetrics>({
    method: 'get',
    url: `/identity-source-metrics/${sourceId}`,
  });
}

export function useIdentitySourceMetrics(sourceId: string) {
  const query = useSchema(getIdentitySourceMetricsSchema, [sourceId]);
  useFetch(query);
  return query;
}

export function getExportsMetricsSchema(sourceId: string) {
  return getApiClientSchema<any>({
    method: 'get',
    url: `/publish-metrics/${sourceId}`,
  });
}

export function useExportMetrics(sourceId: string) {
  const query = useSchema(getExportsMetricsSchema, [sourceId]);
  useFetch(query);
  return query;
}
