import { Box, HStack, Stack, Text, useToken } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { SelectedTimeSeriesFilter } from '../../../../components/shared/visualizations/TimeSeriesFilter/TimeSeriesFilter';
import { useGetIngestTimeSeries } from '../../../../lib/api-client/ingest-time-series/IngestTimeSeriesData';
import { getCurrentTimezone } from '../../../../lib/utils/date-time-utils';
import { formatCompactNumber, formatNumber } from '../../../../lib/utils/number-utils';
import { mapTimeSeriesMultiValueResponse } from '../../../../lib/utils/timeseries-utils';
import { useSourceColor } from '../SourceColorContext';

function DataIngestionChartTooltip({ payload, legend, metric, showSources }: any) {
  const sources = (payload as any[]).reduce((prev, current) => {
    const sourceId = current.dataKey.split('.')[3];
    // eslint-disable-next-line no-param-reassign
    prev[sourceId] = { color: current.color, value: current.value };
    return prev;
  }, {} as Record<string, any>);

  const total = !showSources
    ? payload[0]?.value ?? 0
    : payload[0]?.payload.metric[metric]?.summary?.value ?? 0;

  return (
    <Box bg="gray.50" p={2} shadow="md" boxShadow="md" borderRadius="lg">
      <Stack spacing="0">
        <Text fontSize="sm" fontWeight="bold">
          {formatNumber(total)} new data record events
        </Text>
        <Text>ingested during this time window</Text>
        {showSources &&
          Object.keys(legend).map((key) => (
            <HStack key={key}>
              <Box w="3" h="3" borderRadius="2px" bg={sources[key]?.color} />
              <Text as="span">
                <Text as="span" fontWeight="semibold">
                  {formatNumber(sources[key]?.value ?? 0)}
                </Text>{' '}
                from {legend[key]}
              </Text>
            </HStack>
          ))}
      </Stack>
    </Box>
  );
}

interface DataIngestionChartProps {
  height?: number | string;
  width?: number | string;
  selectedMetric: 'sum:recordEvents' | 'sum:recordEventsIngested' | 'sum:recordsDeleted';
  selectedTimeFilter: SelectedTimeSeriesFilter;
  showSources: boolean;
}

export default function DataIngestionChart({
  width = '100%',
  height = 400,
  selectedMetric,
  selectedTimeFilter,
  showSources,
}: DataIngestionChartProps) {
  const { getColorForSource } = useSourceColor();
  const [actionDark, gray600] = useToken('colors', ['actionDark', 'gray.400']);
  const [now] = useState(() => new Date());

  const timezone = getCurrentTimezone();
  const { interval } = selectedTimeFilter;
  const { data: ingestTimeSeriesData } = useGetIngestTimeSeries(
    selectedMetric,
    selectedTimeFilter.config.getStartDate(now),
    now,
    timezone,
    interval
  );

  if (!ingestTimeSeriesData) {
    return null;
  }

  const timeSeriesData = mapTimeSeriesMultiValueResponse(
    ingestTimeSeriesData,
    selectedTimeFilter.interval,
    showSources ? undefined : (m) => m.summary?.value ?? 0
  );
  const metric = selectedMetric.split(':')[1];

  return (
    <ResponsiveContainer width={width} height={height}>
      <BarChart
        data={timeSeriesData.data}
        margin={{
          top: 10,
          right: 0,
          left: -5,
          bottom: 5,
        }}
      >
        <XAxis dataKey="label" />
        <YAxis tickFormatter={(value) => formatCompactNumber(value).toString()} />
        <Tooltip
          content={
            <DataIngestionChartTooltip
              interval={selectedTimeFilter.interval}
              legend={ingestTimeSeriesData.legend}
              showSources={showSources}
              metric={metric}
            />
          }
        />
        {showSources ? (
          Object.keys(ingestTimeSeriesData.legend).map((key) => {
            const color = getColorForSource(key);
            const dataKey = `metric.${metric}.values.${key}`;

            return <Bar key={key} stackId="stack1" dataKey={dataKey} fill={color} />;
          })
        ) : (
          <Bar dataKey={`metric.${metric}`} radius={[5, 5, 0, 0]}>
            {timeSeriesData.data.map((entry, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Cell key={`cell-${index}`} fill={entry.isLastBucket ? gray600 : actionDark} />
            ))}
          </Bar>
        )}
      </BarChart>
    </ResponsiveContainer>
  );
}
