/* eslint-disable no-nested-ternary */
import { HStack, VStack, chakra } from '@chakra-ui/react';
import { formatNumber } from '../../../lib/utils/number-utils';
import Icon from '../Icon/Icon';
import { IconImage } from '../Icon/IconConfig';

interface TooltipProps {
  mostFrequentTagValue: string | number;
  count: number;
  scoreRange: number | string;
  identityType?: string;
}

export default function BubbleChartTooltip({
  mostFrequentTagValue,
  count,
  scoreRange,
  identityType,
}: TooltipProps) {
  const mostFrequentTagValueType =
    mostFrequentTagValue === null
      ? 'profiles'
      : mostFrequentTagValue === 'questionable'
      ? 'questionable profiles'
      : mostFrequentTagValue === 'certified'
      ? 'certified profiles'
      : mostFrequentTagValue === 'uncertified'
      ? 'not certified profiles'
      : 'profiles';

  return (
    <VStack
      alignItems="flex-start"
      spacing={1}
      bgColor="gray.800"
      p={2}
      boxShadow="md"
      borderRadius="6px"
    >
      <chakra.span textColor="white" fontWeight="bold">{`${formatNumber(
        count
      )} ${mostFrequentTagValueType}`}</chakra.span>
      {scoreRange === '0' || scoreRange === '100' ? (
        <VStack spacing={3} alignItems="self-start">
          <VStack alignItems="self-start" spacing={1}>
            <chakra.span textColor="white" fontWeight="normal">
              {' '}
              with a score of {scoreRange}
            </chakra.span>
            {identityType !== 'all' && (
              <chakra.span textColor="white" fontWeight="normal">
                that represent {identityType}
              </chakra.span>
            )}
          </VStack>
          <HStack spacing={1}>
            <Icon iconImage={IconImage.search} color="white" boxSize="4" />
            <chakra.span textColor="white" fontWeight="normal">
              Click on the bubble to explore profiles
            </chakra.span>
          </HStack>
        </VStack>
      ) : (
        <VStack spacing={3} alignItems="self-start">
          <VStack alignItems="self-start" spacing={1}>
            <chakra.span textColor="white" fontWeight="normal">
              with a score between {scoreRange}
            </chakra.span>
            {identityType !== 'all' && (
              <chakra.span textColor="white" fontWeight="normal">
                that represent a {identityType}
              </chakra.span>
            )}
          </VStack>
          <HStack spacing={1}>
            <Icon color="white" iconImage={IconImage.search} boxSize="4" />
            <chakra.span textColor="white" fontWeight="normal">
              Click on the bubble to explore profiles
            </chakra.span>
          </HStack>
        </VStack>
      )}
    </VStack>
  );
}
