import { Config } from './config';

const config: Config = {
  APP_ENV: 'dev',
  BASE_API_URL: 'https://api.sandbox.harpin.ai',
  HEADWAY_ACCOUNT_ID: '7wDO5J',
  SEVIIN_TENANT_ID: '',
  SESSION_TIMEOUT_SECS: 3600, // 60 mins
  SESSION_CROSS_TAB: true,
  SENTRY_IDLE_TIMEOUT_MS: 3000, // 3 secs
  UGLY_MODE_ENABLED: true,
  XSWR_DEFAULT_TIMEOUT_MS: 60000, // 1 min
  XSWR_MAX_TIMEOUT_MS: 120000, // 2 min
};

export default config;
