/* eslint-disable react/no-array-index-key */
import {
  Button,
  HStack,
  Stack,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import Alert from '../../../../components/core/Alert/Alert';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';
import { Can } from '../../../../context/AuthorizationContext';
import { useDataModel } from '../../../../lib/api-client/data-model/DataModelData';
import { AttributeDomainType } from '../../../../lib/api-client/data-model/data-model.model';
import { mapPrimaryDomainToAttributeDomain } from '../../../../lib/api-client/data-model/data-model.utils';
import { DataSource } from '../../../../lib/api-client/sources/model/DataSource';
import { DataSourceConnectionTypes } from '../../../../lib/api-client/sources/model/DataSourceConnectionType';
import { filterAttributeMappings } from '../../../../lib/api-client/sources/sources.utils';
import {
  DataSourceAttributeMultiValueMapping,
  groupAttributes,
} from '../../../sources/DataMappingPage/DataMappingPage.utils';
import SyncDirectionHelp from '../SyncDirectionHelp';

const truncateStyle: CSSProperties = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: '0',
};

function toMappingType(mappingType: string) {
  switch (mappingType) {
    case 'GENERATED_UUID':
      return 'Generated ID';
    case 'CONSTANT_VALUE':
      return 'Static';
    default:
      return 'Mapped';
  }
}

interface MappingTabProps {
  mappings: DataSourceAttributeMultiValueMapping[];
  direction: DataSourceConnectionTypes;
  hideMappingType?: boolean;
}
function MappingTab({ mappings, direction, hideMappingType }: MappingTabProps) {
  if (mappings.length < 1) {
    return (
      <Alert
        mt={6}
        status="warning"
        variant="subtle"
        description="No data mappings have been configured."
      />
    );
  }

  let iconImage = IconImage.arrowForward;
  if (direction === 'BOTH') {
    iconImage = IconImage.dataTransfer;
  } else if (direction === 'OUT') {
    iconImage = IconImage.arrowBack;
  }

  return (
    <Stack spacing="4">
      <SyncDirectionHelp syncDirection={direction} />
      <TableContainer>
        <Table variant="defaultWithDivider">
          <Thead bgColor="gray.100">
            <Tr>
              {!hideMappingType && <Th>Attribute type</Th>}
              <Th>Attribute source or value</Th>
              <Th>harpin AI Data Field</Th>
            </Tr>
          </Thead>
          <Tbody>
            {mappings.map((mapping, index) => (
              <Tr
                key={`${mapping.domainType}_${mapping.canonicalAttribute}_${index}`}
                borderBottom={index === mappings.length - 1 ? 0 : 1}
              >
                {!hideMappingType && (
                  <Td style={truncateStyle}>{toMappingType(mapping.mappingType)}</Td>
                )}
                {mapping.mappingType === 'CONSTANT_VALUE' && (
                  <Td style={truncateStyle}>{mapping.parameters?.value}</Td>
                )}
                {mapping.mappingType === 'GENERATED_UUID' && (
                  <Td style={truncateStyle}>Auto-generated unique ID</Td>
                )}
                {mapping.mappingType === 'SINGLE_SOURCE_ATTRIBUTE' && (
                  <Td style={truncateStyle}>
                    <HStack justify="space-between">
                      <Stack spacing={0}>
                        {mapping.sourceAttributes.map((s) => (
                          <span key={s.value}>{s.value}</span>
                        ))}
                      </Stack>
                      <Icon iconImage={iconImage} />
                    </HStack>
                  </Td>
                )}
                <Td style={truncateStyle}>
                  {mapping.metadata ? mapping.metadata.displayName : mapping.canonicalAttribute}
                  {mapping.persistedValueName && <Text>{mapping.persistedValueName}</Text>}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

interface DataMappingsSectionProps {
  source: DataSource;
}

function DataMappingsSection({ source }: DataMappingsSectionProps) {
  const { data } = useDataModel();
  const dataModel = data ?? { domains: [], normalizations: [] };

  const publishDomainMappings: AttributeDomainType[] = [];
  if (source.syncDirection !== 'IN') {
    // FIXME - SEV-3275 - assumes identity domain
    publishDomainMappings.push('EXPORT', 'TRANSACTION', 'TRANSACTION_LINE_ITEM');
  }

  const groupedAttributes = groupAttributes(source.attributeMappings);

  let coreMappings = filterAttributeMappings(
    groupedAttributes,
    ...(mapPrimaryDomainToAttributeDomain(dataModel)?.[source.domainType] ?? [])
  );

  const publishMappings = filterAttributeMappings(groupedAttributes, ...publishDomainMappings);
  if (source.syncDirection === 'OUT') {
    coreMappings = publishMappings;
  }

  return (
    <SectionContainer
      data-testid="DataMappingsSection"
      w="full"
      header={
        <HStack justify="space-between">
          <Text fontSize="md" lineHeight={5} fontWeight="bold">
            Data mappings
          </Text>
          <Can I="update" a="DataSource">
            <Button
              as={Link}
              to={`/sources/${source.id}/mappings`}
              data-testid="ConfigureDataMappingsButton"
              variant="ghost"
              rightIcon={<Icon iconImage={IconImage.edit} boxSize="4" />}
            >
              Edit data mappings
            </Button>
          </Can>
        </HStack>
      }
    >
      <Tabs variant="line">
        {source.syncDirection === 'BOTH' && (
          <TabList whiteSpace="nowrap" mb={4}>
            <Tab>Core mappings ({coreMappings.length})</Tab>
            <Tab>Additional publish mappings ({publishMappings.length})</Tab>
          </TabList>
        )}
        <TabPanels>
          <TabPanel p={0}>
            <MappingTab direction={source.syncDirection} mappings={coreMappings} />
          </TabPanel>
          <TabPanel p={0}>
            <MappingTab direction="OUT" mappings={publishMappings} hideMappingType />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </SectionContainer>
  );
}
export default DataMappingsSection;
