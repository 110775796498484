import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import Alert from '../../../components/core/Alert/Alert';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import {
  SearchProfileForm,
  searchProfileFormSchema,
} from '../../../components/shared/models/SearchProfileForm';
import { useAuth } from '../../../context/AuthenticationContext';
import IdentityClient from '../../../lib/api-client/identity/IdentityClient';
import { Identity } from '../../../lib/api-client/identity/model/Identity';
import Mixpanel from '../../../lib/mixpanel/Mixpanel';
import LocalStorageService from '../../../lib/services/LocalStorageService';
import SearchProfileResults from './SearchProfileResults';

interface SearchProfilePageState {
  searchData?: SearchProfileForm;
  searchLocation?: 'Header' | 'Dashboard';
  previousSearchRecord?: any;
  handleRecentSearching?: (props: any) => void;
}

function trackProfileSearch(searchLocation?: string) {
  let mixpanelProperties;
  if (searchLocation) {
    mixpanelProperties = {
      search_location: searchLocation,
    };
  }

  Mixpanel.track('Profile Search', mixpanelProperties);
}

export default function SearchProfilePage() {
  const {
    handleSubmit,
    setValue,
    register,
    watch,
    formState: { errors },
  } = useForm<SearchProfileForm>({
    resolver: yupResolver(searchProfileFormSchema),
    mode: 'onBlur',
  });
  const navigate = useNavigate();

  const location = useLocation();
  const state = location.state as SearchProfilePageState;
  const { tenantId } = useAuth();

  const [showResults, setShowResults] = useState(false);
  const [searchFields, setSearchFields] = useState<string[]>([]);
  const [showError, setShowError] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [searchResults, setSearchResults] = useState<Identity[]>([]);
  const [isLoading, setLoading] = useBoolean(false);
  watch((data) => {
    if (data) {
      setSearchFields(
        Object.entries(data)
          .filter(([, value]) => !!value?.trim())
          .map(([searchField]) => searchField)
      );
    }
  });

  const findResult = async (data: any) => {
    setLoading.on();
    sessionStorage.setItem('formData', JSON.stringify(data));
    await IdentityClient.search({
      filter: data,
    })
      .then((result) => {
        setShowWarning(false);
        setShowError(false);
        if (result.status === 'too_many') {
          setShowWarning(true);
          setShowResults(false);
        } else {
          setSearchResults(result.results);
          setShowResults(true);
          sessionStorage.setItem('profileData', JSON.stringify(result.results));
        }
      })
      .catch(() => {
        setShowWarning(false);
        setShowError(true);
      })
      .finally(setLoading.off);
    navigate(location.pathname, { replace: true });
  };

  const onSubmit: SubmitHandler<SearchProfileForm> = async (data) => {
    trackProfileSearch('Search Page');
    await findResult({
      firstName: data.firstName || undefined,
      lastName: data.lastName || undefined,
      emailAddress: data.emailAddress || undefined,
      phone: data.phone || undefined,
      accountId: data.accountId || undefined,
    });
    const item: any =
      LocalStorageService.getItem(`seviin.${tenantId}.search-recent-searches`) == null
        ? '[]'
        : LocalStorageService.getItem(`seviin.${tenantId}.search-recent-searches`);

    const value = JSON.parse(item);
    if (value.length >= 3) {
      value.unshift(data);
      const newArray = value.slice(0, 3);
      LocalStorageService.setItem(
        `seviin.${tenantId}.search-recent-searches`,
        JSON.stringify(newArray)
      );
    } else {
      value.unshift(data);
      LocalStorageService.setItem(
        `seviin.${tenantId}.search-recent-searches`,
        JSON.stringify(value)
      );
    }
  };

  useEffect(() => {
    if (state == null) {
      return;
    }

    if (state.searchData) {
      trackProfileSearch(state.searchLocation);
      void findResult(state.searchData);
      setValue('firstName', state.searchData?.firstName);
      setValue('lastName', state.searchData?.lastName);
      setValue('emailAddress', state.searchData?.emailAddress);
      setValue('phone', state.searchData?.phone);
      setValue('accountId', state.searchData?.accountId);
    }

    if ('previousSearchRecord' in state) {
      const searchData = sessionStorage.getItem('profileData') || '[]';
      const parsePdata = JSON.parse(searchData);
      setSearchResults(parsePdata);
      setShowResults(true);
      const formData = sessionStorage.getItem('formData');
      if (formData) {
        const dataValues = JSON.parse(formData);
        setValue('firstName', dataValues.firstName);
        setValue('lastName', dataValues.lastName);
        setValue('emailAddress', dataValues.emailAddress);
        setValue('phone', dataValues.phone);
        setValue('accountId', dataValues.accountId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <PageLayout
      pageViewEvent={{ page: 'Search profile page' }}
      header={
        <Header title="Find a consumer profile" icon={<Icon iconImage={IconImage.searchUser} />} />
      }
    >
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex alignItems="center">
            <Icon iconImage={IconImage.search} mr="3" />
            <Text>
              Search for a consumer identity to find all potential profile matches across your
              enterprise’s connected source systems. Enter as much information as possible in order
              to narrow and improve the accuracy of your search results.
            </Text>
          </Flex>
          <Flex py="12">
            <SimpleGrid columns={3} spacing={10} mr="10" w="full">
              <FormControl isInvalid={!!errors.firstName}>
                <FormLabel fontWeight="semibold">First name</FormLabel>
                <Input {...register('firstName')} />
                <FormErrorMessage>{errors?.firstName?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.lastName}>
                <FormLabel fontWeight="semibold">Last name</FormLabel>
                <Input {...register('lastName')} />
                <FormErrorMessage>{errors?.lastName?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.emailAddress}>
                <FormLabel fontWeight="semibold">Email address</FormLabel>
                <Input {...register('emailAddress')} />
                <FormErrorMessage>{errors?.emailAddress?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.phone}>
                <FormLabel fontWeight="semibold">Phone number</FormLabel>
                <Input {...register('phone')} />
                <FormErrorMessage>{errors?.phone?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.accountId}>
                <FormLabel fontWeight="semibold">Loyalty number</FormLabel>
                <Input {...register('accountId')} />
                <FormErrorMessage>{errors?.accountId?.message}</FormErrorMessage>
              </FormControl>
            </SimpleGrid>
          </Flex>
          <Box>
            <Button
              fontSize="md"
              py="4"
              px="52px"
              disabled={searchFields.length === 0}
              sx={{ _disabled: { opacity: 0.5 } }}
              isLoading={isLoading}
              type="submit"
            >
              Search
            </Button>
          </Box>
        </form>
        <Box mt="10">
          {showError && (
            <Alert
              status="error"
              title="A system error occurred."
              description="Please try again or come back later. We apologize for the inconvenience."
              closeable
            />
          )}
          {showWarning && (
            <Alert
              variant="subtle"
              status="warning"
              title="We found a high number of matches."
              description="Please refine your search terms or add additional ones to narrow your search results."
            />
          )}
          {showResults && (
            <SearchProfileResults results={searchResults} searchFields={searchFields} />
          )}
        </Box>
      </Box>
    </PageLayout>
  );
}
