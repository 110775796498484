import {
  HStack,
  Stat,
  StatLabel,
  StatNumber,
  StatProps,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { formatNumber as formatNumberFn } from '../../../../lib/utils/number-utils';
import { SystemStyleObjectWithTheming } from '../../../../styles/theme/theme';
import StatBadge from '../StatBadge/StatBadge';

interface SimpleStatProps extends StatProps {
  label?: string;
  value: number;
  badge?: {
    value: number;
    formattedValue?: string;
    color?: string;
  };
  formatNumber?: (value: number) => string;
  variant?: string;
}

function SimpleStat({
  label,
  value,
  badge,
  formatNumber = formatNumberFn,
  variant,
  ...rest
}: SimpleStatProps) {
  const styles = useMultiStyleConfig('SimpleStat', { variant });
  const { variant: badgeVariant, ...badgeStyles } = (styles.badge ||
    {}) as SystemStyleObjectWithTheming;

  const additionalStyles: any = {};
  if (badge?.color) {
    additionalStyles.bgColor = badge.color;
  }

  return (
    <Stat {...rest}>
      <HStack spacing={1}>
        <StatNumber fontSize="2xl" lineHeight={8}>
          {formatNumber(value)}
        </StatNumber>
        {badge && (
          <StatBadge
            sx={badgeStyles}
            variant={badgeVariant}
            rawValue={badge.value}
            formattedValue={badge.formattedValue}
            {...additionalStyles}
          />
        )}
      </HStack>
      {label && <StatLabel whiteSpace="nowrap">{label}</StatLabel>}
    </Stat>
  );
}
export default SimpleStat;
