import {
  Button,
  Center,
  chakra,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useXSWR } from '@hazae41/xswr';
import { CellContext, ColumnDef, createColumnHelper, PaginationState } from '@tanstack/react-table';
import { isAxiosError } from 'axios';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { ReactComponent as CyborgMachineLearning } from '../../../../assets/images/cyborg-machine-learning.svg';
import Alert from '../../../../components/core/Alert/Alert';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import DataExplorer from '../../../../components/shared/data-explorer/DataExplorer/DataExplorer';
import { useToast } from '../../../../hooks/use-toast';
import { downloadData } from '../../../../lib/api-client/ApiClient';
import ReportExecutionClient from '../../../../lib/api-client/reports/ReportExecutionClient';
import {
  getReport,
  startReportExecutionSchema,
  useGetReport,
  useReportMetadataForReport,
  useStartReportExecution,
} from '../../../../lib/api-client/reports/ReportExecutionData';
import { StartReportExecution } from '../../../../lib/api-client/reports/report.model';
import { Page } from '../../../../lib/model/common/Page';
import { formatDateString } from '../../../../lib/utils/date-time-utils';
import { formatCurrency, formatNumber } from '../../../../lib/utils/number-utils';
import ProfileTypeIcon from '../../../profile/components/ProfileTypeIcon/ProfileTypeIcon';
import {
  buildReportParametersFromSearchParams,
  generateDescriptions,
} from './ReportExplorer.utils';

const columnHelper = createColumnHelper<any>();

function ProfileLinkCell({ cellContext }: { cellContext: CellContext<any, string | undefined> }) {
  const value = cellContext.getValue();
  const { identityType } = cellContext.row.original;

  let link = '';
  if (value) {
    link = `/profiles/${value}`;
  }

  return (
    <HStack spacing={0.5} justify="space-between" as={Link} to={link} target="_blank" data-group>
      <HStack spacing={0.5}>
        {identityType && <ProfileTypeIcon pinType={identityType} />}
        <chakra.div
          textDecoration="none"
          _groupHover={{ color: 'action' }}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {value}
        </chakra.div>
      </HStack>
      <chakra.div>
        <Icon color="action" iconImage={IconImage.externalLink} boxSize={5} />
      </chakra.div>
    </HStack>
  );
}

function ReportLoading({ reportType }: { reportType: string }) {
  return (
    <Flex direction="column" h="full" flexFlow="column">
      <Center h="full">
        <VStack>
          <HStack>
            <Spinner color="action" />

            <Text fontSize="xl" fontWeight="bold">
              One moment while we load the full set of{' '}
              {reportType === 'IDENTITY_RECORDS' ? 'data records' : 'consumer profiles'} ...
            </Text>
          </HStack>
          <Text>
            Please remain on this screen to avoid disruption. If the{' '}
            {reportType === 'IDENTITY_RECORDS' ? 'data records' : 'consumer profiles'} don’t load
            within a few minutes, try refreshing.
          </Text>
          <CyborgMachineLearning />
        </VStack>
      </Center>
    </Flex>
  );
}

type SearchParams = {
  pagination: PaginationState & {
    nextToken?: string;
  };
};

const PAGE_SIZE = 100;

interface ReportExplorerProps {
  reportId: string;
  reportParams?: Omit<StartReportExecution, 'reportType'>;
  filterLabel?: string;
  filterDescriptions?: string[];
  dataLabel?: string;
  hasSecondaryExport?: boolean;
  children?: ReactNode;
}
export default function ReportExplorer({
  reportId: reportType,
  reportParams: defaultReportParams,
  filterLabel = 'Parameters',
  filterDescriptions,
  dataLabel,
  hasSecondaryExport,
  children,
}: ReportExplorerProps) {
  const modal = useDisclosure();
  const { make } = useXSWR();
  const [queryParams] = useSearchParams();
  const runOnLoad = queryParams.get('execute')?.toLowerCase() !== 'false' ?? true;

  const { data: reportMetadata } = useReportMetadataForReport(reportType, true);
  const [searchParameters, setSearchParameters] = useState<SearchParams>({
    pagination: {
      pageIndex: 0,
      pageSize: PAGE_SIZE,
    },
  });
  const [nextTokens, setNextTokens] = useState<(string | undefined)[]>([undefined]);

  const reportParams = {
    ...buildReportParametersFromSearchParams(queryParams),
    ...defaultReportParams,
    reportType,
  };
  const { data: reportExecution = { reportId: '' }, error: executionError } =
    useStartReportExecution(reportParams, runOnLoad);
  const { data: reportPage } = useGetReport(reportExecution.reportId, {
    nextToken: searchParameters.pagination.nextToken,
    pageSize: searchParameters.pagination.pageSize,
    enabled: !!reportExecution.reportId,
  });
  const [reportExecutionId, setReportExecutionId] = useState('');
  const executionErrorData = (executionError as any)?.response?.data?.error;

  const columns = useMemo(() => {
    if (reportMetadata) {
      return reportMetadata.columns.map((c) => {
        const columnDef: ColumnDef<unknown> = {
          header: c.displayName,
          meta: {
            hideActions: true,
          },
        };
        if (c.name === 'pin') {
          columnDef.minSize = 180;
          columnDef.cell = (cellContext: any) => <ProfileLinkCell cellContext={cellContext} />;
        } else if (c.dataType === 'DECIMAL') {
          columnDef.cell = ({ getValue }: any) => formatCurrency(getValue(), 'USD');
        } else if (c.dataType === 'TIMESTAMP') {
          columnDef.cell = ({ getValue }: any) => {
            const value = getValue();
            if (value != null) {
              return formatDateString(getValue(), 'M/dd/yyyy hh:mm:ss a');
            }
            return '';
          };
        } else if (c.dataType === 'DATE') {
          columnDef.cell = ({ getValue }: any) => {
            const value = getValue();
            if (value != null) {
              return formatDateString(getValue(), 'MM-dd-yyyy');
            }

            return '';
          };
        } else if (c.dataType === 'INTEGER') {
          columnDef.cell = ({ getValue }: any) => formatNumber(getValue());
        }
        return columnHelper.accessor(c.name, columnDef);
      });
    }

    return [];
  }, [reportMetadata]);

  const [pageUrl] = useState(window.location.href);
  const toast = useToast();

  useEffect(() => {
    if (!reportExecutionId && reportExecution.reportId && reportPage) {
      setReportExecutionId(reportExecution.reportId);
    }
  }, [reportPage, reportExecution, reportExecutionId]);

  useEffect(() => {
    if (reportPage) {
      if (nextTokens[searchParameters.pagination.pageIndex + 1] !== reportPage.nextToken) {
        const tokenCache = [...nextTokens];
        tokenCache[searchParameters.pagination.pageIndex + 1] = reportPage.nextToken;
        setNextTokens(tokenCache);
      }
    }
  }, [reportPage, searchParameters, nextTokens]);

  const exportData = async (includeSecondaryPii = false) => {
    toast({
      title: 'CSV is being prepared for export.',
      description: 'Hold tight. This may take a few min.',
    });

    const p = { ...reportParams, options: [...(reportParams.options ?? [])] };
    if (includeSecondaryPii) {
      if (!p.options) {
        p.options = [];
      }
      p.options.push({ optionName: 'includeSecondaryPii' });

      const startReportExecution = await make(startReportExecutionSchema(p)).fetch();
      if (startReportExecution) {
        const { data: reportExportExecution = { reportId: '' } } = startReportExecution;

        await new Promise<void>((resolve, reject) => {
          const intervalId = setInterval(async () => {
            const state = await make(
              getReport(reportExportExecution.reportId, { pageSize: 10 })
            ).fetch();
            if (isAxiosError(state?.error) && state?.error.response?.status !== 425) {
              reject();

              toast({
                title: 'There was a problem exporting your CSV.',
                status: 'error',
              });
              clearInterval(intervalId);
            }

            if (state?.data) {
              await ReportExecutionClient.exportReportData(reportExportExecution.reportId).then(
                (res) =>
                  downloadData(res, `${reportParams.reportType}_export_${new Date().getTime()}.csv`)
              );
              resolve();

              toast({
                title: 'CSV has been exported.',
                status: 'success',
              });
              clearInterval(intervalId);
            }
          }, 5000);
        });
      }

      return;
    }

    await ReportExecutionClient.exportReportData(reportExecution.reportId).then((res) =>
      downloadData(res, `${reportType}_export_${new Date().getTime()}.csv`)
    );
    toast({
      title: 'CSV has been exported.',
      status: 'success',
    });
  };

  const hasExecutionError = !!executionErrorData;

  if (!reportPage && runOnLoad && !hasExecutionError) {
    if (!reportExecutionId) {
      return <ReportLoading reportType={reportType} />;
    }
    return <ReportLoading reportType={reportType} />;
  }

  let descriptions;
  if (reportParams && reportMetadata && !hasExecutionError) {
    descriptions = filterDescriptions ?? generateDescriptions(reportParams, reportMetadata);
  }

  // FIXME this should probably be in the ReportExplorerPage instead of here
  let label = reportType === 'DUPLICATES_ACROSS_SOURCES' ? 'data records' : 'consumer profiles';
  if (dataLabel) {
    label = dataLabel;
  }

  let page: Page<unknown> = {
    content: [],
    totalPages: 1,
    totalElements: 0,
    first: true,
    last: true,
    size: 0,
    number: 1,
    numberOfElements: 0,
  };

  if (reportPage) {
    page = {
      content: reportPage.content,
      totalPages: Math.ceil(reportPage.totalRows / searchParameters.pagination.pageSize),
      totalElements: reportPage.totalRows,
      first: searchParameters.pagination.pageIndex === 0,
      last: !!reportPage.nextToken,
      size: reportPage.recordCount ?? 0,
      number: searchParameters.pagination.pageIndex,
      numberOfElements: reportPage?.recordCount ?? 0,
    };
  }

  return (
    <Flex data-testid="ReportExplorer" flexDir="column" h="full">
      {!reportPage && hasExecutionError && (
        <Alert
          status="error"
          title="We were unable to load the data report due to an error."
          description=" To generate a new data report, start by configuring the query parameters."
          mb={5}
        />
      )}
      {!reportPage && !hasExecutionError && (
        <Alert
          status="warning"
          variant="subtle"
          description="Start by configuring the query paramters to generate a custom data report."
          mb={5}
        />
      )}
      <DataExplorer
        data={page}
        defaultPagination={searchParameters.pagination}
        columns={columns}
        defaultSortOrder={(reportParams.sortOrder ?? []).map((s) => ({
          id: s.columnName,
          desc: s.descending ?? false,
        }))}
        defaultDrawerIsOpen={!runOnLoad}
        pageUrl={pageUrl}
        onFetcherParamChange={(params) => {
          const index = params.pagination.pageIndex;
          const nextToken = nextTokens[index];
          setSearchParameters({ ...params, pagination: { ...params.pagination, nextToken } });
        }}
        icon={{
          label,
        }}
        onExport={async () => {
          if (hasSecondaryExport) {
            modal.onOpen();
          } else {
            await exportData();
          }
        }}
        filterLabel={filterLabel}
        filterDescriptions={descriptions}
        hideEditMode
        readonlyPagination
        hideFilters={!children}
      >
        {children}
      </DataExplorer>
      <Modal isOpen={modal.isOpen} onClose={modal.onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Select data for export
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            You can choose to export all data on record for this source system, which will include
            supplemental values ingested for a data field. Alternatively, you can choose to export
            only the primary data that is displayed in the data table.
          </ModalBody>
          <ModalFooter justifyContent="flex-start">
            <HStack spacing={4}>
              <Button
                onClick={async () => {
                  modal.onClose();
                  await exportData(true);
                }}
              >
                Export all data
              </Button>
              <Button
                variant="outline"
                onClick={async () => {
                  modal.onClose();
                  await exportData();
                }}
              >
                Export only primary data
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
