import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

export interface InfiniteScrollContext {
  scrollRef: HTMLElement | null;
  setScrollRef: (ref: any) => void;
}

const Context = createContext<InfiniteScrollContext>({} as InfiniteScrollContext);

export function InfiniteScrollProvider({ children }: { children: ReactNode }) {
  const [scrollRef, setScrollRef] = useState<HTMLElement | null>(null);

  const memo = useMemo(
    () => ({
      scrollRef,
      setScrollRef: (ref: any) => setScrollRef(ref),
    }),
    [scrollRef]
  );

  return <Context.Provider value={memo}>{children}</Context.Provider>;
}

export function useInfiniteScroll(): InfiniteScrollContext {
  return useContext(Context);
}
