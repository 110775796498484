import { Button, HStack, Stack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import { formatNumber } from '../../../lib/utils/number-utils';

interface TotalDataRepairsProps {
  totalRepairs: number;
}

export default function TotalDataRepairs({ totalRepairs }: TotalDataRepairsProps) {
  return (
    <SectionContainer variant="box">
      <Stack>
        <HStack>
          <Text fontSize="md" fontWeight="bold" as="span">
            {formatNumber(totalRepairs)}
          </Text>
        </HStack>
        <Text as="span">total data repairs in this system</Text>
        <Button
          fontWeight="semibold"
          textColor="action"
          as={Link}
          to="/sources/repair-automation"
          variant="link"
          alignSelf="start"
        >
          See all repair automations
        </Button>
      </Stack>
    </SectionContainer>
  );
}
