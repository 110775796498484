import {
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Switch,
  Text,
  useBoolean,
  useClipboard,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import config from '../../../config';
import { useAuth } from '../../../context/AuthenticationContext';
import { useToast } from '../../../hooks/use-toast';

const tenantTools = [
  {
    name: 'Switch Tenant',
    description: 'This tool will let you easily switch to different tenant.',
    url: '/seviin-tools/switch-tenant',
  },
  {
    name: 'Reprocess tenant events',
    description: 'This tool will let you reprocess all ingested data in a tenant.',
    url: '/seviin-tools/reprocess-tenant',
  },

  {
    name: 'Tenant Management Tool',
    description: 'This tool will let you  update and create  a new tenant.',
    url: '/seviin-tools/tenant-management',
  },
];

const data = [
  {
    name: 'Demo 2 Tool',
    description:
      'This tool walks you through the process of setting up an empty tenant for the demo 2 script',
    url: '/seviin-tools/demo2',
  },
];

function UglyModeToggle() {
  const [uglyModeEnabled, setUglyModeEnabled] = useBoolean(config.get().UGLY_MODE_ENABLED);
  useEffect(() => {
    config.set({ UGLY_MODE_ENABLED: uglyModeEnabled });
  }, [uglyModeEnabled]);

  return (
    <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden">
      <Flex
        w="full"
        h="120px"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        p={3}
        bgColor="gray.50"
      >
        <Switch isChecked={uglyModeEnabled} onChange={setUglyModeEnabled.toggle} />
        <Text pt={2} fontWeight="medium">
          {uglyModeEnabled ? 'Enabled' : 'Disabled'}
        </Text>
      </Flex>

      <Box p="4">
        <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" noOfLines={1}>
          Ugly Mode
        </Box>

        <Box mt="1" color="gray.800">
          Disable ugly mode to view work in progress screens & components using their normal color
          scheme.
        </Box>
      </Box>
    </Box>
  );
}

function TokenSnooper() {
  const { token } = useAuth();
  const { onCopy } = useClipboard(token ?? '');
  const toast = useToast();

  return (
    <Box maxW="sm" borderWidth="1px" borderRadius="lg">
      <Stack w="full" h="120px" p={3} flexDirection="column" justify="center" bgColor="gray.50">
        <Text noOfLines={1}>{token}</Text>
        <Button
          variant="ghost"
          onClick={() => {
            onCopy();
            toast({ title: 'Token copied successfully!' });
          }}
        >
          Copy Token
        </Button>
      </Stack>
      <Box p="4">
        <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" noOfLines={1}>
          Token Taker
        </Box>

        <Box mt="1" color="gray.800">
          Quickly copy your access token.
        </Box>
      </Box>
    </Box>
  );
}

function ToolCard({ tool }: { tool: { name: string; description: string; url: string } }) {
  return (
    <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden" as={Link} to={tool.url}>
      <Flex w="full" justifyContent="center" p={3} bgColor="gray.50">
        <Avatar name={tool.name} size="xl" />
      </Flex>

      <Box p="4">
        <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" noOfLines={1}>
          {tool.name}
        </Box>

        <Box mt="1" color="gray.800">
          {tool.description}
        </Box>
      </Box>
    </Box>
  );
}

function SeviinToolsPage() {
  return (
    <PageLayout
      header={
        <Header icon={<Icon boxSize={5} iconImage={IconImage.tools} />} title="harpin AI Tools" />
      }
      data-testid="SeviinToolsPage"
    >
      <Stack spacing={5}>
        <Stack>
          <Heading size="sm">Tenant tools</Heading>
          <SimpleGrid columns={3} spacing={10}>
            {tenantTools.map((tool) => (
              <ToolCard key={tool.url} tool={tool} />
            ))}
          </SimpleGrid>
        </Stack>
        <Stack>
          <Heading size="sm">Other tools</Heading>
          <SimpleGrid columns={3} spacing={10}>
            {data.map((tool) => (
              <ToolCard key={tool.url} tool={tool} />
            ))}

            <TokenSnooper />
            <UglyModeToggle />
          </SimpleGrid>
        </Stack>
      </Stack>
    </PageLayout>
  );
}
export default SeviinToolsPage;
