import { Text } from '@chakra-ui/react';
import { CellContext } from '@tanstack/react-table';
import { useMemo } from 'react';
import { SourceIdentityRecordSearchResponse } from '../../../../lib/api-client/identity/model/SourceIdentityRecordSearchResponse';
import { IdentityDataKeys } from '../../../../lib/api-client/identity/model/UpdateIdentityRecord';
import { getDataRecordScoreLabel } from '../../../data-explorer/components/SourceSystemRecordExplorer/description-utils';

interface ScoreCellProps {
  cellContext: CellContext<SourceIdentityRecordSearchResponse, IdentityDataKeys | undefined>;
  labels: { label: string }[];
}
export default function ScoreCell({ cellContext, labels }: ScoreCellProps) {
  const value = useMemo(() => {
    const v = cellContext.getValue();
    if (typeof v === 'number') {
      return `${getDataRecordScoreLabel(v, labels)} (${(v * 100).toFixed(2)})`;
    }
    if (Array.isArray(v)) {
      return v.map(
        (item) => `${getDataRecordScoreLabel(item, labels)} (${(item * 100).toFixed(2)})`
      );
    }

    return null;
  }, [cellContext, labels]);

  return (
    <div>
      {value && Array.isArray(value) ? (
        value.map((item) => <Text>{item}</Text>)
      ) : (
        <Text>{value}</Text>
      )}
    </div>
  );
}
