import { HStack, Select } from '@chakra-ui/react';
import { startOfDay, startOfMonth, startOfWeek, sub } from 'date-fns';
import React, { ChangeEvent, useState } from 'react';
import { TimeSeriesIntervalName } from '../../../../lib/model/common';

type TimeSeriesFilterConfig = {
  label: string;
  value: string;
  getStartDate: (date: Date) => Date;
};

export const filterConfig: {
  [key: string]: TimeSeriesFilterConfig[];
} = {
  day: [
    {
      label: 'Last 7 days',
      value: 'last7days',
      getStartDate: (date: Date) => startOfDay(sub(date, { days: 6 })),
    },
  ],
  week: [
    {
      label: 'Last 8 weeks',
      value: 'last8weeks',
      getStartDate: (date: Date) => startOfWeek(sub(date, { weeks: 7 })),
    },
  ],
  month: [
    {
      label: 'Last 6 months',
      value: 'last6months',
      getStartDate: (date: Date) => startOfMonth(sub(date, { months: 5 })),
    },
  ],
};

export interface SelectedTimeSeriesFilter {
  interval: TimeSeriesIntervalName;
  config: TimeSeriesFilterConfig;
}

interface TimeSeriesFilterProps {
  onFilterChanged: (filter: SelectedTimeSeriesFilter) => void;
  hide?: boolean;
  filter?: SelectedTimeSeriesFilter;
}

export default function TimeSeriesLimitedFilter({
  onFilterChanged,
  hide = true,
  filter,
}: TimeSeriesFilterProps) {
  const [filters, setFilters] = useState<SelectedTimeSeriesFilter>({
    interval: 'day',
    config: filterConfig.day[0],
  });

  const onIntervalChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const interval = event.target.value as TimeSeriesIntervalName;
    if (interval in filterConfig) {
      const selected = {
        interval,
        config: filterConfig[interval][0],
      };
      setFilters(selected);
      onFilterChanged(selected);
    }
  };

  const onAmountChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setFilters((prev) => {
      const selected = {
        ...prev,
        config:
          filterConfig[prev.interval].find((config) => config.value === event.target.value) ??
          prev.config,
      };
      onFilterChanged(selected);
      return selected;
    });
  };

  return (
    <HStack>
      <Select
        value={filter?.interval}
        onChange={onIntervalChange}
        variant="filled"
        bgColor="transparent"
        textColor="action"
        fontWeight="semibold"
        flexShrink="0"
        w="150px"
      >
        <option value="day">Last 7 days</option>
        <option value="week">Last 8 weeks</option>
        <option value="month">Last 6 months</option>
      </Select>

      {!hide && (
        <Select value={filters.config.value} onChange={onAmountChange}>
          {filterConfig[filters.interval].map((config) => (
            <option key={config.value} value={config.value}>
              {config.label}
            </option>
          ))}
        </Select>
      )}
    </HStack>
  );
}
