import { chakra, HTMLChakraProps, ThemingProps } from '@chakra-ui/react';
import { useCardStyles } from './Card';

interface CardBadgeProps extends HTMLChakraProps<'span'>, ThemingProps {}

function CardBadge(props: CardBadgeProps) {
  const styles = useCardStyles();
  return <chakra.span __css={styles.badge} {...props} />;
}
export default CardBadge;
