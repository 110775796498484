import { defineStyleConfig } from '@chakra-ui/react';

export default defineStyleConfig({
  baseStyle: {
    card: {
      position: 'relative',
      display: 'flex',
      flexDir: 'column',
      justifyContent: 'space-between',
      padding: 4,
      borderColor: 'gray.100',
      borderWidth: '1px',
      borderRadius: 'md',
      shadow: 'base',
    },
    badge: {
      display: 'inline-flex',
      alignItems: 'center',
      rounded: 'full',
      bgColor: 'action',
      color: 'white',
      fontSize: 'xs',
      fontWeight: 'semibold',
      py: 1,
      px: 2,
      position: 'absolute',
      top: -3,
    },
    title: {
      fontSize: 'md',
      fontWeight: 'semibold',
      pb: 1,
    },
    description: {
      fontSize: 'sm',
    },
    action: {
      p: 0,
      w: 'full',
      variant: 'outline',
    },
  },
});
