import {
  chakra,
  HStack,
  IconButton,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { LinkProps } from 'react-router-dom';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';

interface LogItemLinkProps {
  children: ReactNode;
}

export default function LogItemLink({
  children,
  ...rest
}: LogItemLinkProps & (ChakraLinkProps | LinkProps)) {
  return (
    <ChakraLink {...rest}>
      <HStack spacing={0}>
        <chakra.span whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
          {children}
        </chakra.span>
        <IconButton
          aria-label="Open "
          variant="ghost"
          p={0}
          h={5}
          icon={<Icon iconImage={IconImage.externalLink} boxSize={5} />}
        />
      </HStack>
    </ChakraLink>
  );
}
