import { differenceInDays, formatDistanceToNowStrict } from 'date-fns';
import {
  ReportExecutionFilter,
  ReportExecutionOption,
  ReportMetadata,
  ReportPredicateOperator,
  StartReportExecution,
  ReportMetadataOption,
  ReportMetadataOptionValue,
} from '../../../../lib/api-client/reports/report.model';
import { formatNumber } from '../../../../lib/utils/number-utils';
import { isDefined } from '../../../../lib/utils/utils';

function getSelectedSourcesDisplayNames(
  reportMetadata: ReportMetadataOption,
  options: string[]
): string[] {
  const sourceOptions = reportMetadata?.arguments[0].values as ReportMetadataOptionValue[];
  return options.map(
    (value) => sourceOptions.find((option) => option.value === value)?.displayName || value
  );
}

const optionDescription: Record<
  string,
  (option: ReportExecutionOption, reportMetadata: ReportMetadata) => string
> = {
  txDateRange: (option) => {
    const label = `Transaction date`;

    const { startRange, endRange } = option.arguments!;
    if (startRange && endRange) {
      if (Math.abs(differenceInDays(new Date(startRange), new Date(endRange))) > 1) {
        return `${label} between ${startRange} and ${endRange}`;
      }

      return `${label} on ${startRange}`;
    }

    if (endRange) {
      return `${label} on or before ${endRange}`;
    }

    return `${label} on or after ${startRange}`;
  },
  locationNameList: (option) => {
    const label = 'Locations';
    return `${label} ${option.arguments!.locationNames.filter((v: any) => !!v).join(', ')}`;
  },
  sourceList: (option, reportMetadata) => {
    const label = 'Source Systems: ';
    const allSourceSystems = reportMetadata.options.find((opt) => opt.name === option.optionName);
    if (allSourceSystems) {
      return `${label} ${getSelectedSourcesDisplayNames(
        allSourceSystems,
        option.arguments!.sourceIds
      )}`;
    }
    return '';
  },
};

export function mapOperatorLabel(operator: ReportPredicateOperator) {
  switch (operator) {
    case 'BETWEEN':
      return 'Between';
    case 'EQUALS':
      return '';
    case 'GREATER_THAN_OR_EQUAL':
      return 'Greater than or equal to';
    case 'GREATER_THAN':
      return 'Greater than';
    case 'LESS_THAN_OR_EQUAL':
      return 'Less than or equal to';
    case 'LESS_THAN':
      return 'Less than';
    default:
      return `${operator}`;
  }
}

export function generateDescriptions(
  reportParams: Pick<StartReportExecution, 'sortOrder' | 'limit' | 'filter' | 'options'>,
  reportMetadata: ReportMetadata
): string[] {
  const descriptions: string[] = [];

  if (reportParams.sortOrder && reportParams.sortOrder.length > 0) {
    const sortDescriptions = reportParams.sortOrder?.map((sort) => {
      const name =
        reportMetadata.columns.find((c) => c.name === sort.columnName)?.displayName ??
        sort.columnName;
      return `${name}: ${sort.descending ? 'High to low' : 'Low to high'}`;
    });
    descriptions.push(`Sort by ${sortDescriptions.join(', ')}`);
  }

  if (reportParams.filter != null) {
    if (reportParams.filter.predicates) {
      const filterDescriptions: string[] = reportParams.filter.predicates.map((predicate) => {
        const column = reportMetadata.columns.find((c) => c.name === predicate.columnName);

        if (column) {
          if (column.dataType === 'TIMESTAMP' && predicate.arguments) {
            const relativeDate = formatDistanceToNowStrict(new Date(predicate.arguments[0]));
            return `${column.displayName ?? predicate.columnName}: ${relativeDate}`;
          }

          return `${column.displayName ?? predicate.columnName}: ${mapOperatorLabel(
            predicate.operator
          )} ${predicate.arguments?.join(' and ')}`;
        }

        return `${predicate.columnName}: ${predicate.arguments?.join(' and ')}`;
      });

      descriptions.push(...filterDescriptions);
    }
  }

  if (reportParams.options != null) {
    descriptions.push(
      ...reportParams.options
        .map((option) => optionDescription[option.optionName]?.(option, reportMetadata))
        .filter((description) => description != null)
    );
  }

  if (reportParams.limit != null) {
    descriptions.push(`Limit results to: ${formatNumber(reportParams.limit)}`);
  }

  return descriptions;
}

function buildSortOrderFromSearchParams(
  searchParams: URLSearchParams
): StartReportExecution['sortOrder'] {
  return searchParams.getAll('sort').map((s) => {
    const [columnName, desc] = s.split(',');
    return {
      columnName,
      descending: desc?.toLowerCase() === 'desc',
    };
  });
}

function buildLimitFromSearchParams(searchParams: URLSearchParams): number | undefined {
  const limitParam = searchParams.get('limit');
  let limit;
  if (isDefined(limitParam)) {
    limit = Number.isNaN(+limitParam) ? undefined : +limitParam;
  }

  return limit;
}

function buildFilterFromSearchParams(
  searchParams: URLSearchParams
): ReportExecutionFilter | undefined {
  // First look for a single filter
  const filterParam = searchParams.get('filter');
  let filter;
  if (isDefined(filterParam)) {
    const [columnName, operator, argument] = filterParam.split('.', 3) as any;
    filter = {
      predicates: [
        {
          columnName,
          operator,
          arguments: [argument],
        },
      ],
    };
  }

  // Override single filter if multiple filters are present
  const filters = (searchParams.getAll('filters') ?? []).map((s) => {
    const [columnName, operator, ...rest] = s.split('.');
    const argument = rest.join('.');

    return {
      columnName,
      operator,
      arguments: operator === 'BETWEEN' ? argument.split('_') : [argument],
    };
  });
  if (filters.length > 0) {
    filter = {
      predicates: filters,
    };
  }

  return filter;
}

function buildOptionsFromSearchParams(searchParams: URLSearchParams): any {
  return searchParams.getAll('options').map((s) => {
    const [option, ...optionParams] = s.split('_');

    return {
      optionName: option,
      arguments: optionParams.reduce((previousValue, currentValue) => {
        const isList = currentValue.includes(':');

        let k;
        let v;
        if (isList) {
          const [key, ...values] = currentValue.split(':');
          k = key;
          v = values;
        } else {
          const [key, value] = currentValue.split('.');
          k = key;
          v = value;
        }

        const newValue = { ...previousValue };
        newValue[k] = v;
        return newValue;
      }, {} as Record<string, any>),
    };
  });
}

export function buildReportParametersFromSearchParams(
  searchParams: URLSearchParams
): Omit<StartReportExecution, 'reportType'> {
  const sortOrder = buildSortOrderFromSearchParams(searchParams);
  const limit = buildLimitFromSearchParams(searchParams);
  const filter = buildFilterFromSearchParams(searchParams);
  const options = buildOptionsFromSearchParams(searchParams);

  return {
    sortOrder,
    limit,
    filter,
    options,
  };
}
