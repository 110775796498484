import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useFieldArray, UseFieldArrayReturn, useFormContext } from 'react-hook-form';
import Icon from '../../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../../components/core/Icon/IconConfig';
import Combobox from '../../../../../components/core/form/Combobox/Combobox';
import { useToast } from '../../../../../hooks/use-toast';
import {
  AttributeDomainType,
  AttributeMetadata,
  AttributeMetadataWithNormalization,
  NormalizationMetadata,
} from '../../../../../lib/api-client/data-model/data-model.model';
import { DataSourceConnectionTypes } from '../../../../../lib/api-client/sources/model/DataSourceConnectionType';
import { ExternalDataTypeObjectAttribute } from '../../../../../lib/api-client/sources/model/ExternalDataModel';
import { TestIngestionRecord } from '../../../../../lib/api-client/sources/sources.model';
import { isDefined } from '../../../../../lib/utils/utils';
import {
  DataSourceAttributeMultiValueMapping,
  getSyncDirectionIcon,
  showAttributeOrderToast,
  UpdateDataSourceForm,
} from '../../DataMappingPage.utils';
import AdvancedMappingModal from '../AdvancedMappingModal/AdvancedMappingModal';
import EditAttributeNormalizationModal from '../EditAttributeNormalizationModal/EditAttributeNormalizationModal';

const inputStyles = {
  width: '340px',
};

interface CanonicalAttributeControlProps {
  index: number;
  isRequired: boolean;
  coreAttributes: (AttributeMetadata & {
    domain: AttributeDomainType;
    normalizationMetadata?: NormalizationMetadata[];
  })[];
}

function CanonicalAttributeControl({
  index,
  isRequired,
  coreAttributes,
}: CanonicalAttributeControlProps) {
  const {
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useFormContext();

  const watchedMappings = watch('attributeMappings');
  const selectedMappings = watchedMappings.map(
    (mapping: any) =>
      mapping.canonicalAttribute !== 'persistedValue' &&
      `${mapping.domainType}___${mapping.canonicalAttribute}`
  );

  const options = useMemo(
    () =>
      coreAttributes.map((c) => ({
        label: c.displayName,
        value: `${c.domain}___${c.id}`,
        data: {
          domain: c.domain,
        },
        disabled: selectedMappings.includes(`${c.domain}___${c.id}`),
      })),
    [coreAttributes, selectedMappings]
  );

  const canonicalAttribute = getValues(`attributeMappings.${index}.canonicalAttribute`);
  const value = canonicalAttribute
    ? `${getValues(`attributeMappings.${index}.domainType`)}___${canonicalAttribute}`
    : '';

  return (
    <FormControl
      isInvalid={!!errors.attributeMappings?.[index]?.canonicalAttribute}
      {...inputStyles}
    >
      <Tooltip
        label="This field is required and cannot be modified."
        hasArrow
        placement="top"
        isDisabled={!isRequired}
      >
        {coreAttributes && (
          <Combobox
            name={`attributeMappings.${index}.canonicalAttribute`}
            isDisabled={isRequired}
            value={[value]}
            options={options}
            onChange={(e) => {
              const [domain, attribute] = e.value[0].split('___');
              setValue(`attributeMappings.${index}.canonicalAttribute`, attribute, {
                shouldValidate: true,
              });
              setValue(`attributeMappings.${index}.normalizations`, null);
              setValue(`attributeMappings.${index}.persistedValueName`, null);
              setValue(`attributeMappings.${index}.domainType`, domain ?? null);
            }}
          />
        )}
      </Tooltip>
    </FormControl>
  );
}

function CanonicalAttributeErrorMessage({ index }: { index: number }) {
  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext();

  const watchedAttribute = watch(`attributeMappings.${index}.canonicalAttribute`);

  return (
    <Box {...inputStyles}>
      {watchedAttribute === 'persistedValue' && (
        <FormControl mb={0.5} w="full">
          <Input
            placeholder="Data field name"
            {...register(`attributeMappings.${index}.persistedValueName`)}
          />
        </FormControl>
      )}
      <FormControl
        isInvalid={!!errors.attributeMappings?.[index]?.canonicalAttribute}
        {...inputStyles}
      >
        {errors &&
          errors.attributeMappings &&
          errors.attributeMappings[index] &&
          errors.attributeMappings[index]!!.canonicalAttribute && (
            <FormErrorMessage mt={0}>
              {`${errors.attributeMappings[index]!!.canonicalAttribute!!.message}`}
            </FormErrorMessage>
          )}
      </FormControl>
    </Box>
  );
}

function MappingTypeControl({ index }: { index: number }) {
  const { setValue, clearErrors, register, trigger } = useFormContext();

  return (
    <FormControl w="152px">
      <Select
        {...register(`attributeMappings.${index}.mappingType`, {
          onChange: async (e: React.ChangeEvent<HTMLSelectElement>) => {
            const { value } = e.target;
            if (value === 'SINGLE_SOURCE_ATTRIBUTE') {
              setValue(`attributeMappings.${index}.parameters.value`, '');
              clearErrors(`attributeMappings.${index}.parameters.value`);
            } else if (value === 'CONSTANT_VALUE') {
              setValue(`attributeMappings.${index}.sourceAttributes`, [{ value: '' }]);
              clearErrors(`attributeMappings.${index}.sourceAttributes`);
            } else {
              setValue(`attributeMappings.${index}.parameters.value`, '');
              clearErrors(`attributeMappings.${index}.parameters.value`);
              setValue(`attributeMappings.${index}.sourceAttributes`, [{ value: '' }]);
              clearErrors(`attributeMappings.${index}.sourceAttributes`);
            }
            await trigger();
          },
        })}
      >
        <option value="GENERATED_UUID">Generated ID</option>
        <option value="SINGLE_SOURCE_ATTRIBUTE">Mapped</option>
        <option value="CONSTANT_VALUE">Static</option>
      </Select>
    </FormControl>
  );
}

function SourceAttributeControl({
  index,
  sourceAttributes,
  layout,
}: {
  index: number;
  sourceAttributes: string[];
  layout: 'INBOUND' | string;
}) {
  const {
    formState: { errors },
    getValues,
    register,
    setValue,
  } = useFormContext();

  const toast = useToast();

  const options = useMemo(
    () => sourceAttributes.map((s) => ({ label: s, value: s })),
    [sourceAttributes]
  );

  const { fields, append, remove } = useFieldArray({
    name: `attributeMappings.${index}.sourceAttributes`,
  });

  const handleDragStart = (e: any, i: any) => {
    e.dataTransfer.setData('i', i);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleDrop = (e: any, targetIndex: any) => {
    const draggedIndex = e.dataTransfer.getData('i');
    const draggedItem = fields[draggedIndex];
    const newItems = fields.filter((item, i) => i !== parseInt(draggedIndex, 10));
    newItems.splice(targetIndex, 0, draggedItem);
    const newValues = newItems.map((item) => {
      const oldIndex = fields.findIndex((field) => field.id === item.id);
      return getValues(`attributeMappings.${index}.sourceAttributes.${oldIndex}.value`);
    });
    setValue(`attributeMappings.${index}.sourceAttributes`, newItems);
    newValues.forEach((value, i) => {
      setValue(`attributeMappings.${index}.sourceAttributes.${i}.value`, value);
    });
  };

  return (
    <FormControl isInvalid={!!errors.attributeMappings?.[index]?.sourceAttributes} {...inputStyles}>
      <Stack>
        {fields.map((item, idx) => {
          let input;
          if (sourceAttributes.length > 0) {
            input = (
              <Combobox
                fields={fields}
                key={item.id}
                name={`attributeMappings.${index}.sourceAttributes.${idx}.value`}
                options={options}
                allowCustomValue
                value={[getValues(`attributeMappings.${index}.sourceAttributes.${idx}.value`)]}
                onChange={(e) => {
                  setValue(
                    `attributeMappings.${index}.sourceAttributes.${idx}.value`,
                    e.value?.[0] ?? '',
                    {
                      shouldValidate: true,
                    }
                  );
                }}
                onDragStart={(e: any) => handleDragStart(e, idx)}
                onDragOver={(e: any) => handleDragOver(e)}
                onDrop={(e: any) => handleDrop(e, idx)}
              />
            );
          } else {
            input = (
              <InputGroup>
                {fields.length > 1 && (
                  <InputRightElement
                    draggable
                    onDragStart={(e: any) => handleDragStart(e, idx)}
                    onDragOver={(e: any) => handleDragOver(e)}
                    onDrop={(e: any) => handleDrop(e, idx)}
                  >
                    <IconButton
                      aria-label="Drag and Drop Input Fields"
                      variant="ghost"
                      icon={<Icon boxSize={5} iconImage={IconImage.verticalScroll} />}
                    />
                  </InputRightElement>
                )}
                <Input
                  key={item.id}
                  placeholder="Attribute source mapping"
                  {...register(`attributeMappings.${index}.sourceAttributes.${idx}.value`)}
                />
              </InputGroup>
            );
          }

          return (
            <HStack key={item.id}>
              {input}
              {fields.length > 1 && (
                <IconButton
                  variant="outline"
                  aria-label="remove source attribute"
                  icon={<Icon iconImage={IconImage.delete} />}
                  onClick={() => {
                    remove(idx);
                  }}
                />
              )}
            </HStack>
          );
        })}
        {layout === 'INBOUND' && (
          <Button
            variant="ghost"
            justifyContent="start"
            onClick={() => {
              append({ value: '', normalizations: [] });
              showAttributeOrderToast(toast);
            }}
          >
            Add attribute
          </Button>
        )}
      </Stack>
    </FormControl>
  );
}

function ConstantControl({ index }: { index: number }) {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  return (
    <FormControl
      isInvalid={!!errors.attributeMappings?.[index]?.parameters?.value}
      {...inputStyles}
    >
      <Input
        placeholder="Attribute static value"
        defaultValue=""
        {...register(`attributeMappings.${index}.parameters.value`)}
      />
    </FormControl>
  );
}

function GeneratedUUIDControl() {
  return (
    <FormControl {...inputStyles}>
      <Input placeholder="Auto-generated unique ID" isDisabled />
    </FormControl>
  );
}

function SourceAttributeErrorMessage({ index }: { index: number }) {
  const {
    formState: { errors },
  } = useFormContext();

  const sourceAttributeMessage = (
    (errors?.attributeMappings?.[index]?.sourceAttributes as any) ?? []
  ).find((x: any) => !!x?.value?.message)?.value?.message;

  return (
    <Box>
      <FormControl
        isInvalid={
          !!errors.attributeMappings?.[index]?.sourceAttributes ||
          !!errors.attributeMappings?.[index]?.parameters?.value
        }
        {...inputStyles}
      >
        {errors &&
          errors.attributeMappings &&
          errors.attributeMappings[index] &&
          (errors.attributeMappings[index]!!.sourceAttributes ||
            errors.attributeMappings[index]!!.parameters?.value) && (
            <FormErrorMessage mt={0}>
              {`${
                sourceAttributeMessage ??
                errors.attributeMappings[index]!!.parameters?.value?.message
              }`}
            </FormErrorMessage>
          )}
      </FormControl>
    </Box>
  );
}

interface AttributeRowProps {
  syncDirection: DataSourceConnectionTypes;
  item: DataSourceAttributeMultiValueMapping;
  index: number;
  attributeMetadata: AttributeMetadataWithNormalization[];
  sourceAttributes: string[];
  sampleData?: TestIngestionRecord[];
  useFieldArray: UseFieldArrayReturn<UpdateDataSourceForm, 'attributeMappings'>;
  layout?: 'INBOUND' | 'OUTBOUND' | 'OUTBOUND_PUBLISH_TX';
  externalDataModel?: ExternalDataTypeObjectAttribute[];
}

export default function AttributeRow({
  syncDirection,
  item,
  index,
  attributeMetadata,
  sampleData,
  useFieldArray: useFieldArrayProp,
  layout = 'INBOUND',
  sourceAttributes,
  externalDataModel = [],
}: AttributeRowProps) {
  const { watch } = useFormContext();
  const { remove, update } = useFieldArrayProp;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const watchedAttribute = watch(`attributeMappings.${index}`);
  const isRequired = !!item.metadata?.required;

  const attributeNormalizations = attributeMetadata.find(
    (attribute) => attribute.id === watchedAttribute.canonicalAttribute
  );

  const sampleDataValues = sampleData
    ?.filter((data) => data.domainType === item.domainType)
    .flatMap((data) => item.sourceAttributes.map((sa) => data.sourceAttributes?.[sa.value]))
    .filter((d) => isDefined(d));
  return (
    <Stack spacing={2}>
      <Stack spacing={0.5}>
        <HStack justify="space-between" alignItems="start">
          <HStack spacing={6} alignItems="start">
            {layout === 'INBOUND' && <MappingTypeControl index={index} />}
            {watchedAttribute.mappingType === 'SINGLE_SOURCE_ATTRIBUTE' && (
              <SourceAttributeControl
                index={index}
                sourceAttributes={sourceAttributes}
                layout={layout}
              />
            )}
            {watchedAttribute.mappingType === 'CONSTANT_VALUE' && <ConstantControl index={index} />}
            {watchedAttribute.mappingType === 'GENERATED_UUID' && <GeneratedUUIDControl />}
            <Icon boxSize={6} iconImage={getSyncDirectionIcon(syncDirection)} />
            <VStack>
              <CanonicalAttributeControl
                index={index}
                isRequired={isRequired}
                coreAttributes={attributeMetadata}
              />

              <CanonicalAttributeErrorMessage index={index} />
            </VStack>
          </HStack>
          <HStack spacing={3}>
            {layout === 'INBOUND' && (
              <EditAttributeNormalizationModal
                sourceAttributes={watchedAttribute.sourceAttributes}
                isDisabled={(attributeNormalizations?.normalizations?.length ?? 0) < 1}
                attributeMetadata={watchedAttribute.metadata}
                normalizationMetadata={attributeNormalizations?.normalizationMetadata ?? []}
                onSuccess={(result) => {
                  update(index, { ...watchedAttribute, sourceAttributes: result });
                }}
              />
            )}
            {layout === 'OUTBOUND_PUBLISH_TX' && (
              <AdvancedMappingModal
                externalDataModel={externalDataModel}
                valueMappings={item.valueMappings}
                onSuccess={(valueMappings) => {
                  update(index, { ...watchedAttribute, valueMappings });
                }}
              />
            )}
            {sampleDataValues != null && sampleDataValues.length > 0 && (
              <>
                <Tooltip hasArrow label="See sample data" placement="top">
                  <IconButton
                    aria-label="See sample data"
                    variant="outline"
                    color="action"
                    icon={<Icon iconImage={IconImage.searchData} />}
                    onClick={onOpen}
                  />
                </Tooltip>
                <Drawer isOpen={isOpen} onClose={onClose} size="md">
                  <DrawerOverlay />
                  <DrawerContent>
                    <DrawerCloseButton
                      size="lg"
                      right="unset"
                      left="-40px"
                      fontSize="xs"
                      color="white"
                      top={10}
                      bgColor="action"
                      _hover={{ backgroundColor: 'actionDark' }}
                      borderTopRightRadius={0}
                      borderBottomRightRadius={0}
                    />
                    <DrawerHeader>Sample data values</DrawerHeader>
                    <DrawerBody>
                      <Stack divider={<Divider />}>
                        {sampleDataValues.map((d, idx) => (
                          <Text
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${item.canonicalAttribute}-${idx}`}
                          >
                            {d}
                          </Text>
                        ))}
                        {sampleDataValues.length < 1 && <Text>No sample data found</Text>}
                      </Stack>
                    </DrawerBody>
                  </DrawerContent>
                </Drawer>
              </>
            )}
            <Tooltip hasArrow label="Delete mapping" placement="top">
              <IconButton
                aria-label="Delete mapping"
                variant="outline"
                color="action"
                icon={<Icon iconImage={IconImage.delete} />}
                onClick={() => remove(index)}
                isDisabled={isRequired}
              />
            </Tooltip>
          </HStack>
        </HStack>
        <Stack direction="row" spacing="72px" pl={layout === 'INBOUND' ? '176px' : '0'}>
          <SourceAttributeErrorMessage index={index} />
        </Stack>
      </Stack>
      <Divider />
    </Stack>
  );
}
