import { Select, useBoolean } from '@chakra-ui/react';
import { useXSWR } from '@hazae41/xswr';
import React, { useCallback, useState } from 'react';
import Alert from '../../../../components/core/Alert/Alert';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';
import { getSourceUploadSchema } from '../../../../lib/api-client/sources/SourceData';
import { Upload } from '../../../../lib/api-client/sources/model/Upload';
import { UploadPath } from '../../../../lib/api-client/sources/model/UploadPath';
import CompletenessCheckmark from '../../shared/CompletenessCheckmark/CompletenessCheckmark';
import UploadFlatFile, { OnFileUploadProps } from '../../shared/UploadFlatFile/UploadFlatFile';

export default function UploadFlatFileSection({
  sourceId,
  uploads,
  onSuccess,
}: {
  sourceId: string;
  uploads: Upload[];
  onSuccess: (uploadId: string) => void;
}) {
  const [intervalId, setIntervalId] = useState<any>();
  const [isComplete, setComplete] = useState(false);
  const [showUpload, setShowUpload] = useBoolean(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { make } = useXSWR();
  const onUpload = useCallback(
    async ({ setLoadingText, uploadPath }: OnFileUploadProps & { uploadPath: UploadPath }) => {
      if (intervalId) clearInterval(intervalId);
      setLoadingText('Analyzing...');
      try {
        const checkUploadState = async () => {
          const state = await make(getSourceUploadSchema(sourceId, uploadPath.id)).fetch();
          if (!state) return;
          if (state.error || state.data?.status === 'failed' || state.data?.errorMessage) {
            setComplete(false);
            setErrorMessage(state.data?.errorMessage || '');
          } else if (state.data?.status === 'analysisCompleted') {
            setComplete(true);
            onSuccess(uploadPath.id);
          } else {
            const iid = setInterval(checkUploadState, 5000);
            setIntervalId(iid);
          }
        };
        await checkUploadState();
      } catch (error) {
        setErrorMessage('An error occurred during analysis.');
      }
    },
    [intervalId, make, onSuccess, sourceId]
  );

  return (
    <SectionContainer
      title="CSV file upload"
      variant="box"
      borderRadius="6px"
      header={<CompletenessCheckmark isComplete={isComplete} />}
      data-testid="UploadFlatFileSection"
    >
      {errorMessage && <Alert status="error" description={errorMessage} mb={2} />}
      <Select
        mb={3}
        onChange={(event) => {
          if (event.target.value) {
            setComplete(true);
            onSuccess(event.target.value);
            setShowUpload.off();
          } else {
            setShowUpload.on();
          }
        }}
      >
        <option value="">Upload new file</option>
        {uploads
          .sort((a, b) => -1 * a.createdAt.localeCompare(b.createdAt))
          .map((u) => (
            <option key={u.id} value={u.id}>
              {u.fileName || u.id} ({u.createdAt})
            </option>
          ))}
      </Select>
      {showUpload && <UploadFlatFile sourceId={sourceId} onUpload={onUpload} type="UPLOAD_ONLY" />}
    </SectionContainer>
  );
}
