import { DataSourceAttributeMapping } from '../../../lib/api-client/sources/model/DataSourceAttributeMapping';
import { ISODateString } from '../../../lib/model/common';

interface DemoTransaction {
  customer: {
    first_name: string;
    last_name: string;
    email: string;
    address?: string;
    city?: string;
    region?: string;
    postal_code?: string;
    country_code?: string;
  };
  sourceRecordId: string;
  amount: number;
  currency: string;
  locationName: string;
  description: string;
  accountId?: string;
  receipt_number: string; // identity source record id
  orderDate: ISODateString;
}

export function generateDemoTransactions(amount: number): DemoTransaction[] {
  const txs: DemoTransaction[] = [];
  for (let i = 0; i < amount; i++) {
    txs.push({
      sourceRecordId: `sourceRecordId_${i}`,
      amount: 10.0,
      currency: 'USD',
      locationName: `locationName_${i}`,
      description: `description_${i}`,
      customer: {
        first_name: `firstName_${i}`,
        last_name: `lastName_${i}`,
        email: `demouser_{i}@demo.com`,
      },
      receipt_number: `receipt_number_${i}`,
      orderDate: new Date().toISOString(),
    });
  }
  return txs;
}

export const attributeMappings: DataSourceAttributeMapping[] = [
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION',
    canonicalAttribute: 'sourceRecordId',
    sourceAttribute: 'sourceRecordId',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION',
    canonicalAttribute: 'amount',
    sourceAttribute: 'amount',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION',
    canonicalAttribute: 'currency',
    sourceAttribute: 'currency',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION',
    canonicalAttribute: 'sourceLocationId',
    sourceAttribute: 'sourceLocationId',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION',
    canonicalAttribute: 'locationName',
    sourceAttribute: 'locationName',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'paymentFingerprint',
    sourceAttribute: 'paymentFingerprint',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'ccLast4',
    sourceAttribute: 'ccLast4',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION',
    canonicalAttribute: 'description',
    sourceAttribute: 'description',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'firstName',
    sourceAttribute: 'firstName',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'lastName',
    sourceAttribute: 'lastName',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'accountId',
    sourceAttribute: 'accountId',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'streetAddress',
    sourceAttribute: 'streetAddress',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'city',
    sourceAttribute: 'city',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'governingDistrict',
    sourceAttribute: 'governingDistrict',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'governingDistrict',
    sourceAttribute: 'governingDistrict',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'postalCode',
    sourceAttribute: 'postalCode',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'mobilePhone',
    sourceAttribute: 'mobilePhone',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'sourceRecordId',
    sourceAttribute: 'receipt_number',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'sourceIdentityId',
    sourceAttribute: 'customer_id',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'firstName',
    sourceAttribute: 'customer.first_name',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'middleName',
    sourceAttribute: 'customer.middle_name',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'lastName',
    sourceAttribute: 'customer.last_name',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'streetAddress',
    sourceAttribute: 'customer.address',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'city',
    sourceAttribute: 'customer.city',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'governingDistrict',
    sourceAttribute: 'customer.region',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'postalCode',
    sourceAttribute: 'customer.postal_code',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'countryCode',
    sourceAttribute: 'customer.country_code',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'emailAddress',
    sourceAttribute: 'customer.email',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'homePhone',
    sourceAttribute: 'customer.phone_number',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'ccLast4',
    sourceAttribute: 'note.last4',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'paymentFingerprint',
    sourceAttribute: 'note.pay_ref',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION',
    canonicalAttribute: 'sourceRecordId',
    sourceAttribute: 'receipt_number',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION',
    canonicalAttribute: 'startTimestamp',
    sourceAttribute: 'receipt_date',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION',
    canonicalAttribute: 'startTimestamp',
    sourceAttribute: 'orderDate',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION',
    canonicalAttribute: 'description',
    sourceAttribute: 'dining_option',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION',
    canonicalAttribute: 'amount',
    sourceAttribute: 'total_money',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION',
    canonicalAttribute: 'sourceLocationId',
    sourceAttribute: 'note.location_id',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION',
    canonicalAttribute: 'locationName',
    sourceAttribute: 'note.location_name',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION',
    canonicalAttribute: 'currency',
    sourceAttribute: 'currency',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'IDENTITY',
    canonicalAttribute: 'accountId',
    sourceAttribute: 'note.loyalty',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION',
    canonicalAttribute: 'lineItems',
    sourceAttribute: 'line_item_list',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION_LINE_ITEM',
    canonicalAttribute: 'name',
    sourceAttribute: 'item',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION_LINE_ITEM',
    canonicalAttribute: 'amount',
    sourceAttribute: 'price',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION_LINE_ITEM',
    canonicalAttribute: 'category',
    sourceAttribute: 'category',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION_LINE_ITEM',
    canonicalAttribute: 'itemId',
    sourceAttribute: 'productId',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION_LINE_ITEM',
    canonicalAttribute: 'quantity',
    sourceAttribute: 'quantity',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION_LINE_ITEM',
    canonicalAttribute: 'description',
    sourceAttribute: 'description',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION',
    canonicalAttribute: 'lineItems',
    sourceAttribute: 'items',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION_LINE_ITEM',
    canonicalAttribute: 'name',
    sourceAttribute: 'itemName',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION_LINE_ITEM',
    canonicalAttribute: 'amount',
    sourceAttribute: 'amount',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION_LINE_ITEM',
    canonicalAttribute: 'category',
    sourceAttribute: 'category',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION_LINE_ITEM',
    canonicalAttribute: 'itemId',
    sourceAttribute: 'productId',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION_LINE_ITEM',
    canonicalAttribute: 'quantity',
    sourceAttribute: 'quantity',
    recommended: false,
    valueMappings: [],
  },
  {
    mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
    domainType: 'TRANSACTION_LINE_ITEM',
    canonicalAttribute: 'description',
    sourceAttribute: 'description',
    recommended: false,
    valueMappings: [],
  },
];
