import { HStack, IconButton, Stack, Text, useToast as useChakraToast } from '@chakra-ui/react';
import Icon from '../components/core/Icon/Icon';
import { IconImage } from '../components/core/Icon/IconConfig';

const bgToStatusMapping: Record<any, { bg: string; icon: IconImage }> = {
  info: {
    bg: 'actionDark',
    icon: IconImage.info,
  },
  success: {
    bg: 'success',
    icon: IconImage.success,
  },
  error: {
    bg: 'error',
    icon: IconImage.error,
  },
};

// eslint-disable-next-line import/prefer-default-export
export function useToast() {
  return useChakraToast({
    position: 'top-right',
    containerStyle: { pt: '88px' },
    render: (props) => {
      const toastOptions = bgToStatusMapping[props?.status ?? 'info'] ?? bgToStatusMapping.info;

      return (
        <HStack
          p={3}
          spacing={3}
          alignItems="start"
          boxShadow="md"
          rounded="md"
          color="white"
          {...toastOptions}
        >
          <HStack>
            {toastOptions.icon && <Icon iconImage={toastOptions.icon} />}
            <Stack spacing={0}>
              {props.title && <Text fontWeight="bold">{props.title}</Text>}
              {props.description && <Text>{props.description}</Text>}
            </Stack>
          </HStack>
          {props.isClosable && (
            <IconButton
              aria-label="Close toast"
              variant="ghost"
              icon={<Icon boxSize={4} iconImage={IconImage.cancel} color="white" />}
              onClick={() => props.onClose()}
            />
          )}
        </HStack>
      );
    },
  });
}
