import * as yup from 'yup';

export interface SearchProfileForm {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  phone?: string;
  accountId?: string;
}

export const searchProfileFormSchema = yup
  .object({
    emailAddress: yup.string().email('Enter a valid email address'),
    phone: yup
      .string()
      .optional()
      .matches(/^[0-9\-()+. ]{10,}$/, {
        message: 'Enter a valid phone number',
        excludeEmptyString: true,
      }),
  })
  .required();
