/* eslint-disable no-nested-ternary */
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

export default function AboutDataRepair({ automationType }: { automationType: any }) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const headingType =
    automationType === 'emailDomainMisspellings'
      ? 'Repairing misspelled email domains'
      : automationType === 'nameIsRepeated'
      ? ' Repair names repeated across data fields'
      : automationType === 'nameFromEmail'
      ? 'Repair names using the email address on record'
      : '';

  const modalBodyContent =
    automationType === 'emailDomainMisspellings' ? (
      <Stack spacing={5}>
        <Text>
          Our system identifies when an email domain is invalid by doing a DNS lookup. Then the data
          repair rule checks whether it’s similar to a known, valid email domain on our curated
          list. If so, it will be corrected automatically.
        </Text>
      </Stack>
    ) : automationType === 'nameIsRepeated' ? (
      <Text>
        Our system identifies when a data record has first or last name values that are duplicated
        across multiple fields. When this is detected, the data repair rule parses the name values
        and assigns them to the correct data fields.
      </Text>
    ) : automationType === 'nameFromEmail' ? (
      <Text>
        If a data record has first or last name fields that are incomplete or unrecognized, our
        system checks for a valid email address on record. Then the data repair rule parses the
        email address to identify and extract a name value. The extracted names undergo a validation
        process and valid names are updated to the data record automatically.
      </Text>
    ) : (
      ''
    );
  return (
    <>
      <Button variant="ghost" onClick={onOpen}>
        Learn about the repair process
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {headingType}
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>{modalBodyContent}</ModalBody>
          <ModalFooter justifyContent="start">
            <Button onClick={onClose} variant="outline">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
