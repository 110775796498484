import { Flex } from '@chakra-ui/react';
import SystemErrorDetails from '../SystemErrorDetails/SystemErrorDetails';

export default function SystemErrorPage() {
  return (
    <Flex h="full" justifyContent="center" alignItems="stretch">
      <SystemErrorDetails />
    </Flex>
  );
}
