import { IdentitySearchRequest } from '../../../../lib/api-client/identity/identity.model';
import { SearchOperator } from '../../../../lib/api-client/identity/model/SourceIdentityRecordSearch';
import {
  ReportExecutionFilter,
  ReportPredicateOperator,
  StartReportExecution,
} from '../../../../lib/api-client/reports/report.model';

function mapAuthenticityToCertificationType(value: string): string {
  switch (value) {
    case 'authentic':
      return 'certified';
    case 'suspicious':
      return 'questionable';
    case 'unauthentic':
      return 'uncertified';
    default:
      return value;
  }
}
function mapSearchOperatorToReportPredicateOperator(
  operator: SearchOperator
): ReportPredicateOperator {
  switch (operator) {
    case SearchOperator.Between:
      return 'BETWEEN';
    case SearchOperator.GreaterThanOrEqual:
      return 'GREATER_THAN_OR_EQUAL';
    case SearchOperator.GreaterThan:
      return 'GREATER_THAN';
    case SearchOperator.LessThanOrEqual:
      return 'LESS_THAN_OR_EQUAL';
    case SearchOperator.LessThan:
      return 'LESS_THAN';
    case SearchOperator.EqualOrNull:
    case SearchOperator.Equal:
    default:
      return 'EQUALS';
  }
}

// eslint-disable-next-line import/prefer-default-export
export function mapIdentitySearchToReportQuery(
  search: IdentitySearchRequest
): StartReportExecution {
  const predicates: ReportExecutionFilter['predicates'] = [];
  if ('pinType' in search && search.pinType) {
    predicates.push({
      columnName: 'profileType',
      operator: 'EQUALS',
      arguments: [search.pinType],
    });
  }

  if ('identityRecordCount' in search && search.identityRecordCount != null) {
    predicates.push({
      columnName: 'recordCount',
      operator: mapSearchOperatorToReportPredicateOperator(search.identityRecordCount.operator),
      arguments: search.identityRecordCount.values.map((v) => v.toString()),
    });
  }

  if ('tags' in search && search.tags != null && search.tags[0]) {
    const tag = search.tags[0];

    if ('values' in tag) {
      predicates.push({
        columnName: 'certificationType',
        operator: 'EQUALS',
        arguments: tag.values.map(mapAuthenticityToCertificationType),
      });
    } else if ('scoreValues' in tag && 'scoreOperator' in tag) {
      predicates.push({
        columnName: 'certificationScore',
        operator: mapSearchOperatorToReportPredicateOperator(tag.scoreOperator),
        arguments: tag.scoreValues.map((v) => v.toString()),
      });
    }
  }

  const { sourceId } = search;

  return {
    reportType: 'PROFILES',
    filter:
      predicates.length > 0
        ? {
            conjunction: 'AND',
            predicates,
          }
        : undefined,
    options: sourceId
      ? [
          {
            optionName: 'sourceList',
            arguments: {
              sourceIds: [sourceId],
            },
          },
        ]
      : undefined,
  };
}
