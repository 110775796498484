import { Box, Flex, HStack, Text, Tooltip, chakra } from '@chakra-ui/react';
import React from 'react';
import { CustomerValueRank } from '../../../../lib/api-client/customer-value/model/CustomerValue';
import { formatCurrency } from '../../../../lib/utils/number-utils';
import Icon from '../../../core/Icon/Icon';
import { IconImage } from '../../../core/Icon/IconConfig';

const config = new Map<
  CustomerValueRank,
  {
    label: string;
    sx: { h: string; roundedTop?: string; bg: string };
  }
>([
  [
    CustomerValueRank.VeryHigh,
    { label: 'Very High', sx: { h: '100%', roundedTop: 'md', bg: 'success' } },
  ],
  [CustomerValueRank.High, { label: 'High', sx: { h: '80%', bg: 'success' } }],
  [CustomerValueRank.Medium, { label: 'Medium', sx: { h: '60%', bg: 'warning' } }],
  [CustomerValueRank.Low, { label: 'Low', sx: { h: '40%', bg: 'error' } }],
  [CustomerValueRank.VeryLow, { label: 'Very Low', sx: { h: '20%', bg: 'error' } }],
]);

export interface CustomerValueWidgetProps {
  ranking: CustomerValueRank;
  value: number;
  currency?: string;
  averageTotalValue: number;
  totalSpend: number;
  averageSpend: number;
}

function CustomerValueWidget({
  ranking,
  value,
  currency = 'USD',
  averageTotalValue,
  totalSpend,
  averageSpend,
}: CustomerValueWidgetProps) {
  const levelConfig = config.get(ranking) || config.get(CustomerValueRank.None);
  return (
    <HStack alignItems="start" spacing="6" data-testid="CustomerValueWidget">
      <Box>
        <HStack spacing="1">
          <Text color="gray.600" fontSize="12px" fontWeight="semibold">
            Consumer value measure
          </Text>
          <Tooltip
            label="Consumer value measure is calculated by summing the net Consumer Lifetime Revenue (CLR) for all consumers and then organizing them into 5 tiers based on CLR."
            placement="top"
          >
            <chakra.span>
              <Icon iconImage={IconImage.info} color="action" boxSize="4" />
            </chakra.span>
          </Tooltip>
        </HStack>
        <Text fontSize="16px">{levelConfig?.label}</Text>
        <Text fontSize="12px" fontWeight="semibold" color="gray.600" mt="0.5">
          Total spend in last 365 days{' '}
        </Text>
        <Text fontSize="16px">{formatCurrency(value, currency, true)}</Text>
        <Text fontSize="12px" fontWeight="semibold" color="gray.600" mt="0.5">
          Total all-time spend{' '}
        </Text>
        <Text fontSize="16px">{formatCurrency(totalSpend, currency, true)}</Text>
        <Text fontSize="12px" fontWeight="semibold" color="gray.600" mt="0.5">
          Average annual spend{' '}
        </Text>
        <Text fontSize="16px">{formatCurrency(averageTotalValue, currency, true)}</Text>

        <Text fontSize="12px" fontWeight="semibold" color="gray.600" mt="0.5">
          Average spend per transaction
        </Text>
        <Text fontSize="16px">{formatCurrency(averageSpend, currency, true)}</Text>
      </Box>
      <Flex
        mb="4"
        bg="gray.100"
        rounded="md"
        w="80px"
        h="124px"
        flexDir="column"
        justifyContent="end"
      >
        <Box roundedBottom="md" sx={levelConfig?.sx} />
      </Flex>
    </HStack>
  );
}
export default CustomerValueWidget;
