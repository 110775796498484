import { DataSource } from '../../../lib/api-client/sources/model/DataSource';
import { TestIngestionData } from '../../../lib/api-client/sources/sources.model';

export type TestIngestionResult = {
  isTestIngestionFailure: boolean;
  isTestIngestionRunning: boolean;
  sampleData?: TestIngestionData;
  sampleId?: string;
  reprocessEvents?: () => Promise<void>;
};

type SetupType = 'FLAT_FILE' | 'SALESFORCE_APP' | 'MICROSOFT_DYNAMICS' | 'INTEGRATION';

export function getSetupType(sourceSystem: DataSource['sourceSystem']): SetupType {
  switch (sourceSystem) {
    case 'flatFile':
      return 'FLAT_FILE';
    case 'salesforceApp':
      return 'SALESFORCE_APP';
    case 'MicrosoftDynamics':
      return 'MICROSOFT_DYNAMICS';
    default:
      return 'INTEGRATION';
  }
}
