import { useParams } from 'react-router-dom';
import Header from '../../../components/core/Header/Header';
import DataSourceIcon from '../../../components/features/connections/DataSourceIcon/DataSourceIcon';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import { useCurrentDataSource } from '../context/CurrentDataSourceContext';
import SourceSystemRecordIssueExplorer from './SourceSystemRecordIssueExplorer';

export default function DataQualityIssuesPageDetails() {
  const { id = '' } = useParams();
  const { dataSource } = useCurrentDataSource();

  return (
    <PageLayout
      pageViewEvent={{ page: 'Data Quality Issue', pageId: id }}
      header={
        <Header
          icon={<DataSourceIcon sourceSystem={dataSource.sourceSystem} />}
          title={`${dataSource.name} data quality issues`}
          back={{
            label: 'Back to all data quality issues',
            to: `/sources/${dataSource?.id}/issues`,
          }}
        />
      }
      w="full"
      h="full"
      display="flex"
      flexFlow="column"
    >
      <SourceSystemRecordIssueExplorer />
    </PageLayout>
  );
}
