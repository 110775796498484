import { useState } from 'react';
import { useGetSourceUploads } from '../../../../lib/api-client/sources/SourceData';
import { useCurrentDataSource } from '../../../sources/context/CurrentDataSourceContext';
import DataMappingSetupSection from '../DataMappingSetupSection';
import SetupConnectionGrid from '../SetupConnectionGrid';
import { useTestSample } from '../useTestSample';
import UploadFlatFileSection from './UploadFlatFileSection';

export default function FlatFileSetup() {
  const { dataSource: source } = useCurrentDataSource();
  const sourceId = source.id;
  const { data: uploadsData } = useGetSourceUploads(sourceId);
  const uploads = uploadsData?.content || [];

  const [sampleId, setSampleId] = useState<string>();
  useTestSample(sourceId, sampleId);

  return (
    <SetupConnectionGrid>
      <UploadFlatFileSection
        sourceId={sourceId}
        uploads={uploads}
        onSuccess={(uploadId) => {
          setSampleId(uploadId);
        }}
      />
      <DataMappingSetupSection isDisabled={sampleId == null} />
    </SetupConnectionGrid>
  );
}
