import { Box, BoxProps, Divider, HStack, Text } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface SectionContainerProps {
  children: ReactNode;
  title?: string;
  titleFontSize?: string;
  titleMargin?: string;
  titleSubheader?: ReactNode;
  variant?: 'ghost' | 'box';
  header?: ReactNode;
}
function SectionContainer({
  title,
  titleMargin = '3',
  titleFontSize = 'md',
  children,
  variant = 'ghost',
  header,
  titleSubheader,
  ...rest
}: SectionContainerProps & BoxProps) {
  let variantProps: BoxProps = {};
  if (variant === 'box') {
    variantProps = {
      rounded: 'md',
      border: '1px solid',
      borderColor: 'gray.100',
      boxShadow: 'base',
      padding: 3,
    };
  }
  return (
    <Box data-testid="SectionContainer" {...variantProps} {...rest}>
      {header}
      {title && (
        <HStack justify="space-between">
          <Text fontSize={titleFontSize} lineHeight={5} fontWeight="bold" width="full">
            {title}
          </Text>
          {titleSubheader}
        </HStack>
      )}
      {(header || title) && (
        <Divider
          borderBottomWidth={variant === 'ghost' ? 1 : 0}
          mt={variant === 'ghost' ? 1 : 0}
          mb={titleMargin}
          borderColor="gray.300"
        />
      )}
      {children}
    </Box>
  );
}

export default SectionContainer;
