import { Box, HStack, SimpleGrid, StackProps } from '@chakra-ui/react';
import { formatPercentage } from '../../../../lib/utils/number-utils';
import { isDefined } from '../../../../lib/utils/utils';
import PieChart from '../../../core/data/PieChart/PieChart';
import SimpleStat from '../../../core/data/SimpleStat/SimpleStat';

interface PieChartVisualizationMetric {
  value: number;
  color: string;
  percentage?: number;
  label?: string;
  recordCardStyle?: boolean;
}

interface PieChartVisualizationProps extends StackProps {
  metric1: PieChartVisualizationMetric;
  metric2?: PieChartVisualizationMetric;
  metric3?: PieChartVisualizationMetric;
  metric4?: PieChartVisualizationMetric;
}

function PieChartVisualization({
  metric1,
  metric2,
  metric3,
  metric4,
  ...rest
}: PieChartVisualizationProps) {
  return (
    <HStack {...rest} display="flex">
      <SimpleGrid w="full" spacing={2}>
        <SimpleGrid
          w="full"
          columns={metric1.recordCardStyle ? 0 : 2}
          spacing={metric1.recordCardStyle ? 0 : 0}
        >
          <SimpleStat
            variant="solidDark"
            badge={
              typeof metric1.percentage === 'number'
                ? {
                    value: metric1.percentage,
                    formattedValue: formatPercentage(metric1.percentage, 1, 'closePrecision'),
                    color: metric1.color,
                  }
                : undefined
            }
            label={metric1.label}
            value={metric1.value}
          />
          {metric2 && (
            <SimpleStat
              variant="solid"
              badge={
                typeof metric2.percentage === 'number'
                  ? {
                      value: metric2.percentage,
                      formattedValue: formatPercentage(metric2.percentage, 1, 'closePrecision'),
                      color: metric2.color,
                    }
                  : undefined
              }
              label={metric2.label}
              value={metric2.value}
            />
          )}
          {metric3 && (
            <SimpleStat
              variant="solidDark"
              badge={
                typeof metric3.percentage === 'number'
                  ? {
                      value: metric3.percentage,
                      formattedValue: formatPercentage(metric3.percentage, 1, 'closePrecision'),
                      color: metric3.color,
                    }
                  : undefined
              }
              label={metric3.label}
              value={metric3.value}
            />
          )}
          {metric4 && (
            <SimpleStat
              variant="solidDark"
              badge={
                typeof metric4.percentage === 'number'
                  ? {
                      value: metric4.percentage,
                      formattedValue: formatPercentage(metric4.percentage, 1, 'closePrecision'),
                      color: metric4.color,
                    }
                  : undefined
              }
              label={metric4.label}
              value={metric4.value}
            />
          )}
        </SimpleGrid>
      </SimpleGrid>
      <Box>
        <PieChart
          width={120}
          height={120}
          metrics={[metric1, metric2!, metric3!, metric4!].filter(isDefined)}
        />
      </Box>
    </HStack>
  );
}
export default PieChartVisualization;
