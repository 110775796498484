/* eslint-disable no-prototype-builtins */
import { Box, Button, Flex, HStack, Stack, Text, useDisclosure } from '@chakra-ui/react';
import {
  ColumnDef,
  createColumnHelper,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import consola from 'consola';
import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import DataSourceIcon from '../../../components/features/connections/DataSourceIcon/DataSourceIcon';
import DataExplorer from '../../../components/shared/data-explorer/DataExplorer/DataExplorer';
import DataExplorerSkeleton from '../../../components/shared/data-explorer/DataExplorer/DataExplorerSkeleton';
import DataRecordDetails from '../../../components/shared/data-explorer/DataRecordDetails/DataRecordDetails';
import EditRecordModal from '../../../components/shared/data-explorer/EditRecordModal/EditRecordModal';
import { useDataIssue } from '../../../lib/api-client/data-repair/DataRepairData';
import { recordCategoryToLabel } from '../../../lib/api-client/identity/model/SourceIdentityRecordCategory';
import { recordGroupToLabel } from '../../../lib/api-client/identity/model/SourceIdentityRecordGroup';
import { SourceIdentityRecordSearchResponse } from '../../../lib/api-client/identity/model/SourceIdentityRecordSearchResponse';
import SourceDatabyIdClient from '../../../lib/api-client/reports/SourceDataByID';
import { Page } from '../../../lib/model/common/Page';
import { formatNumber } from '../../../lib/utils/number-utils';
import { isUndefined } from '../../../lib/utils/utils';
import IssueExportCsvButton from '../../data-explorer/components/ExportCsvButton/IssueExportCsvButton';
import {
  ADDRESS_SCORE_RANGES,
  EMAIL_SCORE_RANGES,
  NAME_SCORE_RANGES,
  VALIDITY_RANGES,
} from '../../data-explorer/components/SourceSystemRecordExplorer/description-utils';
import {
  PRIORITY_QUERY_RECORD_CATEGORY,
  PRIORITY_QUERY_RECORD_GROUP,
} from '../DataQualityIssuesPage/DataQualityIssuesPage';
import ColumnCell from '../components/SourceSystemRecordExplorer/ColumnCell';
import ProfileLinkCell from '../components/SourceSystemRecordExplorer/ProfileLinkCell';
import ScoreCell from '../components/SourceSystemRecordExplorer/ScoreCell';
import { useCurrentDataSource } from '../context/CurrentDataSourceContext';
import ReportExplorerQualityIssue from './ReportExplorer';

const columnHelper = createColumnHelper<any>();

const columns = [
  columnHelper.accessor((data) => data?.pin, {
    header: 'PIN',
    id: 'pin',
    minSize: 200,
    enableSorting: false,
    cell: (cellContext) => <ProfileLinkCell cellContext={cellContext} />,
  }),
  columnHelper.accessor((data) => data?.sourceRecordId, {
    header: 'Source Record ID ',
    minSize: 180,
    id: 'sourceRecordId',
    enableSorting: false,
  }),

  columnHelper.accessor((data) => data?.firstNames, {
    header: 'first name ',
    minSize: 180,
    id: 'firstNames',
    enableSorting: false,
    cell: (props) => <ColumnCell cellContext={props} />,
  }),
  columnHelper.accessor((data) => data?.firstNameScores, {
    header: 'First name score ',
    minSize: 180,
    id: 'firstNameScores',
    enableSorting: false,
    cell: (props) => <ScoreCell cellContext={props} labels={NAME_SCORE_RANGES} />,
  }),

  columnHelper.accessor((data) => data?.lastNames, {
    header: 'Last name ',
    minSize: 180,
    id: 'lastNames',
    enableSorting: false,
    cell: (props) => <ColumnCell cellContext={props} />,
  }),
  columnHelper.accessor((data) => data?.lastNameScores, {
    header: 'Last name score ',
    minSize: 180,
    id: 'lastNameScores',
    cell: (props) => <ScoreCell cellContext={props} labels={NAME_SCORE_RANGES} />,

    enableSorting: false,
  }),

  columnHelper.accessor((data) => data?.middleNames, {
    header: 'Middle name ',
    minSize: 180,
    id: 'middleNames',
    enableSorting: false,
    cell: (props) => <ColumnCell cellContext={props} />,
  }),

  columnHelper.accessor((data) => data?.nameSuffixes, {
    header: 'Name suffix ',
    minSize: 180,
    id: 'nameSuffixes',
    enableSorting: false,
  }),

  columnHelper.accessor((data) => data?.emailAddresses, {
    header: 'Email Address',
    minSize: 280,
    id: 'emailAddresses',
    enableSorting: false,
    cell: (props) => <ColumnCell cellContext={props} />,
  }),
  columnHelper.accessor((data) => data.emailAddressScores, {
    header: 'Email address score',
    minSize: 280,
    cell: (props) => <ScoreCell cellContext={props} labels={EMAIL_SCORE_RANGES} />,
    id: 'emailAddressScores',
    enableSorting: false,
  }),
  columnHelper.accessor(
    (data) => {
      const mobileIndex = data.phoneNumberTypes.indexOf('MOBILE');
      if (mobileIndex !== -1) {
        return data.phoneNumbers[mobileIndex];
      }
      return '';
    },
    {
      header: 'Mobile phone',
      minSize: 20,
      id: 'mobilePhoneNumber',
      enableSorting: false,
      cell: (props) => <ColumnCell cellContext={props} />,
    }
  ),

  columnHelper.accessor(
    (data) => {
      const mobileIndex = data.phoneNumberTypes.indexOf('MOBILE');
      if (mobileIndex !== -1) {
        return data.phoneNumberScores[mobileIndex];
      }
      return '';
    },
    {
      header: 'Mobile phone score',
      minSize: 180,
      id: 'mobilePhoneNumberScores',
      cell: (props) => <ScoreCell cellContext={props} labels={VALIDITY_RANGES} />,
      enableSorting: false,
    }
  ),

  columnHelper.accessor(
    (data) => {
      const homeIndex = data.phoneNumberTypes.indexOf('HOME');
      if (homeIndex !== -1) {
        return data.phoneNumbers[homeIndex];
      }
      return '';
    },
    {
      header: 'Home phone',
      minSize: 20,
      id: 'homePhoneNumber',
      enableSorting: false,
      cell: (props) => <ColumnCell cellContext={props} />,
    }
  ),

  columnHelper.accessor(
    (data) => {
      const mobileIndex = data.phoneNumberTypes.indexOf('HOME');
      if (mobileIndex !== -1) {
        return data.phoneNumberScores[mobileIndex];
      }
      return '';
    },
    {
      header: 'Home phone score',
      minSize: 180,
      id: 'homePhoneNumberScores',
      cell: (props) => <ScoreCell cellContext={props} labels={VALIDITY_RANGES} />,
      enableSorting: false,
    }
  ),
  columnHelper.accessor(
    (data) => {
      const workIndex = data.phoneNumberTypes.indexOf('WORK');
      if (workIndex !== -1) {
        return data.phoneNumbers[workIndex];
      }
      return '';
    },
    {
      header: 'Work phone',
      minSize: 20,
      id: 'workPhoneNumber',
      enableSorting: false,
      cell: (props) => <ColumnCell cellContext={props} />,
    }
  ),

  columnHelper.accessor(
    (data) => {
      const mobileIndex = data.phoneNumberTypes.indexOf('WORK');
      if (mobileIndex !== -1) {
        return data.phoneNumberScores[mobileIndex];
      }
      return '';
    },
    {
      header: 'Work phone score',
      minSize: 180,
      id: 'workPhoneNumberScores',
      cell: (props) => <ScoreCell cellContext={props} labels={VALIDITY_RANGES} />,
      enableSorting: false,
    }
  ),

  columnHelper.accessor((data) => data?.cities, {
    header: 'City ',
    minSize: 180,
    id: 'cities',
    enableSorting: false,
    cell: (props) => <ColumnCell cellContext={props} />,
  }),

  columnHelper.accessor((data) => data?.countries, {
    header: 'Country ',
    minSize: 180,
    id: 'countries',
    enableSorting: false,
    cell: (props) => <ColumnCell cellContext={props} />,
  }),

  columnHelper.accessor((data) => data?.countryCodes, {
    header: 'Country code ',
    minSize: 180,
    id: 'countryCodes',
    enableSorting: false,
    cell: (props) => <ColumnCell cellContext={props} />,
  }),

  columnHelper.accessor((data) => data?.governingDistricts, {
    header: 'Governing district ',
    minSize: 180,
    id: 'governingDistricts',
    enableSorting: false,
    cell: (props) => <ColumnCell cellContext={props} />,
  }),
  columnHelper.accessor((data) => data?.localMunicipalities, {
    header: 'Local municipality ',
    minSize: 180,
    id: 'localMunicipalities',
    enableSorting: false,
    cell: (props) => <ColumnCell cellContext={props} />,
  }),
  columnHelper.accessor((data) => data?.postalCodes, {
    header: 'Postal code ',
    minSize: 180,
    id: 'postalCodes',
    enableSorting: false,
    cell: (props) => <ColumnCell cellContext={props} />,
  }),

  columnHelper.accessor((data) => data?.addressScores, {
    header: ' Address score',
    minSize: 180,
    id: 'addressScores',
    cell: (props) => <ScoreCell cellContext={props} labels={ADDRESS_SCORE_RANGES} />,

    enableSorting: false,
  }),

  columnHelper.accessor((data) => data?.dateOfBirth, {
    header: 'Date of birth',
    minSize: 20,
    id: 'dateOfBirth',
    enableSorting: false,
  }),

  columnHelper.accessor((data) => data?.dateOfBirthScore, {
    header: 'Date of birth score',
    minSize: 20,
    id: 'dateOfBirthScore',
    cell: (props) => <ScoreCell cellContext={props} labels={VALIDITY_RANGES} />,
    enableSorting: false,
  }),
  columnHelper.accessor((data) => data?.gender, {
    header: 'Gender',
    minSize: 20,
    id: 'gender',
    enableSorting: false,
  }),

  columnHelper.accessor((data) => data?.fieldNames, {
    header: 'field Names',
    minSize: 20,
    id: 'fieldNames',
    enableSorting: false,
    cell: (props) => <ColumnCell cellContext={props} />,
  }),

  columnHelper.accessor((data) => data?.fieldValues, {
    header: 'Field Values',
    minSize: 20,
    id: 'fieldValues',
    enableSorting: false,
    cell: (props) => <ColumnCell cellContext={props} />,
  }),
];

type FetchParams = {
  search?: any;
  pagination: PaginationState;
  sorting: SortingState;
};

export default function SourceSystemRecordIssueExplorer() {
  const { dataSource } = useCurrentDataSource();
  const [searchParams, setSearchParams] = useSearchParams();
  const recordGroup = searchParams.get(PRIORITY_QUERY_RECORD_GROUP) ?? '';
  const recordCategory = searchParams.get(PRIORITY_QUERY_RECORD_CATEGORY) ?? '';
  const [showReportView, setShowReportView] = useState(
    searchParams.get('reportView')?.toLowerCase() === 'true'
  );

  const [modifiedData, setModifiedData] = useState<Page<SourceIdentityRecordSearchResponse>>();

  const searchParamsString = searchParams.toString();
  const { pathname } = window.location;
  const pageURL = `${window.location.origin}${pathname}?${searchParamsString}`;
  const [fetchParams, setFetchParams] = useState<FetchParams>({
    search: {},
    pagination: {
      pageIndex: 0,
      pageSize: 100,
    },
    sorting: [{ id: 'scores_recordScore', desc: true }],
  });

  const { data: sourceIssue, loading: loadingIssue }: any = useDataIssue(
    dataSource.id,
    recordCategory
  );

  let filteredColumns;
  if (sourceIssue?.content) {
    const phoneNumberTypes = sourceIssue.content.flatMap(
      (content: any) => content?.phoneNumberTypes
    );
    const phoneNumberScores = sourceIssue.content.flatMap(
      (content: any) => content?.phoneNumberScores
    );
    const hasMobile = phoneNumberTypes?.includes('MOBILE');
    const hasHome = phoneNumberTypes?.includes('HOME');
    const hasWork = phoneNumberTypes?.includes('WORK');

    filteredColumns = columns.filter((item) => {
      if (
        (item.id === 'mobilePhoneNumber' && hasMobile) ||
        (item.id === 'homePhoneNumber' && hasHome) ||
        (item.id === 'workPhoneNumber' && hasWork) ||
        (item.id === 'mobilePhoneNumberScores' && hasMobile) ||
        (item.id === 'homePhoneNumberScores' && hasHome) ||
        (item.id === 'workPhoneNumberScores' && hasWork)
      ) {
        return true;
      }
      return sourceIssue.content.some(
        (content: { hasOwnProperty: (arg0: string | undefined) => any }) =>
          content?.hasOwnProperty(item.id)
      );
    });

    const mobileScoreIndex = phoneNumberTypes?.indexOf('MOBILE');
    const homeScoreIndex = phoneNumberTypes?.indexOf('HOME');
    const workScoreIndex = phoneNumberTypes?.indexOf('WORK');
    if (mobileScoreIndex !== -1 && homeScoreIndex !== -1 && workScoreIndex !== -1) {
      const mobileScore = phoneNumberScores?.[mobileScoreIndex];
      const homeScore = phoneNumberScores?.[homeScoreIndex];
      const workScore = phoneNumberScores?.[workScoreIndex];
      sourceIssue.content[0].phoneNumberScores = [mobileScore, homeScore, workScore];
    }
  }

  const onFetcherParamChange = useCallback(
    (params: FetchParams) => {
      setModifiedData(undefined);
      setFetchParams({ search: fetchParams.search, ...params });
    },
    [fetchParams.search]
  );

  const editRecordDisclosure = useDisclosure();
  const editDetailsRecordDisclosure = useDisclosure();
  const [exportDataResponse, setExportDataResponse] = useState();
  const [editRecordIndex, setEditRecordIndex] = useState<any>();

  const handleExportSubmit = async (sourceRecordID: any) => {
    try {
      const response = await SourceDatabyIdClient.exportIssueReportData(
        dataSource.id,
        sourceRecordID
      );

      setExportDataResponse(response);
    } catch (error) {
      consola.error(error);
    }
  };

  useEffect(() => {
    if (searchParams.get('reportView')?.toLowerCase() !== 'true') {
      setShowReportView(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  useEffect(() => {
    if (showReportView) {
      searchParams.set('reportView', 'true');
    } else {
      searchParams.delete('reportView');
    }
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showReportView, searchParams]);

  if (!dataSource || loadingIssue) {
    return <DataExplorerSkeleton />;
  }

  const handleCloseEditModal = () => {
    editRecordDisclosure.onClose();
    window.location.reload();
  };

  return (
    <Flex data-testid="SourceSystemRecordExplorer" flexDir="column" h="full">
      {!showReportView && (
        <SectionContainer variant="box" mb={4}>
          <HStack>
            <Icon iconImage={IconImage.info} color="highlightDark" />
            <Flex w="full" justify="space-between">
              <Stack spacing={0}>
                <Text fontWeight="bold">
                  Below is a sample set of the data quality issues detected.
                </Text>
                <Text>
                  If you’d like to access the full set, you can opt to load it here or export a CSV.
                </Text>
              </Stack>
              <HStack>
                <Button variant="outline" onClick={() => setShowReportView(true)}>
                  Load all records
                </Button>
                <IssueExportCsvButton
                  reportParams={{ sourceIssueType: recordCategory }}
                  sourceId={dataSource?.id}
                />
              </HStack>
            </Flex>
          </HStack>
        </SectionContainer>
      )}

      {!showReportView && (
        <>
          <HStack spacing={3} mb={4}>
            <DataSourceIcon sourceSystem={dataSource.sourceSystem} boxSize={12} />
            <Box>
              <Text fontWeight="bold" lineHeight={8}>
                {recordGroupToLabel(recordGroup)}: {recordCategoryToLabel(recordCategory as any)}
              </Text>
              <Text lineHeight="6">{formatNumber(sourceIssue?.totalElements)} data records</Text>
            </Box>
          </HStack>
          <>
            <DataExplorer
              data={sourceIssue}
              columns={(filteredColumns ?? []) as ColumnDef<any>[]}
              defaultSortOrder={fetchParams.sorting}
              defaultPagination={fetchParams.pagination}
              onFetcherParamChange={onFetcherParamChange}
              pageUrl={pageURL}
              onRowSelected={(e) => {
                handleExportSubmit(e.data?.sourceRecordId);
                setEditRecordIndex(e.data?.sourceRecordId);
                editRecordDisclosure.onOpen();
              }}
              onRowSelectedDataDetails={(e: any) => {
                handleExportSubmit(e.data?.sourceRecordId);
                setEditRecordIndex(e.data?.sourceRecordId);
                editDetailsRecordDisclosure.onOpen();
              }}
              hideEditMode
              hideColumn
              hideFilters
            />

            <EditRecordModal
              isOpen={editRecordDisclosure.isOpen}
              onClose={handleCloseEditModal}
              data={isUndefined(editRecordIndex) ? undefined : exportDataResponse}
              onRowValueChanged={(editedRecord) => {
                const d = modifiedData ?? exportDataResponse;
                const content = Array.isArray(d) ? [...d] : [];
                if (!isUndefined(editRecordIndex)) {
                  content[editRecordIndex] = editedRecord;
                }
                if (d) {
                  setModifiedData({ ...d, content } as SetStateAction<
                    Page<SourceIdentityRecordSearchResponse> | undefined
                  >);
                }
              }}
            />

            <DataRecordDetails
              isOpen={editDetailsRecordDisclosure.isOpen}
              onClose={() => editDetailsRecordDisclosure.onClose()}
              data={exportDataResponse}
              editRecordModalOpen={editRecordDisclosure.onOpen}
              editRecordModalClose={editDetailsRecordDisclosure.onClose}
            />
          </>
        </>
      )}

      {showReportView && (
        <ReportExplorerQualityIssue reportId={recordCategory} reportParams={fetchParams as any} />
      )}
    </Flex>
  );
}
