const ButtonStyle = {
  sizes: {
    sm: {
      fontSize: 'sm',
    },
  },
  variants: {
    solid: (props: any) => {
      const { colorScheme } = props;
      return {
        bgColor: `${colorScheme}.600`,
        color: 'white',
        _hover: {
          bg: `${colorScheme}.900`,
          _disabled: {
            bgColor: `${colorScheme}.600`,
          },
        },
      };
    },
    outline: (props: any) => {
      const { colorScheme } = props;
      return {
        borderColor: `${colorScheme}.600`,
        color: `${colorScheme}.600`,
        _active: {
          bg: 'transparent',
        },
        _hover: {
          bg: 'transparent',
          borderColor: `${colorScheme}.700`,
          color: `${colorScheme}.700`,
          _disabled: {
            bg: 'transparent',
            borderColor: `${colorScheme}.600`,
            color: `${colorScheme}.600`,
          },
        },
      };
    },
    ghost: () => ({
      color: 'action',
      px: '0',
      _active: {
        bg: 'transparent',
      },
      _hover: {
        bg: 'transparent',
        color: 'actionDark',
        _disabled: {
          color: 'action',
        },
      },
    }),
  },
  defaultProps: {
    size: 'sm',
    variant: 'solid',
    colorScheme: 'actionScheme',
  },
};

export default ButtonStyle;
