import { Box, chakra, Circle, HStack, StyleProps, Tooltip } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { varColorWrapper } from '../../../lib/utils/color-utils';
import { isDefined } from '../../../lib/utils/utils';
import PieChartVisualization from '../../shared/visualizations/PieChartVisualization/PieChartVisualization';
import SingleRadialBarChartVisualization from '../../shared/visualizations/SingleRadialBarChartVisualization/SingleRadialBarChartVisualization';
import Icon from '../Icon/Icon';
import { IconImage } from '../Icon/IconConfig';
import Card from '../card/Card/Card';
import CardBadge from '../card/Card/CardBadge';
import CardTitle from '../card/Card/CardTitle';

export interface SourceSystemCards {
  title: string;
  buttonTitle?: string;
  badgeScore?: number;
  badgeTitle?: string;
  titleContent?: string;
  bgColor?: string;
  badgeBgColor?: any;
  realData?: string;
  realDataValue: number;
  realDataValuePercentage?: number;
  unrealDataValuePercentage?: number;
  unrealDataValue?: number;
  unrealData?: string;
  usibilityValue?: number;
  usibilityValuePercentage?: number;
  usibility?: string;
  unusibilityValue?: number;
  unusibilityValuePercentage?: number;
  unusibility?: string;
  badge?: boolean;
  pieRadial?: boolean;
  buttonDisable?: boolean;
  visualizationVariant?: string;
  cardAction?: ReactNode;
  recordStyle?: boolean;
  tooltip?: boolean;
  tooltipContent?: string;
}
export default function SourceSystemCard({
  title,
  badgeScore,
  badgeTitle,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  titleContent,
  bgColor,
  badge,
  realData,
  realDataValue,
  realDataValuePercentage,
  unrealDataValuePercentage,
  unrealDataValue,
  unrealData,
  usibilityValue,
  usibilityValuePercentage,
  usibility,
  unusibilityValue,
  unusibilityValuePercentage,
  unusibility,
  pieRadial,
  visualizationVariant,
  cardAction,
  recordStyle,
  tooltip,
  tooltipContent,
}: SourceSystemCards) {
  const cardStyles: StyleProps = {};
  if (badge) {
    cardStyles.pt = 6;
  }

  return (
    <Card data-testid="SourceSystemCard" {...cardStyles} w="full">
      {badge && (
        <CardBadge p={0.5} bgColor={bgColor}>
          <Circle size={5} bgColor="white" mr={1} color={bgColor}>
            {badgeScore}
          </Circle>
          {badgeTitle}
        </CardBadge>
      )}
      <HStack justifyContent="space-between">
        <CardTitle>{title}</CardTitle>
        {tooltip && (
          <Tooltip label={tooltipContent} placement="top">
            <chakra.span>
              <Icon iconImage={IconImage.help} color="actionDark" boxSize="5" />
            </chakra.span>
          </Tooltip>
        )}
      </HStack>
      <Box>
        {pieRadial ? (
          <PieChartVisualization
            metric1={{
              value: realDataValue ?? Number.NaN,
              percentage: realDataValuePercentage,
              color: varColorWrapper('success'),
              label: realData,
              recordCardStyle: recordStyle,
            }}
            metric2={
              isDefined(usibilityValue)
                ? {
                    value: usibilityValue ?? Number.NaN,
                    percentage: usibilityValuePercentage,
                    color: varColorWrapper('gray-200'),
                    label: usibility,
                  }
                : undefined
            }
            metric3={
              isDefined(unrealDataValue)
                ? {
                    value: unrealDataValue ?? Number.NaN,
                    percentage: unrealDataValuePercentage,
                    color: varColorWrapper('warning'),
                    label: unrealData,
                  }
                : undefined
            }
            metric4={
              isDefined(unusibilityValue)
                ? {
                    value: unusibilityValue ?? Number.NaN,
                    percentage: unusibilityValuePercentage,
                    color: varColorWrapper('error'),
                    label: unusibility,
                  }
                : undefined
            }
          />
        ) : (
          <SingleRadialBarChartVisualization
            variant={visualizationVariant}
            primaryMetric={{
              value: realDataValue,
              label: realData,
              percentage: realDataValuePercentage,
            }}
            secondaryMetric={
              isDefined(unrealDataValue)
                ? {
                    value: unrealDataValue!,
                    label: unrealData,
                    percentage: unrealDataValuePercentage,
                  }
                : undefined
            }
          />
        )}
      </Box>
      {cardAction}
    </Card>
  );
}
