import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../context/AuthenticationContext';
import LocalStorageService from '../../../../lib/services/LocalStorageService';
import { SearchProfileForm, searchProfileFormSchema } from '../../models/SearchProfileForm';

interface SearchModalProps {
  isOpen: boolean;
  onClose: () => void;
  searchLocation?: 'Header' | 'Dashboard';
  handleRecentSearching?: (props: SearchProfileForm) => void;
}

export default function SearchModal({
  isOpen,
  onClose,
  searchLocation,
  handleRecentSearching,
}: SearchModalProps) {
  const { tenantId } = useAuth();
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<SearchProfileForm>({
    resolver: yupResolver(searchProfileFormSchema),
    mode: 'onBlur',
  });
  const navigate = useNavigate();
  const [searchEnabled, setSearchEnabled] = useState(false);

  watch((data) => {
    if (data) {
      setSearchEnabled(Object.entries(data).some(([, value]) => !!value));
    }
  });
  const onSubmit: SubmitHandler<SearchProfileForm> = async (data) => {
    const searchData = {
      firstName: data.firstName || undefined,
      lastName: data.lastName || undefined,
      emailAddress: data.emailAddress || undefined,
      phone: data.phone || undefined,
      accountId: data.accountId || undefined,
    };
    const item: any =
      LocalStorageService.getItem(`seviin.${tenantId}.search-recent-searches`) == null
        ? '[]'
        : LocalStorageService.getItem(`seviin.${tenantId}.search-recent-searches`);
    const value = JSON.parse(item);

    if (value.length >= 3) {
      value.unshift(searchData);
      const newArray = value.slice(0, 3);
      LocalStorageService.setItem(
        `seviin.${tenantId}.search-recent-searches`,
        JSON.stringify(newArray)
      );
    } else {
      value.unshift(searchData);
      LocalStorageService.setItem(
        `seviin.${tenantId}.search-recent-searches`,
        JSON.stringify(value)
      );
    }
    if (handleRecentSearching) {
      handleRecentSearching(data);
    }

    onClose();
    navigate('/profiles/search', { state: { searchData, searchLocation } });
  };

  return (
    <Modal isCentered size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent data-testid="SearchModal">
          <ModalHeader fontWeight="bold" fontSize="20px">
            Find a consumer profile
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <SimpleGrid columns={3} spacing={6} mr="10" w="full">
              <FormControl isInvalid={!!errors.firstName}>
                <FormLabel fontWeight="semibold">First name</FormLabel>
                <Input autoFocus {...register('firstName')} />
                <FormErrorMessage>{errors?.firstName?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.lastName}>
                <FormLabel fontWeight="semibold">Last name</FormLabel>
                <Input {...register('lastName')} />
                <FormErrorMessage>{errors?.lastName?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.emailAddress}>
                <FormLabel fontWeight="semibold">Email address</FormLabel>
                <Input {...register('emailAddress')} />
                <FormErrorMessage>{errors?.emailAddress?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.phone}>
                <FormLabel fontWeight="semibold">Phone number</FormLabel>
                <Input {...register('phone')} />
                <FormErrorMessage>{errors?.phone?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.accountId}>
                <FormLabel fontWeight="semibold">Loyalty number</FormLabel>
                <Input {...register('accountId')} />
                <FormErrorMessage>{errors?.accountId?.message}</FormErrorMessage>
              </FormControl>
            </SimpleGrid>
          </ModalBody>
          <ModalFooter justifyContent="flex-start">
            <Button type="submit" disabled={!searchEnabled} variant="solid">
              Search
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}
