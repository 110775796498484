import { chakra, HStack, Text, Tooltip } from '@chakra-ui/react';
import { FaCircle } from 'react-icons/fa';
import { profileCompletenessColorStrategyCssVar } from '../../../../lib/utils/color-utils';
import { formatNumber } from '../../../../lib/utils/number-utils';

const ChakraFaCircle = chakra(FaCircle);
function InfoTooltip() {
  return (
    <div>
      The completeness rating calculation includes the following identity data fields: first name,
      last name, email address and phone number.
    </div>
  );
}

interface ProfileCompletenessBadgeProps {
  completeness: number;
}
export default function ProfileCompletenessBadge({ completeness }: ProfileCompletenessBadgeProps) {
  return (
    <HStack spacing={1}>
      <ChakraFaCircle color={profileCompletenessColorStrategyCssVar(completeness)} width="3" />
      <Tooltip hasArrow label={<InfoTooltip />} placement="top-end">
        <Text fontSize="xs" fontWeight="semibold" color="gray.600">{`${formatNumber(
          completeness * 100
        )}% complete record`}</Text>
      </Tooltip>
    </HStack>
  );
}
