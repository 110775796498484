import { HStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import Alert, { AlertStatus } from '../../../core/Alert/Alert';
import ChangePassword from '../ChangePassword/ChangePassword';
import TwoFactorAuth from '../TwoFactorAuth/TwoFactorAuth';
import UpdateProfile from '../UpdateProfile/UpdateProfile';

type ResultStates =
  | 'changePasswordSuccess'
  | 'changePasswordError'
  | 'updateProfileError'
  | 'updateProfileSuccess'
  | 'twoFactorEnabled'
  | 'twoFactorDisabled';

const resultAlerts: {
  [key in ResultStates]: {
    status: AlertStatus;
    title?: string;
    description: string;
  };
} = {
  changePasswordSuccess: {
    status: 'success',
    description: 'Your new password has been saved.',
  },
  changePasswordError: {
    status: 'error',
    title: 'We were unable to save your new password due to a system error.',
    description: 'Please try again or come back later. We apologize for the inconvenience.',
  },
  updateProfileError: {
    status: 'error',
    title: 'We were unable to update your profile due to a system error.',
    description: 'Please try again or come back later. We apologize for the inconvenience.',
  },
  updateProfileSuccess: {
    status: 'success',
    description: 'Your profile information has been updated.',
  },
  twoFactorEnabled: {
    status: 'success',
    description: 'You have successfully enabled two-factor authentication.',
  },
  twoFactorDisabled: {
    status: 'success',
    description: 'You have successfully disabled two-factor authentication.',
  },
};

function SecurityTabResultMessages({
  state,
  onClose,
}: {
  state: ResultStates;
  onClose: () => void;
}) {
  const { status, title, description } = resultAlerts[state];
  return (
    <Alert
      status={status}
      title={title}
      description={description}
      closeable
      onClose={onClose}
      mb={10}
    />
  );
}

function SecurityTab({ mfaEnabled }: { mfaEnabled: boolean }) {
  const [result, setResult] = useState<ResultStates>();
  const onChangePasswordSuccess = () => setResult('changePasswordSuccess');
  const onChangePasswordError = () => setResult('changePasswordError');
  const onUpdateProfileError = () => setResult('updateProfileError');
  const onUpdateProfileSuccess = () => setResult('updateProfileSuccess');

  const onTwoFactorSuccess = (twoFactorResult: string) =>
    setResult(twoFactorResult === 'enabled' ? 'twoFactorEnabled' : 'twoFactorDisabled');

  return (
    <>
      {!!result && (
        <SecurityTabResultMessages state={result} onClose={() => setResult(undefined)} />
      )}
      <HStack spacing="10" align="start" data-testid="SecurityTab">
        <UpdateProfile onSuccess={onUpdateProfileSuccess} onError={onUpdateProfileError} />
        <TwoFactorAuth mfaEnabled={mfaEnabled} onSuccess={onTwoFactorSuccess} />
      </HStack>
      <ChangePassword onSuccess={onChangePasswordSuccess} onError={onChangePasswordError} />
    </>
  );
}

export default SecurityTab;
