import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { IdentityRecordTable } from '../../../../pages/profile/ProfileDataRecordsPage/ProfileDataRecordsPage';
import ProfileAuthenticityBadge from '../../../../pages/profile/components/ProfileAuthenticityBadge/ProfileAuthenticityBadge';

interface DataRecordDetailsProps {
  isOpen: boolean;
  editRecordModalOpen: () => void;
  editRecordModalClose: () => void;
  onClose: () => void;
  data: any;
}

export default function DataRecordDetails({
  data = {},
  isOpen,
  onClose,
  editRecordModalOpen,
  editRecordModalClose,
}: DataRecordDetailsProps) {
  const handleClick = () => {
    editRecordModalOpen();
    editRecordModalClose();
  };

  return (
    <Modal isCentered onClose={onClose} isOpen={isOpen} size="lg">
      <ModalOverlay />
      {data.identityRecord && (
        <ModalContent>
          <ModalHeader>Data record details</ModalHeader>
          <ModalCloseButton />
          <HStack pl="6" spacing={4}>
            <HStack>
              <Text size="md" fontWeight="semibold" fontSize="lg">
                PIN:
              </Text>
              <Text textColor="action">{data.identityRecord.pin}</Text>
            </HStack>
            <HStack>
              <Text size="md" fontWeight="semibold" fontSize="lg">
                Source record ID:
              </Text>
              <Text>{data.identityRecord.sourceRecordId}</Text>

              <ProfileAuthenticityBadge
                variant="outline"
                tag={{ tagKey: '', tagScore: data.scores.recordScore }}
                labels={['Accurate record', 'Questionable record', 'Inaccurate record']}
              />
            </HStack>
          </HStack>
          <ModalBody>
            <IdentityRecordTable
              identity={data.identityRecord}
              key={data.identityRecord.recordId}
              record={data}
              hideDataFields
            />
          </ModalBody>
          <ModalFooter justifyContent="start">
            <VStack alignItems="self-start">
              <Text textColor="gray.600" mb={4}>
                Note that all empty data fields are hidden from this view. Check out the version
                history below to see all data fields.
              </Text>
              <HStack spacing={2}>
                <Button
                  as={Link}
                  to={`/sources/${data.identityRecord.sourceId}/records/${data.identityRecord.sourceRecordId}`}
                >
                  See version history
                </Button>
                <Button variant="outline" onClick={handleClick}>
                  Edit record
                </Button>
              </HStack>
            </VStack>
          </ModalFooter>
        </ModalContent>
      )}
    </Modal>
  );
}
