import { chakra, Tag, TagLabel } from '@chakra-ui/react';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import { ProfileTags } from '../../../../lib/api-client/identity/model/Identity';
import { formatNumber } from '../../../../lib/utils/number-utils';
import { isUndefined } from '../../../../lib/utils/utils';

function getAuthenticityAttributes(tag: ProfileTags, labels: string[]) {
  if (!tag) {
    return { label: '', colorScheme: '' };
  }

  if (tag.tagValue === 'authentic') {
    return {
      label: labels[0],
      colorScheme: 'successScheme',
      icon: IconImage.success,
    };
  }

  if (tag.tagValue === 'suspicious') {
    return {
      label: labels[1],
      colorScheme: 'warningScheme',
      icon: IconImage.warning,
    };
  }

  if (tag.tagValue === 'unauthentic') {
    return {
      label: labels[2],
      colorScheme: 'errorScheme',
      icon: IconImage.error,
    };
  }

  if (isUndefined(tag.tagScore)) {
    return { label: '', colorScheme: '' };
  }

  if (tag.tagScore <= 0.1) {
    return {
      label: labels[2],
      colorScheme: 'errorScheme',
      icon: IconImage.error,
    };
  }

  if (tag.tagScore <= 0.5) {
    return {
      label: labels[1],
      colorScheme: 'warningScheme',
      icon: IconImage.warning,
    };
  }

  return {
    label: labels[0],
    colorScheme: 'successScheme',
    icon: IconImage.success,
  };
}

export default function ProfileAuthenticityBadge({
  tag,
  variant = 'solid',
  labels = ['Certified profile', 'Questionable profile', 'Not certified profile'],
}: {
  tag: ProfileTags;
  variant?: string;
  labels?: string[];
}) {
  if (labels.length !== 3) {
    throw new Error('Must have 3 labels');
  }
  const { label, colorScheme, icon } = getAuthenticityAttributes(tag, labels);

  return (
    <Tag
      borderRadius="full"
      colorScheme={colorScheme}
      color={variant === 'solid' ? 'white' : 'gray.600'}
      borderWidth={variant === 'solid' ? 0 : '2px'}
      borderColor={`${colorScheme}.500`}
      bg={variant === 'solid' ? undefined : 'none'}
      fontSize="xs"
      py={0.5}
      px={1}
      pe={1.5}
      minH={5}
    >
      {icon && (
        <Icon
          boxSize={4}
          iconImage={icon}
          color={variant === 'solid' ? 'white' : `${colorScheme}.500`}
          me={1}
        />
      )}
      <TagLabel>
        <chakra.span fontWeight="bold" color={variant === 'solid' ? 'white' : `${colorScheme}.500`}>
          {isUndefined(tag.tagScore)
            ? ''
            : `${formatNumber(tag.tagScore * 100, {
                useGrouping: false,
                maximumFractionDigits: 2,
              })} `}
        </chakra.span>
        <chakra.span fontWeight="normal">{label}</chakra.span>
      </TagLabel>
    </Tag>
  );
}
