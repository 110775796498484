import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
  Tooltip,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import YupPassword from 'yup-password';
import AuthenticationService from '../../../../lib/services/AuthenticationService';
import { hasErrorCode } from '../../../../lib/utils/error-utils';
import Icon from '../../../core/Icon/Icon';
import { IconImage } from '../../../core/Icon/IconConfig';
import SectionContainer from '../../../core/SectionContainer/SectionContainer';
import PasswordTooltip from '../../authentication/PasswordTooltip/PasswordTooltip';

YupPassword(yup);

interface ChangePasswordForm {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

const securityRequirementMessage = 'Password must meet all security requirements';
const changePasswordFormSchema = yup.object({
  oldPassword: yup.string().required('Current password is required'),
  newPassword: yup
    .string()
    .required(securityRequirementMessage)
    .password()
    .min(8, securityRequirementMessage)
    .minNumbers(1, securityRequirementMessage)
    .minUppercase(1, securityRequirementMessage)
    .minLowercase(1, securityRequirementMessage)
    .minSymbols(1, securityRequirementMessage),
  newPasswordConfirmation: yup
    .string()
    .oneOf([yup.ref('newPassword'), undefined], 'Passwords must match'),
});

interface ChangePasswordProps {
  onSuccess: () => void;
  onError?: (err?: any) => void;
}
export default function ChangePassword({ onSuccess, onError }: ChangePasswordProps) {
  const {
    handleSubmit,
    register,
    reset,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<ChangePasswordForm>({
    resolver: yupResolver(changePasswordFormSchema),
    mode: 'onBlur',
  });

  const onSubmit: SubmitHandler<ChangePasswordForm> = async (data) => {
    try {
      await AuthenticationService.changePassword(data.oldPassword, data.newPassword);
      reset();
      onSuccess();
    } catch (err) {
      if (hasErrorCode(err) && err.code === 'NotAuthorizedException') {
        setError('oldPassword', {
          type: 'custom',
          message: 'Password does not match our records',
        });
      } else if (onError) {
        onError(err);
      }
    }
  };
  return (
    <Box mt="10" w="680px" data-testid="ChangePassword">
      <SectionContainer title="Change Password">
        <form onSubmit={handleSubmit(onSubmit)}>
          <SimpleGrid columns={2} spacing={10} mb={10}>
            <FormControl isInvalid={!!errors.oldPassword}>
              <FormLabel fontWeight="semibold">Current password</FormLabel>
              <Input type="password" {...register('oldPassword')} />
              <FormErrorMessage>{errors?.oldPassword?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.newPassword}>
              <FormLabel fontWeight="semibold" display="flex" justifyContent="space-between" mr="0">
                New password
                <Tooltip hasArrow h="120px" label={<PasswordTooltip />} placement="top-end">
                  <span>
                    <Icon iconImage={IconImage.info} cursor="pointer" color="action" />
                  </span>
                </Tooltip>
              </FormLabel>
              <Input type="password" {...register('newPassword')} />
              <FormErrorMessage>{errors?.newPassword?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.newPasswordConfirmation}>
              <FormLabel fontWeight="semibold">Confirm new password</FormLabel>
              <Input type="password" {...register('newPasswordConfirmation')} />
              <FormErrorMessage>{errors?.newPasswordConfirmation?.message}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>
          <Button isLoading={isSubmitting} type="submit">
            Save new password
          </Button>
        </form>
      </SectionContainer>
    </Box>
  );
}
