import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';
import { Page } from '../../model/common/Page';
import ApiClient from '../ApiClient';
import {
  Identity,
  IdentityRecords,
  normalizeIdentity,
  ProfileTags,
  VersionData,
} from './model/Identity';
import { SearchIdentityFilter } from './model/SearchIdentityFilter';
import {
  normalizeSearchIdentityResults,
  SearchIdentityResponse,
} from './model/SearchIdentityResults';
import { SearchIdentityTag } from './model/SearchIdentityTag';
import { SourceIdentityRecordSearch } from './model/SourceIdentityRecordSearch';
import { SourceIdentityRecordSearchResponse } from './model/SourceIdentityRecordSearchResponse';
import { UpdateIdentityRecord } from './model/UpdateIdentityRecord';
import { UpdatePrimaryValueOverrideResponse } from './model/UpdatePrimaryValueOverrideResponse';

export type IdentityRecordSort = 'recordScore,asc' | 'recordScore,desc' | 'pin,desc' | 'pin,asc';
export type IdentitySort = 'tagScore,asc' | 'tagScore,desc';

const IdentityClient = {
  search(filter: SearchIdentityFilter): Promise<SearchIdentityResponse> {
    // FIXME: maybe we create an IdentityService that contains this logic
    const sentryTx = Sentry.startTransaction({
      name: '/identities/_search',
    });
    const span = sentryTx?.startChild({
      op: 'http',
      description: 'POST /identities/_search',
    });

    return ApiClient.post<
      SearchIdentityResponse,
      AxiosResponse<SearchIdentityResponse>,
      SearchIdentityFilter
    >('/identities/_search', {
      filter: {
        firstName: filter.filter.firstName?.trim(),
        lastName: filter.filter.lastName?.trim(),
        phone: filter.filter.phone?.trim(),
        emailAddress: filter.filter.emailAddress?.trim(),
        accountId: filter.filter.accountId?.trim(),
      },
    })
      .then((res) => res.data)
      .then(
        (data) =>
          ({
            status: 'success',
            results: normalizeSearchIdentityResults(data.results),
          } as SearchIdentityResponse)
      )
      .catch((err) => {
        if (err.response?.data && err.response.data.code === 'errors.search.tooManyResults') {
          return {
            status: 'too_many',
            results: [],
          } as SearchIdentityResponse;
        }
        throw err;
      })
      .finally(() => {
        span?.finish();
        sentryTx?.finish();
      });
  },

  exportIdentityRecords(search: SourceIdentityRecordSearch) {
    return ApiClient.post('/identity-records/_search', search, {
      headers: {
        accept: 'text/csv',
      },
      responseType: 'blob',
    }).then((res) => res.data);
  },

  searchIdentityRecords(
    search: SourceIdentityRecordSearch,
    {
      pageNumber,
      pageSize,
      sort,
    }: { pageNumber: number; pageSize: number; sort?: IdentityRecordSort[] } = {
      pageNumber: 0,
      pageSize: 10,
    }
  ): Promise<Page<SourceIdentityRecordSearchResponse>> {
    const params = new URLSearchParams();
    params.append('page', pageNumber.toString());
    params.append('size', pageSize.toString());

    if (sort) {
      sort.forEach((s) => params.append('sort', s));
    }

    return ApiClient.post<Page<SourceIdentityRecordSearchResponse>>(
      '/identity-records/_search',
      search,
      {
        params,
      }
    ).then((res) => res.data);
  },

  searchByTags(
    tags: SearchIdentityTag,
    {
      pageNumber,
      pageSize,
      sort,
    }: { pageNumber: number; pageSize: number; sort?: IdentitySort[] } = {
      pageNumber: 0,
      pageSize: 100,
    }
  ): Promise<SearchIdentityResponse> {
    const params = new URLSearchParams();
    params.append('page', pageNumber.toString());
    params.append('size', pageSize.toString());

    if (sort) {
      sort.forEach((s) => params.append('sort', s));
    }

    return ApiClient.post<
      SearchIdentityResponse,
      AxiosResponse<SearchIdentityResponse>,
      SearchIdentityTag
    >(
      '/identities/_search',
      {
        tags: tags.tags,
        identityRecordCount: tags.identityRecordCount,
      },
      { params }
    )
      .then((res) => res.data)
      .then(
        (data) =>
          ({
            status: 'success',
            results: normalizeSearchIdentityResults(data.results),
            totalResults: data.totalResults,
          } as SearchIdentityResponse)
      )
      .catch((err) => {
        throw err;
      });
  },

  getIdentityByPin(pin: string): Promise<Identity> {
    return ApiClient.get<Identity>(`/identities/${pin}`)
      .then((res) => res.data)
      .then(normalizeIdentity);
  },

  getIdentityRecordsByPin(pin: string): Promise<IdentityRecords> {
    return ApiClient.get<IdentityRecords>(`/identities/${pin}/identity-records`).then(
      (res) => res.data
    );
  },

  getIdentityRecordVersions(
    sourceId: string,
    sourceRecordId: string,
    page?: number,
    size?: number
  ): Promise<Page<VersionData>> {
    const params = new URLSearchParams();
    if (size) {
      params.append('size', size.toString());
    }

    if (page) {
      params.append('page', page.toString());
    }

    return ApiClient.get<Page<VersionData>>(
      `/sources/${sourceId}/identity-records/${sourceRecordId}/versions`,
      {
        params,
      }
    ).then((res) => res.data);
  },
  getIdentityRecordsByPinTag(pin: string): Promise<ProfileTags[]> {
    return ApiClient.get<ProfileTags[]>(`/identities/${pin}/tags`).then((res) => res.data);
  },

  updateIdentityRecord(
    sourceId: string,
    sourceRecordId: string,
    dto: UpdateIdentityRecord
  ): Promise<SourceIdentityRecordSearchResponse> {
    return ApiClient.put<SourceIdentityRecordSearchResponse>(
      `/sources/${sourceId}/identity-records/${sourceRecordId}`,
      dto
    ).then((res) => res.data);
  },

  updatePrimaryValueOverride(
    pin: string,
    attributeName: string,
    attributeValue: string
  ): Promise<UpdatePrimaryValueOverrideResponse> {
    return ApiClient.put<UpdatePrimaryValueOverrideResponse>(
      `/identities/${pin}/primary-value-overrides/${attributeName}`,
      { attributeValue }
    ).then((res) => res.data);
  },

  exportIdentities(pins: string[]) {
    return ApiClient.post(`/identities/_publish`, { pins }).then(() => {});
  },

  revokeToken() {
    return ApiClient.delete(`/api-credentials`).then(() => {});
  },

  getAPICredentials() {
    return ApiClient.get(`/api-credentials`).then((res) => res.data);
  },

  saveAPICredentials(token: string) {
    return ApiClient.post(`/api-credentials`, { token }).then(() => {});
  },
};
export default IdentityClient;
