import { FormControl, FormHelperText, FormLabel } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Combobox from '../../../../components/core/form/Combobox/Combobox';
import { ReportExecutionOption } from '../../../../lib/api-client/reports/report.model';
import { isDefined } from '../../../../lib/utils/utils';

type DataSourceQueryOptionProps = {
  index: number;
  name: string;
  options: { value: string; label: string }[];
  optionValue?: ReportExecutionOption;
};
export default function DataSourceQueryOption({
  index,
  name,
  options,
  optionValue,
}: DataSourceQueryOptionProps) {
  const form = useFormContext();
  const field = `options[${index}]`;
  const [comboboxValue, setComboboxValue] = useState<string[]>(
    optionValue?.arguments?.sourceIds ?? []
  );

  const buildFormValue = (values: string[] = []) =>
    `${name}_sourceIds:${values.filter(isDefined).join(':')}`;

  useEffect(() => {
    if (optionValue && optionValue.arguments) {
      form.setValue(field, buildFormValue(optionValue.arguments.sourceIds));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (options.length < 1) {
    return null;
  }

  return (
    <FormControl>
      <FormLabel>Data record source</FormLabel>
      <Combobox
        placeholder="All"
        multiple
        name={`${name}`}
        value={comboboxValue}
        onChange={(e) => {
          const { value } = e;
          const filteredValues = value.filter((v) => !!v);
          if (filteredValues && filteredValues.length > 0) {
            form.setValue(field, buildFormValue(filteredValues));
          } else {
            setComboboxValue([]);
            form.setValue(field, '');
          }
        }}
        options={options}
      />
      <FormHelperText>Select any number of source systems</FormHelperText>
    </FormControl>
  );
}
