import { HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { ISODateString } from '../../../../lib/model/common';
import { formatNumber } from '../../../../lib/utils/number-utils';

export interface CustomerEventValueProps {
  totalEventValue: number;
  averageTotalEvent: number;
  averageDaysBetweenEvents: string;
  firstEvent: ISODateString;
  mostRecentEvent: ISODateString;
}

function CustomerEventValue({
  totalEventValue,
  averageTotalEvent,
  averageDaysBetweenEvents,
  firstEvent,
  mostRecentEvent,
}: CustomerEventValueProps) {
  return (
    <HStack alignItems="start" spacing="6">
      <VStack
        shadow="md"
        w="340px"
        borderRadius="6px"
        border="1px"
        borderColor="gray.100"
        alignItems="start"
        p="4"
        spacing={1}
      >
        <Text fontWeight="bold" fontSize="xl" pb="1">
          Key insights
        </Text>
        <VStack alignItems="start" spacing={0}>
          <Text fontSize="12px" fontWeight="semibold" color="gray.600" mt="0.5">
            Total events in the last 365 days{' '}
          </Text>
          <Text fontSize="16px">{formatNumber(totalEventValue)}</Text>
        </VStack>
        <VStack alignItems="start" spacing={0}>
          <Text fontSize="12px" fontWeight="semibold" color="gray.600" mt="0.5">
            Average events annually{' '}
          </Text>
          <Text fontSize="16px">{formatNumber(averageTotalEvent)}</Text>
        </VStack>
        {averageDaysBetweenEvents && (
          <VStack alignItems="start" spacing={0}>
            <Text fontSize="12px" fontWeight="semibold" color="gray.600" mt="0.5">
              Average time period between events{' '}
            </Text>
            <Text fontSize="16px">{averageDaysBetweenEvents}</Text>
          </VStack>
        )}
        {mostRecentEvent && (
          <VStack alignItems="start" spacing={0}>
            <Text fontSize="12px" fontWeight="semibold" color="gray.600" mt="0.5">
              Date of most recent event
            </Text>
            <Text fontSize="16px">{mostRecentEvent}</Text>
          </VStack>
        )}
        {firstEvent && (
          <VStack alignItems="start" spacing={0}>
            <Text fontSize="12px" fontWeight="semibold" color="gray.600" mt="0.5">
              Date of first event
            </Text>
            <Text fontSize="16px">{firstEvent}</Text>
          </VStack>
        )}
      </VStack>
    </HStack>
  );
}
export default CustomerEventValue;
