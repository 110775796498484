import { useFetch, useSchema } from '@hazae41/xswr';
import { Page } from '../../model/common/Page';
import { getApiClientSchema } from '../ApiClient';
import { SourceIssue, SourceIssueData } from './model/SourceIssue';
import { SourceIssuePriority } from './model/SourceIssuePriority';

function url(path: string = '') {
  return `/source-issues${path}`;
}

export function getSourceIssues(priority?: SourceIssuePriority) {
  let params;
  if (priority) {
    params = new URLSearchParams({ priority });
  }
  return getApiClientSchema<SourceIssueData>({
    method: 'get',
    url: url(),
    params,
  });
}

export function useSourceIssues(priority?: SourceIssuePriority) {
  const query = useSchema(getSourceIssues, [priority]);
  useFetch(query);
  return query;
}

export function getSourceIssuesForSource(sourceId: string, priority?: SourceIssuePriority) {
  let params;
  if (priority) {
    params = new URLSearchParams({ priority });
  }

  return getApiClientSchema<Pick<Page<SourceIssue>, 'content'>>({
    method: 'get',
    url: url(`/${sourceId}`),
    params,
  });
}

export function useSourceIssuesForSource(sourceId: string, priority?: SourceIssuePriority) {
  const query = useSchema(getSourceIssuesForSource, [sourceId, priority]);
  useFetch(query);
  return query;
}
