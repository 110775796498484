import { IconImage } from '../../components/core/Icon/IconConfig';
import { IdentityAttributesWithComponentKeys } from '../api-client/identity/model/Identity';
import { Nullable } from '../model/common';

/**
 * Types of record scores
 *
 *  - record - default record level scoring
 *  - name - name specific scoring
 *  - email - email specific scoring labels
 *   - address - address specific scoring labels
 *  - validity - binary valid/invalid scoring
 */
export type RecordScoreType =
  | 'record'
  | 'name'
  | 'names'
  | 'emailAddress'
  | 'address'
  | 'validity'
  | 'firstNames';

const IDENTITY_ATTRIBUTE_TO_RECORD_SCORE_TYPE: Partial<
  Record<IdentityAttributesWithComponentKeys, RecordScoreType>
> = {
  firstName: 'name',
  firstNames: 'name',
  firstNameScore: 'names',
  middleName: 'name',
  lastName: 'name',
  lastNames: 'name',
  lastNameScore: 'names',
  names: 'name',
  emailAddresses: 'emailAddress',
  emailAddress: 'emailAddress',
  mobilePhone: 'validity',
  homePhone: 'validity',
  workPhone: 'validity',
  dateOfBirth: 'validity',
  address: 'address',
};

type RecordScoreMappingKey = number | 'unmapped' | 'null' | 'undefined';
type RecordScoreMapping = Partial<Record<RecordScoreMappingKey, { label: string; value: string }>>;

const RECORD_SCORE_MAPPING: RecordScoreMapping = {
  0: { label: 'Inaccurate', value: 'nonverified' },
  1: { label: 'Accurate', value: 'verified' },
  unmapped: { label: 'Questionable', value: 'suspicious' },
};

const NAME_SCORE_MAPPING: RecordScoreMapping = {
  0: { label: 'Unrecognized', value: 'nonverified' },
  1: { label: 'Recognized', value: 'verified' },
  null: { label: 'Unverifiable', value: 'null' },
  unmapped: { label: 'Questionable', value: 'suspicious' },
};

const EMAIL_SCORE_MAPPING: RecordScoreMapping = {
  0: { label: 'Invalid', value: 'nonverified' },
  1: { label: 'Valid', value: 'verified' },
  null: { label: 'Unverifiable', value: 'Unverifiable' },
  unmapped: { label: 'Unverifiable', value: 'suspicious' },
  undefined: { label: 'Unverifiable', value: 'suspicious' },
};

const ADDRESS_SCORE_MAPPING: RecordScoreMapping = {
  0: { label: 'Invalid', value: 'nonverified' },
  1: { label: 'Valid', value: 'verified' },
  null: { label: 'Unverifiable', value: 'null' },
  unmapped: { label: 'Unverifiable', value: 'suspicious' },
  undefined: { label: 'Unverifiable', value: 'suspicious' },
};

const VALIDITY_SCORE_MAPPING: RecordScoreMapping = {
  0: { label: 'Invalid', value: 'nonverified' },
  1: { label: 'Valid', value: 'verified' },
};

/**
 * Get the icon details for a data record score.
 *
 * @param score
 */
export function getRecordScoreIcon(score: Nullable<number>) {
  if (score === 1) {
    return {
      colorScheme: 'successScheme',
      color: 'success',
      icon: IconImage.success,
    };
  }

  if (score === 0) {
    return {
      colorScheme: 'errorScheme',
      color: 'error',
      icon: IconImage.error,
    };
  }

  return {
    colorScheme: 'warningScheme',
    color: 'warning',
    icon: IconImage.warning,
  };
}

/**
 * Get the score label for an identity attribute
 *
 * @param score attribute score
 * @param attribute attribute name
 */
export function getIdentityAttributeScoreLabel(
  score: Nullable<number>,
  attribute: IdentityAttributesWithComponentKeys
) {
  const type = IDENTITY_ATTRIBUTE_TO_RECORD_SCORE_TYPE[attribute];
  if (type !== 'emailAddress' && type !== 'address' && score == null) {
    return '';
  }

  let mappedScore: RecordScoreMappingKey;
  if (typeof score === 'number') {
    mappedScore = score === 1 || score === 0 ? score : 'unmapped';
  } else {
    mappedScore = `${score}`;
  }

  let mapping;
  switch (type) {
    case 'name':
      mapping = NAME_SCORE_MAPPING[mappedScore];
      break;
    case 'names':
      mapping = NAME_SCORE_MAPPING[mappedScore];
      break;
    case 'emailAddress':
      mapping = EMAIL_SCORE_MAPPING[mappedScore];
      break;
    case 'address':
      mapping = ADDRESS_SCORE_MAPPING[mappedScore];
      break;
    case 'validity':
      mapping = VALIDITY_SCORE_MAPPING[mappedScore];
      break;
    default:
      mapping = RECORD_SCORE_MAPPING[mappedScore];
      break;
  }

  return mapping?.label ?? '';
}

const TAG_AUTHENTICITY_SCORE_MAPPING: Record<string, { label: string; value: string }> = {
  authentic: { label: 'Certified', value: 'authentic' },
  suspicious: { label: 'Questionable', value: 'suspicious' },
  unauthentic: { label: 'Not certified', value: 'unauthentic' },
};

export function getIdentityTagValueLabel(value: Nullable<string>, tag: 'authenticity') {
  if (value == null) {
    return null;
  }

  let mapping;
  switch (tag) {
    case 'authenticity':
      mapping = TAG_AUTHENTICITY_SCORE_MAPPING[value];
      break;
    default:
  }

  return mapping?.label ?? '';
}
