import { chakra, Stack, Text, TextProps, Tooltip } from '@chakra-ui/react';
import { FaCircle } from 'react-icons/fa';

const ChakraFaCircle = chakra(FaCircle);

function ActiveTooltip({ tooltipText }: { tooltipText: boolean }) {
  return tooltipText ? (
    <div>
      An active ingestion status means that new data record events are streaming in real-time.
    </div>
  ) : (
    <div>
      An inactive ingestion status means that new data record events are not currently streaming.
    </div>
  );
}

export interface NewActiveIndicatorProps {
  active: boolean;
  showLabel?: boolean;
  width?: string;
  textProps?: TextProps;
  tooltip?: boolean;
  variant?: 'default' | 'reverse';
}
function NewActiveIndicator({
  active,
  showLabel = true,
  width,
  textProps = {},
  tooltip,
  variant = 'default',
}: NewActiveIndicatorProps) {
  const color = active ? 'success' : 'gray.600';
  const text = active ? 'Enabled' : 'Disabled';
  const tp = { fontSize: 'sm', ...textProps };
  return (
    <Tooltip hasArrow label={tooltip && <ActiveTooltip tooltipText={active} />} placement="top-end">
      <Stack
        data-testid="NewActiveIndicator"
        direction={variant === 'reverse' ? 'row-reverse' : 'row'}
        align="center"
        justify="start"
        spacing="1"
      >
        <ChakraFaCircle color={color} width={width} />
        {showLabel && (
          <Text fontWeight="semibold" {...tp}>
            {text}
          </Text>
        )}
      </Stack>
    </Tooltip>
  );
}
export default NewActiveIndicator;
