import { Box, BoxProps } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { usePage } from '../../../../context/PageContext';

export default function StickyBox({ children, ...rest }: { children: ReactNode } & BoxProps) {
  const { headerHeight } = usePage();

  return (
    <Box {...rest} position="sticky" top={`${headerHeight}px`}>
      {children}
    </Box>
  );
}
