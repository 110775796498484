import parsePhoneNumber from 'libphonenumber-js';
import { Nullable } from '../model/common';

/**
 * Format phone number to # ###-###-#### format if it can be parsed. If the
 * number can not be parsed echo the result back
 *
 * @param phoneNumber phone number string
 */
export function formatPhoneNumber(phoneNumber?: Nullable<string>): string {
  if (phoneNumber == null) {
    return '';
  }

  return (
    // we want a space after the international code and dashes everywhere else
    parsePhoneNumber(phoneNumber)?.formatInternational().replaceAll(' ', '-').replace('-', ' ') ||
    phoneNumber
  );
}

/**
 * Strip the alias (part after +) from an email given
 *
 * @param emailAddress
 */
export function stripEmailAlias(emailAddress: string) {
  const domainIndex = emailAddress.lastIndexOf('@');

  if (domainIndex > -1) {
    const plusIndex = emailAddress.indexOf('+');
    if (plusIndex > -1) {
      return `${emailAddress.slice(0, plusIndex)}@${emailAddress.slice(domainIndex + 1)}`;
    }
  }
  return emailAddress;
}

/**
 * Generate a one time passcode QR code URI.
 * From https://github.com/google/google-authenticator/wiki/Key-Uri-Format
 *
 * @param secret
 * @param username
 * @param issuer defaults to 'seviin.ai'
 */
export function generateOtpQrValue(
  secret: string,
  username: string,
  issuer: string = 'seviin.ai'
): string {
  return `otpauth://totp/${issuer}:${username}?secret=${secret}&issuer=${issuer}`;
}
