import { useFallback, useFetch, useSchema } from '@hazae41/xswr';
import config from '../../../config';
import { Page } from '../../model/common/Page';
import { getApiClientSchema } from '../ApiClient';
import { IdentitySearchRequest, IdentitySearchResponse } from './identity.model';
import { Identity, IdentityRecord, ProfileTags } from './model/Identity';

function identityUrl(path = '') {
  return `/identities${path}`;
}

export function getIdentityByPin(pin: string) {
  return getApiClientSchema<Identity>({
    method: 'get',
    url: identityUrl(`/${pin}`),
  });
}

export function useGetIdentityByPin(pin: string, defaultIdentity?: Identity) {
  const query = useSchema(getIdentityByPin, [pin]);
  useFetch(query);
  useFallback(query, { data: defaultIdentity });
  return query;
}

export interface ApiCredentials {
  createdAt: string;
  expiresAt: string;
}

export function getApiCredentials() {
  return getApiClientSchema<ApiCredentials>({
    method: 'get',
    url: '/api-credentials',
  });
}

export function useGetApiCredentials() {
  const query = useSchema(getApiCredentials, []);
  useFetch(query);
  return query;
}

export function getIdentityProfileTagsByPin(pin: string) {
  return getApiClientSchema<ProfileTags[]>({
    method: 'get',
    url: identityUrl(`/${pin}/tags`),
  });
}

export function useGetIdentityProfileTagsByPin(pin: string) {
  const query = useSchema(getIdentityProfileTagsByPin, [pin]);
  useFetch(query);
  return query;
}

export function getIdentityRecordsByPin(pin: string) {
  return getApiClientSchema<Pick<Page<IdentityRecord>, 'content'>>({
    method: 'get',
    url: identityUrl(`/${pin}/identity-records`),
  });
}

export function useGetIdentityRecordsByPin(pin: string) {
  const query = useSchema(getIdentityRecordsByPin, [pin]);
  useFetch(query);
  return query;
}

export function searchIdentities(
  search: IdentitySearchRequest,
  options?: { page: number; size: number }
) {
  return getApiClientSchema<IdentitySearchResponse>(
    {
      method: 'post',
      url: identityUrl(`/_search`),
      params: options,
      data: search,
    },
    true,
    config.get().XSWR_MAX_TIMEOUT_MS
  );
}

export function useSearchIdentities(
  search: IdentitySearchRequest,
  options?: { page: number; size: number; sort: string[] }
) {
  const query = useSchema(searchIdentities, [search, options]);
  useFetch(query);
  return query;
}
