import { VStack, Text, Button, HStack } from '@chakra-ui/react';
import { Link, To } from 'react-router-dom';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';

interface RecommendedQueriesCardCardProps {
  label: string;
  iconImage: IconImage;
  to: To;
}

export default function RecommendedQueriesCardCard({
  label,
  iconImage,
  to,
}: RecommendedQueriesCardCardProps) {
  return (
    <SectionContainer variant="box" pb={0}>
      <VStack alignItems="start" spacing={0}>
        <HStack alignItems="start">
          <Icon boxSize="5" iconImage={iconImage} />
          <Text>{label}</Text>
        </HStack>
        <Button as={Link} to={to} pl={7} variant="ghost">
          Run query
        </Button>
      </VStack>
    </SectionContainer>
  );
}
