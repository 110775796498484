import { Button, Tooltip } from '@chakra-ui/react';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import { useAuth } from '../../../../context/AuthenticationContext';

export default function ProfileFeedbackButton({ pin }: { pin: string }) {
  const { tenantId, currentUser } = useAuth();

  const onClick = () => {
    // This is configured in the public/index.html file
    (window as any).showJiraIssueCollector({
      tenantId,
      pin,
      email: currentUser?.emailAddress ?? '',
      name: currentUser?.displayName ?? '',
    });
  };

  return (
    <Tooltip label="Submit feedback about this consumer profile" hasArrow>
      <Button
        variant="outline"
        leftIcon={<Icon iconImage={IconImage.info} boxSize={4} />}
        onClick={onClick}
      >
        Profile feedback
      </Button>
    </Tooltip>
  );
}
