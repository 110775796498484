import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  HStack,
} from '@chakra-ui/react';
import React from 'react';
import { FormProvider, SubmitHandler, useFormContext } from 'react-hook-form';
import { useDataExplorer } from '../../../../components/shared/data-explorer/DataExplorer/DataExplorerContext';

interface ReportExplorerFilterDrawerProps {
  onQueryParameterChange: (queryParameters: any) => void;
}

export default function ReportExplorerFilterDrawer({
  onQueryParameterChange,
  children,
}: ReportExplorerFilterDrawerProps & React.PropsWithChildren) {
  const { filterDrawerDisclosure } = useDataExplorer();
  const form = useFormContext();

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    onQueryParameterChange(data);
    filterDrawerDisclosure.onClose();
  };

  return (
    <Drawer
      isOpen={filterDrawerDisclosure.isOpen}
      onClose={filterDrawerDisclosure.onClose}
      size="md"
      placement="right"
    >
      <DrawerOverlay />
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DrawerContent>
            <DrawerCloseButton
              size="lg"
              right="unset"
              left="-40px"
              fontSize="xs"
              color="white"
              top={10}
              bgColor="action"
              _hover={{ backgroundColor: 'actionDark' }}
              borderTopRightRadius={0}
              borderBottomRightRadius={0}
            />
            <DrawerBody>{children}</DrawerBody>
            <DrawerFooter borderTop="1px solid" borderColor="gray.100">
              <HStack w="full">
                <Button w="full" type="submit">
                  Apply parameters
                </Button>
                <Button
                  variant="outline"
                  w="full"
                  disabled={!form.formState.isDirty}
                  onClick={() => {
                    form.reset();
                  }}
                >
                  Reset parameters
                </Button>
              </HStack>
            </DrawerFooter>
          </DrawerContent>
        </form>
      </FormProvider>
    </Drawer>
  );
}
