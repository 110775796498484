import { dataIssuePercentChangeColorStrategy } from '../../../../lib/utils/color-utils';
import DataFluctuationChip from './DataFluctuationChip';

interface DataIssueFluctuationChipProps {
  value: number;
}
export default function DataIssueFluctuationChip({ value }: DataIssueFluctuationChipProps) {
  const colorScheme = dataIssuePercentChangeColorStrategy(value, 'color');
  return <DataFluctuationChip value={value} colorScheme={colorScheme} />;
}
