import {
  getScrollSchema,
  useFetch,
  useInterval,
  useOnce,
  useSchema,
  useScrollSchema,
} from '@hazae41/xswr';
import { Nullable } from '../../model/common';
import { Page } from '../../model/common/Page';
import instance, { getApiClientSchema } from '../ApiClient';
import { DataSource } from './model/DataSource';
import { DataSourceDomainTypes } from './model/DataSourceDomainTypes';
import { ExportStatus } from './model/ExportStatus';
import { ExternalDataModel, ExternalDataTypeObjectAttribute } from './model/ExternalDataModel';
import { ExternalDataType } from './model/ExternalDataTypes';
import { SourceMergeRules } from './model/MergeRules';
import { SourceSystemType } from './model/SourceTypes';
import { Upload } from './model/Upload';
import { WebHook, WebHookItems } from './model/webHook';
import {
  ExportAuditEvent,
  ExportAuditEventType,
  ExportIngestError,
  IngestionType,
  SourceSystemConfigAttribute,
  StartRemoteIngestionResponse,
  TestIngestionData,
  TestIngestionDetails,
  TestIngestionRecord,
} from './sources.model';

export function getDataSourcesSchema() {
  return getApiClientSchema<Pick<Page<DataSource>, 'content'>>({
    method: 'get',
    url: `/sources`,
  });
}

export function useDataSources() {
  const query = useSchema(getDataSourcesSchema, []);
  useFetch(query);
  return query;
}

export function getDataSourceSchema(
  sourceId: string,
  params?: {
    includeRecommendedAttributeMappings?: boolean;
    uploadId?: string;
  }
) {
  return getApiClientSchema<DataSource>({
    method: 'get',
    url: `/sources/${sourceId}`,
    params,
  });
}

export function useGetDataSource(
  sourceId: string,
  params?: {
    includeRecommendedAttributeMappings?: boolean;
    includeSourceAttributes?: boolean;
    sampleId?: string;
  }
) {
  const query = useSchema(getDataSourceSchema, [sourceId, params]);
  useFetch(query);
  return query;
}

export function getDataSourcesExportStatusSchema(sourceId: string, enabled = true) {
  return getApiClientSchema<ExportStatus>(
    {
      method: 'get',
      url: `/sources/${sourceId}/publish-status`,
    },
    enabled
  );
}

export function useGetDataSourceExportStatus(sourceId: string, enabled: boolean = true) {
  const query = useSchema(getDataSourcesExportStatusSchema, [sourceId, enabled]);
  useFetch(query);
  return query;
}

export function getWebHooksSchema(sourceId: string) {
  return getApiClientSchema<WebHook>({
    method: 'get',
    url: `/sources/${sourceId}/webhooks`,
  });
}

export function useGetWebHooks(sourceId: string) {
  const query = useSchema(getWebHooksSchema, [sourceId]);
  useFetch(query);
  return query;
}

export function getWebHooksSchemaById(sourceId: string, webHookId: string) {
  return getApiClientSchema<WebHookItems>({
    method: 'get',
    url: `/sources/${sourceId}/webhooks/${webHookId}`,
  });
}

export function useGetWebHooksById(sourceId: string, webHookId: string) {
  const query = useSchema(getWebHooksSchemaById, [sourceId, webHookId]);
  useFetch(query);
  return query;
}

export function getExternalDataModelSchema(sourceId: string, domain: DataSourceDomainTypes) {
  return getApiClientSchema<ExternalDataModel>({
    method: 'get',
    url: `/sources/${sourceId}/external-data-model/${domain}`,
  });
}

export function useExternalDataModel(sourceId: string, domain: DataSourceDomainTypes) {
  const query = useSchema(getExternalDataModelSchema, [sourceId, domain]);
  useFetch(query);
  return query;
}

export function getExternalDataModelTypeSchema(
  sourceId: string,
  params?: { domain: ExternalDataType }
) {
  return getApiClientSchema<ExternalDataTypeObjectAttribute[]>({
    method: 'get',
    url: `/sources/${sourceId}/external-data-model`,
    params,
  });
}

export function useExternalDataTypeModel(sourceId: string, params?: { domain: ExternalDataType }) {
  const query = useSchema(getExternalDataModelTypeSchema, [sourceId, params]);

  useFetch(query);
  return query;
}

export function getMergeRuleSchema(sourceId: string) {
  return getApiClientSchema<SourceMergeRules>({
    method: 'get',
    url: `/sources/${sourceId}/merge-rules`,
  });
}

export function useMergeRule(sourceId: string) {
  const query = useSchema(getMergeRuleSchema, [sourceId]);
  useFetch(query);
  return query;
}

export function getExportAuditEvents(
  sourceId: string,
  opt?: {
    page?: number;
    size?: number;
  }
) {
  const params: any = {};
  if (opt?.page != null) {
    params.page = opt.page;
  }

  if (opt?.size != null) {
    params.size = opt.size;
  }

  return getApiClientSchema<Page<ExportAuditEvent>>({
    method: 'get',
    url: `/sources/${sourceId}/publish-audit-events`,
    params,
  });
}

export function useExportAuditEvents(
  sourceId: string,
  opt?: {
    page: number;
    size: number;
  }
) {
  const query = useSchema(getExportAuditEvents, [sourceId, opt]);
  useFetch(query);
  return query;
}

export function getScrollableIngestError(
  sourceId: string,
  filters?: {
    startDate?: string;
    endDate?: string;
  }
) {
  return getScrollSchema(
    (page?: Page<ExportIngestError>) => {
      if (page?.last) {
        return undefined;
      }

      const params: any = {};
      if (filters) {
        if (filters.startDate !== undefined && filters.endDate !== undefined) {
          params.startDate = filters.startDate;
          params.endDate = filters.endDate;
        }
      }

      return {
        method: 'get',
        url: `/sources/${sourceId}/errors`,
        params: {
          page: page ? page.number + 1 : 0,
          size: 100,
          sort: ['ingestionTime,desc'],
          ...params,
        },
      };
    },
    (key) => instance(key)
  );
}

export function useScrollableIngestError(
  sourceId: string,
  filters?: {
    startDate?: string;
    endDate?: string;
  }
) {
  const query = useScrollSchema(getScrollableIngestError, [sourceId, filters]);
  useFetch(query);
  return query;
}

export function getScrollableExportAuditEvents(
  sourceId: string,
  filters?: {
    id?: string;
    eventTypes?: ExportAuditEventType[];
    startTime?: string;
    endTime?: string;
    success?: string;
  }
) {
  return getScrollSchema(
    (page?: Page<ExportAuditEvent>) => {
      if (page?.last) {
        return undefined;
      }

      const params: any = {};

      if (filters?.success !== '') {
        // eslint-disable-next-line prefer-destructuring
        params.success = filters?.success;
      }
      if (filters) {
        if (filters.id != null) {
          params.id = filters.id;
        }

        if (filters.eventTypes != null) {
          // eslint-disable-next-line prefer-destructuring
          params.eventTypes = filters.eventTypes[0];
        }
        if (filters.startTime !== undefined && filters.endTime !== undefined) {
          params.startTime = filters.startTime;
          params.endTime = filters.endTime;
        }
      }

      return {
        method: 'get',
        url: `/sources/${sourceId}/publish-audit-events`,
        params: {
          page: page ? page.number + 1 : 0,
          size: 100,
          sort: ['timestamp,desc', 'eventId'],
          ...params,
        },
      };
    },
    (key) => instance(key)
  );
}

export function useScrollableExportAuditEvents(
  sourceId: string,
  filters?: {
    id?: string;
    eventTypes?: ExportAuditEventType[];
    startTime?: string;
    endTime?: string;
    source?: boolean;
  }
) {
  const query = useScrollSchema(getScrollableExportAuditEvents, [sourceId, filters]);
  useFetch(query);
  return query;
}

export function getTestRecordsSchema(
  sourceId: string,
  opt?: {
    sampleId?: Nullable<string>;
    page?: number;
    size?: number;
  },
  enabled = true
) {
  const params: any = {};
  if (opt?.page != null) {
    params.page = opt.page;
  }

  if (opt?.size != null) {
    params.size = opt.size;
  }

  if (opt?.sampleId != null) {
    params.sampleId = opt.sampleId;
  }

  return getApiClientSchema<Page<TestIngestionRecord>>(
    {
      method: 'get',
      url: `/sources/${sourceId}/tests`,
      params,
    },
    enabled
  );
}

export function useGetTestRecords(
  sourceId: string,
  opt?: {
    sampleId?: Nullable<string>;
    page?: number;
    size?: number;
  },
  enabled = true
) {
  const query = useSchema(getTestRecordsSchema, [sourceId, opt, enabled]);
  useFetch(query);
  return query;
}

export function getSampleDetails(
  sourceId: string,
  params: {
    size?: number;
  },
  enabled: boolean
) {
  return getApiClientSchema<Page<TestIngestionDetails>>(
    {
      method: 'get',
      url: `/sources/${sourceId}/test-samples`,
      params,
    },
    enabled
  );
}

export function useGetSampleDetailsOnce(
  sourceId: string,
  params: {
    size?: number;
  },
  enabled = true
) {
  const query = useSchema(getSampleDetails, [sourceId, params, enabled]);
  useOnce(query);
  return query;
}

export function getSampleData(sourceId: string, sampleId: Nullable<string>, enabled: boolean) {
  return getApiClientSchema<TestIngestionData>(
    {
      method: 'get',
      url: `/sources/${sourceId}/test-samples/${sampleId}`,
    },
    enabled
  );
}

export function useSampleData(
  sourceId: string,
  sampleId: Nullable<string>,
  pollIntervalMs: number,
  enabled = sampleId != null
) {
  const query = useSchema(getSampleData, [sourceId, sampleId, enabled]);
  useFetch(query);
  useInterval(query, !enabled ? 0 : pollIntervalMs);
  return query;
}

export function startRemoteIngestion(sourceId: string, type: IngestionType, enabled: boolean) {
  return getApiClientSchema<StartRemoteIngestionResponse>(
    {
      method: 'post',
      url: `/sources/${sourceId}/_ingest`,
      params: {
        type,
      },
    },
    enabled
  );
}

export function useStartRemoteIngestion(sourceId: string, type: IngestionType, enabled = true) {
  const query = useSchema(startRemoteIngestion, [sourceId, type, enabled]);
  useFetch(query);
  return query;
}

export function getSourceUploadsSchema(sourceId: string, enabled: boolean) {
  return getApiClientSchema<Pick<Page<Upload>, 'content'>>(
    {
      method: 'get',
      url: `/sources/${sourceId}/uploads`,
    },
    enabled
  );
}

export function useGetSourceUploads(sourceId: string, enabled = true) {
  const query = useSchema(getSourceUploadsSchema, [sourceId, enabled]);
  useFetch(query);
  return query;
}

export function useGetSourceUploadsOnInterval(
  sourceId: string,
  pollIntervalMs: number = 0,
  enabled = true
) {
  const query = useSchema(getSourceUploadsSchema, [sourceId, enabled]);
  useFetch(query);
  useInterval(query, enabled ? pollIntervalMs : 0);
  return query;
}

export function getSourceUploadSchema(sourceId: string, uploadId: string, enabled: boolean = true) {
  return getApiClientSchema<Upload>(
    {
      method: 'get',
      url: `/sources/${sourceId}/uploads/${uploadId}`,
    },
    enabled
  );
}

export function useGetSourceUpload(sourceId: string, uploadId: string, enabled = true) {
  const query = useSchema(getSourceUploadSchema, [sourceId, uploadId, enabled]);
  useFetch(query);
  return query;
}

export function getSourceSystemTypeSchema(sourceType: string, enabled: boolean) {
  return getApiClientSchema<SourceSystemType>(
    {
      method: 'get',
      url: `/source-types/${sourceType}`,
    },
    enabled
  );
}

export function useGetSourceSystemType(sourceType: string, enabled = true) {
  const query = useSchema(getSourceSystemTypeSchema, [sourceType, enabled]);
  useFetch(query);
  return query;
}

export function getSourceSystemConfigAttributesSchema(sourceId: string, enabled: boolean) {
  return getApiClientSchema<SourceSystemConfigAttribute[]>(
    {
      method: 'get',
      url: `/sources/${sourceId}/config-attributes`,
    },
    enabled
  );
}

export function useGetSourceSystemConfigAttributes(sourceId: string, enabled = true) {
  const query = useSchema(getSourceSystemConfigAttributesSchema, [sourceId, enabled]);
  useFetch(query);
  return query;
}
