import { Badge, Box, Button, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { Link, To } from 'react-router-dom';
import { formatNumber, formatPercentage } from '../../../lib/utils/number-utils';

interface AttributeCompletenessCardProps {
  label: string;
  complete: number;
  completePercent: number;
  incomplete: number;
  incompletePercent: number;
  to: To;
}

export default function AttributeCompletenessCard({
  label,
  complete,
  completePercent,
  incomplete,
  incompletePercent,
  to,
}: AttributeCompletenessCardProps) {
  const completePercent100 = completePercent * 100;
  const incompletePercent100 = incompletePercent * 100;
  return (
    <Box p={4} w="full" border="1px solid" borderColor="gray.100" boxShadow="sm" borderRadius="6px">
      <Text fontSize="md" fontWeight="semibold" pb={4}>
        {label}
      </Text>
      <Box>
        <Flex>
          {completePercent > 0 && (
            <Box
              w={`${completePercent100 < 3 ? 3 : completePercent100}%`}
              h={6}
              bg="success"
              borderRadius={completePercent100 === 100 ? 10 : 0}
              borderTopLeftRadius={10}
              borderBottomLeftRadius={10}
              border="4px solid"
              borderColor="gray.100"
              borderRightWidth={completePercent100 === 100 ? '4px' : '2px'}
            />
          )}
          {incompletePercent > 0 && (
            <Box
              w={`${incompletePercent100 < 3 ? 3 : incompletePercent100}%`}
              h={6}
              bg="error"
              borderRadius={incompletePercent100 === 100 ? 10 : 0}
              borderTopRightRadius={10}
              borderBottomRightRadius={10}
              border="4px solid"
              borderColor="gray.100"
              borderLeftWidth={incompletePercent100 === 100 ? '4px' : '2px'}
            />
          )}
        </Flex>
        <VStack pt={2} alignItems="flex-start">
          <Box w="60%">
            <Text fontSize="2xl" fontWeight="bold">
              {`${formatNumber(complete)} `}
              <Badge
                w="fit-content"
                h="6"
                pl="2"
                pr="2"
                pt="0.5"
                fontSize="12"
                textColor="white"
                bgColor="success"
                rounded="xl"
              >
                {formatPercentage(completePercent, 1, 'closePrecision')}
              </Badge>
            </Text>
            <Text fontSize="sm" w="170" whiteSpace="nowrap">
              complete records
            </Text>
          </Box>
          <HStack spacing="28">
            <Box w="60%">
              <Text fontSize="2xl" fontWeight="bold">
                {`${formatNumber(incomplete)} `}
                <Badge
                  w="fit-content"
                  h="6"
                  pl="2"
                  pr="2"
                  pt="0.5"
                  fontSize="12"
                  textColor="white"
                  bgColor="error"
                  rounded="xl"
                >
                  {formatPercentage(incompletePercent, 1, 'closePrecision')}
                </Badge>
              </Text>
              <Text fontSize="sm" w="170" whiteSpace="nowrap">
                incomplete records
              </Text>
            </Box>
            <Button variant="outline" as={Link} to={to}>
              Explore
            </Button>
          </HStack>
        </VStack>
      </Box>
    </Box>
  );
}
