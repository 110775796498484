import { Tag, TagLabel } from '@chakra-ui/react';
import Icon from '../../Icon/Icon';
import { IconImage } from '../../Icon/IconConfig';

export default function PrimaryIdentityFieldBadge() {
  return (
    <Tag
      fontSize="xs"
      fontWeight="normal"
      rounded="full"
      py="1px"
      px={1.5}
      bgColor="white"
      border="1px solid"
      borderColor="gray.300"
      color="gray.800"
      minH={4}
    >
      <Icon boxSize={4} iconImage={IconImage.badgeStar} mr={1} color="gray.600" />
      <TagLabel>Primary</TagLabel>
    </Tag>
  );
}
