import { chakra, Flex, Stack, Tag } from '@chakra-ui/react';

interface DataExplorerFilterDescriptionsProps {
  filterLabel?: string;
  descriptions: string[];
}

export default function DataExplorerFilterDescriptions({
  filterLabel = 'Selected filters',
  descriptions,
}: DataExplorerFilterDescriptionsProps) {
  return (
    <Stack>
      {descriptions.length > 0 && (
        <>
          <chakra.span fontWeight="semibold" fontSize="sm">
            {filterLabel}:
          </chakra.span>
          <Flex pb={3} flexWrap="wrap" gap={2}>
            {descriptions.map((description) => (
              <Tag
                key={description}
                fontSize="xs"
                rounded="full"
                bgColor="highlightHover"
                whiteSpace="nowrap"
                fontWeight="normal"
                m={0}
              >
                {description}
              </Tag>
            ))}
          </Flex>
        </>
      )}
    </Stack>
  );
}
