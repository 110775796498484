import {
  Box,
  Button,
  Center,
  chakra,
  Divider,
  HStack,
  Input,
  Select,
  Spinner,
  Stack,
  useBoolean,
} from '@chakra-ui/react';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Header from '../../../components/core/Header/Header';
import DataSourceIcon from '../../../components/features/connections/DataSourceIcon/DataSourceIcon';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import { useScrollableExportAuditEvents } from '../../../lib/api-client/sources/SourceData';
import {
  ExportAuditEvent,
  ExportAuditEventType,
  getExportAuditEventTypeLabel,
} from '../../../lib/api-client/sources/sources.model';
import { Page } from '../../../lib/model/common/Page';
import { DateDifferenceFormatted } from '../../../lib/utils/date-time-utils';
import { formatNumber } from '../../../lib/utils/number-utils';
import { useCurrentDataSource } from '../context/CurrentDataSourceContext';
import Datepicker from './components/PublishAuditLogItem/DatePicker';
import PublishAuditLogItem from './components/PublishAuditLogItem/PublishAuditLogItem';

type LogFilters = {
  id?: string;
  eventTypes?: ExportAuditEventType[];
  startTime?: string;
  endTime?: string;
  success?: string;
};
export default function PublishAuditLogPage() {
  const { id = '' } = useParams();
  const { dataSource } = useCurrentDataSource();
  const [initialLoad, setInitialLoad] = useBoolean(true);
  const [filters, setFilters] = useState<LogFilters>({});
  const [idFilter, setIdFilter] = useState<string>('');
  const { data, loading, scroll } = useScrollableExportAuditEvents(id, filters);
  const [lastPage, setLastPage] = useState<Page<ExportAuditEvent>>();
  const location = useLocation();
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const isErrorParam = queryParams.get('error') === 'true';
    setIsError(isErrorParam);
    setFilters((prev) => ({
      ...prev,
      success: isErrorParam ? 'false' : undefined,
    }));
  }, [location.search]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    const isErrorSelected = selectedValue === 'false';
    setIsError(isErrorSelected);
    setFilters((prev) => ({
      ...prev,
      success: isErrorSelected ? 'false' : undefined,
      eventTypes: isErrorSelected ? undefined : [selectedValue as ExportAuditEventType],
    }));
  };

  useEffect(() => {
    if (initialLoad && !loading) {
      setInitialLoad.toggle();
    }
  }, [initialLoad, setInitialLoad, loading]);

  useEffect(() => {
    if (data != null) {
      setLastPage(data.slice(-1).pop());
    }
  }, [data]);

  const onNext = useCallback(async () => {
    await scroll();
  }, [scroll]);

  const onIdChange = (value: string) => {
    setFilters((logFilters) => ({ ...logFilters, id: value.trim() || undefined }));
  };

  const onTimeChange = (value: any) => {
    if (value?.startDate && value?.endDate) {
      const { formattedStartDate, formattedEndDate } = DateDifferenceFormatted(value);

      setFilters((logFilters) => ({
        ...logFilters,
        startTime: formattedStartDate || undefined,
        endTime: formattedEndDate || undefined,
      }));
    } else {
      setFilters((logFilters) => ({
        ...logFilters,
        startTime: undefined,
        endTime: undefined,
      }));
    }
  };

  const debouncedOnIdChange = debounce(onIdChange, 300);

  return (
    <PageLayout
      pageViewEvent={{ page: 'Publish Audit Log', pageId: id }}
      header={
        <Header
          icon={<DataSourceIcon sourceSystem={dataSource.sourceSystem} />}
          title={`${dataSource.name} publish audit log`}
          back={{
            label: 'Back to source data dashboard',
            to: `/sources/${dataSource.id}`,
          }}
        >
          <HStack>
            {lastPage && (
              <>
                <chakra.form px={6} py={3} onSubmit={(e) => e.preventDefault()}>
                  <HStack w="full" justify="space-between">
                    <Stack direction="row" spacing={7}>
                      <Input
                        maxW={64}
                        value={idFilter}
                        placeholder="Search by record ID or PIN"
                        onChange={(event) => {
                          setIdFilter(event.target.value);
                          debouncedOnIdChange(event.target.value);
                        }}
                      />
                      <Select
                        maxW={64}
                        value={isError ? 'false' : filters.eventTypes?.[0] || ''}
                        placeholder="All publish activity"
                        onChange={handleSelectChange}
                      >
                        <option value="IDENTITY_CREATED">
                          {getExportAuditEventTypeLabel('IDENTITY_CREATED')}
                        </option>
                        <option value="IDENTITY_UPDATED">
                          {getExportAuditEventTypeLabel('IDENTITY_UPDATED')}
                        </option>
                        <option value="IDENTITY_MERGED">
                          {getExportAuditEventTypeLabel('IDENTITY_MERGED')}
                        </option>
                        <option value=" IDENTITY_REFERENCE_UPDATED">
                          {getExportAuditEventTypeLabel('IDENTITY_REFERENCE_UPDATED')}
                        </option>
                        <option value="TRANSACTION_IDENTITY_REMOVED">
                          {getExportAuditEventTypeLabel('TRANSACTION_IDENTITY_REMOVED')}
                        </option>
                        <option value="TRANSACTION_UPDATED">
                          {getExportAuditEventTypeLabel('TRANSACTION_UPDATED')}
                        </option>
                        <option value="TRANSACTION_LINE_ITEM_CREATED">
                          {getExportAuditEventTypeLabel('TRANSACTION_LINE_ITEM_CREATED')}
                        </option>
                        <option value="false">Error</option>
                      </Select>
                    </Stack>
                  </HStack>
                </chakra.form>
                <HStack justifyContent="space-between" w="510px">
                  <Datepicker onTimeChange={onTimeChange} />
                  <Box>{loading && <Spinner size="sm" borderColor="action" />}</Box>
                  <chakra.span fontSize="xs" fontWeight="semibold" color="gray.600" ml="120px">
                    {`Showing ${lastPage.number * lastPage.size + 1} - ${Math.min(
                      (lastPage.number + 1) * lastPage.size,
                      lastPage.totalElements
                    )} of ${formatNumber(lastPage.totalElements)} `}
                  </chakra.span>
                </HStack>
              </>
            )}
          </HStack>
        </Header>
      }
      loading={initialLoad}
    >
      {data && (
        <Stack divider={<Divider />} spacing={3}>
          {data
            .flatMap((page) => page.content)
            .map((event) => (
              <PublishAuditLogItem
                key={event.eventId}
                event={event}
                externalSourceLink={
                  event.sourceRecordId
                    ? dataSource.externalLinks?.sourceIdentity?.replace(
                        '{id}',
                        event.sourceRecordId
                      )
                    : undefined
                }
                searchText={filters.id}
              />
            ))}
          {!lastPage?.last && (
            <Center>
              <Button isLoading={loading} onClick={onNext}>
                See next{' '}
                {lastPage
                  ? Math.min(100, lastPage.totalElements - (lastPage.number + 1) * lastPage.size)
                  : ''}
              </Button>
            </Center>
          )}
        </Stack>
      )}
    </PageLayout>
  );
}
