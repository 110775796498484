import React from 'react';
import Alert from '../../../components/core/Alert/Alert';
import { Can } from '../../../context/AuthorizationContext';
import { DataSource } from '../../../lib/api-client/sources/model/DataSource';
import ConnectionManagementHeader from '../../sources/components/ConnectionManagementHeader/ConnectionManagementHeader';
import FlatFileSetup from './FlatFileSetup/FlatFileSetup';
import IntegrationSetup from './IntegrationSetup/IntegrationSetup';
import MicrosoftDynamicsSetup from './MicrosoftDynamicsSetup/MicrosoftDynamicsSetup';
import SalesforceAppSetup from './SalesforceAppSetup/SalesforceAppSetup';
import { getSetupType } from './SetupConnectionPage.utils';
import { TestIngestionResultProvider } from './TestIngestionResultContext';

interface SetupConnectionPageProps {
  source: DataSource;
  onError: () => void;
}

function useSetup(sourceSystem: string, onError: () => void) {
  const setupType = getSetupType(sourceSystem);

  switch (setupType) {
    case 'FLAT_FILE':
      return <FlatFileSetup />;
    case 'SALESFORCE_APP':
      return <SalesforceAppSetup />;
    case 'MICROSOFT_DYNAMICS':
      return <MicrosoftDynamicsSetup onError={onError} />;
    case 'INTEGRATION':
    default:
      return <IntegrationSetup onError={onError} />;
  }
}

export default function SetupConnectionPage({ source, onError }: SetupConnectionPageProps) {
  const setup = useSetup(source.sourceSystem ?? '', onError);
  return (
    <>
      <ConnectionManagementHeader source={source} />
      <Alert
        status="warning"
        description="Complete the connection process to start ingesting data."
        mb={3}
      />
      <TestIngestionResultProvider>
        <Can I="update" a="DataSource">
          {setup}
        </Can>
      </TestIngestionResultProvider>
    </>
  );
}
