import {
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Select,
  Stack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ReportExecutionPredicate,
  ReportPredicateOperator,
} from '../../../../lib/api-client/reports/report.model';

function getControlValues(
  options: { label: string; value: string }[],
  predicateValue?: ReportExecutionPredicate
) {
  if (!predicateValue) {
    return null;
  }

  const value = options.find(
    (option) =>
      option.value === `${predicateValue.operator}.${predicateValue.arguments?.join('_') ?? ''}`
  );
  return {
    value: value?.value ?? 'CUSTOM',
    operator: predicateValue.operator,
    filterValue: predicateValue.arguments?.[0] ?? '',
    betweenFilterValue: predicateValue.arguments?.[1] ?? '',
  };
}

interface NumericalQueryFilterProps {
  label: string;
  customLabel?: string;
  columnName: string;
  helperText?: string;
  index: number;
  options: { label: string; value: string }[];
  predicateValue?: ReportExecutionPredicate;
}

export default function NumericalQueryFilter({
  label,
  predicateValue,
  customLabel = 'Custom',
  columnName,
  index,
  options,
  helperText,
}: NumericalQueryFilterProps) {
  const controlValues = getControlValues(options, predicateValue);
  const [showCustom, setShowCustom] = useState(controlValues?.value === 'CUSTOM');
  const [showBetweenValue, setShowBetweenValue] = useState(controlValues?.operator === 'BETWEEN');
  const [listValue, setListValue] = useState(controlValues?.value ?? '');
  const [operator, setOperator] = useState<ReportPredicateOperator>(
    controlValues?.operator ?? 'GREATER_THAN_OR_EQUAL'
  );
  const [filterValue, setFilterValue] = useState(controlValues?.filterValue ?? '');
  const [betweenFilterValue, setBetweenFilterValue] = useState(
    controlValues?.betweenFilterValue ?? ''
  );
  const form = useFormContext();
  const field = `filters[${index}]`;
  const buildFormValue = (
    operatorParam = operator,
    filterValueParam = filterValue,
    betweenFilterValueParam = betweenFilterValue
  ) => {
    let optional = '';
    if (betweenFilterValueParam) {
      optional = `_${betweenFilterValueParam}`;
    }

    return `${columnName}.${operatorParam}.${filterValueParam}${optional}`;
  };

  useEffect(() => {
    if (predicateValue) {
      form.setValue(field, buildFormValue());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack w="full">
      <FormControl>
        <FormLabel>{label}</FormLabel>
        <Select
          placeholder="All"
          value={listValue}
          onChange={(event) => {
            const { value } = event.target;
            setListValue(value);
            if (value === 'CUSTOM') {
              setShowCustom(true);
              form.setValue(field, '');
            } else if (value) {
              setShowCustom(false);
              form.setValue(field, `${columnName}.${event.target.value}`);
            } else {
              setShowCustom(false);
              form.setValue(field, '');
            }
          }}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
          <option value="CUSTOM">{customLabel}</option>
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
      {showCustom && (
        <FormControl>
          <Select
            value={operator}
            onChange={(event) => {
              const { value } = event.target;
              setOperator(value as ReportPredicateOperator);

              if (value === 'BETWEEN') {
                setShowBetweenValue(true);
                form.setValue(field, `${columnName}.${value}.${filterValue}.${betweenFilterValue}`);
              } else {
                setShowBetweenValue(false);
                setBetweenFilterValue('');
                form.setValue(field, `${columnName}.${value}.${filterValue}`);
              }
            }}
          >
            <option value="GREATER_THAN_OR_EQUAL">Greater than or equal to</option>
            <option value="LESS_THAN_OR_EQUAL">Less than or equal to</option>
            <option value="EQUALS">Equal to</option>
            <option value="BETWEEN">Between</option>
          </Select>
        </FormControl>
      )}
      {showCustom && (
        <HStack>
          <Input
            w="full"
            type="number"
            placeholder="Enter a number"
            value={filterValue}
            onChange={(event) => {
              const { value } = event.target;
              setFilterValue(event.target.value);
              form.setValue(field, `${columnName}.${operator}.${value}`);
            }}
          />
          {showBetweenValue && (
            <Input
              w="full"
              type="number"
              placeholder="Enter a number"
              value={betweenFilterValue}
              onChange={(event) => {
                const { value } = event.target;
                setBetweenFilterValue(event.target.value);
                form.setValue(field, `${columnName}.${operator}.${filterValue}_${value}`);
              }}
            />
          )}
        </HStack>
      )}
    </Stack>
  );
}
