import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useBoolean } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import AdminConfigurationsTab from '../../../components/features/account-settings/AdminConfigurationsTab/AdminConfigurationsTab';
import SecurityTab from '../../../components/features/account-settings/SecurityTab/SecurityTab';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import { useAuth } from '../../../context/AuthenticationContext';
import { Can, useAbility } from '../../../context/AuthorizationContext';
import CustomerValueSettingsClient from '../../../lib/api-client/customer-value-settings/CustomerValueSettingsClient';
import { CustomerValueSettings } from '../../../lib/api-client/customer-value-settings/model/customer-value-settings';
import AuthenticationService from '../../../lib/services/AuthenticationService';
import AuthorizationService from '../../../lib/services/AuthorizationService';
import ApiCredentialsTab from '../components/ApiCredentialsTab/ApiCredentialsTab';

function AccountSettingsPage() {
  const [mfaEnabled, setMfaEnabled] = useState<boolean>(false);
  const [customerValueSettings, setCustomerValueSettings] = useState<CustomerValueSettings>();

  const [isLoading, setLoading] = useBoolean(true);
  const [initializationError, setInitializationError] = useState(false);
  const { currentUser, tenantName } = useAuth();
  const ability = useAbility();

  useEffect(() => {
    const isMfaEnabled = AuthenticationService.isSoftwareTokenMfaEnabled().then(setMfaEnabled);
    const allRequests = [isMfaEnabled];
    if (ability.can('update', 'AdminConfig')) {
      const cltvSettings = CustomerValueSettingsClient.getCustomerValueSettings()
        .then((settings) =>
          // add default variable cost percentage to form if none in response
          ({
            ...settings,
            variableCostPercentage:
              settings.variableCostPercentage && settings.variableCostPercentage.length > 0
                ? settings.variableCostPercentage
                : [{ costPercentage: 0, costCategory: '' }],
          })
        )
        .then(setCustomerValueSettings);
      allRequests.push(cltvSettings);
    }

    Promise.all(allRequests)
      .catch(() => setInitializationError(true))
      .finally(setLoading.off);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout
      pageViewEvent={{ page: 'Account Settings' }}
      header={<Header title="Account settings" icon={<Icon iconImage={IconImage.settings} />} />}
      initializationError={initializationError}
      loading={isLoading}
      data-testid="AccountSettingsPage"
    >
      <Box mb="10">
        <Text mb="2" fontWeight="bold" fontSize="2xl">
          {tenantName}
        </Text>
        <Text lineHeight="6">
          {currentUser?.displayName} (
          {AuthorizationService.getRoleLabelFromUserGroups(currentUser?.groups)})
        </Text>
      </Box>
      <Can I="manage" a="AdminConfig" passThrough>
        {(isAllowed) => (
          <Tabs variant="line" colorScheme="#005874">
            <TabList>
              {isAllowed && <Tab>Admin configurations</Tab>}
              <Tab data-testid="ProfileTab">Profile</Tab>
              <Tab data-testid="ApiCredentials">API credentials</Tab>
            </TabList>
            <TabPanels pt={6}>
              {isAllowed && (
                <TabPanel p={0}>
                  <AdminConfigurationsTab initialCustomerValueSettings={customerValueSettings} />
                </TabPanel>
              )}
              <TabPanel p={0}>
                <SecurityTab mfaEnabled={mfaEnabled} />
              </TabPanel>
              <TabPanel p={0}>
                <ApiCredentialsTab />
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
      </Can>
    </PageLayout>
  );
}
export default AccountSettingsPage;
