import { Box, BoxProps, Text } from '@chakra-ui/react';
import { CustomerValue } from '../../../../lib/api-client/customer-value/model/CustomerValue';
import { Profile } from '../../../../lib/api-client/profile/model/Profile';
import CustomerValueWidget from '../CustomerValueWidget/CustomerValueWidget';

interface Props {
  customerValue: CustomerValue;
  profile: Profile;
}

export type ProfilePageLTVProps = Props & BoxProps;

function ProfilePageLifetimeValue({ customerValue, profile, ...rest }: ProfilePageLTVProps) {
  return (
    <Box
      data-testid="ProfilePageLTV"
      {...rest}
      shadow="md"
      w="340px"
      borderRadius="6px"
      border="1px"
      borderColor="gray.100"
      alignItems="start"
      h="300px"
      p="4"
    >
      <Text fontWeight="bold" fontSize="xl" pb="3">
        Spend insights
      </Text>
      <CustomerValueWidget
        ranking={customerValue.ranking}
        value={profile.transactionSummary.currentAnnualSpend}
        averageTotalValue={profile.transactionSummary.averageAnnualSpend}
        currency={customerValue.currency}
        totalSpend={profile.transactionSummary.totalSpend}
        averageSpend={profile.transactionSummary.averageSpend}
      />
    </Box>
  );
}
export default ProfilePageLifetimeValue;
