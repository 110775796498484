import { Stack, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useReportMetadataForReport } from '../../../lib/api-client/reports/ReportExecutionData';
import { ReportMetadata } from '../../../lib/api-client/reports/report.model';
import CertificationTypeQueryFilter from '../components/ReportExplorer/CertificationTypeQueryFilter';
import DateRangeQueryOption from '../components/ReportExplorer/DateRangeQueryOption';
import LocationQueryOption from '../components/ReportExplorer/LocationQueryOption';
import NumericalQueryFilter from '../components/ReportExplorer/NumericalQueryFilter';
import QueryLimit from '../components/ReportExplorer/QueryLimit';
import QuerySorts from '../components/ReportExplorer/QuerySorts';
import RelativeDaysQueryFilter from '../components/ReportExplorer/RelativeDaysQueryFilter';
import { buildReportParametersFromSearchParams } from '../components/ReportExplorer/ReportExplorer.utils';
import ReportExplorerFilterDrawer from '../components/ReportExplorer/ReportExplorerFilterDrawer';

function extractLocationsFromReportMetadata(reportMetadata?: ReportMetadata) {
  return (
    reportMetadata?.options
      ?.find((o) => o.name === 'locationNameList')
      ?.arguments.find((l) => l.name === 'locationNames')
      ?.values?.map((v) => ({ value: v.value, label: v.displayName })) ?? []
  );
}

function extractColumnsFromReportMetadata(reportMetadata?: ReportMetadata) {
  return (
    reportMetadata?.columns?.map((column) => ({
      label: column.displayName,
      value: column.name,
    })) ?? []
  );
}

export default function TransactionSummaryReportFilterDrawer() {
  const [queryParams] = useSearchParams();
  const reportParams = buildReportParametersFromSearchParams(queryParams);

  const { data: reportMetadata } = useReportMetadataForReport(
    'TRANSACTION_SUMMARY_BY_PROFILE',
    true
  );
  const locations = extractLocationsFromReportMetadata(reportMetadata);
  const sortOptions = extractColumnsFromReportMetadata(reportMetadata);

  const [, setQueryParams] = useSearchParams();
  const form = useForm<any>({
    defaultValues: {},
  });

  return (
    <FormProvider {...form}>
      <ReportExplorerFilterDrawer
        onQueryParameterChange={(value) => {
          const newParams: any = { execute: 'true' };
          if (value.filters) {
            newParams.filters = value.filters.filter((filter: any) => !!filter);
          }

          if (value.limit) {
            newParams.limit = value.limit;
          }

          if (value.options) {
            newParams.options = value.options.filter((option: any) => !!option);
          }

          if (value.sort) {
            newParams.sort = value.sort.map((s: any) => `${s.column},${s.sort}`);
          }

          setQueryParams(newParams);
        }}
      >
        <Text fontWeight="bold" fontSize="xl" pt={4}>
          Query parameters
        </Text>
        <Stack spacing={4} pt={5} pb={6}>
          <NumericalQueryFilter
            index={0}
            columnName="totalSpend"
            label="Total all-time spend"
            predicateValue={reportParams.filter?.predicates?.find(
              (p) => p.columnName === 'totalSpend'
            )}
            customLabel="Custom amount"
            options={[
              { label: 'Less than $1,000', value: 'LESS_THAN.1000' },
              { label: '$1,000 or greater', value: 'GREATER_THAN_OR_EQUAL.1000' },
              { label: '$5,000 or greater', value: 'GREATER_THAN_OR_EQUAL.5000' },
              { label: '$10,000 or greater', value: 'GREATER_THAN_OR_EQUAL.10000' },
              { label: '$15,000 or greater', value: 'GREATER_THAN_OR_EQUAL.15000' },
              { label: '$20,000 or greater', value: 'GREATER_THAN_OR_EQUAL.20000' },
            ]}
          />
          <LocationQueryOption
            index={1}
            name="locationNameList"
            options={locations}
            optionValue={reportParams.options?.find(
              (option) => option.optionName === 'locationNameList'
            )}
          />
          <NumericalQueryFilter
            index={2}
            columnName="locationCount"
            label="Transaction location count"
            predicateValue={reportParams.filter?.predicates?.find(
              (p) => p.columnName === 'locationCount'
            )}
            customLabel="Custom count"
            options={[
              { label: 'Only 1 location', value: 'EQUALS.1' },
              { label: '2 or more locations', value: 'GREATER_THAN_OR_EQUAL.2' },
              { label: '2 or fewer locations', value: 'LESS_THAN_OR_EQUAL.2' },
              { label: '3 or more locations', value: 'GREATER_THAN_OR_EQUAL.3' },
              { label: '3 or fewer locations', value: 'LESS_THAN_OR_EQUAL.3' },
            ]}
          />
          <DateRangeQueryOption
            label="Transaction date"
            name="txDateRange"
            index={3}
            optionValue={reportParams.options?.find(
              (option) => option.optionName === 'txDateRange'
            )}
          />
          <RelativeDaysQueryFilter
            label="Transaction frequency"
            helperText="This is the average number of days between transactions"
            columnName="avgDaysBetweenTx"
            predicateValue={reportParams.filter?.predicates?.find(
              (p) => p.columnName === 'avgDaysBetweenTx'
            )}
            index={4}
          />
          <RelativeDaysQueryFilter
            label="Elapsed time since last transaction"
            columnName="daysSinceLastTx"
            predicateValue={reportParams.filter?.predicates?.find(
              (p) => p.columnName === 'daysSinceLastTx'
            )}
            index={5}
          />
          <CertificationTypeQueryFilter
            index={6}
            columnName="certificationType"
            label="Profile certification"
            predicateValue={reportParams.filter?.predicates?.find(
              (p) => p.columnName === 'certificationType'
            )}
            predicateScoreValue={reportParams.filter?.predicates?.find(
              (p) => p.columnName === 'certificationScore'
            )}
            scoreColumnName="certificationScore"
            customLabel="Specific score range"
            options={[
              { label: 'Certified', value: 'EQUALS.certified' },
              { label: 'Questionable', value: 'EQUALS.questionable' },
              { label: 'Not certified', value: 'EQUALS.uncertified' },
            ]}
          />
        </Stack>
        <Text fontWeight="bold" fontSize="xl">
          Sort query results
        </Text>
        <Text>
          If multiple sort parameters are selected, they will be applied to the data report in the
          order in which they are selected.
        </Text>
        <Stack spacing={6} py={5}>
          <QuerySorts sortOptions={sortOptions} sortValue={reportParams.sortOrder} />
          <QueryLimit value={reportParams.limit} />
        </Stack>
      </ReportExplorerFilterDrawer>
    </FormProvider>
  );
}
