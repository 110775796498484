import { useFetch, useSchema } from '@hazae41/xswr';
import { TimeSeriesAggregate, TimeSeriesIntervalName } from '../../model/common';
import ApiClient, { getApiClientSchema } from '../ApiClient';
import { DataSourceMetrics } from './model/DataSourceMetrics';
import { SystemMetrics } from './model/SystemMetrics';
import {
  DataSourceTimeSeriesMetrics,
  SystemTimeSeriesMetrics,
  TimeSeriesQueryResponse,
} from './model/TimeSeriesQueryResponse';

const SourceMetricsClient = {
  getSystemMetrics(): Promise<SystemMetrics> {
    return ApiClient.get<SystemMetrics>(`/source-metrics`).then((res) => res.data);
  },

  getSourceMetrics(sourceId: string): Promise<DataSourceMetrics> {
    return ApiClient.get<DataSourceMetrics>(`/source-metrics/${sourceId}`).then((res) => res.data);
  },

  getSystemTimeSeriesData(
    metric: SystemTimeSeriesMetrics[],
    startDate: Date,
    endDate: Date,
    timezone: string,
    intervalName: TimeSeriesIntervalName,
    aggregate: TimeSeriesAggregate,
    aggregateSummary?: TimeSeriesAggregate[]
  ): Promise<TimeSeriesQueryResponse> {
    const params = new URLSearchParams();
    metric.forEach((m) => params.append('metric', m));
    params.append('startDate', startDate.toISOString());
    params.append('endDate', endDate.toISOString());
    params.append('timezone', timezone);
    params.append('intervalName', intervalName);
    if (aggregateSummary) {
      aggregateSummary.forEach((s) => params.append('aggregateSummary', s));
    }

    return ApiClient.get<TimeSeriesQueryResponse>(`/identity-time-series`, {
      params,
    }).then((res) => res.data);
  },

  getSourceTimeSeriesData(
    sourceId: string,
    metric: DataSourceTimeSeriesMetrics[],
    startDate: Date,
    endDate: Date,
    timezone: string,
    intervalName: TimeSeriesIntervalName,
    aggregateSummary?: TimeSeriesAggregate[]
  ): Promise<TimeSeriesQueryResponse> {
    const params = new URLSearchParams();
    metric.forEach((m) => params.append('metric', m));
    params.append('startDate', startDate.toISOString());
    params.append('endDate', endDate.toISOString());
    params.append('timezone', timezone);
    params.append('intervalName', intervalName);
    if (aggregateSummary) {
      aggregateSummary.forEach((s) => params.append('aggregateSummary', s));
    }

    return ApiClient.get<TimeSeriesQueryResponse>(`/identity-time-series/${sourceId}`, {
      params,
    }).then((res) => res.data);
  },

  getTimeSeriesData(
    sourceId: string,
    metric: DataSourceTimeSeriesMetrics[],
    startDate: Date,
    endDate: Date,
    timezone: string,
    intervalName: TimeSeriesIntervalName,
    aggregate: TimeSeriesAggregate,
    aggregateSummary?: TimeSeriesAggregate[]
  ): Promise<TimeSeriesQueryResponse> {
    const params = new URLSearchParams();
    metric.forEach((m) => params.append('metric', m));
    params.append('startDate', startDate.toISOString());
    params.append('endDate', endDate.toISOString());
    params.append('timezone', timezone);
    params.append('intervalName', intervalName);
    params.append('aggregate', aggregate);
    if (aggregateSummary) {
      aggregateSummary.forEach((s) => params.append('aggregateSummary', s));
    }

    return ApiClient.get<TimeSeriesQueryResponse>(`/source-metrics/${sourceId}/time-series`, {
      params,
    }).then((res) => res.data);
  },

  getExportTimeSeriesData(
    sourceId: string,
    metric: DataSourceTimeSeriesMetrics[],
    startDate: Date,
    endDate: Date,
    timezone: string,
    intervalName: TimeSeriesIntervalName,
    aggregate: TimeSeriesAggregate,
    aggregateSummary?: TimeSeriesAggregate[],
    eventType?: string
  ): Promise<TimeSeriesQueryResponse> {
    const params = new URLSearchParams();
    metric.forEach((m) => params.append('metric', m));
    params.append('startDate', startDate.toISOString());
    params.append('endDate', endDate.toISOString());
    params.append('timezone', timezone);
    params.append('intervalName', intervalName);
    params.append('aggregate', aggregate);

    if (eventType) {
      params.append('eventType', eventType);
    }
    if (aggregateSummary) {
      aggregateSummary.forEach((s) => params.append('aggregateSummary', s));
    }

    return ApiClient.get<TimeSeriesQueryResponse>(`/publish-metrics/${sourceId}/time-series`, {
      params,
    }).then((res) => res.data);
  },
};

export default SourceMetricsClient;

export function getSystemMetricsSchema() {
  return getApiClientSchema<SystemMetrics>({
    method: 'get',
    url: `/source-metrics`,
  });
}

export function useGetSystemMetrics() {
  const query = useSchema(getSystemMetricsSchema, []);
  useFetch(query);
  return query;
}

export function getSourceMetricsSchema(sourceId: string) {
  return getApiClientSchema<DataSourceMetrics>({
    method: 'get',
    url: `/source-metrics/${sourceId}`,
  });
}

export function useGetSourceMetrics(sourceId: string) {
  const query = useSchema(getSourceMetricsSchema, [sourceId]);
  useFetch(query);
  return query;
}
