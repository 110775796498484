import { dataQualityScorePercentChangeColorStrategy } from '../../../../lib/utils/color-utils';
import DataFluctuationChip from './DataFluctuationChip';

interface DataQualityScoreFluctuationChipProps {
  value: number;
  timePeriod?: string;
}
export default function DataQualityScoreFluctuationChip({
  value,
  timePeriod = '24 hours',
}: DataQualityScoreFluctuationChipProps) {
  const colorScheme = dataQualityScorePercentChangeColorStrategy(value, 'scheme');
  return <DataFluctuationChip timePeriod={timePeriod} value={value} colorScheme={colorScheme} />;
}
