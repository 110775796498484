import { Box, Button, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import EditSourceSystemModal from '../../../../components/features/connections/EditSourceSystemModal/EditSourceSystemModal';
import { Can, useAbility } from '../../../../context/AuthorizationContext';
import SourceClient from '../../../../lib/api-client/sources/SourceClient';
import { DataSource } from '../../../../lib/api-client/sources/model/DataSource';
import { SourceTypes } from '../../../../lib/api-client/sources/model/SourceTypes';

interface EditSourceButtonProps {
  source: Pick<
    DataSource,
    | 'id'
    | 'sourceSystem'
    | 'name'
    | 'category'
    | 'attributeMappings'
    | 'syncDirection'
    | 'domainType'
  >;
  onSuccess: (source: DataSource) => void;
}

function EditSourceButton({ source, onSuccess: onSuccessParent }: EditSourceButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sourceTypes, setSourceTypes] = useState<SourceTypes>({
    systemTypes: [],
    categories: [],
  });
  const ability = useAbility();

  useEffect(() => {
    if (ability.can('update', 'DataSource')) {
      SourceClient.getSourceTypes().then(setSourceTypes);
    }
  }, [ability]);

  const onSuccess = (result: DataSource) => {
    onClose();
    onSuccessParent(result);
  };

  return (
    <Can I="update" a="DataSource">
      <Box data-testid="EditDisplayButton">
        <Button
          variant="ghost"
          onClick={onOpen}
          rightIcon={<Icon iconImage={IconImage.edit} boxSize="4" />}
        >
          Edit or delete source
        </Button>
        <EditSourceSystemModal
          sourceTypes={sourceTypes}
          source={source}
          isOpen={isOpen}
          onClose={onClose}
          onSuccess={onSuccess}
        />
      </Box>
    </Can>
  );
}
export default EditSourceButton;
