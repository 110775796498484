import { useFetch, useRetry, useSchema } from '@hazae41/xswr';
import { getApiClientSchema } from '../ApiClient';
import {
  ReportMetadata,
  ReportPage,
  StartReportExecution,
  StartReportExecutionResponse,
} from './report.model';

export function startReportExecutionSchema(data: StartReportExecution, enabled = true) {
  return getApiClientSchema<StartReportExecutionResponse>(
    {
      method: 'post',
      url: '/report-executions',
      data,
    },
    enabled
  );
}

export function useStartReportExecution(reportParams: StartReportExecution, enabled?: boolean) {
  const query = useSchema(startReportExecutionSchema, [reportParams, enabled]);
  useFetch(query);
  return query;
}

type GetReportOptions = {
  nextToken?: string;
  pageSize: number;
  enabled?: boolean;
};

export function getReport(reportId: string, options: GetReportOptions) {
  return getApiClientSchema<ReportPage>(
    {
      url: `/report-executions/${reportId}`,
      params: {
        maxResults: options.pageSize,
        nextToken: options.nextToken,
      },
      validateStatus: (status) => status === 200,
    },
    options.enabled
  );
}

export function useGetReport(reportId: string, options: GetReportOptions) {
  const query = useSchema(getReport, [reportId, options]);
  useFetch(query);
  useRetry(query, {
    init: 5000,
    base: 1,
    max: 60, // 60 is 5 mins max
  });
  return query;
}

export function getReportMetadataForReport(
  reportType: string,
  includeArgumentValues: boolean = false
) {
  return getApiClientSchema<ReportMetadata>({
    url: `/report-metadata/${reportType}`,
    params: {
      includeArgumentValues,
    },
  });
}

export function useReportMetadataForReport(
  reportType: string,
  includeArgumentValues: boolean = false
) {
  const query = useSchema(getReportMetadataForReport, [reportType, includeArgumentValues]);
  useFetch(query);
  return query;
}

export function startIssueReportExecutionSchema(sourceId: any, data: any, enabled = true) {
  return getApiClientSchema<any>(
    {
      method: 'post',
      url: `/source-issues/${sourceId}/reports`,
      data,
    },
    enabled
  );
}

export function useIssueReportExecution(sourceId: any, enabled?: boolean) {
  const query = useSchema(startIssueReportExecutionSchema, [sourceId, enabled]);
  useFetch(query);
  return query;
}

export function getIssueReport(sourceId: string, reportId: string, options: GetReportOptions) {
  return getApiClientSchema<ReportPage>(
    {
      url: `/source-issues/${sourceId}/reports/${reportId}`,
      params: {
        maxResults: options.pageSize,
        nextToken: options.nextToken,
      },
      validateStatus: (status) => status === 200,
    },
    options.enabled
  );
}

export function useGetIssueReport(sourceId: string, reportId: string, options: GetReportOptions) {
  const query = useSchema(getIssueReport, [sourceId, reportId, options]);
  useFetch(query);
  useRetry(query, {
    init: 5000,
    base: 1,
    max: 60, // 60 is 5 mins max
  });
  return query;
}
