import { chakra, HStack, Stack, Text, Tooltip } from '@chakra-ui/react';
import { FaCircle } from 'react-icons/fa';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import { formatPercentage } from '../../../lib/utils/number-utils';
import DataQualityScoreFluctuationChip from '../components/DataFluctuationChip/DataQualityScoreFluctuationChip';

function getDataQualityScoreColor(score: number) {
  let color = '--chakra-colors-success';
  if (score <= 0.59) {
    color = '--chakra-colors-error';
  } else if (score <= 0.79) {
    color = '--chakra-colors-warning';
  }

  return `var(${color})`;
}

const ChakraFaCircle = chakra(FaCircle);

interface DataQualityScoreProps {
  dataQualityScore: number;
  dataQualityScoreChange: number;
}

export default function DataQualityScore({
  dataQualityScore,
  dataQualityScoreChange,
}: DataQualityScoreProps) {
  const color = getDataQualityScoreColor(dataQualityScore);
  const dataQualityPercentage = formatPercentage(dataQualityScore, 0);
  return (
    <SectionContainer variant="box">
      <Stack>
        <HStack justifyContent="space-between">
          <HStack>
            <ChakraFaCircle color={color} />
            <Text fontSize="md" fontWeight="bold" as="span">
              {dataQualityPercentage}
            </Text>
          </HStack>{' '}
          <Tooltip
            label="This is the percentage of high quality data records ingested from this source that are usable for identity resolution based on data accuracy, completeness and pinnability."
            aria-label="A tooltip"
          >
            <chakra.span>
              <Icon iconImage={IconImage.help} boxSize="5" color="action" />
            </chakra.span>
          </Tooltip>
        </HStack>
        <HStack>
          <Text>data quality score</Text>
        </HStack>
        <HStack>
          <DataQualityScoreFluctuationChip value={dataQualityScoreChange} />
          <Text whiteSpace="nowrap">in the last 24 hours</Text>
        </HStack>
      </Stack>
    </SectionContainer>
  );
}
