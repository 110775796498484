import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import ExportEventsBarChart from '../../../components/features/connections/IngestionHistoryChart/ExportEventsBarChart';
import SimpleBarGraph from '../../../components/shared/visualizations/SimpleBarGraph/SimpleBarGraph';
import { formatNumber } from '../../../lib/utils/number-utils';
import DataQualityScoreFluctuationChip from '../components/DataFluctuationChip/DataQualityScoreFluctuationChip';

function ToolTipContent({ payload, isLastInterval }: any) {
  const exportEvent = payload?.[0]?.value;
  return (
    <VStack alignItems="start" spacing={0}>
      <Text fontWeight="bold">{exportEvent} new data records events </Text>
      <Text>exported during this time window</Text>

      {isLastInterval && (
        <Text mt="4">
          Note that this time window is in progress and does not yet represent a complete data point
        </Text>
      )}
    </VStack>
  );
}

interface AddsAndUpdatesActivityProps {
  timeseriesExportData: any;
  sourceId: string;
  exportData: any;
  sourceName: string;
}
export default function ExportEventsActivity({
  sourceId,
  sourceName,
  timeseriesExportData,
  exportData,
}: AddsAndUpdatesActivityProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <SectionContainer variant="box">
      <SimpleGrid columns={2}>
        <Stack>
          <Text as="span" fontWeight="bold">
            Publish events
          </Text>
          <HStack>
            <Text fontWeight="bold" fontSize="2xl">
              {formatNumber(exportData?.totalEventsLastWeek ?? 0)}
            </Text>
            <DataQualityScoreFluctuationChip
              timePeriod=" 7 days"
              value={exportData?.totalEventsLastWeekChange}
            />
          </HStack>
          <Text>total data record publish events in the last 7 days</Text>

          <Button
            fontWeight="semibold"
            textColor="action"
            variant="link"
            alignSelf="start"
            onClick={onOpen}
          >
            Explore all publish activity
          </Button>
        </Stack>

        <Drawer isOpen={isOpen} size="xl" placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerHeader>
              <HStack justifyContent="space-between">
                <Text>Publish activity for {sourceName}</Text>
                <Button
                  fontWeight="semibold"
                  alignSelf="start"
                  as={Link}
                  to={`/sources/${sourceId}/logs/publish-audit`}
                >
                  Review publish audit log
                </Button>
              </HStack>
            </DrawerHeader>

            <DrawerBody>
              <ExportEventsBarChart
                chartWidth="100%"
                chartHeight={330}
                sourceId={sourceId}
                exportData={exportData}
                hideMetricsCards
              />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <SimpleBarGraph
          data={timeseriesExportData}
          xDataKey="label"
          yDataKey="value"
          highlightLastInterval
          tooltipContent={<ToolTipContent />}
          height={140}
        />
      </SimpleGrid>
    </SectionContainer>
  );
}
