import { HStack, SimpleGrid, Stack, Text, VStack } from '@chakra-ui/react';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';
import { LoyaltyProgram } from '../../../../lib/api-client/profile/model/LoyaltyProgram';
import { formatNumber } from '../../../../lib/utils/number-utils';

interface ProfilePageLoyaltyDataProps {
  loyaltyProgram?: LoyaltyProgram;
}
export default function ProfilePageLoyaltyData({ loyaltyProgram }: ProfilePageLoyaltyDataProps) {
  let card;
  if (!loyaltyProgram) {
    card = (
      <HStack>
        <Icon iconImage={IconImage.unsuccessful} color="error" />
        <Text>No loyalty membership on record</Text>
      </HStack>
    );
  } else {
    card = (
      <SimpleGrid columns={2}>
        <HStack>
          <Icon iconImage={IconImage.success} color="success" />
          <VStack spacing={1} alignItems="start">
            <Text>
              {loyaltyProgram.program && loyaltyProgram.tier
                ? `${loyaltyProgram.program} ${loyaltyProgram.tier} member`
                : 'Loyalty member'}
            </Text>
            <Text>{loyaltyProgram.accountNumber ?? ''}</Text>
          </VStack>
        </HStack>
        {loyaltyProgram.pointsBalance != null && (
          <HStack>
            <Icon iconImage={IconImage.star} />
            <Text>{formatNumber(loyaltyProgram.pointsBalance)} points</Text>
          </HStack>
        )}
      </SimpleGrid>
    );
  }

  return (
    <Stack spacing={2} w="708px">
      <SectionContainer mb={4} variant="box">
        {card}
      </SectionContainer>
    </Stack>
  );
}
