/* eslint-disable no-nested-ternary */

/* eslint-disable react/no-array-index-key */
import {
  Box,
  Button,
  chakra,
  Flex,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FaCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import BubbleChart from '../../../components/core/BubbleChart/BubbleChart';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import SearchModal from '../../../components/shared/modals/SearchModal/SearchModal';
import { useAuth } from '../../../context/AuthenticationContext';
import { useGetIdentitySourceMetrics } from '../../../lib/api-client/source-metrics/IdentitySourceMetricsData';
import { useGetSystemMetrics } from '../../../lib/api-client/source-metrics/SourceMetricsClient';
import { IdentityMetrics } from '../../../lib/api-client/source-metrics/model/IdentityDataSourceMetrics';
import LocalStorageService from '../../../lib/services/LocalStorageService';
import { getFormattedSixMonthAgoDate } from '../../../lib/utils/date-time-utils';
import {
  formatCompactNumber,
  formatNumber,
  formatPercentage,
} from '../../../lib/utils/number-utils';
import DashboardStat from '../components/DashboardStat/DashboardStat';
import DataQualityScoreFluctuationChip from '../components/DataFluctuationChip/DataQualityScoreFluctuationChip';
import DataQualityScoreIndicator from '../components/DataQualityScoreIndicator/DataQualityScoreIndicator';
import DataRefreshFluctuationChip from '../components/DataRefreshFluctuationChip/DataRefreshFluctuationChip';
import ProfileDataReportCard from '../components/ProfileDataReportsCard/ProfileDataReportsCard';
import RecommendedQueriesCardCard from '../components/RecommendedQueriesCard/RecommendedQueriesCard';

function PinTypeValue({ value, label }: { value: number; label: string }) {
  return (
    <HStack>
      <Text fontWeight="bold">{formatNumber(value)}</Text>
      <Text>{label} </Text>
    </HStack>
  );
}
interface IdentityMetricsProps {
  identityMetrics: IdentityMetrics;
}

function QualityScoreSection({ identityMetrics }: IdentityMetricsProps) {
  const { data: useSystemMetricsData }: any = useGetSystemMetrics() ?? [];
  const systemMetrics = useSystemMetricsData?.metrics;

  const systemMetricsPercentValue = Math.abs(
    Math.round(
      (identityMetrics.metrics.identityQualityScore -
        identityMetrics.metrics.identityQualityScoreTarget) *
        100
    )
  );

  return (
    <SectionContainer
      variant="box"
      w="340px"
      p={3}
      header={
        <HStack w="full" justifyContent="space-between">
          <Text fontWeight="bold" fontSize="sm" whiteSpace="nowrap">
            IQ: Identity quality score
          </Text>
          {systemMetrics?.recordEventsIngested?.lastIngestedAt && (
            <DataRefreshFluctuationChip
              value={`${systemMetrics?.recordEventsIngested?.lastIngestedAt}`}
            />
          )}
        </HStack>
      }
    >
      <Stack>
        <HStack spacing={1}>
          <DataQualityScoreIndicator w={3} score={identityMetrics.metrics.identityQualityScore} />
          <chakra.span fontWeight="bold" fontSize="xl">
            {formatPercentage(identityMetrics.metrics.identityQualityScore)}
          </chakra.span>
        </HStack>
        <HStack spacing={1}>
          <DataQualityScoreFluctuationChip
            value={identityMetrics.metrics.dataQualityScoreChange}
            timePeriod="7 days"
          />
          <chakra.span fontSize="sm">in the last 7 days</chakra.span>
        </HStack>
        <Text fontWeight="600" fontSize="12px" color="gray.600">
          key insights
        </Text>
      </Stack>
      <Stack alignItems="start" spacing={2} mt={2}>
        <HStack spacing={0}>
          {identityMetrics.metrics.identityQualityScore <
          identityMetrics.metrics.identityQualityScoreTarget ? (
            <Icon iconImage={IconImage.arrowDown} color="error" boxSize="5" />
          ) : (
            <Icon iconImage={IconImage.arrowUp} color="success" boxSize="5" />
          )}
          <Text>
            {systemMetricsPercentValue}%{' '}
            {identityMetrics.metrics.identityQualityScore <
            identityMetrics.metrics.identityQualityScoreTarget
              ? 'below'
              : 'above'}{' '}
            target benchmark score of{' '}
            {formatPercentage(identityMetrics.metrics.identityQualityScoreTarget)}
          </Text>
        </HStack>
      </Stack>
    </SectionContainer>
  );
}

interface IdentityMetricsProps {
  identityMetrics: IdentityMetrics;
}

function ConsumerPinTypeSection({ identityMetrics }: IdentityMetricsProps) {
  const ChakraFaCircle = chakra(FaCircle);
  const person = identityMetrics?.identityTypes?.person?.totalIdentities ?? 0;
  const household = identityMetrics?.identityTypes?.household?.totalIdentities ?? 0;
  const business = identityMetrics?.identityTypes?.business?.totalIdentities ?? 0;
  const unknown = identityMetrics?.identityTypes?.unknown?.totalIdentities ?? 0;

  return (
    <SectionContainer
      variant="box"
      w="340px"
      p={3}
      header={
        <Text fontWeight="bold" fontSize="sm" whiteSpace="nowrap">
          Consumer profile
        </Text>
      }
    >
      <Stack spacing={-1} mb="4">
        <chakra.span fontWeight="bold" fontSize="xl">
          {formatNumber(identityMetrics.metrics.totalIdentities)}
        </chakra.span>
        <chakra.span fontSize="sm">total profiles </chakra.span>
      </Stack>
      <Stack>
        <Box overflow="clip" rounded="full">
          <Flex>
            {person > 0 && (
              <Box
                h={3}
                bgColor="actionDark"
                w={`${(person / identityMetrics.metrics.totalIdentities) * 100}%`}
              />
            )}
            {household > 0 && (
              <Box
                h={3}
                bgColor="action"
                w={`${(household / identityMetrics.metrics.totalIdentities) * 100}%`}
              />
            )}
            {business > 0 && (
              <Box
                h={3}
                bgColor="highlightDark"
                w={`${(business / identityMetrics.metrics.totalIdentities) * 100}%`}
              />
            )}
            {unknown > 0 && (
              <Box
                h={3}
                bgColor="highlight"
                w={`${(unknown / identityMetrics.metrics.totalIdentities) * 100}%`}
              />
            )}
          </Flex>
        </Box>
        <Stack spacing={1}>
          <HStack>
            <ChakraFaCircle boxSize={3} color="actionDark" />{' '}
            <PinTypeValue value={person} label="Individual consumers" />
          </HStack>
          <HStack>
            <ChakraFaCircle boxSize={3} color="action" />{' '}
            <PinTypeValue value={household} label="Households" />
          </HStack>
          <HStack>
            <ChakraFaCircle boxSize={3} color="highlightDark" />{' '}
            <PinTypeValue value={business} label="Businesses" />
          </HStack>
          <HStack>
            <ChakraFaCircle boxSize={3} color="highlight" />{' '}
            <PinTypeValue value={unknown} label="Unknown" />
          </HStack>
        </Stack>
      </Stack>
    </SectionContainer>
  );
}
function SearchProfileSection() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { tenantId } = useAuth();
  const item: any =
    LocalStorageService.getItem(`seviin.${tenantId}.search-recent-searches`) == null
      ? '[]'
      : LocalStorageService.getItem(`seviin.${tenantId}.search-recent-searches`);
  const value = JSON.parse(item);

  return (
    <SectionContainer
      title=""
      variant="box"
      p={3}
      w="340px"
      header={
        <Text fontWeight="bold" fontSize="sm">
          Find a consumer profile
        </Text>
      }
    >
      <Stack>
        <Stack alignItems="start" spacing={0} fontSize="sm" w="300px">
          <Text fontWeight="600" fontSize="12px" color="gray.600">
            Recent searches
          </Text>
        </Stack>
        <Stack alignItems="start" spacing={0} fontSize="sm">
          {value.map((v: any, index: any) => (
            <Button
              key={index}
              variant="ghost"
              leftIcon={<Icon iconImage={IconImage.search} boxSize="5" color="action" />}
              as={Link}
              to="/profiles/search"
              state={{
                searchData: v,
              }}
            >
              <Text
                fontWeight="normal"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                maxW="300px"
              >
                {v.firstName} {v.lastName} {v.emailAddress && `,${v.emailAddress}`}{' '}
                {v.phone && `,${v.phone}`} {v.accountId && `,${v.accountId}`}
              </Text>
            </Button>
          ))}
        </Stack>
        <Box>
          <Button onClick={onOpen} variant="solid" w="310px" mt="6">
            Start a new search
          </Button>
          <SearchModal isOpen={isOpen} onClose={onClose} searchLocation="Dashboard" />
        </Box>
      </Stack>
    </SectionContainer>
  );
}

function ProfileDashboard() {
  const [identityType, setIdentityType] = useState('all');
  const { data: identityMetrics } = useGetIdentitySourceMetrics();
  const handleIdentityType = (e: any) => {
    setIdentityType(e);
  };
  const identityMetricsValue: any =
    identityType === 'all'
      ? identityMetrics?.metrics?.certificationTypes
      : identityType === 'business'
      ? identityMetrics?.identityTypes?.business?.certificationTypes
      : identityType === 'person'
      ? identityMetrics?.identityTypes?.person?.certificationTypes
      : identityType === 'household'
      ? identityMetrics?.identityTypes?.household?.certificationTypes
      : identityMetrics?.identityTypes?.unknown?.certificationTypes;

  const totalIdentities =
    identityType === 'all'
      ? identityMetrics?.metrics?.totalIdentities
      : identityType === 'business'
      ? identityMetrics?.identityTypes?.business?.totalIdentities
      : identityType === 'person'
      ? identityMetrics?.identityTypes?.person?.totalIdentities
      : identityType === 'household'
      ? identityMetrics?.identityTypes?.household?.totalIdentities
      : identityMetrics?.identityTypes?.unknown?.totalIdentities;

  const icon =
    identityType === 'all'
      ? IconImage.consumer360View
      : identityType === 'business'
      ? IconImage.office
      : identityType === 'person'
      ? IconImage.user
      : identityType === 'household'
      ? IconImage.household
      : IconImage.unknown;

  const calculateSpendPercentage = (nominator: number, denominator: number) =>
    nominator && denominator && denominator > 0
      ? Number(Math.abs((nominator / denominator) * 100).toFixed(2))
      : 0;

  const url = identityType === 'all' ? '?' : `?pinType=${identityType}`;
  const todayDate = new Date();
  const hasTransactionSource =
    identityMetrics?.sources.find((s) => s.source.domainType === 'TRANSACTION') != null;
  return (
    <PageLayout
      pageViewEvent={{ page: 'Profile Dashboard' }}
      header={
        <Header
          title="Profile dashboard"
          icon={<Icon iconImage={IconImage.consumer360View} />}
          noSearch
        />
      }
      loading={!identityMetrics}
    >
      <SimpleGrid columns={3} spacing={4}>
        <QualityScoreSection identityMetrics={identityMetrics!} />
        <ConsumerPinTypeSection identityMetrics={identityMetrics!} />
        <SearchProfileSection />
      </SimpleGrid>
      <SectionContainer title="Profile certification breakdown" mt={8}>
        <Stack mt={4} mb={6}>
          {identityMetrics && (
            <BubbleChart
              identityMetrics={identityMetrics}
              handleIdentityType={handleIdentityType}
            />
          )}
        </Stack>
        <Stack spacing={6}>
          <SimpleGrid columns={4} spacing={3}>
            <DashboardStat
              label="total profiles"
              value={formatNumber(totalIdentities ?? 0)}
              iconImage={icon}
              to={`/data-explorer/profile${url}`}
              toTransactionReportSummary="/reports/transaction-summary?sort=totalSpend,desc"
              totalTimeSpendValue={`$${formatCompactNumber(
                identityMetricsValue?.all?.totalSpend ?? 0
              )}`}
              transactionTooltipLabel="Explore all profiles sorted by total all-time spend"
              profilesTooltipLabel="Explore all profiles"
            />
            <DashboardStat
              label="certified profiles"
              value={formatNumber(identityMetricsValue?.certified?.totalIdentities ?? 0)}
              badgeValue={calculateSpendPercentage(
                identityMetricsValue?.certified?.totalSpend,
                identityMetricsValue?.all?.totalSpend
              )}
              iconImage={icon}
              totalTimeSpendValue={`$${formatCompactNumber(
                identityMetricsValue?.certified?.totalSpend ?? 0
              )}`}
              transactionTooltipLabel="Explore certified profiles sorted by total all-time spend"
              profilesTooltipLabel="Explore certified profiles"
              badge={
                <Icon
                  boxSize="20px"
                  p={1}
                  iconImage={IconImage.checkmark}
                  color="white"
                  bgColor="success"
                  ms="-22px"
                  rounded="full"
                />
              }
              to={`/data-explorer/profile${url}&authenticityValue=authentic`}
              toTransactionReportSummary="/reports/transaction-summary?execute=true&filters=certificationType.EQUALS.certified&sort=totalSpend,desc"
            />
            <DashboardStat
              label="questionable profiles"
              value={formatNumber(identityMetricsValue?.questionable?.totalIdentities ?? 0)}
              badgeValue={calculateSpendPercentage(
                identityMetricsValue?.questionable?.totalSpend,
                identityMetricsValue?.all?.totalSpend
              )}
              iconImage={icon}
              totalTimeSpendValue={`$${formatCompactNumber(
                identityMetricsValue?.questionable?.totalSpend ?? 0
              )}`}
              transactionTooltipLabel="Explore questionable profiles sorted by total all-time spend"
              profilesTooltipLabel="Explore questionable profiles"
              badge={
                <Icon
                  boxSize="20px"
                  p={1}
                  iconImage={IconImage.help}
                  color="white"
                  bgColor="warning"
                  ms="-22px"
                  rounded="full"
                />
              }
              to={`/data-explorer/profile${url}&authenticityValue=suspicious`}
              toTransactionReportSummary="/reports/transaction-summary?execute=true&filters=certificationType.EQUALS.questionable&sort=totalSpend,desc"
            />
            <DashboardStat
              label="not certified profiles"
              value={formatNumber(identityMetricsValue?.uncertified?.totalIdentities ?? 0)}
              badgeValue={calculateSpendPercentage(
                identityMetricsValue?.uncertified?.totalSpend,
                identityMetricsValue?.all?.totalSpend
              )}
              iconImage={icon}
              totalTimeSpendValue={`$${formatCompactNumber(
                identityMetricsValue?.uncertified?.totalSpend ?? 0
              )}`}
              transactionTooltipLabel="Explore not certified profiles sorted by total all-time spend"
              profilesTooltipLabel="Explore not certified profiles"
              badge={
                <Icon
                  boxSize="20px"
                  p={1}
                  iconImage={IconImage.cancel}
                  color="white"
                  bgColor="error"
                  ms="-22px"
                  rounded="full"
                />
              }
              to={`/data-explorer/profile${url}&authenticityValue=unauthentic`}
              toTransactionReportSummary="/reports/transaction-summary?execute=true&filters=certificationType.EQUALS.uncertified&sort=totalSpend,desc"
            />
          </SimpleGrid>
        </Stack>
      </SectionContainer>
      <HStack spacing={4} mt={6} alignItems="start">
        <Stack spacing={4} w="344px">
          <Flex
            justifyContent="space-between"
            borderBottomWidth="1px"
            pb="2"
            borderColor="gray.200"
            w="full"
          >
            <Text fontWeight="bold" fontSize="md">
              Profile data reports
            </Text>
          </Flex>
          <SimpleGrid column={1} w="full" spacing={4}>
            {hasTransactionSource && (
              <ProfileDataReportCard
                iconImage={IconImage.transaction}
                label="Transaction data report"
                content="Explore profiles based on transaction activity and spend behavior. "
                toDefaultReport="/reports/transaction-summary"
                toCustomizeReport="/reports/transaction-summary?execute=false"
              />
            )}
            <ProfileDataReportCard
              iconImage={IconImage.consumerInsights}
              label="Duplicate data report"
              content="Explore identity data records with duplicates across connected source systems."
              toDefaultReport="/reports/duplicates"
              toCustomizeReport="/reports/duplicates?execute=false"
            />
          </SimpleGrid>
        </Stack>
        {hasTransactionSource && (
          <Stack spacing={4} w="760px">
            <Flex
              justifyContent="space-between"
              borderBottomWidth="1px"
              pb="2"
              borderColor="gray.200"
              w="full"
            >
              <Text fontWeight="bold" fontSize="md">
                Recommended queries
              </Text>
            </Flex>
            <SimpleGrid columns={2} w="full" spacing={5}>
              <RecommendedQueriesCardCard
                iconImage={IconImage.transaction}
                label="Top 1,000 profiles based on total all-time spend"
                to="/reports/transaction-summary?sort=totalSpend,desc&limit=1000"
              />
              <RecommendedQueriesCardCard
                iconImage={IconImage.transaction}
                label="Top 1,000 profiles based on total all-time spend with no transactions in the last 6 mo"
                to={`/reports/transaction-summary?filter=lastTxTimestamp.LESS_THAN.${getFormattedSixMonthAgoDate(
                  todayDate
                )}&sort=totalSpend,desc&limit=1000`}
              />
              <RecommendedQueriesCardCard
                iconImage={IconImage.transaction}
                label="Top 1,000 profiles based on total transaction count"
                to="/reports/transaction-summary?sort=txCount,desc&limit=1000"
              />
              <RecommendedQueriesCardCard
                iconImage={IconImage.transaction}
                label="Top 1,000 profiles based on total transaction count with none in the last 6 mo"
                to={`/reports/transaction-summary?filter=lastTxTimestamp.LESS_THAN.${getFormattedSixMonthAgoDate(
                  todayDate
                )}&sort=txCount,desc&limit=1000`}
              />
              <RecommendedQueriesCardCard
                iconImage={IconImage.consumerInsights}
                label="All duplicate identity data records sorted by total duplicate count"
                to="/reports/duplicates?sort=recordCount,desc&sort=pin,desc"
              />
              <RecommendedQueriesCardCard
                iconImage={IconImage.consumerInsights}
                label="All duplicate identity data records sorted by total transaction count"
                to="/reports/duplicates?sort=txCount,desc&sort=pin,desc"
              />
              <RecommendedQueriesCardCard
                iconImage={IconImage.consumerInsights}
                label="All duplicate identity data records sorted by total all-time spend"
                to="/reports/duplicates?sort=totalSpend,desc&sort=pin,desc"
              />
            </SimpleGrid>
          </Stack>
        )}
      </HStack>
    </PageLayout>
  );
}
export default ProfileDashboard;
