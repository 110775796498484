import Icon, { IconProps } from '../../../core/Icon/Icon';
import { IconImage } from '../../../core/Icon/IconConfig';

interface DataSourceIconProps {
  sourceSystem?: string;
}

function DataSourceIcon({ sourceSystem, ...iconProps }: DataSourceIconProps & IconProps) {
  let iconImage = IconImage.database;
  if (sourceSystem) {
    const s = sourceSystem.toLowerCase();
    if (s.startsWith('salesforce')) {
      iconImage = IconImage.salesforce;
    } else if (s.startsWith('hubspot')) {
      iconImage = IconImage.hubspot;
    } else if (s === 'microsoftdynamics') {
      iconImage = IconImage.microsoftDynamics;
    } else if (s.includes('snowflake')) {
      iconImage = IconImage.snowflake;
    } else if (s === 'flatfile') {
      iconImage = IconImage.flatFile;
    } else if (s === 'sevenrooms') {
      iconImage = IconImage.sevenrooms;
    } else if (s === 'square') {
      iconImage = IconImage.square;
    } else if (s === 'tripleseat') {
      iconImage = IconImage.tripleseat;
    }
  }

  return <Icon data-testid="DataSourceIcon" iconImage={iconImage} {...iconProps} />;
}
export default DataSourceIcon;
