/* eslint-disable no-nested-ternary */
import { Box, Button, chakra, Circle, Flex, HStack, Stack, Text, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { FaCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import NewActiveIndicator from '../../../components/core/NewActiveIndicator/NewActiveIndicator';
import DataSourceIcon from '../../../components/features/connections/DataSourceIcon/DataSourceIcon';
import { useGetDataSourceExportStatus } from '../../../lib/api-client/sources/SourceData';
import { DataSource } from '../../../lib/api-client/sources/model/DataSource';
import { formatDateString } from '../../../lib/utils/date-time-utils';

interface SourceConnectionHealthCardProps {
  source: DataSource;
}

function ConnectionStatus({ source }: Pick<SourceConnectionHealthCardProps, 'source'>) {
  const ChakraFaCircle = chakra(FaCircle);
  const isComplete = source.configState === 'COMPLETE';

  if (source.sourceSystem === 'flatFile') {
    return null;
  }

  return (
    <HStack spacing={12}>
      <VStack alignItems="start" spacing={0}>
        <Text fontWeight="semibold" fontSize="xs" color="gray.600">
          {isComplete ? 'Connection Status' : 'Ingestion status'}
        </Text>
        {isComplete ? (
          <NewActiveIndicator
            active={source.enabled}
            textProps={{ fontWeight: 'normal' }}
            width="12px"
          />
        ) : (
          <HStack spacing={1}>
            <ChakraFaCircle color="warning" />
            <Text>Incomplete</Text>
          </HStack>
        )}
      </VStack>
    </HStack>
  );
}

function ExportStatus({
  source,
  onExportError,
}: {
  source: DataSource;
  onExportError: (status: 'DEGRADED' | 'FAILED') => void;
}) {
  const ChakraFaCircle = chakra(FaCircle);
  const { data: exportStatus } = useGetDataSourceExportStatus(source.id);

  const isComplete = source.configState === 'COMPLETE';

  const IconStatus =
    exportStatus?.exportState === 'HEALTHY' ? (
      <Circle size="3" bg="success" />
    ) : exportStatus?.exportState === 'DEGRADED' ? (
      <Circle size="3" bg="warning" />
    ) : exportStatus?.exportState === 'FAILED' ? (
      <Circle size="3" bg="error" />
    ) : (
      ''
    );

  if (exportStatus?.exportState === 'DEGRADED' || exportStatus?.exportState === 'FAILED') {
    onExportError(exportStatus?.exportState);
  }

  const exportStatusLabel =
    exportStatus?.exportState === 'HEALTHY'
      ? 'Healthy'
      : exportStatus?.exportState === 'DEGRADED'
      ? 'Delayed'
      : exportStatus?.exportState === 'FAILED'
      ? 'Error detected'
      : '';

  if (source.sourceSystem === 'flatFile') {
    return null;
  }

  return (
    <HStack spacing={12}>
      <VStack alignItems="start" spacing={0}>
        <Text fontWeight="semibold" fontSize="xs" color="gray.600">
          {isComplete ? 'Ingestion status' : 'Connection Status'}
        </Text>
        {isComplete ? (
          <NewActiveIndicator
            active={source.enabled}
            textProps={{ fontWeight: 'normal' }}
            width="12px"
          />
        ) : (
          <HStack spacing={1}>
            <ChakraFaCircle color="warning" />
            <Text>Incomplete</Text>
          </HStack>
        )}
      </VStack>

      <VStack alignItems="start" spacing={0}>
        <Text fontWeight="semibold" fontSize="xs" color="gray.600">
          Publish status
        </Text>
        <HStack spacing={1}>
          {IconStatus}
          <Text>{exportStatusLabel}</Text>
        </HStack>
      </VStack>
    </HStack>
  );
}

export default function SourceConnectionHealthCard({ source }: SourceConnectionHealthCardProps) {
  const [exportStatus, setExportStatus] = useState<'DEGRADED' | 'FAILED'>();
  const isComplete = source.configState === 'COMPLETE';
  const borderColor = !isComplete
    ? 'warning'
    : source.primary
    ? 'highlightDark'
    : exportStatus === 'FAILED'
    ? 'error'
    : exportStatus === 'DEGRADED'
    ? 'warning'
    : 'gray.100';

  const buttonColorScheme = !isComplete
    ? 'warningScheme'
    : exportStatus === 'FAILED'
    ? 'errorScheme'
    : exportStatus === 'DEGRADED'
    ? 'warningScheme'
    : 'actionScheme';

  return (
    <Flex direction="column" position="relative">
      <Box p={4} border="1px" boxShadow="base" borderRadius="md" borderColor={borderColor}>
        <VStack alignItems="start" spacing={3}>
          <HStack>
            <VStack spacing={0}>
              {source.primary && (
                <Icon
                  iconImage={IconImage.badgeStar}
                  bgColor="highlightDark"
                  color="whatsapp.50"
                  ml={-5}
                  boxSize="4"
                  p={0.5}
                  borderRadius={7}
                />
              )}
              <DataSourceIcon sourceSystem={source.sourceSystem} />
            </VStack>
            <Stack spacing={0}>
              <Text fontWeight="bold" fontSize="14" whiteSpace="nowrap">
                {source.name}
              </Text>
              <chakra.span>{source.category}</chakra.span>
            </Stack>
          </HStack>
          <HStack justifyContent="space-between" spacing={8}>
            {source.sourceSystem === 'flatFile' && (
              <HStack spacing={12}>
                <VStack alignItems="start" spacing={0}>
                  <Text fontWeight="semibold" fontSize="xs" color="gray.600">
                    Last ingestion
                  </Text>
                  <Text> {formatDateString(source?.createdAt, 'LLL dd, yyyy')} </Text>
                </VStack>
                {source.syncDirection !== 'IN' && (
                  <ExportStatus source={source} onExportError={setExportStatus} />
                )}
              </HStack>
            )}
            {source.syncDirection !== 'IN' ? (
              <ExportStatus source={source} onExportError={setExportStatus} />
            ) : (
              <ConnectionStatus source={source} />
            )}
          </HStack>
        </VStack>
        <Button
          variant="solid"
          colorScheme={buttonColorScheme}
          w="full"
          mt="4"
          as={Link}
          to={`/sources/${source.id}/manage`}
        >
          {exportStatus === 'FAILED' && <Icon iconImage={IconImage.error} boxSize={4} mr={2} />}
          {exportStatus === 'DEGRADED' && <Icon iconImage={IconImage.warning} boxSize={4} mr={2} />}
          {isComplete ? 'Manage connection' : 'Continue connection setup'}
        </Button>
      </Box>
    </Flex>
  );
}
