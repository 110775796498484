import { PolarAngleAxis, RadialBar, RadialBarChart } from 'recharts';

interface SingleRadialBarChartProps {
  value: number;
  title?: string;
  chartColor?: string;
  width?: number;
  height?: number;
}

function SingleRadialBarChart({
  value,
  width,
  height,
  title,
  chartColor = '#000000',
}: SingleRadialBarChartProps) {
  return (
    <RadialBarChart
      width={width}
      height={height}
      innerRadius={54}
      outerRadius={70}
      barSize={20}
      startAngle={90}
      endAngle={-270}
      data={[
        {
          value: Number.isNaN(value) ? 0 : value * 100,
          fill: chartColor,
        },
      ]}
    >
      <PolarAngleAxis type="number" domain={[0, 100]} tick={false} />
      <RadialBar background dataKey="value" cornerRadius={20} />
      {title && <title>{title}</title>}
    </RadialBarChart>
  );
}
export default SingleRadialBarChart;
