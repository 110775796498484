import { Stack, Text } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import SimpleBarGraph from '../../../../../components/shared/visualizations/SimpleBarGraph/SimpleBarGraph';
import TimeSeriesFilter, {
  filterConfig,
  SelectedTimeSeriesFilter,
} from '../../../../../components/shared/visualizations/TimeSeriesFilter/TimeSeriesFilter';
import { useDataRepairTimeSeriesMetrics } from '../../../../../lib/api-client/data-repair/DataRepairData';
import { DataRepairAutomationType } from '../../../../../lib/api-client/data-repair/data-repair.model';
import { formatDateString, getCurrentTimezone } from '../../../../../lib/utils/date-time-utils';
import { formatNumber } from '../../../../../lib/utils/number-utils';
import { mapTimeSeriesResponse } from '../../../../../lib/utils/timeseries-utils';

function ToolTipContent({ interval, payload, isLastInterval }: any) {
  const metric = payload[0].payload.metric.totalRepairs;

  const isTime = interval === 'hour';

  const startDate = formatDateString(metric.startDate, 'h:mm');
  const endDate = formatDateString(metric.endDate, "h:mm a 'on' M/dd/yyyy");

  return (
    <>
      <Text fontSize="md" fontWeight="bold">
        {formatNumber(metric.value)} total data repairs
      </Text>
      {isTime ? (
        <Text>
          across all sources systems between {startDate} - {endDate}
        </Text>
      ) : (
        <Text>across all sources systems on {formatDateString(metric.startDate, 'M/dd/yyyy')}</Text>
      )}
      {isLastInterval && (
        <Text mt="4">
          Note that this time window is in progress and does not yet represent a complete data point
        </Text>
      )}
    </>
  );
}

export default function DataRepairActivityChart({
  dataRepairAutomationType,
}: {
  dataRepairAutomationType: DataRepairAutomationType;
}) {
  const now = useMemo(() => new Date(), []);
  const [filter, setFilter] = useState<SelectedTimeSeriesFilter>({
    interval: 'day',
    config: filterConfig.day[0],
  });
  const { data } = useDataRepairTimeSeriesMetrics(
    dataRepairAutomationType,
    filter.config.getStartDate(now),
    now,
    getCurrentTimezone(),
    filter.interval,
    'count:totalRepairs'
  );

  const timeSeriesData = data
    ? mapTimeSeriesResponse(data, ['count:totalRepairs'], filter.interval)
    : undefined;

  const onFilterChanged = (selectedFilter: SelectedTimeSeriesFilter) => {
    setFilter(selectedFilter);
  };

  return (
    <Stack spacing={4} height="full" w="full">
      <TimeSeriesFilter onFilterChanged={onFilterChanged} />
      {timeSeriesData && (
        <SimpleBarGraph
          data={timeSeriesData.data}
          xDataKey="label"
          yDataKey="metric.totalRepairs.value"
          highlightLastInterval
          tooltipContent={<ToolTipContent interval={filter.interval} />}
          height={262}
        />
      )}
    </Stack>
  );
}
