import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import { CustomerValue } from '../../../../lib/api-client/customer-value/model/CustomerValue';
import { Profile } from '../../../../lib/api-client/profile/model/Profile';
import { Transaction } from '../../../../lib/api-client/profile/model/Transaction';
import Alert from '../../../core/Alert/Alert';
import PurchaseDetailsCard from '../../../core/PurchaseDetails/PurchaseDetailsCard';
import ProfilePageLifetimeValue from '../ProfilePageLifetimeValue/ProfilePageLifetimeValue';

interface ProfilePageActivityPurchaseDataProps {
  transactions: Transaction[];
  handleClick?: () => void;
  customerValue?: CustomerValue;
  profile?: Profile;
}
function ProfilePageActivityRecentPurchaseData({
  transactions = [],
  handleClick,
  customerValue,
  profile,
}: ProfilePageActivityPurchaseDataProps) {
  const txs = transactions.slice(0, 3);
  let content = (
    <Alert
      status="warning"
      variant="subtle"
      description="There is no purchase data on record for this consumer."
    />
  );
  if (txs.length > 0) {
    content = (
      <HStack alignItems="start" width="full" spacing={6}>
        <VStack spacing="5" alignItems="start">
          {txs.map((tx) => (
            <PurchaseDetailsCard
              key={`${tx.sourceId}-${tx.sourceRecordId}`}
              amount={tx.amount}
              currency={tx.currency}
              description={tx.description}
              locationName={tx.locationName}
              sourceId={tx.sourceId}
              sourceRecordId={tx.sourceRecordId}
              startTimestamp={tx.startTimestamp}
              orderId={tx.orderId}
              subtotal={tx.subtotal}
              taxes={tx.taxes}
              discounts={tx.discounts}
              gratuity={tx.gratuity}
              otherFees={tx.otherFees}
              shippingFees={tx.shippingFees}
              paymentType={tx.paymentType}
              reward={tx.reward}
              recordId={tx.recordId}
            />
          ))}
          {transactions.length > 0 && (
            <Button mt={6} onClick={handleClick}>
              See all activity and insights
            </Button>
          )}
        </VStack>
        <Box>
          {customerValue && profile && (
            <ProfilePageLifetimeValue customerValue={customerValue} profile={profile} />
          )}
        </Box>
      </HStack>
    );
  }
  return (
    <Box width="680px" data-testid="ProfilePageActivityRecentPurchaseData">
      {content}
    </Box>
  );
}
export default ProfilePageActivityRecentPurchaseData;
