/* eslint-disable no-nested-ternary */
import {
  Box,
  Button,
  ButtonGroup,
  chakra,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  SimpleGrid,
  Stack,
  Tag,
  TagLabel,
  Text,
  useBoolean,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { sub } from 'date-fns';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AccurateVerificationCard from '../../../components/core/AccuracyVerificationCard/AccuracyVerificationCard';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import SourceSystemCard from '../../../components/core/SourceSystemHealth/SourceSystemCard';
import SystemDashboardCard, {
  calculateAndRound,
} from '../../../components/core/SystemDashboardCard/SystemDashboardCard';
import CardAction from '../../../components/core/card/Card/CardAction';
import DataSourceIcon from '../../../components/features/connections/DataSourceIcon/DataSourceIcon';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import { useDataRepairMetrics } from '../../../lib/api-client/data-repair/DataRepairData';
import {
  recordGroupToLabel,
  SourceIdentityRecordGroup,
} from '../../../lib/api-client/identity/model/SourceIdentityRecordGroup';
import {
  useExportMetrics,
  useIdentitySourceMetrics,
} from '../../../lib/api-client/source-metrics/IdentitySourceMetricsClient';
import SourceMetricsClient, {
  useGetSourceMetrics,
} from '../../../lib/api-client/source-metrics/SourceMetricsClient';
import { useSourceIssuesForSource } from '../../../lib/api-client/sources/SourceIssuesData';
import { SourceIssuePriority } from '../../../lib/api-client/sources/model/SourceIssuePriority';
import DataSourceTimeSeriesMetricsService from '../../../lib/services/DataSourceTimeSeriesMetricsService';
import { formatDateString, getCurrentTimezone } from '../../../lib/utils/date-time-utils';
import { formatNumber } from '../../../lib/utils/number-utils';
import { CATEGORY_QUERY_PARAM } from '../DataQualityIssuesPage/DataQualityIssuesPage';
import DuplicateMonitorFluctuationChip from '../components/DuplicateDataFluctuationChip/DuplicateMonitorFluctuationChip';
import { useCurrentDataSource } from '../context/CurrentDataSourceContext';
import AddsAndUpdatesActivity from './AddsAndUpdatesActivity';
import AttributeCompletenessCard from './AttributeCompletenessCard';
import DataQualityScore from './DataQualityScore';
import DeletesActivity from './DeletesActivity';
import DuplicateMonitorChart from './DuplicateMonitorChart';
import ExportEventsActivity from './ExportEventsActivity';
import IngestionEventsActivity from './IngestionEvents';
import RecentExportErrors from './RecentExportErrors';
import RecentIngestionErrors from './RecentIngestionErrors';
import TotalDataRecords from './TotalDataRecords';
import TotalDataRepairs from './TotalDataRepairs';

function DuplicateMonitorCard({
  value,
  label,
  iconImage,
  percentValue,
  percentaValueEnabled,
}: {
  value: number;
  label: string;
  iconImage: IconImage;
  percentValue?: number;
  percentaValueEnabled?: boolean;
}) {
  return (
    <Box
      shadow="md"
      w="210px"
      borderRadius="6px"
      border="1px"
      borderColor="#CBD5E0"
      py="3"
      px="3"
      position="relative"
    >
      <HStack>
        <Icon boxSize="28px" iconImage={iconImage} />
        <Text fontSize="2xl" fontWeight="bold">
          {formatNumber(value)}
        </Text>
        {percentaValueEnabled && percentValue !== null && (
          <DuplicateMonitorFluctuationChip value={percentValue ?? 0} />
        )}
      </HStack>
      <Text>{label} </Text>
    </Box>
  );
}

function SystemDashboardPage() {
  const { id = '' } = useParams();
  const { dataSource: source } = useCurrentDataSource();
  const { data: identitySourceMetrics } = useIdentitySourceMetrics(id);
  const {
    data: sourceIssuesData,
    loading: sourceIssuesLoading,
    error: sourceIssuesError,
  } = useSourceIssuesForSource(id);
  const issues = sourceIssuesData?.content ?? [];
  const { data: metrics, loading: metricsLoading, error: metricsError } = useGetSourceMetrics(id);
  const { data: dataRepairMetrics } = useDataRepairMetrics();
  const { data: exportData } = useExportMetrics(id);

  const [timeseriesDuplicateData, setTimeseriesDuplicateData] =
    useState<{ label: string; value: number }[]>();
  const [timeseriesData, setTimeseriesData] = useState<{ label: string; value: number }[][]>();
  const [timeseriesExportData, setTimeseriesExportData] =
    useState<{ label: string; value: number }[][]>();

  const [timeseriesIngestionData, setTimeseriesIngestionData] =
    useState<{ label: string; value: number }[][]>();
  const [totalRecordsEvents, setTotalRecordsEvents] = useState<number>(0);

  const [initializationError, setInitializationError] = useState(false);
  const [isLoading, setLoading] = useBoolean(true);
  const navigate = useNavigate();

  const totalRepairs = dataRepairMetrics?.sources.find((item) => item.source.id === id)?.metrics
    ?.totalRepairs;

  const monthlyMonitorData =
    timeseriesData &&
    timeseriesData[0]
      ?.map((item, index) => {
        if (item.value !== 0 && item.value !== null && timeseriesData[1][index].value !== null) {
          return {
            dLabel: item.label,
            dValue: item.value,
            sValue: timeseriesData[1][index].value,
          };
        }
        return null;
      })
      .filter(Boolean);

  useEffect(() => {
    async function fetchData() {
      try {
        const now = new Date();
        const endTime = sub(now, { months: 12 });
        const [timseriesResponse] = await Promise.all([
          SourceMetricsClient.getSourceTimeSeriesData(
            id,
            ['most_recent:duplicatesSameEmail', 'most_recent:duplicatesDifferentEmail'],
            endTime,
            now,
            getCurrentTimezone(),
            'month'
          ).then((data) => {
            if (data.series.length < 1) {
              return [];
            }
            return data.series.map((item) =>
              item.dataPoints.map((d) => ({
                label: formatDateString(d.startDate, 'MMM yyyy'),
                value: d.value,
              }))
            );
          }),
        ]);
        setTimeseriesData(timseriesResponse);
      } catch (err) {
        setInitializationError(true);
      }

      setLoading.off();
    }
    void fetchData();
  }, [id, setLoading, navigate]);

  useEffect(() => {
    async function fetchData() {
      try {
        const now = new Date();
        const endTime = sub(now, { months: 12 });
        const [timseriesResponse] = await Promise.all([
          SourceMetricsClient.getSourceTimeSeriesData(
            id,
            ['most_recent:newDuplicates '],
            endTime,
            now,
            getCurrentTimezone(),
            'month'
          ).then((data) => {
            if (data.series.length < 1) {
              return [];
            }
            return data.series[0].dataPoints.map((d) => ({
              label: formatDateString(d.startDate, 'MMM yyyy'),
              value: d.value,
            }));
          }),
        ]);
        setTimeseriesDuplicateData(timseriesResponse);
      } catch (err) {
        setInitializationError(true);
      }

      setLoading.off();
    }
    void fetchData();
  }, [id, setLoading, navigate]);

  useEffect(() => {
    async function fetchData() {
      try {
        const now = new Date();
        const endTime = sub(now, { days: 7 });
        const [timseriesResponse] = await Promise.all([
          SourceMetricsClient.getExportTimeSeriesData(
            id,
            ['sum:exportAttempts '],
            endTime,
            now,
            getCurrentTimezone(),
            'day',
            'sum'
          ).then((data) => {
            if (data?.series?.length < 1) {
              return [];
            }
            return data?.series[0].dataPoints.map((d) => ({
              label: formatDateString(d.startDate, 'M/d'),
              value: d.value,
            }));
          }),
        ]);
        setTimeseriesExportData(timseriesResponse as any);
      } catch (err) {
        setInitializationError(true);
      }

      setLoading.off();
    }
    void fetchData();
  }, [id, setLoading, navigate]);

  useEffect(() => {
    async function fetchData() {
      try {
        const now = new Date();
        const endTime = sub(now, { days: 7 });
        const timeseriesResponse = await DataSourceTimeSeriesMetricsService.getTimeSeries(
          id,
          ['sum:recordEvents'],
          endTime,
          now,
          getCurrentTimezone(),
          'day',
          'sum'
        );
        const formattedData =
          timeseriesResponse?.data?.map((d) => {
            const date = formatDateString(d.metric.recordEvents.startDate, 'M/d');
            return {
              label: date,
              value: d?.metric?.recordEvents?.value,
            };
          }) || [];
        setTimeseriesIngestionData(formattedData as any);
        const totalValue = formattedData.reduce((acc, item) => acc + item.value, 0);
        setTotalRecordsEvents(totalValue);
      } catch (err) {
        setInitializationError(true);
      } finally {
        setLoading.off();
      }
    }
    void fetchData();
  }, [id, setLoading, setInitializationError, navigate]);

  const array1 = timeseriesDuplicateData?.slice(-2).map((item, index) => ({
    [`label${index}`]: item.label,
    [`value${index}`]: item.value,
  }));

  const identityMetricsRecord =
    identitySourceMetrics &&
    identitySourceMetrics?.recordCountsByCategory
      ?.filter((category) =>
        Object.values(SourceIdentityRecordGroup).includes(category.category as any)
      )
      .sort((a, b) => b.count - a.count);

  const s = identityMetricsRecord?.filter((item) => item.category === 'duplicates');
  const s1 = identityMetricsRecord?.filter((item) => item.category !== 'duplicates');
  const mergedArray = [{ ...s?.[0], ...array1?.[0], ...array1?.[1] }, ...(s1 || [])];
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isCompletenessOpen,
    onOpen: onCompletenessOpen,
    onClose: isCompletenessClose,
  } = useDisclosure();
  const {
    isOpen: isAccuracyOpen,
    onOpen: onAccuracyOpen,
    onClose: isAccuracyClose,
  } = useDisclosure();

  const handleCompletenessClick = () => {
    onCompletenessOpen();
  };

  const handleAccuracyClick = () => {
    onAccuracyOpen();
  };

  const color =
    identitySourceMetrics &&
    (identitySourceMetrics?.completeRecords?.matchPercentage <= 29
      ? 'var(--chakra-colors-error)'
      : identitySourceMetrics?.completeRecords?.matchPercentage <= 59
      ? 'var(--chakra-colors-warning)'
      : identitySourceMetrics?.completeRecords?.matchPercentage <= 100
      ? 'var(--chakra-colors-success)'
      : 'var(--chakra-colors-success)');

  return (
    <PageLayout
      data-testid="SystemDashboardPage"
      pageViewEvent={{ page: 'System dashboard', pageId: id }}
      header={
        <Header
          title={`${source.name} system dashboard`}
          icon={<DataSourceIcon sourceSystem={source.sourceSystem} />}
          noSearch
        />
      }
      initializationError={initializationError || !!sourceIssuesError || !!metricsError}
      loading={isLoading || sourceIssuesLoading || metricsLoading}
    >
      <SimpleGrid columns={3} gap="4">
        <DataQualityScore
          dataQualityScore={identitySourceMetrics?.dataQualityScore ?? 0}
          dataQualityScoreChange={identitySourceMetrics?.dataQualityScoreChange ?? 0}
        />
        <TotalDataRecords
          sourceId={source.id}
          totalRecords={identitySourceMetrics?.totalRecords ?? 0}
          lastIngestedAt={metrics?.recordEventsIngested.lastIngestedAt}
        />
        <TotalDataRepairs totalRepairs={totalRepairs ?? 0} />
      </SimpleGrid>
      {source.syncDirection === 'IN' && (
        <SectionContainer title="Ingestion activity monitor" variant="ghost" pt={4}>
          <SimpleGrid columns={2} gap={4}>
            <AddsAndUpdatesActivity
              sourceId={source.id}
              sourceName={source?.name}
              metrics={metrics}
            />
            <DeletesActivity sourceId={source.id} sourceName={source?.name} metrics={metrics} />
            <RecentIngestionErrors
              sourceId={source.id}
              errorCount={metrics?.recordEventsIngestedLastWeek.errors ?? 0}
            />
          </SimpleGrid>
        </SectionContainer>
      )}

      {source.syncDirection === 'BOTH' && (
        <SectionContainer title="Data activity monitor" variant="ghost" pt={4}>
          <SimpleGrid columns={2} gap={4}>
            <IngestionEventsActivity
              recordEventsLastWeek={metrics?.recordEventsLastWeek.totalChange ?? 0}
              timeseriesIngestionData={timeseriesIngestionData}
              sourceId={source.id}
              sourceName={source?.name}
              totalRecordsEvents={totalRecordsEvents}
              metrics={metrics}
            />

            <ExportEventsActivity
              sourceName={source?.name}
              exportData={exportData}
              timeseriesExportData={timeseriesExportData}
              sourceId={source.id}
            />

            <RecentIngestionErrors
              sourceId={source.id}
              errorCount={metrics?.recordEventsIngestedLastWeek.errors ?? 0}
            />

            <RecentExportErrors
              sourceId={source.id}
              errorCount={exportData?.totalFailuresLastWeek ?? 0}
            />
          </SimpleGrid>
        </SectionContainer>
      )}
      <Stack pt={4} spacing={4}>
        <Flex
          justifyContent="space-between"
          borderBottomWidth="1px"
          borderColor="gray.200"
          w="full"
        >
          <Text fontWeight="bold" fontSize="md">
            Quality issue categories
          </Text>
          <ButtonGroup spacing="6">
            <Button
              fontWeight="semibold"
              textColor="action"
              as={Link}
              to={`/sources/${id}/issues`}
              variant="link"
            >
              See all quality issues
            </Button>
          </ButtonGroup>
        </Flex>
        {mergedArray && (
          <SimpleGrid columns={4} spacing={4} w="full">
            {mergedArray
              .map((issue: any) => ({
                isHighPriority: !!issues
                  .filter((i) => i.priority === SourceIssuePriority.High)
                  .find((data) => data.group.toString() === issue.category),
                issue,
              }))
              .filter(
                ({ issue }) =>
                  (source.domainType !== 'TRANSACTION' && source.domainType !== 'CONSUMER_EVENT') ||
                  issue.category !== 'duplicates'
              )
              .sort((a, b) => {
                const isDuplicateTitleA = a.issue.category === 'duplicates';
                const isDuplicateTitleB = b.issue.category === 'duplicates';
                if (isDuplicateTitleA && !isDuplicateTitleB) {
                  return -1;
                }
                if (isDuplicateTitleB && !isDuplicateTitleA) {
                  return 1;
                }
                if (a.isHighPriority && !b.isHighPriority) {
                  return -1;
                }
                if (b.isHighPriority && !a.isHighPriority) {
                  return 1;
                }
                return b.issue.count - a.issue.count;
              })
              .map(({ isHighPriority, issue }) => (
                <SystemDashboardCard
                  key={`issue-category-${issue.category}`}
                  title={recordGroupToLabel(issue.category)}
                  issuePercentage={issue.change}
                  totalRecords={issue.count}
                  to={
                    issue.category === 'duplicates'
                      ? `/sources/${id}/data-explorer?duplicates=duplicate`
                      : `/sources/${id}/issues?${CATEGORY_QUERY_PARAM}=${issue.category}`
                  }
                  priority={isHighPriority}
                  onClick={onOpen}
                  issue={issue}
                  totalDuplicates={identitySourceMetrics?.totalRecords}
                />
              ))}
          </SimpleGrid>
        )}

        {source.sourceSystem !== 'flatFile' && (
          <SectionContainer title="Record scoring" variant="ghost" pt={4}>
            <SimpleGrid columns={3} gap={4}>
              {identitySourceMetrics && (
                <>
                  <SourceSystemCard
                    title="Data record pinnability"
                    realDataValue={identitySourceMetrics.pinnedRecords}
                    realDataValuePercentage={
                      identitySourceMetrics.pinnedRecords / identitySourceMetrics.totalRecords
                    }
                    realData="data records pinned"
                    unrealData="data records not pinned"
                    unrealDataValue={identitySourceMetrics.unpinnedRecords}
                    unrealDataValuePercentage={
                      identitySourceMetrics.unpinnedRecords / identitySourceMetrics.totalRecords
                    }
                    bgColor={color}
                    badgeBgColor={color}
                    tooltip
                    tooltipContent="This is the measure of data records ingested from this source that were assigned a PIN for identity resolution. "
                    cardAction={
                      <CardAction
                        as={Link}
                        to={{
                          pathname: `/sources/${source.id}/data-explorer`,
                          search: `?pinned=unpinned`,
                        }}
                        variant="outline"
                      >
                        Review data records not pinned
                      </CardAction>
                    }
                  />
                  {identitySourceMetrics?.completeRecords && (
                    <SourceSystemCard
                      recordStyle
                      title="Data record completeness"
                      realDataValue={identitySourceMetrics.completeRecords.matches}
                      realDataValuePercentage={
                        identitySourceMetrics.completeRecords.matchPercentage / 100
                      }
                      realData="complete data records"
                      unrealDataValue={identitySourceMetrics.completeRecords.misses}
                      unrealDataValuePercentage={
                        (100 - identitySourceMetrics.completeRecords.matchPercentage) / 100
                      }
                      unrealData="incomplete data records"
                      bgColor={color}
                      badgeBgColor={color}
                      cardAction={
                        <CardAction onClick={handleCompletenessClick} variant="outline">
                          Explore breakdown by data field
                        </CardAction>
                      }
                      tooltip
                      tooltipContent="This is the measure of data records ingested from this source that have all primary identity data fields populated including first name, last name, email address and phone number."
                    />
                  )}
                  <SourceSystemCard
                    pieRadial
                    recordStyle
                    title="Data record accuracy"
                    realDataValue={identitySourceMetrics.totalValidRecords}
                    realDataValuePercentage={
                      identitySourceMetrics.totalValidRecords / identitySourceMetrics.totalRecords
                    }
                    realData="records with accurate data"
                    unrealDataValue={identitySourceMetrics.totalPartiallyValidRecords}
                    unrealDataValuePercentage={
                      identitySourceMetrics.totalPartiallyValidRecords /
                      identitySourceMetrics.totalRecords
                    }
                    unrealData="records with questionable data"
                    bgColor={color}
                    badgeBgColor={color}
                    unusibility="records with inaccurate data"
                    unusibilityValue={identitySourceMetrics.totalInvalidRecords}
                    unusibilityValuePercentage={
                      identitySourceMetrics.totalInvalidRecords / identitySourceMetrics.totalRecords
                    }
                    cardAction={
                      <CardAction variant="outline" onClick={handleAccuracyClick}>
                        Explore breakdown by data field
                      </CardAction>
                    }
                    tooltip
                    tooltipContent="This is the measure of data records ingested from this source that have been verified for accuracy."
                  />
                </>
              )}
            </SimpleGrid>
          </SectionContainer>
        )}
      </Stack>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton
            size="lg"
            right="unset"
            left="-40px"
            fontSize="xs"
            color="white"
            top={10}
            bgColor="action"
            _hover={{ backgroundColor: 'actionDark' }}
            borderTopRightRadius={0}
            borderBottomRightRadius={0}
          />
          <DrawerHeader>Duplicates monitor</DrawerHeader>
          <DrawerBody>
            <VStack alignItems="start" spacing={3}>
              <HStack spacing={6}>
                <DuplicateMonitorCard
                  value={identitySourceMetrics?.totalDuplicates ?? 0}
                  label="total duplicates detected since first ingestion"
                  iconImage={IconImage.versionHistory}
                />
                <DuplicateMonitorCard
                  iconImage={IconImage.trendRating}
                  value={identitySourceMetrics?.newDuplicates ?? 0}
                  label="new duplicates detected in the last 30 days"
                  percentValue={identitySourceMetrics?.newDuplicatesChange ?? 0}
                  percentaValueEnabled
                />
              </HStack>
              <HStack spacing={6}>
                <DuplicateMonitorCard
                  iconImage={IconImage.consumer360View}
                  value={identitySourceMetrics?.duplicateProfiles ?? 0}
                  label="total unique profiles identified from duplicates"
                />
                <DuplicateMonitorCard
                  iconImage={IconImage.merge}
                  value={identitySourceMetrics?.totalDedupes ?? 0}
                  label="total records deduped since first ingestion "
                />
              </HStack>
              <DuplicateMonitorChart />
              <SectionContainer title="Monthly monitor" p={3} width="full">
                {monthlyMonitorData?.reverse().map(
                  (item, index) =>
                    item && (
                      <VStack
                        key={`${item?.dLabel}-${item?.dLabel}-${item?.sValue}`}
                        alignItems="start"
                        spacing={2}
                      >
                        <HStack w="full" justifyContent="space-between">
                          <Text mt={1} fontWeight="semibold" textColor="#4A5568">
                            {item.dLabel}
                          </Text>
                          {index === 0 || monthlyMonitorData.length - 1 === index ? (
                            <Text fontSize="12" fontWeight="semibold" textColor="#4A5568">
                              {index === 0 ? (
                                <Text fontSize="12" fontWeight="semibold" textColor="#4A5568">
                                  <Icon iconImage={IconImage.inProgress} boxSize="5" />
                                  in progress
                                </Text>
                              ) : (
                                <Text fontSize="12" fontWeight="semibold" textColor="#4A5568">
                                  <Icon iconImage={IconImage.dataTransfer} boxSize="4" /> First
                                  ingestion
                                </Text>
                              )}
                            </Text>
                          ) : null}
                        </HStack>
                        <HStack>
                          <Text fontWeight="bold" fontSize="14">
                            {item.dValue + item.sValue} total duplicates
                          </Text>
                          <Tag
                            size="sm"
                            borderRadius="full"
                            variant="solid"
                            bgColor="gray.200"
                            color="gray.800"
                          >
                            <TagLabel fontWeight="semibold">
                              {calculateAndRound(
                                item.dValue + item.sValue ?? 0,
                                identitySourceMetrics?.totalRecords ?? 0
                              )}
                              % of all records
                            </TagLabel>
                          </Tag>
                        </HStack>
                        <HStack>
                          <Tag variant="solid" bgColor="#8EE9FF" size="sm">
                            {' '}
                          </Tag>
                          <chakra.span>
                            {item.sValue} (
                            {calculateAndRound(item.sValue ?? 0, item.dValue + item.sValue ?? 0)}%)
                            with different emails{' '}
                          </chakra.span>
                        </HStack>
                        <HStack mb={2}>
                          <Tag variant="solid" bgColor="action" size="sm">
                            {' '}
                          </Tag>
                          <chakra.span>
                            {item.dValue} (
                            {calculateAndRound(item.dValue ?? 0, item.dValue + item.sValue ?? 0)}%)
                            with the same email
                          </chakra.span>
                        </HStack>
                        <Divider borderBottomWidth={1} borderColor="gray.300" />
                      </VStack>
                    )
                )}
                <Text ml={8} mt={4} alignItems="center" textColor="#4A5568">
                  Duplicates monitor was not enabled prior to this point
                </Text>
              </SectionContainer>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {source && (
        <Drawer
          isOpen={isCompletenessOpen}
          onClose={isCompletenessClose}
          placement="right"
          size="sm"
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton
              size="lg"
              right="unset"
              left="-40px"
              fontSize="xs"
              color="white"
              top={10}
              bgColor="action"
              _hover={{ backgroundColor: 'actionDark' }}
              borderTopRightRadius={0}
              borderBottomRightRadius={0}
            />
            <DrawerHeader fontSize="2xl" fontWeight="bold">
              Completeness rating breakdown by data field
            </DrawerHeader>
            <DrawerBody>
              <VStack spacing={4}>
                {identitySourceMetrics && (
                  <>
                    {identitySourceMetrics?.recordsWithCompleteFirstName && (
                      <AttributeCompletenessCard
                        label="First name data field"
                        complete={identitySourceMetrics?.recordsWithCompleteFirstName?.matches}
                        completePercent={
                          identitySourceMetrics.recordsWithCompleteFirstName.matchPercentage / 100
                        }
                        incomplete={identitySourceMetrics.recordsWithCompleteFirstName.misses}
                        incompletePercent={
                          (100 -
                            identitySourceMetrics.recordsWithCompleteFirstName.matchPercentage) /
                          100
                        }
                        to={{
                          pathname: `/sources/${source.id}/data-explorer`,
                          search: `?firstName=incomplete`,
                        }}
                      />
                    )}
                    {identitySourceMetrics.recordsWithCompleteLastName && (
                      <AttributeCompletenessCard
                        label="Last name data field"
                        complete={identitySourceMetrics.recordsWithCompleteLastName.matches}
                        completePercent={
                          identitySourceMetrics.recordsWithCompleteLastName.matchPercentage / 100
                        }
                        incomplete={identitySourceMetrics.recordsWithCompleteLastName.misses}
                        incompletePercent={
                          (100 -
                            identitySourceMetrics.recordsWithCompleteLastName.matchPercentage) /
                          100
                        }
                        to={{
                          pathname: `/sources/${source.id}/data-explorer`,
                          search: `?lastName=incomplete`,
                        }}
                      />
                    )}
                    {identitySourceMetrics.recordsWithCompleteEmailAddress && (
                      <AttributeCompletenessCard
                        label="Email address data field"
                        complete={identitySourceMetrics.recordsWithCompleteEmailAddress.matches}
                        completePercent={
                          identitySourceMetrics.recordsWithCompleteEmailAddress.matchPercentage /
                          100
                        }
                        incomplete={identitySourceMetrics.recordsWithCompleteEmailAddress.misses}
                        incompletePercent={
                          (100 -
                            identitySourceMetrics.recordsWithCompleteEmailAddress.matchPercentage) /
                          100
                        }
                        to={{
                          pathname: `/sources/${source.id}/data-explorer`,
                          search: `?emailAddress=incomplete`,
                        }}
                      />
                    )}

                    {identitySourceMetrics?.recordsWithCompletePhone && (
                      <AttributeCompletenessCard
                        label="Phone number data field"
                        complete={identitySourceMetrics?.recordsWithCompletePhone?.matches}
                        completePercent={
                          identitySourceMetrics.recordsWithCompletePhone.matchPercentage / 100
                        }
                        incomplete={identitySourceMetrics.recordsWithCompletePhone.misses}
                        incompletePercent={
                          (100 - identitySourceMetrics.recordsWithCompletePhone.matchPercentage) /
                          100
                        }
                        to={{
                          pathname: `/sources/${source.id}/data-explorer`,
                          search: `?phone=incomplete`,
                        }}
                      />
                    )}
                  </>
                )}
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
      {source && (
        <Drawer isOpen={isAccuracyOpen} onClose={isAccuracyClose} placement="right" size="sm">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton
              size="lg"
              right="unset"
              left="-40px"
              fontSize="xs"
              color="white"
              top={10}
              bgColor="action"
              _hover={{ backgroundColor: 'actionDark' }}
              borderTopRightRadius={0}
              borderBottomRightRadius={0}
            />
            <DrawerHeader fontSize="2xl" fontWeight="bold">
              Accuracy breakdown by data field
            </DrawerHeader>
            <DrawerBody>
              <VStack spacing={4}>
                {identitySourceMetrics && (
                  <>
                    <AccurateVerificationCard
                      label="First name data field"
                      accurate={identitySourceMetrics.recordsWithRecognizedFirstName}
                      accuratePercent={
                        identitySourceMetrics.recordsWithRecognizedFirstName !== 0
                          ? identitySourceMetrics.recordsWithRecognizedFirstName /
                            (identitySourceMetrics.recordsWithRecognizedFirstName +
                              identitySourceMetrics.recordsWithUnrecognizedFirstName +
                              identitySourceMetrics.recordsWithPartiallyRecognizedFirstName)
                          : 0
                      }
                      inaccurate={identitySourceMetrics.recordsWithUnrecognizedFirstName}
                      inaccuratePercent={
                        identitySourceMetrics.recordsWithUnrecognizedFirstName !== 0
                          ? identitySourceMetrics.recordsWithUnrecognizedFirstName /
                            (identitySourceMetrics.recordsWithRecognizedFirstName +
                              identitySourceMetrics.recordsWithUnrecognizedFirstName +
                              identitySourceMetrics.recordsWithPartiallyRecognizedFirstName)
                          : 0
                      }
                      questionable={identitySourceMetrics.recordsWithPartiallyRecognizedFirstName}
                      questionablePercent={
                        identitySourceMetrics.recordsWithPartiallyRecognizedFirstName !== 0
                          ? identitySourceMetrics.recordsWithPartiallyRecognizedFirstName /
                            (identitySourceMetrics.recordsWithRecognizedFirstName +
                              identitySourceMetrics.recordsWithUnrecognizedFirstName +
                              identitySourceMetrics.recordsWithPartiallyRecognizedFirstName)
                          : 0
                      }
                      inaccurateTo={{
                        pathname: `/sources/${source.id}/data-explorer`,
                        search: `?firstNameScore=nonverified`,
                      }}
                      questionableTo={{
                        pathname: `/sources/${source.id}/data-explorer`,
                        search: `?firstNameScore=suspicious`,
                      }}
                    />
                    <AccurateVerificationCard
                      label="Last name data field"
                      accurate={identitySourceMetrics.recordsWithRecognizedLastName}
                      accuratePercent={
                        identitySourceMetrics.recordsWithRecognizedLastName !== 0
                          ? identitySourceMetrics.recordsWithRecognizedLastName /
                            (identitySourceMetrics.recordsWithRecognizedLastName +
                              identitySourceMetrics.recordsWithUnrecognizedLastName +
                              identitySourceMetrics.recordsWithPartiallyRecognizedLastName)
                          : 0
                      }
                      inaccurate={identitySourceMetrics.recordsWithUnrecognizedLastName}
                      inaccuratePercent={
                        identitySourceMetrics.recordsWithUnrecognizedLastName !== 0
                          ? identitySourceMetrics.recordsWithUnrecognizedLastName /
                            (identitySourceMetrics.recordsWithRecognizedLastName +
                              identitySourceMetrics.recordsWithUnrecognizedLastName +
                              identitySourceMetrics.recordsWithPartiallyRecognizedLastName)
                          : 0
                      }
                      questionable={identitySourceMetrics.recordsWithPartiallyRecognizedLastName}
                      questionablePercent={
                        identitySourceMetrics.recordsWithPartiallyRecognizedLastName !== 0
                          ? identitySourceMetrics.recordsWithPartiallyRecognizedLastName /
                            (identitySourceMetrics.recordsWithRecognizedLastName +
                              identitySourceMetrics.recordsWithUnrecognizedLastName +
                              identitySourceMetrics.recordsWithPartiallyRecognizedLastName)
                          : 0
                      }
                      inaccurateTo={{
                        pathname: `/sources/${source.id}/data-explorer`,
                        search: `?lastNameScore=nonverified`,
                      }}
                      questionableTo={{
                        pathname: `/sources/${source.id}/data-explorer`,
                        search: `?lastNameScore=suspicious`,
                      }}
                    />
                    <AccurateVerificationCard
                      label="Email address data field"
                      accurate={identitySourceMetrics.recordsWithValidEmailAddress}
                      accuratePercent={
                        identitySourceMetrics.recordsWithValidEmailAddress !== 0
                          ? identitySourceMetrics.recordsWithValidEmailAddress /
                            (identitySourceMetrics.recordsWithValidEmailAddress +
                              identitySourceMetrics.recordsWithInvalidEmailAddress +
                              identitySourceMetrics.recordsWithUnconfirmedEmailAddress)
                          : 0
                      }
                      inaccurate={identitySourceMetrics.recordsWithInvalidEmailAddress}
                      inaccuratePercent={
                        identitySourceMetrics.recordsWithInvalidEmailAddress !== 0
                          ? identitySourceMetrics.recordsWithInvalidEmailAddress /
                            (identitySourceMetrics.recordsWithValidEmailAddress +
                              identitySourceMetrics.recordsWithInvalidEmailAddress +
                              identitySourceMetrics.recordsWithUnconfirmedEmailAddress)
                          : 0
                      }
                      questionable={identitySourceMetrics.recordsWithUnconfirmedEmailAddress}
                      questionablePercent={
                        identitySourceMetrics.recordsWithUnconfirmedEmailAddress !== 0
                          ? identitySourceMetrics.recordsWithUnconfirmedEmailAddress /
                            (identitySourceMetrics.recordsWithValidEmailAddress +
                              identitySourceMetrics.recordsWithInvalidEmailAddress +
                              identitySourceMetrics.recordsWithUnconfirmedEmailAddress)
                          : 0
                      }
                      inaccurateTo={{
                        pathname: `/sources/${source.id}/data-explorer`,
                        search: `?emailScore=nonverified`,
                      }}
                      questionableTo={{
                        pathname: `/sources/${source.id}/data-explorer`,
                        search: `?emailScore=suspicious`,
                      }}
                    />
                    <AccurateVerificationCard
                      label="Phone number data field"
                      accurate={identitySourceMetrics.recordsWithValidPhone}
                      accuratePercent={
                        identitySourceMetrics.recordsWithValidPhone !== 0
                          ? identitySourceMetrics.recordsWithValidPhone /
                            (identitySourceMetrics.recordsWithValidPhone +
                              identitySourceMetrics.recordsWithInvalidPhone)
                          : 0
                      }
                      inaccurate={identitySourceMetrics.recordsWithInvalidPhone}
                      inaccuratePercent={
                        identitySourceMetrics.recordsWithInvalidPhone !== 0
                          ? identitySourceMetrics.recordsWithInvalidPhone /
                            (identitySourceMetrics.recordsWithValidPhone +
                              identitySourceMetrics.recordsWithInvalidPhone)
                          : 0
                      }
                      // phone field does not exist
                      questionable={0}
                      questionablePercent={0}
                      questionableTo={{
                        pathname: `/sources/${source.id}/data-explorer`,
                        search: `?phoneScore=nonverified`,
                      }}
                      inaccurateTo={{
                        pathname: `/sources/${source.id}/data-explorer`,
                        search: `?phoneScore=suspicious`,
                      }}
                    />
                  </>
                )}
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </PageLayout>
  );
}
export default SystemDashboardPage;
