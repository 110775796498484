import { Box, BoxProps, HStack, Text } from '@chakra-ui/react';
import Icon from '../Icon/Icon';
import { IconImage } from '../Icon/IconConfig';

interface Props {
  title: string;
  content: JSX.Element;
}

type ProTipProps = Props & BoxProps;

export default function ProTip({ title, content, ...rest }: ProTipProps) {
  return (
    <Box
      p={3}
      borderRadius="md"
      border="1px solid"
      borderColor="gray.100"
      boxShadow="base"
      {...rest}
    >
      <HStack spacing="3" mb="3">
        <Icon iconImage={IconImage.innovation} color="success" boxSize={5} />
        <Text fontSize="sm" fontWeight="bold" lineHeight="5">
          {title}
        </Text>
      </HStack>
      {content}
    </Box>
  );
}
