import { Box, BoxProps, Select, Text, SimpleGrid } from '@chakra-ui/react';
import { startOfDay, startOfHour, startOfMonth, startOfWeek, sub } from 'date-fns';
import React, { ChangeEvent, useEffect, useState, useRef } from 'react';
import { DataSourceTimeSeriesMetrics } from '../../../../lib/api-client/source-metrics/model/TimeSeriesQueryResponse';
import Mixpanel from '../../../../lib/mixpanel/Mixpanel';
import { TimeSeriesIntervalName } from '../../../../lib/model/common';
import { BasicTimeSeriesData } from '../../../../lib/model/visualizations/BasicTimeSeriesData';
import DataSourceTimeSeriesMetricsService from '../../../../lib/services/DataSourceTimeSeriesMetricsService';
import { formatDateString, getCurrentTimezone } from '../../../../lib/utils/date-time-utils';
import { formatNumber } from '../../../../lib/utils/number-utils';
import MetricCard from '../../../core/MetricCard/MetricCard';
import SectionContainer from '../../../core/SectionContainer/SectionContainer';
import SimpleBarGraph from '../../../shared/visualizations/SimpleBarGraph/SimpleBarGraph';

// NOTE: The start of the window needs to be the length of the interval - 1. For example
// the start date for the last 12 hours is now - 11 hours.
const CHART_CONFIG: {
  [key: string]: {
    label: string;
    value: string;
    getStartDate: (date: Date) => Date;
  }[];
} = {
  hour: [
    {
      label: 'Last 12 hours',
      value: 'last12Hours',
      getStartDate: (date: Date) => startOfHour(sub(date, { hours: 11 })),
    },
    {
      label: 'Last 24 hours',
      value: 'last24Hours',
      getStartDate: (date: Date) => startOfHour(sub(date, { hours: 23 })),
    },
    {
      label: 'Last 36 hours',
      value: 'last36Hours',
      getStartDate: (date: Date) => startOfHour(sub(date, { hours: 35 })),
    },
    {
      label: 'Last 48 hours',
      value: 'last48Hours',
      getStartDate: (date: Date) => startOfHour(sub(date, { hours: 47 })),
    },
  ],
  day: [
    {
      label: 'Last 7 days',
      value: 'last7days',
      getStartDate: (date: Date) => startOfDay(sub(date, { days: 6 })),
    },
    {
      label: 'Last 14 days',
      value: 'last14days',
      getStartDate: (date: Date) => startOfDay(sub(date, { days: 13 })),
    },
  ],
  week: [
    {
      label: 'Last 4 weeks',
      value: 'last4weeks',
      getStartDate: (date: Date) => startOfWeek(sub(date, { weeks: 3 })),
    },
    {
      label: 'Last 6 weeks',
      value: 'last6weeks',
      getStartDate: (date: Date) => startOfWeek(sub(date, { weeks: 5 })),
    },
    {
      label: 'Last 8 weeks',
      value: 'last8weeks',
      getStartDate: (date: Date) => startOfWeek(sub(date, { weeks: 7 })),
    },
    {
      label: 'Last 10 weeks',
      value: 'last10weeks',
      getStartDate: (date: Date) => startOfWeek(sub(date, { weeks: 9 })),
    },
    {
      label: 'Last 12 weeks',
      value: 'last12weeks',
      getStartDate: (date: Date) => startOfWeek(sub(date, { weeks: 11 })),
    },
  ],
  month: [
    {
      label: 'Last 3 months',
      value: 'last3months',
      getStartDate: (date: Date) => startOfMonth(sub(date, { months: 2 })),
    },
    {
      label: 'Last 6 months',
      value: 'last6months',
      getStartDate: (date: Date) => startOfMonth(sub(date, { months: 5 })),
    },
    {
      label: 'Last 9 months',
      value: 'last9months',
      getStartDate: (date: Date) => startOfMonth(sub(date, { months: 8 })),
    },
    {
      label: 'Last 12 months',
      value: 'last12months',
      getStartDate: (date: Date) => startOfMonth(sub(date, { months: 11 })),
    },
  ],
};

export function ToolTipContent({ interval, payload, isLastInterval, sum }: any) {
  const recordEventsIngestedMetric = payload[0]?.payload?.metric?.recordEventsIngested;
  const recordsDeletedMetric = payload[0]?.payload?.metric?.recordsDeleted;

  const isTime = interval === 'hour';
  const startDate =
    sum === 'sum:recordsDeleted'
      ? formatDateString(recordsDeletedMetric?.startDate, 'h:mm a')
      : formatDateString(recordEventsIngestedMetric?.startDate, 'h:mm a');
  const endDate =
    sum === 'sum:recordsDeleted'
      ? formatDateString(recordsDeletedMetric?.endDate, "h:mm a 'on' MMM dd")
      : formatDateString(recordEventsIngestedMetric?.endDate, "h:mm a 'on' MMM dd");

  return (
    <>
      <Text fontSize="md" fontWeight="bold">
        {formatNumber(
          sum === 'sum:recordsDeleted'
            ? recordsDeletedMetric?.value
            : recordEventsIngestedMetric?.value
        )}{' '}
        new data record {sum === 'sum:recordsDeleted' ? 'delete' : 'add or update'} events
      </Text>
      {isTime ? (
        <Text>
          ingested between {startDate} - {endDate}
        </Text>
      ) : (
        <Text>ingested during this time window</Text>
      )}
      {isLastInterval && (
        <Text mt="4">
          Note that this time window is in progress and does not yet represent a complete data point
        </Text>
      )}
    </>
  );
}

function IngestionHistoryChart({
  sourceId,
  recordsDeleted,
  recordEventsIngested,
  error,
  chartWidth = '100%',
  chartHeight = '100%',
  hideFilters = false,
  hideMetricsCards = false,
  hideReferenceValue = false,
  initialEventType = 'addupdate',
  onDataChange,
  ...rest
}: {
  sourceId: string;
  chartHeight?: string | number;
  chartWidth?: string | number;
  recordsDeleted?: number;
  recordEventsIngested?: number;
  error?: number;
  hideFilters?: boolean;
  hideReferenceValue?: boolean;
  hideMetricsCards?: boolean;
  initialEventType?: 'addupdate' | 'delete';
  onDataChange?: (data: BasicTimeSeriesData) => void;
} & BoxProps) {
  const [interval, setInterval] = useState<TimeSeriesIntervalName>('day');
  const [config, setConfig] = useState(CHART_CONFIG.day[0]);
  const [data, setData] = useState<BasicTimeSeriesData>({
    data: [],
  });

  const initialSum: DataSourceTimeSeriesMetrics | string[] =
    initialEventType === 'addupdate'
      ? ['sum:recordEventsIngested', 'sum:recordsDeleted']
      : ('sum:recordsDeleted' as DataSourceTimeSeriesMetrics);

  const [sum, setSum] = useState(initialSum);

  const [yDataKey, setYDataKey] = useState<string>(
    initialEventType === 'addupdate'
      ? 'metric.recordEventsIngested.value'
      : 'metric.recordsDeleted.value'
  );

  const onIntervalChange = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
    let intervalProperties;
    if (value in CHART_CONFIG) {
      setConfig(CHART_CONFIG[value][0]);
      setInterval(value as TimeSeriesIntervalName);
      intervalProperties = {
        chart_name: 'IngestionHistory',
        time_interval: value,
        time_window_length: CHART_CONFIG[value][0].value,
      };
      Mixpanel.track('Change Chart', intervalProperties);
    }
  };

  const onAmountChange = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
    let amountProperties;
    if (interval in CHART_CONFIG) {
      const selectedConfig = CHART_CONFIG[interval].find(
        (timeWindowConfig) => timeWindowConfig.value === value
      );
      if (selectedConfig) {
        setConfig(selectedConfig);
      }
      amountProperties = {
        chart_name: 'IngestionHistory',
        time_interval: interval,
        time_window_length: value,
      };
      Mixpanel.track('Change Chart', amountProperties);
    }
  };

  const handleChange = (e: any) => {
    const selectedValue = e.target.value;
    setSum(e.target.value);
    setYDataKey(
      selectedValue === 'sum:recordEventsIngested'
        ? 'metric.recordEventsIngested.value'
        : 'metric.recordsDeleted.value'
    );
  };
  const initialDataRef = useRef(data);

  useEffect(() => {
    const now = new Date();
    DataSourceTimeSeriesMetricsService.getTimeSeries(
      sourceId,
      [sum as DataSourceTimeSeriesMetrics],
      config.getStartDate(now),
      now,
      getCurrentTimezone(),
      interval,
      'sum',
      ['avg', 'sum']
    ).then((timeSeriesData) => {
      setData(timeSeriesData);

      if (initialDataRef.current.data.length === 0) {
        initialDataRef.current = timeSeriesData;
      }

      if (onDataChange) {
        onDataChange(timeSeriesData);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceId, interval, config, sum, onDataChange]);
  const recordEventsIngestedSum =
    initialDataRef?.current?.referenceValue?.recordEventsIngested?.sum;
  const recordsDeletedSum = initialDataRef?.current?.referenceValue?.recordsDeleted?.sum;

  return (
    <>
      <Box data-testid="IngestionHistoryChart" {...rest}>
        {!hideFilters && (
          <Box mb="4">
            <Select onChange={handleChange} display="inline-block" w="auto" mr="2" fontSize="14">
              <option value="sum:recordEventsIngested">Show adds & updates</option>
              <option value="sum:recordsDeleted">Show deletes</option>
            </Select>
            <Select
              defaultValue="day"
              onChange={onIntervalChange}
              display="inline-block"
              w="auto"
              mr="2"
              fontSize="14"
            >
              <option value="hour">Show hourly</option>
              <option value="day">Show daily</option>
              <option value="week">Show weekly</option>
              <option value="month">Show monthly</option>
            </Select>
            <Select onChange={onAmountChange} display="inline-block" w="auto">
              {CHART_CONFIG[interval].map((c) => (
                <option key={c.label} value={c.value}>
                  {c.label}
                </option>
              ))}
            </Select>
          </Box>
        )}

        <SimpleBarGraph
          width={chartWidth}
          height={chartHeight}
          data={data.data}
          xDataKey="label"
          yDataKey={yDataKey}
          highlightLastInterval
          referenceValue={
            hideReferenceValue ? undefined : data.referenceValue?.recordEventsIngested?.avg
          }
          tooltipContent={<ToolTipContent interval={interval} sum={sum} />}
          referenceContent={
            data?.referenceValue && (
              <>
                {sum === 'sum:recordsDeleted' ? (
                  <Text fontSize="md" fontWeight="bold">
                    {formatNumber(data?.referenceValue?.recordEventsDeleted?.avg)} new data record
                    delete events
                  </Text>
                ) : (
                  <Text fontSize="md" fontWeight="bold">
                    {formatNumber(data?.referenceValue?.recordEventsIngested?.avg)} new data record
                    add or update events
                  </Text>
                )}
                <Text fontSize="sm">ingested per time window on average</Text>
              </>
            )
          }
        />
      </Box>
      {hideMetricsCards && (
        <SimpleGrid columns={3} spacing={4} w="full" mt={4} mb={2}>
          <SectionContainer title="Adds & updates" variant="box" fontSize="14">
            <MetricCard
              value={recordEventsIngested ?? 0}
              label="total data record add or update events ingested from this system"
              badge
              label2=" last 7 days"
              badgeValue={recordEventsIngestedSum ?? 0}
            />
          </SectionContainer>
          <SectionContainer title="Deletes" variant="box" fontSize="14">
            <MetricCard
              value={recordsDeleted ?? 0}
              label="total data record delete events ingested from this system"
              badge
              badgeValue={recordsDeletedSum ?? 0}
              label2=" last 7 days"
            />
          </SectionContainer>
          <SectionContainer title="Errors" variant="box" fontSize="14">
            <MetricCard
              value={error ?? 0}
              label="total ingestion errors detected in the last 7 days"
              button
              id={sourceId}
            />
          </SectionContainer>
        </SimpleGrid>
      )}
    </>
  );
}
export default IngestionHistoryChart;
