import ApiClient from '../ApiClient';

const SourceDatabyIdClient = {
  async exportIssueReportData(sourceId: string, sourceRecordId: string) {
    return ApiClient.get(`/sources/${sourceId}/identity-records/${sourceRecordId}`, {}).then(
      (res) => res.data
    );
  },
};

export default SourceDatabyIdClient;
