import { Button, ButtonProps, forwardRef, SystemStyleObject } from '@chakra-ui/react';
import { useCardStyles } from './Card';

type SystemStyleObjectWithTheming = SystemStyleObject & { variant: string };

const CardAction = forwardRef<ButtonProps, 'button'>((props, ref) => {
  const styles = useCardStyles();
  const { variant, ...rest } = (styles.action || {}) as SystemStyleObjectWithTheming;
  return <Button ref={ref} sx={rest} variant={variant} {...props} />;
});

export default CardAction;
