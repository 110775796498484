import { BoxProps, Button, chakra, HStack, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Identity,
  IdentityAttribute,
  IdentityAttributeValue,
} from '../../../../lib/api-client/identity/model/Identity';
import ProfileAttributeScoreBadge from '../../../../pages/profile/components/ProfileAttributeScoreBadge/ProfileAttributeScoreBadge';
import ProfileCompletenessBadge from '../../../../pages/profile/components/ProfileCompletenessBadge/ProfileCompletenessBadge';
import SectionContainer from '../../../core/SectionContainer/SectionContainer';

interface ProfilePageIdentityDataProps {
  identity: Identity;
}

type IdentityAttributeFieldProps = {
  label: string;
  attribute: string;
  value?: Nullable<IdentityAttribute>;
  pin: string;
};
function IdentityAttributeField({
  label,
  attribute,
  value,
  pin,
  ...rest
}: IdentityAttributeFieldProps & BoxProps) {
  let format: ((value: any) => any) | undefined;
  if (attribute === 'phone') {
    format = (attrValue: IdentityAttributeValue | undefined) => {
      if (!attrValue) {
        return undefined;
      }

      return {
        value: `(${attrValue.components[0]?.type.substring(0, 1)}) ${attrValue.value}`,
        attribute: attrValue.components[0]?.type,
      };
    };
  }

  let formattedPrimary;
  if (format) {
    formattedPrimary = format(value?.primaryValue);
  }

  return (
    <SectionContainer
      variant="box"
      header={
        <HStack justifyContent="space-between" fontSize="sm" fontWeight="semibold" p={3}>
          <chakra.span color="gray.800">{label}</chakra.span>
          <chakra.span fontSize="xs" color="gray.600">
            {value ? value.otherValues.length + 1 : 0} total
          </chakra.span>
        </HStack>
      }
      titleMargin="0"
      fontSize="sm"
      p={0}
      {...rest}
    >
      {!value ? (
        <Text p={3}>Not on record</Text>
      ) : (
        <Stack p={0}>
          <HStack p={3} justifyContent="space-between">
            <chakra.span w="180px">
              {formattedPrimary?.value ?? value.primaryValue.value}
            </chakra.span>
            ;
            <ProfileAttributeScoreBadge
              attribute={formattedPrimary?.attribute ?? attribute}
              attributeValue={value.primaryValue}
            />
          </HStack>
        </Stack>
      )}
    </SectionContainer>
  );
}

function ProfilePageIdentityData({ identity }: ProfilePageIdentityDataProps) {
  return (
    <SectionContainer
      header={
        <HStack justify="space-between">
          <Text fontSize="md" lineHeight={5} fontWeight="bold">
            Primary identity data
          </Text>
          <ProfileCompletenessBadge completeness={identity.completeness} />
        </HStack>
      }
      data-testid="ProfilePageIdentityData"
      w="full"
    >
      <SimpleGrid columns={3} gap={5}>
        <IdentityAttributeField
          label="Full name"
          attribute="name"
          value={identity.identityAttributes.name}
          pin={identity.pin}
          data-testid="IdentityDataField_FullName"
        />
        <IdentityAttributeField
          label="Phone number"
          attribute="phone"
          value={identity.identityAttributes.phone}
          pin={identity.pin}
          data-testid="IdentityDataField_PhoneNumber"
        />
        <IdentityAttributeField
          label="Date of birth"
          attribute="dateOfBirth"
          value={identity.identityAttributes.dateOfBirth}
          pin={identity.pin}
          data-testid="IdentityDataField_DateOfBirth"
        />
        <IdentityAttributeField
          label="Email address"
          attribute="emailAddress"
          value={identity.identityAttributes.emailAddress}
          pin={identity.pin}
          data-testid="IdentityDataField_EmailAddress"
        />
        <IdentityAttributeField
          label="Address"
          attribute="address"
          value={identity.identityAttributes.address}
          pin={identity.pin}
          data-testid="IdentityDataField_MailingAddress"
        />
      </SimpleGrid>
      <HStack alignItems="start" mt={6}>
        <Button variant="solid" as={Link} to={`/profiles/${identity.pin}/identities`}>
          See all identity data
        </Button>
        <Button
          variant="outline"
          as={Link}
          to={`/profiles/${identity.pin}/identities?tab=source-data`}
        >
          See all source data records
        </Button>
      </HStack>
    </SectionContainer>
  );
}

export default ProfilePageIdentityData;
