import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Tooltip,
  useBoolean,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import YupPassword from 'yup-password';
import { useAuth } from '../../../../context/AuthenticationContext';
import { AuthenticationConfiguration } from '../../../../lib/api-client/authentication/model/AuthenticationConfiguration';
import AuthenticationService from '../../../../lib/services/AuthenticationService';
import { hasErrorCode } from '../../../../lib/utils/error-utils';
import Icon from '../../../core/Icon/Icon';
import { IconImage } from '../../../core/Icon/IconConfig';
import FormLogoHeader from '../FormLogoHeader/FormLogoHeader';
import PasswordTooltip from '../PasswordTooltip/PasswordTooltip';

YupPassword(yup);

const resetPasswordFormSchema = yup
  .object({
    verificationCode: yup.string().required('Verification code is required'),
    password: yup
      .string()
      .required('New password is required')
      .password()
      .min(8)
      .minUppercase(1)
      .minLowercase(1)
      .minSymbols(1),
    passwordConfirmation: yup
      .string()
      .required('New password confirmation is required')
      .oneOf([yup.ref('password'), ''], 'Passwords must match'),
  })
  .required();

interface ChangePasswordFormData {
  verificationCode: string;
  password: string;
  passwordConfirmation: string;
  serverError?: any;
}

interface ResetPasswordFormProps {
  accountEmail: string;
  authenticationConfiguration: AuthenticationConfiguration;
  onError: () => void;
}

export default function ResetPasswordForm({
  accountEmail,
  authenticationConfiguration,
  onError,
}: ResetPasswordFormProps) {
  const [isLoading, setLoading] = useBoolean(false);
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<ChangePasswordFormData>({
    resolver: yupResolver(resetPasswordFormSchema),
    mode: 'onBlur',
  });

  const onSubmit: SubmitHandler<any> = async (form) => {
    setLoading.on();
    try {
      await AuthenticationService.confirmNewPassword(
        authenticationConfiguration.userPool.id,
        authenticationConfiguration.userPool.clientId,
        accountEmail,
        form.verificationCode,
        form.password
      );
      await signIn({
        email: accountEmail,
        password: form.password,
        userPool: {
          id: authenticationConfiguration.userPool.id,
          clientId: authenticationConfiguration.userPool.clientId,
        },
      });
      navigate('/', { replace: true });
    } catch (err: unknown) {
      setLoading.off();
      if (hasErrorCode(err) && err.code === 'CodeMismatchException') {
        setError('verificationCode', {
          type: 'custom',
          message: 'Verification code does not match',
        });
        return;
      }
      onError();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormLogoHeader
        message="Reset your password"
        subMessage="A verification code has been sent to your email."
      />
      <Box paddingX="170px">
        <FormControl isInvalid={!!errors.verificationCode} paddingBottom="40px">
          <FormLabel htmlFor="verificationCode" display="flex" justifyContent="space-between">
            Verification code
          </FormLabel>
          <Input
            id="verificationCode"
            type="password"
            {...register('verificationCode')}
            autoFocus
          />
          <FormErrorMessage>{errors?.verificationCode?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.password} paddingBottom="40px">
          <FormLabel htmlFor="password" display="flex" justifyContent="space-between">
            New password
            <Tooltip hasArrow h="120px" label={<PasswordTooltip />} placement="top">
              <span>
                <Icon ml="4px" iconImage={IconImage.info} cursor="pointer" color="action" />
              </span>
            </Tooltip>
          </FormLabel>
          <Input id="password" type="password" {...register('password')} />
          <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.passwordConfirmation}>
          <FormLabel htmlFor="passwordConfirmation">Confirm new password</FormLabel>
          <Input id="passwordConfirmation" type="password" {...register('passwordConfirmation')} />
          <FormErrorMessage>{errors?.passwordConfirmation?.message}</FormErrorMessage>
        </FormControl>
      </Box>
      <Button w="200px" h="48px" m="40px" type="submit" isLoading={isLoading}>
        Save new password
      </Button>
    </form>
  );
}
