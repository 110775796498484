/* eslint-disable no-nested-ternary */
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import Header from '../../../../components/core/Header/Header';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';
import DataSourceIcon from '../../../../components/features/connections/DataSourceIcon/DataSourceIcon';
import PageLayout from '../../../../components/shared/layouts/PageLayout/PageLayout';
import { useCurrentDataSource } from '../../../sources/context/CurrentDataSourceContext';

function CustomTab({ isCompleted, isDisabled, isSelected, children, ...props }: any) {
  return (
    <Tab
      _selected={{
        border: '2px',
        borderColor: 'green',
        bgColor: 'white',
      }}
      pt={1}
      pb={1}
      pl={1}
      pr={2}
      fontSize="14"
      fontWeight="400"
      color={isSelected ? 'gray.800' : isCompleted ? 'white' : 'gray.800'}
      border={isSelected ? '2px' : 'none'}
      borderColor={isSelected ? 'green' : 'none'}
      bgColor={isCompleted ? (isSelected ? 'gray.200' : 'success') : 'gray.200'}
      borderRadius="26px"
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      opacity={isDisabled ? 0.5 : 1}
      pointerEvents={isDisabled ? 'none' : 'auto'}
      {...props}
    >
      {children}
    </Tab>
  );
}

function AccessTabPanel({ onNext }: { onNext: () => void }) {
  const [accessMethod, setAccessMethod] = useState('');

  const handleAccessMethodChange = (event: any) => {
    setAccessMethod(event.target.value);
  };
  return (
    <TabPanel pl={-4}>
      <form>
        <Text>
          Additional configuration fields will load based on the access method that is selected
          below.
        </Text>
        <Box w="528px" mt={4}>
          <VStack alignItems="start" spacing={4}>
            <FormControl>
              <FormLabel>Access method </FormLabel>
              <Select placeholder="Select one" onChange={handleAccessMethodChange}>
                <option value="DIRECT_CONNECT">DIRECT_CONNECT</option>
              </Select>
              <FormHelperText fontSize="12" color="gray.600">
                This is the method harpin AI will use to access data in the data source
              </FormHelperText>
            </FormControl>
            {accessMethod === 'DIRECT_CONNECT' && (
              <>
                <FormLabel>Organization</FormLabel>
                <InputGroup mt={-4}>
                  <Tooltip
                    label="Field level tooltip content goes here and continues to wrap as needed..."
                    placement="right"
                  >
                    <InputRightElement pr={2}>
                      <Icon iconImage={IconImage.info} color="action" boxSize={4} />
                    </InputRightElement>
                  </Tooltip>
                  <FormControl>
                    <Input />
                  </FormControl>
                </InputGroup>
                <FormLabel>Account</FormLabel>
                <InputGroup mt={-4}>
                  <Tooltip
                    label="Field level tooltip content goes here and continues to wrap as needed..."
                    placement="right"
                  >
                    <InputRightElement pr={2}>
                      <Icon iconImage={IconImage.info} color="action" boxSize={4} />
                    </InputRightElement>
                  </Tooltip>
                  <FormControl>
                    <Input />
                  </FormControl>
                </InputGroup>
                <FormControl>
                  <FormLabel>Username</FormLabel>
                  <Input />
                </FormControl>
                <FormControl mt={2}>
                  <FormLabel>Password</FormLabel>
                  <Input />
                </FormControl>
                <Stack direction="row" spacing={4} w="full">
                  <Button w="full" onClick={onNext}>
                    save and continue
                  </Button>
                  <Button w="full" colorScheme="teal" variant="outline">
                    Test connection
                  </Button>
                </Stack>
              </>
            )}
          </VStack>
        </Box>
      </form>
    </TabPanel>
  );
}

function DatabaseObjectTabPanel({ onNext }: { onNext: () => void }) {
  return (
    <TabPanel>
      <form>
        <Text>
          Values identified during the test connection have been auto-populated below. Review them
          for accuracy and make any necessary edits before continuing.
        </Text>
        <Box w="528px" mt={4}>
          <VStack alignItems="start" spacing={4}>
            <FormControl>
              <FormLabel>Database</FormLabel>
              <Input />
            </FormControl>
            <FormControl>
              <FormLabel>Schema</FormLabel>
              <Input />
            </FormControl>
            <FormControl>
              <FormLabel>Source object</FormLabel>
              <Input />
            </FormControl>
            <FormControl>
              <FormLabel>Columns</FormLabel>
              <Input />
            </FormControl>
            <Stack direction="row" mt={2} spacing={4} w="full">
              <Button w="full" onClick={onNext}>
                save and continue
              </Button>
              <Button w="full" colorScheme="teal" variant="outline">
                Test connection
              </Button>
            </Stack>
          </VStack>
        </Box>
      </form>
    </TabPanel>
  );
}

function ObjectRefreshTabPanel() {
  return (
    <TabPanel pl={-4}>
      <SectionContainer title="Object refresh configuration" w="528px" mt={4}>
        <form>
          <VStack alignItems="start" spacing={4}>
            <Checkbox defaultChecked size="sm">
              Use change tracking to detect updates and deletes
            </Checkbox>
            <FormControl>
              <FormLabel>Column name for querying incremental updates</FormLabel>
              <Input />
            </FormControl>
            <Stack direction="row" mt={2} spacing={4} w="full">
              <Button w="full">save </Button>
              <Button w="full" colorScheme="teal" variant="outline">
                Test connection
              </Button>
            </Stack>
          </VStack>
        </form>
      </SectionContainer>
    </TabPanel>
  );
}

function ConnectionConfigurationPage() {
  const { dataSource } = useCurrentDataSource();
  const [tabIndex, setTabIndex] = useState(0);
  const [completedTabs, setCompletedTabs] = useState<boolean[]>([false, false, false]);

  const handleNext = () => {
    setCompletedTabs((prev) => {
      const newCompletedTabs = [...prev];
      newCompletedTabs[tabIndex] = true;
      return newCompletedTabs;
    });
    setTabIndex((prevIndex) => prevIndex + 1);
  };

  const handleTabChange = (index: number) => {
    if (completedTabs[index] || index < tabIndex) {
      setTabIndex(index);
    }
  };

  return (
    <PageLayout
      loading={false}
      pageViewEvent={{ page: 'Connection configuration' }}
      header={
        <Header
          title={`${dataSource.name} connection configuration`}
          icon={<DataSourceIcon sourceSystem={dataSource.sourceSystem} />}
          back={{
            label: 'Back to source connections dashboard',
            to: `/sources/${dataSource.id}/manage`,
          }}
        />
      }
    >
      <Tabs
        variant="soft-rounded"
        borderColor="red"
        colorScheme="whiteScheme"
        index={tabIndex}
        onChange={handleTabChange}
        _disabled={{ cursor: 'not-allowed' }}
      >
        <TabList>
          <HStack spacing={0}>
            <CustomTab
              isCompleted={completedTabs[0]}
              isDisabled={!completedTabs[0] && tabIndex !== 0}
              isSelected={tabIndex === 0}
            >
              <Icon
                iconImage={IconImage.success}
                color={tabIndex === 0 ? 'gray.400' : completedTabs[0] ? 'white' : 'gray.400'}
              />{' '}
              Access
            </CustomTab>
            <Text
              border="2px"
              w="18px"
              borderColor={completedTabs[0].toString() === 'true' ? 'success' : 'gray.300'}
            >
              {' '}
            </Text>
          </HStack>
          <HStack spacing={0}>
            <CustomTab
              isCompleted={completedTabs[1]}
              isDisabled={!completedTabs[1] && tabIndex !== 1}
              isSelected={tabIndex === 1}
            >
              <Icon
                iconImage={IconImage.success}
                color={tabIndex === 1 ? 'gray.400' : completedTabs[1] ? 'white' : 'gray.400'}
              />{' '}
              Database object
            </CustomTab>
            <Text
              border="2px"
              w="18px"
              borderColor={completedTabs[1].toString() === 'true' ? 'success' : 'gray.300'}
            >
              {' '}
            </Text>
          </HStack>
          <CustomTab
            isCompleted={completedTabs[2]}
            isDisabled={!completedTabs[2] && tabIndex !== 2}
            isSelected={tabIndex === 2}
          >
            <Icon
              iconImage={IconImage.success}
              color={tabIndex === 2 ? 'gray.400' : completedTabs[2] ? 'white' : 'gray.400'}
            />{' '}
            Object refresh
          </CustomTab>
        </TabList>
        <TabPanels>
          <AccessTabPanel onNext={handleNext} />
          <DatabaseObjectTabPanel onNext={handleNext} />
          <ObjectRefreshTabPanel />
        </TabPanels>
      </Tabs>
    </PageLayout>
  );
}

export default ConnectionConfigurationPage;
