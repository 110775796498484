import { Button, ButtonProps, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SourceClient from '../../../../lib/api-client/sources/SourceClient';
import { SourceTypes } from '../../../../lib/api-client/sources/model/SourceTypes';
import Icon from '../../../core/Icon/Icon';
import { IconImage } from '../../../core/Icon/IconConfig';
import EditSourceSystemModal from '../EditSourceSystemModal/EditSourceSystemModal';

function ConnectNewSystemButton({ ...rest }: Omit<ButtonProps, 'onClick'>) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const [sourceTypes, setSourceTypes] = useState<SourceTypes>({
    systemTypes: [],
    categories: [],
  });

  useEffect(() => {
    SourceClient.getSourceTypes().then((types) => {
      setSourceTypes({
        ...types,
        systemTypes: types.systemTypes
          .filter((s) => s.uiCreation)
          .sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase())),
      });
    });
  }, []);

  return (
    <>
      <Button
        leftIcon={<Icon iconImage={IconImage.add} boxSize="4" />}
        data-testid="ConnectNewSystemButton"
        minW="unset"
        onClick={onOpen}
        {...rest}
      >
        Connect a new data source
      </Button>
      {sourceTypes && (
        <EditSourceSystemModal
          sourceTypes={sourceTypes}
          isOpen={isOpen}
          onClose={onClose}
          onSuccess={(result) => {
            navigate(`/sources/${result.id}/manage`);
            window.location.reload();
          }}
        />
      )}
    </>
  );
}
export default ConnectNewSystemButton;
