import { Stack } from '@chakra-ui/react';
import React from 'react';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import { useCurrentDataSource } from '../../sources/context/CurrentDataSourceContext';
import SetupConnectionPage from '../SetupConnectionPage/SetupConnectionPage';
import DataMappingsSection from './DataMappingsSection/DataMappingsSection';
import FlatFileSourceConfiguration from './FlatFileSourceConfiguration/FlatFileSourceConfiguration';
import IntegratedSourceConfiguration from './IntegratedSourceConfiguration/IntegratedSourceConfiguration';
import SourceAlerts from './SourceAlerts/SourceAlerts';
import SourceDetails from './SourceDetails/SourceDetails';

export default function SourceConnectionManagementPage() {
  const { dataSource } = useCurrentDataSource();
  const isFlatFile = dataSource.sourceSystem === 'flatFile';
  const isFullyConfigured = dataSource.configState === 'COMPLETE';

  return (
    <PageLayout
      loading={false}
      pageViewEvent={{ page: 'Connection management' }}
      header={
        <Header
          title={`${dataSource.name} connection management`}
          icon={<Icon iconImage={IconImage.database} data-testid="ConnectionManagementPage" />}
          back={{
            label: 'Back to source connections dashboard',
            to: '/sources/connections',
          }}
        />
      }
    >
      {isFullyConfigured ? (
        <Stack spacing={6}>
          <SourceAlerts />
          <SourceDetails />
          {isFlatFile ? <FlatFileSourceConfiguration /> : <IntegratedSourceConfiguration />}
          <DataMappingsSection source={dataSource} />
        </Stack>
      ) : (
        <SetupConnectionPage source={dataSource} onError={() => {}} />
      )}
    </PageLayout>
  );
}
