import ApiClient from '../ApiClient';
import { ReprocessEvents } from './identity.model';

/**
 * Identity client that can do admin things on the identity endpoint. These API calls require users
 * that are in a special group.
 */
const AdminIdentityClient = {
  reprocessEvents(data: ReprocessEvents): Promise<void> {
    return ApiClient.post<void>('/identities/_reprocess', data).then(() => {
      // ignore
    });
  },
};

export default AdminIdentityClient;
