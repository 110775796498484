import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { Location, useLocation } from 'react-router-dom';

type PageHistoryLabel = { label: string; icon?: JSX.Element };

export interface PageHistoryContext {
  setLabel: (label: PageHistoryLabel) => void;
  history: { label: PageHistoryLabel; location: Location }[];
}

const Context = createContext<PageHistoryContext>({} as PageHistoryContext);
interface PageHistoryProviderProps {
  children: ReactNode;
}

export function PageHistoryProvider({ children }: PageHistoryProviderProps) {
  const location: Location = useLocation();
  const [history, setHistory] = useState<{ label: PageHistoryLabel; location: Location }[]>([]);
  const memo = useMemo<PageHistoryContext>(
    () => ({
      setLabel: (label) => {
        setHistory((prev: { label: PageHistoryLabel; location: Location }[]) =>
          [{ label, location }, ...prev.filter((p) => p.location.key !== location.key)].slice(0, 3)
        );
      },
      history,
    }),
    [location, history]
  );
  return <Context.Provider value={memo}>{children}</Context.Provider>;
}

export function usePageHistory(): PageHistoryContext {
  return useContext(Context);
}
