import {
  Box,
  chakra,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  HStack,
} from '@chakra-ui/react';
import * as combobox from '@zag-js/combobox';
import { normalizeProps, useMachine } from '@zag-js/react';
import debounce from 'lodash.debounce';
import { DragEventHandler, useId, useState } from 'react';
import Icon from '../../Icon/Icon';
import { IconImage } from '../../Icon/IconConfig';

type ComboboxOption = {
  label: string;
  value: string;
  data?: Record<string, string>;
  disabled?: boolean;
};

interface ComboboxProps {
  name: string;
  options: ComboboxOption[];
  value?: string[];
  isDisabled?: boolean;
  placeholder?: string;
  allowCustomValue?: boolean;
  multiple?: boolean;
  fields?: any[];
  onChange?: combobox.Context['onValueChange'];
  onDrop?: DragEventHandler<HTMLDivElement>;
  onDragOver?: DragEventHandler<HTMLDivElement>;
  onDragStart?: DragEventHandler<HTMLDivElement>;
}

export default function Combobox({
  allowCustomValue,
  multiple,
  isDisabled,
  placeholder,
  options: data,
  value: valueProp = [],
  fields,
  onDrop,
  onDragOver,
  onDragStart,
  onChange,
}: ComboboxProps) {
  const [filter, setFilter] = useState<string | undefined>(undefined);
  const options = data.filter((option) =>
    filter ? option.label.toLowerCase().includes(filter.toLowerCase()) : true
  );

  const collection = combobox.collection({
    items: data,
    itemToValue: (item) => item.value,
    itemToString: (item) => item.label,
    isItemDisabled: (item) => !!item.disabled,
  });

  let inputValue = '';
  if (!multiple && allowCustomValue) {
    inputValue = data.find((d) => d.value === valueProp[0])?.label ?? valueProp[0];
  }

  let debouncedOnChange: combobox.Context['onValueChange'];
  if (onChange) {
    debouncedOnChange = debounce(onChange, 300);
  }

  const [state, send] = useMachine(
    combobox.machine({
      id: useId(),
      value: valueProp,
      inputValue,
      disabled: isDisabled,
      allowCustomValue,
      multiple,
      collection,
      onOpenChange(details) {
        if (!details.open) {
          setFilter(undefined);
        }
      },
      onInputValueChange({ value }) {
        setFilter(value);

        if (allowCustomValue || value.trim() === '') {
          debouncedOnChange?.({ value: [value], items: [] });
        }
      },
      onValueChange(event) {
        debouncedOnChange?.(event);
      },
    }),
    { context: { collection } }
  );

  const api = combobox.connect(state, send, normalizeProps);

  return (
    <Box w="full">
      <Box {...api.rootProps}>
        <InputGroup {...api.controlProps} draggable>
          <Input placeholder={placeholder} {...(api.inputProps as any)} />
          <HStack>
            {fields && fields?.length > 1 && onDrop && (
              <InputRightElement
                mr={6}
                draggable
                onDrop={onDrop}
                onDragOver={onDragOver}
                onDragStart={onDragStart}
              >
                <IconButton
                  aria-label="Drag and Drop Input Fields"
                  variant="ghost"
                  icon={<Icon boxSize={5} iconImage={IconImage.verticalScroll} />}
                />
              </InputRightElement>
            )}
            <InputRightElement>
              <IconButton
                aria-label="Show options"
                variant="ghost"
                icon={<Icon boxSize={5} iconImage={IconImage.chevronDown} />}
                {...api.triggerProps}
              />
            </InputRightElement>
          </HStack>
        </InputGroup>
      </Box>
      <Box
        bg="gray.50"
        borderRadius="md"
        border="1px solid"
        borderColor="gray.300"
        {...api.positionerProps}
      >
        {options.length > 0 && (
          <Stack {...api.contentProps} zIndex={3} spacing={0} maxH="56" overflow="auto">
            {options.map((option) => {
              let itemProps: any = api.getItemProps({ item: option })['aria-selected']
                ? { bg: 'highlight', cursor: 'pointer' }
                : {};

              if (option.disabled) {
                itemProps = {
                  color: 'gray.400',
                };
              }

              return (
                <chakra.span
                  key={`${option?.data?.domain ?? ''}_${option.value}_${option.disabled}`}
                  px={3}
                  py={1.5}
                  {...api.getItemProps({ item: option })}
                  {...itemProps}
                >
                  {option.label}
                </chakra.span>
              );
            })}
          </Stack>
        )}
      </Box>
    </Box>
  );
}
