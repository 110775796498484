import { IconButton, Tooltip, useBoolean } from '@chakra-ui/react';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import { useToast } from '../../../../hooks/use-toast';
import { downloadData } from '../../../../lib/api-client/ApiClient';
import IdentityClient from '../../../../lib/api-client/identity/IdentityClient';
import { SourceIdentityRecordGroup } from '../../../../lib/api-client/identity/model/SourceIdentityRecordGroup';
import { SourceIdentityRecordSearch } from '../../../../lib/api-client/identity/model/SourceIdentityRecordSearch';
import { SourceIssue } from '../../../../lib/api-client/sources/model/SourceIssue';
import Mixpanel from '../../../../lib/mixpanel/Mixpanel';

export default function ExportSourceIssueDataRecordCsvButton({
  issue,
}: {
  issue: Pick<SourceIssue, 'type' | 'group' | 'sourceId'>;
}) {
  const [isExporting, setIsExporting] = useBoolean();
  const toast = useToast();
  let exportParams: Partial<Pick<SourceIdentityRecordSearch, 'recordCategories' | 'duplicates'>> = {
    recordCategories: [issue.type],
  };
  if (issue.group === SourceIdentityRecordGroup.Duplicates) {
    exportParams = { duplicates: true };
  }

  const exportData = async () => {
    setIsExporting.on();
    try {
      Mixpanel.track('Export Source Issue Data Record CSV', {
        sourceId: issue.sourceId,
        issue: issue.type,
      });
      await IdentityClient.exportIdentityRecords({
        sourceId: issue.sourceId,
        ...exportParams,
      }).then((data) => downloadData(data, 'export.csv'));
      toast({ title: 'CSV export successful.' });
    } catch (err) {
      toast({ title: 'There was a problem exporting your CSV.' });
    } finally {
      setIsExporting.off();
    }
  };

  return (
    <Tooltip hasArrow label="Export CSV" placement="top">
      <IconButton
        aria-label="Download csv"
        variant="outline"
        icon={<Icon iconImage={IconImage.download} boxSize={4} />}
        onClick={exportData}
        isLoading={isExporting}
      />
    </Tooltip>
  );
}
