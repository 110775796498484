import ApiClient from '../ApiClient';
import { FlagNonDuplicateSourceRecords } from './model/FlagNonDuplicateSourceRecords';
import { FlagNonDuplicateSourceRecordsResponse } from './model/FlagNonDuplicateSourceRecordsResponse';

const DuplicateClient = {
  flagNonDuplicateSourceRecords(
    request: FlagNonDuplicateSourceRecords[]
  ): Promise<FlagNonDuplicateSourceRecordsResponse[]> {
    return ApiClient.post<FlagNonDuplicateSourceRecordsResponse[]>(
      `/duplicate-merge-decisions`,
      request
    ).then((res) => res.data);
  },
};
export default DuplicateClient;
