import { Button, chakra, HStack, Stack, VStack } from '@chakra-ui/react';
import React, { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';
import Alert from '../../../components/core/Alert/Alert';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import { useCurrentDataSource } from '../../sources/context/CurrentDataSourceContext';
import { getSetupType, TestIngestionResult } from './SetupConnectionPage.utils';

interface TestIngestionResultContext {
  testIngestionResult: TestIngestionResult;
  setTestIngestionResult: (result: TestIngestionResult) => void;
  setRestartTestIngestionFn: (fn: () => void) => void;
  restartTestIngestionFn: () => void;
}

const Context = createContext<TestIngestionResultContext | undefined>(undefined);

export function useTestIngestionResult() {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useTestIngestionResult must be used within a TestIngestionResultProvider');
  }
  return context;
}

function TestIngestionResultAlerts() {
  const { dataSource: source } = useCurrentDataSource();
  const { testIngestionResult, restartTestIngestionFn } = useTestIngestionResult();
  const setupType = getSetupType(source.sourceSystem ?? '');
  return (
    <Stack spacing={3} mb={3}>
      {testIngestionResult.isTestIngestionRunning && (
        <Alert
          status="loading"
          description="One moment while we process a sample set of data records to expedite the mappings configuration."
        />
      )}
      {testIngestionResult.isTestIngestionFailure && (
        <HStack
          justify="space-between"
          bgColor="error"
          p={2}
          border="1px solid"
          borderColor="error"
          borderRadius="6px"
        >
          <HStack spacing={3}>
            <chakra.span>
              <Icon boxSize={6} color="white" iconImage={IconImage.error} />
            </chakra.span>
            <VStack alignItems="start" spacing="0">
              {setupType === 'FLAT_FILE' ? (
                <chakra.span fontWeight="700" color="white">
                  We encountered the following error while processing the sample set of data
                  records. Please correct it and re-upload the data.
                </chakra.span>
              ) : (
                <chakra.span fontWeight="700" color="white">
                  We encountered the following error while processing the sample set of data
                  records. Please correct it and reprocess the data.
                </chakra.span>
              )}
              {testIngestionResult.sampleData?.errors?.[0] && (
                <chakra.span color="white">{testIngestionResult.sampleData.errors[0]}</chakra.span>
              )}
            </VStack>
          </HStack>
          {setupType !== 'FLAT_FILE' && (
            <Button
              onClick={() => {
                restartTestIngestionFn();
              }}
              variant="outline"
              borderColor="white"
              fontWeight="600"
              color="white"
              _hover={{ borderColor: 'white', color: 'white', bgColor: 'errorDark' }}
            >
              Reprocess data
            </Button>
          )}
        </HStack>
      )}
    </Stack>
  );
}

export function TestIngestionResultProvider({ children }: PropsWithChildren) {
  const [testIngestionResult, setTestIngestionResult] = useState<TestIngestionResult>({
    isTestIngestionRunning: false,
    isTestIngestionFailure: false,
  });
  const [restartTestIngestionFn, setRestartTestIngestionFn] = useState(() => () => {});

  const value = useMemo(
    () => ({
      testIngestionResult,
      setTestIngestionResult,
      setRestartTestIngestionFn,
      restartTestIngestionFn,
    }),
    [testIngestionResult, restartTestIngestionFn]
  );

  return (
    <Context.Provider value={value}>
      <TestIngestionResultAlerts />
      {children}
    </Context.Provider>
  );
}
