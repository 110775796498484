import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Stack,
  FormErrorMessage,
} from '@chakra-ui/react';
import { ChangeEvent, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SearchOperator } from '../../../../lib/api-client/identity/model/SourceIdentityRecordSearch';

interface AttributeFilterProps {
  fieldName: string;
  label: string;
  options: { label: string; value: string }[];
  disabled?: boolean;
}
export default function AttributeScoreFilter({
  label,
  disabled,
  fieldName,
  options,
}: AttributeFilterProps) {
  const form = useFormContext();
  const rangeField = `${fieldName}.range`;
  const operatorField = `${fieldName}.operator`;
  const specificRangeValue1 = `${fieldName}.value1`;
  const specificRangeValue2 = `${fieldName}.value2`;
  const [range, operator] = form.watch([rangeField, operatorField]);
  const [Operator, selectedOperator] = useState('');
  const [firstInputField, setFirstInputField] = useState(
    form.getValues(specificRangeValue1) >= 0 && form.getValues(specificRangeValue1) <= 1
      ? form.getValues(specificRangeValue1)
      : ''
  );

  const [secondInputField, setSecondInputField] = useState(
    form.getValues(specificRangeValue2) >= 0 && form.getValues(specificRangeValue2) <= 1
      ? form.getValues(specificRangeValue2)
      : ''
  );

  const handleChangeFirstInputField = (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
    let value;
    if (e.target.value) {
      value = parseFloat(e.target.value);
    }
    form.setValue(field, value);
    setFirstInputField(e.target.value);
  };
  const handleChangeSecondInputField = (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
    let value;
    if (e.target.value) {
      value = parseFloat(e.target.value);
    }
    form.setValue(field, value);
    setSecondInputField(e.target.value);
  };

  const handleNewChange = (e: any) => {
    if (e.target.value !== SearchOperator.Between) {
      form.setValue(specificRangeValue2, undefined);
    }
    selectedOperator(e.target.value);
    if (e.target.value !== 'None') {
      form.setValue(operatorField, e.target.value);
    }
  };

  const isFirstInputValid = firstInputField >= 0 && firstInputField <= 1;
  const isSecondInputValid = secondInputField >= 0 && secondInputField <= 1;
  const isInputOutOfRange = (value: number) => value < 0 || value > 1;
  const getInputErrorMessage = (isValid: boolean, value: number | undefined) =>
    !isValid && isInputOutOfRange(value!) ? 'Enter a value between 0 and 1' : '';
  const getBorderColor = (isValid: boolean, value: number | undefined) =>
    !isValid || isInputOutOfRange(value!) ? 'red.500' : 'gray.300';

  return (
    <Stack w="full">
      <FormControl>
        <FormLabel>{label}</FormLabel>
        <Select {...form.register(rangeField)} disabled={disabled}>
          <option value="all">All</option>
          {options.map((o) => (
            <option key={`${fieldName}-${o.value}`} value={o.value}>
              {o.label}
            </option>
          ))}
          <option value="range">Specific score range</option>
        </Select>
      </FormControl>
      {range === 'range' && (
        <>
          <FormControl w="full">
            <Select
              onChange={handleNewChange}
              disabled={disabled}
              value={form.getValues(`${fieldName}.operator`)}
            >
              {!Operator && <option value="None">Select One...</option>}
              <option value={SearchOperator.GreaterThan}>Greater than</option>
              <option value={SearchOperator.LessThan}>Less than</option>
              <option value={SearchOperator.Equal}>Equal</option>
              <option value={SearchOperator.Between}>Between</option>
            </Select>
          </FormControl>
          <HStack>
            <FormControl isInvalid={!isFirstInputValid}>
              <Input
                borderColor={getBorderColor(isFirstInputValid, firstInputField)}
                placeholder="Enter a value between 0-1"
                value={firstInputField}
                type="number"
                disabled={disabled}
                min={0}
                max={1}
                step="any"
                onChange={handleChangeFirstInputField(specificRangeValue1)}
              />
              <FormErrorMessage textColor="red.500">
                {getInputErrorMessage(isFirstInputValid, firstInputField)}
              </FormErrorMessage>
            </FormControl>
            {operator === SearchOperator.Between && (
              <FormControl
                borderColor={getBorderColor(isSecondInputValid, secondInputField)}
                isInvalid={!isSecondInputValid}
              >
                <Input
                  placeholder="Enter a value between 0-1"
                  value={secondInputField}
                  type="number"
                  disabled={disabled}
                  min={0}
                  max={1}
                  step="any"
                  onChange={handleChangeSecondInputField(specificRangeValue2)}
                />
                <FormErrorMessage>
                  {getInputErrorMessage(isSecondInputValid, secondInputField)}
                </FormErrorMessage>
              </FormControl>
            )}
          </HStack>
        </>
      )}
    </Stack>
  );
}
