import {
  Button,
  chakra,
  Flex,
  HStack,
  SimpleGrid,
  Text,
  Tooltip,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import consola from 'consola';
import React, { useMemo } from 'react';
import ActiveIndicator from '../../../../components/core/ActiveIndicator/ActiveIndicator';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';
import DataSourceIcon from '../../../../components/features/connections/DataSourceIcon/DataSourceIcon';
import { Can } from '../../../../context/AuthorizationContext';
import { useToast } from '../../../../hooks/use-toast';
import IdentityClient from '../../../../lib/api-client/identity/IdentityClient';
import { IdentityDataSource } from '../../../../lib/api-client/identity/model/Identity';
import { useDataSources } from '../../../../lib/api-client/sources/SourceData';
import { formatRelativeDateString } from '../../../../lib/utils/date-time-utils';

interface ProfilePageProfileDataSourcesProps {
  sources: IdentityDataSource[];
  pin: string;
}
function InfoTooltip() {
  return (
    <div>
      This status shows which connected source systems are contributing to the data for this
      consumer profile.
    </div>
  );
}
function ProfilePageProfileDataSources({ sources, pin }: ProfilePageProfileDataSourcesProps) {
  const { data } = useDataSources();

  // FIXME this should probably be some sort of global state
  const showExport = useMemo(
    () =>
      !!data?.content?.find(
        ({ syncDirection, properties }) =>
          (syncDirection === 'BOTH' || syncDirection === 'OUT') &&
          (properties?.exportMode === 'test' || properties?.exportMode === 'enabled')
      ),
    [data]
  );

  const toast = useToast();
  const [isPublishing, setPublishing] = useBoolean();

  const publishProfile = async () => {
    try {
      setPublishing.on();
      await IdentityClient.exportIdentities([pin]);
      toast({ status: 'success', description: 'The profile published successfully!' });
    } catch (err) {
      consola.error(err);
      toast({ status: 'error', description: 'There was a problem publishing the profile.' });
    } finally {
      setPublishing.off();
    }
  };

  return (
    <SectionContainer
      data-testid="ProfilePageProfileDataSources"
      w="full"
      header={
        <HStack justify="space-between">
          <HStack>
            <Text fontSize="md" lineHeight={5} fontWeight="bold">
              Data sources
            </Text>
            <Tooltip hasArrow label={<InfoTooltip />} placement="top-start">
              <span>
                <Icon boxSize={4} iconImage={IconImage.info} color="action" />
              </span>
            </Tooltip>
          </HStack>
          {showExport && (
            <Can I="update" an="ConsumerProfile">
              <Button
                data-testid="PublishProfileButton"
                size="sm"
                variant="ghost"
                onClick={publishProfile}
                leftIcon={<Icon boxSize={4} iconImage={IconImage.publish} />}
                isLoading={isPublishing}
              >
                Publish profile
              </Button>
            </Can>
          )}
        </HStack>
      }
    >
      <SimpleGrid columns={3} spacing="2">
        {sources &&
          sources.map((source) => (
            <Flex key={source.source.id} justifyContent="space-between" width="full">
              <HStack>
                <chakra.span>
                  <DataSourceIcon sourceSystem={source.source.sourceSystem} />
                  <HStack pos="inherit" pl="16px" mt="-10px">
                    <ActiveIndicator
                      active={source.identitySourceDetails.dataPresent}
                      showLabel={false}
                      width="3"
                    />
                  </HStack>
                </chakra.span>
                <VStack alignItems="start" spacing={0}>
                  <Text>{source.source.name}</Text>
                  {source.identitySourceDetails.lastUpdated && (
                    <Text fontSize="xs">
                      Last data ingestion was{' '}
                      {formatRelativeDateString(source.identitySourceDetails.lastUpdated)}
                    </Text>
                  )}
                </VStack>
              </HStack>
            </Flex>
          ))}
      </SimpleGrid>
    </SectionContainer>
  );
}

export default ProfilePageProfileDataSources;
