import { Box, Button, chakra, HStack, Spinner, Stack, Tag, Text } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { FaCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Cell, Pie, PieChart as RechartPieChart, Tooltip } from 'recharts';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import TimeSeriesLimitedFilter, {
  filterConfig,
  SelectedTimeSeriesFilter,
} from '../../../components/shared/visualizations/TimeSeriesLimitedFilter/TimeSeriesLimitedFilter';
import { useDataRepairMetrics } from '../../../lib/api-client/data-repair/DataRepairData';
import { useSourceColor } from './SourceColorContext';

interface PieChartProps {
  height: number;
  width: number;
}

function ToolTipContent({ payload }: any) {
  const data = payload?.[0]?.value;
  const color = payload?.[0]?.payload.fill;
  const sourceSystemName = payload?.[0]?.name;

  return (
    <Box bgColor="gray.100" p={2} shadow="md" boxShadow="md" borderRadius="6px">
      <HStack>
        <Tag variant="solid" bgColor={color} size="sm">
          {' '}
        </Tag>
        <chakra.span fontWeight="700">{data ?? 0} </chakra.span>
      </HStack>
      <Text w="200px" mt={1}>
        total data records repaired from {sourceSystemName} during this time window
      </Text>
    </Box>
  );
}

function PieChartVisualization({ width, height }: PieChartProps) {
  const { getColorForSource } = useSourceColor();
  const now = useMemo(() => new Date(), []);
  const [filter, setFilter] = useState<SelectedTimeSeriesFilter>({
    interval: 'day',
    config: filterConfig.day[0],
  });
  const { data: dataRepairMetricsData, loading: dataRepairMetricsLoading } = useDataRepairMetrics(
    filter.config.getStartDate(now)
  );

  const onFilterChanged = (selectedFilter: SelectedTimeSeriesFilter) => {
    setFilter(selectedFilter);
  };
  const ChakraFaCircle = chakra(FaCircle);

  const { sources } = useMemo(() => {
    if (!dataRepairMetricsData || !dataRepairMetricsData.sources) {
      return { sources: [] };
    }

    const orderedSources = dataRepairMetricsData.sources
      .sort((a, b) => b.metrics.totalRepairs - a.metrics.totalRepairs)
      .map((s) => ({
        id: s.source.id,
        name: s.source.name,
        value: s.metrics.totalRepairs,
        color: getColorForSource(s.source.id),
      }));

    return {
      sources: orderedSources,
    };
  }, [dataRepairMetricsData, getColorForSource]);

  if (dataRepairMetricsLoading) {
    return (
      <Spinner
        mt="140px"
        ml="160px"
        thickness="4px"
        size="xl"
        color="action"
        emptyColor="gray.200"
        speed="0.65s"
      />
    );
  }

  return (
    <SectionContainer
      variant="box"
      title="Data repair"
      titleSubheader={<TimeSeriesLimitedFilter onFilterChanged={onFilterChanged} filter={filter} />}
      display="flex"
      flexDirection="column"
      minH="0"
    >
      <HStack spacing={6} h="full">
        <Stack h="full" justify="space-between">
          <Stack>
            <HStack spacing={1}>
              <chakra.span>
                <Icon iconImage={IconImage.automation} boxSize={4} />
              </chakra.span>
              <chakra.span fontWeight="semibold" fontSize="sm" lineHeight={5} noOfLines={1}>
                {dataRepairMetricsData && dataRepairMetricsData.metrics.totalRepairs}
              </chakra.span>
              <chakra.span fontSize="sm" lineHeight={5} noOfLines={1}>
                total across all data sources
              </chakra.span>
            </HStack>
            {sources.map((s) => (
              <HStack key={`data-records-list-${s.id}`} spacing={1}>
                <Box>
                  <ChakraFaCircle boxSize={3} color={s.color} />
                </Box>
                <chakra.span fontSize="sm" fontWeight="semibold" lineHeight={5} noOfLines={1}>
                  {s.value}
                </chakra.span>
                <chakra.span fontSize="sm" lineHeight={5} noOfLines={1}>
                  {s.name}
                </chakra.span>
              </HStack>
            ))}
          </Stack>
          <Button w="200px" variant="outline" as={Link} to="/sources/repair-automation">
            See all repair automations
          </Button>
        </Stack>
        <Box mt={10}>
          <RechartPieChart width={width} height={height}>
            <Pie dataKey="value" data={sources} innerRadius={0} outerRadius={70} paddingAngle={1}>
              {sources.map((entry, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Cell key={`cell-${index}`} fill={entry!.color} />
              ))}
            </Pie>
            <Tooltip content={<ToolTipContent interval={filter.interval} />} />
          </RechartPieChart>
        </Box>
      </HStack>
    </SectionContainer>
  );
}
export default PieChartVisualization;
