import { HStack, Stack, Tag, VStack, chakra, Text, Box } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';
import SectionContainer from '../../../../../components/core/SectionContainer/SectionContainer';
import TimeSeriesFilter, {
  SelectedTimeSeriesFilter,
  filterConfig,
} from '../../../../../components/shared/visualizations/TimeSeriesFilter/TimeSeriesFilter';
import { useDataRepairTimeSeriesMetrics } from '../../../../../lib/api-client/data-repair/DataRepairData';
import { formatDateString, getCurrentTimezone } from '../../../../../lib/utils/date-time-utils';
import { formatNumber } from '../../../../../lib/utils/number-utils';
import { mapTimeSeriesResponse } from '../../../../../lib/utils/timeseries-utils';

function ToolTipContent({ payload, interval, isLastInterval }: any) {
  const isTime = interval === 'hour';

  const metric = payload?.[0]?.payload?.amt?.metric?.totalRepairs;

  const startDate = metric?.startDate !== undefined && formatDateString(metric?.startDate, 'h:mm');

  const endDate =
    metric?.endDate !== undefined && formatDateString(metric?.endDate, "h:mm a 'on' M/dd/yyyy");

  const amt = payload?.[0]?.payload?.amt?.metric?.totalRepairs?.value ?? 0;
  const pv = payload?.[0]?.payload?.pv?.metric?.totalRepairs?.value ?? 0;
  const uv = payload?.[0]?.payload?.uv?.metric?.totalRepairs?.value ?? 0;
  const totalRepairMetrics = amt + pv + uv;

  return (
    <Box bgColor="gray.50" p={2} shadow="md" boxShadow="md" borderRadius="6px">
      <Text fontSize="md" fontWeight="bold">
        {formatNumber(totalRepairMetrics)} total data repairs
      </Text>
      {isTime ? (
        <Text>
          across all sources systems between {startDate} - {endDate}
        </Text>
      ) : (
        <>
          <Text>across all sources systems on</Text>
          <Text>
            {metric?.startDate !== undefined &&
              formatDateString(metric?.startDate ?? '', 'M/dd/yyyy')}
          </Text>
        </>
      )}
      {isLastInterval && (
        <Text mt="4">
          Note that this time window is in progress and does not yet represent a complete data point
        </Text>
      )}
      <VStack alignItems="start" mt="2">
        <HStack>
          <Tag variant="solid" bgColor="#8EE9FF" size="sm">
            {' '}
          </Tag>
          <chakra.span>{amt ?? 0} </chakra.span>
        </HStack>
        <HStack>
          <Tag variant="solid" bgColor="#007FA7" size="sm">
            {' '}
          </Tag>
          <chakra.span>{pv ?? 0}</chakra.span>
        </HStack>
        <HStack>
          <Tag variant="solid" bgColor="#092747" size="sm">
            {' '}
          </Tag>
          <chakra.span>{uv ?? 0} </chakra.span>
        </HStack>
      </VStack>
    </Box>
  );
}

export default function DataRepairAllAutomationChart() {
  const now = useMemo(() => new Date(), []);
  const [filter, setFilter] = useState<SelectedTimeSeriesFilter>({
    interval: 'day',
    config: filterConfig.day[0],
  });
  const { data } = useDataRepairTimeSeriesMetrics(
    'nameIsRepeated',
    filter.config.getStartDate(now),
    now,
    getCurrentTimezone(),
    filter.interval,
    'count:totalRepairs'
  );

  const { data: data1 } = useDataRepairTimeSeriesMetrics(
    'nameFromEmail',
    filter.config.getStartDate(now),
    now,
    getCurrentTimezone(),
    filter.interval,
    'count:totalRepairs'
  );

  const { data: data2 } = useDataRepairTimeSeriesMetrics(
    'emailDomainMisspellings',
    filter.config.getStartDate(now),
    now,
    getCurrentTimezone(),
    filter.interval,
    'count:totalRepairs'
  );
  const timeSeriesData = data
    ? mapTimeSeriesResponse(data, ['count:totalRepairs'], filter.interval)
    : undefined;

  const nameFromEmailData = data1
    ? mapTimeSeriesResponse(data1, ['count:totalRepairs'], filter.interval)
    : undefined;

  const emailDomainMisspellingsData = data2
    ? mapTimeSeriesResponse(data2, ['count:totalRepairs'], filter.interval)
    : undefined;

  const onFilterChanged = (selectedFilter: SelectedTimeSeriesFilter) => {
    setFilter(selectedFilter);
  };
  const timeSeriesDataObject = timeSeriesData?.data.map((item) => ({
    uv: item,
  }));
  const nameFromEmailDataObject = nameFromEmailData?.data.map((item) => ({
    pv: item,
  }));

  const emailDomainMisspellingsDataObject = emailDomainMisspellingsData?.data.map((item) => ({
    amt: item,
  }));
  const arr =
    timeSeriesDataObject &&
    nameFromEmailDataObject &&
    emailDomainMisspellingsDataObject &&
    timeSeriesDataObject?.map((obj: any, index: any) => ({
      ...nameFromEmailDataObject[index],
      ...obj,
      ...emailDomainMisspellingsDataObject[index],
    }));

  return (
    <HStack alignItems="start" display="flex" justifyContent="space-between">
      <Stack>
        <TimeSeriesFilter onFilterChanged={onFilterChanged} />
        <BarChart
          width={700}
          height={350}
          data={arr}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="pv.label" />
          <YAxis />
          <Tooltip content={<ToolTipContent interval={filter.interval} />} />
          <Bar
            dataKey="uv.metric.totalRepairs.value"
            stackId="a"
            fill="#092747"
            radius={[4, 4, 0, 0]}
          />
          <Bar
            dataKey="pv.metric.totalRepairs.value"
            stackId="a"
            fill="#007FA7"
            radius={[4, 4, 0, 0]}
          />
          <Bar
            dataKey="amt.metric.totalRepairs.value"
            stackId="a"
            fill="#8EE9FF"
            radius={[4, 4, 0, 0]}
          />
        </BarChart>
      </Stack>
      <SectionContainer variant="box" w="344px" h="144px" p={3} display="flex">
        <VStack alignItems="start" spacing={2}>
          <Text>Key</Text>
          <HStack>
            <Tag variant="solid" bgColor="#8EE9FF" size="sm">
              {' '}
            </Tag>
            <chakra.span>Repair email domain </chakra.span>
          </HStack>
          <HStack>
            <Tag variant="solid" bgColor="action" size="sm">
              {' '}
            </Tag>
            <chakra.span>Repair name using email</chakra.span>
          </HStack>
          <HStack>
            <Tag variant="solid" bgColor="#092747" size="sm">
              {' '}
            </Tag>
            <chakra.span>Repair name repetition </chakra.span>
          </HStack>
        </VStack>
      </SectionContainer>
    </HStack>
  );
}
