import { Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import { ISODateString } from '../../../../lib/model/common';
import { formatDateString } from '../../../../lib/utils/date-time-utils';

interface ConnectedSinceTagProps {
  connectedSince: ISODateString;
}

export default function ConnectedSinceTag({ connectedSince }: ConnectedSinceTagProps) {
  return (
    <Tag variant="rounded">
      <TagLeftIcon boxSize="4" as={Icon} iconImage={IconImage.history} />
      <TagLabel>Connected since {formatDateString(connectedSince, 'LLL dd, yyyy')}</TagLabel>
    </Tag>
  );
}
