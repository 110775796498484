import { Box, Button, HStack, Stack, useDisclosure } from '@chakra-ui/react';
import {
  ColumnDef,
  getCoreRowModel,
  PaginationState,
  RowSelectionState,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { ReactNode, useMemo, useState } from 'react';
import { Page } from '../../../../lib/model/common/Page';
import { IconImage } from '../../../core/Icon/IconConfig';
import DataExplorerColumnDrawer from './DataExplorerColumnDrawer';
import { DataExplorerProvider } from './DataExplorerContext';
import DataExplorerFilterDescriptions from './DataExplorerFilterDescriptions';
import DataExplorerTable from './DataExplorerTable';
import DataExplorerTableHeader from './DataExplorerTableHeader';

type Pagination = {
  pageCount?: number;
  totalItems?: number;
  nextToken?: string;
} & PaginationState;

type FetchParams = {
  pagination: PaginationState & { nextToken?: string };
  sorting: SortingState;
};

interface DataExplorerProps<D> {
  data?: Page<D>;
  columns: ColumnDef<D>[];
  onRowSelected?: (props: { index: number; data: D }) => void;
  onRowSelectedDataDetails?: (props: { index: number; data: D }) => void;

  onExport?: () => void;
  onColumnVisibilityChange?: (visibility: VisibilityState) => void;
  filterDescriptions?: string[];
  pageUrl?: string;
  groupColumnId?: string;
  defaultPagination?: Pagination;
  defaultColumnVisibility?: VisibilityState;
  defaultSortOrder?: SortingState;
  defaultDrawerIsOpen?: boolean;
  onFetcherParamChange?: (params: FetchParams) => void;
  renderFilterDrawer?: () => JSX.Element;
  hideEditMode?: boolean;
  hideColumn?: boolean;
  hideFilters?: boolean;
  icon?: {
    label: string;
    icon?: IconImage;
  };
  readonlyPagination?: boolean;
  filterLabel?: string;
  footerActions?: ReactNode;
  children?: ReactNode;
}

export default function DataExplorer<D>({
  data,
  columns,
  pageUrl,
  groupColumnId,
  onRowSelected,
  onRowSelectedDataDetails,
  onExport,
  onColumnVisibilityChange,
  filterDescriptions = [],
  defaultColumnVisibility = {},
  defaultPagination = { pageIndex: 0, pageSize: 100 },
  defaultSortOrder = [],
  defaultDrawerIsOpen = false,
  onFetcherParamChange,
  renderFilterDrawer,
  filterLabel,
  hideEditMode = false,
  hideFilters = false,
  hideColumn = false,
  readonlyPagination = false,
  icon,
  footerActions,
  children,
}: DataExplorerProps<D>) {
  const {
    isOpen: isFilterDrawerOpen,
    onOpen: onFilterDrawerOpen,
    onClose: onFilterDrawerClose,
  } = useDisclosure({ defaultIsOpen: defaultDrawerIsOpen });

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const [fetchParams, setFetchParams] = useState<FetchParams>({
    pagination: defaultPagination,
    sorting: defaultSortOrder,
  });

  const [columnVisibility, setColumnVisibility] =
    useState<VisibilityState>(defaultColumnVisibility);
  const columnVisibilityMemo = useMemo(() => ({ ...columnVisibility }), [columnVisibility]);

  const defaultData = useMemo<D[]>(() => [], []);

  const table = useReactTable<D>({
    data: data?.content ?? defaultData,
    columns,
    state: {
      columnVisibility: columnVisibilityMemo,
      pagination: fetchParams.pagination,
      sorting: fetchParams.sorting,
      rowSelection,
    },
    manualPagination: true,
    onPaginationChange: (updaterOrValue: any) => {
      const params = { ...fetchParams, pagination: updaterOrValue(fetchParams.pagination) };
      setFetchParams(params);
      if (onFetcherParamChange) {
        onFetcherParamChange(params);
      }
    },
    pageCount: data?.totalPages ?? -1,
    manualSorting: true,
    onSortingChange: (updaterOrValue: any) => {
      const params = { ...fetchParams, sorting: updaterOrValue(fetchParams.sorting) };
      setFetchParams(params);
      if (onFetcherParamChange) {
        onFetcherParamChange(params);
      }
    },
    sortDescFirst: false,
    enableSortingRemoval: false,
    columnResizeMode: 'onChange',
    onColumnVisibilityChange: (updaterOrValue: any) => {
      setColumnVisibility(updaterOrValue);
      if (onColumnVisibilityChange) {
        if (updaterOrValue instanceof Function) {
          onColumnVisibilityChange(updaterOrValue(columnVisibilityMemo));
        } else {
          onColumnVisibilityChange(updaterOrValue);
        }
      }
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      shareUrl: pageUrl,
      totalItems: data?.totalElements,
      readonlyPagination,
    },
  });

  return (
    <DataExplorerProvider
      filterDrawerDisclosure={{
        onClose: onFilterDrawerClose,
        onOpen: onFilterDrawerOpen,
        isOpen: isFilterDrawerOpen,
      }}
      table={table}
    >
      <Box>
        <Stack w="full" alignItems="start" direction="row" justify="space-between">
          <DataExplorerFilterDescriptions
            filterLabel={filterLabel}
            descriptions={filterDescriptions}
          />
          <HStack>
            {!hideFilters && (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {renderFilterDrawer ? (
                  renderFilterDrawer()
                ) : (
                  <Button onClick={onFilterDrawerOpen}>{filterLabel ?? 'Filters'}</Button>
                )}
              </>
            )}

            {!hideColumn && <DataExplorerColumnDrawer />}
          </HStack>
        </Stack>
      </Box>
      <DataExplorerTableHeader hideEditMode={hideEditMode} icon={icon} />
      <DataExplorerTable
        groupColumnId={groupColumnId}
        onRowSelected={onRowSelected}
        onRowSelectedDataDetails={onRowSelectedDataDetails}
        onExport={onExport}
        footerActions={footerActions}
      />
      {children}
    </DataExplorerProvider>
  );
}
