import {
  Box,
  BoxProps,
  Button,
  chakra,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { ReactComponent as Logo } from '../../../../../assets/harpinAI-logo-graphic-only.svg';
import Icon from '../../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../../../components/core/SectionContainer/SectionContainer';
import { DataSourceConnectionTypes } from '../../../../../lib/api-client/sources/model/DataSourceConnectionType';
import { getSyncDirectionIcon } from '../../DataMappingPage.utils';

interface MappingTypeExplanationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function MappingTypeExplanationModal({ isOpen, onClose }: MappingTypeExplanationModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="bold" fontSize="xl">
          Source attribute types
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody fontSize="md">
          <Text mb={2}>
            <chakra.span fontWeight="bold">Mapped</chakra.span> is used to map dynamic field values
            from a source system attribute to a harpin AI data field.
          </Text>
          <Text mb={2}>
            <chakra.span fontWeight="bold">Static</chakra.span> is used to populate a harpin AI data
            field with the same static field value across all records ingested from this source
            system.
          </Text>
          <Text>
            <chakra.span fontWeight="bold">Generated ID</chakra.span> is used to auto-generate
            unique identifiers for records that aren’t assigned them by the source system.
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="start">
          <Button variant="outline" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

const headerStyles: BoxProps = {
  textTransform: 'uppercase',
  borderRadius: 'md',
  background: 'gray.200',
  padding: 2,
  fontSize: 'xs',
  fontWeight: 'bold',
  lineHeight: 4,
  width: '340px',
  color: 'gray.600',
  flexShrink: 0,
};

interface AttributeRowHeaderProps {
  syncDirection: DataSourceConnectionTypes;
  layout: 'INBOUND' | 'OUTBOUND';
  showSyncHelp?: boolean;
}

export default function AttributeRowHeader({
  syncDirection,
  layout,
  showSyncHelp = true,
}: AttributeRowHeaderProps) {
  let icon = IconImage.arrowForward;
  if (syncDirection === 'BOTH' && layout === 'INBOUND') {
    icon = IconImage.dataTransfer;
  }

  if (layout === 'OUTBOUND') {
    icon = IconImage.arrowBack;
  }

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Stack spacing={4}>
        {showSyncHelp && (
          <SectionContainer variant="box">
            <HStack>
              <Icon iconImage={IconImage.database} />
              <Icon iconImage={icon} />
              <Logo />
              <chakra.span fontSize="sm">
                {syncDirection === 'IN' &&
                  'These mappings are one-way, ingesting data from the source system to harpin AI.'}
                {syncDirection === 'OUT' &&
                  'These mappings are one-way, publishing data from harpin AI back to the source system.'}
                {syncDirection === 'BOTH' &&
                  'These mappings are two-way, ingesting data to harpin AI and publishing it back to the source system.'}
              </chakra.span>
            </HStack>
          </SectionContainer>
        )}
        <HStack spacing={6}>
          {layout === 'INBOUND' && (
            <HStack {...headerStyles} justify="space-between" width="152px">
              <chakra.span>Attribute type</chakra.span>
              <Icon
                iconImage={IconImage.info}
                color="action"
                boxSize={4}
                _hover={{ cursor: 'pointer' }}
                onClick={onOpen}
              />
            </HStack>
          )}
          <Box {...headerStyles}>Attribute source or value</Box>
          <Icon boxSize={6} iconImage={getSyncDirectionIcon(syncDirection)} />
          <Box {...headerStyles}>harpin ai data field</Box>
        </HStack>
      </Stack>
      <Divider pt={2} />
      <MappingTypeExplanationModal onClose={onClose} isOpen={isOpen} />
    </>
  );
}
