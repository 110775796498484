import { Box, chakra, HStack, Tooltip } from '@chakra-ui/react';
import { CellContext } from '@tanstack/react-table';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import { useDataExplorer } from '../../../../components/shared/data-explorer/DataExplorer/DataExplorerContext';
import { SourceIdentityRecordSearchResponse } from '../../../../lib/api-client/identity/model/SourceIdentityRecordSearchResponse';
import { IdentityDataKeys } from '../../../../lib/api-client/identity/model/UpdateIdentityRecord';

interface IdentityRecordAddressCellProps {
  cellContext?: CellContext<SourceIdentityRecordSearchResponse, IdentityDataKeys | undefined>;
  streetAddress: string;
  city: string;
  governingDistrict: string;
  postalCode: string;
  countryCode: string;
}
export default function IdentityRecordAddressCell({
  cellContext,
  streetAddress,
  city,
  governingDistrict,
  postalCode,
  countryCode,
}: IdentityRecordAddressCellProps) {
  const value = cellContext?.getValue();
  const isModified = value && value.modified != null;
  const { editMode } = useDataExplorer();

  return (
    <HStack spacing={1} justify="space-between">
      <chakra.span overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        {streetAddress} {city} {governingDistrict} {postalCode} {countryCode}
      </chakra.span>
      {isModified && editMode && (
        <Tooltip hasArrow placement="top" label="This data field value has been edited.">
          <Box>
            <Icon color="gray.800" iconImage={IconImage.edit} />
          </Box>
        </Tooltip>
      )}
    </HStack>
  );
}
