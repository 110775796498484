import {
  Drawer as ChakraDrawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  DrawerProps,
} from '@chakra-ui/react';

export default function Drawer({ children, ...props }: DrawerProps) {
  return (
    <ChakraDrawer size="md" placement="right" {...props}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton
          size="lg"
          right="unset"
          top="40px"
          left="-40px"
          fontSize="xs"
          color="white"
          bgColor="action"
          _hover={{ backgroundColor: 'actionDark' }}
          borderRightRadius={0}
        />
        {children}
      </DrawerContent>
    </ChakraDrawer>
  );
}
