import * as Sentry from '@sentry/react';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import config from '../../config';
import { setAuthorizationToken } from '../api-client/ApiClient';
import AuthenticationClient from '../api-client/authentication/AuthenticationClient';
import { AuthenticationConfiguration } from '../api-client/authentication/model/AuthenticationConfiguration';
import { NewPasswordChallengeRequest } from '../api-client/authentication/model/NewPasswordChallengeRequest';
import { SignInRequest } from '../api-client/authentication/model/SignInRequest';
import {
  ResetSignInResult,
  SignInResult,
  TotpSignInResult,
} from '../api-client/authentication/model/SignInResult';
import { isTypeUserGroup } from '../api-client/authentication/model/UserGroup';
import TenantClient from '../api-client/tenant/TenantClient';
import Mixpanel from '../mixpanel/Mixpanel';
import { stripEmailAlias } from '../utils/format-utils';
import AuthorizationService from './AuthorizationService';
import LocalStorageService from './LocalStorageService';

interface SessionState {
  expiration: number;
  emailAddress: string;
}

const sessionState: SessionState = {
  expiration: 0,
  emailAddress: '',
};

const onAuthenticationSuccess = (session: CognitoUserSession): SignInResult => {
  const { payload } = session.getIdToken();
  const tokenExpirationMillis = payload.exp * 1000;

  Sentry.setContext('tenant', {
    name: payload.tenant_id,
  });

  if (payload.sub) {
    Sentry.setUser({ id: payload.sub });
    Mixpanel.identify(payload.sub);
    Mixpanel.register({ tenant: payload.tentant_id });
    Mixpanel.people.set({
      $email: payload.email,
      tenant: payload.tentant_id,
    });
  }
  Mixpanel.track('Successful login');

  sessionState.emailAddress = payload.email;
  sessionState.expiration = tokenExpirationMillis;
  setAuthorizationToken(session.getIdToken().getJwtToken());

  let groups = (payload['cognito:groups'] || []).filter(isTypeUserGroup);
  if (groups.length === 0) {
    groups = AuthorizationService.getDefaultGroups();
  }

  return {
    result: 'success',
    displayName: payload.given_name || payload.name || payload.email,
    emailAddress: payload.email,
    token: session.getIdToken().getJwtToken(),
    tokenExpiration: tokenExpirationMillis,
    tenantId: payload.tenant_id,
    groups,
  };
};

function getAuthClientFromStorage(): { userPoolId: string; clientId: string } | null {
  const authClient = LocalStorageService.getItem('seviin.authclient');
  if (authClient) {
    const authClientObj = JSON.parse(authClient);
    return authClientObj
      ? { userPoolId: authClientObj.id, clientId: authClientObj.clientId }
      : null;
  }

  return null;
}

function clearAuthClient(): void {
  LocalStorageService.removeItem('seviin.authclient');
}

function normalizeEmailForCognito(email: string): string {
  if (email.includes('@seviin.ai') || email.includes('@codescience.com')) {
    return stripEmailAlias(email);
  }

  return email;
}

const AuthenticationService = {
  getAuthenticationConfig(emailAddress: string): Promise<AuthenticationConfiguration | null> {
    return TenantClient.getTenantInfo(emailAddress).then(({ content: tenantInfos }) => {
      if (!tenantInfos || tenantInfos.length < 1) {
        return null;
      }

      let tenant = tenantInfos[0];
      // seVIIn.ai is mapped to a lot of tenants. If multiple tenants come back try to find
      // our default tenant.
      if (emailAddress.includes('seviin.ai')) {
        tenant = tenantInfos.find((t) => t.tenantId === config.get().SEVIIN_TENANT_ID) || tenant;
      }

      return {
        useNative: true,
        userPool: {
          id: tenant.userPoolId,
          clientId: tenant.authClientId,
        },
      };
    });
  },

  handleNewPasswordChallenge(request: NewPasswordChallengeRequest): Promise<SignInResult> {
    return AuthenticationClient.handleNewPasswordChallenge(request).then(onAuthenticationSuccess);
  },

  signIn(request: SignInRequest): Promise<SignInResult> {
    const signInRequest = request;
    signInRequest.email = normalizeEmailForCognito(signInRequest.email);

    return AuthenticationClient.signIn(signInRequest)
      .then((response) => {
        if (response === 'newPasswordRequired') {
          return { result: 'reset' } as ResetSignInResult;
        }

        if (response === 'totpRequired') {
          return { result: 'totp' } as TotpSignInResult;
        }
        return onAuthenticationSuccess(response);
      })
      .then((result) => {
        LocalStorageService.setItem(
          'seviin.authclient',
          JSON.stringify({ id: request.userPool.id, clientId: request.userPool.clientId })
        );
        return result;
      });
  },

  forgotPassword(userPoolId: string, clientId: string, email: string): Promise<any> {
    return AuthenticationClient.forgotPassword(
      userPoolId,
      clientId,
      normalizeEmailForCognito(email)
    );
  },

  confirmNewPassword(
    userPoolId: string,
    clientId: string,
    email: string,
    verificationCode: string,
    newPassword: string
  ): Promise<any> {
    return AuthenticationClient.confirmNewPassword(
      userPoolId,
      clientId,
      email,
      verificationCode,
      newPassword
    );
  },

  verifySession(): Promise<SignInResult> {
    const authClient = getAuthClientFromStorage();
    if (authClient) {
      return AuthenticationClient.verifySession(authClient.userPoolId, authClient.clientId)
        .then(onAuthenticationSuccess)
        .catch((err) => {
          clearAuthClient();
          throw err;
        });
    }

    return Promise.reject();
  },

  isSessionExpired(): boolean {
    return sessionState.expiration < Date.now();
  },

  signOut(): Promise<void> {
    const authClient = getAuthClientFromStorage();

    let signOut;
    if (authClient) {
      signOut = AuthenticationClient.signOut();
    } else {
      signOut = Promise.resolve();
    }

    return signOut.then(() => {
      clearAuthClient();
    });
  },

  associateSoftwareToken(): Promise<string> {
    return AuthenticationClient.associateSoftwareToken();
  },

  verifySoftwareToken(totp: string): Promise<void> {
    return AuthenticationClient.verifySoftwareToken(totp);
  },

  enableSoftwareTokenMfa(): Promise<void> {
    return AuthenticationClient.enableSoftwareTokenMfa(true);
  },

  disableSoftwareTokenMfa(): Promise<void> {
    return AuthenticationClient.enableSoftwareTokenMfa(false).then(() => {});
  },

  isSoftwareTokenMfaEnabled(): Promise<boolean> {
    return AuthenticationClient.getUserData({ bypassCache: true }).then(
      (userData) =>
        !!userData?.UserMFASettingList && userData.UserMFASettingList.includes('SOFTWARE_TOKEN_MFA')
    );
  },

  sendMFACode(code: string): Promise<SignInResult> {
    return AuthenticationClient.sendMFACode(code).then(onAuthenticationSuccess);
  },

  changePassword(oldPassword: string, newPassword: string): Promise<void> {
    return AuthenticationClient.changePassword(oldPassword, newPassword);
  },

  updateProfile(firstName: string, lastName: string): Promise<any> {
    return AuthenticationClient.updateProfile(firstName, lastName);
  },

  getUserDetails() {
    return AuthenticationClient.getUserDetails();
  },
};

export default AuthenticationService;
