import { Box, chakra, Flex, HStack, IconButton } from '@chakra-ui/react';
import { useEffect } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { formatBytes } from '../../../lib/utils/number-utils';
import Icon from '../Icon/Icon';
import { IconImage } from '../Icon/IconConfig';

const MAX_SIZE_5GB = 5 * 1024 * 1024 * 1024;

function getErrorMessage(fileRejections: FileRejection[] = []) {
  if (fileRejections.length < 1) {
    return null;
  }

  return fileRejections.reduce((prev, rejection) => {
    if (prev != null) {
      return prev;
    }

    return rejection.errors.reduce((error, fileError) => {
      if (error != null) {
        return error;
      }

      switch (fileError.code) {
        case 'file-too-large':
          return 'The file you selected exceeds the maximum allowed size of 5GB. Please select a smaller file.';
        case 'file-invalid-type':
          return 'This file is not a CSV file. Please select a different file.';
        default:
          return 'There is an unexpected problem with this file. Please select a different file.';
      }
    }, null as string | null);
  }, null as string | null);
}

interface FilePickerProps {
  onFilesAccepted: (files: File[]) => void;
}

export default function FilePicker({ onFilesAccepted }: FilePickerProps) {
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: MAX_SIZE_5GB,
    accept: {
      'text/plain': ['.csv'],
      'text/csv': ['.csv'],
      'application/gzip': ['.gz'],
    },
  });
  const isError = fileRejections.length > 0;
  const hasFile = acceptedFiles.length > 0;

  useEffect(() => {
    if (hasFile && !isError) {
      onFilesAccepted(acceptedFiles);
    }
  }, [hasFile, isError, acceptedFiles, onFilesAccepted]);

  const fileRejectionMessage = getErrorMessage(fileRejections);

  return (
    <Box>
      <Flex
        {...getRootProps()}
        _hover={{
          cursor: 'pointer',
          borderColor: 'action',
        }}
        w="full"
        minH={12}
        border="1px solid"
        borderRadius="md"
        borderColor={isError ? 'error' : 'gray.300'}
        backgroundColor="gray.50"
        alignItems="center"
        justify="center"
      >
        <input {...getInputProps()} />
        {hasFile ? (
          <HStack w="full" spacing={1} ps={2}>
            <Flex w="full" justify="center" minW={0}>
              <chakra.span whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                {`${acceptedFiles[0]?.name} (${formatBytes(acceptedFiles[0]?.size)})`}
              </chakra.span>
            </Flex>
            <IconButton
              boxSize="4"
              aria-label="Remove file"
              icon={<Icon iconImage={IconImage.delete} />}
              variant="ghost"
            />
          </HStack>
        ) : (
          <chakra.span>Drag and drop a file here or click to select one (5 GB max)</chakra.span>
        )}
      </Flex>
      {isError && <chakra.span color="error">{fileRejectionMessage}</chakra.span>}
    </Box>
  );
}
