import {
  TimeSeriesDataPoint,
  TimeSeriesQueryResponse,
} from '../api-client/source-metrics/model/TimeSeriesQueryResponse';
import {
  TimeSeriesAggregate,
  TimeSeriesIntervalName,
  TimeSeriesMultiValueDataPoint,
  TimeSeriesQueryMultiValueResponse,
} from '../model/common';
import {
  BasicTimeSeriesData,
  BasicTimeSeriesMultiValueData,
} from '../model/visualizations/BasicTimeSeriesData';
import {
  LabelWithMetric,
  LabelWithMultiValueMetric,
} from '../model/visualizations/LabelWithMetric';
import { formatDateString } from './date-time-utils';

const labelConfig: { [key: string]: (date: string) => string } = {
  minute: (dateStr: string) => formatDateString(dateStr, 'h:mm a'),
  hour: (dateStr: string) => formatDateString(dateStr, 'h:mm a'),
  day: (dateStr: string) => formatDateString(dateStr, 'M/d'),
  week: (dateStr: string) => formatDateString(dateStr, 'M/d'),
  month: (dateStr: string) => formatDateString(dateStr, 'LLL'),
  year: (dateStr: string) => formatDateString(dateStr, 'y'),
};

export function mapTimeSeriesMultiValueResponse<T = TimeSeriesMultiValueDataPoint>(
  response: TimeSeriesQueryMultiValueResponse,
  intervalName: TimeSeriesIntervalName,
  mapMetric?: (metric: TimeSeriesMultiValueDataPoint) => T
) {
  if (response.series.length < 1) {
    return {
      data: [],
    };
  }
  const config = labelConfig[intervalName] || labelConfig.day;
  const values: LabelWithMultiValueMetric<T>[] = [];

  const defaultMapMetric = (metric: TimeSeriesMultiValueDataPoint) => metric;

  const map = mapMetric || defaultMapMetric;

  for (let i = 0; i < response.series[0].dataPoints.length; i++) {
    const metric: Record<string, any> = {};

    for (let j = 0; j < response.series.length; j++) {
      metric[response.series[j].metric] = map(response.series[j].dataPoints[i]);
    }

    values.push({
      label: config(response.series[0].dataPoints[i].startDate),
      metric,
      isLastBucket: i === response.series[0].dataPoints.length - 1,
    });
  }

  const timeSeriesData: BasicTimeSeriesMultiValueData<T> = {
    data: values,
  };

  return timeSeriesData;
}

// eslint-disable-next-line import/prefer-default-export
export function mapTimeSeriesResponse<T>(
  response: TimeSeriesQueryResponse,
  m: T[],
  intervalName: TimeSeriesIntervalName,
  aggregateSummary?: TimeSeriesAggregate[]
) {
  if (response.series.length < 1) {
    return {
      data: [],
    };
  }

  const config = labelConfig[intervalName] || labelConfig.day;
  const values: LabelWithMetric[] = [];

  for (let i = 0; i < response.series[0].dataPoints.length; i++) {
    const metric: Record<string, TimeSeriesDataPoint> = {};

    for (let j = 0; j < response.series.length; j++) {
      metric[response.series[j].metric] = response.series[j].dataPoints[i];
    }

    values.push({
      label: config(response.series[0].dataPoints[i].startDate),
      metric,
    });
  }

  const timeSeriesData: BasicTimeSeriesData = {
    data: values,
  };

  if (aggregateSummary) {
    const referenceValue: any = {};
    for (let i = 0; i < response.series.length; i++) {
      const { summaryData } = response.series[i];
      if (summaryData) {
        for (let j = 0; j < summaryData.length; j++) {
          if (!referenceValue[response.series[i].metric]) {
            referenceValue[response.series[i].metric] = {};
          }
          referenceValue[response.series[i].metric][summaryData[j].aggregate] =
            summaryData[j].value;
        }
      }
    }
    timeSeriesData.referenceValue = referenceValue;
  }

  return timeSeriesData;
}
