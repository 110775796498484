import config from '../../config';

export function openTrayConfigWindow(
  url: string,
  onClose: () => void,
  onFinish: () => void,
  onError: (err: any) => void
) {
  const configWindow = window.open(url, '_blank', `width=500,height=500,scrollbars=no`);

  const onMessage = (e: { data?: { type?: string; err?: any } }) => {
    if (!e.data || !e.data.type) {
      return;
    }

    if (e.data.type === 'tray.configPopup.error') {
      onError(e.data.err);
      configWindow?.close();
    }

    if (e.data.type === 'tray.configPopup.cancel') {
      onClose();
      configWindow?.close();
    }

    if (e.data.type === 'tray.configPopup.finish') {
      onFinish();
      onClose();
      configWindow?.close();
    }
  };
  window.addEventListener('message', onMessage);

  const CHECK_TIMEOUT = 1000;
  const checkWindow = () => {
    if (configWindow?.closed) {
      window.removeEventListener('message', onMessage);
      onClose();
    } else {
      setTimeout(checkWindow, CHECK_TIMEOUT);
    }
  };
  checkWindow();

  return configWindow;
}

export async function openOAuthWindow(
  registrationId: string,
  sourceId: string,
  onFinish: () => void
) {
  const onMessage = (e?: { data?: { type: 'seviin.oauth.finish' } }) => {
    if (e?.data?.type === 'seviin.oauth.finish') {
      onFinish();
    }
  };
  window.addEventListener('message', onMessage);

  const configWindow = window.open(
    `${config.get().BASE_API_URL}/oauth2/authorization/${registrationId}?sourceId=${sourceId}`,
    '_blank',
    `width=500,height=500,scrollbars=no`
  );

  const CHECK_TIMEOUT = 1000;
  const checkWindow = () => {
    if (configWindow?.closed) {
      window.removeEventListener('message', onMessage);
    } else {
      setTimeout(checkWindow, CHECK_TIMEOUT);
    }
  };
  checkWindow();

  return configWindow;
}

export async function openMicrosoftDynamicsConsentWindow(
  microsoftTenantId: string,
  onFinish: () => void
) {
  const onMessage = (e?: { data?: { type: 'seviin.oauth.finish' } }) => {
    if (e?.data?.type === 'seviin.oauth.finish') {
      onFinish();
    }
  };
  window.addEventListener('message', onMessage);

  const configWindow = window.open(
    `https://login.microsoftonline.com/${microsoftTenantId}/adminconsent?client_id=0aabfeaf-5c2a-44f7-9fe8-52e9acca7d3b&redirect_uri=${window.location.origin}/oauth/success`,
    '_blank',
    `width=500,height=500,scrollbars=no`
  );

  const CHECK_TIMEOUT = 1000;
  const checkWindow = () => {
    if (configWindow?.closed) {
      window.removeEventListener('message', onMessage);
    } else {
      setTimeout(checkWindow, CHECK_TIMEOUT);
    }
  };
  checkWindow();

  return configWindow;
}
