import { Stack } from '@chakra-ui/react';
import React, { useState } from 'react';
import Alert from '../../../../components/core/Alert/Alert';
import { useCurrentDataSource } from '../../../sources/context/CurrentDataSourceContext';
import DataMappingSetupSection from '../DataMappingSetupSection';
import ExternalConfigurationSection from '../ExternalConfigurationSection';
import SetupConnectionGrid from '../SetupConnectionGrid';
import { useRemoteIngestion } from '../useRemoteIngestion';
import MicrosoftDynamicsApplicationAccessSection from './MicrosoftDynamicsApplicationAccessSection';
import MicrosoftDynamicsPublishPermissionsSection from './MicrosoftDynamicsPublishPermissionSection';

interface MicrosoftDynamicsSetupProps {
  onError: () => void;
}

export default function MicrosoftDynamicsSetup({ onError }: MicrosoftDynamicsSetupProps) {
  const [testAccessResult, setTestAccessResult] = useState<'success' | 'error' | null>(null);
  const { dataSource: source, refetchDataSource } = useCurrentDataSource();
  const isSyncBoth = source.syncDirection === 'BOTH';
  const dataMappingCompleteLabel = isSyncBoth ? 'Step 4: ' : 'Step 2: ';
  const isDisabled = source.configState === 'INCOMPLETE';
  useRemoteIngestion(source);

  let exportSetup = null;
  if (isSyncBoth) {
    exportSetup = (
      <>
        <MicrosoftDynamicsPublishPermissionsSection />
        <MicrosoftDynamicsApplicationAccessSection
          onComplete={async () => {
            setTestAccessResult('success');
            await refetchDataSource();
          }}
          onError={() => setTestAccessResult('error')}
        />
      </>
    );
  }

  return (
    <>
      <Stack mb={3} spacing={3}>
        {testAccessResult === 'success' && (
          <Alert
            status="success"
            description="Success! Access has been confirmed."
            closeable
            onClose={() => setTestAccessResult(null)}
          />
        )}
        {testAccessResult === 'error' && (
          <Alert
            status="error"
            description="We were unable to confirm access. Double check that the application user has been created and granted consent to access then try testing again."
            closeable
            onClose={() => setTestAccessResult(null)}
          />
        )}
      </Stack>
      <SetupConnectionGrid>
        <ExternalConfigurationSection
          completeLabel="Step 1: "
          source={source}
          onComplete={refetchDataSource}
          onError={onError}
        />
        {exportSetup}
        <DataMappingSetupSection completeLabel={dataMappingCompleteLabel} isDisabled={isDisabled} />
      </SetupConnectionGrid>
    </>
  );
}
