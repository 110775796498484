import {
  Box,
  Button,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FaCircle } from 'react-icons/fa';
import Icon from '../../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../../components/core/Icon/IconConfig';
import { DataSourceAttributeMapping } from '../../../../../lib/api-client/sources/model/DataSourceAttributeMapping';
import { ExternalDataTypeObjectAttribute } from '../../../../../lib/api-client/sources/model/ExternalDataModel';

const ChakraFaCircle = chakra(FaCircle);

interface Form {
  type: 'targetLookup';
  parameters: {
    entityType: string;
    lookupFieldName: string;
    mappedFieldName: string;
  };
}

interface AdvancedMappingModalProps {
  valueMappings?: DataSourceAttributeMapping['valueMappings'];
  externalDataModel: ExternalDataTypeObjectAttribute[];
  onSuccess: (valueMappings: DataSourceAttributeMapping['valueMappings']) => void;
}
export default function AdvancedMappingModal({
  valueMappings = [],
  externalDataModel,
  onSuccess,
}: AdvancedMappingModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Form>({
    defaultValues: {
      type: 'targetLookup' as const,
      parameters: {
        entityType: valueMappings?.[0]?.parameters?.entityType || '',
        lookupFieldName: valueMappings?.[0]?.parameters?.lookupFieldName || '',
        mappedFieldName: valueMappings?.[0]?.parameters?.mappedFieldName || '',
      },
    },
  });

  const resetOnOpen = () => {
    reset();
    onOpen();
  };

  const onSubmit = (data: Form) => {
    let value: DataSourceAttributeMapping['valueMappings'] = [];
    if (data.parameters.entityType !== '') {
      value = [data, ...(valueMappings ?? []).slice(1)];
    }
    onSuccess(value);
  };

  return (
    <Box>
      <Tooltip hasArrow label="Advanced mapping" placement="top">
        <Flex position="relative">
          <IconButton
            aria-label="Advanced mapping"
            onClick={resetOnOpen}
            variant="outline"
            icon={<Icon iconImage={IconImage.edit} />}
          />
          {valueMappings && valueMappings.length > 0 && (
            <ChakraFaCircle size={12} color="action" position="absolute" top="-4px" left="-4px" />
          )}
        </Flex>
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <form>
            <ModalHeader fontWeight="bold">
              <Text fontSize="sm" color="gray.600" fontWeight="semibold">
                Advanced mapping
              </Text>
              Target lookup for transaction attributes
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text pb={4}>
                Use this advanced field level mapping to lookup the identifier for a transaction
                field&apos;s value in the source system it&apos;s being published to.
              </Text>
              <Stack w="320px" spacing={4}>
                <FormControl isInvalid={!!errors.parameters?.entityType}>
                  <FormLabel>Lookup object type</FormLabel>
                  <Select {...register('parameters.entityType')} placeholder="Select one">
                    {externalDataModel.map((model) => (
                      <option key={model.name} value={model.name}>
                        {model.label}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{errors.parameters?.entityType?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.parameters?.lookupFieldName}>
                  <FormLabel>Field containing value in transaction</FormLabel>
                  <InputGroup>
                    <Input {...register('parameters.lookupFieldName')} />
                    <InputRightElement>
                      <Tooltip
                        hasArrow
                        placement="right"
                        label="This is the name of the data field in the lookup object type selected above that contains the value on the transaction object ingested by harpin AI."
                        shouldWrapChildren
                      >
                        <Icon boxSize={4} iconImage={IconImage.info} color="action" />
                      </Tooltip>
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{errors.parameters?.lookupFieldName?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.parameters?.mappedFieldName}>
                  <FormLabel>Identifier field for lookup object</FormLabel>
                  <InputGroup>
                    <Input {...register('parameters.mappedFieldName')} />
                    <InputRightElement>
                      <Tooltip
                        hasArrow
                        placement="right"
                        label="This is the name of the data field in the lookup object type selected above that contains the ID for the lookup object."
                        shouldWrapChildren
                      >
                        <Icon boxSize={4} iconImage={IconImage.info} color="action" />
                      </Tooltip>
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{errors.parameters?.mappedFieldName?.message}</FormErrorMessage>
                </FormControl>
              </Stack>
            </ModalBody>
            <ModalFooter justifyContent="start">
              <Button type="button" onClick={() => handleSubmit(onSubmit)()}>
                Save mapping
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
}
