import { SourceIdentityRecordCategory } from './SourceIdentityRecordCategory';

export interface SourceIdentityRecordSearch {
  sourceId: string;
  recordScore?: RecordSearchOperation;
  recordCompleteness?: RecordSearchOperation;
  duplicates?: boolean;
  modifications?: boolean;
  pinned?: boolean;
  attributeScores?: AttributeSearchOperation[];
  attributeCompleteness?: AttributeSearchOperation[];
  recordCategories?: SourceIdentityRecordCategory[];
  optInAttributes?: SourceIdentityRecordOptInAttribute[];
}

export interface SourceIdentityRecordOptInAttribute {
  attribute: 'optInEmail' | 'optInText' | 'optInPhone';
  value?: boolean;
}

export enum SearchOperator {
  Equal = 'equal',
  EqualOrNull = 'equalOrNull',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  Between = 'between',
}

interface RecordSearchOperation {
  operator: SearchOperator;
  values: number[];
}

export enum IdentityRecordSearchAttribute {
  FirstName = 'firstName',
  LastName = 'lastName',
  Phone = 'phone',
  MobilePhone = 'mobilePhone',
  HomePhone = 'homePhone',
  WorkPhone = 'workPhone',
  EmailAddress = 'emailAddress',
  DateOfBirth = 'dateOfBirth',
  Address = 'address',
}

export interface AttributeSearchOperation {
  attribute: IdentityRecordSearchAttribute;
  operator: SearchOperator;
  values: number[];
}
