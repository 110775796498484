import {
  Box,
  Button,
  chakra,
  Flex,
  HStack,
  Switch,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useSize } from '@chakra-ui/react-use-size';
import { ReactNode, useEffect, useRef } from 'react';
import { Link, To } from 'react-router-dom';
import { Can } from '../../../context/AuthorizationContext';
import { usePage } from '../../../context/PageContext';
import { usePageHistory } from '../../../context/PageHistoryContext';
import { usePreviewPage } from '../../../context/PreviewContext';
import { useSystemIdentitySourceMetrics } from '../../../lib/api-client/source-metrics/IdentitySourceMetricsClient';
import { useGetSystemMetrics } from '../../../lib/api-client/source-metrics/SourceMetricsClient';
import { useSourceIssues } from '../../../lib/api-client/sources/SourceIssuesData';
import { SourceIssuePriority } from '../../../lib/api-client/sources/model/SourceIssuePriority';
import { formatRelativeDateStringStrict } from '../../../lib/utils/date-time-utils';
import { formatCompactNumber } from '../../../lib/utils/number-utils';
import BatchResolutionHeaderAlert from '../../shared/alerts/BatchResolutionHeaderAlert/BatchResolutionHeaderAlert';
import SearchModal from '../../shared/modals/SearchModal/SearchModal';
import Icon from '../Icon/Icon';
import { IconImage } from '../Icon/IconConfig';

interface HeaderStatProps {
  label: string;
  value: number | string;
  to?: string;
}

function HeaderStat({ label, value, to = '/' }: HeaderStatProps) {
  return (
    <Link to={to}>
      <VStack
        alignItems="start"
        spacing={-1}
        bgColor="gray.50"
        px="2"
        py="1"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="8"
        _hover={{ borderColor: 'action' }}
      >
        <chakra.span fontSize="md" fontWeight="bold">
          {value}
        </chakra.span>
        <HStack _hover={{ borderColor: 'red' }}>
          <chakra.span fontSize="xs" whiteSpace="nowrap">
            {label}
          </chakra.span>
        </HStack>
      </VStack>
    </Link>
  );
}

interface HeaderStatDataRecordsProps {
  label: string;
  value: string;
  recordUpdated?: string;
  text: string;
}

function HeaderStatDataRecords({ label, value, recordUpdated, text }: HeaderStatDataRecordsProps) {
  return (
    <VStack
      alignItems="start"
      spacing={-1}
      bgColor="gray.50"
      px="2"
      py="1"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="8"
    >
      <HStack spacing={2}>
        <HStack>
          <VStack alignItems="start" spacing={-1} w="max-content">
            <chakra.span fontSize="md" fontWeight="bold">
              {value}
            </chakra.span>
            <chakra.span fontSize="xs" whiteSpace="nowrap">
              {label}
            </chakra.span>
          </VStack>
          <chakra.span fontSize="xl" fontWeight="300" color="gray.300">
            |
          </chakra.span>
        </HStack>
        <VStack alignItems="start" spacing={-0.5} pt="0.5">
          <chakra.span fontSize="xs" fontWeight="semibold">
            {recordUpdated ?? 'N/A'}
          </chakra.span>
          <chakra.span fontSize="xs" whiteSpace="nowrap">
            {text}
          </chakra.span>
        </VStack>
      </HStack>
    </VStack>
  );
}

type BackLink = {
  to: To;
  replace?: boolean;
};

type BackButton = {
  onClick: () => void;
};

export interface HeaderProps {
  title?: string;
  icon?: JSX.Element;
  noSearch?: boolean;
  back?: {
    label: string;
  } & (BackLink | BackButton);
  previousRecord?: any;
  children?: ReactNode;
  hideChildrenOnAlert?: boolean;
}
export default function Header({
  title = '',
  icon,
  back,
  noSearch = true,
  previousRecord,
  children,
  hideChildrenOnAlert,
}: HeaderProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { showPreviewPage, hasPreviewPage, togglePreviewPage } = usePreviewPage();
  const { data: useIdentityMetricsData } = useSystemIdentitySourceMetrics();
  const identityMetrics = useIdentityMetricsData;
  const { data: useSourceIssuesData } = useSourceIssues(SourceIssuePriority.High);
  const sourceIssues = useSourceIssuesData?.content;
  const { data: useSystemMetricsData } = useGetSystemMetrics();
  const systemMetrics = useSystemMetricsData?.metrics;
  const { setLabel } = usePageHistory();
  const elementRef = useRef<HTMLDivElement | null>(null);
  const headerSize = useSize(elementRef);
  const headerHeight = headerSize?.height;
  const { setHeaderHeight } = usePage();

  useEffect(() => {
    if (headerHeight != null) {
      setHeaderHeight(headerHeight);
    }
  }, [headerHeight, setHeaderHeight]);

  useEffect(() => {
    setLabel({ label: title, icon });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  const certifiedProfile =
    identityMetrics?.metrics.certificationTypes?.certified?.totalIdentities ?? 0;
  const iq = identityMetrics?.metrics?.identityQualityScore;

  let backBoxShadow = {};
  if (children) {
    backBoxShadow = { boxShadow: 'base', width: '100%' };
  }

  let backProps = {};
  if (back) {
    const { label, ...rest } = back;
    backProps = rest;
    if ('to' in rest) {
      backProps = {
        ...backProps,
        as: Link,
        state: { previousSearchRecord: previousRecord },
      };
    }
  }

  return (
    <Box ref={elementRef} boxShadow="base" width="100%" bg="white">
      <Flex
        data-testid="Header"
        bg="gray.100"
        alignItems="center"
        justifyContent="space-between"
        py={noSearch ? '22px' : 5}
        px="6"
        boxShadow="base"
        position="relative"
        pb={3}
        pt={3}
      >
        <HStack spacing="4">
          {icon}
          <Text fontSize="xl">{title}</Text>
        </HStack>
        <HStack spacing="4">
          <HeaderStat label="IQ score" value={iq ? (iq * 100).toFixed() : 0} />
          <HeaderStat
            label="certified profiles"
            value={formatCompactNumber(certifiedProfile ?? 0)}
          />
          <HeaderStatDataRecords
            label="data records"
            value={formatCompactNumber(identityMetrics?.metrics?.totalRecords ?? 0)}
            text="last updated"
            recordUpdated={
              systemMetrics?.recordEventsIngested?.lastIngestedAt != null
                ? `${formatRelativeDateStringStrict(
                    systemMetrics?.recordEventsIngested?.lastIngestedAt
                  )}`
                : undefined
            }
          />
          <HeaderStat label="alerts" value={sourceIssues?.length ?? 0} to="/sources" />
        </HStack>
        {!noSearch && (
          <Box>
            <Button
              onClick={onOpen}
              variant="outline"
              fontWeight="regular"
              color="action"
              w="320px"
              display="flex"
              justifyContent="start"
              borderColor="gray.300"
              backgroundColor="white"
              leftIcon={<Icon iconImage={IconImage.searchUser} />}
            >
              Find a consumer profile
            </Button>
            <SearchModal isOpen={isOpen} onClose={onClose} searchLocation="Header" />
          </Box>
        )}
      </Flex>
      {(back || hasPreviewPage) && (
        <HStack
          bg="gray.50"
          justifyContent={back ? 'space-between' : 'end'}
          px={6}
          py={2.5}
          {...backBoxShadow}
        >
          {back && (
            <HStack
              bg="gray.50"
              color="action"
              spacing={3}
              fontSize="sm"
              _hover={{ cursor: 'pointer' }}
              {...backProps}
            >
              <Icon iconImage={IconImage.arrowBack} />
              <Text fontWeight="semibold" lineHeight="6">
                {back.label}
              </Text>
            </HStack>
          )}
          {hasPreviewPage && (
            <Can I="manage" an="SeviinAdmin">
              <HStack spacing={4}>
                <Text fontSize="sm" fontWeight="semibold">
                  Preview new page
                </Text>
                <Switch isChecked={showPreviewPage} onChange={togglePreviewPage} />
              </HStack>
            </Can>
          )}
        </HStack>
      )}

      {hideChildrenOnAlert ? (
        <BatchResolutionHeaderAlert identityMetrics={identityMetrics} />
      ) : (
        <BatchResolutionHeaderAlert identityMetrics={identityMetrics}>
          {children}
        </BatchResolutionHeaderAlert>
      )}
    </Box>
  );
}
