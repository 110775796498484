import { Box, BoxProps, Text } from '@chakra-ui/react';
import { Profile } from '../../../../lib/api-client/profile/model/Profile';
import {
  formatDateString,
  formatDays,
  getDateDifferenceString,
} from '../../../../lib/utils/date-time-utils';
import TransactionInsights from '../TransactionInsights/TransactionInsights';

interface Props {
  profile: Profile;
}

export type ProfilePageInteractionProps = Props & BoxProps;

function ProfilePageTransactionInsights({ profile, ...rest }: ProfilePageInteractionProps) {
  const currentDate = new Date();
  const formattedDate = currentDate
    .toISOString()
    .replace(/\.(\d+)Z$/, (_, millis) => `.${millis}Z`);

  const transactionRelationshipLength = getDateDifferenceString(
    new Date(formattedDate),
    new Date(profile?.transactionSummary?.earliestTransactionTimestamp)
  );

  const transactingTimePeriod = getDateDifferenceString(
    new Date(formattedDate),
    new Date(profile?.transactionSummary?.latestTransactionTimestamp)
  );

  return (
    <Box
      {...rest}
      shadow="md"
      w="340px"
      borderRadius="6px"
      border="1px"
      borderColor="gray.100"
      alignItems="start"
      p="4"
    >
      <Text fontWeight="bold" fontSize="xl" pb="2">
        Interaction insights
      </Text>
      <TransactionInsights
        transactionLength={
          profile?.transactionSummary?.earliestTransactionTimestamp !== null
            ? transactionRelationshipLength
            : 'Not on record'
        }
        averageTimePeriod={
          formatDays(profile.transactionSummary.averageDaysBetweenTransactions) ?? 'Not on record'
        }
        earlierTransactionDate={
          profile?.transactionSummary?.latestTransactionTimestamp !== null
            ? transactingTimePeriod
            : 'Not on record'
        }
        latestTransactionTimestamp={formatDateString(
          profile.transactionSummary.latestTransactionTimestamp,
          'LLL dd, yyyy'
        )}
        firstTransaction={formatDateString(
          profile.transactionSummary.earliestTransactionTimestamp,
          'LLL dd, yyyy'
        )}
      />
    </Box>
  );
}
export default ProfilePageTransactionInsights;
