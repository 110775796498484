import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useToast } from '../../../../hooks/use-toast';
import { UploadPath } from '../../../../lib/api-client/sources/model/UploadPath';
import UploadFlatFile from '../../shared/UploadFlatFile/UploadFlatFile';

interface UploadFlatFileButtonProps {
  sourceId: string;
  onUploadComplete: ({
    uploadPath,
    ingestStarted,
  }: {
    uploadPath: UploadPath;
    ingestStarted: boolean;
  }) => Promise<void>;
}
export default function UploadFlatFileButton({
  sourceId,
  onUploadComplete,
}: UploadFlatFileButtonProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const toast = useToast();
  return (
    <>
      <Button onClick={onOpen} variant="outline" w="full">
        Upload a new file
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Upload a new CSV file
            <ModalCloseButton top={3} />
          </ModalHeader>
          <ModalBody pb={6}>
            <UploadFlatFile
              type="UPLOAD_AND_INGEST"
              sourceId={sourceId}
              onUpload={async ({ uploadPath, ingestStarted }) => {
                await onUploadComplete({ uploadPath, ingestStarted });
                toast({
                  status: 'success',
                  title: ingestStarted
                    ? 'Data ingest is in progress.'
                    : 'Data successfully uploaded.',
                });
                onClose();
              }}
              onUploadError={() => {
                toast({
                  status: 'error',
                  title: 'We were unable to ingest data.',
                  description: 'Please try again.',
                });
                onClose();
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
