import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useAuth } from '../../../../context/AuthenticationContext';
import { AuthenticationConfiguration } from '../../../../lib/api-client/authentication/model/AuthenticationConfiguration';
import AuthenticationService from '../../../../lib/services/AuthenticationService';
import Alert from '../../../core/Alert/Alert';
import ChangePasswordForm from '../ChangePasswordForm/ChangePasswordForm';
import FormLogoHeader from '../FormLogoHeader/FormLogoHeader';
import FormTermsFooter from '../FormTermsFooter/FormTermsFooter';
import ResetPasswordForm from '../ResetPasswordForm/ResetPasswordForm';
import TwoFactorVerificationForm from '../TwoFactorVerificationForm/TwoFactorVerificationForm';

interface LoginFormProps {
  accountEmail: string;
  authenticationConfiguration: AuthenticationConfiguration;
  onBack: () => void;
  onError: () => void;
  onSuccess: () => void;
}
const fieldError = 'Field is required';
const loginFormSchema = yup
  .object({
    email: yup.string().required('Field is required').email(),
    password: yup.string().required(fieldError),
  })
  .required();

type LoginFormState = 'LOGIN' | 'LOADING' | 'RESET' | 'PASSWORD_CHANGE' | '2FA_VERIFY';

export default function LoginForm({
  accountEmail,
  authenticationConfiguration,
  onBack,
  onError,
  onSuccess,
}: LoginFormProps) {
  const [currentState, setCurrentState] = useState<[LoginFormState, any?]>(['LOGIN']);
  const [isLoading, setLoading] = useBoolean(false);
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<{ email: string; password: string; serverError?: string }>({
    resolver: yupResolver(loginFormSchema),
    mode: 'onBlur',
  });

  const onSubmit: SubmitHandler<any> = async (form) => {
    setLoading.on();
    let result;
    try {
      result = await signIn({
        email: form.email,
        password: form.password,
        userPool: {
          id: authenticationConfiguration.userPool.id,
          clientId: authenticationConfiguration.userPool.clientId,
        },
      });
      onSuccess();
    } catch (err: any) {
      setLoading.off();
      if (err.code === 'NotAuthorizedException') {
        setError('password', {
          type: 'usernamePasswordIncorrect',
          message: 'Please double check that your information is entered correctly and try again.',
        });
        return;
      }
      onError();
      return;
    }

    switch (result && result.result) {
      case 'reset':
        setCurrentState(['PASSWORD_CHANGE', { oldPassword: form.password }]);
        break;
      case 'totp':
        setCurrentState(['2FA_VERIFY']);
        break;
      default:
        navigate('/', { replace: true });
    }
  };

  const [state, params] = currentState;
  switch (state) {
    case 'PASSWORD_CHANGE':
      return (
        <ChangePasswordForm
          accountEmail={accountEmail}
          oldPassword={params.oldPassword}
          authenticationConfiguration={authenticationConfiguration}
          onError={onError}
        />
      );
    case 'RESET':
      return (
        <ResetPasswordForm
          accountEmail={accountEmail}
          authenticationConfiguration={authenticationConfiguration}
          onError={onError}
        />
      );
    case '2FA_VERIFY':
      return <TwoFactorVerificationForm />;
    default:
  }

  return (
    <>
      <VStack pos="absolute" w="inherit" top="148px">
        {errors?.password?.message && errors?.password?.message !== fieldError && (
          <Alert
            status="error"
            title="There was a problem signing you in."
            description={errors.password.message}
            closeable
          />
        )}
      </VStack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormLogoHeader />
        <Box paddingX="170px">
          <Box pb="20px">
            <Flex alignItems="center" justifyContent="space-between">
              {accountEmail}
              <Input id="email" type="hidden" defaultValue={accountEmail} {...register('email')} />
              <Button variant="link" fontSize="md" color="action" onClick={onBack}>
                Edit email
              </Button>
            </Flex>
          </Box>
          <FormControl isInvalid={errors?.password?.message === fieldError && !!errors.password}>
            <FormLabel fontWeight="semibold" htmlFor="password">
              Password
            </FormLabel>
            <Input
              fontSize="md"
              id="password"
              type="password"
              {...register('password')}
              autoFocus
            />
            {errors?.password?.message === fieldError && (
              <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
            )}
          </FormControl>
        </Box>
        <Button w="200px" h="48px" m="40px" type="submit" mb="3" isLoading={isLoading}>
          Sign in
        </Button>
        <Box>
          <Button
            type="button"
            variant="ghost"
            onClick={() => {
              AuthenticationService.forgotPassword(
                authenticationConfiguration.userPool.id,
                authenticationConfiguration.userPool.clientId,
                accountEmail
              )
                .then(() => setCurrentState(['RESET']))
                .catch(onError);
            }}
          >
            Forgot password?
          </Button>
        </Box>
        <Box mb="40px">
          <FormTermsFooter />
        </Box>
      </form>
    </>
  );
}
