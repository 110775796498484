import { chakra, HStack } from '@chakra-ui/react';
import React from 'react';
import { ReactComponent as Logo } from '../../../assets/harpinAI-logo-graphic-only.svg';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import { DataSourceConnectionTypes } from '../../../lib/api-client/sources/model/DataSourceConnectionType';

interface SyncDirectionHelpProps {
  syncDirection: DataSourceConnectionTypes;
}

export default function SyncDirectionHelp({ syncDirection }: SyncDirectionHelpProps) {
  let icon = IconImage.arrowForward;
  if (syncDirection === 'BOTH') {
    icon = IconImage.dataTransfer;
  } else if (syncDirection === 'OUT') {
    icon = IconImage.arrowBack;
  }

  return (
    <SectionContainer variant="box">
      <HStack>
        <Icon iconImage={IconImage.database} />
        <Icon iconImage={icon} />
        <Logo />
        <chakra.span fontSize="sm">
          {syncDirection === 'IN' &&
            'These mappings are one-way, ingesting data from the source system to harpin AI.'}
          {syncDirection === 'OUT' &&
            'These mappings are one-way, publishing data from harpin AI back to the source system.'}
          {syncDirection === 'BOTH' &&
            'These mappings are two-way, ingesting data attributes to harpin AI and publishing them back to the source system.'}
        </chakra.span>
      </HStack>
    </SectionContainer>
  );
}
