import { Box, Button, FormControl, FormLabel, HStack, Select, Stack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import { ReportExecutionSort } from '../../../../lib/api-client/reports/report.model';

interface QuerySortsProps {
  sortOptions: { label: string; value: string }[];
  sortValue?: ReportExecutionSort[];
}
export default function QuerySorts({ sortOptions, sortValue }: QuerySortsProps) {
  const form = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'sort',
  });
  const watchedSort: any[] = form.watch('sort') ?? [];
  const sortedColumns = watchedSort.map((s) => s.column);

  useEffect(() => {
    if (sortValue) {
      form.setValue(
        'sort',
        sortValue.map((s) => ({ column: s.columnName, sort: s.descending ? 'desc' : 'asc' }))
      );
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={4}>
      {fields.map((field: any, index) => (
        <FormControl key={field.id} w="full">
          <HStack w="full" justify="space-between">
            <FormLabel>Sort by</FormLabel>
            <Box>
              <Button size="sm" variant="ghost" onClick={() => remove(index)}>
                Remove
              </Button>
            </Box>
          </HStack>
          <Stack>
            <Select placeholder="Select one" {...form.register(`sort.${index}.column`)}>
              {sortOptions.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  disabled={sortedColumns.includes(option.value) && field.column !== option.value}
                >
                  {option.label}
                </option>
              ))}
            </Select>
            <Select placeholder="Select one" {...form.register(`sort.${index}.sort`)}>
              <option value="desc">High to low </option>
              <option value="asc">Low to high</option>
            </Select>
          </Stack>
        </FormControl>
      ))}
      <Box>
        <Button
          variant="ghost"
          onClick={() => append({ column: '', sort: '' })}
          rightIcon={<Icon iconImage={IconImage.add} boxSize={4} />}
          isDisabled={watchedSort.length === sortOptions.length}
        >
          Add {fields.length > 0 ? 'another' : 'a sort'}
        </Button>
      </Box>
    </Stack>
  );
}
