/* eslint-disable react/require-default-props */
import { HStack, Text, Link } from '@chakra-ui/react';

export default function FormTermsFooter() {
  return (
    <HStack mt="5" fontSize="xs" justifyContent="center" spacing={1}>
      <Text>By continuing, you are agreeing to harpin AI</Text>
      <Link color="action" target="_blank" href="https://seviin.ai/terms-of-use-harpin-ai">
        terms of use
      </Link>
    </HStack>
  );
}
