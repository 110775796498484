import { Box, chakra, HStack, SimpleGrid, Stack, Tag, TagLabel, Text } from '@chakra-ui/react';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';
import { useGetSystemMetrics } from '../../../../lib/api-client/source-metrics/SourceMetricsClient';
import { formatNumber } from '../../../../lib/utils/number-utils';
import { useSourceColor } from '../SourceColorContext';

export default function DataEventSummarySection() {
  const { getColorForSource } = useSourceColor();
  const { data: systemMetrics } = useGetSystemMetrics();
  const sources =
    systemMetrics?.sources.sort(
      (source1, source2) => source2.metrics.recordEvents.total - source1.metrics.recordEvents.total
    ) ?? [];
  return (
    <SimpleGrid columns={4} spacing={3}>
      <SectionContainer title="All data sources" variant="box" titleFontSize="xs">
        <Stack spacing={0}>
          <Text as="span" fontWeight="bold" fontSize="2xl">
            {formatNumber(systemMetrics?.metrics.recordEvents.total ?? 0)}
          </Text>
          <Text as="span">total data record events ingested across all data sources</Text>
          <Box>
            <Tag rounded="full" fontSize="xs" minH={5} px={3} py={1} mt={3} bgColor="gray.200">
              <TagLabel fontWeight="bold" lineHeight={1}>
                {formatNumber(systemMetrics?.metrics.recordEventsLastWeek.total ?? 0)}{' '}
                <chakra.span fontWeight="normal">in the last 7 days</chakra.span>
              </TagLabel>
            </Tag>
          </Box>
        </Stack>
      </SectionContainer>
      {sources.map((source) => (
        <SectionContainer
          variant="box"
          titleFontSize="xs"
          header={
            <HStack fontSize="xs" fontWeight="bold" spacing={1}>
              <Box w={3} h={3} bg={getColorForSource(source.source.id)} borderRadius="sm" />
              <Text>{source.source.name}</Text>
            </HStack>
          }
        >
          <Stack spacing={0}>
            <Text as="span" fontWeight="bold" fontSize="2xl">
              {formatNumber(source.metrics.recordEvents.total ?? 0)}
            </Text>
            <Text as="span">total data record events ingested across all data sources</Text>
            <Box mt={3}>
              <Tag rounded="full" fontSize="xs" minH={5} px={3} py={1} bgColor="gray.200">
                <TagLabel fontWeight="bold" lineHeight={1}>
                  {formatNumber(source.metrics.recordEventsLastWeek.total ?? 0)}{' '}
                  <chakra.span fontWeight="normal">in the last 7 days</chakra.span>
                </TagLabel>
              </Tag>
            </Box>
          </Stack>
        </SectionContainer>
      ))}
    </SimpleGrid>
  );
}
