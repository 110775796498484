import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useAuth } from '../../../../context/AuthenticationContext';
import AuthenticationService from '../../../../lib/services/AuthenticationService';
import SectionContainer from '../../../core/SectionContainer/SectionContainer';
import UnsavedChangesModal from '../../../shared/modals/UnsavedChangesModal/UnsavedChangesModal';

export interface UpdateProfileForm {
  firstName: string;
  lastName: string;
  email: string;
}
const updateProfileFormSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().required(' email is required'),
});

interface ChangeProfileProps {
  onSuccess: () => void;
  onError: (err?: any) => void;
}

export default function UpdateProfile({ onSuccess, onError }: ChangeProfileProps) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<UpdateProfileForm>({
    resolver: yupResolver(updateProfileFormSchema),
    mode: 'onBlur',
  });
  const { verifySession } = useAuth();

  const fetchData = async () => {
    try {
      const userData = await AuthenticationService.getUserDetails().then((details) => details);
      userData.forEach((item: any) => {
        if (item.Name === 'given_name') {
          setValue('firstName', item.Value);
        }
        if (item.Name === 'family_name') {
          setValue('lastName', item.Value);
        }
        if (item.Name === 'email') {
          setValue('email', item.Value);
        }
      });
    } catch (err) {
      onError(err);
    }
  };

  const [warnOnNavigation, setWarnOnNavigation] = useState(true);

  const onSubmit: SubmitHandler<UpdateProfileForm> = async (data) => {
    try {
      await AuthenticationService.updateProfile(data.firstName, data.lastName);

      onSuccess();
      setWarnOnNavigation(false);
      verifySession();
    } catch (err) {
      onError(err);
    }
  };

  useEffect(() => {
    void fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box w="680px" data-testid="UpdateProfile">
      <SectionContainer title="Profile Information">
        <form onSubmit={handleSubmit(onSubmit)}>
          <SimpleGrid columns={2} spacing={10} mb={10}>
            <FormControl isInvalid={!!errors.firstName}>
              <FormLabel fontWeight="semibold">First name</FormLabel>
              <Input {...register('firstName')} />
              <FormErrorMessage>{errors?.firstName?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.lastName}>
              <FormLabel fontWeight="semibold">Last name</FormLabel>
              <Input {...register('lastName')} />
              <FormErrorMessage>{errors?.lastName?.message}</FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel fontWeight="semibold" display="flex" justifyContent="space-between" mr="0">
                Email address
              </FormLabel>
              <Input {...register('email')} isDisabled />
              <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
            </FormControl>
          </SimpleGrid>
          <Button type="submit" isLoading={isSubmitting}>
            Update profile
          </Button>
        </form>
      </SectionContainer>
      <UnsavedChangesModal
        message="Are you sure you want to leave before saving your changes to your profile?"
        warnOnNavigation={warnOnNavigation && isDirty}
      />
    </Box>
  );
}
