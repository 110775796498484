import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDataExplorer } from '../../../../components/shared/data-explorer/DataExplorer/DataExplorerContext';
import { IdentityPinType } from '../../../../lib/api-client/identity/identity.model';
import { DataSource } from '../../../../lib/api-client/sources/model/DataSource';
import { Nullable } from '../../../../lib/model/common';

export type ConsumerProfileFilterForm = {
  sourceId: Nullable<string>;
  pinType: Nullable<IdentityPinType>;
  authenticityValue: Nullable<string>;
  authenticityScore: Nullable<string>;
  authenticityScoreValue1?: Nullable<number>;
  authenticityScoreValue2?: Nullable<number>;
  identityRecordCount: Nullable<string>;
  identityRecordCountOperator: Nullable<string>;
  identityRecordCountValue1?: Nullable<number>;
  identityRecordCountValue2?: Nullable<number>;
};

interface ConsumerProfileExplorerFilterDrawerProps {
  form: ConsumerProfileFilterForm;
  sources: DataSource[];
  onFilterChange: (search: ConsumerProfileFilterForm) => void;
}

export default function ConsumerProfileExplorerFilterDrawer({
  form,
  sources,
  onFilterChange,
}: ConsumerProfileExplorerFilterDrawerProps) {
  const { filterDrawerDisclosure } = useDataExplorer();
  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { isDirty },
  } = useForm<ConsumerProfileFilterForm>({
    defaultValues: {
      ...form,
    },
  });

  const watched = watch();

  const onSubmit: SubmitHandler<ConsumerProfileFilterForm> = async (data) => {
    onFilterChange(data);
    filterDrawerDisclosure.onClose();
  };

  return (
    <Drawer
      isOpen={filterDrawerDisclosure.isOpen}
      onClose={filterDrawerDisclosure.onClose}
      size="md"
      placement="right"
    >
      <DrawerOverlay />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DrawerContent>
          <DrawerCloseButton
            size="lg"
            right="unset"
            left="-40px"
            fontSize="xs"
            color="white"
            top={10}
            bgColor="action"
            _hover={{ backgroundColor: 'actionDark' }}
            borderTopRightRadius={0}
            borderBottomRightRadius={0}
          />
          <DrawerBody>
            <Text fontWeight="bold" fontSize="xl">
              Filter data records
            </Text>

            <Stack spacing={4} py={5}>
              <FormControl>
                <FormLabel>Profile type</FormLabel>
                <Select placeholder="All" {...register('pinType')}>
                  <option value="person">Show: Individual consumer only </option>
                  <option value="household">Show: Household only</option>
                  <option value="business">Show: Business only</option>
                  <option value="unknown">Show: Unknown only</option>
                </Select>
              </FormControl>
              <Stack>
                <FormControl>
                  <FormLabel mt={2}>Profile certification score</FormLabel>
                  <HStack spacing={3}>
                    <Select placeholder="All" {...register('authenticityValue')}>
                      <option value="authentic">Certified</option>
                      <option value="suspicious">Questionable</option>
                      <option value="unauthentic">Not certified</option>
                      <option value="specific">Specific score range</option>
                    </Select>
                  </HStack>
                </FormControl>

                {watched.authenticityValue === 'specific' && (
                  <>
                    <FormControl>
                      <Select {...register('authenticityScore')} mt="2">
                        <option value="greaterThanOrEqual">Greater than or equal</option>
                        <option value="lessThanOrEqual">Less than or equal</option>
                        <option value="equal">Equal</option>
                        <option value="between">Between</option>
                      </Select>
                    </FormControl>
                    <HStack>
                      <FormControl>
                        <Input
                          type="number"
                          placeholder="0 - 100"
                          min="0"
                          max="100"
                          step="0.01"
                          {...register('authenticityScoreValue1', { valueAsNumber: true })}
                        />
                      </FormControl>
                      {watched.authenticityScore === 'between' && (
                        <FormControl>
                          <Input
                            type="number"
                            placeholder="0 - 100"
                            min="0"
                            max="100"
                            step="0.01"
                            {...register('authenticityScoreValue2', { valueAsNumber: true })}
                          />
                        </FormControl>
                      )}
                    </HStack>
                  </>
                )}
              </Stack>
              <FormControl>
                <FormLabel>Source system contribution</FormLabel>
                <Select placeholder="All" {...register('sourceId')}>
                  {sources.map((source) => (
                    <option key={source.id} value={source.id}>
                      {source.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <Stack>
                <FormControl>
                  <FormLabel>Number of data records in profile cluster</FormLabel>
                  <Select placeholder="All" {...register('identityRecordCount')}>
                    <option value="specific">Specific range</option>
                  </Select>
                </FormControl>
                {watched.identityRecordCount === 'specific' && (
                  <>
                    <FormControl>
                      <Select {...register('identityRecordCountOperator')}>
                        <option value="greaterThan">Greater than</option>
                        <option value="lessThan">Less than</option>
                        <option value="equal">Equal</option>
                        <option value="between">Between</option>
                      </Select>
                    </FormControl>
                    <HStack>
                      <FormControl>
                        <Input type="number" min="0" {...register('identityRecordCountValue1')} />
                      </FormControl>
                      {watched.identityRecordCountOperator === 'between' && (
                        <FormControl>
                          <Input type="number" min="0" {...register('identityRecordCountValue2')} />
                        </FormControl>
                      )}
                    </HStack>
                  </>
                )}
              </Stack>
            </Stack>
          </DrawerBody>
          <DrawerFooter borderTop="1px solid" borderColor="gray.100">
            <HStack w="full">
              <Button w="full" type="submit">
                Apply filters
              </Button>
              <Button
                variant="outline"
                w="full"
                disabled={!isDirty}
                onClick={() => {
                  reset();
                }}
              >
                Reset filters
              </Button>
            </HStack>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
}
