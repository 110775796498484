import { SearchOperator } from '../identity/model/SourceIdentityRecordSearch';

export type ReportPredicateOperator =
  | 'BETWEEN'
  | 'EQUALS'
  | 'GREATER_THAN'
  | 'GREATER_THAN_OR_EQUAL'
  | 'LESS_THAN'
  | 'LESS_THAN_OR_EQUAL'
  | 'IS_NULL'
  | 'IS_NOT_NULL'
  | 'CONTAINS';

export interface ReportExecutionPredicate {
  columnName: string;
  operator: ReportPredicateOperator;
  arguments?: string[];
}

export interface ReportExecutionFilter {
  conjunction?: 'AND' | 'OR';
  predicates?: ReportExecutionPredicate[];
  filters?: ReportExecutionFilter[];
}

export interface ReportExecutionOption {
  optionName: string;
  arguments?: Record<string, any>;
}

export interface ReportExecutionSort {
  columnName: string;
  descending?: boolean;
}

export interface StartReportExecution {
  reportType: string;
  columns?: string[];
  filter?: ReportExecutionFilter;
  sortOrder?: ReportExecutionSort[];
  options?: ReportExecutionOption[];
  limit?: number;
}

export interface StartReportExecutionResponse {
  reportId: string;
}

export interface ReportPage {
  reportType: string;
  nextToken: string;
  nextTokenUrlEscaped: string;
  totalRows: number;
  totalBytes: number;
  recordCount: number;
  content: any[];
}

export interface ReportMetadata {
  type: string;
  displayName: string;
  description: string;
  options: ReportMetadataOption[];
  columns: ReportMetadataColumn[];
}

export interface ReportMetadataOptionValue {
  displayName: string;
  value: string;
  description?: string;
}

export interface ReportMetadataOption {
  name: string;
  displayName: string;
  description: string;
  arguments: {
    name: string;
    displayName: string;
    description: string;
    dataType: ReportArgumentDataType;
    required: boolean;
    values?: ReportMetadataOptionValue[];
  }[];
}

interface ReportMetadataColumn {
  name: string;
  displayName: string;
  description: string;
  dataType: ReportColumnDataType;
}

export type ReportArgumentDataType =
  | 'BOOLEAN'
  | 'STRING'
  | 'INTEGER'
  | 'DECIMAL'
  | 'STRING_ARRAY'
  | 'TIMESTAMP'
  | 'DATE';

export type ReportColumnDataType = ReportArgumentDataType;

export function mapSearchOperatorToReportPredicateOperator(
  operator: SearchOperator
): ReportPredicateOperator {
  switch (operator) {
    case SearchOperator.Between:
      return 'BETWEEN';
    case SearchOperator.GreaterThanOrEqual:
      return 'GREATER_THAN_OR_EQUAL';
    case SearchOperator.GreaterThan:
      return 'GREATER_THAN';
    case SearchOperator.LessThanOrEqual:
      return 'LESS_THAN_OR_EQUAL';
    case SearchOperator.LessThan:
      return 'LESS_THAN';
    case SearchOperator.EqualOrNull:
    case SearchOperator.Equal:
    default:
      return 'EQUALS';
  }
}
