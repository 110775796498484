import {
  Box,
  Button,
  ButtonGroup,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useBoolean,
  useClipboard,
  useDisclosure,
} from '@chakra-ui/react';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { isAxiosError } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import Alert from '../../../../components/core/Alert/Alert';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';
import { useAuth } from '../../../../context/AuthenticationContext';
import { useToast } from '../../../../hooks/use-toast';
import IdentityClient from '../../../../lib/api-client/identity/IdentityClient';
import { useGetApiCredentials } from '../../../../lib/api-client/identity/IdentityData';
import { formatDateString } from '../../../../lib/utils/date-time-utils';

function AuthenticationModal({
  isOpen,
  onClose,
  onSuccess,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (password: string) => void;
}) {
  const { tenant, currentUser } = useAuth();
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useBoolean();
  const [error, setError] = useBoolean();

  const generateApiKey = useCallback(() => {
    setLoading.on();
    setError.off();
    if (tenant && currentUser) {
      const authenticationDetails = new AuthenticationDetails({
        Username: currentUser.emailAddress,
        Password: password,
      });
      const userPool = new CognitoUserPool({
        UserPoolId: tenant.userPoolId,
        ClientId: tenant.apiAuthClientId,
      });
      const cognitoUser = new CognitoUser({
        Username: currentUser.emailAddress,
        Pool: userPool,
      });

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (session) => {
          setLoading.off();
          onClose();
          setPassword('');
          onSuccess(session.getRefreshToken().getToken());
        },
        onFailure: () => {
          setLoading.off();
          setError.on();
        },
      });
    }
  }, [currentUser, tenant, onSuccess, password, onClose, setLoading, setError]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm">
      <ModalOverlay>
        <ModalContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <ModalHeader>Sign in to your harpin AI account</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack>
                <Text fontSize="sm">Re-authenticate to generate your OAuth refresh token.</Text>
                <FormControl isInvalid={error}>
                  <FormLabel>Password</FormLabel>
                  <Input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {error && (
                    <FormErrorMessage>
                      Please double check that your information is entered correctly and try again.
                    </FormErrorMessage>
                  )}
                </FormControl>
              </Stack>
            </ModalBody>
            <ModalFooter justifyContent="start">
              <Button onClick={generateApiKey} isLoading={loading}>
                Continue
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

function RefreshTokenModal({
  token,
  isOpen,
  onClose,
}: {
  token: string;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { onCopy, setValue } = useClipboard('');
  const toast = useToast();

  useEffect(() => {
    setValue(token);
  }, [token, setValue]);

  const onCloseInternal = () => {
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseInternal} size="lg">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>harpin AI OAuth refresh token</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <Stack fontSize="sm">
                <HStack justifyContent="space-between">
                  <Text fontWeight="semibold">
                    Below is the refresh token that you will need to authenticate to the harpin AI
                    platform.
                  </Text>
                  <Box>
                    <Button
                      variant="ghost"
                      rightIcon={<Icon iconImage={IconImage.copy} boxSize={4} />}
                      onClick={() => {
                        onCopy();
                        toast({ status: 'success', description: 'Refresh token copied!' });
                      }}
                    >
                      Copy token
                    </Button>
                  </Box>
                </HStack>
                <Box
                  wordBreak="break-all"
                  p={1}
                  border="1px solid"
                  borderColor="gray.300"
                  borderRadius="lg"
                >
                  {token}
                </Box>
              </Stack>
              <Alert
                status="warning"
                variant="subtle"
                description="Double check that you’ve copied and saved your OAuth refresh token. Once this
  modal is closed, it will not be accessible again and can only be regenerated or
  revoked."
                fontSize="sm"
                p={2}
              />
            </Stack>
          </ModalBody>
          <ModalFooter justifyContent="start">
            <Button onClick={onCloseInternal}>Continue</Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

function RegenerateTokenConfirmationModal({
  isOpen,
  onClose,
  onConfirmation,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirmation: () => void;
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Are you sure you want to regenerate your refresh token?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="sm">
              This will revoke the OAuth refresh token previously generated and any further usage of
              the token will be denied.
            </Text>
          </ModalBody>
          <ModalFooter justifyContent="start">
            <ButtonGroup>
              <Button
                onClick={() => {
                  onConfirmation();
                  onClose();
                }}
              >
                Yes, regenerate now
              </Button>
              <Button variant="outline" onClick={onClose}>
                No, cancel
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

function RevokeTokenConfirmationModal({
  isOpen,
  onClose,
  onConfirmation,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirmation: () => void;
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Are you sure you want to revoke your refresh token?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="sm">
              Your OAuth refresh token will be revoked and any further usage of the token will be
              denied.
            </Text>
          </ModalBody>
          <ModalFooter justifyContent="start">
            <ButtonGroup>
              <Button
                onClick={() => {
                  onConfirmation();
                  onClose();
                }}
              >
                Yes, revoke now
              </Button>
              <Button variant="outline" onClick={onClose}>
                No, cancel
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}

export interface ApiCredential {
  createdAt: string;
  expiresAt: string;
}

export default function ApiCredentialsTab() {
  const { tenant } = useAuth();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: isOpenAuth, onClose: onCloseAuth, onOpen: onOpenAuth } = useDisclosure();
  const { isOpen: isOpenRegen, onClose: onCloseRegen, onOpen: onOpenRegen } = useDisclosure();
  const { isOpen: isOpenRevoke, onClose: onCloseRevoke, onOpen: onOpenRevoke } = useDisclosure();
  const { data: apiCredentialData, refetch, error } = useGetApiCredentials();
  const apiCredential =
    isAxiosError(error) && error?.response?.status === 404 ? undefined : apiCredentialData;
  const [newToken, setNewToken] = useState('');

  const [alertMsg, setAlertMsg] = useState('');
  const { onCopy, setValue } = useClipboard(tenant?.apiAuthClientId ?? '');
  const toast = useToast();

  useEffect(() => {
    if (tenant) {
      setValue(tenant.apiAuthClientId);
    }
  }, [tenant, setValue]);

  const saveToken = async (token: string) => {
    try {
      await IdentityClient.saveAPICredentials(token);
      await refetch();
      setAlertMsg('Your OAuth refresh token is activated.');
    } catch (err) {
      toast({ status: 'error', description: 'There was a problem generating the token' });
    }
  };

  const revokeToken = async () => {
    try {
      await IdentityClient.revokeToken();
      setAlertMsg('Your OAuth refresh token has been revoked.');
      await refetch();
    } catch (err) {
      toast({ status: 'error', description: 'There was a problem revoking the token' });
    }
  };

  return (
    <Box>
      {alertMsg && <Alert status="success" description={alertMsg} closeable mb={6} />}

      <Stack spacing={1}>
        <chakra.span fontWeight="bold">
          Manage your access credentials for the harpin AI toolset
        </chakra.span>
        <HStack spacing={0} fontSize="sm">
          <chakra.span>Client ID: {tenant?.apiAuthClientId}</chakra.span>
          <IconButton
            aria-label="Copy client ID"
            variant="ghost"
            icon={<Icon iconImage={IconImage.copy} boxSize={4} />}
            onClick={() => {
              if (tenant) {
                onCopy();
                toast({ status: 'success', description: 'Client ID copied!' });
              }
            }}
          />
        </HStack>
        {apiCredential && (
          <chakra.span fontSize="sm" pb={5}>
            OAuth refresh token generated on{' '}
            {formatDateString(apiCredential.createdAt, 'MMM dd, yyyy')}. Expires{' '}
            {formatDateString(apiCredential.expiresAt, 'MMM dd, yyyy')}.
          </chakra.span>
        )}
      </Stack>
      <Box pb={6}>
        {apiCredential ? (
          <Stack direction="row">
            <ButtonGroup variant="outline">
              <Button onClick={onOpenRegen}>Regenerate refresh token</Button>
              <Button onClick={onOpenRevoke}>Revoke refresh token</Button>
            </ButtonGroup>
            <RegenerateTokenConfirmationModal
              isOpen={isOpenRegen}
              onClose={onCloseRegen}
              onConfirmation={onOpenAuth}
            />
            <RevokeTokenConfirmationModal
              isOpen={isOpenRevoke}
              onClose={onCloseRevoke}
              onConfirmation={revokeToken}
            />
          </Stack>
        ) : (
          <Button onClick={onOpenAuth}>Generate refresh token</Button>
        )}
      </Box>
      <SectionContainer
        variant="ghost"
        header={
          <HStack justifyContent="space-between">
            <Text fontSize="md" lineHeight={5} fontWeight="bold" width="full">
              Available APIs
            </Text>
            <Text fontWeight="semibold" fontSize="xs" color="gray.600" whiteSpace="nowrap">
              Showing 1 of 1
            </Text>
          </HStack>
        }
      >
        <Stack>
          <SectionContainer
            variant="box"
            header={
              <Text fontSize="sm" lineHeight={5} fontWeight="bold" width="full">
                Data Sync API
              </Text>
            }
          >
            <Text fontSize="sm">
              The Data Sync API provides access to all data records, indexed by PIN, across all
              connected source systems.
            </Text>
          </SectionContainer>
        </Stack>
      </SectionContainer>
      <RefreshTokenModal token={newToken} isOpen={isOpen} onClose={onClose} />
      <AuthenticationModal
        isOpen={isOpenAuth}
        onClose={onCloseAuth}
        onSuccess={async (token) => {
          await saveToken(token);
          setNewToken(token);
          onOpen();
        }}
      />
    </Box>
  );
}
