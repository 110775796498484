import { chakra, HStack } from '@chakra-ui/react';
import { CellContext } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import ProfileTypeIcon from '../../../profile/components/ProfileTypeIcon/ProfileTypeIcon';

export default function ProfileLinkCell({
  cellContext,
}: {
  cellContext: CellContext<any, string | undefined>;
}) {
  const value = cellContext.getValue();
  const { pinType } = cellContext.row.original;

  let link = '';
  if (value) {
    link = `/profiles/${value}`;
  }

  return (
    <HStack spacing={0.5} justify="space-between" as={Link} to={link} target="_blank" data-group>
      <HStack spacing={0.5}>
        {pinType && <ProfileTypeIcon pinType={pinType} />}
        <chakra.div
          textDecoration="none"
          _groupHover={{ color: 'action' }}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {value}
        </chakra.div>
      </HStack>
      <chakra.div>
        <Icon color="action" iconImage={IconImage.externalLink} boxSize={5} />
      </chakra.div>
    </HStack>
  );
}
