import { Box, HStack, Link as ChakraLink, Text } from '@chakra-ui/react';
import { CellContext } from '@tanstack/react-table';
import React from 'react';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import { useCurrentDataSource } from '../../context/CurrentDataSourceContext';

export default function ExternalLinkCell<T>({
  cellContext,
}: {
  cellContext: CellContext<T, string | undefined>;
}) {
  const { dataSource } = useCurrentDataSource();
  const v = cellContext.getValue();
  if (!v) {
    return null;
  }

  if (!dataSource.externalLinks?.sourceIdentity) {
    return <Text>{v}</Text>;
  }

  return (
    <HStack
      spacing={1}
      justify="space-between"
      as={ChakraLink}
      href={dataSource.externalLinks?.sourceIdentity.replace('{id}', v)}
      target="_blank"
    >
      <Text
        textDecoration="none"
        _hover={{ color: 'action' }}
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {v}
      </Text>
      <Box>
        <Icon color="action" iconImage={IconImage.externalLink} boxSize={5} />
      </Box>
    </HStack>
  );
}
