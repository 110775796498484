import { HStack, Select } from '@chakra-ui/react';
import { startOfDay, startOfHour, startOfMonth, startOfWeek, sub } from 'date-fns';
import React, { ChangeEvent, useState } from 'react';
import { TimeSeriesIntervalName } from '../../../../lib/model/common';

type TimeSeriesFilterConfig = {
  label: string;
  value: string;
  getStartDate: (date: Date) => Date;
};

// NOTE: The start of the window needs to be the length of the interval - 1. For example
// the start date for the last 12 hours is now - 11 hours.
export const filterConfig: {
  [key: string]: TimeSeriesFilterConfig[];
} = {
  hour: [
    {
      label: 'Last 12 hours',
      value: 'last12Hours',
      getStartDate: (date: Date) => startOfHour(sub(date, { hours: 11 })),
    },
    {
      label: 'Last 24 hours',
      value: 'last24Hours',
      getStartDate: (date: Date) => startOfHour(sub(date, { hours: 23 })),
    },
    {
      label: 'Last 36 hours',
      value: 'last36Hours',
      getStartDate: (date: Date) => startOfHour(sub(date, { hours: 35 })),
    },
    {
      label: 'Last 48 hours',
      value: 'last48Hours',
      getStartDate: (date: Date) => startOfHour(sub(date, { hours: 47 })),
    },
  ],
  day: [
    {
      label: 'Last 7 days',
      value: 'last7days',
      getStartDate: (date: Date) => startOfDay(sub(date, { days: 6 })),
    },
    {
      label: 'Last 14 days',
      value: 'last14days',
      getStartDate: (date: Date) => startOfDay(sub(date, { days: 13 })),
    },
  ],
  week: [
    {
      label: 'Last 4 weeks',
      value: 'last4weeks',
      getStartDate: (date: Date) => startOfWeek(sub(date, { weeks: 3 })),
    },
    {
      label: 'Last 6 weeks',
      value: 'last6weeks',
      getStartDate: (date: Date) => startOfWeek(sub(date, { weeks: 5 })),
    },
    {
      label: 'Last 8 weeks',
      value: 'last8weeks',
      getStartDate: (date: Date) => startOfWeek(sub(date, { weeks: 7 })),
    },
    {
      label: 'Last 10 weeks',
      value: 'last10weeks',
      getStartDate: (date: Date) => startOfWeek(sub(date, { weeks: 9 })),
    },
    {
      label: 'Last 12 weeks',
      value: 'last12weeks',
      getStartDate: (date: Date) => startOfWeek(sub(date, { weeks: 11 })),
    },
  ],
  month: [
    {
      label: 'Last 3 months',
      value: 'last3months',
      getStartDate: (date: Date) => startOfMonth(sub(date, { months: 2 })),
    },
    {
      label: 'Last 6 months',
      value: 'last6months',
      getStartDate: (date: Date) => startOfMonth(sub(date, { months: 5 })),
    },
    {
      label: 'Last 9 months',
      value: 'last9months',
      getStartDate: (date: Date) => startOfMonth(sub(date, { months: 8 })),
    },
    {
      label: 'Last 12 months',
      value: 'last12months',
      getStartDate: (date: Date) => startOfMonth(sub(date, { months: 11 })),
    },
  ],
};

export interface SelectedTimeSeriesFilter {
  interval: TimeSeriesIntervalName;
  config: TimeSeriesFilterConfig;
}

interface TimeSeriesFilterProps {
  onFilterChanged: (filter: SelectedTimeSeriesFilter) => void;
  bgColor?: boolean;
  hideHourFilter?: boolean;
}

export default function TimeSeriesFilter({
  onFilterChanged,
  bgColor,
  hideHourFilter,
}: TimeSeriesFilterProps) {
  const [filters, setFilters] = useState<SelectedTimeSeriesFilter>({
    interval: 'day',
    config: filterConfig.day[0],
  });

  const onIntervalChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const interval = event.target.value;
    if (interval in filterConfig) {
      const selected = {
        interval: interval as TimeSeriesIntervalName,
        config: filterConfig[interval][0],
      };
      setFilters(selected);
      onFilterChanged(selected);
    }
  };

  const onAmountChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setFilters((prev) => {
      const selected = {
        ...prev,
        config:
          filterConfig[prev.interval].find((config) => config.value === event.target.value) ??
          prev.config,
      };
      onFilterChanged(selected);

      return selected;
    });
  };

  return (
    <HStack spacing={2}>
      <Select
        defaultValue={filters.interval}
        onChange={onIntervalChange}
        bgColor={bgColor ? 'white' : ''}
        w={bgColor ? 'full' : 'auto'}
      >
        {!hideHourFilter && <option value="hour">Show hourly</option>}
        <option value="day">Show daily</option>
        <option value="week">Show weekly</option>
        <option value="month">Show monthly</option>
      </Select>
      <Select
        onChange={onAmountChange}
        bgColor={bgColor ? 'white' : ''}
        w={bgColor ? 'full' : 'auto'}
      >
        {filterConfig[filters.interval].map((config) => (
          <option key={config.label} value={config.value}>
            {config.label}
          </option>
        ))}
      </Select>
    </HStack>
  );
}
