/* eslint-disable no-nested-ternary */
import {
  Box,
  BoxProps,
  Button,
  Center,
  chakra,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Select,
  Stack,
  Switch,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import { useXSWR } from '@hazae41/xswr';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as CyborgMachineLearning } from '../../../assets/images/cyborg-machine-learning.svg';
import Header from '../../../components/core/Header/Header';
import ProTip from '../../../components/core/ProTip/ProTip';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import StickyBox from '../../../components/core/containers/StickyBox/StickyBox';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import { Can } from '../../../context/AuthorizationContext';
import DataRepairClient from '../../../lib/api-client/data-repair/DataRepairClient';
import {
  getDataRepairs,
  useDataRepairMetrics,
  useDataRepairs,
  useScrollableDataRepairAuditEvents,
} from '../../../lib/api-client/data-repair/DataRepairData';
import {
  DataRepairAutomationType,
  DataRepairSourceSystemMetric,
} from '../../../lib/api-client/data-repair/data-repair.model';
import { useDataSources } from '../../../lib/api-client/sources/SourceData';
import { DateDifferenceFormatted, formatDateString } from '../../../lib/utils/date-time-utils';
import { formatNumber } from '../../../lib/utils/number-utils';
import { isDefined } from '../../../lib/utils/utils';
import Datepicker from '../PublishAuditLogPage/components/PublishAuditLogItem/DatePicker';
import LogItem from '../components/LogItem/LogItem';
import LogItemLink from '../components/LogItem/LogItemLink';
import AboutDataRepair from './components/AboutDataRepair/AboutDataRepair';
import DataRepairActivityChart from './components/DataRepairActivityChart/DataRepairActivityChart';

const ellipsisOverflow: BoxProps = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

type AuditLogFilters = {
  sourceId?: string;
  startTime?: string;
  endTime?: string;
  eventType?: string;
};

export default function DataRepairPage() {
  const [initialLoad, setInitialLoad] = useBoolean(true);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const automationType = params.get('automationType') as DataRepairAutomationType;
  const [logFilters, setLogFilters] = useState<AuditLogFilters>({ eventType: automationType });
  const { make } = useXSWR();
  const { data: sources, loading: sourcesLoading } = useDataSources();
  const {
    data: auditEvents,
    loading: auditEventsLoading,
    scroll,
  } = useScrollableDataRepairAuditEvents(logFilters);
  const { data: dataRepair, loading: dataRepairLoading } = useDataRepairs(automationType);
  const { data: allDataRepairMetrics, loading: dataRepairMetricsLoading } = useDataRepairMetrics();
  const [dataRepairMetrics, setDataRepairMetrics] = useState<DataRepairSourceSystemMetric[]>();
  const [isActive, setActive] = useState(dataRepair?.enabled);
  const loading =
    sourcesLoading || auditEventsLoading || dataRepairLoading || dataRepairMetricsLoading;
  const lastPage = auditEvents != null ? auditEvents.slice(-1).pop() : undefined;
  const isEmptyState = !initialLoad && !dataRepair?.lastEnabledOrDisabledAt;

  const allowedKeys = {
    emailDomainMisspellings: ['emailAddressDomain'],
    nameIsRepeated: ['firstName', 'lastName'],
    nameFromEmail: ['firstName', 'lastName', 'emailAddress'],
  };

  useEffect(() => {
    if (dataRepair) {
      setActive(dataRepair.enabled);
      setInitialLoad.off();
    }

    if (!dataRepairMetrics || dataRepairMetrics.length < 1) {
      const repairMetricsByAutomationType: DataRepairSourceSystemMetric[] | any =
        allDataRepairMetrics?.sources?.filter((source) =>
          source?.metrics?.repairsByAutomationType?.find(
            (repairType) => repairType.type === automationType
          )
        );
      if (repairMetricsByAutomationType) {
        setDataRepairMetrics(repairMetricsByAutomationType);
      }
    }
  }, [
    dataRepair,
    setInitialLoad,
    allDataRepairMetrics,
    dataRepairMetrics,
    automationType,
    setDataRepairMetrics,
  ]);

  const onNext = useCallback(async () => {
    await scroll();
  }, [scroll]);

  const onSwitchChanged = useCallback(
    async (enabled: boolean, automationTypeName: DataRepairAutomationType) => {
      setActive(enabled);
      const data = await DataRepairClient.updateDataRepairAutomation(automationTypeName, enabled);
      const dataRepairs = make(getDataRepairs(automationTypeName));
      await dataRepairs.mutate((previous) => ({
        ...previous,
        data,
      }));
    },
    [make, setActive]
  );
  const onTimeChange = (value: any) => {
    if (value?.startDate && value?.endDate) {
      const { formattedStartDate, formattedEndDate } = DateDifferenceFormatted(value);
      setLogFilters((AuditLogFilters) => ({
        ...AuditLogFilters,
        startTime: formattedStartDate || undefined,
        endTime: formattedEndDate || undefined,
      }));
    } else {
      setLogFilters((AuditLogFilters) => ({
        ...AuditLogFilters,
        startTime: undefined,
        endTime: undefined,
      }));
    }
  };

  const sourceMap = useMemo(
    () =>
      (sources?.content ?? []).reduce((prev, curr) => {
        const p = { ...prev };
        p[curr.id] = { link: curr.externalLinks?.sourceIdentity, name: curr.name };

        return p;
      }, {} as Record<string, { link?: string; name: string }>),
    [sources]
  );

  const repairAuditEventRow = (values: any) => {
    const label = Object.keys(values)
      ?.map((key) => {
        if (allowedKeys[automationType].includes(key)) {
          switch (key) {
            case 'firstName':
              return values[key] ? `F: ${values[key]}` : 'F: null';
            case 'lastName':
              return values[key] ? `L: ${values[key]}` : 'L: null';
            case 'emailAddressDomain':
              return values[key] ?? null;
            default:
          }
        }

        return null;
      })
      .filter(isDefined)
      .join(' | ');

    return label || 'null';
  };

  const repairUsingData = (values: any) => {
    let label = '';

    // eslint-disable-next-line array-callback-return
    Object.keys(values)?.map((key) => {
      if (allowedKeys[automationType].includes(key) && key === 'emailAddress') {
        label += values[key] ?? null;
      }
    });

    return label;
  };

  const auditEventsData = auditEvents?.flatMap((page) => page.content);

  const headingType =
    automationType === 'emailDomainMisspellings'
      ? 'Common misspellings in email domains'
      : automationType === 'nameIsRepeated'
      ? 'Repair names repeated across data fields'
      : automationType === 'nameFromEmail'
      ? 'Repair names using the email address on record'
      : '';

  const getTotalRepairsForAutomationType = (metrics?: any) => {
    const totalRepairs = metrics.reduce((accumulator: any, a: any) => accumulator + a);
    return totalRepairs;
  };

  return (
    <PageLayout
      pageViewEvent={{ page: 'Data repair Page' }}
      header={
        <Header
          title="Manage data repair automation"
          back={{
            label: 'Back to all data repair automations',
            to: '/sources/repair-automation',
          }}
        />
      }
      loading={initialLoad}
    >
      <Stack spacing={3} pb={6}>
        <HStack justifyContent="space-between" w="full">
          <Heading fontSize="xl">{headingType}</Heading>
          <AboutDataRepair automationType={automationType} />
        </HStack>
        <Stack fontSize="sm" spacing={1}>
          {dataRepair && (
            <FormControl>
              <HStack>
                <Can I="update" an="DataRepair">
                  <Switch
                    isChecked={isActive}
                    onChange={() => onSwitchChanged(!isActive, automationType)}
                  />
                </Can>
                <FormLabel>
                  {`${dataRepair.enabled ? 'Active' : 'Inactive'} ${
                    dataRepair.lastEnabledOrDisabledAt
                      ? `since ${formatDateString(
                          dataRepair.lastEnabledOrDisabledAt,
                          'MMM dd, yyyy'
                        )}`
                      : ''
                  } `}
                </FormLabel>
              </HStack>
            </FormControl>
          )}
          <Text>
            {isEmptyState
              ? 'Data repairs are not currently being processed.'
              : 'Data repairs are being applied automatically upon detection across all connected source systems.'}
          </Text>
        </Stack>
      </Stack>
      {isEmptyState && (
        <Stack>
          <ProTip
            title="Start improving your data quality!"
            content={
              <Text fontSize="sm">
                Get started by activating the data repair automation above to unlock quality issue
                detection and automatically repair and enhance your enterprise data.
              </Text>
            }
          />
          <Center>
            <CyborgMachineLearning />
          </Center>
        </Stack>
      )}
      {!isEmptyState && (
        <>
          <SectionContainer title="Data repair activity" pb={6}>
            <Grid templateColumns="1fr 344px" gap={5}>
              <Box h="306px" w="full">
                <DataRepairActivityChart dataRepairAutomationType={automationType} />
              </Box>
              {dataRepairMetrics && dataRepairMetrics.length > 0 && (
                <Stack boxShadow="base" p={3} w="344px" minW="344px" borderRadius="md">
                  <Heading fontSize="xs" fontWeight="semibold" color="gray.600">
                    Total data repairs
                  </Heading>
                  <Stack spacing={0}>
                    <chakra.span fontWeight="bold">
                      {formatNumber(
                        getTotalRepairsForAutomationType(
                          dataRepairMetrics.map(
                            (source) =>
                              source?.metrics?.repairsByAutomationType?.find(
                                (repairType) => repairType?.type === automationType
                              )?.totalRepairs
                          )
                        )
                      ) ?? 0}
                    </chakra.span>
                    <Text fontSize="sm">total repairs across all source systems</Text>
                  </Stack>
                  {dataRepairMetrics.map((source) => (
                    <Stack spacing={0} key={source.source.id}>
                      <chakra.span fontWeight="bold">
                        {formatNumber(
                          source?.metrics?.repairsByAutomationType?.find(
                            (repairType) => repairType?.type === automationType
                          )?.totalRepairs ?? 0
                        )}
                      </chakra.span>
                      <Text fontSize="sm">total repairs in {source.source.name}</Text>
                    </Stack>
                  ))}
                </Stack>
              )}
            </Grid>
          </SectionContainer>
          <SectionContainer title="Data repair audit log" titleMargin="0">
            <StickyBox bg="white" zIndex={1}>
              <HStack w="full" justify="space-between" py={4}>
                <HStack spacing={4}>
                  {sources && (
                    <Select
                      minW="256px"
                      placeholder="All source systems"
                      onChange={(event) => {
                        setLogFilters((prev) => ({
                          ...prev,
                          sourceId: event.target.value || undefined,
                        }));
                      }}
                    >
                      {sources.content
                        .sort((a, b) =>
                          a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
                        )
                        .map((source) => (
                          <option value={source.id} key={source.id}>
                            {source.name}
                          </option>
                        ))}
                    </Select>
                  )}
                  <Datepicker onTimeChange={onTimeChange} />
                </HStack>
                {lastPage && (
                  <chakra.span fontSize="xs" fontWeight="semibold" color="gray.600">
                    {`Showing ${lastPage.number * lastPage.size + 1} - ${Math.min(
                      (lastPage.number + 1) * lastPage.size,
                      lastPage.totalElements
                    )} of ${formatNumber(lastPage.totalElements)} `}
                  </chakra.span>
                )}
              </HStack>
              <Divider />
            </StickyBox>
            {auditEvents && (
              <Stack divider={<Divider />} pt={3}>
                {auditEventsData &&
                  auditEventsData.map((event) => (
                    <LogItem fontSize="sm" key={event.eventId}>
                      <Grid templateColumns="1fr max-content">
                        <Stack spacing={1}>
                          <Grid templateColumns="256px 256px 256px" columnGap={4}>
                            <Box {...ellipsisOverflow}>
                              {event.pin ? (
                                <LogItemLink
                                  fontWeight="semibold"
                                  as={Link}
                                  to={`/profiles/${event.pin}`}
                                  target="_blank"
                                >
                                  PIN: {event.pin}
                                </LogItemLink>
                              ) : (
                                <chakra.span fontWeight="semibold" {...ellipsisOverflow}>
                                  PIN: Not on record
                                </chakra.span>
                              )}
                              <Stack pt={2} spacing={0}>
                                <chakra.span fontSize="xs" textColor="gray.600" fontWeight="medium">
                                  {automationType === 'emailDomainMisspellings'
                                    ? 'Original domain'
                                    : 'Original name'}
                                </chakra.span>
                                {event?.originalValues && (
                                  <chakra.span {...ellipsisOverflow}>
                                    {repairAuditEventRow(event?.originalValues)}
                                  </chakra.span>
                                )}
                              </Stack>
                            </Box>
                            <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                              <chakra.span fontWeight="semibold" {...ellipsisOverflow}>
                                Source: {sourceMap[event.sourceId]?.name}
                              </chakra.span>
                              <Stack pt={2} spacing={0}>
                                <chakra.span fontSize="12" textColor="gray.600" fontWeight="medium">
                                  {automationType === 'emailDomainMisspellings'
                                    ? 'Repaired domain'
                                    : 'Repaired name'}
                                </chakra.span>
                                {event?.repairedValues && (
                                  <chakra.span {...ellipsisOverflow}>
                                    {repairAuditEventRow(event?.repairedValues)}
                                  </chakra.span>
                                )}
                              </Stack>
                            </Box>
                            <Box>
                              {event?.timestamp && (
                                <chakra.span fontWeight="semibold">
                                  Repaired:{' '}
                                  {formatDateString(event?.timestamp, 'MMM dd, yyyy h:mm a')}
                                </chakra.span>
                              )}
                              {event?.originalValues &&
                                automationType === 'nameFromEmail' &&
                                repairUsingData(event?.originalValues) && (
                                  <Stack spacing={0} pt={2}>
                                    <chakra.span
                                      fontSize="xs"
                                      textColor="gray.600"
                                      fontWeight="medium"
                                    >
                                      Repaired using
                                    </chakra.span>
                                    <chakra.span {...ellipsisOverflow}>
                                      {repairUsingData(event?.originalValues)}
                                    </chakra.span>
                                  </Stack>
                                )}
                            </Box>
                          </Grid>
                        </Stack>
                        <GridItem alignSelf="center">
                          <Button
                            variant="outline"
                            as={Link}
                            to={`/sources/${event.sourceId}/records/${event.sourceRecordId}`}
                            state={{ backLabel: 'Back to manage data repair automation' }}
                          >
                            Record Version history
                          </Button>
                        </GridItem>
                      </Grid>
                    </LogItem>
                  ))}
                {!lastPage?.last && (
                  <Center>
                    <Button isLoading={loading} onClick={onNext}>
                      See next{' '}
                      {lastPage
                        ? Math.min(
                            100,
                            lastPage.totalElements - (lastPage.number + 1) * lastPage.size
                          )
                        : ''}
                    </Button>
                  </Center>
                )}
              </Stack>
            )}
          </SectionContainer>
        </>
      )}
    </PageLayout>
  );
}
