import { Box, Button, Stack } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { UseFieldArrayReturn, useFormContext } from 'react-hook-form';
import Icon from '../../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../../components/core/Icon/IconConfig';
import { DataModel } from '../../../../../lib/api-client/data-model/data-model.model';
import {
  filterAttributeDomainsFromDataModel,
  getAttributeMetadataFromDataModel,
} from '../../../../../lib/api-client/data-model/data-model.utils';
import { DataSource } from '../../../../../lib/api-client/sources/model/DataSource';
import { UpdateDataSource } from '../../../../../lib/api-client/sources/model/UpdateDataSource';
import { attributeMappingSchema, UpdateDataSourceForm } from '../../DataMappingPage.utils';
import AttributeRow from '../AttributeRow/AttributeRow';
import AttributeRowHeader from '../AttributeRowHeader/AttributeRowHeader';
import PublishTransactionsSection from '../PublishTransactionsSection/PublishTransactionsSection';

interface PublishMappingsTabProps {
  source: DataSource;
  dataModel: DataModel;
  useFieldArray: UseFieldArrayReturn<UpdateDataSourceForm, 'attributeMappings'>;
  onValidate: (valid: boolean) => void;
}
export default function PublishMappingsTab({
  dataModel,
  useFieldArray,
  source,
  onValidate,
}: PublishMappingsTabProps) {
  const publishDataModel = filterAttributeDomainsFromDataModel(dataModel, 'EXPORT');
  const attributeMetadata = getAttributeMetadataFromDataModel(publishDataModel);
  const domainIds = publishDataModel.domains.map((domain) => domain.id);

  const form = useFormContext<UpdateDataSource>();
  const { fields, append } = useFieldArray;

  const isValid = useMemo(
    () =>
      attributeMappingSchema.isValidSync(
        (form.getValues('attributeMappings') ?? []).filter((field) =>
          field.domainType ? domainIds.includes(field.domainType) : false
        )
      ),
    [form, domainIds]
  );

  useEffect(() => {
    onValidate(isValid);
  }, [isValid, onValidate]);

  return (
    <Stack spacing={6}>
      <Stack>
        <AttributeRowHeader syncDirection="OUT" layout="OUTBOUND" />
        {fields.map((item, index) =>
          domainIds.includes(item.domainType!) ? (
            <AttributeRow
              sourceAttributes={[]}
              syncDirection="OUT"
              item={item}
              index={index}
              key={item.id}
              attributeMetadata={attributeMetadata}
              useFieldArray={useFieldArray}
              layout="OUTBOUND"
            />
          ) : null
        )}
        <Box>
          <Button
            variant="ghost"
            rightIcon={<Icon iconImage={IconImage.add} />}
            onClick={() => {
              append({
                mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
                domainType: 'EXPORT',
                canonicalAttribute: '',
                sourceAttributes: [{ value: '' }],
              } as any);
            }}
          >
            Add a new mapping
          </Button>
        </Box>
      </Stack>
      <PublishTransactionsSection
        source={source}
        dataModel={dataModel}
        useFieldArray={useFieldArray}
      />
    </Stack>
  );
}
