import { useCurrentDataSource } from '../../../sources/context/CurrentDataSourceContext';
import DataMappingSetupSection from '../DataMappingSetupSection';
import SetupConnectionGrid from '../SetupConnectionGrid';
import SalesforceSynchronizationCredentialsSection from './SalesforceSynchronizationCredentialsSection';

export default function SalesforceAppSetup() {
  const { dataSource: source, refetchDataSource } = useCurrentDataSource();
  const isSecretConfigured = source.secretConfigured ?? false;

  return (
    <SetupConnectionGrid>
      <SalesforceSynchronizationCredentialsSection source={source} onComplete={refetchDataSource} />
      <DataMappingSetupSection isDisabled={!isSecretConfigured} />
    </SetupConnectionGrid>
  );
}
