import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { Profile } from '../../../../lib/api-client/profile/model/Profile';

export interface ProfileLocationProps {
  profileLocationList: Profile;
}

function ProfileLocation({ profileLocationList }: ProfileLocationProps) {
  return (
    <Box
      shadow="md"
      w="340px"
      borderRadius="6px"
      border="1px"
      borderColor="gray.100"
      alignItems="start"
      p="4"
    >
      <HStack w="full" justifyContent="space-between">
        <Text fontWeight="bold" fontSize="xl" whiteSpace="nowrap">
          Locations
        </Text>
        <Text fontWeight="semibold" fontSize="sm" whiteSpace="nowrap" color="gray.600">
          {profileLocationList?.transactionLocations.length} total
        </Text>
      </HStack>
      <VStack alignItems="start" spacing={2} mt={2}>
        {profileLocationList?.transactionLocations?.map((item) => (
          <Text key={item.locationName} fontSize="14px" fontWeight="400" color="gray.800">
            {item.locationName}
          </Text>
        ))}
      </VStack>
    </Box>
  );
}
export default ProfileLocation;
