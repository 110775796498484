const InputStyle = {
  sizes: {
    sm: {
      field: {
        rounded: 'md',
      },
    },
  },
  variants: {
    outline: {
      field: {
        borderColor: 'gray.300',
        _hover: {
          borderColor: 'gray.400',
        },
        _focus: { borderColor: 'action' },
        _invalid: {
          borderColor: 'error',
        },
        _placeholder: { color: 'gray.400' },
        textColor: 'gray.800',
      },
    },
  },

  defaultProps: {
    size: 'sm',
    variant: 'outline',
  },
};

export default InputStyle;
