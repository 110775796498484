import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { InferType } from 'yup';
import Alert from '../../../../../components/core/Alert/Alert';
import { DataSource } from '../../../../../lib/api-client/sources/model/DataSource';
import { openOAuthWindow } from '../../../../../lib/utils/iframe-utils';
import { useCurrentDataSource } from '../../../../sources/context/CurrentDataSourceContext';

const manageOauthFormSchema = yup.object({
  systemType: yup
    .mixed<'production' | 'sandbox'>()
    .oneOf(['production', 'sandbox'])
    .required('System type required'),
});

type ManageOAuthForm = InferType<typeof manageOauthFormSchema>;

type ManageOAuthModalProps = {
  source: Pick<DataSource, 'id' | 'properties' | 'secretConfigured'>;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

function ManageOAuthModal({ source, isOpen, onClose, onSuccess }: ManageOAuthModalProps) {
  const [error, setError] = useState(false);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ManageOAuthForm>({
    resolver: yupResolver(manageOauthFormSchema),
    mode: 'onBlur',
    defaultValues: {
      systemType: 'production',
    },
  });

  const onSubmit: SubmitHandler<ManageOAuthForm> = async (data) => {
    setError(false);
    try {
      const registrationId =
        data.systemType === 'sandbox' ? 'salesforce-sandbox' : 'salesforce-production';

      await openOAuthWindow(registrationId, source.id, () => {
        reset();
        onClose();
        onSuccess();
      });
    } catch (err) {
      setError(true);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        reset();
        onClose();
      }}
      isCentered
      closeOnOverlayClick={!isSubmitting}
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>
            {source.secretConfigured ? 'Update' : 'Add'} synchronization credentials
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={6} w="320px">
              <FormControl isInvalid={!!errors.systemType}>
                <FormLabel>System type</FormLabel>
                <Select {...register('systemType')}>
                  <option value="production">Production</option>
                  <option value="sandbox">Sandbox</option>
                </Select>
                <FormErrorMessage>{errors.systemType?.message}</FormErrorMessage>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter justifyContent="start">
            <Box>
              <Button isLoading={isSubmitting} type="submit">
                Continue
              </Button>
            </Box>
            {error && (
              <Alert
                ml={6}
                width="full"
                status="error"
                description={`There was a problem ${
                  source.secretConfigured ? 'updating' : 'adding'
                } the credentials.`}
              />
            )}
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export default function ManageSalesforceConfigurationButton() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { dataSource: source, refetchDataSource } = useCurrentDataSource();
  return (
    <>
      <Button variant="solid" w="full" onClick={onOpen}>
        Manage configuration
      </Button>
      <ManageOAuthModal
        source={source}
        isOpen={isOpen}
        onClose={onClose}
        onSuccess={() => refetchDataSource()}
      />
    </>
  );
}
