import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Transition, usePrompt } from '../../../../hooks/use-prompt';

interface UnsavedChangesModalProps {
  warnOnNavigation?: boolean;
  message: string;
}

function UnsavedChangesModal({ message, warnOnNavigation = true }: UnsavedChangesModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [transition, setTransition] = useState<Transition>();
  const [bypassWarnOnNavigation, setBypassWarnOnNavigation] = useState(false);

  usePrompt((tx: Transition) => {
    setTransition(tx);
    onOpen();
  }, warnOnNavigation && !bypassWarnOnNavigation);

  useEffect(() => {
    if (!warnOnNavigation || bypassWarnOnNavigation) {
      transition?.retry();
    }
  }, [warnOnNavigation, bypassWarnOnNavigation, transition]);

  return (
    <Modal isCentered size="sm" closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Wait! You have unsaved changes</ModalHeader>
        <ModalCloseButton top={3} />
        <ModalBody pb={6} data-testid="UnsavedChangesModal">
          {message}
        </ModalBody>
        <ModalFooter justifyContent="start">
          <Button
            onClick={() => {
              setTransition(undefined);
              onClose();
            }}
            mr={3}
            autoFocus
          >
            Go back to save
          </Button>
          <Button
            onClick={() => {
              setBypassWarnOnNavigation(true);
              onClose();
            }}
            variant="outline"
          >
            Don’t save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
export default UnsavedChangesModal;
