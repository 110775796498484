import { useEffect } from 'react';
import { useSampleData } from '../../../lib/api-client/sources/SourceData';
import { useTestIngestionResult } from './TestIngestionResultContext';

// eslint-disable-next-line import/prefer-default-export
export function useTestSample(sourceId: string, sampleId?: string): void {
  const { testIngestionResult, setTestIngestionResult } = useTestIngestionResult();
  const { data: sampleData, error } = useSampleData(
    sourceId,
    sampleId,
    testIngestionResult.isTestIngestionRunning ? 5_000 : 0
  );
  const sampleStatus = sampleData?.summary.status ?? '';
  const isTestIngestionFailure = sampleStatus === 'failed' || error != null;
  const isTestIngestionCompleted = sampleStatus.includes('completed');

  useEffect(() => {
    setTestIngestionResult({
      isTestIngestionRunning:
        sampleId != null && !isTestIngestionFailure && !isTestIngestionCompleted,
      isTestIngestionFailure,
      sampleData,
      sampleId,
    });
  }, [
    isTestIngestionFailure,
    isTestIngestionCompleted,
    setTestIngestionResult,
    sampleData,
    sampleId,
  ]);
}
