import { chakra, Tag, TagLabel, Tooltip } from '@chakra-ui/react';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';

function getProfileType(pinType: any, labels: string[]) {
  if (!pinType) {
    return { label: '', colorScheme: '' };
  }

  if (pinType === 'person') {
    return {
      label: labels[0],
      icon: IconImage.user,
      message: 'This profile represents an individual consumer',
    };
  }

  if (pinType === 'unknown') {
    return {
      label: labels[1],
      icon: IconImage.unknown,
      message: 'We are unable to identify this profile type using the identity data on record.',
    };
  }

  if (pinType === 'household') {
    return {
      label: labels[2],
      icon: IconImage.household,
      message: 'This profile represents a household',
    };
  }

  if (pinType === 'business') {
    return {
      label: labels[3],
      icon: IconImage.business,
      message: 'This profile represents a business',
    };
  }

  return {
    label: labels[0],
    icon: IconImage.business,
    message: 'This profile represents a business',
  };
}

export default function ProfileType({
  pinType,
  variant = 'solid',
  labels = ['Individual consumer', 'Unknown', 'Household', 'Business'],
}: {
  pinType: any;
  variant?: string;
  labels?: string[];
}) {
  if (labels.length !== 4) {
    throw new Error('Must have 3 labels');
  }
  const { label, colorScheme, icon, message } = getProfileType(pinType, labels);

  return (
    <Tooltip hasArrow placement="top" label={message}>
      <Tag
        borderRadius="full"
        colorScheme="highlightScheme"
        borderColor={`${colorScheme}.500`}
        bg={variant === 'solid' ? undefined : 'none'}
        fontSize="xs"
        py={0.5}
        px={2}
        pe={2}
        minH={5}
      >
        {icon && <Icon boxSize={4} iconImage={icon} mr={0.5} />}
        <TagLabel>
          <chakra.span fontWeight="normal">{label}</chakra.span>
        </TagLabel>
      </Tag>
    </Tooltip>
  );
}
