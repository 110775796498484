import { useFetch, useSchema } from '@hazae41/xswr';
import { getApiClientSchema } from '../ApiClient';
import { DataModel } from './data-model.model';

export function getDataModel() {
  return getApiClientSchema<DataModel>({
    method: 'get',
    url: `/data-model`,
  });
}

export function useDataModel() {
  const query = useSchema(getDataModel, []);
  useFetch(query);
  return query;
}
