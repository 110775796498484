export enum CustomerValueRank {
  VeryHigh = 5,
  High = 4,
  Medium = 3,
  Low = 2,
  VeryLow = 1,
  None = 0,
}

export interface CustomerValue {
  averageAnnualSpend: number;
  currency: string;
  ranking: CustomerValueRank;
  rankingLabel: string;
  pin: string;
  currentAnnualSpend: number;
  totalValue: number;
}
