import { Identity, normalizeIdentity } from './Identity';

export interface SearchIdentityResponse {
  status: 'success' | 'too_many';
  results: Identity[];
  totalResults?: number;
}

/**
 * Normalize identity results
 * @param results
 */
export function normalizeSearchIdentityResults(results: Identity[]): Identity[] {
  if (!results) {
    return results;
  }

  return results.map(normalizeIdentity);
}
