import {
  Box,
  Button,
  chakra,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import DataSourceIcon from '../../../components/features/connections/DataSourceIcon/DataSourceIcon';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import { Can } from '../../../context/AuthorizationContext';
import { useToast } from '../../../hooks/use-toast';
import SourceClient from '../../../lib/api-client/sources/SourceClient';
import { useExternalDataModel, useMergeRule } from '../../../lib/api-client/sources/SourceData';
import {
  AttributeMergeRule,
  mergeRuleLabels,
  PrimaryRecordMergeRule,
  SourceMergeRules,
} from '../../../lib/api-client/sources/model/MergeRules';
import { useCurrentDataSource } from '../context/CurrentDataSourceContext';

const formSchema = yup.object({
  primaryRecordRule: yup.string().required('Primary data record rule is required'),
  attributeRules: yup.array(
    yup.object({
      attributeName: yup.string().required('Data field is required'),
      rule: yup.string().required('Merge rule is required'),
    })
  ),
});

const DEFAULT_AUTO_SIMILARITY_THRESHOLD = 1;

export default function MergeRulesPage() {
  const { id = '' } = useParams();
  const { dataSource } = useCurrentDataSource();
  const toast = useToast();
  const confirmationDisclosure = useDisclosure();
  const [sliderValue, setSliderValue] = useState(
    dataSource.properties?.autoMergeSimilarityThreshold != null
      ? +dataSource.properties.autoMergeSimilarityThreshold
      : DEFAULT_AUTO_SIMILARITY_THRESHOLD
  );
  const { data: externalDataModel, loading: isExternalDataModelReady } = useExternalDataModel(
    dataSource.id,
    'IDENTITY'
  );
  const { data: mergeRules, loading: isMergeRulesLoading, update } = useMergeRule(dataSource.id);
  const isLoading = isMergeRulesLoading || isExternalDataModelReady;
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<SourceMergeRules>({
    mode: 'onBlur',
    resolver: yupResolver(formSchema),
  });
  const { fields, append, remove } = useFieldArray({ control, name: 'attributeRules' });

  useEffect(() => {
    if (!isLoading && externalDataModel) {
      reset({
        primaryRecordRule: mergeRules?.primaryRecordRule,
        attributeRules: mergeRules?.attributeRules,
      });
    }
  }, [mergeRules, reset, isLoading, externalDataModel]);

  const onSubmit = useCallback(
    async (data: SourceMergeRules) => {
      // eslint-disable-next-line require-yield,func-names
      await update(async function* () {
        await SourceClient.updateSourceMergeRules(dataSource.id, data);
        await SourceClient.updateDataSourceProperties(id, {
          autoMergeSimilarityThreshold: sliderValue.toString(),
        });
        toast({ status: 'success', description: 'Merge rules saved successfully!' });
      });
    },

    [update, dataSource.id, id, sliderValue, toast]
  );

  const handleChangeSlider = async (val: any) => {
    setSliderValue(val);
  };

  return (
    <PageLayout
      pageViewEvent={{ page: 'Merge Rules Page' }}
      data-testid="MergeRulesPage"
      loading={isLoading}
      header={
        <Header
          title={`${dataSource.name} merge rules configuration`}
          icon={<DataSourceIcon sourceSystem={dataSource.sourceSystem} />}
          back={{
            label: 'Back to connection management',
            to: `/sources/${dataSource.id}/manage`,
          }}
        />
      }
    >
      <chakra.form onSubmit={handleSubmit(onSubmit)}>
        <Text fontSize="sm" pb={4}>
          Use this form to configure the rules for merging duplicate data records in this source
          system.
        </Text>
        <Stack spacing={6}>
          <Can I="manage" a="AdminConfig">
            <SectionContainer
              data-testid="SimilarityScoreThreshold"
              title="Similarity score threshold"
            >
              <Text fontSize="sm" pb={4}>
                This rule determines which duplicate data records are auto-merged based on their
                similarity score.
              </Text>
              <SimpleGrid columns={3} gap={4} mt={4}>
                <GridItem>
                  <FormControl>
                    <Slider
                      aria-label="similarity-slider"
                      colorScheme="actionScheme"
                      onChange={handleChangeSlider}
                      value={sliderValue}
                      step={0.01}
                      max={1}
                      min={0}
                      ml={2}
                    >
                      <SliderMark
                        data-testid="SliderMark"
                        value={sliderValue}
                        textAlign="center"
                        fontSize="sm"
                        fontWeight="bold"
                        bg="action"
                        borderRadius="md"
                        color="white"
                        mt="-9"
                        ml="-5"
                        w="10"
                      >
                        {sliderValue}
                      </SliderMark>
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb />
                    </Slider>
                  </FormControl>
                </GridItem>
              </SimpleGrid>
            </SectionContainer>
          </Can>
          <SectionContainer title="Primary data record">
            <Text fontSize="sm" pb={4}>
              This rule determines which of the duplicate data records becomes the primary, and
              which are archived.
            </Text>
            <SimpleGrid columns={3} gap={4}>
              <GridItem>
                <FormControl>
                  <Select {...register('primaryRecordRule')}>
                    {Object.values(PrimaryRecordMergeRule).map((value) => (
                      <option key={`primary-${value}`} value={value}>
                        {mergeRuleLabels(value)}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
            </SimpleGrid>
          </SectionContainer>
          <SectionContainer title="Data field values">
            <Text fontSize="sm" pb={4}>
              These rules override the primary data record at a data field level. They are also
              applied if the data field is blank in the primary data record.
            </Text>
            <SimpleGrid columns={3} gap={4} pb={4}>
              {fields.map((field, index) => (
                <Fragment key={field.id}>
                  <FormControl isInvalid={!!errors?.attributeRules?.[index]?.attributeName}>
                    <FormLabel>Data field</FormLabel>
                    <Select
                      placeholder="Select one"
                      {...register(`attributeRules.${index}.attributeName`)}
                    >
                      {externalDataModel?.attributes
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map((a) => (
                          <option key={`${field.id}-${a.name}`} value={a.name}>
                            {a.label}
                          </option>
                        ))}
                    </Select>
                    <FormErrorMessage>
                      {errors?.attributeRules?.[index]?.attributeName?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors?.attributeRules?.[index]?.rule}>
                    <FormLabel>Merge rule</FormLabel>
                    <Select placeholder="Select one" {...register(`attributeRules.${index}.rule`)}>
                      {Object.values(AttributeMergeRule).map((value) => (
                        <option key={`${field.id}-${value}`} value={value}>
                          {mergeRuleLabels(value)}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      {errors?.attributeRules?.[index]?.rule?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <GridItem alignSelf="end">
                    <IconButton
                      aria-label="Remove rule"
                      variant="outline"
                      icon={<Icon iconImage={IconImage.delete} />}
                      onClick={() => remove(index)}
                    />
                  </GridItem>

                  <GridItem colSpan={2}>
                    <Divider />
                  </GridItem>
                  <GridItem>
                    <Divider borderColor="white" />
                  </GridItem>
                </Fragment>
              ))}
            </SimpleGrid>
            <Button
              onClick={() => append({ attributeName: '', rule: '' as any })}
              variant="ghost"
              rightIcon={<Icon boxSize={4} iconImage={IconImage.add} />}
              type="button"
            >
              {fields.length > 0 ? 'Add another data field' : 'Add a data field'}
            </Button>
          </SectionContainer>
          <Box>
            <Button onClick={confirmationDisclosure.onOpen} isDisabled={!isValid}>
              Save merge rules
            </Button>
            <Modal
              size="sm"
              isOpen={confirmationDisclosure.isOpen}
              onClose={confirmationDisclosure.onClose}
              isCentered
            >
              <ModalOverlay />
              <ModalCloseButton />
              <ModalContent>
                <ModalHeader>
                  Are you sure you want to save these changes to the merge rules?
                </ModalHeader>
                <ModalBody>
                  <Text fontSize="sm">
                    The new configuration will be applied to all data record merges in this source
                    system moving forward.
                  </Text>
                </ModalBody>
                <ModalFooter justifyContent="start">
                  <HStack>
                    <Button
                      onClick={() => {
                        handleSubmit(onSubmit)();
                        confirmationDisclosure.onClose();
                      }}
                    >
                      Yes, save
                    </Button>
                    <Button variant="outline" onClick={confirmationDisclosure.onClose}>
                      No, cancel
                    </Button>
                  </HStack>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Box>
        </Stack>
      </chakra.form>
    </PageLayout>
  );
}
