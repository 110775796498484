import {
  Button,
  ButtonGroup,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  useDisclosure,
} from '@chakra-ui/react';
import Drawer from '../../../../../components/shared/modals/Drawer/Drawer';
import {
  useGetSourceSystemConfigAttributes,
  useGetSourceSystemType,
} from '../../../../../lib/api-client/sources/SourceData';
import { useCurrentDataSource } from '../../../../sources/context/CurrentDataSourceContext';
import ConfigAttributeForm, { ConfigAttributeFormContext } from './CredentialConfigForm';

export default function ManageSourceConfigAttributesButton() {
  const { dataSource } = useCurrentDataSource();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { id: sourceId, sourceSystem = '' } = dataSource;
  const { data: sourceSystemTypeData } = useGetSourceSystemType(sourceSystem);
  const { data: configAttributesData = [], refetch: refetchConfigAttributes } =
    useGetSourceSystemConfigAttributes(sourceId);
  const button = { variant: 'solid', label: 'Manage configuration' };
  const configAttributeConfig = sourceSystemTypeData?.configAttributes ?? [];

  const defaultConfigAttributes = configAttributeConfig.map((configAttribute) => {
    const found = configAttributesData.find((c) => c.name === configAttribute.name);
    return found ?? { name: configAttribute.name, value: '' };
  });

  return (
    <>
      <Button onClick={onOpen} w="full" variant="solid">
        {button.label}
      </Button>
      <Drawer isOpen={isOpen} onClose={onClose}>
        <ConfigAttributeFormContext
          sourceId={sourceId}
          configAttributes={defaultConfigAttributes}
          configAttributesConfig={configAttributeConfig}
          onSuccess={async () => {
            await refetchConfigAttributes();
            onClose();
          }}
        >
          <DrawerContent>
            <DrawerHeader>Connection configuration</DrawerHeader>
            <DrawerBody>
              <ConfigAttributeForm configAttributesConfig={configAttributeConfig} />
            </DrawerBody>
            <DrawerFooter>
              <ButtonGroup w="full">
                <Button w="full" variant="outline" onClick={onClose} type="button">
                  Cancel
                </Button>
                <Button w="full" type="submit">
                  Save
                </Button>
              </ButtonGroup>
            </DrawerFooter>
          </DrawerContent>
        </ConfigAttributeFormContext>
      </Drawer>
    </>
  );
}
