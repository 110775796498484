import {
  Box,
  Button,
  chakra,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react';
import consola from 'consola';
import React, { useState } from 'react';
import { FaCircle } from 'react-icons/fa';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import { useToast } from '../../../../hooks/use-toast';
import SourceClient from '../../../../lib/api-client/sources/SourceClient';
import { useCurrentDataSource } from '../../../sources/context/CurrentDataSourceContext';

const ChakraFaCircle = chakra(FaCircle);

type ExportStatus = 'enabled' | 'test' | 'disabled' | 'pinOnly';

interface ExportStatusButtonProps {
  status?: ExportStatus;
  sourceId: string;
}

export default function ExportStatusButton({
  status: statusProp = 'disabled',
  sourceId,
}: ExportStatusButtonProps) {
  const [status, setStatus] = useState(statusProp);
  const toast = useToast();
  const { exportStatus } = useCurrentDataSource();

  let statusMessage;
  let statusLabel;
  let statusColor;
  switch (status) {
    case 'enabled':
      statusColor = 'success';
      statusMessage = 'Publish events are streaming in real-time.';
      statusLabel = 'Enabled';
      break;
    case 'test':
      statusColor = 'warning';
      statusMessage = 'Data can be manually published from a profile.';
      statusLabel = 'Test';
      break;
    case 'pinOnly':
      statusColor = 'success';
      statusMessage = 'PIN field updates are streaming in real-time.';
      statusLabel = 'PIN only';
      break;
    default:
      statusColor = 'gray.600';
      statusMessage = 'Publish events are not currently streaming.';
      statusLabel = 'Disabled';
  }

  const updateStatus = async (newStatus: ExportStatus) => {
    try {
      await SourceClient.updateDataSourceProperties(sourceId, { exportMode: newStatus });
      setStatus(newStatus);
    } catch (err) {
      consola.error(`Error updating source ${sourceId} status`, err);
      toast({ status: 'error', description: 'Error updating publish status' });
    }
  };

  let isError = false;
  if (exportStatus?.exportState === 'DEGRADED') {
    statusColor = 'warning';
    statusMessage = 'Queued events are being published.';
    statusLabel = 'Enabled';
  } else if (exportStatus?.exportState === 'FAILED') {
    isError = true;
    statusColor = 'error';
    statusMessage = 'A publish error was detected.';
    statusLabel = 'Enabled';
  }

  return (
    <Stack spacing="2">
      <HStack justify="space-between">
        <HStack fontSize="sm" spacing={1}>
          <ChakraFaCircle color={statusColor} />
          <chakra.span fontWeight="semibold">{statusLabel}</chakra.span>
        </HStack>
        <Box>
          <Menu>
            <MenuButton
              as={Button}
              variant="ghost"
              colorScheme="action"
              rightIcon={<Icon iconImage={IconImage.chevronDown} />}
            >
              Edit
            </MenuButton>
            <MenuList
              fontSize="sm"
              fontWeight="light"
              minW="100%"
              bg="gray.50"
              border="1px solid"
              borderColor="gray.300"
            >
              <MenuItem
                isDisabled={status === 'enabled'}
                _hover={{ color: 'action', bg: 'none' }}
                _disabled={{
                  fontWeight: 'semibold',
                  cursor: 'not-allowed',
                  _hover: { color: 'gray.800' },
                }}
                onClick={() => updateStatus('enabled')}
              >
                Enabled
              </MenuItem>
              <MenuItem
                isDisabled={status === 'test'}
                _hover={{ color: 'action', bg: 'none' }}
                _disabled={{
                  fontWeight: 'semibold',
                  cursor: 'not-allowed',
                  _hover: { color: 'gray.800' },
                }}
                onClick={() => updateStatus('test')}
              >
                Test
              </MenuItem>
              <MenuItem
                isDisabled={status === 'disabled'}
                _hover={{ color: 'action', bg: 'none' }}
                _disabled={{
                  fontWeight: 'semibold',
                  cursor: 'not-allowed',
                  _hover: { color: 'gray.800' },
                }}
                onClick={() => updateStatus('disabled')}
              >
                Disabled
              </MenuItem>
              <MenuItem
                isDisabled={status === 'pinOnly'}
                _hover={{ color: 'action', bg: 'none' }}
                _disabled={{
                  fontWeight: 'semibold',
                  cursor: 'not-allowed',
                  _hover: { color: 'gray.800' },
                }}
                onClick={() => updateStatus('pinOnly')}
              >
                PIN only
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </HStack>
      <Text fontSize="sm" color={isError ? statusColor : undefined}>
        {statusMessage}
      </Text>
    </Stack>
  );
}
