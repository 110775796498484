import { Box, Text, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';
import { DataSource } from '../../../../lib/api-client/sources/model/DataSource';
import CompletenessCheckmark from '../../shared/CompletenessCheckmark/CompletenessCheckmark';
import ManageSynchronizationCredentialsButton from './ManageSynchronizationCredentialsButton';

interface SalesforceSynchronizationCredentialsSectionProps {
  source: DataSource;
  onComplete: () => void;
}

export default function SalesforceSynchronizationCredentialsSection({
  source,
  onComplete,
}: SalesforceSynchronizationCredentialsSectionProps) {
  const [isSecretConfigured, setSecretConfigured] = useState<boolean>(
    source.secretConfigured ?? false
  );

  return (
    <SectionContainer
      variant="box"
      title="Synchronization credentials"
      borderRadius="6px"
      header={<CompletenessCheckmark isComplete={isSecretConfigured} />}
      data-testid="SalesforceSynchronizationCredentialsSection"
    >
      <VStack alignItems="start">
        <Text pb={4}>
          Add credentials to allow harpin AI access to perform updates on this system.
        </Text>
        <Box>
          <ManageSynchronizationCredentialsButton
            source={source}
            onSecretsSuccess={() => {
              setSecretConfigured(true);
              onComplete();
            }}
          />
        </Box>
      </VStack>
    </SectionContainer>
  );
}
