import { parseISO } from 'date-fns';
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import config from '../config';
import { NewPasswordChallengeRequest } from '../lib/api-client/authentication/model/NewPasswordChallengeRequest';
import { SignInRequest } from '../lib/api-client/authentication/model/SignInRequest';
import { SignInResult } from '../lib/api-client/authentication/model/SignInResult';
import { UserGroup } from '../lib/api-client/authentication/model/UserGroup';
import { useSystemIdentitySourceMetrics } from '../lib/api-client/source-metrics/IdentitySourceMetricsClient';
import TenantClient from '../lib/api-client/tenant/TenantClient';
import { Tenant, TenantProperties } from '../lib/api-client/tenant/model/Tenant';
import AuthenticationService from '../lib/services/AuthenticationService';
import LocalStorageService from '../lib/services/LocalStorageService';

const TICK_STORAGE_KEY = 'seviin.tick';

export interface CurrentUser {
  displayName: string;
  emailAddress: string;
  groups: UserGroup[];
}

export interface AuthenticationContext {
  signIn: (signInRequest: SignInRequest) => Promise<SignInResult>;
  signOut: () => Promise<void>;
  sendMFACode: (answerChallenge: string) => Promise<SignInResult>;
  handleNewPasswordChallenge: (request: NewPasswordChallengeRequest) => Promise<SignInResult>;
  currentUser?: CurrentUser;
  tenantName?: string;
  tenantId?: string;
  tenant?: Tenant;
  loyaltyData?: TenantProperties;
  isAuthenticated: boolean;
  verifySession: () => void;
  isTimeout: boolean;
  token?: string;
}

const Context = createContext<AuthenticationContext>({
  isAuthenticated: false,
  isTimeout: false,
} as AuthenticationContext);

function isIdleTimeout(tick: Date) {
  return Date.now() - tick.getTime() > config.get().SESSION_TIMEOUT_SECS * 1000;
}

interface AuthenticationProviderProps {
  children: ReactNode;
}

export function AuthenticationProvider({ children }: AuthenticationProviderProps) {
  const [currentUser, setCurrentUser] = useState<CurrentUser>();
  const [token, setToken] = useState('');
  const [tenantName, setTenantName] = useState<string>('');
  const [tenantId, setTenantId] = useState<string>('');
  const [tenant, setTenant] = useState<Tenant>();
  const [isTimeout, setTimeout] = useState(false);
  const [loyaltyData, setLoyaltyData] = useState<TenantProperties>();
  const [isInitialLoadFinished, setIsInitialLoadFinished] = useState<boolean>(false);
  const [tick, setTick] = useState<Date>();
  const { data: useIdentityMetricsData } = useSystemIdentitySourceMetrics(!!tenant);
  const idleTimer = useIdleTimer({
    timeout: config.get().SESSION_TIMEOUT_SECS * 1000,
    crossTab: config.get().SESSION_CROSS_TAB,
    stopOnIdle: true,
    startManually: true,
    syncTimers: 1000, // sync every 1s
    onIdle: () => {
      setTimeout(true);
    },
    onActive: () => {
      const now = new Date();
      if (tick && isIdleTimeout(tick)) {
        idleTimer.pause();
        setTimeout(true);
      } else {
        setTick(now);
        LocalStorageService.setItem(TICK_STORAGE_KEY, now.toISOString());
      }
    },
  });

  const memo = useMemo(() => {
    const signOut = () =>
      AuthenticationService.signOut().then(() => {
        setTick(undefined);
        LocalStorageService.removeItem(TICK_STORAGE_KEY);
        // FIXME not fond of sprinking this logic everywhere
        if (useIdentityMetricsData?.lastBatchResolution) {
          LocalStorageService.setItem(
            `seviin.${tenantId}.batch-resolution-alert-dismissed`,
            `${parseISO(useIdentityMetricsData.lastBatchResolution).getTime()}`
          );
        }
        setCurrentUser(undefined);
      });

    function onSignInSuccess(signInResult: SignInResult) {
      if (signInResult.result === 'success') {
        setTimeout(false);
        idleTimer.start();

        return TenantClient.getTenant(signInResult.tenantId).then((t) => {
          setToken(signInResult.token);
          setCurrentUser({
            displayName: signInResult.displayName,
            emailAddress: signInResult.emailAddress,
            groups: signInResult.groups,
          });
          setTenantName(t.name);
          setTenantId(t.id);
          setLoyaltyData(t.properties);
          setTenant(t);
          return signInResult as SignInResult;
        });
      }

      return Promise.resolve(signInResult);
    }

    const signIn = (signInRequest: SignInRequest): Promise<SignInResult> =>
      AuthenticationService.signIn(signInRequest).then(onSignInSuccess);

    const handleNewPasswordChallenge = (request: NewPasswordChallengeRequest) =>
      AuthenticationService.handleNewPasswordChallenge(request).then(onSignInSuccess);

    const sendMFACode = (code: string) =>
      AuthenticationService.sendMFACode(code).then(onSignInSuccess);

    const verifySession = () => {
      AuthenticationService.verifySession()
        .then(onSignInSuccess)
        .catch(() => {
          // ignore
        })
        .finally(() => {
          setIsInitialLoadFinished(true);
        });
    };

    return {
      signIn,
      signOut,
      handleNewPasswordChallenge,
      sendMFACode,
      verifySession,
      currentUser,
      tenantName,
      tenantId,
      loyaltyData,
      isAuthenticated: !!currentUser,
      isTimeout,
      tenant,
      token,
    };
  }, [
    currentUser,
    tenantName,
    tenantId,
    loyaltyData,
    isTimeout,
    tenant,
    idleTimer,
    useIdentityMetricsData?.lastBatchResolution,
    token,
  ]);

  useEffect(() => {
    const tickFromStorage = LocalStorageService.getItem(TICK_STORAGE_KEY);
    if (tickFromStorage && isIdleTimeout(new Date(tickFromStorage))) {
      setTimeout(true);
      setIsInitialLoadFinished(true);
      return;
    }

    memo.verifySession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isTimeout) {
      void memo.signOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimeout]);

  return <Context.Provider value={memo}>{isInitialLoadFinished && children}</Context.Provider>;
}

export function useAuth(): AuthenticationContext {
  return useContext(Context);
}
