import {
  Button,
  ButtonGroup,
  Checkbox,
  DrawerBody,
  DrawerFooter,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import Mixpanel from '../../../../lib/mixpanel/Mixpanel';
import Drawer from '../../modals/Drawer/Drawer';
import { useDataExplorer } from './DataExplorerContext';

export default function DataExplorerColumnDrawer() {
  const { table } = useDataExplorer();
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Button
        onClick={() => {
          onOpen();
          Mixpanel.track('Column Preferences');
        }}
      >
        Columns
      </Button>
      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerBody>
          <Text fontWeight="bold" fontSize="xl" mt={4}>
            Edit columns
          </Text>
          <Text fontSize="sm">Customize which columns to show and hide in the table.</Text>
          <Stack spacing={3} pt={3}>
            {table
              .getAllLeafColumns()
              .filter((column) => !(column.columnDef.meta as any)?.emptyDisplay)
              .map((column) => (
                <Checkbox
                  key={column.id}
                  isChecked={column.getIsVisible()}
                  onChange={column.getToggleVisibilityHandler()}
                  colorScheme="actionScheme"
                >
                  {column.columnDef.header as string}
                </Checkbox>
              ))}
          </Stack>
        </DrawerBody>
        <DrawerFooter borderTop="1px solid" borderColor="gray.100">
          <ButtonGroup w="full">
            <Button w="full" onClick={onClose}>
              Save columns
            </Button>
            <Button
              w="full"
              variant="outline"
              onClick={() => table.toggleAllColumnsVisible()}
              disabled={table.getIsAllColumnsVisible()}
            >
              Reset columns
            </Button>
          </ButtonGroup>
        </DrawerFooter>
      </Drawer>
    </>
  );
}
