import { Box, Button, Flex, HStack, Tag, TagLabel, Text, VStack, chakra } from '@chakra-ui/react';
import { useFlags } from 'flagsmith/react';
import { Link, To } from 'react-router-dom';
import { FeatureFlags } from '../../../lib/model/FeatureFlags';
import { formatNumber } from '../../../lib/utils/number-utils';
import DataIssueFluctuationChip from '../../../pages/sources/components/DataFluctuationChip/DataIssueFluctuationChip';
import Icon from '../Icon/Icon';
import { IconImage } from '../Icon/IconConfig';

export function calculateAndRound(value1: number, value2: number) {
  const result = ((value1 ?? 0) / (value2 ?? 0)) * 100;
  const roundedResult = Number.isFinite(result) ? Math.round(result) : 0;
  return roundedResult;
}

export interface SourceSystemCardProps {
  title?: string;
  issuePercentage: number;
  totalRecords: number;
  to: To;
  priority?: boolean;
  onClick: () => void;
  issue?: any;
  totalDuplicates?: number;
}
export default function SystemDashboardCard({
  title,
  issuePercentage,
  totalRecords,
  to,
  priority,
  issue,
  totalDuplicates,
  onClick,
}: SourceSystemCardProps) {
  const flags = useFlags([FeatureFlags.SHOW_DUPLICATE_MONITOR_TRAY]);
  return (
    <Flex
      direction="column"
      position="relative"
      gridColumn={title === 'Duplicates' ? 'span 1' : 'span 0'}
      gridRow={title === 'Duplicates' ? 'span 2' : 'span 0'}
    >
      <Box
        shadow="md"
        w="full"
        borderRadius="6px"
        border="1px"
        borderColor={priority ? 'error' : 'gray.100'}
        py="4"
        px="4"
        position="relative"
      >
        <Flex mb={2} />
        <Text fontWeight="700" fontSize="14" textColor="gray.800" mb={1}>
          {title}
        </Text>
        <VStack alignItems="start" spacing={0} mb={2}>
          <HStack>
            <Text fontWeight="bold" fontSize="xl" textColor="gray.800">
              {formatNumber(totalRecords)}
            </Text>
            <DataIssueFluctuationChip value={issuePercentage} />
          </HStack>
          <Text>total records</Text>
        </VStack>
        {priority ? (
          <Button
            leftIcon={<Icon iconImage={IconImage.error} boxSize="4" />}
            colorScheme="errorScheme"
            as={Link}
            to={to}
            w="full"
          >
            Inspect
          </Button>
        ) : (
          <Button w="full" as={Link} to={to}>
            Inspect
          </Button>
        )}

        {title === 'Duplicates' && flags.show_duplicate_monitor_tray.enabled && (
          <>
            <Text fontWeight="semibold" mt={2} fontSize={12} textColor="#4A5568">
              Monthly monitor
            </Text>
            <VStack alignItems="start" spacing={3} mt={2}>
              <VStack alignItems="start" spacing={0}>
                <HStack>
                  <Text fontWeight="bold" fontSize="14">
                    {issue?.value1 ?? 0}
                  </Text>
                  <Tag
                    size="sm"
                    borderRadius="full"
                    variant="solid"
                    bgColor="gray.200"
                    color="gray.800"
                  >
                    <TagLabel fontWeight="semibold">
                      {calculateAndRound(issue.value1 ?? 0, totalDuplicates ?? 0)}% of all records
                    </TagLabel>
                  </Tag>
                </HStack>
                <chakra.span>total in {issue?.label1}</chakra.span>
              </VStack>
              <VStack alignItems="start" spacing={0}>
                <HStack>
                  <Text fontWeight="bold" fontSize="14">
                    {issue?.value0 ?? 0}
                  </Text>
                  <Tag
                    size="sm"
                    borderRadius="full"
                    variant="solid"
                    bgColor="gray.200"
                    color="gray.800"
                  >
                    <TagLabel fontWeight="semibold">
                      {calculateAndRound(issue.value0 ?? 0, totalDuplicates ?? 0)}% of all records
                    </TagLabel>
                  </Tag>
                </HStack>
                <chakra.span>total in {issue?.label0}</chakra.span>
              </VStack>
              <Button w="full" variant="outline" onClick={onClick}>
                Explore duplicates monitor
              </Button>
            </VStack>
          </>
        )}
      </Box>
    </Flex>
  );
}
