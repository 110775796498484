import { useEffect, useRef } from 'react';

export default function OAuthSuccess() {
  const isFinishedRef = useRef(false);
  useEffect(() => {
    if (!isFinishedRef.current) {
      isFinishedRef.current = !isFinishedRef.current;
      window.opener.postMessage({ type: 'seviin.oauth.finish' });
      window.close();
    }
  }, []);

  return <div />;
}
