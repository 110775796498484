import {
  Button,
  chakra,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useDataExplorer } from '../../../../components/shared/data-explorer/DataExplorer/DataExplorerContext';
import DuplicateClient from '../../../../lib/api-client/duplicates/DuplicateClient';
import { FlagNonDuplicateSourceRecords } from '../../../../lib/api-client/duplicates/model/FlagNonDuplicateSourceRecords';
import Mixpanel from '../../../../lib/mixpanel/Mixpanel';
import { useCurrentDataSource } from '../../context/CurrentDataSourceContext';

interface SplitDuplicatesFooterActionProps {
  onDupesFlagged: () => Promise<void>;
}

export default function SplitDuplicatesFooterAction({
  onDupesFlagged,
}: SplitDuplicatesFooterActionProps) {
  const { dataSource } = useCurrentDataSource();
  const { table } = useDataExplorer();

  const confirmSplitDisclosure = useDisclosure();
  const [splitSelections, setSplitSelections] = useState<FlagNonDuplicateSourceRecords[]>();

  const flagNonDuplicateSourceRecords = async () => {
    const dupes = table.getSelectedRowModel().flatRows.map((r) => ({
      decision: 'SPLIT',
      sourceId: dataSource.id,
      sourceRecordId: (r.original as any).identityRecord.sourceRecordId as string,
    }));
    setSplitSelections(dupes);
    confirmSplitDisclosure.onOpen();
  };

  if (!table.getVisibleFlatColumns().find((column) => column.id === 'select')) {
    return null;
  }

  return (
    <>
      <Flex bg="action" p={3} justify="space-between" alignItems="center">
        <Text fontSize="sm" w="auto" color="white" whiteSpace="normal">
          <chakra.span fontWeight="bold">Spot false duplicates?</chakra.span> Select false
          duplicates using the checkboxes in the table and remove them from the duplicate group to
          improve this system&apos;s data quality score.
        </Text>
        <Button
          variant="outline"
          color="white"
          borderColor="white"
          _hover={{ color: 'white' }}
          onClick={flagNonDuplicateSourceRecords}
          w="auto"
          whiteSpace="normal"
          flexShrink="0"
        >
          Flag and remove from the group
        </Button>
      </Flex>
      <Modal
        isOpen={confirmSplitDisclosure.isOpen}
        onClose={confirmSplitDisclosure.onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Are you sure you want to flag these data records as false duplicates and remove them
            from their duplicate group?
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              This will not update the original system of record or the current PIN assignment.
              Flagging false duplicates will better inform the machine learning model for future
              identity resolution, and removing these data records from their duplicate group will
              reduce the duplicate calculation for this source system and improve the data quality
              score.
            </Text>
          </ModalBody>
          <ModalFooter justifyContent="start">
            <HStack>
              <Button
                onClick={async () => {
                  if (splitSelections) {
                    await DuplicateClient.flagNonDuplicateSourceRecords(splitSelections);
                    Mixpanel.track('Remove from the group');
                    await onDupesFlagged();
                  }
                  confirmSplitDisclosure.onClose();
                  setSplitSelections(undefined);
                }}
              >
                Flag and remove from group
              </Button>
              <Button
                variant="outline"
                onClick={() => {
                  confirmSplitDisclosure.onClose();
                  Mixpanel.track('Cancel Modal Flag and remove from the group');
                }}
              >
                Cancel
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
