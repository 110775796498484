import { Button, Select, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';
import { Can } from '../../../../context/AuthorizationContext';
import { useToast } from '../../../../hooks/use-toast';
import SourceClient from '../../../../lib/api-client/sources/SourceClient';
import { useGetSourceUploadsOnInterval } from '../../../../lib/api-client/sources/SourceData';
import { Upload } from '../../../../lib/api-client/sources/model/Upload';
import { useCurrentDataSource } from '../../../sources/context/CurrentDataSourceContext';

export function isEventuallyIngestable(upload: Upload) {
  switch (upload.status) {
    case 'created':
    case 'analysisRunning':
    case 'analysisCompleted':
    case 'testImportRunning':
    case 'testImportRequested':
    case 'testImportCompleted':
      return true;
    default:
      return false;
  }
}

interface IngestDataSectionProps {
  uploadId?: string;
}

export default function IngestDataSection({ uploadId: uploadIdProp }: IngestDataSectionProps) {
  const { dataSource } = useCurrentDataSource();
  const toast = useToast();
  const [uploadId, setUploadId] = useState<string>('');
  const [isIngesting, setIngesting] = useState(true);
  const { data: uploadsData } = useGetSourceUploadsOnInterval(
    dataSource.id,
    isIngesting ? 10 * 1000 : 0
  );
  const uploads = uploadsData?.content ?? [];
  const navigate = useNavigate();

  useEffect(() => {
    setUploadId(uploadIdProp ?? '');
  }, [uploadIdProp]);

  return (
    <Can I="update" an="DataSource">
      <SectionContainer title="Ingest data" variant="box" w="full" alignItems="space-between">
        <Stack spacing={4}>
          <Text>
            Select a file to start ingesting data or test the data mappings by running a test
            ingestion first.
          </Text>
          <Select
            placeholder="Select one"
            color={uploadId ? 'gray.800' : 'gray.300'}
            value={uploadId}
            onChange={(e) => setUploadId(e.target.value)}
          >
            {uploads
              .filter((item) => isEventuallyIngestable(item))
              .map((item: Upload) => (
                <option
                  key={item.id}
                  value={item.id}
                  disabled={
                    item.status !== 'analysisCompleted' && item.status !== 'testImportCompleted'
                  }
                >
                  {item.fileName || item.id}
                </option>
              ))}
          </Select>
        </Stack>
        <SimpleGrid columns={2} mt={6} spacingX={3}>
          <Button
            width="full"
            onClick={async () => {
              try {
                await SourceClient.updateSourceUploadStatus(
                  dataSource.id,
                  uploadId,
                  'importRequested'
                );
                toast({
                  status: 'success',
                  title: 'Data ingestion is in progress.',
                });
                setIngesting(true);
              } catch (e) {
                toast({
                  status: 'error',
                  title: 'We were unable to ingest data.',
                  description: 'Please try again.',
                });
              }
            }}
            isDisabled={!uploadId}
          >
            Ingest now
          </Button>
          <Button
            width="full"
            onClick={async () => {
              try {
                await SourceClient.updateSourceUploadStatus(
                  dataSource.id,
                  uploadId,
                  'testImportRequested'
                );
                toast({
                  status: 'success',
                  title: 'Test data ingestion is in progress.',
                });
                navigate(`/sources/${dataSource.id}/test-ingestion/${uploadId}`);
              } catch (e) {
                toast({
                  status: 'error',
                  title: 'We were unable to ingest test data.',
                  description: 'Please try again.',
                });
              }
            }}
            isDisabled={!uploadId}
            variant="outline"
          >
            Run test ingestion
          </Button>
        </SimpleGrid>
      </SectionContainer>
    </Can>
  );
}
