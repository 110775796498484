import { chakra, Tag, TagLabel } from '@chakra-ui/react';
import Icon from '../../../../components/core/Icon/Icon';
import { getRecordScoreIcon } from '../../../../lib/utils/scoring-utils';

export default function ProfileDataRecordScoreBadge({
  score,
  label,
}: {
  score?: Nullable<number>;
  label: string;
}) {
  const icon = getRecordScoreIcon(score);
  return (
    <Tag
      data-testid="ProfileDataRecordScoreBadge"
      borderRadius="full"
      borderWidth="1px"
      borderColor="gray.300"
      bgColor="white"
      fontSize="xs"
      py={0.5}
      px={1}
      pe={1.5}
      minH={5}
    >
      {icon && <Icon boxSize={4} iconImage={icon.icon} color={icon.color} me={1} />}
      <TagLabel>
        <chakra.span fontWeight="normal">{label}</chakra.span>
      </TagLabel>
    </Tag>
  );
}
