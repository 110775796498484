import { useCurrentDataSource } from '../../../sources/context/CurrentDataSourceContext';
import ManageExternalConfigSection from './ManageExternalConfigurationSection/ManageExternalConfigSection';
import ManageSalesforceConfigurationSection from './ManageSalesforceConfigurationSection/ManageSalesforceConfigurationSection';
import ManageSourceConfigAttributesSection from './ManageSourceConfigrationAttributesSection/ManageSourceConfigAttributesSection';

export default function ConnectionConfiguration() {
  const { dataSource } = useCurrentDataSource();

  if (dataSource.requiresExternalConfiguration) {
    return <ManageExternalConfigSection />;
  }

  if (dataSource.sourceSystem === 'salesforceApp') {
    return <ManageSalesforceConfigurationSection />;
  }

  return <ManageSourceConfigAttributesSection />;
}
