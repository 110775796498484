import {
  Box,
  Button,
  Center,
  chakra,
  Divider,
  HStack,
  Spinner,
  Stack,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../../components/core/Header/Header';
import DataSourceIcon from '../../../components/features/connections/DataSourceIcon/DataSourceIcon';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import { useScrollableIngestError } from '../../../lib/api-client/sources/SourceData';
import { ExportIngestError } from '../../../lib/api-client/sources/sources.model';
import { Page } from '../../../lib/model/common/Page';
import { DateDifferenceFormatted } from '../../../lib/utils/date-time-utils';
import { formatNumber } from '../../../lib/utils/number-utils';
import Datepicker from '../PublishAuditLogPage/components/PublishAuditLogItem/DatePicker';
import ConnectionManagementHeader from '../components/ConnectionManagementHeader/ConnectionManagementHeader';
import { useCurrentDataSource } from '../context/CurrentDataSourceContext';
import IngestErrorsItem from './IngestErrorsItem';

type LogFilters = {
  startDate?: string;
  endDate?: string;
};
export default function IngestErrorLogPage() {
  const { id = '' } = useParams();
  const { dataSource } = useCurrentDataSource();
  const [initialLoad, setInitialLoad] = useBoolean(true);
  const [filters, setFilters] = useState<LogFilters>({});
  const { data, loading, scroll } = useScrollableIngestError(id, filters);
  const [lastPage, setLastPage] = useState<Page<ExportIngestError>>();

  useEffect(() => {
    if (initialLoad && !loading) {
      setInitialLoad.toggle();
    }
  }, [initialLoad, setInitialLoad, loading]);

  useEffect(() => {
    if (data != null) {
      setLastPage(data.slice(-1).pop());
    }
  }, [data]);

  const onNext = useCallback(async () => {
    await scroll();
  }, [scroll]);

  const onTimeChange = (value: any) => {
    if (value?.startDate && value?.endDate) {
      const { formattedStartDate, formattedEndDate } = DateDifferenceFormatted(value);

      setFilters((logFilters) => ({
        ...logFilters,
        startDate: formattedStartDate || undefined,
        endDate: formattedEndDate || undefined,
      }));
    } else {
      setFilters((logFilters) => ({
        ...logFilters,
        startDate: undefined,
        endDate: undefined,
      }));
    }
  };

  return (
    <PageLayout
      pageViewEvent={{ page: 'ingestion error log', pageId: id }}
      header={
        <Header
          icon={<DataSourceIcon sourceSystem={dataSource.sourceSystem} />}
          title={`${dataSource.name} ingestion error log`}
          back={{
            label: 'Back to source data dashboard',
            to: `/sources/${dataSource.id}`,
          }}
        >
          <VStack alignItems="start" py={3} px={6} mt={2}>
            <ConnectionManagementHeader source={dataSource} />
            {lastPage && (
              <HStack justifyContent="space-between" w="1060px">
                <Datepicker onTimeChange={onTimeChange} />
                <Box>{loading && <Spinner size="sm" borderColor="action" />}</Box>
                <chakra.span fontSize="xs" fontWeight="semibold" color="gray.600" ml="120px">
                  {`Showing ${lastPage.number * lastPage.size + 1} - ${Math.min(
                    (lastPage.number + 1) * lastPage.size,
                    lastPage.totalElements
                  )} of ${formatNumber(lastPage.totalElements)} `}
                </chakra.span>
              </HStack>
            )}
          </VStack>
        </Header>
      }
      loading={initialLoad}
    >
      {data && (
        <Stack divider={<Divider />} spacing={3}>
          {data
            .flatMap((page) => page.content)
            .map((item) => (
              <IngestErrorsItem
                key={`error-records-list-${item.recordId}`}
                errors={item}
                externalSourceLink={
                  item.sourceId
                    ? dataSource.externalLinks?.sourceIdentity?.replace('{id}', item.sourceId)
                    : undefined
                }
              />
            ))}
          {!lastPage?.last && (
            <Center>
              <Button isLoading={loading} onClick={onNext}>
                See next{' '}
                {lastPage
                  ? Math.min(100, lastPage.totalElements - (lastPage.number + 1) * lastPage.size)
                  : ''}
              </Button>
            </Center>
          )}
        </Stack>
      )}
    </PageLayout>
  );
}
