import { SimpleGrid } from '@chakra-ui/react';
import { useCurrentDataSource } from '../../../sources/context/CurrentDataSourceContext';
import ConnectionConfiguration from '../ConnectionConfiguration/ConnectionConfiguration';
import ExportStatusSection from '../ExportStatusSection/ExportStatusSection';
import IngestionStatusSection from '../IngestionStatusSection/IngestionStatusSection';

export default function IntegratedSourceConfiguration() {
  const { dataSource } = useCurrentDataSource();
  const isIn = dataSource.syncDirection !== 'OUT';
  const isOut = dataSource.syncDirection !== 'IN';

  return (
    <SimpleGrid columns={3} gap={5} minH="0">
      {isIn && <IngestionStatusSection />}
      {isOut && <ExportStatusSection />}
      <ConnectionConfiguration />
    </SimpleGrid>
  );
}
