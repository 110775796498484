import { Box, Flex, HStack, Spinner, VStack, useBoolean } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useParams } from 'react-router-dom';
import { useInfiniteScroll } from '../../../../context/InfiniteScrollContext';
import ProfileClient from '../../../../lib/api-client/profile/ProfileClient';
import { Events } from '../../../../lib/api-client/profile/model/Events';
import { Profile } from '../../../../lib/api-client/profile/model/Profile';
import { formatDateString, formatDays } from '../../../../lib/utils/date-time-utils';
import ActivityTrackerDetailsCard from '../../../core/ActivityTrackerDetails/ActivityTrackerDetailsCard';
import Alert from '../../../core/Alert/Alert';
import CustomerEventValue from '../CustomerEventValue/CustomerEventValue';

function ProfilePageActivityAllTrackerData({ keyvalue }: any) {
  const { id } = useParams() as { id: string };
  const [isFetchingEvents, setFetchingEvents] = useState(false);
  const [initializationError, setInitializationError] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const { scrollRef } = useInfiniteScroll();
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useBoolean(true);

  const [events, setEvents] = useState<Events[]>([]);
  const [profile, setProfile] = useState<Profile>();

  const fetchTxs = useCallback(async () => {
    if ((isFetchingEvents && hasMore) || initializationError || error) {
      return;
    }
    setFetchingEvents(true);

    try {
      const getEventsByPin = await ProfileClient.getEventsByPin(id, keyvalue, page, 10);
      const { content } = getEventsByPin;
      const getProfileByPin = ProfileClient.getProfileByPin(id).then((p) => {
        if (!p) {
          return p;
        }
        return { ...p };
      });
      setProfile(await getProfileByPin);
      setEvents((eventData) => eventData.concat(content));
      setPage(page + 1);
      setHasMore(!getEventsByPin.last);
      setLoading.off();
    } catch (err) {
      if (page === 0) {
        setInitializationError(true);
      } else {
        setError(true);
      }
    } finally {
      setFetchingEvents(false);
    }
  }, [isFetchingEvents, hasMore, initializationError, error, setLoading, id, keyvalue, page]);

  const eventsSummary = profile?.eventsSummary?.[keyvalue];
  const content = (
    <InfiniteScroll
      loadMore={fetchTxs}
      hasMore={hasMore}
      getScrollParent={() => scrollRef}
      useWindow={false}
    >
      {isLoading && (
        <Flex w="full" flex="1" justifyContent="center" alignItems="center">
          <Spinner
            mt="180px"
            thickness="4px"
            size="xl"
            color="action"
            emptyColor="gray.200"
            speed="0.65s"
          />
        </Flex>
      )}
      {!isLoading && (
        <HStack alignItems="start" spacing={6}>
          <VStack spacing="5" alignItems="start">
            {events.map((item, index) => (
              <ActivityTrackerDetailsCard
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                sourceId={item.sourceId}
                sourceRecordId={item.sourceRecordId}
                eventName={item.eventName}
                sourceRecordTimestamp={item.sourceRecordTimestamp}
                locationName={item.locationName}
                locationDetailedName={item.locationDetailedName}
                persistedValues={item.persistedValues}
              />
            ))}
            {events.length < 1 && (
              <Alert
                status="warning"
                variant="subtle"
                description="There is no activity data of this type on record for this consumer."
                w="710px"
              />
            )}
          </VStack>
          {profile && (
            <CustomerEventValue
              totalEventValue={eventsSummary?.eventsLastYear ?? 0}
              averageTotalEvent={eventsSummary?.averageAnnualEventCount ?? 0}
              averageDaysBetweenEvents={formatDays(eventsSummary?.averageDaysBetweenEvents)}
              firstEvent={formatDateString(
                eventsSummary?.earliestEventTimestamp ?? '',
                'LLL dd, yyyy'
              )}
              mostRecentEvent={formatDateString(
                eventsSummary?.latestEventTimestamp ?? '',
                'LLL dd, yyyy'
              )}
            />
          )}
        </HStack>
      )}
    </InfiniteScroll>
  );
  return <Box width="710px">{content}</Box>;
}
export default ProfilePageActivityAllTrackerData;
