import {
  Button,
  Text,
  useClipboard,
  IconButton,
  HStack,
  Stack,
  Divider,
  VStack,
} from '@chakra-ui/react';
import Icon from '../Icon/Icon';
import { IconImage } from '../Icon/IconConfig';

export default function WebHookCard({
  url,
  label,
  deleteWebhook,
  cutomizeWebhook,
}: {
  url: string;
  label: string;
  deleteWebhook: () => void;
  cutomizeWebhook: () => void;
}) {
  const { onCopy } = useClipboard(url ?? '');
  return (
    <>
      <HStack justifyContent="space-between" mt={2}>
        <VStack spacing={1} alignItems="start">
          <Text fontWeight="semibold" lineHeight="20px">
            {label}{' '}
          </Text>
          <HStack>
            <Text>{url}</Text>{' '}
            <IconButton
              onClick={() => {
                onCopy();
              }}
              boxSize="4"
              aria-label="Copy url"
              icon={<Icon iconImage={IconImage.copyLink} />}
              variant="ghost"
            />
          </HStack>
        </VStack>

        <Stack direction="row" spacing={4}>
          <Button variant="outline" onClick={cutomizeWebhook}>
            Customize
          </Button>
          <Button variant="outline" onClick={deleteWebhook}>
            Delete
          </Button>
        </Stack>
      </HStack>
      <Divider mt={4} color="gray.300" borderWidth="thin" />
    </>
  );
}
