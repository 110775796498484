import {
  Box,
  Button,
  chakra,
  Code,
  Collapse,
  Grid,
  GridItem,
  HStack,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { parseISO } from 'date-fns';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { motion } from 'framer-motion';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import { ExportIngestError } from '../../../lib/api-client/sources/sources.model';
import LogItem from '../components/LogItem/LogItem';
import LogItemLabel from '../components/LogItem/LogItemLabel';
import LogItemLink from '../components/LogItem/LogItemLink';

interface IngestErrorsItemProps {
  errors: ExportIngestError;
  externalSourceLink?: string;
}

export default function IngestErrorsItem({ errors, externalSourceLink }: IngestErrorsItemProps) {
  const { isOpen, onToggle } = useDisclosure();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <LogItem fontSize="sm" key={errors.recordId}>
      <Grid templateColumns="1fr max-content">
        <Stack spacing={1}>
          <LogItemLabel>
            <Box display="inline-flex">
              <Icon iconImage={IconImage.error} boxSize={5} color="error" />
              <chakra.span ml={1}>{errors.errors}</chakra.span>
            </Box>
          </LogItemLabel>
          <HStack spacing={10}>
            <chakra.span ml={6}>
              {formatInTimeZone(
                parseISO(errors.ingestionTime),
                timezone,
                'MMM dd, yyyy h:mm a zzz'
              )}
            </chakra.span>
            {externalSourceLink ? (
              <LogItemLink href={externalSourceLink} target="_blank">
                Source record ID: {errors.recordId}
              </LogItemLink>
            ) : (
              <chakra.span>Source record ID: {errors.recordId}</chakra.span>
            )}
          </HStack>
        </Stack>
        <GridItem alignSelf="center" ml={4}>
          <Button
            minW="105px"
            variant="ghost"
            rightIcon={
              <motion.div initial={{ rotate: 0 }} animate={{ rotate: isOpen ? -180 : 0 }}>
                <Icon boxSize={4} iconImage={IconImage.accordionDown} />
              </motion.div>
            }
            onClick={onToggle}
          >
            {isOpen ? 'Hide details' : 'See details'}
          </Button>
        </GridItem>
      </Grid>
      <Collapse in={isOpen} animateOpacity>
        <Code
          w="full"
          mt={3}
          p="3"
          borderRadius="md"
          border="1px"
          borderColor="gray.200"
          fontSize="sm"
          maxH="300px"
          overflowY="auto"
        >
          <pre>{JSON.stringify(errors?.sourceData?.event?.data, null, 2) ?? {}}</pre>
        </Code>
      </Collapse>
    </LogItem>
  );
}
