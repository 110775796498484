import { FormControl, FormLabel, Input, Select, Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { formatNumber } from '../../../../lib/utils/number-utils';

const options = [100, 500, 1000, 5000, 10000];

function getControlValues(value?: number) {
  if (value == null) {
    return null;
  }

  return {
    listValue: options.includes(value) ? `${value}` : 'CUSTOM',
  };
}

type QueryLimitProps = {
  value?: number;
};

export default function QueryLimit({ value: valueProp }: QueryLimitProps) {
  const controlValues = getControlValues(valueProp);
  const [showCustom, setShowCustom] = useState(controlValues?.listValue === 'CUSTOM');
  const [listValue, setListValue] = useState(controlValues?.listValue ?? '');
  const [filterValue, setFilterValue] = useState(`${valueProp ?? ''}`);
  const form = useFormContext();

  useEffect(() => {
    if (valueProp) {
      form.setValue('limit', valueProp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack w="full">
      <FormControl>
        <FormLabel>Limit the number of results</FormLabel>
        <Select
          placeholder="Select one"
          value={listValue}
          onChange={(event) => {
            const { value } = event.target;
            setListValue(value);
            if (value === 'CUSTOM') {
              setShowCustom(true);
              setFilterValue('');
            } else {
              setShowCustom(false);
              form.setValue('limit', value);
            }
          }}
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {formatNumber(option)}
            </option>
          ))}
          <option value="CUSTOM">Custom</option>
        </Select>
      </FormControl>
      {showCustom && (
        <Input
          w="full"
          type="number"
          min="1"
          placeholder="Enter a number"
          value={filterValue}
          onChange={(event) => {
            const { value } = event.target;
            setFilterValue(value);
            form.setValue('limit', `${value}`);
          }}
        />
      )}
    </Stack>
  );
}
