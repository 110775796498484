import Icon, { IconProps } from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import { LoyaltyProgram } from '../../../../lib/api-client/profile/model/LoyaltyProgram';

export default function ProfilePageLoyaltyDataIcon({
  loyaltyProgram,
  ...props
}: {
  loyaltyProgram?: LoyaltyProgram;
} & IconProps) {
  if (loyaltyProgram == null) {
    return <Icon iconImage={IconImage.unsuccessful} color="error" {...props} />;
  }

  return <Icon iconImage={IconImage.success} color="success" {...props} />;
}
