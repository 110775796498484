import {
  Button,
  chakra,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useBoolean,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Alert from '../../../components/core/Alert/Alert';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import DataSourceIcon from '../../../components/features/connections/DataSourceIcon/DataSourceIcon';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import { useToast } from '../../../hooks/use-toast';
import SourceClient from '../../../lib/api-client/sources/SourceClient';
import {
  useGetSourceUpload,
  useStartRemoteIngestion,
} from '../../../lib/api-client/sources/SourceData';
import { Upload } from '../../../lib/api-client/sources/model/Upload';
import { useCurrentDataSource } from '../context/CurrentDataSourceContext';
import { ConfirmationModal } from './IngestionStatusSection/IngestionStatusSection';
import TestDataMappingsSection from './TestDataMappingSection';
import { useProcessTestIngestion } from './useProcessTestIngestion';

interface TestIngestionProps {
  sampleId: string;
  sampleError?: boolean;
  upload?: Upload;
  startIngestion?: () => void;
}

export default function TestIngestionData({
  sampleId,
  sampleError,
  upload,
  startIngestion,
}: TestIngestionProps) {
  const { id = '' } = useParams();
  const { dataSource } = useCurrentDataSource();
  const [initialLoad, setInitialLoad] = useBoolean(true);

  const {
    testData: { status, records: ingestionData, warnings },
    isError: isTestIngestError,
    isComplete,
  } = useProcessTestIngestion(dataSource.id, sampleId);
  const isError = sampleError || isTestIngestError;
  const isInitialLoadComplete = status !== 'running' || isError || isComplete;
  const [enabled, setEnabled] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const setDataSourceEnabled = (value: any) =>
    SourceClient.setDataSourceEnabled(id, value)
      .then(() => {
        setEnabled(value);
        navigate(`/sources/${dataSource.id}/manage`);
      })
      .catch()
      .finally(() => onClose());

  let statusIcon;
  if (status === 'completedSuccessfully') {
    statusIcon = {
      iconImage: IconImage.checkmark,
      bgColor: 'success',
      color: 'white',
    };
  } else if (status === 'completedWithWarnings' || status === 'timeout') {
    statusIcon = {
      iconImage: IconImage.warning,
      bgColor: 'white',
      color: 'warning',
    };
  } else {
    statusIcon = {
      iconImage: IconImage.error,
      bgColor: 'white',
      color: 'error',
    };
  }

  if (isInitialLoadComplete && initialLoad) {
    setInitialLoad.off();
  }

  const handleChange = () => {
    onOpen();
  };
  const handleClick = async () => {
    if (startIngestion != null) {
      startIngestion();
    }
    onClose();
  };

  const recordCount = ingestionData?.length ?? 0;

  return (
    <PageLayout
      pageViewEvent={{ page: 'Test Ingestion', pageId: id }}
      header={
        <Header
          icon={<DataSourceIcon sourceSystem={dataSource.sourceSystem} />}
          title={`${dataSource.name} test ingestion`}
          back={{
            label: 'Back to connection management',
            to: `/sources/${dataSource.id}/manage`,
          }}
        />
      }
    >
      <>
        {isError && (
          <Alert
            status="error"
            description="There was a problem during the test ingestion. Please refresh and try again."
          />
        )}
        {!isError && initialLoad && (
          <HStack spacing="3">
            <Spinner thickness="3px" speed="3s" emptyColor="gray.200" color="blue.500" size="lg" />
            <VStack alignItems="start" spacing="1">
              <chakra.span fontWeight="bold" fontSize="20px">
                One moment while we ingest data records...
              </chakra.span>
              <chakra.span>
                Please remain on this screen to avoid disrupting the ingestion. Data records will
                load as we receive them.
              </chakra.span>
            </VStack>
          </HStack>
        )}
        {!isError && !initialLoad && (
          <>
            <HStack justifyContent="space-between">
              <HStack spacing="3">
                {isComplete ? (
                  <Icon boxSize={9} p={1} {...statusIcon} rounded="full" />
                ) : (
                  <Spinner
                    thickness="3px"
                    speed="3s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="lg"
                  />
                )}
                <VStack alignItems="start" spacing="1">
                  <chakra.span fontWeight="700" fontSize="20px">
                    {`${recordCount} data ${recordCount > 1 ? 'records' : 'record'} ${
                      isComplete ? 'were ingested' : 'ingested so far...'
                    }`}
                  </chakra.span>
                  <chakra.span fontWeight="400" fontSize="14px">
                    Carefully review the data mapping results below for accuracy.
                  </chakra.span>
                </VStack>
              </HStack>
              <HStack spacing="3">
                <Button
                  variant="outline"
                  as={Link}
                  to={`/sources/${dataSource.id}/mappings`}
                  target="_blank"
                >
                  Configure data mappings
                </Button>
                <Button onClick={handleChange}>
                  {upload ? 'Ingest all records now' : 'Activate ingestion now'}
                </Button>
              </HStack>
            </HStack>
            {warnings.length > 0 && (
              <Alert
                mt={6}
                status="warning"
                title="The following warnings were detected:"
                description={warnings.join(', ')}
                variant="subtle"
              />
            )}
            {status === 'timeout' && (
              <Alert
                mt={6}
                status="warning"
                description="The test ingestion timed out"
                variant="subtle"
              />
            )}
            {ingestionData && <TestDataMappingsSection records={ingestionData} />}
            {upload && upload ? (
              <Modal size="sm" isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader fontWeight="bold" w="360px">
                    Are you sure you want to ingest all data records?
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    This will kick-off batch ingestion for all data records in the following file:{' '}
                    {upload?.fileName}
                  </ModalBody>
                  <ModalFooter justifyContent="start">
                    <Button mr={4} onClick={handleClick}>
                      Yes,ingest now
                    </Button>
                    <Button autoFocus variant="outline">
                      No, ingest later
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            ) : (
              <ConfirmationModal
                enabled={!enabled}
                isOpen={isOpen}
                onClose={onClose}
                onConfirmation={setDataSourceEnabled}
              />
            )}
          </>
        )}
      </>
    </PageLayout>
  );
}

export function ConnectedSystemTestIngestionData() {
  const { dataSource } = useCurrentDataSource();

  const { data: remoteIngestion, error: remoteIngestionError } = useStartRemoteIngestion(
    dataSource.id,
    'TEST'
  );

  if (!remoteIngestion?.sampleId) {
    return null;
  }

  return (
    <TestIngestionData
      sampleId={remoteIngestion.sampleId}
      sampleError={remoteIngestionError != null}
    />
  );
}

export function FlatFileTestIngestionData() {
  const { dataSource } = useCurrentDataSource();
  const { uploadId = '' }: any = useParams();
  const toast = useToast();

  const { data: uploadIngest, error: uploadIngestError } = useGetSourceUpload(
    dataSource.id,
    uploadId
  );

  const handleClick = async () => {
    try {
      await SourceClient.updateSourceUploadStatus(dataSource.id, uploadId, 'importRequested');
      toast({
        status: 'success',
        title: `Data ingestion is in progress.`,
      });
    } catch (e) {
      toast({
        status: 'error',
        title: `We were unable to ingest the data .`,
        description: 'Please try again.',
      });
    }
  };

  if (!uploadIngest?.sampleId) {
    return null;
  }

  return (
    <TestIngestionData
      sampleId={uploadIngest.sampleId}
      sampleError={uploadIngestError != null}
      upload={uploadIngest}
      startIngestion={handleClick}
    />
  );
}
