import mixpanel, { Dict, OverridedMixpanel } from 'mixpanel-browser';

let initialized = false;

const actions = {
  init: (token: string) => {
    mixpanel.init(token, {
      secure_cookie: true,
    });
    initialized = true;
  },
  identify: (id: string) => {
    if (initialized) mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (initialized) mixpanel.alias(id);
  },
  track: (name: string, props?: Dict | undefined) => {
    if (initialized) mixpanel.track(name, props);
  },
  register: (props: Dict) => {
    if (initialized) {
      mixpanel.register(props);
    }
  },
  people: {
    set: (props: Dict) => {
      if (initialized) mixpanel.people.set(props);
    },
  },
};

export default actions as OverridedMixpanel;
