import { Box, BoxProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface LogItemProps {
  children: ReactNode;
}

export default function LogItem({ children, ...rest }: LogItemProps & BoxProps) {
  return <Box {...rest}>{children}</Box>;
}
