import {
  AccordionDown,
  AccordionLeft,
  AccordionRight,
  AccordionUp,
  Add,
  Adobe,
  ArrowBack,
  ArrowDown,
  ArrowForward,
  ArrowUp,
  ArtificialIntelligence,
  Business,
  BusinessAlt,
  Calendar,
  CallCenterAgent,
  Cancel,
  Cart,
  Change,
  Checkmark,
  ChevronBack,
  ChevronBackBookend,
  ChevronDown,
  ChevronForward,
  ChevronForwardBookend,
  ChevronUp,
  Consumer360View,
  ConsumerInsights,
  ConsumerSpend,
  CreditCard,
  Dashboard,
  Database,
  DatabaseError,
  Databases,
  DatabaseSettings,
  DataChart,
  DataMap,
  DataSecurity,
  DesktopComputer,
  Email,
  EmailAlt,
  Error,
  Gauge,
  Handshake,
  Help,
  Identification,
  IdentificationAlt,
  IdentificationConfirmed,
  Info,
  Innovation,
  Intelligence,
  Link,
  Location,
  LoyaltyCard,
  LoyaltyCardAlt,
  Mailchimp,
  MicrosoftDynamics,
  MobileApproved,
  MobileIdentification,
  MobilePhone,
  MobileSecurity,
  MobileWallet,
  MobileWalletAlt,
  More,
  Notifications,
  Office,
  Payment,
  People,
  Phone,
  PosTerminal,
  Privacy,
  Robot,
  RobotAlt,
  Salesforce,
  Search,
  SearchData,
  SearchUser,
  Service,
  Settings,
  Shopify,
  ShoppingBag,
  SignOut,
  Star,
  Success,
  Tableau,
  ThumbsDown,
  ThumbsUp,
  TouchInteraction,
  Transaction,
  TransactionApproved,
  Trust,
  User,
  UserConfirmed,
  UserConfirmedAlt,
  Wallet,
  WalletAlt,
  Warning,
  BadgeStar,
  Hubspot,
  Edit,
  Delete,
  ExternalLink,
  DatabaseConfirmed,
  File,
  EditData,
  CopyLink,
  Health,
  Download,
  VersionHistory,
  Line,
  MoreAlt,
  Publish,
  Sync,
  Input,
  Automation,
  Copy,
  Unknown,
  Household,
  DataTransfer,
  Tools,
  Unsuccessful,
  Snowflake,
  FlatFile,
  SevenRooms,
  TripleSeat,
  Merge,
  TrendRating,
  InProgress,
  Square,
  VerticalScroll,
  History,
  DataIncoming,
  DataOutgoing,
  Duplicate,
  Target,
} from './svg';

export enum IconImage {
  accordionDown,
  accordionLeft,
  accordionRight,
  accordionUp,
  add,
  adobe,
  arrowBack,
  arrowDown,
  arrowForward,
  arrowUp,
  artificialIntelligence,
  business,
  businessAlt,
  calendar,
  callCenterAgent,
  cancel,
  cart,
  change,
  checkmark,
  chevronBack,
  chevronBackBookend,
  chevronDown,
  chevronForward,
  chevronForwardBookend,
  chevronUp,
  consumer360View,
  consumerInsights,
  consumerSpend,
  creditCard,
  dashboard,
  dataChart,
  dataMap,
  dataSecurity,
  database,
  databaseConfirmed,
  databases,
  databaseSettings,
  desktopComputer,
  email,
  emailAlt,
  error,
  gauge,
  handshake,
  help,
  hubspot,
  identification,
  identificationAlt,
  identificationConfirmed,
  info,
  innovation,
  intelligence,
  link,
  location,
  loyaltyCard,
  loyaltyCardAlt,
  mailchimp,
  microsoftDynamics,
  mobileApproved,
  mobileIdentification,
  mobilePhone,
  mobileSecurity,
  mobileWallet,
  mobileWalletAlt,
  notifications,
  office,
  payment,
  people,
  phone,
  posTerminal,
  privacy,
  robot,
  robotAlt,
  salesforce,
  search,
  searchData,
  searchUser,
  service,
  settings,
  shopify,
  shoppingBag,
  signOut,
  star,
  success,
  tableau,
  thumbsDown,
  thumbsUp,
  touchInteraction,
  transaction,
  transactionApproved,
  trust,
  userConfirmed,
  userConfirmedAlt,
  user,
  wallet,
  walletAlt,
  warning,
  badgeStar,
  edit,
  delete,
  externalLink,
  file,
  editData,
  copyLink,
  more,
  health,
  databaseError,
  download,
  versionHistory,
  line,
  moreAlt,
  publish,
  sync,
  input,
  automation,
  copy,
  icon,
  unknown,
  household,
  dataTransfer,
  tools,
  unsuccessful,
  snowflake,
  flatFile,
  square,
  sevenrooms,
  tripleseat,
  merge,
  trendRating,
  inProgress,
  verticalScroll,
  history,
  dataIncoming,
  dataOutgoing,
  duplicate,
  target,
}

export const IconMap = new Map<IconImage, JSX.Element>([
  [IconImage.accordionLeft, <AccordionLeft />],
  [IconImage.accordionDown, <AccordionDown />],
  [IconImage.accordionRight, <AccordionRight />],
  [IconImage.accordionUp, <AccordionUp />],
  [IconImage.add, <Add />],
  [IconImage.adobe, <Adobe />],
  [IconImage.arrowBack, <ArrowBack />],
  [IconImage.arrowDown, <ArrowDown />],
  [IconImage.arrowForward, <ArrowForward />],
  [IconImage.arrowUp, <ArrowUp />],
  [IconImage.artificialIntelligence, <ArtificialIntelligence />],
  [IconImage.business, <Business />],
  [IconImage.businessAlt, <BusinessAlt />],
  [IconImage.calendar, <Calendar />],
  [IconImage.callCenterAgent, <CallCenterAgent />],
  [IconImage.cancel, <Cancel />],
  [IconImage.cart, <Cart />],
  [IconImage.change, <Change />],
  [IconImage.checkmark, <Checkmark />],
  [IconImage.chevronDown, <ChevronDown />],
  [IconImage.chevronBack, <ChevronBack />],
  [IconImage.chevronBackBookend, <ChevronBackBookend />],
  [IconImage.chevronForward, <ChevronForward />],
  [IconImage.chevronForwardBookend, <ChevronForwardBookend />],
  [IconImage.chevronUp, <ChevronUp />],
  [IconImage.consumer360View, <Consumer360View />],
  [IconImage.consumerInsights, <ConsumerInsights />],
  [IconImage.consumerSpend, <ConsumerSpend />],
  [IconImage.creditCard, <CreditCard />],
  [IconImage.dashboard, <Dashboard />],
  [IconImage.dataChart, <DataChart />],
  [IconImage.dataMap, <DataMap />],
  [IconImage.dataSecurity, <DataSecurity />],
  [IconImage.database, <Database />],
  [IconImage.databaseConfirmed, <DatabaseConfirmed />],
  [IconImage.databases, <Databases />],
  [IconImage.databaseSettings, <DatabaseSettings />],
  [IconImage.desktopComputer, <DesktopComputer />],
  [IconImage.email, <Email />],
  [IconImage.emailAlt, <EmailAlt />],
  [IconImage.error, <Error />],
  [IconImage.gauge, <Gauge />],
  [IconImage.handshake, <Handshake />],
  [IconImage.hubspot, <Hubspot />],
  [IconImage.help, <Help />],
  [IconImage.identification, <Identification />],
  [IconImage.identificationAlt, <IdentificationAlt />],
  [IconImage.identificationConfirmed, <IdentificationConfirmed />],
  [IconImage.info, <Info />],
  [IconImage.innovation, <Innovation />],
  [IconImage.intelligence, <Intelligence />],
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  [IconImage.link, <Link />],
  [IconImage.location, <Location />],
  [IconImage.loyaltyCard, <LoyaltyCard />],
  [IconImage.loyaltyCardAlt, <LoyaltyCardAlt />],
  [IconImage.mailchimp, <Mailchimp />],
  [IconImage.microsoftDynamics, <MicrosoftDynamics />],
  [IconImage.mobileApproved, <MobileApproved />],
  [IconImage.mobileIdentification, <MobileIdentification />],
  [IconImage.mobilePhone, <MobilePhone />],
  [IconImage.mobileSecurity, <MobileSecurity />],
  [IconImage.mobileWallet, <MobileWallet />],
  [IconImage.mobileWalletAlt, <MobileWalletAlt />],
  [IconImage.notifications, <Notifications />],
  [IconImage.office, <Office />],
  [IconImage.payment, <Payment />],
  [IconImage.people, <People />],
  [IconImage.phone, <Phone />],
  [IconImage.posTerminal, <PosTerminal />],
  [IconImage.privacy, <Privacy />],
  [IconImage.robot, <Robot />],
  [IconImage.robotAlt, <RobotAlt />],
  [IconImage.salesforce, <Salesforce />],
  [IconImage.search, <Search />],
  [IconImage.searchData, <SearchData />],
  [IconImage.searchUser, <SearchUser />],
  [IconImage.service, <Service />],
  [IconImage.settings, <Settings />],
  [IconImage.shopify, <Shopify />],
  [IconImage.shoppingBag, <ShoppingBag />],
  [IconImage.signOut, <SignOut />],
  [IconImage.star, <Star />],
  [IconImage.success, <Success />],
  [IconImage.tableau, <Tableau />],
  [IconImage.thumbsDown, <ThumbsDown />],
  [IconImage.thumbsUp, <ThumbsUp />],
  [IconImage.touchInteraction, <TouchInteraction />],
  [IconImage.transaction, <Transaction />],
  [IconImage.transactionApproved, <TransactionApproved />],
  [IconImage.trust, <Trust />],
  [IconImage.user, <User />],
  [IconImage.userConfirmed, <UserConfirmed />],
  [IconImage.userConfirmedAlt, <UserConfirmedAlt />],
  [IconImage.wallet, <Wallet />],
  [IconImage.walletAlt, <WalletAlt />],
  [IconImage.warning, <Warning />],
  [IconImage.badgeStar, <BadgeStar />],
  [IconImage.edit, <Edit />],
  [IconImage.delete, <Delete />],
  [IconImage.externalLink, <ExternalLink />],
  [IconImage.file, <File />],
  [IconImage.editData, <EditData />],
  [IconImage.copyLink, <CopyLink />],
  [IconImage.more, <More />],
  [IconImage.health, <Health />],
  [IconImage.databaseError, <DatabaseError />],
  [IconImage.download, <Download />],
  [IconImage.versionHistory, <VersionHistory />],
  [IconImage.line, <Line />],
  [IconImage.moreAlt, <MoreAlt />],
  [IconImage.publish, <Publish />],
  [IconImage.sync, <Sync />],
  [IconImage.input, <Input />],
  [IconImage.automation, <Automation />],
  [IconImage.copy, <Copy />],
  [IconImage.unknown, <Unknown />],
  [IconImage.household, <Household />],
  [IconImage.dataTransfer, <DataTransfer />],
  [IconImage.tools, <Tools />],
  [IconImage.unsuccessful, <Unsuccessful />],
  [IconImage.merge, <Merge />],
  [IconImage.trendRating, <TrendRating />],
  [IconImage.inProgress, <InProgress />],
  [IconImage.flatFile, <FlatFile />],
  [IconImage.snowflake, <Snowflake />],
  [IconImage.sevenrooms, <SevenRooms />],
  [IconImage.square, <Square />],
  [IconImage.tripleseat, <TripleSeat />],
  [IconImage.verticalScroll, <VerticalScroll />],
  [IconImage.history, <History />],
  [IconImage.dataIncoming, <DataIncoming />],
  [IconImage.dataOutgoing, <DataOutgoing />],
  [IconImage.duplicate, <Duplicate />],
  [IconImage.target, <Target />],
]);
