import { Badge, Box, Button, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { Link, To } from 'react-router-dom';
import { formatNumber, formatPercentage } from '../../../lib/utils/number-utils';

interface AccurateVerificationCardProps {
  label: string;
  accurate: number;
  accuratePercent: number;
  inaccurate: number;
  inaccuratePercent: number;
  questionable: number;
  questionablePercent: number;
  questionableTo: To;
  inaccurateTo: To;
}

export default function AccurateVerificationCard({
  label,
  accurate,
  accuratePercent,
  inaccurate,
  inaccuratePercent,
  questionable,
  questionablePercent,
  questionableTo,
  inaccurateTo,
}: AccurateVerificationCardProps) {
  const accuratePercent100 = accuratePercent * 100;
  const inaccuratePercent100 = inaccuratePercent * 100;
  const questionablePercent100 = questionablePercent * 100;

  return (
    <Box p={4} w="full" border="1px solid" borderColor="gray.100" boxShadow="sm" borderRadius="6px">
      <Text fontSize="md" fontWeight="semibold" pb={4}>
        {label}
      </Text>
      <Box>
        <Flex>
          {accurate > 0 && (
            <Box
              w={`${accuratePercent100 < 3 ? 3 : accuratePercent100}%`}
              h={6}
              bg="success"
              borderRadius={
                accuratePercent100 === 100 && questionable === 0 && inaccurate === 0 ? 10 : 0
              }
              borderTopLeftRadius={10}
              borderBottomLeftRadius={10}
              border="4px solid"
              borderColor="gray.100"
              borderRightWidth={
                accuratePercent100 === 100 && questionable === 0 && inaccurate === 0 ? '4px' : '2px'
              }
            />
          )}
          {questionable > 0 && (
            <Box
              w={`${questionablePercent100 < 3 ? 3 : questionablePercent100}%`}
              h={6}
              bg="warning"
              borderRadius={
                questionablePercent100 === 100 && accurate === 0 && inaccurate === 0 ? 10 : 0
              }
              borderTopRightRadius={inaccurate > 0 ? 0 : 10}
              borderBottomRightRadius={inaccurate > 0 ? 0 : 10}
              border="4px solid"
              borderColor="gray.100"
              borderLeftWidth={
                questionablePercent100 === 100 && accurate === 0 && inaccurate === 0 ? '4px' : '2px'
              }
              borderRightWidth={
                questionablePercent100 === 100 && accurate === 0 && inaccurate === 0 ? '4px' : '2px'
              }
            />
          )}
          {inaccurate > 0 && (
            <Box
              w={`${inaccuratePercent100 < 3 ? 3 : inaccuratePercent100}%`}
              h={6}
              bg="error"
              borderRadius={
                inaccuratePercent100 === 100 && accurate === 0 && questionable === 0 ? 10 : 0
              }
              borderTopRightRadius={10}
              borderBottomRightRadius={10}
              border="4px solid"
              borderColor="gray.100"
              borderLeftWidth={
                inaccuratePercent100 === 100 && accurate === 0 && questionable === 0 ? '4px' : '2px'
              }
            />
          )}
          {accuratePercent100 + inaccuratePercent100 + questionablePercent100 === 0 && (
            <Box
              w="full"
              h={6}
              bg="error"
              borderRadius={10}
              borderTopRightRadius={10}
              borderBottomRightRadius={10}
              border="4px solid"
              borderColor="gray.100"
              borderLeftWidth={4}
            />
          )}
        </Flex>
        <VStack spacing={2} pt={2} alignItems="start">
          <Box w="50%">
            <Text fontSize="2xl" fontWeight="bold">
              {`${formatNumber(accurate)} `}
              <Badge
                w="fit-content"
                h="6"
                pl="2"
                pr="2"
                pt="0.5"
                fontSize="12"
                textColor="white"
                bgColor="success"
                rounded="xl"
              >
                {formatPercentage(accuratePercent, 1, 'closePrecision')}
              </Badge>
            </Text>
            <Text w="170">accurate field values</Text>
          </Box>
          <HStack spacing="24">
            <Box w="60%">
              <Text fontSize="2xl" fontWeight="bold">
                {`${formatNumber(questionable)} `}
                <Badge
                  w="fit-content"
                  h="6"
                  pl="2"
                  pr="2"
                  pt="0.5"
                  fontSize="xs"
                  textColor="white"
                  bgColor="warning"
                  rounded="xl"
                >
                  {formatPercentage(questionablePercent, 1, 'closePrecision')}
                </Badge>
              </Text>
              <Text w="170" whiteSpace="nowrap">
                questionable field values
              </Text>
            </Box>
            <Button variant="outline" as={Link} to={questionableTo}>
              {' '}
              Explore
            </Button>
          </HStack>
          <HStack spacing="28">
            <Box w="60%">
              <Text fontSize="2xl" fontWeight="bold">
                {`${formatNumber(inaccurate)} `}
                <Badge
                  w="fit-content"
                  h="6"
                  pl="2"
                  pr="2"
                  pt="0.5"
                  fontSize="12"
                  textColor="white"
                  bgColor="error"
                  rounded="xl"
                >
                  {formatPercentage(inaccuratePercent, 1, 'closePrecision')}
                </Badge>
              </Text>
              <Text w="170" whiteSpace="nowrap">
                inaccurate field values
              </Text>
            </Box>
            <Button variant="outline" as={Link} to={inaccurateTo}>
              Explore
            </Button>
          </HStack>
        </VStack>
      </Box>
    </Box>
  );
}
