import Alert from '../../../core/Alert/Alert';

function PartialErrorDetails() {
  return (
    <div data-testid="PartialErrorDetails">
      <Alert
        status="error"
        title="A system error occurred."
        description="Some of the content on this page could not load due to a system failure. Try refreshing the page."
      />
    </div>
  );
}
export default PartialErrorDetails;
