import {
  Box,
  Button,
  chakra,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  IconButton,
  Input,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FaCircle } from 'react-icons/fa';
import Icon from '../../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../../components/core/Icon/IconConfig';
import {
  AttributeMetadata,
  NormalizationMetadata,
} from '../../../../../lib/api-client/data-model/data-model.model';
import { DataSourceNormalization } from '../../../../../lib/api-client/sources/model/DataSourceAttributeMapping';
import { get } from '../../../../../lib/utils/utils';
import { DataSourceAttributeMultiValueMapping } from '../../DataMappingPage.utils';

const ChakraFaCircle = chakra(FaCircle);

interface AttributeNormalizationModalProps {
  sourceAttributes: DataSourceAttributeMultiValueMapping['sourceAttributes'];
  attributeMetadata?: AttributeMetadata;
  normalizationMetadata: NormalizationMetadata[];
  onSuccess: (normalizations: DataSourceAttributeMultiValueMapping['sourceAttributes']) => void;
  isDisabled?: boolean;
}

type NormalizationForm = Record<
  string,
  Omit<DataSourceNormalization, 'id'> & { id: boolean | string }
>;

type DataSourceNormalizationForm = Record<
  string,
  { value: string; normalizations?: DataSourceNormalization[] }
>;

function toFormSafeValue(value: string) {
  return value.replace('.', '___harperiod___');
}

function EditAttributeNormalizationModal({
  sourceAttributes,
  attributeMetadata,
  normalizationMetadata,
  onSuccess,
  isDisabled,
}: AttributeNormalizationModalProps) {
  const hasNormalizations = sourceAttributes.find(
    (sa) => sa.normalizations && sa.normalizations.length > 0
  );
  const defaultValues = sourceAttributes.reduce((previous, current) => {
    const defaultNormalizations = (current.normalizations || []).reduce(
      (previousNormalization: NormalizationForm, currentNormalization: DataSourceNormalization) => {
        const p = previousNormalization;
        p[currentNormalization.id] = { ...currentNormalization, id: true };
        return p;
      },
      {}
    );

    const p = previous;
    p[toFormSafeValue(current.value)] = {
      ...current,
      normalizations: defaultNormalizations,
    };
    return p;
  }, {} as any);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<DataSourceNormalizationForm>({
    defaultValues,
  });
  const watchAll = watch();

  const onSubmit: SubmitHandler<DataSourceNormalizationForm> = (data) => {
    const result = sourceAttributes.map((sa) => {
      const normalizations = Object.entries(data[toFormSafeValue(sa.value)].normalizations ?? [])
        .filter(([, val]) => val.id)
        .map(([id, val]) => {
          let parameters;
          if (val.parameters) {
            const filteredParams = Object.entries(val.parameters).filter(
              ([, v]) => typeof v !== 'boolean' && v
            );

            if (filteredParams.length > 0) {
              parameters = filteredParams.reduce((p: any, [k, v]) => {
                const pr = p;
                pr[k] = v;
                return pr;
              }, {});
            }
          }

          return {
            id,
            parameters,
          };
        });

      return { ...sa, normalizations };
    });

    onSuccess(result);
  };

  const resetOnOpen = () => {
    reset(defaultValues);
    onOpen();
  };

  return (
    <Box>
      <Tooltip hasArrow label="Edit normalizations" placement="top">
        <Flex position="relative">
          <IconButton
            data-testid="EditAttributeNormalizationButton"
            aria-label="Edit normalizations"
            alignItems="center"
            variant="outline"
            icon={<Icon iconImage={IconImage.edit} />}
            isDisabled={isDisabled}
            onClick={resetOnOpen}
          />
          {hasNormalizations && (
            <ChakraFaCircle size={12} color="action" position="absolute" top="-4px" left="-4px" />
          )}
        </Flex>
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <form data-testid="EditAttributeNormalizationModal">
            <ModalHeader>
              Customize normalizations for incoming {attributeMetadata?.displayName} data
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody px={0}>
              <Box maxH="448px" overflowY="scroll">
                {sourceAttributes.map((sa) => {
                  const safeAttributeValue = toFormSafeValue(sa.value);

                  return (
                    <>
                      <Box p={2} px={6} bg="gray.200">
                        <chakra.span fontWeight="semibold">Normalization for:</chakra.span>{' '}
                        {sa.value}
                      </Box>
                      <Box>
                        <List>
                          {normalizationMetadata.map((n, idx) => {
                            const bgColor = idx % 2 === 0 ? 'inherit' : 'gray.50';
                            return (
                              <ListItem
                                key={`${safeAttributeValue}.normalizations.${n.id}`}
                                py={2}
                                px={6}
                                bgColor={
                                  (watchAll[safeAttributeValue]?.normalizations as any)?.[n.id]?.id
                                    ? 'rgba(142, 233, 255, 0.5)'
                                    : bgColor
                                }
                              >
                                <HStack>
                                  <FormControl>
                                    <Checkbox
                                      {...register(
                                        `${safeAttributeValue}.normalizations.${n.id}.id`
                                      )}
                                    >
                                      <FormLabel fontWeight="normal" m={0}>
                                        {n.displayName}
                                      </FormLabel>
                                    </Checkbox>
                                  </FormControl>
                                </HStack>
                                {(watchAll[safeAttributeValue]?.normalizations as any)?.[n.id]
                                  ?.id &&
                                  n.parameters &&
                                  n.parameters.length > 0 && (
                                    <Stack pl={6} pt={4} spacing={3}>
                                      {n.parameters.map((p) => {
                                        switch (p.valueType) {
                                          case 'BOOLEAN':
                                            return (
                                              <FormControl
                                                key={`${safeAttributeValue}.normalizations.${n.id}-${p.id}`}
                                                isInvalid={
                                                  !!get(
                                                    errors,
                                                    `${safeAttributeValue}.normalizations.${n.id}.parameters.${p.id}`
                                                  )
                                                }
                                              >
                                                <Flex alignItems="center">
                                                  <Switch
                                                    {...register(
                                                      `${safeAttributeValue}.normalizations.${n.id}.parameters.${p.id}`
                                                    )}
                                                  />
                                                  <FormLabel ml="2" mb="0">
                                                    {p.displayName}
                                                  </FormLabel>
                                                </Flex>
                                                <FormErrorMessage>
                                                  {
                                                    get(
                                                      errors,
                                                      `${safeAttributeValue}.normalizations.${n.id}.parameters.${p.id}`
                                                    )?.message
                                                  }
                                                </FormErrorMessage>
                                                <FormHelperText>{n.description}</FormHelperText>
                                              </FormControl>
                                            );
                                          case 'STRING':
                                            return (
                                              <FormControl
                                                key={`${safeAttributeValue}.normalizations.${n.id}-${p.id}`}
                                                isInvalid={
                                                  !!get(
                                                    errors,
                                                    `${safeAttributeValue}.normalizations.${n.id}.parameters.${p.id}`
                                                  )
                                                }
                                              >
                                                <FormLabel>{p.displayName}</FormLabel>
                                                <Input
                                                  bgColor="white"
                                                  w="240px"
                                                  {...register(
                                                    `${safeAttributeValue}.normalizations.${n.id}.parameters.${p.id}`,
                                                    {
                                                      required: p.required
                                                        ? 'Field required'
                                                        : false,
                                                    }
                                                  )}
                                                />
                                                <FormErrorMessage>
                                                  {
                                                    get(
                                                      errors,
                                                      `${safeAttributeValue}.normalizations.${n.id}.parameters.${p.id}`
                                                    )?.message
                                                  }
                                                </FormErrorMessage>
                                                <FormHelperText>{n.description}</FormHelperText>
                                              </FormControl>
                                            );
                                          default:
                                            return (
                                              <div
                                                key={`${safeAttributeValue}.normalizations.${n.id}-${p.id}`}
                                              />
                                            );
                                        }
                                      })}
                                    </Stack>
                                  )}
                              </ListItem>
                            );
                          })}
                        </List>
                      </Box>
                    </>
                  );
                })}
              </Box>
            </ModalBody>
            <ModalFooter justifyContent="start">
              <Button autoFocus type="button" onClick={() => handleSubmit(onSubmit)()}>
                Save normalizations
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
}
export default EditAttributeNormalizationModal;
