import ApiClient from '../ApiClient';
import { DataRepairAutomation, DataRepairAutomationType } from './data-repair.model';

const DataRepairClient = {
  updateDataRepairAutomation(
    repairAutomationType: DataRepairAutomationType,
    enabled: boolean
  ): Promise<DataRepairAutomation> {
    return ApiClient.put(`/data-repairs/${repairAutomationType}`, { enabled }).then(
      (res) => res.data
    );
  },
};
export default DataRepairClient;
