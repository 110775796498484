import ApiClient from '../ApiClient';
import { CustomerValueSettings } from './model/customer-value-settings';

const CustomerValueSettingsClient = {
  getCustomerValueSettings(): Promise<CustomerValueSettings> {
    return ApiClient.get<CustomerValueSettings>(`/customer-value-settings`).then((res) => res.data);
  },

  updateCustomerValueSettings(request: CustomerValueSettings): Promise<CustomerValueSettings> {
    return ApiClient.put<CustomerValueSettings>(`/customer-value-settings`, request).then(
      (res) => res.data
    );
  },
};

export default CustomerValueSettingsClient;
