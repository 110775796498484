import { isBefore, parseISO } from 'date-fns';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../../../context/AuthenticationContext';
import {
  IdentityMetrics,
  IdentitySources,
} from '../../../../lib/api-client/source-metrics/model/IdentityDataSourceMetrics';
import localStorageService from '../../../../lib/services/LocalStorageService';
import Alert from '../../../core/Alert/Alert';

type BatchResolutionStatus = {
  state: 'n/a' | 'running' | 'complete';
  lastBatchResolutionTime?: Date;
  sourcesRequiringIngestion: IdentitySources[];
};

function getBatchResolutionStatus(
  identityMetrics: IdentityMetrics | undefined,
  lastDismissedTime: number
) {
  const lastBatchResolutionTime = identityMetrics?.lastBatchResolution
    ? parseISO(identityMetrics.lastBatchResolution)
    : undefined;

  let batchIngestionStatus: BatchResolutionStatus = {
    state: 'n/a',
    lastBatchResolutionTime,
    sourcesRequiringIngestion: [],
  };

  if (!identityMetrics) {
    return batchIngestionStatus;
  }

  const sourcesWithBatch = identityMetrics.sources.filter(
    (source) => source.lastBatchIngestion != null
  );
  const sourcesRequiringIngestion = sourcesWithBatch.filter(
    ({ lastBatchIngestion }) =>
      lastBatchResolutionTime == null ||
      isBefore(lastBatchResolutionTime, parseISO(lastBatchIngestion!))
  );
  const mostRecentBatchIngestion: Date | undefined = sourcesWithBatch.reduce(
    (prev: Date | undefined, source) => {
      const sourceIngestionDate = parseISO(source.lastBatchIngestion!);
      if (prev == null) {
        return sourceIngestionDate;
      }

      return prev.getTime() > sourceIngestionDate.getTime() ? prev : sourceIngestionDate;
    },
    undefined
  );

  let state: any = 'n/a';
  if (mostRecentBatchIngestion) {
    if (
      lastBatchResolutionTime == null ||
      mostRecentBatchIngestion.getTime() > lastBatchResolutionTime.getTime()
    ) {
      state = 'running';
    } else if (mostRecentBatchIngestion.getTime() > lastDismissedTime) {
      state = 'complete';
    }
  }

  batchIngestionStatus = {
    state,
    sourcesRequiringIngestion,
  };

  return batchIngestionStatus;
}

interface BatchResolutionHeaderAlertProps {
  identityMetrics?: IdentityMetrics;
  children?: ReactNode;
}
export default function BatchResolutionHeaderAlert({
  identityMetrics,
  children,
}: BatchResolutionHeaderAlertProps) {
  const { tenantId = 'unknown' } = useAuth();
  const alertDismissedKey = `seviin.${tenantId}.batch-resolution-alert-dismissed`;
  const alertDismissedTime = +(localStorageService.getItem(alertDismissedKey) ?? '0');
  const batchResolutionStatus = useMemo(
    () => getBatchResolutionStatus(identityMetrics, alertDismissedTime),
    [identityMetrics, alertDismissedTime]
  );
  const [resolutionState, setResolutionState] = useState(batchResolutionStatus.state);

  useEffect(() => {
    setResolutionState(batchResolutionStatus.state);
  }, [batchResolutionStatus.state]);

  const source =
    batchResolutionStatus.sourcesRequiringIngestion.length === 1
      ? batchResolutionStatus.sourcesRequiringIngestion[0].source.name
      : `${batchResolutionStatus.sourcesRequiringIngestion.length} source systems`;

  let alert = null;
  switch (resolutionState) {
    case 'running':
      alert = (
        <Alert
          status="info"
          title={`Identity resolution is in process for the data recently ingested from ${source}`}
          description="Please allow 24 hours for this process to complete. During this period, some data metrics may appear pending or temporarily outdated."
          borderRadius={0}
          data-testid="BatchResolutionInProgressAlert"
        />
      );
      break;
    case 'complete':
      alert = (
        <Alert
          status="success"
          title="Identity resolution is complete for the data recently ingested."
          borderRadius={0}
          data-testid="BatchResolutionCompleteAlert"
          closeable
          onClose={() => {
            if (batchResolutionStatus.lastBatchResolutionTime) {
              localStorageService.setItem(
                alertDismissedKey,
                `${batchResolutionStatus.lastBatchResolutionTime.getTime()}`
              );
            }
            setResolutionState('n/a');
          }}
        />
      );
      break;
    default:
  }

  return (
    <>
      {alert}
      {children}
    </>
  );
}
