import { Stack } from '@chakra-ui/react';
import React, { useState } from 'react';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';
import TimeSeriesLimitedFilter, {
  filterConfig,
  SelectedTimeSeriesFilter,
} from '../../../../components/shared/visualizations/TimeSeriesLimitedFilter/TimeSeriesLimitedFilter';
import DataIngestionChart from './DataIngestionChart';
import DataIngestionChartDrawer from './DataIngestionChartDrawer';

export default function DataIngestSection() {
  const [selectedFilter, setSelectedFilter] = useState<SelectedTimeSeriesFilter>({
    interval: 'day',
    config: filterConfig.day[0],
  });
  return (
    <SectionContainer
      title="Data ingestion"
      variant="box"
      titleSubheader={<TimeSeriesLimitedFilter onFilterChanged={(e) => setSelectedFilter(e)} />}
      display="flex"
      flexDirection="column"
      minH="0"
    >
      <Stack spacing="3" h="full" justify="space-between">
        <DataIngestionChart
          width="100%"
          height={220}
          selectedMetric="sum:recordEvents"
          selectedTimeFilter={selectedFilter}
          showSources={false}
        />
        <DataIngestionChartDrawer />
      </Stack>
    </SectionContainer>
  );
}
