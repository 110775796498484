import { Box, Button, Stack } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { UseFieldArrayReturn, useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import Icon from '../../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../../components/core/Icon/IconConfig';
import {
  AttributeMetadataWithNormalization,
  DataModel,
} from '../../../../../lib/api-client/data-model/data-model.model';
import {
  filterPrimaryDomainFromDataModel,
  getAttributeMetadataFromDataModel,
} from '../../../../../lib/api-client/data-model/data-model.utils';
import { useGetTestRecords } from '../../../../../lib/api-client/sources/SourceData';
import { DataSource } from '../../../../../lib/api-client/sources/model/DataSource';
import { UpdateDataSource } from '../../../../../lib/api-client/sources/model/UpdateDataSource';
import { attributeMappingSchema, UpdateDataSourceForm } from '../../DataMappingPage.utils';
import AttributeRow from '../AttributeRow/AttributeRow';
import AttributeRowHeader from '../AttributeRowHeader/AttributeRowHeader';

function addDomainLabel(attributeMetadata: AttributeMetadataWithNormalization) {
  let { displayName } = attributeMetadata;
  switch (attributeMetadata.domain) {
    case 'CONSUMER_EVENT':
      displayName = `Event: ${displayName}`;
      break;
    case 'IDENTITY':
      displayName = `Identity: ${displayName}`;
      break;
    case 'LOYALTY_PROGRAM':
      displayName = `Loyalty: ${displayName}`;
      break;
    case 'TRANSACTION':
      displayName = `Transaction: ${displayName}`;
      break;
    case 'TRANSACTION_LINE_ITEM':
      displayName = `Transaction line item: ${displayName}`;
      break;
    default:
  }

  return { ...attributeMetadata, displayName };
}

interface CoreMappingsTabProps {
  source: DataSource;
  dataModel: DataModel;
  useFieldArray: UseFieldArrayReturn<UpdateDataSourceForm, 'attributeMappings'>;
  onValidate: (isValid: boolean) => void;
}

export default function CoreMappingsTab({
  source,
  dataModel,
  useFieldArray,
  onValidate,
}: CoreMappingsTabProps) {
  const [queryParams] = useSearchParams();
  const sampleId = queryParams.get('sampleId');
  const { data: sampleData } = useGetTestRecords(
    source.id,
    { sampleId, size: 100 },
    sampleId != null
  );
  const coreDataModel = useMemo(
    () => filterPrimaryDomainFromDataModel(dataModel, source.domainType),
    [dataModel, source.domainType]
  );
  const attributeMetadata = useMemo(
    () =>
      getAttributeMetadataFromDataModel(coreDataModel)
        .map((a) => (source.domainType === 'IDENTITY' ? a : addDomainLabel(a)))
        .filter(
          (a) =>
            source.domainType === 'IDENTITY' ||
            !(a.domain === 'IDENTITY' && a.id === 'sourceRecordId')
        ),
    [coreDataModel, source.domainType]
  );

  const domainIds = coreDataModel.domains.map((domain) => domain.id);

  const form = useFormContext<UpdateDataSource>();
  const { fields, append } = useFieldArray;

  const isValid = useMemo(
    () =>
      attributeMappingSchema.isValidSync(
        (form.getValues('attributeMappings') ?? []).filter(
          (field) => field.domainType == null || domainIds.includes(field.domainType)
        )
      ),
    [form, domainIds]
  );

  useEffect(() => {
    onValidate(isValid);
  }, [isValid, onValidate]);

  return (
    <Stack>
      <AttributeRowHeader syncDirection={source.syncDirection} layout="INBOUND" />
      {fields.map((item, index) =>
        item.domainType == null || domainIds.includes(item.domainType) ? (
          <AttributeRow
            syncDirection={source.syncDirection}
            item={item}
            index={index}
            key={item.id}
            sourceAttributes={source.sourceAttributes ?? []}
            sampleData={sampleData?.content}
            attributeMetadata={attributeMetadata}
            useFieldArray={useFieldArray}
          />
        ) : null
      )}
      <Box>
        <Button
          variant="ghost"
          rightIcon={<Icon iconImage={IconImage.add} />}
          onClick={() => {
            append({
              mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
              domainType: source.domainType,
              canonicalAttribute: '',
              sourceAttributes: [{ value: '' }],
            } as any);
          }}
        >
          Add a new mapping
        </Button>
      </Box>
    </Stack>
  );
}
