import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ProfileClient from '../../../lib/api-client/profile/ProfileClient';
import { TransactionsByRecord } from '../../../lib/api-client/profile/model/TransactionsByRecord';
import { formatDateString } from '../../../lib/utils/date-time-utils';
import { formatCurrency } from '../../../lib/utils/number-utils';
import Icon from '../Icon/Icon';
import { IconImage } from '../Icon/IconConfig';

export interface PurchaseDetailsCardProps {
  amount: number;
  recordId: string;
  currency: string;
  description?: string;
  locationName?: string;
  sourceId: string;
  sourceRecordId: string;
  startTimestamp?: string;
  orderId?: string;
  subtotal?: number | null;
  taxes?: number | null;
  discounts?: number | null;
  gratuity?: number | null;
  otherFees?: number | null;
  shippingFees?: number | null;
  reward?: number | null;
  paymentType?: string;
}
export default function PurchaseDetailsCard({
  amount,
  recordId,
  description,
  locationName,
  sourceId,
  sourceRecordId,
  startTimestamp,
  orderId,
  subtotal,
  taxes,
  discounts,
  gratuity,
  otherFees,
  shippingFees,
  paymentType,
  reward,
  currency,
}: PurchaseDetailsCardProps) {
  const [transactionsRecords, setTransactionsRecords] = useState<TransactionsByRecord>();

  const { id = '' } = useParams();
  const fetchData = async () => {
    const getTransactionsRecordByPin = await ProfileClient.getTransactionsByRecordId(
      id,
      recordId
    ).then((page) => page);
    setTransactionsRecords(getTransactionsRecordByPin);
  };

  return (
    <Box
      data-testid="PurchaseDetailsCard"
      width="710px"
      key={`${sourceId}-${sourceRecordId}`}
      border="1px solid"
      borderRadius="6px"
      borderColor="gray.100"
      shadow="md"
    >
      <Flex px="4" pt="4">
        <HStack spacing={3}>
          <Icon iconImage={IconImage.location} />
          <VStack w="316px" spacing="0" alignItems="start">
            <Text>{locationName}</Text>
            <Text>{description}</Text>
          </VStack>
        </HStack>
        <HStack spacing={3}>
          <Icon iconImage={IconImage.transaction} />
          <VStack spacing="0" alignItems="start">
            {orderId == null ? (
              <Text>No order number on record</Text>
            ) : (
              <HStack spacing={1}>
                <Text whiteSpace="nowrap">Order number </Text>
                <Tooltip
                  label="See the source data record and consumer identity data for this transaction"
                  placement="top"
                >
                  <Button
                    variant="link"
                    color="action"
                    as={Link}
                    to={`/profiles/${id}/identities?sourceId=${sourceId}&recordId=${sourceRecordId}&tab=source-data`}
                  >
                    <Text
                      alignItems="start"
                      display="flex"
                      fontWeight="normal"
                      w="140px"
                      overflow="hidden"
                      overflowX="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {orderId}
                    </Text>
                  </Button>
                </Tooltip>
              </HStack>
            )}
            <HStack spacing="1">
              {amount && <Text>{formatCurrency(amount, currency)} on</Text>}
              {startTimestamp && <Text>{formatDateString(startTimestamp, 'MMM d, y')}</Text>}
            </HStack>
          </VStack>
        </HStack>
      </Flex>
      <Accordion defaultIndex={[1]} allowMultiple border="transparent" pb="4">
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <AccordionPanel paddingInlineStart="0" paddingInlineEnd="0">
                <TableContainer px={3}>
                  <Table variant="simple">
                    {transactionsRecords?.lineItems &&
                      transactionsRecords?.lineItems.length > 0 && (
                        <Thead>
                          <Tr>
                            <Th
                              w="352px"
                              pt="24px"
                              pb="4px"
                              textTransform="none"
                              paddingInlineStart="9"
                              paddingInlineEnd="0"
                            >
                              Items purchased
                            </Th>
                            <Th
                              pt="24px"
                              pb="4px"
                              paddingInlineStart="8"
                              paddingInlineEnd="8"
                              textTransform="none"
                              isNumeric
                            >
                              Item quantity
                            </Th>
                            <Th
                              pt="24px"
                              pb="4px"
                              textTransform="none"
                              paddingInlineStart="0"
                              paddingInlineEnd="9"
                              isNumeric
                            >
                              Line item price
                            </Th>
                          </Tr>
                        </Thead>
                      )}
                    <Tbody pos="relative">
                      {transactionsRecords?.lineItems.map((data) => (
                        <Tr key={`${data.sourceId}-${data.sourceRecordId}`}>
                          <Td
                            whiteSpace="pre-wrap"
                            paddingInlineStart="9"
                            paddingInlineEnd="0"
                            py={3}
                          >
                            {data.name || ''}
                          </Td>
                          <Td isNumeric paddingInlineStart="8" paddingInlineEnd="8" py={3}>
                            {data.quantity || ''}
                          </Td>
                          <Td isNumeric py={3} paddingInlineStart="0" paddingInlineEnd="9">
                            {(data.quantity &&
                              formatCurrency(data.amount * data.quantity, currency)) ||
                              ''}
                          </Td>
                        </Tr>
                      ))}
                      <Tr>
                        <Th pt="24px" pb="4px" paddingInlineStart="9" textTransform="none">
                          Order total summary
                        </Th>
                        <Td />
                        <Td />
                      </Tr>
                      {subtotal != null && (
                        <Tr>
                          <Td paddingInlineStart="9" py={3}>
                            Subtotal
                          </Td>
                          <Td py={3} />
                          <Td isNumeric py={3} paddingInlineStart="0" paddingInlineEnd="9">
                            {formatCurrency(subtotal, currency)}
                          </Td>
                        </Tr>
                      )}
                      {reward != null && (
                        <Tr>
                          <Td paddingInlineStart="9" py={3}>
                            Reward
                          </Td>
                          <Td py={3} />
                          <Td isNumeric py={3} paddingInlineStart="0" paddingInlineEnd="9">
                            {`${reward > 0 ? '-' : ''}${formatCurrency(reward, currency)}`}
                          </Td>
                        </Tr>
                      )}
                      {discounts != null && (
                        <Tr>
                          <Td paddingInlineStart="9" py={3}>
                            Discount
                          </Td>
                          <Td py={3} />
                          <Td py={3} isNumeric paddingInlineStart="0" paddingInlineEnd="9">
                            {`${discounts > 0 ? '-' : ''}${formatCurrency(discounts, currency)}`}
                          </Td>
                        </Tr>
                      )}
                      {taxes != null && (
                        <Tr>
                          <Td py={3} paddingInlineStart="9">
                            Sales tax
                          </Td>
                          <Td py={3} />
                          <Td py={3} isNumeric paddingInlineStart="0" paddingInlineEnd="9">
                            {formatCurrency(taxes, currency)}
                          </Td>
                        </Tr>
                      )}
                      {otherFees != null && (
                        <Tr>
                          <Td py={3} paddingInlineStart="9">
                            Other charges and fees
                          </Td>
                          <Td py={3} />
                          <Td py={3} isNumeric paddingInlineStart="0" paddingInlineEnd="9">
                            {formatCurrency(otherFees, currency)}
                          </Td>
                        </Tr>
                      )}
                      {gratuity != null && (
                        <Tr>
                          <Td py={3} paddingInlineStart="9">
                            Gratuity
                          </Td>
                          <Td py={3} />
                          <Td py={3} isNumeric paddingInlineStart="0" paddingInlineEnd="9">
                            {formatCurrency(gratuity, currency)}
                          </Td>
                        </Tr>
                      )}
                      {shippingFees != null && (
                        <Tr>
                          <Td py={3} paddingInlineStart="9">
                            Shipping
                          </Td>
                          <Td py={3} />
                          <Td py={3} isNumeric paddingInlineStart="0" paddingInlineEnd="9">
                            {shippingFees > 0
                              ? `${formatCurrency(shippingFees, currency)}`
                              : 'FREE'}
                          </Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
                <Flex
                  bgColor="rgba(142, 233, 255, 0.5)"
                  justifyContent="space-between"
                  alignItems="start"
                  py={3}
                >
                  <HStack pl={3} spacing={3}>
                    <Icon iconImage={IconImage.payment} />
                    <VStack spacing="2" alignItems="start">
                      <Text fontWeight="bold">Total</Text>
                      {paymentType && <Text>Payment method: {paymentType}</Text>}
                    </VStack>
                  </HStack>
                  <Box fontWeight="bold" pr={12}>
                    {formatCurrency(amount, currency)}
                  </Box>
                </Flex>
              </AccordionPanel>
              <AccordionButton
                color="action"
                onClick={() => !isExpanded && fetchData()}
                fontWeight="semibold"
                width="fit-content"
                pb="0"
                pt="0"
                pl="48px"
              >
                {isExpanded ? 'Hide purchase details' : 'See purchase details'}
                <AccordionIcon
                  ml="2"
                  color="action"
                  borderRadius="50%"
                  borderColor="action"
                  borderWidth="thin"
                  boxSize="22px"
                />
              </AccordionButton>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </Box>
  );
}
