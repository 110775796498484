/* eslint-disable no-nested-ternary */
import { Box, HStack, Tag, TagLabel, Text } from '@chakra-ui/react';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import DataSourceIcon from '../../../../components/features/connections/DataSourceIcon/DataSourceIcon';
import { useGetDataSource } from '../../../../lib/api-client/sources/SourceData';
import { DataSource } from '../../../../lib/api-client/sources/model/DataSource';
import EditDisplayButton from './EditDisplayButton/EditDisplayButton';

interface ConnectionManagementHeaderProps {
  source: Pick<
    DataSource,
    | 'id'
    | 'sourceSystem'
    | 'name'
    | 'category'
    | 'attributeMappings'
    | 'syncDirection'
    | 'domainType'
    | 'primary'
  >;
}

function ConnectionManagementHeader({ source }: ConnectionManagementHeaderProps) {
  const { fetch } = useGetDataSource(source.id);

  const connectionType =
    source.syncDirection === 'IN'
      ? 'Ingest only'
      : source.syncDirection === 'OUT'
      ? 'Export only'
      : source.syncDirection === 'BOTH'
      ? 'Ingest and export'
      : '';

  const domainType =
    source.domainType === 'IDENTITY'
      ? 'Identity data'
      : source.domainType === 'TRANSACTION'
      ? 'Transaction data'
      : source.domainType === 'LOYALTY_PROGRAM'
      ? 'Loyalty program data'
      : source.domainType === 'CONSUMER_EVENT'
      ? 'Event data'
      : source.domainType;

  return (
    <HStack spacing={5} mb={4}>
      <DataSourceIcon sourceSystem={source.sourceSystem} boxSize={12} />
      <Box>
        <HStack>
          <Text fontWeight="bold" fontSize="2xl" lineHeight={8}>
            {source.name}
          </Text>
          {source.primary && (
            <Box>
              <Tag borderRadius="full" border="1px solid" borderColor="gray.300" bgColor="white">
                <Icon iconImage={IconImage.badgeStar} boxSize={4} mr={0.5} />
                <TagLabel fontWeight="normal">Primary</TagLabel>
              </Tag>
            </Box>
          )}
        </HStack>
        <HStack spacing={2}>
          <Text fontSize="md" lineHeight="6">
            {source.category}, {domainType}, {connectionType}
          </Text>
          <EditDisplayButton source={source} onSuccess={() => fetch()} />
        </HStack>
      </Box>
    </HStack>
  );
}
export default ConnectionManagementHeader;
