import { Page } from '../../model/common/Page';
import ApiClient, { disableAuthentication } from '../ApiClient';
import { Tenant } from './model/Tenant';
import { TenantInfo } from './model/TenantInfo';

const TenantClient = {
  getTenantInfo(emailAddress: string): Promise<Pick<Page<TenantInfo>, 'content'>> {
    return ApiClient.post<Pick<Page<TenantInfo>, 'content'>>(
      '/tenant-info',
      {
        emailAddress: emailAddress.trim(),
      },
      {
        transformRequest: disableAuthentication(),
      }
    ).then((res) => res.data);
  },

  getTenant(id: string): Promise<Tenant> {
    return ApiClient.get<Tenant>(`/tenants/${id}`).then((res) => res.data);
  },
};
export default TenantClient;
