// React router v6 doesn't have prompt on navigation built in... this gist
// is the original implementation. Hopefully, they add it back
// in a future release.
//
// Feature Request: https://github.com/remix-run/react-router/issues/8139
// https://gist.github.com/rmorse/426ffcc579922a82749934826fa9f743
import { useContext, useEffect, useCallback } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

// workaround interface to avoid importing history
export interface Transition {
  retry: () => void;
}

/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 *
 * @param  blocker
 * @param  when
 * @see https://reactrouter.com/api/useBlocker
 */
function useBlocker(blocker: any, when = true) {
  const { navigator } = useContext(NavigationContext) as any;

  useEffect(() => {
    if (!when) return;

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    // eslint-disable-next-line consistent-return
    return unblock;
  }, [navigator, blocker, when]);
}

/**
 * Prompts the user with an Alert before they leave the current screen.
 *
 * @param  callback
 * @param  when
 */
export function usePrompt(callback: (tx: Transition) => void, when = true) {
  const blocker = useCallback(
    (tx: any) => {
      callback(tx);
    },
    [callback]
  );

  useBlocker(blocker, when);
}
