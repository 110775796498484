import {
  Box,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { flexRender, Header, Row } from '@tanstack/react-table';
import { ReactNode } from 'react';
import Icon from '../../../core/Icon/Icon';
import { IconImage } from '../../../core/Icon/IconConfig';
import { useDataExplorer } from './DataExplorerContext';
import DataExplorerTableFooter from './DataExplorerTableFooter';

interface HeaderProps {
  header: Header<unknown, unknown>;
}

function HeaderResizer({ header }: HeaderProps) {
  return (
    <Flex
      position="absolute"
      top={0}
      right={0}
      height="full"
      width="5px"
      py={2}
      cursor="col-resize"
      userSelect="none"
      flexDir="column"
      rounded="lg"
      onMouseDown={header.getResizeHandler()}
    >
      <Box w="2px" h="full" background="gray.300" rounded="lg" />
    </Flex>
  );
}

function HeaderMenu({ header }: HeaderProps) {
  const isPinned = header.column.getIsPinned() === 'left';
  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={IconButton}
        variant="ghost"
        icon={<Icon iconImage={IconImage.more} />}
        display="none"
        boxSize={4}
        _groupHover={{ display: 'inline-flex' }}
      />
      <MenuList fontSize="sm">
        {header.column.getCanSort() && (
          <MenuItem onClick={header.column.getToggleSortingHandler()}>
            {header.column.getIsSorted() === 'asc' ? 'Sort column Z-A' : 'Sort column A-Z'}
          </MenuItem>
        )}
        <MenuItem onClick={header.column.getToggleVisibilityHandler()}>Hide column</MenuItem>
        <MenuItem onClick={() => header.column.pin(isPinned ? false : 'left')}>
          {isPinned ? 'Unanchor column' : 'Anchor column'}
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

function HeaderSortIcon({ header }: HeaderProps) {
  return header.column.getCanSort() && header.column.getIsSorted() ? (
    <Icon
      boxSize={4}
      iconImage={header.column.getIsSorted() === 'asc' ? IconImage.arrowUp : IconImage.arrowDown}
    />
  ) : null;
}

function stripedBackground(idx: number) {
  return idx % 2 === 0 ? 'transparent' : 'gray.50';
}

let color = 'transparent';
function groupedBackground(columnId: string, currentRow: Row<unknown>, previousRow?: Row<unknown>) {
  if (previousRow == null) {
    color = 'transparent';
    return color;
  }

  if (currentRow.getValue(columnId) !== previousRow?.getValue(columnId)) {
    color = color === 'transparent' ? 'gray.50' : 'transparent';
  }

  if (currentRow.getIsSelected()) {
    return 'highlightHover';
  }

  return color;
}

interface DataExplorerTableProps {
  groupColumnId?: string;
  onRowSelected?: (props: { index: number; data: any }) => void;
  onRowSelectedDataDetails?: (props: { index: number; data: any }) => void;
  onExport?: () => void;
  footerActions?: ReactNode;
}

export default function DataExplorerTable({
  groupColumnId,
  onRowSelected,
  onExport,
  onRowSelectedDataDetails,
  footerActions,
}: DataExplorerTableProps) {
  const { table } = useDataExplorer();
  return (
    <Flex flexFlow="column" h="full" border="1px solid" borderColor="gray.300">
      <Flex flexFlow="column" overflowX="auto" overflowY="auto" h="full" position="relative">
        <Box position="absolute" top={0} bottom={0} right={0} left={0}>
          <Table variant="explorer" w={table.getTotalSize()}>
            <Thead position="sticky" top={0} zIndex="2">
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id} boxShadow="base">
                  {headerGroup.headers.map((header) => (
                    <Th
                      key={header.id}
                      position="relative"
                      w={header.column.getSize()}
                      maxW={header.column.getSize()}
                      data-group
                    >
                      {!(header.column.columnDef.meta as any)?.emptyDisplay && (
                        <>
                          <HStack
                            spacing={1}
                            _hover={{
                              cursor:
                                header.column.getCanSort() &&
                                !(header.column.columnDef.meta as any)?.hideActions
                                  ? 'pointer'
                                  : 'default',
                            }}
                            justify="space-between"
                          >
                            <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </Box>
                            <HStack>
                              <HeaderSortIcon header={header} />
                              {!(header.column.columnDef.meta as any)?.hideActions && (
                                <HeaderMenu header={header} />
                              )}
                            </HStack>
                          </HStack>
                          <HeaderResizer header={header} />
                        </>
                      )}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row, idx) => (
                <Tr
                  key={row.id}
                  _hover={{
                    cursor: 'pointer',
                    bgColor: 'highlightHover',
                  }}
                  bgColor={
                    groupColumnId
                      ? groupedBackground(
                          groupColumnId,
                          row,
                          idx > 0 ? table.getRowModel().rows[idx - 1] : undefined
                        )
                      : stripedBackground(idx)
                  }
                  onClick={() => {
                    if ((table.options.meta as any).isEditMode && onRowSelected) {
                      onRowSelected({ index: idx, data: row.original });
                    }
                    if (!(table.options.meta as any).isEditMode && onRowSelectedDataDetails) {
                      onRowSelectedDataDetails({ index: idx, data: row.original });
                    }
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id} w={cell.column.getSize()} maxW={cell.column.getSize()}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Flex>
      <DataExplorerTableFooter
        shareUrl={(table.options.meta as any)?.shareUrl || ''}
        onExport={onExport}
        actions={footerActions}
      />
    </Flex>
  );
}
