import * as yup from 'yup';
import { ConfigAttribute } from '../../../../../lib/api-client/sources/model/SourceTypes';

// eslint-disable-next-line import/prefer-default-export
export function createFormSchema(credentialConfigs: ConfigAttribute[]) {
  const schemas = credentialConfigs.map((config) => {
    let valueSchema = yup.string();
    if (config.required) {
      valueSchema = valueSchema.required('Field is required');
    }

    if (config.validations) {
      config.validations.forEach((validation) => {
        switch (validation.type) {
          case 'LENGTH_BETWEEN':
            valueSchema = valueSchema
              .min(
                validation.intValues[0],
                `Must be at least ${validation.intValues[0]} characters`
              )
              .max(
                validation.intValues[1],
                `Must be less than ${validation.intValues[1]} characters`
              );
            break;
          case 'MATCHES_REGEX':
            valueSchema = valueSchema.matches(new RegExp(validation.stringValue), {
              message: `Must match ${validation.stringValue}`,
              excludeEmptyString: !config.required,
            });
            break;
          default:
            break;
        }
      });

      if (config.dataType === 'SECRET') {
        const copyValueSchema = valueSchema;
        valueSchema = yup.string().test(
          'is-secret',
          ({ value }) => {
            try {
              copyValueSchema.validateSync(value);
            } catch (a: any) {
              return a.message;
            }

            return '';
          },
          (val) => val === '*** Redacted value ***' || copyValueSchema.isValidSync(val)
        );
      }
    } else {
      valueSchema = yup.string().optional();
    }

    return yup.object({
      name: yup.string().required(),
      value: valueSchema,
    });
  });

  return yup.object({ data: yup.tuple(schemas as any) });
}
