import { dataIssuePercentChangeColorStrategy } from '../../../../lib/utils/color-utils';
import DataFluctuationChip from './DuplicateDataFluctuationChip';

interface DuplicateMonitorFluctuationChipProps {
  value: number;
}
export default function DuplicateMonitorFluctuationChip({
  value,
}: DuplicateMonitorFluctuationChipProps) {
  const colorScheme = dataIssuePercentChangeColorStrategy(value, 'color');
  return (
    <DataFluctuationChip
      value={value}
      colorScheme={colorScheme}
      color={colorScheme === 'neutralScheme' ? 'gray.800' : 'white'}
    />
  );
}
