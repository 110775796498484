import {
  Button,
  chakra,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { formatDistanceToNowStrict } from 'date-fns';
import React from 'react';
import FlatFileUploadIndicator from '../../../../components/core/indicator/FlatFileUploadIndicator/FlatFileUploadIndicator';
import { DataSource } from '../../../../lib/api-client/sources/model/DataSource';
import { Upload } from '../../../../lib/api-client/sources/model/Upload';
import { formatPercentage } from '../../../../lib/utils/number-utils';

interface AllFlatFileUploadsButtonProps {
  source: Pick<DataSource, 'name'>;
  uploads: Upload[];
}
export default function AllFlatFileUploadsButton({
  source,
  uploads,
}: AllFlatFileUploadsButtonProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} w="full">
        See all files
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pb={0}>
            All files for {source.name}
            <ModalCloseButton top={3} />
            <Text pt={6} fontSize="xs" fontWeight="semibold" pb={2} color="gray.600">
              {uploads.length} files total for this source connection
            </Text>
          </ModalHeader>
          <ModalBody pb={6}>
            <Table variant="simple">
              <Thead bgColor="gray.100">
                <Tr>
                  <Th>File</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {uploads
                  .sort((a, b) => -1 * a.createdAt.localeCompare(b.createdAt))
                  .map((upload) => (
                    <Tr key={upload.id}>
                      <Td>{upload.fileName}</Td>
                      <Td>
                        <FlatFileUploadIndicator status={upload.status} />
                        {upload.percentRecordsImported != null &&
                          upload.status === 'importRunning' && (
                            <chakra.span whiteSpace="nowrap">
                              ({formatPercentage(upload.percentRecordsImported)} complete,
                            </chakra.span>
                          )}
                        {upload.estimatedImportCompletion != null &&
                          upload.status === 'importRunning' && (
                            <chakra.span>
                              {formatDistanceToNowStrict(new Date(upload.estimatedImportCompletion))
                                .replace('minutes', 'mins')
                                .replace('seconds', 'secs')}{' '}
                              remaining)
                            </chakra.span>
                          )}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </ModalBody>
          <ModalFooter justifyContent="start">
            <Button variant="outline" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
