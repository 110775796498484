import { Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import { DataSourceConnectionTypes } from '../../../../lib/api-client/sources/model/DataSourceConnectionType';
import { DataSourcePrimaryDomainType } from '../../../../lib/api-client/sources/model/DataSourcePrimaryDomainType';

interface ConnectionConfigChipProps {
  label: string;
  icon?: IconImage;
}

export default function ConnectionConfigChip({ label, icon }: ConnectionConfigChipProps) {
  return (
    <Tag variant="rounded">
      {icon && <TagLeftIcon boxSize="4" as={Icon} iconImage={icon} />}
      <TagLabel>{label}</TagLabel>
    </Tag>
  );
}

interface DomainTypeConnectionConfigChipProps {
  domainType: DataSourcePrimaryDomainType;
}

export function DomainTypeConnectionConfigChip({
  domainType: domainTypeProp,
}: DomainTypeConnectionConfigChipProps) {
  let domainType: string = domainTypeProp;
  switch (domainTypeProp) {
    case 'IDENTITY':
      domainType = 'Identity data';
      break;
    case 'TRANSACTION':
      domainType = 'Transaction data';
      break;
    case 'LOYALTY_PROGRAM':
      domainType = 'Loyalty program data';
      break;
    case 'CONSUMER_EVENT':
      domainType = 'Event data';
      break;
    default:
      break;
  }

  return <ConnectionConfigChip label={domainType} icon={IconImage.file} />;
}

interface SyncDirectionConnectionConfigChipProps {
  syncDirection: DataSourceConnectionTypes;
}

export function SyncDirectionConnectionConfigChip({
  syncDirection: syncDirectionProp,
}: SyncDirectionConnectionConfigChipProps) {
  let syncDirection: string = syncDirectionProp;
  let icon: IconImage | undefined;
  switch (syncDirectionProp) {
    case 'BOTH':
      icon = IconImage.dataTransfer;
      syncDirection = 'Ingest & publish';
      break;
    case 'IN':
      icon = IconImage.dataIncoming;
      syncDirection = 'Ingest only';
      break;
    case 'OUT':
      icon = IconImage.dataOutgoing;
      syncDirection = 'Publish only';
      break;
    default:
      break;
  }

  return <ConnectionConfigChip label={syncDirection} icon={icon} />;
}
