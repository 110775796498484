import { PieChart as RechartPieChart, Pie, Cell } from 'recharts';

interface PieChartProps {
  metrics: {
    value: number;
    color: string;
  }[];
  height: number;
  width: number;
  title?: string;
}

function PieChart({ metrics, width, height, title }: PieChartProps) {
  return (
    <RechartPieChart width={width} height={height}>
      <Pie dataKey="value" data={metrics} innerRadius={40} outerRadius={57} paddingAngle={1}>
        {metrics.map((metric, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Cell key={`PieChartCell-${index}`} fill={metric!.color} />
        ))}
      </Pie>
      {title && <title>{title}</title>}
    </RechartPieChart>
  );
}
export default PieChart;
