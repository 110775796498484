import {
  Box,
  BoxProps,
  createStylesContext,
  forwardRef,
  useMultiStyleConfig,
} from '@chakra-ui/react';

const [StylesProvider, useStyles] = createStylesContext('Card');

interface CardProps extends BoxProps {
  variant?: string;
}

const Card = forwardRef<CardProps, 'div'>(({ variant, children, ...rest }: CardProps, ref) => {
  const styles = useMultiStyleConfig('Card', { variant });
  return (
    <Box __css={styles.card} ref={ref} {...rest}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </Box>
  );
});

export default Card;
export const useCardStyles = useStyles;
