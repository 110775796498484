import { useFetch, useSchema } from '@hazae41/xswr';
import { getApiClientSchema } from '../ApiClient';
import { IdentityMetrics } from './model/IdentityDataSourceMetrics';

export function getIdentitySourceMetricsSchema() {
  return getApiClientSchema<IdentityMetrics>({
    method: 'get',
    url: `/identity-source-metrics`,
  });
}

export function useGetIdentitySourceMetrics() {
  const query = useSchema(getIdentitySourceMetricsSchema, []);
  useFetch(query);
  return query;
}
