import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

interface Context {
  headerHeight: string | number;
  setHeaderHeight: (headerHeight: string | number) => void;
}

export const PageContext = createContext<Context | null>(null);

interface PageContextProviderProps {
  children: ReactNode;
}

export function PageContextProvider({ children }: PageContextProviderProps) {
  const [headerHeight, setHeaderHeight] = useState<string | number>(0);

  const value = useMemo(
    () => ({
      headerHeight,
      setHeaderHeight: (height: string | number) => {
        setHeaderHeight(height);
      },
    }),
    [headerHeight]
  );

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
}

export function usePage(): Context {
  const context = useContext(PageContext);

  if (context == null) {
    throw new Error('PageContext is null. Have you wrapped PageContextProvider?');
  }

  return context;
}
