import { Text } from '@chakra-ui/react';
import { formatRelativeDateString } from '../../../../lib/utils/date-time-utils';

function getProfileModified(lastModifiedBy?: string, lastModifiedAt?: string) {
  let message = 'This version represents an update ingested from the source system';

  if (lastModifiedBy) {
    if (lastModifiedBy === 'data-repair') {
      message = 'This version was updated by the data repair automation';
    } else {
      message = `This version was saved by ${lastModifiedBy}`;
    }
  }

  if (lastModifiedAt) {
    message = `${message} on ${formatRelativeDateString(lastModifiedAt)}`;
  }

  return message;
}

export default function ProfileModified({
  lastModifiedBy,
  lastModifiedAt,
}: {
  lastModifiedBy?: string;
  lastModifiedAt?: string;
}) {
  const message = getProfileModified(lastModifiedBy, lastModifiedAt);

  return <Text> {message}</Text>;
}
