import { ISODateString } from '../../../model/common';

export enum AttributeMergeRule {
  CREATED_LAST = 'createdLast',
  CREATED_FIRST = 'createdFirst',
  UPDATED_LAST = 'modifiedLast',
  UPDATED_FIRST = 'modifiedFirst',
  TRUE_OVER_FALSE = 'trueOverFalse',
  FALSE_OVER_TRUE = 'falseOverTrue',
  HIGHEST_SCORE = 'highestScore',
}

export enum PrimaryRecordMergeRule {
  CREATED_LAST = 'createdLast',
  CREATED_FIRST = 'createdFirst',
  UPDATED_LAST = 'modifiedLast',
  UPDATED_FIRST = 'modifiedFirst',
}

export function mergeRuleLabels(rule: AttributeMergeRule | PrimaryRecordMergeRule | string) {
  switch (rule) {
    case 'createdFirst':
      return 'Least recently created';
    case 'createdLast':
      return 'Most recently created';
    case 'modifiedFirst':
      return 'Least recently modified';
    case 'modifiedLast':
      return 'Most recently modified';
    case 'trueOverFalse':
      return 'True over false';
    case 'falseOverTrue':
      return 'False over true';
    case 'highestScore':
      return 'Highest score';
    default:
      return rule;
  }
}

export interface SourceMergeRules {
  primaryRecordRule: PrimaryRecordMergeRule;
  attributeRules: {
    attributeName: string;
    rule: AttributeMergeRule;
  }[];
  lastModifiedBy?: string;
  lastModifiedAt?: ISODateString;
}
