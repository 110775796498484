import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';

interface AttributeCompletenessFilterProps {
  fieldName: string;
  label: string;
  disabled?: boolean;
}
export default function AttributeCompletenessFilter({
  label,
  disabled,
  fieldName,
}: AttributeCompletenessFilterProps) {
  const form = useFormContext();

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Select {...form.register(fieldName)} disabled={disabled}>
        <option value="all">All</option>
        <option value="complete">Complete</option>
        <option value="incomplete">Incomplete</option>
      </Select>
    </FormControl>
  );
}
