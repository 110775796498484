/* eslint-disable no-unsafe-optional-chaining */

/* eslint-disable react/no-array-index-key */

/* eslint-disable no-nested-ternary */
import {
  Box,
  Button,
  Circle,
  Divider,
  GridItem,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverAnchor,
  PopoverContent,
  Select,
  SimpleGrid,
  Stack,
  Tag,
  TagLabel,
  Text,
  TextProps,
  useDisclosure,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IdentityMetrics } from '../../../lib/api-client/source-metrics/model/IdentityDataSourceMetrics';
import theme from '../../../styles/theme/theme';
import Icon from '../Icon/Icon';
import { IconImage } from '../Icon/IconConfig';
import BubbleChartTooltip from './BubbleChartTooltip';

interface CertificationConfigurationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function CertificationConfigurationModal({
  isOpen,
  onClose,
}: CertificationConfigurationModalProps) {
  const semiboldLabel: TextProps = {
    fontWeight: 'semibold',
    fontSize: 'xs',
    color: 'gray.600',
  };

  const normalLabel: TextProps = {
    fontWeight: 'normal',
    fontSize: '14px',
    color: 'gray.800',
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight="bold" fontSize="xl">
          Certification configuration
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Stack>
            <Stack alignItems="start" spacing={0} fontSize="sm">
              <Text {...semiboldLabel}>Complete</Text>
              <Text {...normalLabel}>First name, last name, email address, phone number</Text>
            </Stack>
            <Stack alignItems="start" spacing={0} fontSize="sm">
              <Text {...semiboldLabel}>Recognized</Text>
              <Text {...normalLabel}>First name, last name</Text>
            </Stack>
            <Stack alignItems="start" spacing={0} fontSize="sm">
              <Text {...semiboldLabel}>Valid</Text>
              <Text {...normalLabel}>Email address, phone number</Text>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter justifyContent="start">
          <Button variant="outline" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

const rangeValue = [
  { rangeValue: '100', color: theme.colors.success },
  { rangeValue: '99 - 90', color: theme.colors.success },
  { rangeValue: '89 - 80 ', color: theme.colors.warning },
  { rangeValue: '79 - 70', color: theme.colors.warning },
  { rangeValue: '69 - 60 ', color: theme.colors.warning },
  { rangeValue: '59 - 50', color: theme.colors.warning },
  { rangeValue: '49 - 40', color: theme.colors.warning },
  { rangeValue: '39 - 30', color: '#A0AEC0' },
  { rangeValue: '29 - 20 ', color: theme.colors.warning },
  { rangeValue: '19 - 10', color: theme.colors.warning },
  { rangeValue: '9 - 1', color: theme.colors.error },
  { rangeValue: '0', color: theme.colors.error },
];
interface IdentityMetricsProps {
  identityMetrics: IdentityMetrics;
  handleIdentityType: (item: string) => void;
}
export default function BubbleChart({ identityMetrics, handleIdentityType }: IdentityMetricsProps) {
  const [identityType, setIdentityType] = useState('all');

  const authenticityMetrics: any =
    identityType === 'all'
      ? identityMetrics?.metrics?.certificationTypes
      : identityType === 'business'
      ? identityMetrics?.identityTypes?.business?.certificationTypes
      : identityType === 'person'
      ? identityMetrics?.identityTypes?.person?.certificationTypes
      : identityType === 'household'
      ? identityMetrics?.identityTypes?.household?.certificationTypes
      : identityMetrics?.identityTypes?.unknown?.certificationTypes;

  const [hoverIndex, setHoverIndex] = useState(-1);
  const handleMouseOver = (index: number) => {
    setHoverIndex(index);
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const handleMouseOut = () => {
    setHoverIndex(-1);
  };

  const totalIdentities = authenticityMetrics?.all?.totalIdentities ?? 0;
  const scoreDistributions = authenticityMetrics?.all?.scoreDistributions ?? [];
  const circles = [...scoreDistributions]
    .reverse()
    .map(
      (distribution) =>
        Math.max(Math.round((distribution.count / (totalIdentities || 1)) * 80) + 10),
      80
    );

  const handleChange = (item: any) => {
    setIdentityType(item);
    handleIdentityType(item);
  };

  const makeSearchParams = (scoreRange: any = null) => {
    let url = identityType === 'all' ? '?' : `?pinType=${identityType}`;

    if (scoreRange?.indexOf('-') !== -1) {
      const firstValue = scoreRange.split(' ')[2];
      const secondValue = scoreRange.split(' ')[0];
      url += `&authenticityValue=specific&authenticityScore=between&authenticityScoreValue1=${firstValue}&authenticityScoreValue2=${secondValue}`;
    } else {
      url += `&authenticityValue=specific&authenticityScore=equal&authenticityScoreValue1=${scoreRange}`;
    }

    return url;
  };

  const handleClick = (value: any) => {
    const searchParams = makeSearchParams(value);
    navigate(`/data-explorer/profile/${searchParams}`);
  };
  const initialFocusRef = useRef<any>();

  return (
    <>
      <HStack justifyContent="space-between">
        <Select
          w="300px"
          onChange={(e) => handleChange(e.target.value)}
          value={identityType}
          mb="2"
        >
          <option value="all"> Show: All profile types </option>
          <option value="person">Show: Individual consumer only </option>
          <option value="household">Show: Household only</option>
          <option value="business">Show: Business only</option>
          <option value="unknown">Show: Unknown only</option>
        </Select>
        <CertificationConfigurationModal isOpen={isOpen} onClose={onClose} />
        <Button
          variant="ghost"
          leftIcon={<Icon iconImage={IconImage.info} boxSize={4} />}
          onClick={onOpen}
        >
          How is certification determined?
        </Button>
      </HStack>
      <Stack
        spacing={0}
        alignItems="flex-start"
        bgColor="gray.50"
        px="2"
        py="1"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="lg"
      >
        <Text fontWeight="semibold" color="gray.600" mt={2}>
          Certification score <Icon iconImage={IconImage.arrowForward} boxSize="5" />
        </Text>
        <SimpleGrid columns={12} justifyItems="center" w="full" px="2" pt="4">
          {rangeValue.map((range, index) => (
            <GridItem key={range.rangeValue}>
              <Popover
                initialFocusRef={initialFocusRef}
                placement="top"
                isOpen={hoverIndex === index}
              >
                <PopoverAnchor>
                  <Tag
                    bg="white"
                    w="16"
                    justifyContent="center"
                    border="1px solid"
                    borderColor="gray.200"
                    borderRadius="full"
                    onMouseOver={() => handleMouseOver(index)}
                    onMouseOut={() => handleMouseOut()}
                    onClick={() => handleClick(rangeValue[index].rangeValue)}
                    cursor="pointer"
                    ref={initialFocusRef}
                  >
                    <TagLabel fontSize="xs" fontWeight="semibold">
                      {range.rangeValue}
                    </TagLabel>
                  </Tag>
                </PopoverAnchor>
                <PopoverContent>
                  <BubbleChartTooltip
                    identityType={identityType}
                    mostFrequentTagValue={
                      authenticityMetrics?.all?.scoreDistributions[
                        authenticityMetrics?.all?.scoreDistributions?.length - (index + 1)
                      ].certificationType
                    }
                    count={
                      authenticityMetrics?.all?.scoreDistributions[
                        authenticityMetrics?.all?.scoreDistributions?.length - (index + 1)
                      ].count
                    }
                    scoreRange={rangeValue[index].rangeValue}
                  />
                </PopoverContent>
              </Popover>
            </GridItem>
          ))}
          {Array.from({ length: 12 }).map((_, index) => (
            <GridItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onMouseOver={() => handleMouseOver(index)}
              onMouseOut={() => handleMouseOut()}
              cursor="pointer"
            >
              <Divider orientation="vertical" h="20" />
            </GridItem>
          ))}
          {circles.map((size, index) => {
            let color = 'success';
            if (index > 1) {
              color = 'warning';
            }
            if (index > 10) {
              color = 'error';
            }
            return (
              <GridItem
                // eslint-disable-next-line react/no-array-index-key
                key={`circle-${index}`}
                zIndex="2"
                h="20"
                alignContent="center"
                onMouseOver={() => handleMouseOver(index)}
                onMouseOut={handleMouseOut}
                onClick={() => handleClick(rangeValue[index].rangeValue)}
                cursor="pointer"
              >
                <Circle size={`${size}px`} bg={color} />
              </GridItem>
            );
          })}
          <GridItem colSpan={12} w="full" zIndex="1">
            <Box bg="black" h="1px" w="full" mt="-40px" />
          </GridItem>
        </SimpleGrid>
      </Stack>
    </>
  );
}
