// eslint-disable-next-line import/prefer-default-export
export const FeatureFlags = {
  SHOW_RELEASE_NOTES: 'show_release_notes',
  SHOW_PROFILE_FEEDBACK: 'show_profile_feedback',
  SHOW_DORA_REPORT_VIEW: 'show_dora_report_view',
  SHOW_DUPLICATE_MONITOR_TRAY: 'show_duplicate_monitor_tray',
  SHOW_SOURCE_CONNECTION_DASHBOARD: 'show_source_connection_dashboard',
  SHOW_REFACTORED_SETUP_CONNECTION: 'show_refactored_setup_connection',
  SHOW_ENTERPRISE_DASHBOARD: 'show_enterprise_dashboard',
  SHOW_CONNECTION_CONFIGURATION_PAGE: 'show_connection_configuration_page',
};
