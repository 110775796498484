import { SimpleGrid } from '@chakra-ui/react';
import { useState } from 'react';
import FileStatusSection from './FileStatusSection';
import IngestDataSection from './IngestDataSection';

export default function FlatFileSourceConfiguration() {
  const [uploadId, setUploadId] = useState<string>('');
  return (
    <SimpleGrid columns={3} gap={5}>
      <IngestDataSection uploadId={uploadId} />
      <FileStatusSection onUploadComplete={(id) => setUploadId(id)} />
    </SimpleGrid>
  );
}
