import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys);

const rounded = definePartsStyle({
  container: {
    bg: 'white',
    color: 'color.600',
    borderRadius: 'full',
    border: '1px solid',
    borderColor: 'gray.300',
  },
  label: {
    fontWeight: 'normal',
    fontSize: 'xs',
  },
});

export const tagTheme = defineMultiStyleConfig({
  variants: {
    rounded,
  },
});
