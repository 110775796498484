import { ISODateString, Nullable } from '../../../model/common';
import { DataSource } from '../../sources/model/DataSource';

/*
 * @deprecated use identityAttributes instead
 */
export interface IdentityNameAttribute {
  firstName?: string;
  middleName?: string;
  lastName?: string;
}

/*
 * @deprecated use identityAttributes instead
 */
export interface IdentityAddressAttribute {
  streetAddress?: string;
  localMunicipality?: string;
  city?: string;
  governingDistrict?: string;
  postalCode?: string;
  countryCode?: string;
}

/*
 * @deprecated use identityAttributes instead
 */
export interface AllPhoneAttributes {
  type: string;
  value: string;
}

/*
 * @deprecated use identityAttributes instead
 */
export interface IdentityPhoneAttribute {
  objectValues: AllPhoneAttributes[];
}

/*
 * @deprecated use identityAttributes instead
 */
export interface VerifiableIdentityObjectAttribute<T> {
  objectValues: T[];
  verified?: boolean;
}

/*
 * @deprecated use identityAttributes instead
 */
export interface VerifiableIdentityStringAttribute {
  stringValues: string[];
  verified?: boolean;
  value?: string[];
}

export interface IdentityDataSource {
  source: DataSource;
  identitySourceDetails: {
    dataPresent: boolean;
    lastUpdated: string;
  };
}

export interface IdentityTag {
  key: string;
  value: string;
  score?: number;
}

export interface IdentityAttributeValue {
  value: string;
  components: {
    type: string;
    value: Nullable<string>;
    score: Nullable<number>;
  }[];
}

export interface PrimaryIdentityAttributeValue extends IdentityAttributeValue {
  overridden: boolean;
  overriddenAt?: ISODateString;
  overriddenBy?: string;
}

export interface IdentityAttribute {
  primaryValue: PrimaryIdentityAttributeValue;
  otherValues: IdentityAttributeValue[];
}

export interface IdentityAttributes {
  accountId: Nullable<IdentityAttribute>;
  address: Nullable<IdentityAttribute>;
  dateOfBirth: Nullable<IdentityAttribute>;
  emailAddresses: Nullable<IdentityAttribute>;
  emailAddress: Nullable<IdentityAttribute>;
  gender: Nullable<IdentityAttribute>;
  homePhone: Nullable<IdentityAttribute>;
  ipAddress: Nullable<IdentityAttribute>;
  mobilePhone: Nullable<IdentityAttribute>;
  name: Nullable<IdentityAttribute>;
  names: Nullable<IdentityAttribute>;
  phone: Nullable<IdentityAttribute>;
  workPhone: Nullable<IdentityAttribute>;
  city: Nullable<IdentityAttribute>;
  country: Nullable<IdentityAttribute>;
  countryCode: Nullable<IdentityAttribute>;
  governingDistrict: Nullable<IdentityAttribute>;
  localMunicipality: Nullable<IdentityAttribute>;
  postalCode: Nullable<IdentityAttribute>;
  ccLast4: Nullable<IdentityAttribute>;
  linkingKey1: Nullable<IdentityAttribute>;
  linkingKey2: Nullable<IdentityAttribute>;
  linkingKey3: Nullable<IdentityAttribute>;
  optInEmail: Nullable<IdentityAttribute>;
  optInPhone: Nullable<IdentityAttribute>;
  optInText: Nullable<IdentityAttribute>;
}

export type IdentityAttributesWithComponentKeys =
  | keyof IdentityAttributes
  | 'firstName'
  | 'firstNames'
  | 'lastNames'
  | 'firstNameScore'
  | 'lastNameScore'
  | 'middleName'
  | 'lastName'
  | 'nameSuffix';

export type IdentityAttributesWithComponentKeysScore =
  | keyof IdentityAttributes
  | 'firstNameScore'
  | 'middleName'
  | 'lastNameScore'
  | 'nameSuffix';

export interface Identity {
  pin: string;
  completeness: number;
  /*
   * @deprecated use identityAttributes instead
   */
  attributes: {
    name: VerifiableIdentityObjectAttribute<IdentityNameAttribute>;
    address: VerifiableIdentityObjectAttribute<IdentityAddressAttribute>;
    emailAddress: VerifiableIdentityStringAttribute;

    dateOfBirth: VerifiableIdentityStringAttribute;
    accountId: VerifiableIdentityStringAttribute;
    phone: IdentityPhoneAttribute;
  };
  identityAttributes: IdentityAttributes;
  sources?: IdentityDataSource[];
  tags?: IdentityTag[];
  pinType?: string;
}

export interface IdentityDataKeys {
  normalized: string;
  modified: string;
  raw: string;
}

export interface IdentityDataEmailAddressesKeys {
  emailAddress: string;
  emailAddressModified: string;
  emailAddressRaw: string;
  emailAddressScore: string;
  emailAddressVerificationMessage: string;
  primaryIndicator: string;
}

export interface IdentityDataNamesKeys {
  firstName: string;
  firstNameModified: string;
  firstNameRaw: string;
  firstNameScore: number;
  middleName: string;
  middleNameRaw: string;
  middleNameModified: string;
  lastName: string;
  lastNameRaw: string;
  lastNameModified: string;
  lastNameScore: number;
  nameSuffix: string;
  nameSuffixRaw: string;
  nameSuffixModified: string;
  primaryIndicator: boolean;
}

export interface IdentityData {
  pin: string;
  recordId: string;
  sourceId: string;
  dataNetwork: string;
  tenantId: string;
  sourceDomain?: string;
  sourceRecordId?: string;
  sourceIdentityId?: string;
  sourceRecordTimestamp?: ISODateString;
  accountId?: string;
  firstName?: IdentityDataKeys;
  middleName?: IdentityDataKeys;
  lastName?: IdentityDataKeys;
  nameSuffix?: IdentityDataKeys;
  streetAddress?: IdentityDataKeys;
  localMunicipality?: IdentityDataKeys;
  city?: IdentityDataKeys;
  governingDistrict?: IdentityDataKeys;
  postalCode?: IdentityDataKeys;
  zip4?: IdentityDataKeys;
  country?: IdentityDataKeys;
  countryCode?: IdentityDataKeys;
  emailAddresses?: IdentityDataEmailAddressesKeys[];
  names?: IdentityDataNamesKeys[];
  emailAddressRaw?: string;
  homePhone?: IdentityDataKeys;
  mobilePhone?: IdentityDataKeys;
  workPhone?: IdentityDataKeys;
  dateOfBirth?: IdentityDataKeys;
  ipAddress?: string;
  gender?: string;
  ccLast4?: string;
  paymentFingerprint?: string;
  ingestionTime: ISODateString;
  lastUpdated: ISODateString;
  resolved: boolean;
  lastModifiedBy?: ISODateString;
  lastModifiedAt?: ISODateString;
}

export interface RecordsData {
  pin: string;
  recordId: string;
  sourceId: string;
  dataNetwork: string;
  tenantId: string;
  sourceDomain?: string;
  sourceRecordId?: string;
  sourceIdentityId?: string;
  sourceRecordTimestamp?: ISODateString;
  accountId?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  streetAddress?: string;
  localMunicipality?: string;
  city?: string;
  governingDistrict?: string;
  postalCode?: string;
  zip4?: string;
  country?: string;
  countryCode?: string;
  emailAddress?: string;
  emailAddressRaw?: string;
  homePhone?: string;
  mobilePhone?: string;
  workPhone?: string;
  dateOfBirth?: string;
  ipAddress?: string;
  gender?: string;
  ccLast4?: string;
  paymentFingerprint?: string;
  ingestionTime: ISODateString;
  lastUpdated: ISODateString;
  resolved: boolean;
  lastModifiedBy: string;
  lastModifiedAt: string;
}

export interface Attributes {
  firstName: number | undefined;
  lastName: number | undefined;
  mobilePhone: number | undefined;
  homePhone: number | undefined;
  workPhone: number | undefined;
  emailAddress: number | undefined;
  dateOfBirth: number | undefined;
  emailAddressScore: number | undefined;
  firstNameScore: number | undefined;
  lastNameScore: number | undefined;
  middleNameScore: number | undefined;
}

export interface ScoresData {
  recordScore: number;
  attributeScores: Attributes;
}
export interface VersionData {
  record: RecordsData;
  scores: ScoresData;
  identityRecord: IdentityData;
}

export interface IdentityRecord {
  /**
   * @deprecated use identityRecord
   */
  record: RecordsData;
  scores: ScoresData;
  identityRecord: IdentityData;
}
export interface IdentityRecords {
  content: IdentityRecord[];
}
export interface ProfileTags {
  tagKey: string;
  tagValue?: string;
  tagScore?: number;
}

function capitalize(s?: string) {
  if (s == null) {
    return s;
  }

  return s
    .toLowerCase()
    .split(' ')
    .map((part) => {
      if (part === '') {
        return part;
      }
      return part.replace(part.charAt(0), part.charAt(0).toUpperCase());
    })
    .join(' ');
}

export function normalizeIdentity(i: Identity): Identity {
  if (!i) {
    return i;
  }

  const identity = i;
  identity.attributes.emailAddress.stringValues = identity.attributes.emailAddress.stringValues.map(
    (s) => s.toLowerCase()
  );
  identity.attributes.name.objectValues = identity.attributes.name.objectValues.map((value) => ({
    ...value,
    firstName: capitalize(value.firstName),
    lastName: capitalize(value.lastName),
    middleName: capitalize(value.middleName),
  }));

  // FIXME the mock data from the API was missing address. This
  // is incorrect response based on the spec. We should remove this
  // once its implemented. The address attribute should always be
  // available with an empty list for object values
  if (identity.attributes.address) {
    identity.attributes.address.objectValues = identity.attributes.address.objectValues.map(
      (value) => ({
        ...value,
        streetAddress: capitalize(value.streetAddress),
        city: capitalize(value.city),
        governingDistrict: capitalize(value.governingDistrict)?.toUpperCase(),
        localMunicipality: capitalize(value.localMunicipality),
        postalCode: value.postalCode?.toUpperCase(),
        countryCode: value.countryCode?.toUpperCase(),
      })
    );
  }

  return identity;
}
