import { HStack, Stack, Tag, VStack, chakra, Text, Box } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import TimeSeriesFilter, {
  SelectedTimeSeriesFilter,
  filterConfig,
} from '../../../components/shared/visualizations/TimeSeriesFilter/TimeSeriesFilter';
import { useTimeSeriesMetrics } from '../../../lib/api-client/data-repair/DataRepairData';
import { formatDateString, getCurrentTimezone } from '../../../lib/utils/date-time-utils';
import { formatNumber } from '../../../lib/utils/number-utils';

function ToolTipContent({ payload }: any) {
  const startDateLabel =
    payload &&
    payload[0]?.payload?.label !== undefined &&
    new Date(payload[0]?.payload?.fullDate).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });

  const pv = payload && payload[0]?.payload?.value1 !== undefined ? payload[0]?.payload?.value1 : 0;
  const amt =
    payload && payload[1]?.payload?.value2 !== undefined ? payload[1]?.payload?.value2 : 0;

  const totalRepairMetrics = amt + pv;

  return (
    <Box bgColor="gray.50" p={2} shadow="md" boxShadow="md" borderRadius="6px">
      <Text fontSize="md" fontWeight="bold">
        {formatNumber(totalRepairMetrics)} total duplicates
      </Text>
      <Text>on {startDateLabel}</Text>
      <VStack alignItems="start" mt="2">
        <HStack>
          <Tag variant="solid" bgColor="#8EE9FF" size="sm">
            {' '}
          </Tag>
          <chakra.span>{amt ?? 0} </chakra.span>
        </HStack>
        <HStack>
          <Tag variant="solid" bgColor="#007FA7" size="sm">
            {' '}
          </Tag>
          <chakra.span>{pv ?? 0}</chakra.span>
        </HStack>
      </VStack>
    </Box>
  );
}

export default function DuplicateMonitorChart() {
  const now = useMemo(() => new Date(), []);
  const [filter, setFilter] = useState<SelectedTimeSeriesFilter>({
    interval: 'day',
    config: filterConfig.day[0],
  });
  const { id = '' } = useParams();
  const { data: timeseries } = useTimeSeriesMetrics(
    id,
    ['most_recent:duplicatesSameEmail', 'most_recent:duplicatesDifferentEmail'],
    filter.config.getStartDate(now),
    now,
    getCurrentTimezone(),
    filter.interval
  );
  const onFilterChanged = (selectedFilter: SelectedTimeSeriesFilter) => {
    setFilter(selectedFilter);
  };
  const sameEmail = timeseries?.series[0]?.dataPoints;
  const diffEmail = timeseries?.series[1]?.dataPoints;

  const timeSeriesSameEmail =
    sameEmail &&
    sameEmail.map((item: any) => ({
      pv: item?.value,
      mvt: item?.startDate,
    }));

  const timeSeriesDifferentEmail: any =
    diffEmail &&
    diffEmail.map((item: any) => ({
      amt: item?.value || 0,
    }));

  const arr =
    timeSeriesSameEmail &&
    timeSeriesDifferentEmail &&
    timeSeriesSameEmail?.map((obj: any, index: any) => ({
      label: formatDateString(obj?.mvt, 'M/d'),
      value1: obj?.pv || 0,
      value2: timeSeriesDifferentEmail[index]?.amt || 0,
      fullDate: obj?.mvt,
    }));

  return (
    <VStack alignItems="start" display="flex" justifyContent="space-between">
      <Stack
        p={2}
        bgColor="#EDF2F7"
        borderRadius="6px"
        border="1px"
        borderColor="gray.100"
        position="relative"
      >
        <TimeSeriesFilter onFilterChanged={onFilterChanged} bgColor hideHourFilter />
        <BarChart
          width={436}
          height={190}
          data={arr}
          margin={{
            top: 20,
            right: 6,
            left: 5,
            bottom: 5,
          }}
          barSize={15}
          barGap={20}
        >
          <XAxis dataKey="label" tick={{ fontSize: 10 }} interval={0} />
          <YAxis />
          <Tooltip content={<ToolTipContent interval={filter.interval} />} />
          <Bar dataKey="value1" stackId="a" fill="#007FA7" radius={[4, 4, 0, 0]} />
          <Bar dataKey="value2" stackId="a" fill="#8EE9FF" radius={[4, 4, 0, 0]} />
        </BarChart>
      </Stack>
      <SectionContainer p={3} display="flex">
        <VStack alignItems="start" spacing={2}>
          <Text>Key</Text>
          <HStack>
            <Tag variant="solid" bgColor="#8EE9FF" size="sm">
              {' '}
            </Tag>
            <chakra.span>Total duplicate data records with different emails </chakra.span>
          </HStack>
          <HStack>
            <Tag variant="solid" bgColor="action" size="sm">
              {' '}
            </Tag>
            <chakra.span>Total duplicate data records with the same email</chakra.span>
          </HStack>
        </VStack>
      </SectionContainer>
    </VStack>
  );
}
