import { createContextualCan } from '@casl/react';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import AuthorizationService, { AppAbilityType } from '../lib/services/AuthorizationService';
import { useAuth } from './AuthenticationContext';

const defaultAbility = AuthorizationService.buildDefaultAbility();
const Context = createContext<AppAbilityType>(defaultAbility);

interface AuthorizationProviderProps {
  children: ReactNode;
}

export function AuthorizationProvider({ children }: AuthorizationProviderProps) {
  const { currentUser } = useAuth();
  const [ability, setAbility] = useState<AppAbilityType>(
    currentUser ? AuthorizationService.buildAbilityForGroups(currentUser.groups) : defaultAbility
  );

  useEffect(() => {
    if (currentUser) {
      setAbility(AuthorizationService.buildAbilityForGroups(currentUser.groups));
    } else {
      setAbility(defaultAbility);
    }
  }, [currentUser, setAbility]);

  return <Context.Provider value={ability}>{children}</Context.Provider>;
}

export const Can = createContextualCan(Context.Consumer);

export const useAbility = () => useContext(Context);
