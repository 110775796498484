import { Box, Button, chakra, Flex, Grid, HStack, Select, Stack } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import Alert from '../../../components/core/Alert/Alert';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import DataSourceIcon from '../../../components/features/connections/DataSourceIcon/DataSourceIcon';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import {
  recordCategoryToLabel,
  SourceIdentityRecordCategory,
} from '../../../lib/api-client/identity/model/SourceIdentityRecordCategory';
import {
  recordGroupToLabel,
  SourceIdentityRecordGroup,
} from '../../../lib/api-client/identity/model/SourceIdentityRecordGroup';
import { useSourceIssuesForSource } from '../../../lib/api-client/sources/SourceIssuesData';
import { SourceIssue } from '../../../lib/api-client/sources/model/SourceIssue';
import { SourceIssuePriority } from '../../../lib/api-client/sources/model/SourceIssuePriority';
import { formatNumber } from '../../../lib/utils/number-utils';
import ExportSourceIssueDataRecordCsvButton from '../components/ExportSourceIssueDataRecordCsvButton/ExportSourceIssueDataRecordCsvButton';
import { useCurrentDataSource } from '../context/CurrentDataSourceContext';
import SourceIssuePriorityTag from './SourceIssuePriorityTag';

export const CATEGORY_QUERY_PARAM = 'category';
export const PRIORITY_QUERY_PARAM = 'priority';
export const PRIORITY_QUERY_RECORD_GROUP = 'recordGroup';
export const PRIORITY_QUERY_RECORD_CATEGORY = 'recordCategory';

function DataQualityIssueItem({ issue }: { issue: SourceIssue }) {
  let recordCategoryQuery = `recordCategory=${issue.type}`;
  if (issue.group === SourceIdentityRecordGroup.Duplicates) {
    recordCategoryQuery = `duplicates=duplicate`;
  }

  return (
    <Flex direction="column" w="full">
      <Box mb="-14px" ml={3} zIndex="3">
        <SourceIssuePriorityTag value={issue.priority} />
      </Box>
      <HStack
        p={3}
        spacing={3}
        border="1px solid"
        borderColor="gray.100"
        rounded="md"
        boxShadow="base"
        w="full"
      >
        <Grid templateColumns="1fr 168px" gridRowGap={1} gap={3} w="full" fontSize="sm">
          <chakra.span fontWeight="bold">{recordGroupToLabel(issue.group)}</chakra.span>
          <chakra.span fontSize="xs" fontWeight="semibold" color="gray.600">
            Total records
          </chakra.span>
          <chakra.span>{recordCategoryToLabel(issue.type)}</chakra.span>
          <chakra.span fontWeight="bold">{formatNumber(issue.count)}</chakra.span>
        </Grid>

        {issue.type === SourceIdentityRecordCategory.Duplicates ? (
          <Box>
            <Button
              as={Link}
              to={`/sources/${issue.sourceId}/data-explorer?${recordCategoryQuery}`}
            >
              Review records
            </Button>
          </Box>
        ) : (
          <Box>
            <Button
              as={Link}
              to={`/sources/${issue.sourceId}/issues-details?recordGroup=${issue.group}&recordCategory=${issue.type}`}
            >
              Review records
            </Button>
          </Box>
        )}

        <ExportSourceIssueDataRecordCsvButton issue={issue} />
      </HStack>
    </Flex>
  );
}

export default function DataQualityIssuesPage() {
  const { id = '' } = useParams();
  const [searchParams] = useSearchParams();
  const { dataSource } = useCurrentDataSource();
  const [selectedPriority, setSelectedPriority] = useState<SourceIssuePriority | undefined>(
    searchParams.get(PRIORITY_QUERY_PARAM) as SourceIssuePriority
  );
  const [selectedCategory, setSelectedCategory] = useState<SourceIdentityRecordCategory | string>(
    searchParams.get(CATEGORY_QUERY_PARAM) ?? ''
  );

  const {
    data: sourceIssues,
    loading: sourceIssuesLoading,
    error: sourceIssuesError,
  } = useSourceIssuesForSource(id, selectedPriority);

  const filteredIssues = useMemo(
    () =>
      sourceIssues?.content?.filter(
        (issue) => !selectedCategory || selectedCategory === issue.group
      ) ?? [],
    [selectedCategory, sourceIssues?.content]
  );
  const total = useMemo(
    () => filteredIssues.reduce((prev, current) => prev + current.count, 0),
    [filteredIssues]
  );

  let isInitialLoad = true;
  let initializationError = false;

  if (isInitialLoad && sourceIssuesError) {
    initializationError = true;
  }

  if (isInitialLoad && !sourceIssuesLoading) {
    isInitialLoad = false;
  }

  return (
    <PageLayout
      pageViewEvent={{ page: 'Data Quality Issue', pageId: id }}
      header={
        <Header
          icon={<DataSourceIcon sourceSystem={dataSource.sourceSystem} />}
          title={`${dataSource.name} data quality issues`}
        />
      }
      initializationError={initializationError}
      loading={isInitialLoad}
    >
      <SectionContainer title="All issues impacting data quality">
        <HStack justify="space-between">
          <HStack spacing={5}>
            <Box>
              <Select
                minW="256px"
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value as any)}
              >
                <option value="">Category: All</option>
                {Object.values(SourceIdentityRecordGroup).map((type) => (
                  <option key={`type-${type}`} value={type}>
                    Category: {recordGroupToLabel(type)}
                  </option>
                ))}
              </Select>
            </Box>
            <Box>
              <Select
                minW="256px"
                disabled={sourceIssuesLoading}
                value={selectedPriority ?? ''}
                onChange={(e) => setSelectedPriority(e.target.value as any)}
              >
                <option value="">Priority: All</option>
                <option value="high">Priority: High</option>
                <option value="medium">Priority: Medium</option>
                <option value="low">Priority: Low</option>
              </Select>
            </Box>
          </HStack>
          <HStack>
            <Icon iconImage={IconImage.databaseError} />
            <chakra.span fontSize="sm" fontWeight="bold">
              {formatNumber(total)} total records
            </chakra.span>
          </HStack>
        </HStack>
        <Stack pt={5}>
          {filteredIssues.length < 1 && (
            <Alert
              status="success"
              variant="subtle"
              title="All clear!"
              description="There are no data quality issues detected in this category."
            />
          )}
          {filteredIssues.map((issue) => (
            <DataQualityIssueItem key={`${issue.group}-${issue.type}`} issue={issue} />
          ))}
        </Stack>
      </SectionContainer>
    </PageLayout>
  );
}
