import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { QRCodeSVG } from 'qrcode.react';
import { useAuth } from '../../../../../context/AuthenticationContext';
import { generateOtpQrValue } from '../../../../../lib/utils/format-utils';
import VerifyCodeModal from '../VerifyCodeModal/VerifyCodeModal';

interface EnableTwoFactorModalProps {
  secret: string;
  onSuccess: (result: 'enabled' | 'disabled') => void;
  isOpen: boolean;
  onClose: () => void;
}

function EnableTwoFactorModal({ secret, onSuccess, isOpen, onClose }: EnableTwoFactorModalProps) {
  const {
    isOpen: verifyCodeModalIsOpen,
    onOpen: verifyCodeModalOnOpen,
    onClose: verifyCodeModalOnClose,
  } = useDisclosure();
  const { currentUser } = useAuth();
  const otpUri = generateOtpQrValue(secret, currentUser?.emailAddress || '');

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enable two-factor authentication</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb="4">
              Download an authentication app on your mobile device. There are many to choose from,
              but some common ones include Google Authenticator, Microsoft Authenticator and Authy.
              Scan the QR code below with your app of choice and then click continue.
            </Text>

            <VStack spacing={0} justifyContent="center">
              <QRCodeSVG
                value={otpUri}
                size={188}
                level="H"
                includeMargin
                bgColor="#ffffff"
                fgColor="#000000"
              />

              <Text mt="4" data-testid="2FASecret">
                {secret}
              </Text>
            </VStack>
          </ModalBody>

          <ModalFooter justifyContent="flex-start">
            <Button
              data-testid="Enable2FAContinue"
              colorScheme="blue"
              mr={3}
              onClick={() => {
                verifyCodeModalOnOpen();
                onClose();
              }}
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <VerifyCodeModal
        onSuccess={onSuccess}
        onClose={verifyCodeModalOnClose}
        isOpen={verifyCodeModalIsOpen}
      />
    </>
  );
}

export default EnableTwoFactorModal;
