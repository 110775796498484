import { Box, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';
import { useCurrentDataSource } from '../../../sources/context/CurrentDataSourceContext';
import CompletenessCheckmark from '../../shared/CompletenessCheckmark/CompletenessCheckmark';
import MicrosoftTenantIdButton from './MicrosoftTenantIdButton';

export default function MicrosoftDynamicsPublishPermissionsSection() {
  const { dataSource: source, refetchDataSource } = useCurrentDataSource();
  const isComplete = !!source.properties?.microsoftTenantId;

  return (
    <SectionContainer
      variant="box"
      title="Publish permission"
      borderRadius="6px"
      header={<CompletenessCheckmark label="Step 2: " isComplete={isComplete} />}
    >
      <Stack spacing={3}>
        <Text>
          Allow harpin AI to publish data back to this source system by providing the tenant ID for
          your organization and granting consent to access.
        </Text>
        <Box>
          <MicrosoftTenantIdButton
            sourceId={source.id}
            microsoftTenantId={source.properties?.microsoftTenantId}
            onSuccess={refetchDataSource}
            variant={isComplete ? 'outline' : 'solid'}
          />
        </Box>
      </Stack>
    </SectionContainer>
  );
}
