import { extendTheme, SystemStyleObject, ThemingProps } from '@chakra-ui/react';
import '@fontsource/inter/variable.css';
import Card from '../../components/core/card/Card/Card.styles';
import SimpleStat from '../../components/core/data/SimpleStat/SimpleStat.styles';
import StatBadge from '../../components/core/data/StatBadge/StatBadge.styles';
import FlatFileUploadIndicator from '../../components/core/indicator/FlatFileUploadIndicator/FlatFileUploadIndicator.styles';
import Button from '../components/Button/Button';
import Input from '../components/Input/Input';
import Modal from '../components/Modal/Modal';
import Tooltip from '../components/Tooltip/TooltipStyles';
import { drawerTheme } from '../components/drawer.theme';
import { tagTheme } from '../components/tag.theme';

export type SystemStyleObjectWithTheming = SystemStyleObject & Pick<ThemingProps, 'variant'>;

const theme = extendTheme({
  styles: {
    global: {
      body: {
        fontSize: 'sm',
      },
    },
  },
  fonts: {
    heading: 'InterVariable, san-serif',
    body: 'InterVariable, san-serif',
  },
  colors: {
    primary: '#092747',
    primaryDark: '#061B30',
    highlight: '#8EE9FF',
    highlightHover: '#8EE9FF88',
    highlightDark: '#52C9E5',
    accent: '#72E8D2',
    accentDark: '#43D6BA',
    action: '#007FA7',
    actionDark: '#005874',
    error: '#BD123B',
    errorDark: '#960628',
    success: '#00866E',
    warning: '#F35135',
    warningDark: '#D84B32',
    bgColor: '#3182CE',
    current: '#00A83933',
    original: '#F1F1F1',
    versionColor: '#DDF1FF',
    versionBorderColor: '#29A6FE',
    tableBorderColor: '#7B7B7B',
    successScheme: {
      50: '#00866E',
      100: '#00866E',
      200: '#00866E',
      300: '#00866E',
      400: '#00866E',
      500: '#00866E',
      600: '#00866E',
      700: '#00866E',
      800: '#00866E',
      900: '#00866E',
    },
    actionScheme: {
      50: '#007FA7',
      100: '#007FA7',
      200: '#007FA7',
      300: '#007FA7',
      400: '#007FA7',
      500: '#007FA7',
      600: '#007FA7',
      700: '#005874',
      800: '#005874',
      900: '#005874',
    },
    errorScheme: {
      50: '#BD123B',
      100: '#BD123B',
      200: '#BD123B',
      300: '#BD123B',
      400: '#BD123B',
      500: '#BD123B',
      600: '#BD123B',
      700: '#960628',
      800: '#960628',
      900: '#960628',
    },
    warningScheme: {
      50: '#F35135',
      100: '#F35135',
      200: '#F35135',
      300: '#F35135',
      400: '#F35135',
      500: '#F35135',
      600: '#F35135',
      700: '#D84B32',
      800: '#D84B32',
      900: '#D84B32',
    },
    neutralScheme: {
      50: '#CBD5E0',
      100: '#CBD5E0',
      200: '#CBD5E0',
    },
    whiteScheme: {
      50: '#FFF',
      100: '#FFF',
      200: '#FFF',
    },
    highlightScheme: {
      50: '#B7F1FF',
      100: '#B7F1FF',
      200: '#B7F1FF',
    },
  },
  components: {
    Button,
    Drawer: drawerTheme,
    Input,
    Tooltip,
    Modal,
    Card,
    StatBadge,
    SimpleStat,
    FlatFileUploadIndicator,
    FormLabel: {
      baseStyle: () => ({
        fontWeight: 'semibold',
        mb: 1,
        fontSize: 'sm',
      }),
    },
    Radio: {
      sizes: {
        lg: {
          label: {
            fontSize: 'md',
          },
        },
      },
      defaultProps: {
        colorScheme: 'actionScheme',
        size: 'lg',
      },
    },
    Select: {
      baseStyle: {
        icon: {
          color: 'action',
        },
      },
      defaultProps: {
        size: 'sm',
      },
      variants: {
        outline: {
          field: {
            fontSize: 'sm',
            borderColor: 'gray.300',
            rounded: 'md',
            _hover: { borderColor: 'gray.400' },
          },
        },
      },
    },
    Stat: {
      baseStyle: {
        number: {
          fontSize: '2xl',
          fontWeight: 'bold',
        },
        label: {
          fontSize: 'sm',
          fontWeight: 'normal',
        },
      },
    },
    Switch: {
      defaultProps: {
        colorScheme: 'actionScheme',
        size: 'md',
      },
    },
    Table: {
      variants: {
        // Default seVIIn styled tables
        default: {
          th: {
            backgroundColor: 'gray.100',
            color: 'gray.600',
            fontWeight: 'bold',
            fontSize: 'xs',
            h: 10,
          },
          td: {
            p: 2.5,
            px: 3,
            fontSize: 'sm',
          },
        },
        defaultWithDivider: {
          th: {
            backgroundColor: 'gray.100',
            color: 'gray.600',
            fontWeight: 'bold',
            fontSize: 'xs',
            h: 10,
            px: 3,
          },
          td: {
            p: 2.5,
            px: 3,
            fontSize: 'sm',
            borderBottom: '1px solid',
            borderColor: 'gray.100',
          },
        },
        explorer: {
          th: {
            backgroundColor: 'gray.100',
            color: 'gray.600',
            fontWeight: 'bold',
            fontSize: 'xs',
            h: 10,
          },
          td: {
            p: 2.5,
            px: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: 'sm',
          },
        },
      },
    },
    Tabs: {
      variants: {
        line: {
          tablist: {
            borderBottom: '4px solid',
          },
          tab: {
            color: 'action',
            borderBottom: '4px solid',
            marginBottom: '-4px',
            _selected: {
              fontWeight: 'semibold',
              color: 'actionDark',
              borderColor: 'currentColor',
            },
          },
        },
      },
    },
    Tag: tagTheme,
  },
  sizes: {
    pageWidth: '1120px',
  },
  space: {
    pagePadding: '24px',
  },
  semanticTokens: {},
});

export default theme;
