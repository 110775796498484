/* eslint-disable react/require-default-props */
import { Box, Text, Icon as ChakraIcon } from '@chakra-ui/react';
import { ReactComponent as Logo } from '../../../../assets/harpinAI-logo-graphic-only.svg';

export interface FormLogoHeaderProps {
  message?: string;
  subMessage?: string;
  errorMessage?: string;
}

const DEFAULT_MESSAGE = 'Sign in to your harpin AI account';

export default function FormLogoHeader({
  message = DEFAULT_MESSAGE,
  subMessage,
  errorMessage,
}: FormLogoHeaderProps) {
  return (
    <Box paddingX="40px" paddingTop="40px" paddingBottom="24px">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '30px',
        }}
      >
        <ChakraIcon boxSize="12">
          <Logo />
        </ChakraIcon>
      </div>
      <Text color="red">{errorMessage}</Text>
      <Text fontWeight="bold" fontSize="xl">
        {message}
      </Text>
      {subMessage && (
        <Text mt="12px" fontSize="sm">
          {subMessage}
        </Text>
      )}
    </Box>
  );
}
