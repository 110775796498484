import { defineStyleConfig } from '@chakra-ui/react';

export default defineStyleConfig({
  baseStyle: {
    fontWeight: 'semibold',
    rounded: 'full',
  },
  sizes: {
    sm: {
      fontSize: 'xs',
      lineHeight: 4,
      px: 2,
      py: 1,
    },
  },
  variants: {
    solid: {
      color: 'gray.800',
      bgColor: 'gray.200',
    },
    solidDark: {
      color: 'white',
    },
    dynamic: {
      color: 'white',
    },
    dynamicReverse: {
      color: 'white',
    },
  },
  defaultProps: {
    size: 'sm',
    variant: 'solid',
  },
});
