import { Button, useBoolean } from '@chakra-ui/react';
import { useXSWR } from '@hazae41/xswr';
import { isAxiosError } from 'axios';
import React, { useCallback } from 'react';
import { useToast } from '../../../../hooks/use-toast';
import { downloadData } from '../../../../lib/api-client/ApiClient';
import IssueReportExecutionClient from '../../../../lib/api-client/reports/IssueReportExecutionClient ';
import {
  getIssueReport,
  startIssueReportExecutionSchema,
} from '../../../../lib/api-client/reports/ReportExecutionData';

interface ExportCsvButtonProps {
  reportParams: any;
  sourceId: string;
}

export default function IssueExportCsvButton({ reportParams, sourceId }: ExportCsvButtonProps) {
  const [isLoading, setLoading] = useBoolean();
  const { make } = useXSWR();
  const toast = useToast();

  const exportData = useCallback(
    async () => {
      setLoading.on();
      toast({
        title: 'CSV is being prepared for export.',
        description: 'Hold tight. This may take a few min.',
      });

      const startReportExecution = await make(
        startIssueReportExecutionSchema(sourceId, reportParams)
      ).fetch();

      if (startReportExecution) {
        const { data: reportExecution = { reportId: '' } } = startReportExecution;

        await new Promise<void>((resolve, reject) => {
          const intervalId = setInterval(async () => {
            const state = await make(
              getIssueReport(sourceId, reportExecution.reportId, { pageSize: 10 })
            ).fetch();
            if (isAxiosError(state?.error) && state?.error.response?.status !== 425) {
              reject();

              toast({
                title: 'There was a problem exporting your CSV.',
                status: 'error',
              });
              clearInterval(intervalId);
            }
            if (state?.data) {
              await IssueReportExecutionClient.exportIssueReportData(
                sourceId,
                reportExecution.reportId
              ).then((res) =>
                downloadData(
                  res,
                  `${reportParams.sourceIssueType}_export_${new Date().getTime()}.csv`
                )
              );
              resolve();

              toast({
                title: 'CSV has been exported.',
                status: 'success',
              });
              clearInterval(intervalId);
            }
          }, 5000);
        });
      }

      setLoading.off();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setLoading, toast, reportParams, make, sourceId]
  );

  return (
    <Button
      variant="outline"
      isLoading={isLoading}
      onClick={async () => {
        await exportData();
      }}
      loadingText="Exporting..."
    >
      Export CSV
    </Button>
  );
}
