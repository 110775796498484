import { HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';

export interface TransactionInsightsProps {
  transactionLength: string;
  averageTimePeriod: string;
  earlierTransactionDate: string;
  latestTransactionTimestamp: string;
  firstTransaction: string;
}

function TransactionInsights({
  transactionLength,
  averageTimePeriod,
  earlierTransactionDate,
  latestTransactionTimestamp,
  firstTransaction,
}: TransactionInsightsProps) {
  return (
    <HStack alignItems="start" spacing="6">
      <VStack alignItems="start" spacing={1}>
        <VStack alignItems="start" spacing={0}>
          <Text fontSize="12px" fontWeight="semibold" color="gray.600" mt="0.5">
            Length of relationship{' '}
          </Text>
          <Text fontSize="16px" color={transactionLength === 'Not on record' ? 'gray.400' : ''}>
            {transactionLength}
          </Text>
        </VStack>
        <VStack alignItems="start" spacing={0}>
          <Text fontSize="12px" fontWeight="semibold" color="gray.600" mt="0.5">
            Average time period between transactions{' '}
          </Text>
          <Text fontSize="16px" color={averageTimePeriod === '' ? 'gray.400' : ''}>
            {averageTimePeriod || 'Not on record'}
          </Text>
        </VStack>
        <VStack alignItems="start" spacing={0}>
          <Text fontSize="12px" fontWeight="semibold" color="gray.600" mt="0.5">
            Time period since transacting{' '}
          </Text>
          <Text
            fontSize="16px"
            color={earlierTransactionDate === 'Not on record' ? 'gray.400' : ''}
          >
            {earlierTransactionDate}
          </Text>
        </VStack>
        <VStack alignItems="start" spacing={0}>
          <Text fontSize="12px" fontWeight="semibold" color="gray.600" mt="0.5">
            Most recent transaction date
          </Text>
          <Text fontSize="16px" color={latestTransactionTimestamp === '' ? 'gray.400' : ''}>
            {latestTransactionTimestamp || 'Not on record'}
          </Text>
        </VStack>
        <VStack alignItems="start" spacing={0}>
          <Text fontSize="12px" fontWeight="semibold" color="gray.600" mt="0.5">
            First transaction date
          </Text>
          <Text color={latestTransactionTimestamp === '' ? 'gray.400' : ''} fontSize="16px">
            {firstTransaction || 'Not on record'}
          </Text>
        </VStack>
      </VStack>
    </HStack>
  );
}
export default TransactionInsights;
