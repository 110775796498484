import { useFetch, useSchema } from '@hazae41/xswr';
import config from '../../../config';
import { Page } from '../../model/common/Page';
import { getApiClientSchema } from '../ApiClient';
import { SourceIdentityRecordSearch } from './model/SourceIdentityRecordSearch';
import { SourceIdentityRecordSearchResponse } from './model/SourceIdentityRecordSearchResponse';

type PaginationParams = {
  pageNumber: number;
  pageSize: number;
};

type PaginationAndSortingParams = {
  pagination: PaginationParams;
  sort: string[];
};

const DEFAULT_PAGINATION: PaginationParams = {
  pageNumber: 0,
  pageSize: 100,
};

const DEFAULT_PAGINATION_AND_SORTING: PaginationAndSortingParams = {
  pagination: DEFAULT_PAGINATION,
  sort: [],
};

function identityRecordsUrl(path: string = '') {
  return `/identity-records${path}`;
}

export function searchIdentityRecords(
  search: SourceIdentityRecordSearch | undefined,
  params: PaginationAndSortingParams = DEFAULT_PAGINATION_AND_SORTING,
  enabled: boolean = true
) {
  if (!search) {
    return undefined;
  }

  return getApiClientSchema<Page<SourceIdentityRecordSearchResponse>>(
    {
      method: 'post',
      url: identityRecordsUrl('/_search'),
      data: search,
      params: {
        page: params.pagination.pageNumber,
        size: params.pagination.pageSize,
        sort: [...params.sort],
      },
      paramsSerializer: {
        indexes: null,
      },
    },
    enabled,
    config.get().XSWR_MAX_TIMEOUT_MS
  );
}

export function useSearchIdentityRecords(
  search: SourceIdentityRecordSearch | undefined,
  params: PaginationAndSortingParams,
  enabled: boolean = true
) {
  const query = useSchema(searchIdentityRecords, [search, params, enabled]);
  useFetch(query);
  return query;
}
