import { defineStyleConfig } from '@chakra-ui/react';

export default defineStyleConfig({
  variants: {
    dynamic: {
      badge: {
        variant: 'dynamic',
      },
    },
    dynamicReverse: {
      badge: {
        variant: 'dynamicReverse',
      },
    },
    solid: {
      badge: {
        variant: 'solid',
      },
    },
    solidDark: {
      badge: {
        variant: 'solidDark',
      },
    },
  },
  defaultProps: {
    variant: 'solid',
  },
});
