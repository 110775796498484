import { Box, Button, chakra, Flex, HStack, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { FaCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Alert from '../../../components/core/Alert/Alert';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import { Identity, IdentityTag } from '../../../lib/api-client/identity/model/Identity';
import Mixpanel from '../../../lib/mixpanel/Mixpanel';
import { profileCompletenessColorStrategyCssVar } from '../../../lib/utils/color-utils';
import { formatPhoneNumber } from '../../../lib/utils/format-utils';
import { formatNumber } from '../../../lib/utils/number-utils';
import { getIdentityTagValueLabel } from '../../../lib/utils/scoring-utils';
import { ProfilePageLinkState } from '../ProfilePage/ProfilePage';
import ProfileTypeIcon from '../components/ProfileTypeIcon/ProfileTypeIcon';

function trackProfileSearchView(properties: {
  seviin_id: string;
  result_number: number;
  total_results: number;
  searchFields: string[];
}) {
  Mixpanel.track('Profile Search View', properties);
}

function SearchResultRowColumn({
  value,
  iconImage,
  fontWeight,
  pinType,
  tags,
}: {
  value: string;
  iconImage?: IconImage;
  fontWeight?: string;
  pinType?: string;
  tags?: IdentityTag[];
}) {
  const notOnRecord = 'Not on record';
  const ChakraFaCircle = chakra(FaCircle);
  const authenticityTag = tags && tags.find((e) => e.key === 'authenticity');
  const variant = profileCompletenessColorStrategyCssVar(authenticityTag?.score ?? 0);
  const profileType = `${
    getIdentityTagValueLabel(authenticityTag?.value, 'authenticity')?.toLowerCase() || ''
  } profile`;
  const showPin = !!pinType;

  return (
    <Box flex="1">
      <HStack spacing="2">
        <chakra.span>
          {iconImage ? <Icon iconImage={iconImage} /> : <ProfileTypeIcon pinType={pinType} />}
        </chakra.span>
        <VStack spacing="0" alignItems="start">
          <Text fontWeight={fontWeight}>{value || notOnRecord}</Text>
          {showPin && (
            <HStack spacing={1}>
              {variant && <ChakraFaCircle color={variant} width="3" />}
              {authenticityTag?.score && (
                <>
                  <Text fontWeight="semibold">
                    {formatNumber(authenticityTag.score * 100, {
                      useGrouping: false,
                      maximumFractionDigits: 2,
                    })}
                  </Text>
                  <Text>{profileType}</Text>
                </>
              )}
            </HStack>
          )}
        </VStack>
      </HStack>
    </Box>
  );
}

interface SearchResultRowProps {
  result: Identity;
  onResultViewed: () => void;
  buttonVariant?: 'solid' | 'outline';
}
function SearchResultRow({
  result,
  onResultViewed,
  buttonVariant = 'outline',
}: SearchResultRowProps) {
  let name = '';
  if (result.attributes.name.objectValues.length > 0) {
    name = `${result.attributes.name.objectValues[0].firstName || ''} ${
      result.attributes.name.objectValues[0].lastName || ''
    }`;
  }

  let emailAddress = '';
  if (result.attributes.emailAddress.stringValues.length > 0) {
    [emailAddress] = result.attributes.emailAddress.stringValues;
  }

  let phone = '';
  if (result.attributes.phone.objectValues.length > 0) {
    phone = formatPhoneNumber(result.attributes.phone.objectValues[0].value);
  }

  const profileLinkState: ProfilePageLinkState = {
    identity: result,
    options: { label: '', value: '' },
  };
  return (
    <SimpleGrid
      columns={4}
      spacing="5"
      py="4"
      px="4"
      mt="4"
      borderWidth="1px"
      alignItems="center"
      boxShadow="base"
      borderRadius="6px"
    >
      <SearchResultRowColumn
        fontWeight="bold"
        value={name}
        tags={result.tags}
        pinType={result.pinType}
      />
      <SearchResultRowColumn value={phone} iconImage={IconImage.mobilePhone} />
      <SearchResultRowColumn value={emailAddress} iconImage={IconImage.email} />

      {/* Credit Card not in scope yet <SearchResultRowColumn value="" iconImage={IconImage.creditCard} />*/}
      <Box flex="1" textAlign="end">
        <Button
          to={`/profiles/${result.pin}`}
          onClick={onResultViewed}
          state={profileLinkState}
          as={Link}
          variant={buttonVariant}
          py="3"
          px="4"
          target="_blank"
        >
          See profile
        </Button>
      </Box>
    </SimpleGrid>
  );
}

interface SearchConsumerProfileResultsProps {
  results: Identity[];
  searchFields: string[];
}

function SearchProfileResults({ results, searchFields }: SearchConsumerProfileResultsProps) {
  const numResults = results.length;
  const noResultsFound = (
    <Alert
      variant="subtle"
      status="error"
      title="We could not find any matches."
      description="Please double check that the information that you entered is correct and try again."
    />
  );

  const resultsFound = (
    <>
      <Box data-testid="SearchProfileResults_bestmatch">
        <Text
          fontWeight="bold"
          fontSize="xl"
          borderBottomWidth="1px"
          borderColor="gray.300"
          padding="10px"
        >
          Best match
        </Text>
        <SearchResultRow
          result={results[0]}
          buttonVariant="solid"
          onResultViewed={() =>
            trackProfileSearchView({
              seviin_id: results[0].pin,
              result_number: 0,
              total_results: numResults,
              searchFields,
            })
          }
        />
      </Box>
      {numResults > 1 && (
        <Box data-testid="SearchProfileResults_othermatches">
          <Flex
            borderBottomWidth="1px"
            borderColor="gray.300"
            padding="10px"
            mt="4"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text fontWeight="bold" fontSize="xl">
              Other potential matches
            </Text>
            <Text color="gray.600" fontSize="xs" lineHeight="16px" fontWeight="semibold">
              Showing {numResults - 1} of {numResults - 1}
            </Text>
          </Flex>

          {results.slice(1).map((result, index) => (
            <SearchResultRow
              key={result.pin}
              result={result}
              onResultViewed={() =>
                trackProfileSearchView({
                  seviin_id: result.pin,
                  result_number: index + 1,
                  total_results: numResults,
                  searchFields,
                })
              }
            />
          ))}
        </Box>
      )}
    </>
  );

  return (
    <Box mt="10" data-testid="SearchProfileResults">
      {numResults < 1 ? noResultsFound : resultsFound}
    </Box>
  );
}

export default SearchProfileResults;
