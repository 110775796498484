/* eslint-disable no-nested-ternary */
import { Box, BoxProps, Select, Text, SimpleGrid, HStack } from '@chakra-ui/react';
import { startOfDay, startOfHour, startOfMonth, startOfWeek, sub } from 'date-fns';
import React, { ChangeEvent, useEffect, useState } from 'react';
import SourceMetricsClient from '../../../../lib/api-client/source-metrics/SourceMetricsClient';
import { getExportAuditEventTypeLabel } from '../../../../lib/api-client/sources/sources.model';
import Mixpanel from '../../../../lib/mixpanel/Mixpanel';
import { TimeSeriesIntervalName } from '../../../../lib/model/common';
import { formatDateString, getCurrentTimezone } from '../../../../lib/utils/date-time-utils';
import { formatNumber } from '../../../../lib/utils/number-utils';
import MetricCard from '../../../core/MetricCard/MetricCard';
import SectionContainer from '../../../core/SectionContainer/SectionContainer';
import SimpleBarGraph from '../../../shared/visualizations/SimpleBarGraph/SimpleBarGraph';

// NOTE: The start of the window needs to be the length of the interval - 1. For example
// the start date for the last 12 hours is now - 11 hours.
const CHART_CONFIG: {
  [key: string]: {
    label: string;
    value: string;
    getStartDate: (date: Date) => Date;
  }[];
} = {
  hour: [
    {
      label: 'Last 12 hours',
      value: 'last12Hours',
      getStartDate: (date: Date) => startOfHour(sub(date, { hours: 11 })),
    },
    {
      label: 'Last 24 hours',
      value: 'last24Hours',
      getStartDate: (date: Date) => startOfHour(sub(date, { hours: 23 })),
    },
    {
      label: 'Last 36 hours',
      value: 'last36Hours',
      getStartDate: (date: Date) => startOfHour(sub(date, { hours: 35 })),
    },
    {
      label: 'Last 48 hours',
      value: 'last48Hours',
      getStartDate: (date: Date) => startOfHour(sub(date, { hours: 47 })),
    },
  ],
  day: [
    {
      label: 'Last 7 days',
      value: 'last7days',
      getStartDate: (date: Date) => startOfDay(sub(date, { days: 6 })),
    },
    {
      label: 'Last 14 days',
      value: 'last14days',
      getStartDate: (date: Date) => startOfDay(sub(date, { days: 13 })),
    },
  ],
  week: [
    {
      label: 'Last 4 weeks',
      value: 'last4weeks',
      getStartDate: (date: Date) => startOfWeek(sub(date, { weeks: 3 })),
    },
    {
      label: 'Last 6 weeks',
      value: 'last6weeks',
      getStartDate: (date: Date) => startOfWeek(sub(date, { weeks: 5 })),
    },
    {
      label: 'Last 8 weeks',
      value: 'last8weeks',
      getStartDate: (date: Date) => startOfWeek(sub(date, { weeks: 7 })),
    },
    {
      label: 'Last 10 weeks',
      value: 'last10weeks',
      getStartDate: (date: Date) => startOfWeek(sub(date, { weeks: 9 })),
    },
    {
      label: 'Last 12 weeks',
      value: 'last12weeks',
      getStartDate: (date: Date) => startOfWeek(sub(date, { weeks: 11 })),
    },
  ],
  month: [
    {
      label: 'Last 3 months',
      value: 'last3months',
      getStartDate: (date: Date) => startOfMonth(sub(date, { months: 2 })),
    },
    {
      label: 'Last 6 months',
      value: 'last6months',
      getStartDate: (date: Date) => startOfMonth(sub(date, { months: 5 })),
    },
    {
      label: 'Last 9 months',
      value: 'last9months',
      getStartDate: (date: Date) => startOfMonth(sub(date, { months: 8 })),
    },
    {
      label: 'Last 12 months',
      value: 'last12months',
      getStartDate: (date: Date) => startOfMonth(sub(date, { months: 11 })),
    },
  ],
};

export function ToolTipContent({ payload, isLastInterval }: any) {
  const recordEventsIngestedMetric = payload[0]?.payload?.value;
  return (
    <>
      <Text fontSize="md" fontWeight="bold">
        {formatNumber(recordEventsIngestedMetric)} new data record events
      </Text>
      <Text>ingested during this time window</Text>
      {isLastInterval && (
        <Text mt="4">
          Note that this time window is in progress and does not yet represent a complete data point
        </Text>
      )}
    </>
  );
}

function ExportEventsBarChart({
  sourceId,

  recordsDeleted,
  recordEventsIngested,
  error,
  hideFilters = false,
  hideMetricsCards = false,
  hideReferenceValue = false,
  exportData,
  ...rest
}: {
  sourceId: string;
  chartHeight?: string | number;
  chartWidth?: string | number;
  recordsDeleted?: number;
  recordEventsIngested?: number;
  error?: number;
  hideFilters?: boolean;
  hideReferenceValue?: boolean;
  hideMetricsCards?: boolean;
  exportData?: any;
} & BoxProps) {
  const [interval, setInterval] = useState<TimeSeriesIntervalName>('day');
  const [config, setConfig] = useState(CHART_CONFIG.day[0]);
  const [selectedEventType, setSelectedEventType] = useState<string>('');
  const [data, setData] = useState<any>({
    data: [],
  });

  const onIntervalChange = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
    let intervalProperties;
    if (value in CHART_CONFIG) {
      setConfig(CHART_CONFIG[value][0]);
      setInterval(value as TimeSeriesIntervalName);
      intervalProperties = {
        chart_name: 'IngestionHistoryPerSource',
        time_interval: value,
        time_window_length: CHART_CONFIG[value][0].value,
      };
      Mixpanel.track('Change Chart', intervalProperties);
    }
  };

  const onAmountChange = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
    let amountProperties;
    if (interval in CHART_CONFIG) {
      const selectedConfig = CHART_CONFIG[interval].find(
        (timeWindowConfig) => timeWindowConfig.value === value
      );
      if (selectedConfig) {
        setConfig(selectedConfig);
      }
      amountProperties = {
        chart_name: 'IngestionHistoryPerSource',
        time_interval: interval,
        time_window_length: value,
      };
      Mixpanel.track('Change Chart', amountProperties);
    }
  };

  const onEventTypeChange = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
    setSelectedEventType(value);
  };

  useEffect(() => {
    const now = new Date();
    SourceMetricsClient.getExportTimeSeriesData(
      sourceId,
      ['sum:exportAttempts '],
      config.getStartDate(now),
      now,
      getCurrentTimezone(),
      interval,
      'sum',
      ['avg', 'sum'],
      selectedEventType
    ).then((timeSeriesData) => {
      setData(timeSeriesData);
    });
  }, [sourceId, interval, config, selectedEventType]);

  const graphData =
    data?.series?.[0]?.dataPoints?.map((d: any) => ({
      label: formatDateString(d?.startDate, 'M/d'),
      value: d?.value,
    })) || [];

  const getEventStatsByType = (eventType: string) => {
    const event = exportData.totalsByType.find((item: any) => item.eventType === eventType);
    return event
      ? { totalEvents: event.totalEvents, totalEventsLastWeek: event.totalEventsLastWeek }
      : { totalEvents: 0, totalEventsLastWeek: 0 };
  };

  const identityCreatedStats = getEventStatsByType('IDENTITY_CREATED');
  const identityUpdatedStats = getEventStatsByType('IDENTITY_UPDATED');
  const identityMergedStats = getEventStatsByType('IDENTITY_MERGED');
  const transactionUpdatedStats = getEventStatsByType('TRANSACTION_UPDATED');
  const transactionIdentityRemovedStats = getEventStatsByType('TRANSACTION_IDENTITY_REMOVED');
  const transactionLineItemCreatedStats = getEventStatsByType('TRANSACTION_LINE_ITEM_CREATED');
  const referenceValue = data?.series?.[0]?.summaryData?.[0]?.value;

  return (
    <>
      <Box {...rest}>
        {!hideFilters && (
          <HStack mb="4">
            <Select onChange={onEventTypeChange} placeholder="All publish activity" maxW={200}>
              <option value="IDENTITY_CREATED">
                {getExportAuditEventTypeLabel('IDENTITY_CREATED')}
              </option>
              <option value="IDENTITY_UPDATED">
                {getExportAuditEventTypeLabel('IDENTITY_UPDATED')}
              </option>
              <option value="IDENTITY_MERGED">
                {getExportAuditEventTypeLabel('IDENTITY_MERGED')}
              </option>
              <option value="TRANSACTION_IDENTITY_REMOVED">
                {getExportAuditEventTypeLabel('TRANSACTION_IDENTITY_REMOVED')}
              </option>
              <option value="TRANSACTION_UPDATED">
                {getExportAuditEventTypeLabel('TRANSACTION_UPDATED')}
              </option>
              <option value="TRANSACTION_LINE_ITEM_CREATED">
                {getExportAuditEventTypeLabel('TRANSACTION_LINE_ITEM_CREATED')}
              </option>
            </Select>
            <Select
              defaultValue="day"
              onChange={onIntervalChange}
              display="inline-block"
              w="auto"
              mr="2"
              fontSize="14"
            >
              <option value="hour">Show hourly</option>
              <option value="day">Show daily</option>
              <option value="week">Show weekly</option>
              <option value="month">Show monthly</option>
            </Select>
            <Select onChange={onAmountChange} display="inline-block" w="auto">
              {CHART_CONFIG[interval].map((c) => (
                <option key={c.label} value={c.value}>
                  {c.label}
                </option>
              ))}
            </Select>
          </HStack>
        )}
        <SimpleBarGraph
          data={graphData}
          xDataKey="label"
          yDataKey="value"
          highlightLastInterval
          tooltipContent={<ToolTipContent />}
          height={400}
          referenceValue={hideReferenceValue ? undefined : referenceValue}
          referenceContent={
            data &&
            referenceValue !== undefined && (
              <>
                <Text fontSize="md" fontWeight="bold">
                  {formatNumber(referenceValue)} new data record events
                </Text>
                <Text fontSize="sm">published per time window on average</Text>
              </>
            )
          }
        />
      </Box>

      {hideMetricsCards && (
        <SimpleGrid columns={4} spacing={3} w="full" mt={4} mb={2}>
          <SectionContainer
            title="All publish events"
            variant="box"
            fontSize="14"
            titleFontSize="12"
            bgColor={selectedEventType === '' ? '#B7F1FF' : ''}
          >
            <MetricCard
              value={exportData?.totalEvents ?? 0}
              label="total events published "
              badge
              label2=" last 7 days"
              badgeValue={exportData.totalEventsLastWeek ?? 0}
            />
          </SectionContainer>
          <SectionContainer
            bgColor={selectedEventType === 'IDENTITY_CREATED' ? '#B7F1FF' : ''}
            title="Identity added"
            variant="box"
            fontSize="14"
            titleFontSize="12"
          >
            <MetricCard
              value={identityCreatedStats.totalEvents}
              label="total events published "
              badge
              label2=" last 7 days"
              badgeValue={identityCreatedStats.totalEventsLastWeek}
            />
          </SectionContainer>
          <SectionContainer
            bgColor={selectedEventType === 'IDENTITY_UPDATED' ? '#B7F1FF' : ''}
            title="Identity updated"
            variant="box"
            fontSize="14"
            titleFontSize="12"
          >
            <MetricCard
              value={identityUpdatedStats.totalEvents ?? 0}
              label="total events published "
              badge
              badgeValue={identityUpdatedStats.totalEventsLastWeek ?? 0}
              label2=" last 7 days"
            />
          </SectionContainer>
          <SectionContainer
            bgColor={selectedEventType === 'IDENTITY_MERGED' ? '#B7F1FF' : ''}
            title="Identity merged"
            variant="box"
            fontSize="14"
            titleFontSize="12"
          >
            <MetricCard
              value={identityMergedStats.totalEvents ?? 0}
              label="total events published "
              badge
              badgeValue={identityMergedStats.totalEventsLastWeek ?? 0}
              label2=" last 7 days"
            />
          </SectionContainer>
          <SectionContainer
            bgColor={selectedEventType === 'TRANSACTION_IDENTITY_REMOVED' ? '#B7F1FF' : ''}
            variant="box"
            header={
              <HStack w="full" mb={-3}>
                <Text fontWeight="bold" fontSize="12">
                  Identity removed from transaction
                </Text>
              </HStack>
            }
          >
            <MetricCard
              value={transactionIdentityRemovedStats.totalEvents ?? 0}
              label="total events published "
              badge
              badgeValue={transactionIdentityRemovedStats.totalEventsLastWeek ?? 0}
              label2=" last 7 days"
            />
          </SectionContainer>
          <SectionContainer
            bgColor={selectedEventType === 'TRANSACTION_UPDATED' ? '#B7F1FF' : ''}
            title="Transaction updated"
            variant="box"
            fontSize="14"
            titleFontSize="12"
          >
            <MetricCard
              value={transactionUpdatedStats.totalEvents ?? 0}
              label="total events published "
              badge
              badgeValue={transactionUpdatedStats.totalEventsLastWeek ?? 0}
              label2=" last 7 days"
            />
          </SectionContainer>
          <SectionContainer
            bgColor={selectedEventType === 'TRANSACTION_LINE_ITEM_CREATED' ? '#B7F1FF' : ''}
            title="Transaction line item added"
            variant="box"
            titleFontSize="12"
          >
            <MetricCard
              value={transactionLineItemCreatedStats.totalEvents ?? 0}
              label="total events published "
              badge
              badgeValue={transactionLineItemCreatedStats.totalEventsLastWeek ?? 0}
              label2=" last 7 days"
            />
          </SectionContainer>
          <SectionContainer title="Errors" variant="box" fontSize="14" titleFontSize="12">
            <MetricCard
              value={exportData?.totalFailures ?? 0}
              label="total events published "
              badge
              badgeValue={exportData?.totalFailuresLastWeek ?? 0}
              label2=" last 7 days"
            />
          </SectionContainer>
        </SimpleGrid>
      )}
    </>
  );
}
export default ExportEventsBarChart;
