import React from 'react';
import Header from '../../../components/core/Header/Header';
import DataSourceIcon from '../../../components/features/connections/DataSourceIcon/DataSourceIcon';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import SourceSystemRecordExplorer from '../components/SourceSystemRecordExplorer/SourceSystemRecordExplorer';
import { useCurrentDataSource } from '../context/CurrentDataSourceContext';

export default function SourceDataRecordExplorerPage() {
  const { dataSource } = useCurrentDataSource();

  return (
    <PageLayout
      pageViewEvent={{ page: 'Data Record Explorer' }}
      header={
        <Header
          title={`${dataSource.name} data record explorer`}
          icon={<DataSourceIcon sourceSystem={dataSource.sourceSystem} />}
        />
      }
      w="full"
      h="full"
      display="flex"
      flexFlow="column"
    >
      <SourceSystemRecordExplorer />
    </PageLayout>
  );
}
