import { chakra, ChakraProps } from '@chakra-ui/react';
import { FaCircle } from 'react-icons/fa';

const ChakraFaCircle = chakra(FaCircle);

interface DataQualityScoreIndicatorProps {
  score: number;
}

export default function DataQualityScoreIndicator({
  score,
  ...rest
}: DataQualityScoreIndicatorProps & ChakraProps) {
  let color = 'success';
  if (score < 0.8) {
    color = 'warning';
  }
  if (score < 0.6) {
    color = 'error';
  }
  return <ChakraFaCircle color={color} {...rest} />;
}
