import { HStack, Text } from '@chakra-ui/react';
import config from '../../../config';
import { useEffectOnce } from '../../../hooks/use-effect-once';

export default function HeadwayWidget() {
  useEffectOnce(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.headwayapp.co/widget.js';
    document.head.appendChild(script);
    const headwayConfig = {
      selector: '.headway-badge',
      account: config.get().HEADWAY_ACCOUNT_ID,
      trigger: '#headway-trigger',
    };
    script.onload = () => {
      (window as any).Headway.init(headwayConfig);
    };
  });

  return (
    <HStack id="headway-trigger">
      <Text fontSize="sm">Product updates</Text>
      <span className="headway-badge" />
    </HStack>
  );
}
