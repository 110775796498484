import { Text, Box, Image, chakra, HStack, VStack, Heading, Link } from '@chakra-ui/react';
import DataConnectionBlockChain from '../../../assets/images/blockchain.png';
import DataSecurity from '../../../assets/images/data-security.svg';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';

export default function SecurityPage() {
  return (
    <PageLayout
      pageViewEvent={{ page: 'Security & Privacy Page' }}
      header={<Header title="Security & privacy" icon={<Icon iconImage={IconImage.privacy} />} />}
      data-testid="SecurityPage"
      p={0}
      w="full"
    >
      <Box pos="relative">
        <Image
          src={DataConnectionBlockChain}
          alt="DataConnectionBlockChain"
          width="full"
          height="300px"
          objectFit="cover"
        />
        <Heading
          pos="absolute"
          top="30%"
          left="4%"
          fontWeight="bold"
          fontSize="3xl"
          color="#8EE9FF"
        >
          Security at our core
        </Heading>
        <Text
          w="1040px"
          pos="absolute"
          top="45%"
          left="4%"
          fontWeight="semibold"
          fontSize="md"
          color="white"
        >
          harpin AI is committed to ensuring the protection of our customer’s data. Our Security
          Team has implemented comprehensive processes for detecting, assessing, and resolving
          security risks across the organization.
        </Text>
      </Box>

      <Box
        ml="8"
        mt="10"
        borderRadius="md"
        border="1px solid"
        borderColor="gray.100"
        boxShadow="base"
        p="16px"
        w="1040px"
        h="136px"
      >
        <HStack spacing="4">
          <Link target="_blank" href="https://www.aicpa.org/soc4so">
            <chakra.span>
              <Image src={DataSecurity} width="104px" alt="DataSecurity" />
            </chakra.span>
          </Link>
          <VStack spacing="2" alignItems="start">
            <Text fontWeight="bold" fontSize="large" color="gray.800">
              Certifications and compliance
            </Text>

            <Text>
              We are SOC 2 certified, and continuously monitor all associated controls to ensure we
              remain in compliance at all times.
            </Text>
          </VStack>
        </HStack>
      </Box>
      <Box p="30" mb="28">
        <SectionContainer mt="4" title="Platform security" w="1040px">
          <Box w="1040px" h="100px">
            <Text>
              harpin AI recognizes the importance of the first-party data each customer shares with
              us and our security program is built around the protection of this highly valuable
              data. We utilize Amazon Web Services (AWS) for our production platform and make use of
              the security products within the AWS ecosystem. harpin AI has policies, procedures,
              and automation in place to ensure only the appropriate personnel can access customer
              data based on their role and we monitor and log all AWS access events and retain logs
              in accordance with best practices and SOC 2 compliance. Additionally, we use a
              combination of AWS-native and third party tools to capture access patterns from
              employees and services to alert us on activity that doesn’t match our usage baselines.
            </Text>
          </Box>
        </SectionContainer>

        <SectionContainer mt="10" title="Data privacy" w="1040px">
          <Box w="1040px" h="100px">
            <Text>
              We respect the privacy rights of users and recognize the importance of protecting
              information collected about you and your customers.
            </Text>
            <HStack spacing="10" mt="5">
              <Box
                p="5"
                borderRadius="md"
                border="1px solid"
                borderColor="gray.100"
                boxShadow="base"
                w="500px"
                h="148px"
              >
                <Heading fontSize="lg">Encryption-at-rest</Heading>
                <Text mt="2" fontWeight="md">
                  All customer data we collect and store is protected at rest in stores encrypted by
                  AES-256 keys that are provisioned on a ‘by customer’ basis. This ensures that even
                  if other controls failed, no 3rd party would be able to access your data.
                </Text>
              </Box>
              <Box
                p="5"
                borderRadius="md"
                border="1px solid"
                borderColor="gray.100"
                boxShadow="base"
                w="500px"
                h="148px"
              >
                <Heading fontSize="lg">Encryption-in-transit</Heading>
                <Text mt="2" fontWeight="md">
                  All data in transit occurs via modern encryption protocols, including TLS 1.2 and
                  SSHv2. We monitor any potential violations of these configurations in real time
                  via AWS-native and 3rd party security tools.
                </Text>
              </Box>
            </HStack>
          </Box>
        </SectionContainer>

        <SectionContainer mt="32" title="Additional information" w="1040px">
          <Box w="1040px" h="100px">
            <HStack spacing="10" mt="8">
              <Box
                pos="relative"
                p="5"
                borderRadius="md"
                border="1px solid"
                borderColor="gray.100"
                boxShadow="base"
                w="320px"
                h="180px"
              >
                <Heading mb="2" fontSize="lg">
                  Privacy policy
                </Heading>
                <Text fontWeight="md">
                  Refer to the Privacy Policy on our company website to learn about how we manage
                  personal information.
                </Text>
                <Link
                  color="action"
                  fontSize="md"
                  fontWeight="semibold"
                  pos="absolute"
                  bottom="3"
                  target="_blank"
                  href="https://seviin.ai/privacy-policy-harpin-ai"
                >
                  Review privacy policy
                </Link>
              </Box>
              <Box
                pos="relative"
                p="5"
                borderRadius="md"
                border="1px solid"
                borderColor="gray.100"
                boxShadow="base"
                w="320px"
                h="180px"
              >
                <Heading mb="2" fontSize="lg">
                  Terms of use
                </Heading>
                <Text w="288" h="80px" fontWeight="md">
                  Refer to the Terms of Use on our company website to learn about the rules and
                  restrictions that govern your use of our tools.
                </Text>
                <Link
                  color="action"
                  fontSize="md"
                  fontWeight="semibold"
                  pos="absolute"
                  bottom="3"
                  target="_blank"
                  href="https://seviin.ai/terms-of-use-harpin-ai"
                >
                  Review terms of use
                </Link>
              </Box>
              <Box
                pos="relative"
                p="5"
                borderRadius="md"
                border="1px solid"
                borderColor="gray.100"
                boxShadow="base"
                w="320px"
                h="180px"
              >
                <Heading mb="2" fontSize="lg">
                  Security program
                </Heading>
                <Text fontWeight="md">
                  Refer to the Security & Privacy page on our company website to learn more about
                  our security program.
                </Text>
                <Link
                  color="action"
                  fontSize="md"
                  fontWeight="semibold"
                  pos="absolute"
                  bottom="3"
                  target="_blank"
                  href="https://seviin.ai/security-and-privacy "
                >
                  Learn more
                </Link>
              </Box>
            </HStack>
          </Box>
        </SectionContainer>
      </Box>
    </PageLayout>
  );
}
