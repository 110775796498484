import { chakra, HStack, Stack } from '@chakra-ui/react';
import { formatDistanceToNowStrict } from 'date-fns';
import React, { useEffect, useState } from 'react';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';
import FlatFileUploadIndicator from '../../../../components/core/indicator/FlatFileUploadIndicator/FlatFileUploadIndicator';
import { useGetSourceUploadsOnInterval } from '../../../../lib/api-client/sources/SourceData';
import { Upload } from '../../../../lib/api-client/sources/model/Upload';
import { formatPercentage } from '../../../../lib/utils/number-utils';
import { useCurrentDataSource } from '../../../sources/context/CurrentDataSourceContext';
import AllFlatFileUploadsButton from './AllFlatFileUploadsButton';
import UploadFlatFileButton from './UploadFlatFileButton';

function isUploadIngesting(upload: Upload) {
  switch (upload.status) {
    case 'created': // actually should eventually get to the analysis
    case 'importRequested':
    case 'importRunning':
    case 'testImportRequested':
    case 'testImportRunning':
      return true;
    default:
      return false;
  }
}

interface FileStatusSectionProps {
  onUploadComplete: (id: string) => void;
}

export default function FileStatusSection({ onUploadComplete }: FileStatusSectionProps) {
  const { dataSource } = useCurrentDataSource();

  const [isIngesting, setIngesting] = useState(true);
  const { data: uploadsData, fetch: uploadsFetch } = useGetSourceUploadsOnInterval(
    dataSource.id,
    isIngesting ? 10 * 1000 : 0
  );

  const uploads = uploadsData?.content ?? [];
  const ingesting = uploads.find(isUploadIngesting) != null;

  useEffect(() => {
    setIngesting(ingesting);
  }, [ingesting]);

  return (
    <SectionContainer
      variant="box"
      w="full"
      title="File status"
      titleSubheader={
        <chakra.span whiteSpace="nowrap" fontSize="sm" fontWeight="semibold" color="gray.600">
          {uploads?.length ?? 0} total
        </chakra.span>
      }
    >
      <Stack spacing={4}>
        {uploads
          .sort((a, b) => -1 * a.createdAt.localeCompare(b.createdAt))
          .slice(0, 2)
          .map((upload) => (
            <Stack spacing={1} key={upload.id}>
              <chakra.span lineHeight={5} fontSize="md">
                {upload.fileName}
              </chakra.span>
              <HStack justify="space-between" fontSize="sm" color="gray.600">
                <HStack spacing={1}>
                  <FlatFileUploadIndicator status={upload.status} />
                  {upload.percentRecordsImported != null && upload.status === 'importRunning' && (
                    <chakra.span whiteSpace="nowrap">
                      ({formatPercentage(upload.percentRecordsImported)} complete)
                    </chakra.span>
                  )}
                </HStack>
                {upload.estimatedImportCompletion != null && upload.status === 'importRunning' && (
                  <chakra.span>
                    {formatDistanceToNowStrict(new Date(upload.estimatedImportCompletion))
                      .replace('minutes', 'mins')
                      .replace('seconds', 'secs')}{' '}
                    remaining
                  </chakra.span>
                )}
              </HStack>
            </Stack>
          ))}
        <HStack>
          {uploads && uploads.length > 3 && (
            <AllFlatFileUploadsButton source={dataSource} uploads={uploads} />
          )}
          <UploadFlatFileButton
            sourceId={dataSource.id}
            onUploadComplete={async ({ uploadPath, ingestStarted }) => {
              setIngesting(ingestStarted);
              await uploadsFetch();
              onUploadComplete(uploadPath.id);
            }}
          />
        </HStack>
      </Stack>
    </SectionContainer>
  );
}
