import { Page } from '../../model/common/Page';
import ApiClient from '../ApiClient';
import { CreateTenant, UpdateTenant } from './model/CreateTenant';
import { Tenant } from './model/Tenant';

/**
 * Tenant client that can do admin thing on the Tenant endpoint. These API calls require users
 * that are in a special group.
 */
const AdminTenantClient = {
  createTenant(data: CreateTenant): Promise<Tenant> {
    return ApiClient.post<Tenant>('/tenants', data).then((res) => res.data);
  },

  getTenants(): Promise<Page<Tenant>> {
    return ApiClient.get<Page<Tenant>>('/tenants').then((res) => res.data);
  },

  updateTenant(tenantId: string, data: UpdateTenant): Promise<Tenant> {
    return ApiClient.put<Tenant>(`/tenants/${tenantId}`, data).then((res) => res.data);
  },
};

export default AdminTenantClient;
