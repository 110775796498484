/**
 * Get the value from an object from the specified dot notation path
 * @param obj
 * @param path
 * @param defValue
 */
export function get(obj: any, path: string, defValue?: any) {
  if (!path) return undefined;

  // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);

  // Find value
  // @ts-ignore
  const result = pathArray?.reduce((prevObj: any, key: any) => prevObj && prevObj[key], obj);
  // If found value is undefined return default value; otherwise return the value
  return result === undefined ? defValue : result;
}

/**
 * Check if a value is undefined
 * @param val value to check
 *
 * @return boolean
 */
export function isUndefined(val: unknown): val is undefined {
  return typeof val === 'undefined';
}

/**
 * Check if a value is undefined
 * @param val value to check
 *
 * @return if value is defined
 */
// This val is {} is some typescript hotness - https://www.totaltypescript.com/the-empty-object-type-in-typescript
export function isDefined(val: unknown): val is {} {
  return val != null;
}
