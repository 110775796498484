import { Button, HStack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import { formatNumber } from '../../../lib/utils/number-utils';

function SuccessIcon({ success }: { success: boolean }) {
  if (!success) {
    return <Icon iconImage={IconImage.error} color="error" />;
  }

  return <Icon iconImage={IconImage.success} color="success" />;
}

interface RecentExportErrorsProps {
  sourceId: string;
  errorCount: number;
}
export default function RecentExportErrors({ errorCount, sourceId }: RecentExportErrorsProps) {
  const isError = errorCount > 0;

  let borders = {};
  if (isError) {
    borders = {
      borderWidth: '2px',
    };
  }
  return (
    <SectionContainer variant="box" {...borders}>
      <HStack justifyContent="space-between">
        <HStack>
          <SuccessIcon success={!isError} />
          <Text>{formatNumber(errorCount)} publish errors detected in the last 7 days</Text>
        </HStack>
        <Button
          fontWeight="semibold"
          textColor={isError ? 'error' : 'action'}
          as={Link}
          to={`/sources/${sourceId}/logs/publish-audit?error=true`}
          variant="link"
        >
          Review audit log
        </Button>
      </HStack>
    </SectionContainer>
  );
}
