import {
  Badge,
  Box,
  BoxProps,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  TableContainer,
  Text,
  Tooltip,
  useBoolean,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import DataSourceIcon from '../../../components/features/connections/DataSourceIcon/DataSourceIcon';
import EditRecordModal from '../../../components/shared/data-explorer/EditRecordModal/EditRecordModal';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import { Can } from '../../../context/AuthorizationContext';
import { useInfiniteScroll } from '../../../context/InfiniteScrollContext';
import IdentityClient from '../../../lib/api-client/identity/IdentityClient';
import { VersionData } from '../../../lib/api-client/identity/model/Identity';
import { SourceIdentityRecordSearchResponse } from '../../../lib/api-client/identity/model/SourceIdentityRecordSearchResponse';
import { formatRelativeDateString } from '../../../lib/utils/date-time-utils';
import { isHttpError } from '../../../lib/utils/error-utils';
import { isDefined } from '../../../lib/utils/utils';
import { formatKeyName } from '../../profile/ProfileDataRecordsPage/ProfileDataRecordsPage';
import ProfileModified from '../../profile/components/ProfileModified/ProfileModified';
import { useCurrentDataSource } from '../context/CurrentDataSourceContext';

const fieldLabel: { label: string; key: string }[] = [
  {
    label: 'Full name',
    key: 'names',
  },

  {
    label: 'Email address',
    key: 'emailAddresses',
  },
  {
    label: 'Mobile phone number',
    key: 'mobilePhone',
  },
  {
    label: 'Home phone number',
    key: 'homePhone',
  },
  {
    label: 'Work phone number',
    key: 'workPhone',
  },
  {
    label: 'Street address',
    key: 'streetAddress',
  },
  { label: 'City', key: 'city' },
  {
    label: 'State',
    key: 'governingDistrict',
  },
  { label: 'ZIP code', key: 'zip4' },
  { label: 'Country', key: 'country' },
  {
    label: 'Date of birth',
    key: 'dateOfBirth',
  },
  {
    label: 'Opt in email',
    key: 'optInEmail',
  },
  {
    label: 'Opt in text',
    key: 'optInText',
  },
  {
    label: 'Opt in phone',
    key: 'optInText',
  },
];

interface SourceDataRecordVersionPageState {
  backLabel?: string;
}

function IdentityRecordTooltip() {
  return (
    <>
      Data records that aren’t assigned a PIN were deemed unusable for identity resolution by our
      platform.
    </>
  );
}

function SourceDataRecordVersionPage() {
  const [identityData, setidentityData] = useState<VersionData[]>([]);
  const [isFetching, setFetching] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const { recordId = '' } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as SourceDataRecordVersionPageState;
  const { dataSource } = useCurrentDataSource();
  const { scrollRef } = useInfiniteScroll();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modifiedData, setModifiedData] = useState<SourceIdentityRecordSearchResponse>();
  const [showEmpty, setShowEmpty] = useBoolean();

  const [page, setPage] = useState(0);
  const fetchTxs = useCallback(async () => {
    if (isFetching && hasMore) {
      return;
    }
    setFetching(true);
    try {
      const identityRecord = await IdentityClient.getIdentityRecordVersions(
        dataSource.id,
        recordId,
        page,
        10
      );
      setidentityData((vsd) => vsd.concat(identityRecord.content));
      setPage(page + 1);
      setHasMore(!identityRecord.last);
    } catch (err) {
      if (isHttpError(err, 404)) {
        navigate('/404', { replace: true });
      }
    } finally {
      setFetching(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMore, recordId, isFetching, dataSource]);

  useEffect(() => {
    if (modifiedData) {
      window.location.reload();
    }
  }, [modifiedData]);

  const rowProps: BoxProps = {
    py: 2.5,
    px: 3,
  };

  const headerProps: BoxProps = {
    ...rowProps,
    bgColor: 'gray.100',
    color: 'gray.600',
    textTransform: 'uppercase',
    fontSize: 'xs',
    fontWeight: 'bold',
  };

  return (
    <PageLayout
      pageViewEvent={{ page: 'Source data record version page' }}
      header={
        <Header
          title="Data record version history"
          back={{
            onClick: () => navigate(-1),
            label: state?.backLabel ?? 'Back to explore all data records',
          }}
          icon={<Icon iconImage={IconImage.searchData} />}
        />
      }
      loading={isFetching}
      p={0}
      w="full"
      h="full"
      display="flex"
      flexFlow="column"
    >
      <EditRecordModal
        isOpen={isOpen}
        onClose={onClose}
        onRowValueChanged={setModifiedData}
        data={identityData[0]}
        hideVersionButton
      />
      <InfiniteScroll
        loadMore={fetchTxs}
        hasMore={hasMore}
        getScrollParent={() => scrollRef}
        useWindow={false}
      >
        <Box bgColor="white">
          <HStack
            bgColor="white"
            p={10}
            border="2px"
            boxShadow="md"
            borderRadius="6px"
            borderColor="gray.100"
            justifyContent="space-between"
          >
            <Flex justifyContent="start" alignItems="center">
              <DataSourceIcon boxSize="48px" mr="5" color="gray.800" />
              <VStack alignItems="flex-start" spacing={1}>
                <Text fontWeight="bold" fontSize="2xl">
                  {dataSource.name}
                </Text>
                <HStack>
                  <Text fontWeight="bold" fontSize="md">
                    Source system ID:
                  </Text>
                  <Text fontSize="md">{recordId}</Text>
                </HStack>
              </VStack>
            </Flex>
            <Can I="update" an="SourceDataRecord">
              <Button variant="solid" onClick={onOpen}>
                Create a new version
              </Button>
            </Can>
          </HStack>
          {identityData &&
            identityData?.map((data, index, arr) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box key={`${data.identityRecord.sourceId}-${index}`}>
                <TableContainer p={10} w="full" bgColor="gray.50" pt={4} pb={4}>
                  <HStack h="40px" w="full" display="flex" justifyContent="space-between">
                    <HStack>
                      <Text fontSize="20" fontWeight="700">
                        Version {arr.length - index}
                      </Text>
                      {index === 0 && (
                        <Badge
                          textTransform="capitalize"
                          textColor="white"
                          fontSize="12"
                          fontWeight="600"
                          rounded="xl"
                          p="0"
                          pl="2"
                          pr="2"
                          bgColor="success"
                        >
                          Current
                        </Badge>
                      )}
                      {arr.length - 1 === index && (
                        <Badge
                          textTransform="capitalize"
                          textColor="gray.800"
                          fontSize="12"
                          fontWeight="600"
                          rounded="xl"
                          p="0"
                          pl="2"
                          pr="2"
                          bgColor="gray.200"
                        >
                          Original
                        </Badge>
                      )}
                    </HStack>
                    <HStack>
                      <Text fontWeight="bold" fontSize="16">
                        PIN:
                      </Text>
                      {isDefined(data.identityRecord.pin) ? (
                        <Text textColor="action">{data.identityRecord.pin}</Text>
                      ) : (
                        <HStack>
                          <Text>not assigned</Text>
                          <Tooltip
                            hasArrow
                            h="76px"
                            label={<IdentityRecordTooltip />}
                            placement="top"
                          >
                            <Text>
                              <Icon iconImage={IconImage.info} boxSize={4} color="actionDark" />
                            </Text>
                          </Tooltip>
                        </HStack>
                      )}
                    </HStack>
                  </HStack>
                  <HStack w="full" h="50" p="2" pl="0" spacing="2">
                    <Icon iconImage={IconImage.editData} boxSize={6} />
                    {arr.length - 1 === index ? (
                      <Text>
                        This version represents the original data record ingested from the source
                        system
                        {data.identityRecord.lastModifiedAt &&
                          `on ${formatRelativeDateString(data.identityRecord.lastModifiedAt)}`}
                      </Text>
                    ) : (
                      <ProfileModified
                        lastModifiedBy={data.identityRecord.lastModifiedBy}
                        lastModifiedAt={data.identityRecord.lastModifiedAt}
                      />
                    )}
                  </HStack>

                  <Grid templateColumns="256px 1fr 256px" fontSize="sm" bgColor="white" w="full">
                    <Box {...headerProps}>Data field</Box>
                    <Box {...headerProps}>Field value</Box>
                    <Box {...headerProps}>Edited</Box>

                    {fieldLabel.map((item) => {
                      const dataValue = (data.identityRecord as any)[item.key];
                      let value;
                      let isModified = false;

                      if (typeof dataValue === 'object') {
                        const { modified, raw, normalized } =
                          (data.identityRecord as any)[item.key] ?? {};
                        value = modified ?? raw ?? normalized;
                        isModified = modified != null;
                      } else {
                        value = dataValue;
                      }
                      if (item.key === 'emailAddresses') {
                        if (Array.isArray(dataValue)) {
                          value = dataValue.map((x, i) => ({
                            emailAddress: x.emailAddressModified ?? x.emailAddressRaw,
                            key: i,
                          }));
                          if (!showEmpty) {
                            value = value.filter((x) => x.emailAddress !== null);
                          }
                          if (dataValue.some((x) => x.emailAddressModified != null)) {
                            isModified = true;
                          }
                        } else {
                          value = null;
                        }
                      } else if (item.key === 'names') {
                        value = Array.isArray(dataValue)
                          ? dataValue.map((x) => {
                              const obj: any = {};
                              if (x.firstName != null || showEmpty) {
                                obj.firstName =
                                  x.firstNameModified ?? x.firstNameRaw ?? 'Not on record';
                              }
                              if (x.middleName != null || showEmpty) {
                                obj.middleName =
                                  x.middleNameModified ?? x.middleNameRaw ?? 'Not on record';
                              }
                              if (x.lastName != null || showEmpty) {
                                obj.lastName =
                                  x.lastNameModified ?? x.lastNameRaw ?? 'Not on record';
                              }
                              if (
                                x.firstNameModified != null ||
                                x.middleNameModified != null ||
                                x.lastNameModified != null
                              ) {
                                isModified = true;
                              }
                              return obj;
                            })
                          : null;
                        if (value === null && !showEmpty) {
                          return null;
                        }
                      }
                      if (value == null && !showEmpty) {
                        return null;
                      }
                      return (
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        <>
                          {Array.isArray(value) ? (
                            value.map((v) => (
                              <>
                                <GridItem colSpan={3}>
                                  <Divider color="gray.300" w="full" />
                                </GridItem>
                                {Object.keys(v).length > 1 &&
                                  Object.keys(v).map(
                                    (key: any) =>
                                      key !== 'key' && (
                                        <>
                                          <Box {...rowProps}>{formatKeyName(key)}</Box>
                                          <Box
                                            {...rowProps}
                                            color={
                                              v[key as keyof typeof v] === 'Not on record'
                                                ? 'gray.400'
                                                : undefined
                                            }
                                          >
                                            {v[key as keyof typeof v] ?? 'Not on record'}
                                          </Box>
                                          <Box {...rowProps}>
                                            {isModified && (
                                              <Text>
                                                <Icon
                                                  iconImage={IconImage.editData}
                                                  boxSize={6}
                                                  color="gray.800"
                                                />{' '}
                                                Edited
                                              </Text>
                                            )}
                                          </Box>
                                        </>
                                      )
                                  )}
                              </>
                            ))
                          ) : (
                            <>
                              <GridItem colSpan={3}>
                                <Divider color="gray.300" w="full" />
                              </GridItem>
                              <Box {...rowProps}>{item.label}</Box>
                              <Box
                                {...rowProps}
                                textColor={
                                  value === null || value === undefined ? 'gray.400' : undefined
                                }
                              >
                                {value ?? 'Not on record'}
                              </Box>
                              <Box {...rowProps}>
                                {isModified && (
                                  <Text>
                                    <Icon
                                      iconImage={IconImage.editData}
                                      boxSize={6}
                                      color="gray.800"
                                    />{' '}
                                    Edited
                                  </Text>
                                )}
                              </Box>
                            </>
                          )}
                        </>
                      );
                    })}
                  </Grid>
                  <Button
                    variant="ghost"
                    onClick={setShowEmpty.toggle}
                    rightIcon={
                      <Icon
                        iconImage={showEmpty ? IconImage.accordionUp : IconImage.accordionDown}
                        boxSize={4}
                      />
                    }
                  >
                    {showEmpty ? 'Hide empty data fields' : 'Show empty data fields'}
                  </Button>
                </TableContainer>
              </Box>
            ))}
        </Box>
      </InfiniteScroll>
    </PageLayout>
  );
}

export default SourceDataRecordVersionPage;
