import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import ReportExplorer from '../components/ReportExplorer/ReportExplorer';
import DuplicateSummaryReportFilterDrawer from './DuplicateSummaryReportFilterDrawer';
import TransactionSummaryReportFilterDrawer from './TransactionSummaryReportFilterDrawer';

const config: Record<string, any> = {
  'transaction-summary': {
    title: 'Transaction data report',
    icon: IconImage.transaction,
    reportId: 'TRANSACTION_SUMMARY_BY_PROFILE',
    drawer: <TransactionSummaryReportFilterDrawer />,
  },
  duplicates: {
    title: 'Duplicate data report',
    icon: IconImage.consumerInsights,
    reportId: 'DUPLICATES_ACROSS_SOURCES',
    drawer: <DuplicateSummaryReportFilterDrawer />,
  },
};

export default function ReportExplorerPage() {
  const { id = '' } = useParams();
  const navigate = useNavigate();

  const reportConfig = config[id];
  useEffect(() => {
    if (!reportConfig) {
      navigate('/404', { replace: true });
    }
  }, [navigate, reportConfig]);

  if (!reportConfig) {
    return null;
  }

  return (
    <PageLayout
      pageViewEvent={{ page: reportConfig.title }}
      header={<Header title={reportConfig.title} icon={<Icon iconImage={reportConfig.icon} />} />}
      w="full"
      h="full"
      display="flex"
      flexFlow="column"
    >
      <ReportExplorer reportId={reportConfig.reportId}>{reportConfig.drawer}</ReportExplorer>
    </PageLayout>
  );
}
