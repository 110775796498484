const ModalStyle = {
  sizes: {
    sm: {
      dialog: { maxWidth: '448px' },
      body: { py: 1 },
      footer: { p: 6 },
    },
    md: {
      dialog: { maxWidth: '648px' },
      body: { py: 1 },
      footer: { p: 6 },
    },
    lg: {
      dialog: { maxWidth: '848px' },
      body: { py: 1 },
      footer: { p: 6 },
    },
  },
};

export default ModalStyle;
