import { SingleQuery } from '@hazae41/xswr';
import { isAxiosError } from 'axios';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import {
  useGetDataSource,
  useGetDataSourceExportStatus,
  useGetSourceSystemType,
} from '../../../lib/api-client/sources/SourceData';
import { DataSource } from '../../../lib/api-client/sources/model/DataSource';
import { ExportStatus } from '../../../lib/api-client/sources/model/ExportStatus';
import { SourceSystemType } from '../../../lib/api-client/sources/model/SourceTypes';

export interface CurrentDataSourceContext {
  dataSource: DataSource;
  sourceSystemType: SourceSystemType;
  exportStatus?: ExportStatus;
  refetchDataSource: SingleQuery<DataSource>['fetch'];
  loading: boolean;
}

const Context = createContext<CurrentDataSourceContext | undefined>(undefined);

function assertContext(
  state?: Partial<CurrentDataSourceContext>
): asserts state is CurrentDataSourceContext {
  if (!state?.dataSource) {
    throw new Error(`'state' has a undefined dataSource`);
  }
}

export function CurrentDataSourceProvider({ children }: { children: ReactNode }) {
  const { id = '' } = useParams();
  const { data, error, fetch, loading } = useGetDataSource(id);
  const { data: systemTypeData, error: systemTypeDataError } = useGetSourceSystemType(
    data?.sourceSystem ?? '',
    data?.sourceSystem != null
  );

  const isExportStatusEnabled =
    data?.properties?.exportMode === 'enabled' && data?.syncDirection !== 'IN';
  const { data: exportStatus } = useGetDataSourceExportStatus(
    data?.id ?? '',
    isExportStatusEnabled
  );

  const state = useMemo(
    () => ({
      dataSource: data,
      sourceSystemType: systemTypeData,
      exportStatus,
      refetchDataSource: fetch,
      loading,
    }),
    [data, systemTypeData, exportStatus, fetch, loading]
  );

  if (error || systemTypeDataError) {
    if (isAxiosError(error) && error.response?.status === 404) {
      return <Navigate to="/404" replace />;
    }

    throw error ?? systemTypeDataError;
  }

  if (!state.dataSource || !systemTypeData) {
    return null;
  }

  assertContext(state);
  return <Context.Provider value={state}>{children}</Context.Provider>;
}

export function useCurrentDataSource(): CurrentDataSourceContext {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useCurrentDataSource must be used within a CurrentDataSourceProvider');
  }

  return context;
}
