export default function PasswordTooltip() {
  return (
    <>
      <div>For your security, your password must:</div>
      <div>• Contain at least 1 lowercase letter</div>
      <div>• Contain at least 1 uppercase letter</div>
      <div>• Contain at least 1 number</div>
      <div>• Contain at least 1 special character</div>
    </>
  );
}
