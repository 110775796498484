import {
  Button,
  ButtonProps,
  chakra,
  IconButton,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Stack,
  useClipboard,
  useDisclosure,
} from '@chakra-ui/react';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import MicrosoftTestAccessButton from './MicrosoftTestAccessButton';

interface MicrosoftApplicationAccessInstructionsButtonProps {
  sourceId: string;
  onSuccess: () => void;
  onError: () => void;
}

export default function MicrosoftApplicationAccessInstructionsButton({
  sourceId,
  onSuccess,
  onError,
  ...props
}: MicrosoftApplicationAccessInstructionsButtonProps & ButtonProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { onCopy, hasCopied } = useClipboard('0aabfeaf-5c2a-44f7-9fe8-52e9acca7d3b', {
    timeout: 1500,
  });

  return (
    <>
      <Button onClick={onOpen} {...props}>
        See instructions
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            How to create an application user for harpin AI in Microsoft Dynamics
            <ModalCloseButton top={3.5} />
          </ModalHeader>
          <ModalBody>
            <OrderedList spacing={4}>
              <ListItem>
                Login to{' '}
                <chakra.a color="action" href="https://admin.powerplatform.microsoft.com/home ">
                  Power Platform Admin
                </chakra.a>{' '}
                using your admin credentials
              </ListItem>
              <ListItem>Click on “Environment” and select the desired environment</ListItem>
              <ListItem>
                {`Click on “Settings” and find "Application users" under "Users + permissions"`}
              </ListItem>
              <ListItem>{`Click on "New app user" at the top and then “Add an app”`}</ListItem>
              <ListItem>
                <Stack>
                  <span>
                    Search for the harpin AI application using our client ID below and select
                    “harpin AI” in the results
                  </span>
                  <span>
                    harpin AI client ID: 0aabfeaf-5c2a-44f7-9fe8-52e9acca7d3b
                    <IconButton
                      size="sm"
                      ml={1}
                      variant="ghost"
                      aria-label="copy client ID"
                      icon={<Icon iconImage={IconImage.copy} />}
                      onClick={onCopy}
                    />
                    {hasCopied && <>Copied!</>}
                  </span>
                </Stack>
              </ListItem>
              <ListItem>
                Select the target business unit and add a security role of “Salesperson”
              </ListItem>
              <ListItem>That’s it! Test and confirm access using the button below</ListItem>
            </OrderedList>
          </ModalBody>
          <ModalFooter justifyContent="start">
            <MicrosoftTestAccessButton
              sourceId={sourceId}
              onSuccess={() => {
                onSuccess();
                onClose();
              }}
              onError={() => {
                onError();
                onClose();
              }}
            >
              Test access now
            </MicrosoftTestAccessButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
