/* eslint-disable no-nested-ternary */
import {
  Box,
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useBoolean,
  IconButton,
  HStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { isAxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import { useAuth } from '../../../context/AuthenticationContext';
import { useToast } from '../../../hooks/use-toast';
import AdminTenantClient from '../../../lib/api-client/tenant/AdminTenantClient';
import { Tenant } from '../../../lib/api-client/tenant/model/Tenant';

const createTenantFormSchema = yup.object({
  id: yup
    .string()
    .required('Tenant ID is required')
    .matches(/^[a-zA-Z0-9]+$/, 'Tenant ID can only be letters and numbers')
    .max(16),
  name: yup.string().required('Tenant name is required'),
  emailDomains: yup
    .array()
    .of(
      yup
        .string()
        .matches(/^(?!-)[A-Za-z0-9-]+([-.][a-z0-9]+)*\.[A-Za-z]{2,32}$/, 'Must be a valid domain')
    )
    .test(
      'atLeastOneEmailDomain',
      'At least one email domain is required',
      (value: any) => value && value.length > 0
    ),
  type: yup.string().oneOf(['sandbox', 'production', 'THIRD_PARTY']),
  dataNetwork: yup.string().when('type', {
    is: 'THIRD_PARTY',
    then: () => yup.string().required('Data network name is required'),
  }),
  properties: yup.object().shape({
    disableAddressValidation: yup.string().oneOf(['true', 'false']).default('false'),
  }),
});

interface CreateTenantForm {
  id: string;
  name: string;
  emailDomains: string[];
  type: 'sandbox' | 'production' | 'THIRD_PARTY';
  dataNetwork: string;
  thirdPartyDataUsage: 'ALL' | 'NONE';
  properties: {
    hideLoyaltyData: 'true' | 'false';
    disableAddressValidation: 'true' | 'false';
  };
}

export default function TenantManagementPage() {
  const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null);
  const [selectedTenantType, setSelectedTenantType] = useState('THIRD_PARTY');

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    reset,
    getValues,

    formState: { errors, isSubmitting },
  } = useForm<CreateTenantForm>({
    resolver: yupResolver(createTenantFormSchema),
    mode: 'onBlur',
  });
  const toast = useToast();
  const [isLoading, setLoading] = useBoolean(true);

  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [queryParams] = useSearchParams();
  const tenantId = queryParams.get('tenantId') ?? '';
  const { tenantId: currentTenantId } = useAuth();
  const watchedType = watch('type');
  const { fields, append, replace, remove } = useFieldArray({
    control,
    name: 'emailDomains',
  } as any);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getTenants = async () => {
    const { content } = await AdminTenantClient.getTenants();
    setTenants(content);
  };

  const sortedTenants = [...tenants].sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    getTenants();
    setLoading.off();
    if (selectedTenant) {
      replace(getValues('emailDomains'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTenantId, tenantId, selectedTenant]);
  const onSubmit = async (data: CreateTenantForm) => {
    if (selectedTenant) {
      try {
        await AdminTenantClient.updateTenant(data.id, {
          name: data.name,
          emailDomains: data.emailDomains,
          dataNetwork:
            data.type === 'production'
              ? 'MAIN'
              : data.type === 'sandbox'
              ? `SBX_${data.id.toUpperCase()}`
              : `${data.dataNetwork.toUpperCase()}`,
          thirdPartyDataUsage: data.thirdPartyDataUsage,
          properties: {
            hideLoyaltyData: data.properties.hideLoyaltyData,
            disableAddressValidation: data.properties.disableAddressValidation,
          },
        });
      } catch (e) {
        let description;
        if (isAxiosError(e)) {
          description = e.response?.data?.message;
        }

        toast({
          status: 'error',
          title: 'There was a problem updating the tenant.',
          description,
        });
      }
      getTenants();
      return onClose();
    }

    try {
      await AdminTenantClient.createTenant({
        name: data.name,
        id: data.id,
        emailDomains: data.emailDomains,
        thirdPartyDataUsage: data.thirdPartyDataUsage,
        dataNetwork:
          data.type === 'production'
            ? 'MAIN'
            : data.type === 'sandbox'
            ? `SBX_${data.id.toUpperCase()}`
            : `${data.dataNetwork.toUpperCase()}`,
        properties: {
          hideLoyaltyData: data.properties.hideLoyaltyData,
          disableAddressValidation: data.properties.disableAddressValidation,
        },
      });
      toast({
        status: 'success',
        title: 'Tenant created successfully!',
        description: `Login with your user credentials to switch to your new tenant - ${data.name}.`,
      });
      navigate(`/seviin-tools/switch-tenant?tenantId=${data.id}`);
    } catch (e) {
      let description;
      if (isAxiosError(e)) {
        description = e.response?.data?.message;
      }

      toast({
        status: 'error',
        title: 'There was a problem creating the tenant.',
        description,
      });
    }
    return null;
  };

  const handleEditTenant = (tenant: Tenant) => {
    setSelectedTenant(tenant);
    setValue('id', tenant.id);
    setValue('name', tenant.name);
    setValue('emailDomains', tenant.emailDomains);
    setValue(
      'type',
      tenant.dataNetwork.includes('SBX')
        ? 'sandbox'
        : tenant.dataNetwork === 'MAIN'
        ? 'production'
        : 'THIRD_PARTY'
    );
    setValue('dataNetwork', tenant.dataNetwork);
    setValue('thirdPartyDataUsage', tenant.thirdPartyDataUsage);
    setValue(
      'properties.disableAddressValidation',
      tenant?.properties.disableAddressValidation as 'true' | 'false'
    );

    onOpen();
  };

  const handleChange = (e: any) => {
    setSelectedTenant(e.target.value);
  };
  return (
    <PageLayout
      pageViewEvent={{ page: 'Tenant management page' }}
      header={
        <Header
          icon={<Icon iconImage={IconImage.settings} />}
          title="Tenant management page"
          back={{ label: 'Back to harpin tools', to: '/seviin-tools' }}
        />
      }
      loading={isLoading}
    >
      <Button
        onClick={() => {
          setSelectedTenant(null);
          reset();
          onOpen();
        }}
      >
        Create tenant
      </Button>
      <TableContainer mt={4}>
        <Table size="sm">
          <Thead bgColor="gray.100" h={10}>
            <Tr>
              <Th>Tenant name </Th>
              <Th>Domain</Th>
              <Th isNumeric> </Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedTenants.map((tenant) => (
              <Tr key={tenant.id}>
                <Td>{tenant.name}</Td>
                {tenant.emailDomains.length > 0 ? (
                  <Td wordBreak="break-word" whiteSpace="pre-line">
                    {tenant.emailDomains.join(',  ')}
                  </Td>
                ) : (
                  <Td textColor="gray.400">Not on record</Td>
                )}
                <Td>
                  <Button onClick={() => handleEditTenant(tenant)} variant="ghost">
                    Edit tenant
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> {selectedTenant ? 'Edit tenant' : 'Create new tenant'} </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <chakra.form onSubmit={handleSubmit(onSubmit)} w="500px">
              <Stack spacing={5} pb={10}>
                <FormControl isInvalid={!!errors.type}>
                  <FormLabel>Type</FormLabel>
                  <Select {...register('type')}>
                    <option value="sandbox">Sandbox</option>
                    <option value="production">Production</option>
                    <option value="THIRD_PARTY">Private data network</option>
                  </Select>
                  {watchedType === 'sandbox' && (
                    <>
                      <FormLabel mt={2}>Data Network Name</FormLabel>
                      <Input disabled defaultValue="sandbox" />
                      <FormHelperText>
                        Sandbox creates a scratch tenant that can be used for testing and demoing.
                      </FormHelperText>
                    </>
                  )}
                  {watchedType === 'production' && (
                    <>
                      <FormLabel mt={2}>Data Network Name</FormLabel>
                      <Input disabled defaultValue="production" />
                      <FormHelperText>
                        Production should only be used for live customers.
                      </FormHelperText>
                    </>
                  )}
                  {watchedType === 'THIRD_PARTY' && (
                    <>
                      <FormLabel mt="2">Data Network Name</FormLabel>
                      <FormControl isInvalid={!!errors.dataNetwork}>
                        <Input
                          {...register('dataNetwork')}
                          defaultValue="THIRD_PARTY"
                          value={selectedTenant ? selectedTenant.dataNetwork : selectedTenantType}
                          onChange={(e) => {
                            const newDataNetwork = e.target.value as 'THIRD_PARTY';
                            if (selectedTenant) {
                              setSelectedTenant({
                                ...selectedTenant,
                                dataNetwork: newDataNetwork,
                              });
                            } else {
                              setSelectedTenantType(e.target.value as any);
                            }
                          }}
                        />
                        <FormErrorMessage>{errors?.dataNetwork?.message}</FormErrorMessage>
                      </FormControl>
                    </>
                  )}
                  <FormErrorMessage>{errors?.type?.type}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.id}>
                  <FormLabel>Tenant ID</FormLabel>
                  <Input
                    disabled={!!selectedTenant}
                    {...register('id')}
                    value={selectedTenant?.id}
                  />
                  <FormErrorMessage>{errors?.id?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.name}>
                  <FormLabel>Name</FormLabel>
                  <Input
                    {...register('name')}
                    onClick={handleChange}
                    value={selectedTenant?.name}
                  />
                  <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.emailDomains}>
                  <FormLabel>Email Domains</FormLabel>
                  <Stack>
                    <>
                      {fields.map((field, index) => (
                        <FormControl key={field.id} isInvalid={!!errors.emailDomains?.[index]}>
                          <HStack>
                            <Input {...register(`emailDomains.${index}`)} />
                            <IconButton
                              variant="outline"
                              disabled={fields.length === 1}
                              aria-label="remove emailDomain"
                              icon={<Icon iconImage={IconImage.delete} />}
                              onClick={() => remove(index)}
                            />
                          </HStack>
                          <FormErrorMessage>
                            {errors?.emailDomains?.[index]?.message}
                          </FormErrorMessage>
                        </FormControl>
                      ))}
                      {errors.emailDomains &&
                        errors.emailDomains.type === 'atLeastOneEmailDomain' && (
                          <FormErrorMessage>At least one email domain is required</FormErrorMessage>
                        )}
                    </>
                    <Box>
                      <Button variant="outline" onClick={() => append('')} size="xs">
                        Add a domain
                      </Button>
                    </Box>
                  </Stack>
                </FormControl>
                <FormControl isInvalid={!!errors.properties?.disableAddressValidation}>
                  <FormLabel> Address Validation</FormLabel>
                  <Select
                    disabled={watchedType === 'sandbox'}
                    {...register('properties.disableAddressValidation')}
                    defaultValue={watchedType === 'sandbox' ? 'false' : 'true'}
                  >
                    <option value="true">Disabled</option>
                    <option value="false">Enabled</option>
                  </Select>
                  <FormErrorMessage>
                    {errors?.properties?.disableAddressValidation?.message}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
              <Button type="submit" isLoading={isSubmitting}>
                {selectedTenant ? 'Save updates  ' : 'Create tenant'}
              </Button>
              {!selectedTenant && (
                <Text pt={3} fontSize="sm">
                  NOTE: Clicking create tenant will create your tenant and redirect you to the
                  switch tenant page, so that you can login to your new tenant.
                </Text>
              )}
            </chakra.form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </PageLayout>
  );
}
