import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthenticationContext';

interface AuthenticationGuardProps {
  children: ReactNode;
}

export default function AuthenticationGuard({ children }: AuthenticationGuardProps): JSX.Element {
  const { isAuthenticated, isTimeout } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/signin" state={{ isTimeout }} />;
  }

  return children as JSX.Element;
}
