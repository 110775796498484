import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useBoolean,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';
import { Can } from '../../../../context/AuthorizationContext';
import SourceClient from '../../../../lib/api-client/sources/SourceClient';

const CONFIRMATION_CONFIG = {
  false: {
    title: 'Are you sure you want to change the ingestion status to inactive?',
    description: 'This will pause all data flow from this source system.',
    confirm: 'Yes, change to inactive',
  },
  true: {
    title: 'Are you sure you want to change the ingestion status to active?',
    description: 'This will reactivate data flow from this source system.',
    confirm: 'Yes, change to active',
  },
};

interface ConfirmationModalProps {
  enabled: boolean;
  isOpen: boolean;
  onClose: () => void;
  onConfirmation: (enabled: boolean) => Promise<void>;
}

export function ConfirmationModal({
  enabled,
  isOpen,
  onClose,
  onConfirmation,
}: ConfirmationModalProps) {
  const [isLoading, setLoading] = useBoolean(false);
  const config = CONFIRMATION_CONFIG[`${enabled}`];
  const modalOnClose = () => {
    setLoading.off();
    onClose();
  };
  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      onClose={modalOnClose}
      isCentered
      closeOnOverlayClick={!isLoading}
    >
      <ModalOverlay />
      <ModalContent data-testid="ConfirmationModal">
        <ModalHeader fontWeight="bold">{config.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{config.description}</ModalBody>
        <ModalFooter justifyContent="start">
          <Button
            onClick={() => {
              setLoading.on();
              onConfirmation(enabled).finally(setLoading.off);
            }}
            isLoading={isLoading}
            mr={4}
          >
            {config.confirm}
          </Button>
          <Button autoFocus variant="outline" onClick={modalOnClose} disabled={isLoading}>
            No, cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

interface IngestionStatusSectionProps {
  sourceId: string;
  enabled?: boolean;
  enableTestIngestion?: boolean;
  onError: () => void;
}

function IngestionStatusSection({
  sourceId,
  onError,
  enableTestIngestion = false,
  enabled: enabledLoaded,
}: IngestionStatusSectionProps) {
  const [enabled, setEnabled] = useState(enabledLoaded);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const setDataSourceEnabled = (value: boolean) =>
    SourceClient.setDataSourceEnabled(sourceId, value)
      .then(() => {
        setEnabled(value);
      })
      .catch(onError)
      .finally(onClose);

  return (
    <Can I="update" a="DataSource">
      <SectionContainer
        title="Ingestion status"
        variant="box"
        data-testid="IngestionStatusSection"
        w="full"
      >
        <FormControl display="flex" alignItems="center" mb="1">
          <Switch
            id="enabled"
            data-testid="DataSourceEnabled"
            isChecked={enabled}
            size="md"
            colorScheme="successScheme"
            onChange={onOpen}
          />
          <FormLabel
            ml="2.5"
            htmlFor="enabled"
            mb="0"
            fontSize="md"
            lineHeight="6"
            fontWeight="semibold"
          >
            {enabled ? 'Active' : 'Inactive'}
          </FormLabel>
        </FormControl>
        <Text fontSize="sm" mb={4}>
          {enabled
            ? 'An active status means that new data record events are streaming in real-time.'
            : 'Ingestion events are not currently streaming.'}
        </Text>

        {enabled !== true && enableTestIngestion && (
          <>
            <Text fontSize="sm" mb={4}>
              Ingest a small number of data records to test the connection before activating it.
            </Text>
            <Button w="full" as={Link} to={`/sources/${sourceId}/test-ingestion`}>
              Run test ingestion now
            </Button>
          </>
        )}

        <Button variant="outline" w="full" as={Link} to={`/sources/${sourceId}/web-hooks`} mt={2}>
          Manage webhooks
        </Button>

        <ConfirmationModal
          enabled={!enabled}
          isOpen={isOpen}
          onClose={onClose}
          onConfirmation={setDataSourceEnabled}
        />
      </SectionContainer>
    </Can>
  );
}
export default IngestionStatusSection;
