/* eslint-disable react/no-unstable-nested-components */
import { Box } from '@chakra-ui/react';
import { cloneElement, ReactNode } from 'react';
import {
  Label,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { AxisDomain, DataKey } from 'recharts/types/util/types';
import ReferenceLineToolTip from '../../../components/shared/visualizations/ReferenceLineTooltip/ReferenceLineToolTip';

function CustomToolTip({
  active,
  payload,
  children,
  isLastInterval = false,
}: {
  active?: boolean;
  payload?: any;
  children: ReactNode;
  isLastInterval?: boolean;
}) {
  if (active && payload && payload.length) {
    return (
      <Box
        maxW="200px"
        w="200px"
        color="white"
        background="gray.800"
        borderRadius="6px"
        padding="2"
        fontSize="sm"
        data-testid="SimpleLineGraphTooltip"
      >
        {cloneElement(children as any, { payload, isLastInterval })}
      </Box>
    );
  }

  return null;
}

function MemoCustomToolTip({ tooltipContent, ...rest }: any) {
  return <CustomToolTip {...rest}>{tooltipContent}</CustomToolTip>;
}

interface Props<T> {
  data: T[];
  xDataKey: DataKey<any>;
  yDataKey: DataKey<any>;
  referenceValue?: any;
  tooltipContent?: JSX.Element;
  referenceContent?: ReactNode;
  variant?: any;
  activeVariant?: any;
  yTickFormatter?: (value: T) => any;
  yDomain?: AxisDomain;
  width?: string | number;
  height?: string | number;
}

type EnterpriseSimpleLineGraphProps<T> = Props<T>;

function EnterpriseSimpleLineGraph({
  data,
  xDataKey,
  yDataKey,
  width = '100%',
  height = '100%',
  tooltipContent,
  referenceValue,
  referenceContent,
  variant,
  activeVariant,
  yTickFormatter,
  yDomain = ['dataMin', 'auto'],
}: EnterpriseSimpleLineGraphProps<any>) {
  return (
    <Box>
      <ResponsiveContainer width={width} height={height}>
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 10,
            right: 0,
            left: -20,
            bottom: 0,
          }}
        >
          <XAxis
            dataKey={xDataKey}
            tickLine={false}
            stroke="var(--chakra-colors-gray-300)"
            strokeWidth={1}
            tick={{ fill: 'var(--chakra-colors-gray-800)', fontSize: 12 }}
            dy={10}
            padding={{ left: 14, right: 10 }}
          />
          <YAxis
            domain={yDomain}
            tickLine={false}
            stroke="var(--chakra-colors-gray-300)"
            tick={{ fill: 'var(--chakra-colors-gray-800)', fontSize: 12 }}
            tickFormatter={yTickFormatter}
          />
          {tooltipContent && (
            <Tooltip
              cursor={{ fill: 'transparent' }}
              content={<MemoCustomToolTip tooltipContent={tooltipContent} />}
            />
          )}
          {referenceValue && (
            <ReferenceLine
              y={referenceValue}
              stroke="#00866E"
              strokeWidth="2"
              strokeDasharray="6 4"
              label={
                referenceContent ? (
                  <Label
                    position="left"
                    content={
                      <ReferenceLineToolTip referenceContent={referenceContent} color="#00866E" />
                    }
                  />
                ) : undefined
              }
            />
          )}

          <Line
            type="linear"
            dataKey={yDataKey}
            stroke="#092747"
            strokeWidth={1.5}
            dot={(props: any) => (
              <circle {...props} fill={variant[props.index]} r={8} strokeWidth={0} />
            )}
            activeDot={(props: any) => (
              <circle
                {...props}
                fill={activeVariant[props.index]}
                stroke={activeVariant[props.index]}
                strokeOpacity={0.5}
                strokeWidth={4}
                r={4}
              />
            )}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
}
export default EnterpriseSimpleLineGraph;
