import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBoolean,
} from '@chakra-ui/react';

interface PrimaryValueChangeConfirmationModalProps {
  field: {
    fieldLabel: string;
    oldValue: string;
    newValue: string;
  };
  onConfirmation: () => void;
  isOpen: boolean;
  onClose: () => void;
}

export default function PrimaryValueChangeConfirmationModal({
  field: { fieldLabel, oldValue, newValue },
  onConfirmation,
  isOpen,
  onClose,
}: PrimaryValueChangeConfirmationModalProps) {
  const [isLoading, setLoading] = useBoolean();
  const onSave = () => {
    if (onConfirmation) {
      setLoading.on();
      try {
        onConfirmation();
      } finally {
        setLoading.off();
      }
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text pr={9}>
            Are you sure you want to change the primary value for the {fieldLabel} field?
          </Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          {`The consumer's primary ${fieldLabel} will be changed from "${oldValue}" to "${newValue}"`}
        </ModalBody>
        <ModalFooter justifyContent="start">
          <HStack>
            <Button onClick={onSave} isLoading={isLoading}>
              Save
            </Button>
            <Button variant="outline" onClick={onClose} isLoading={isLoading}>
              Cancel
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
