export function varColorWrapper(color: string) {
  return `var(--chakra-colors-${color.replace('.', '-')})`;
}

/**
 * Default color strategy based on values between 0 - 1
 *
 * @param value value between 0 - 1
 * @param invert the logic
 */
export function defaultPercentageColorStrategy(value: number, invert = false) {
  if (value < 0.3) {
    return invert ? 'success' : 'error';
  }

  if (value < 0.6) {
    return 'warning';
  }

  return invert ? 'error' : 'success';
}

/**
 * Default color strategy css vars
 * @param value
 * @param invert
 */
export function defaultPercentageColorStrategyCssVar(value: number, invert = false) {
  return varColorWrapper(defaultPercentageColorStrategy(value, invert));
}

/**
 * Returns a chakra color token for the profile completeness value.
 * @param value completeness value from 0 to 1
 */
export function profileCompletenessColorStrategy(value: number) {
  return defaultPercentageColorStrategy(value);
}

/**
 * Returns a css var for the provided completeness value.
 * @param value completeness value from 0 to 1
 */
export function profileCompletenessColorStrategyCssVar(value: number) {
  return varColorWrapper(profileCompletenessColorStrategy(value));
}

/**
 * Returns the color token for data quality score percentage changed.
 *
 * @param value percentage change value from
 * @param tokenType 'scheme' for scheme token or 'color' for a single color
 */
export function dataQualityScorePercentChangeColorStrategy(
  value: number,
  tokenType: 'scheme' | 'color' = 'color'
) {
  const useSchemeToken = tokenType === 'color';
  if (value >= 0.001) {
    return useSchemeToken ? 'successScheme' : 'success';
  }

  if (value > -0.001) {
    return useSchemeToken ? 'neutralScheme' : 'gray.300';
  }

  if (value > -0.1) {
    return useSchemeToken ? 'warningScheme' : 'warning';
  }

  return useSchemeToken ? 'errorScheme' : 'error';
}

/**
 * Returns the color token for data issue percentage changed.
 *
 * @param value percentage change value
 * @param tokenType 'scheme' for scheme token or 'color' for a single color
 */
export function dataIssuePercentChangeColorStrategy(
  value: number,
  tokenType: 'scheme' | 'color' = 'color'
) {
  const useSchemeToken = tokenType === 'scheme';

  if (value <= -0.001) {
    return useSchemeToken ? 'successScheme' : 'success';
  }

  if (value < 0.001) {
    return useSchemeToken ? 'neutralScheme' : 'gray.300';
  }

  if (value < 0.1) {
    return useSchemeToken ? 'warningScheme' : 'warning';
  }

  return useSchemeToken ? 'errorScheme' : 'error';
}
