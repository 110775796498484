import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Tooltip,
  useBoolean,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import YupPassword from 'yup-password';
import { useAuth } from '../../../../context/AuthenticationContext';
import { AuthenticationConfiguration } from '../../../../lib/api-client/authentication/model/AuthenticationConfiguration';
import Icon from '../../../core/Icon/Icon';
import { IconImage } from '../../../core/Icon/IconConfig';
import FormLogoHeader from '../FormLogoHeader/FormLogoHeader';
import FormTermsFooter from '../FormTermsFooter/FormTermsFooter';
import PasswordTooltip from '../PasswordTooltip/PasswordTooltip';

YupPassword(yup);

const changePasswordFormSchema = yup
  .object({
    password: yup.string().password().min(8).minUppercase(1).minLowercase(1).minSymbols(1),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), undefined], 'Passwords must match.'),
  })
  .required();

interface ChangePasswordFormData {
  password: string;
  passwordConfirmation: string;
  serverError?: any;
}

interface ChangePasswordFormProps {
  accountEmail: string;
  oldPassword: string;
  authenticationConfiguration: AuthenticationConfiguration;
  onError: () => void;
}

export default function ChangePasswordForm({
  accountEmail,
  oldPassword,
  authenticationConfiguration,
  onError,
}: ChangePasswordFormProps) {
  const [isLoading, setLoading] = useBoolean(false);
  const navigate = useNavigate();
  const { handleNewPasswordChallenge } = useAuth();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ChangePasswordFormData>({
    resolver: yupResolver(changePasswordFormSchema),
    mode: 'onBlur',
  });

  const onSubmit: SubmitHandler<any> = async (form) => {
    setLoading.on();
    try {
      await handleNewPasswordChallenge({
        email: accountEmail,
        oldPassword,
        newPassword: form.password,
        userPool: {
          id: authenticationConfiguration.userPool.id,
          clientId: authenticationConfiguration.userPool.clientId,
        },
      });
      navigate('/', { replace: true });
    } catch (err) {
      onError();
    } finally {
      setLoading.off();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormLogoHeader message="Change your password" />
      <Box paddingX="170px">
        <FormControl isInvalid={!!errors.password} paddingBottom="40px">
          <FormLabel htmlFor="password" display="flex" justifyContent="space-between">
            Password
            <Tooltip hasArrow h="120px" label={<PasswordTooltip />} placement="top">
              <span>
                <Icon ml="4px" iconImage={IconImage.info} cursor="pointer" color="action" />
              </span>
            </Tooltip>
          </FormLabel>
          <Input id="password" type="password" {...register('password')} autoFocus />
          <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.passwordConfirmation}>
          <FormLabel htmlFor="passwordConfirmation">Confirm Password</FormLabel>
          <Input id="passwordConfirmation" type="password" {...register('passwordConfirmation')} />
          <FormErrorMessage>{errors?.passwordConfirmation?.message}</FormErrorMessage>
        </FormControl>
      </Box>
      <Button w="200px" h="48px" m="40px" type="submit" isLoading={isLoading}>
        Sign in
      </Button>
      <Box mb="40px">
        <FormTermsFooter />
      </Box>
    </form>
  );
}
