import {
  Box,
  Button,
  chakra,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useBoolean,
  useDisclosure,
} from '@chakra-ui/react';
import consola from 'consola';
import React, { useState } from 'react';
import { FaCircle } from 'react-icons/fa';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import { useToast } from '../../../../hooks/use-toast';
import SourceClient from '../../../../lib/api-client/sources/SourceClient';

const CONFIRMATION_CONFIG = {
  false: {
    title: 'Are you sure you want to change the ingestion status to disabled?',
    description: 'This will pause all data flow from this source system.',
    confirm: 'Yes, change to diabled',
  },
  true: {
    title: 'Are you sure you want to change the ingestion status to enabled?',
    description: 'This will reactivate data flow from this source system.',
    confirm: 'Yes, change to enabled',
  },
};

interface ConfirmationModalProps {
  enabled: boolean;
  isOpen: boolean;
  onClose: () => void;
  onConfirmation: (enabled: boolean) => Promise<void>;
}

export function ConfirmationModal({
  enabled,
  isOpen,
  onClose,
  onConfirmation,
}: ConfirmationModalProps) {
  const [isLoading, setLoading] = useBoolean(false);
  const config = CONFIRMATION_CONFIG[`${enabled}`];
  const modalOnClose = () => {
    setLoading.off();
    onClose();
  };
  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      onClose={modalOnClose}
      isCentered
      closeOnOverlayClick={!isLoading}
    >
      <ModalOverlay />
      <ModalContent data-testid="ConfirmationModal">
        <ModalHeader fontWeight="bold">{config.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{config.description}</ModalBody>
        <ModalFooter justifyContent="start">
          <Button
            onClick={() => {
              setLoading.on();
              onConfirmation(enabled).finally(setLoading.off);
            }}
            isLoading={isLoading}
            mr={4}
          >
            {config.confirm}
          </Button>
          <Button autoFocus variant="outline" onClick={modalOnClose} disabled={isLoading}>
            No, cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

const ChakraFaCircle = chakra(FaCircle);

type IngestionStatus = 'enabled' | 'disabled';

interface EnableIngestionButtonProps {
  status?: IngestionStatus;
  sourceId: string;
}

export default function EnableIngestionButton({
  status: statusProp = 'disabled',
  sourceId,
}: EnableIngestionButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [status, setStatus] = useState(statusProp);
  const toast = useToast();

  let statusMessage;
  let statusLabel;
  let statusColor;
  switch (status) {
    case 'enabled':
      statusColor = 'success';
      statusMessage = 'New data events are streaming in real-time.';
      statusLabel = 'Enabled';
      break;
    default:
      statusColor = 'gray.600';
      statusMessage = 'New data events are not currently streaming.';
      statusLabel = 'Disabled';
  }

  const updateStatus = async (newStatus: IngestionStatus) => {
    try {
      await SourceClient.setDataSourceEnabled(sourceId, newStatus === 'enabled');
      setStatus(newStatus);
    } catch (err) {
      consola.error(`Error enabling source ${sourceId}`, err);
      toast({ status: 'error', description: 'Error enabling ingestion status' });
    } finally {
      onClose();
    }
  };

  return (
    <Stack>
      <HStack justify="space-between">
        <HStack fontSize="sm" spacing={1}>
          <ChakraFaCircle color={statusColor} />
          <chakra.span fontWeight="semibold">{statusLabel}</chakra.span>
        </HStack>
        <Box>
          <Menu>
            <MenuButton
              as={Button}
              variant="ghost"
              colorScheme="action"
              rightIcon={<Icon iconImage={IconImage.chevronDown} />}
            >
              Edit
            </MenuButton>
            <MenuList
              fontSize="sm"
              fontWeight="light"
              minW="100%"
              bg="gray.50"
              border="1px solid"
              borderColor="gray.300"
            >
              <MenuItem
                isDisabled={status === 'enabled'}
                _hover={{ color: 'action', bg: 'none' }}
                _disabled={{
                  fontWeight: 'semibold',
                  cursor: 'not-allowed',
                  _hover: { color: 'gray.800' },
                }}
                onClick={onOpen}
              >
                Enabled
              </MenuItem>
              <MenuItem
                isDisabled={status === 'disabled'}
                _hover={{ color: 'action', bg: 'none' }}
                _disabled={{
                  fontWeight: 'semibold',
                  cursor: 'not-allowed',
                  _hover: { color: 'gray.800' },
                }}
                onClick={onOpen}
              >
                Disabled
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </HStack>
      <Text fontSize="sm">{statusMessage}</Text>
      <ConfirmationModal
        enabled={!(status === 'enabled')}
        isOpen={isOpen}
        onClose={onClose}
        onConfirmation={(enabled) => updateStatus(enabled ? 'enabled' : 'disabled')}
      />
    </Stack>
  );
}
