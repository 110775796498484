import {
  Box,
  Button,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  useFieldArray,
  useForm,
  UseFormRegister,
} from 'react-hook-form';
import { UseFormWatch } from 'react-hook-form/dist/types/form';
import * as yup from 'yup';
import CustomerValueSettingsClient from '../../../../lib/api-client/customer-value-settings/CustomerValueSettingsClient';
import { CustomerValueSettings } from '../../../../lib/api-client/customer-value-settings/model/customer-value-settings';
import Alert from '../../../core/Alert/Alert';
import Icon from '../../../core/Icon/Icon';
import { IconImage } from '../../../core/Icon/IconConfig';
import SectionContainer from '../../../core/SectionContainer/SectionContainer';
import UnsavedChangesModal from '../../../shared/modals/UnsavedChangesModal/UnsavedChangesModal';

const variableCostOptions = {
  FOOD_BEVERAGE: [
    {
      label: 'Alcoholic beverages',
      value: 'ALCOHOLIC_BEVERAGE',
    },
    {
      label: 'Food',
      value: 'FOOD',
    },
    {
      label: 'Non-alcoholic beverages',
      value: 'NONALCOHOLIC_BEVERAGE',
    },
  ],
};

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirmation: () => void;
  onCancel?: () => void;
}

function ConfirmationModal({
  isOpen,
  onClose,
  onConfirmation,
  onCancel = onClose,
}: ConfirmationModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm">
      <ModalOverlay />
      <ModalContent data-testid="ConfirmationModal">
        <ModalHeader>Are you sure you want to save these configuration changes?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Changes to the consumer value measure configuration will impact all consumer profiles.
        </ModalBody>
        <ModalFooter justifyContent="flex-start">
          <Button onClick={() => onConfirmation()} mr={4}>
            Save now
          </Button>
          <Button variant="outline" onClick={onCancel}>
            Don&apos;t save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function ConsumerValueMeasure() {
  return (
    <SectionContainer title="Consumer value measure" mb="10">
      <Text mb="4">
        Consumer value measure is a metric that calculates the value of a consumer’s relationship
        with your business. harpin {`AI's`} model considers revenue gained and costs incurred from
        the relationship along with a number of expert factors that can be included. Each of these
        components can be configured below to uniquely reflect your business.
      </Text>
      <Text mb="4">Hover over each component in the equation below to learn more.</Text>
      <Text fontWeight="bold" fontSize="md">
        consumer value measure = (
        <Tooltip
          placement="top"
          hasArrow
          label="Revenue is the total amount of money received from transactions by an individual consumer."
          aria-label="revenue tooltip"
        >
          <chakra.span color="action">revenue</chakra.span>
        </Tooltip>{' '}
        -{' '}
        <Tooltip
          placement="top"
          hasArrow
          label="Cost is the total amount of business expenses incurred from transactions by an individual consumer."
          aria-label="cost tooltip"
        >
          <chakra.span color="action">cost</chakra.span>
        </Tooltip>
        ) x{' '}
        <Tooltip
          placement="top"
          hasArrow
          label="Expert factors are a coefficient used to magnify or shrink an individual consumer’s value."
          aria-label="expert factors tooltip"
        >
          <chakra.span color="action">expert factors</chakra.span>
        </Tooltip>
      </Text>
    </SectionContainer>
  );
}

interface RevenueConfigurationProps {
  register: UseFormRegister<CustomerValueSettings>;
}

function RevenueConfiguration({ register }: RevenueConfigurationProps) {
  return (
    <SectionContainer title="Revenue configuration" titleMargin="6" mb="10">
      <>
        <Text fontWeight="bold" mb="2">
          Within what time window would you like to calculate revenue?
        </Text>
        <Select width="320px" {...register('revenueWindowMonths')}>
          <option value={3}>Last 3 months</option>
          <option value={6}>Last 6 months</option>
          <option value={9}>Last 9 months</option>
          <option value={12}>Last 12 months</option>
          <option value={18}>Last 18 months</option>
          <option value={24}>Last 2 years</option>
          <option value={36}>Last 3 years</option>
          <option value={48}>Last 4 years</option>
          <option value={60}>Last 5 years</option>
        </Select>
      </>
    </SectionContainer>
  );
}

function VariableCostPercentageConfiguration({
  register,
  errors,
  control,
  options,
  variableCostPercentage,
  disabled,
}: VariableCostPercentageConfigurationProps) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'variableCostPercentage',
  });

  return (
    <Box>
      <Text fontWeight="bold" mb="2">
        What percentage of revenue from each product or service category is allocated towards cost?
      </Text>
      {fields.map((field, index) => (
        <Flex key={field.id} alignItems="start" justifyContent="start" mt={index === 0 ? '0' : '6'}>
          <FormControl
            isInvalid={
              !!(
                errors?.variableCostPercentage &&
                errors?.variableCostPercentage[index]?.costCategory
              )
            }
            width="320px"
            mr="6"
          >
            <Select
              id="newCategory"
              {...register(`variableCostPercentage.${index}.costCategory`)}
              placeholder="Select one..."
              disabled={disabled}
            >
              {options.map((o) => (
                <option
                  key={o.value}
                  value={o.value}
                  disabled={variableCostPercentage.map((v) => v.costCategory).includes(o.value)}
                >
                  {o.label}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              {errors?.variableCostPercentage &&
                errors?.variableCostPercentage[index]?.costCategory?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={
              !!(
                errors?.variableCostPercentage &&
                errors?.variableCostPercentage[index]?.costPercentage
              )
            }
            width="140px"
          >
            <InputGroup>
              <Input
                {...register(`variableCostPercentage.${index}.costPercentage`)}
                type="number"
                maxLength={3}
                disabled={disabled}
              />
              <InputRightElement color="gray.400">%</InputRightElement>
            </InputGroup>
            <FormErrorMessage>
              {errors?.variableCostPercentage &&
                errors?.variableCostPercentage[index]?.costPercentage?.message}
            </FormErrorMessage>
          </FormControl>
          {disabled ? (
            <Box ml="6" alignSelf="center">
              <Tooltip
                hasArrow
                placement="top"
                label="You must select a business category above before selecting product or service categories."
                shouldWrapChildren
              >
                <Icon iconImage={IconImage.info} color="action" />
              </Tooltip>
            </Box>
          ) : (
            <Button
              variant="ghost"
              onClick={() => remove(index)}
              disabled={variableCostPercentage.length === 1}
              ml="6"
            >
              Remove
            </Button>
          )}
        </Flex>
      ))}
      <Button
        mt="2"
        variant="ghost"
        disabled={disabled || variableCostPercentage.length === options.length}
        rightIcon={<Icon iconImage={IconImage.add} />}
        onClick={() => append({ costPercentage: 0, costCategory: '' })}
      >
        Add another category
      </Button>
    </Box>
  );
}

interface CostConfigurationProps {
  register: UseFormRegister<CustomerValueSettings>;
  errors: FieldErrors<CustomerValueSettings>;
  control: Control<CustomerValueSettings, object>;
  watch: UseFormWatch<CustomerValueSettings>;
}

function CostConfiguration({ register, errors, control, watch }: CostConfigurationProps) {
  const costModel = watch('costModel');
  const variableCostPercentage = watch('variableCostPercentage', []) || [];
  const businessCategory = watch('businessCategory');

  return (
    <SectionContainer title="Cost configuration" titleMargin="6" mb="10">
      <Stack spacing="6">
        <FormControl isInvalid={!!errors.businessCategory}>
          <FormLabel htmlFor="businessCategory" fontWeight="bold" mb="2">
            What category best describes your business?
          </FormLabel>
          <Flex>
            <Box>
              <Select
                id="businessCategory"
                width="320px"
                {...register('businessCategory')}
                placeholder="Select one..."
              >
                <option value="FOOD_BEVERAGE">Food & Beverage</option>
              </Select>
              <FormErrorMessage>{errors?.businessCategory?.message}</FormErrorMessage>
            </Box>
            {costModel === 'VARIABLE' && !businessCategory && (
              <Alert
                ml="6"
                h="48px"
                status="info"
                description="Please select a business category"
              />
            )}
          </Flex>
        </FormControl>
        <Box>
          <Text fontWeight="bold" mb="4">
            What cost model best fits your business?
          </Text>
          <Controller
            control={control}
            name="costModel"
            render={({ field }) => (
              <RadioGroup {...field}>
                <Stack>
                  <Radio value="UNIVERSAL">Universal cost for all products and services</Radio>
                  <Radio value="VARIABLE">Variable cost by product or service category</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </Box>
        {costModel === 'UNIVERSAL' && (
          <Box>
            <Text fontWeight="bold" mb="2">
              What percentage of revenue from each transaction is allocated towards cost?
            </Text>
            <FormControl isInvalid={!!errors.universalCostPercentage}>
              <InputGroup width="140px">
                <Input {...register('universalCostPercentage')} type="number" maxLength={3} />
                <InputRightElement color="gray.400">%</InputRightElement>
              </InputGroup>
              <FormErrorMessage>{errors?.universalCostPercentage?.message}</FormErrorMessage>
            </FormControl>
          </Box>
        )}
        {costModel === 'VARIABLE' && (
          <VariableCostPercentageConfiguration
            register={register}
            errors={errors}
            control={control}
            options={variableCostOptions.FOOD_BEVERAGE}
            variableCostPercentage={variableCostPercentage}
            disabled={!businessCategory}
          />
        )}
      </Stack>
    </SectionContainer>
  );
}

interface ExpertFactorsConfigurationProps {
  control: Control<CustomerValueSettings, object>;
}

function ExpertFactorsConfiguration({ control }: ExpertFactorsConfigurationProps) {
  return (
    <SectionContainer title="Expert factors configuration" titleMargin="6" mb="10">
      <>
        <Text fontWeight="bold" mb="4">
          What expert factors would you like to include in your value measure?
        </Text>
        <VStack alignItems="start" spacing="4">
          <Controller
            control={control}
            name="factorLengthOfRelationshipEnabled"
            render={({ field: { onChange, value, ref } }) => (
              <HStack>
                <Switch onChange={onChange} isChecked={value} ref={ref} />
                <VStack alignItems="start" spacing="1">
                  <Text>Length of relationship</Text>
                  <Text>This factors in how long a consumer has been doing business with you.</Text>
                </VStack>
              </HStack>
            )}
          />
          <Controller
            control={control}
            name="factorFrequencyOfInteractionEnabled"
            render={({ field: { onChange, value, ref } }) => (
              <HStack>
                <Switch onChange={onChange} isChecked={value} ref={ref} />
                <VStack alignItems="start" spacing="1">
                  <Text>Frequency of interaction</Text>
                  <Text>
                    This factors in how frequently a consumer interacts with your business.
                  </Text>
                </VStack>
              </HStack>
            )}
          />
        </VStack>
      </>
    </SectionContainer>
  );
}

interface VariableCostPercentageConfigurationProps {
  register: UseFormRegister<CustomerValueSettings>;
  errors: FieldErrors<CustomerValueSettings>;
  control: Control<CustomerValueSettings, object>;
  options: { label: string; value: string }[];
  variableCostPercentage: any[];
  disabled: boolean;
}

const formSchema = yup
  .object({
    costModel: yup.string(),
    businessCategory: yup.string().required('This is a required selection').nullable(),
    universalCostPercentage: yup
      .number()
      .nullable()
      .when('costModel', {
        is: (costModel: string) => costModel === 'UNIVERSAL',
        then: () =>
          yup
            .number()
            .typeError('Enter a valid cost percentage')
            .min(0, 'Cost percentage must be at least 0%')
            .max(100, 'Cost percentage must not be greater that 100%')
            .required('Enter a valid cost percentage')
            .nullable(),
      }),
    variableCostPercentage: yup.array().when('costModel', {
      is: (costModel: string) => costModel === 'VARIABLE',
      then: () =>
        yup.array().of(
          yup.object().shape({
            costCategory: yup.string().required('This is a required selection').nullable(),
            costPercentage: yup
              .number()
              .typeError('Enter a valid cost percentage')
              .min(0, 'Cost percentage must be at least 0%')
              .max(100, 'Cost percentage must not be greater that 100%')
              .required('Enter a valid cost percentage')
              .nullable(),
          })
        ),
    }),
  })
  .required();

interface AdminConfigurationsTabProps {
  initialCustomerValueSettings?: CustomerValueSettings;
}

function AdminConfigurationsTab({ initialCustomerValueSettings }: AdminConfigurationsTabProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isSubmitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [customerValueSettings, setCustomerValueSettings] = useState(initialCustomerValueSettings);

  const {
    handleSubmit,
    register,
    reset,
    control,
    watch,
    formState: { isValid, isDirty, errors },
  } = useForm<CustomerValueSettings>({
    resolver: yupResolver(formSchema),
    mode: 'onBlur',
  });

  const updateCustomerValueSettings = async () => {
    onClose();
    if (!customerValueSettings) {
      return;
    }

    setError(false);
    setSuccess(false);
    setSubmitting(true);

    try {
      const request = { ...customerValueSettings };
      if (customerValueSettings.costModel === 'UNIVERSAL') {
        delete request.variableCostPercentage;
      } else {
        delete request.universalCostPercentage;
      }

      await CustomerValueSettingsClient.updateCustomerValueSettings(request);
      setSuccess(true);
    } catch (err) {
      setError(true);
    } finally {
      setSubmitting(false);
    }
  };

  const onSubmit: SubmitHandler<CustomerValueSettings> = async (form) => {
    setCustomerValueSettings(form);
    onOpen();
  };

  useEffect(() => {
    reset(customerValueSettings);
  }, [customerValueSettings, reset]);

  return (
    <>
      <ConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirmation={updateCustomerValueSettings}
      />
      <UnsavedChangesModal
        message="Are you sure you want to leave before saving your changes to the consumer value measure
          configuration?"
        warnOnNavigation={isDirty}
      />
      <form data-testid="LTVConfigurationForm" onSubmit={handleSubmit(onSubmit)}>
        {success && (
          <Alert status="success" description="Your changes have been saved." closeable mb={10} />
        )}
        {error && (
          <Alert
            status="error"
            title="We were unable to save your changes due to a system error."
            description="Please try again or come back later. We apologize for the inconvenience."
            closeable
            mb={10}
          />
        )}
        <ConsumerValueMeasure />

        <Box w="680px">
          <RevenueConfiguration register={register} />
          <CostConfiguration register={register} errors={errors} control={control} watch={watch} />
          <ExpertFactorsConfiguration control={control} />
          <Button disabled={!isValid} isLoading={isSubmitting} type="submit">
            Save all configurations
          </Button>
        </Box>
      </form>
    </>
  );
}

export default AdminConfigurationsTab;
