import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import { Events } from '../../../../lib/api-client/profile/model/Events';
import { Profile } from '../../../../lib/api-client/profile/model/Profile';
import { formatDateString, formatDays } from '../../../../lib/utils/date-time-utils';
import ActivityTrackerDetailsCard from '../../../core/ActivityTrackerDetails/ActivityTrackerDetailsCard';
import Alert from '../../../core/Alert/Alert';
import CustomerEventValue from '../CustomerEventValue/CustomerEventValue';

interface ProfilePageActivityTrackerDataProps {
  events: Events[];
  handleClick?: (keyValue: string) => void;
  profile?: Profile;
  keyValue: string;
}
function ProfilePageActivityTrackerData({
  events = [],
  profile,
  keyValue,

  handleClick,
}: ProfilePageActivityTrackerDataProps) {
  const event = events.slice(0, 3);
  const eventsSummary = profile?.eventsSummary?.[keyValue];

  let content = (
    <Alert
      status="warning"
      variant="subtle"
      description="There is no activity data of this type on record for this consumer."
    />
  );
  if (event.length > 0) {
    content = (
      <HStack alignItems="start" spacing={6}>
        <VStack spacing="5" alignItems="start">
          {event.map((item) => (
            <ActivityTrackerDetailsCard
              key={`${item.sourceId}-${item.sourceRecordId}`}
              sourceId={item.sourceId}
              sourceRecordId={item.sourceRecordId}
              eventName={item.eventName}
              sourceRecordTimestamp={item.sourceRecordTimestamp}
              locationName={item.locationName}
              locationDetailedName={item.locationDetailedName}
              persistedValues={item.persistedValues}
            />
          ))}
          {events.length > 0 && (
            <Button mt={6} mb={4} onClick={() => handleClick && handleClick(keyValue)}>
              See all activity and insights
            </Button>
          )}
        </VStack>
        <CustomerEventValue
          totalEventValue={eventsSummary?.eventsLastYear ?? 0}
          averageTotalEvent={eventsSummary?.averageAnnualEventCount ?? 0}
          averageDaysBetweenEvents={formatDays(eventsSummary?.averageDaysBetweenEvents)}
          firstEvent={formatDateString(eventsSummary?.earliestEventTimestamp ?? '', 'LLL dd, yyyy')}
          mostRecentEvent={formatDateString(
            eventsSummary?.latestEventTimestamp ?? '',
            'LLL dd, yyyy'
          )}
        />
      </HStack>
    );
  }
  return <Box width="680px">{content}</Box>;
}
export default ProfilePageActivityTrackerData;
