import { Box, HStack, Text } from '@chakra-ui/react';
import { CellContext } from '@tanstack/react-table';
import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';

export default function ProfileLinkCell<T>({
  cellContext,
}: {
  cellContext: CellContext<T, string | undefined>;
}) {
  const v = cellContext.getValue();
  if (!v) {
    return null;
  }

  return (
    <HStack
      spacing={1}
      justify="space-between"
      as={Link}
      to={`/profiles/${v}`}
      target="_blank"
      onClick={(e) => e.stopPropagation()}
    >
      <Text
        textDecoration="none"
        _hover={{ color: 'action' }}
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {v}
      </Text>
      <Box>
        <Icon color="action" iconImage={IconImage.externalLink} boxSize={5} />
      </Box>
    </HStack>
  );
}
