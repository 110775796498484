import { HStack, Skeleton, Stack } from '@chakra-ui/react';
import React from 'react';

export default function DataExplorerSkeleton() {
  return (
    <Stack spacing={4}>
      <HStack spacing={4}>
        <Skeleton h={10} flex={1} />
        <Skeleton h={10} flex={1} />
      </HStack>
      <HStack spacing={4}>
        <Skeleton h={10} flex={1} />
        <Skeleton h={10} flex={1} />
        <Skeleton h={10} flex={1} />
        <Skeleton h={10} flex={1} />
        <Skeleton h={10} flex={1} />
      </HStack>
    </Stack>
  );
}
