import { As, Icon, IconProps as ChakraIconProps } from '@chakra-ui/react';
import { isUndefined } from '../../../lib/utils/utils';
import { IconImage, IconMap } from './IconConfig';

// TODO Typescript really didn't like the css  property. We can try and remove this later
export interface IconProps extends Omit<ChakraIconProps, 'css'> {
  iconImage?: IconImage;
  as?: As;
}

function IconWrapper({ iconImage = undefined, ...props }: IconProps) {
  return (
    <Icon boxSize={6} data-testid="Icon" {...props}>
      {!isUndefined(iconImage) && IconMap.get(iconImage)}
    </Icon>
  );
}
export default IconWrapper;
