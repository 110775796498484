// Lifted from https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage
function storageAvailable() {
  let storage: any;
  try {
    storage = window.sessionStorage;
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

const isSessionStorageAvailable: boolean = storageAvailable();
const memoryStorage: { [keyof: string]: string } = {};

function getItem(key: string): string | null {
  if (isSessionStorageAvailable) {
    return sessionStorage.getItem(key);
  }

  return memoryStorage[key] || null;
}

function setItem(key: string, value: string) {
  if (isSessionStorageAvailable) {
    sessionStorage.setItem(key, value);
  } else {
    memoryStorage[key] = value;
  }
}

function removeItem(key: string) {
  if (sessionStorage) {
    sessionStorage.removeItem(key);
  } else {
    delete memoryStorage[key];
  }
}

const LocalStorageService = {
  getItem,
  setItem,
  removeItem,
};

export default LocalStorageService;
