import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Select,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { UseFieldArrayReturn, useFormContext } from 'react-hook-form';
import Icon from '../../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../../../components/core/SectionContainer/SectionContainer';
import {
  AttributeDomainType,
  DataModel,
} from '../../../../../lib/api-client/data-model/data-model.model';
import {
  filterAttributeDomainsFromDataModel,
  getAttributeMetadataFromDataModel,
} from '../../../../../lib/api-client/data-model/data-model.utils';
import { useExternalDataTypeModel } from '../../../../../lib/api-client/sources/SourceData';
import {
  DataSource,
  DataSourceProperties,
} from '../../../../../lib/api-client/sources/model/DataSource';
import { DataSourceAttributeMapping } from '../../../../../lib/api-client/sources/model/DataSourceAttributeMapping';
import { UpdateDataSource } from '../../../../../lib/api-client/sources/model/UpdateDataSource';
import { UpdateDataSourceForm } from '../../DataMappingPage.utils';
import AttributeRow from '../AttributeRow/AttributeRow';
import AttributeRowHeader from '../AttributeRowHeader/AttributeRowHeader';

const inputStyles = {
  width: '280px',
};

export interface PublishTransactionsSectionProps {
  source: DataSource;
  dataModel: DataModel;
  useFieldArray: UseFieldArrayReturn<UpdateDataSourceForm, 'attributeMappings'>;
}

export default function PublishTransactionsSection({
  source,
  dataModel,
  useFieldArray,
}: PublishTransactionsSectionProps) {
  const [isPublishTransactions, setPublishTransactions] = useState(
    !!(
      source?.properties?.transactionSourceEntityName ||
      source?.properties?.transactionLineItemSourceEntityName
    )
  );
  const [dataType, setDataType] = useState(
    source.properties?.transactionLineItemSourceEntityName
      ? 'transactionLineItemSourceEntityName'
      : 'transactionSourceEntityName'
  );
  const publishDomains: AttributeDomainType[] =
    dataType === 'transactionSourceEntityName'
      ? ['TRANSACTION']
      : ['TRANSACTION', 'TRANSACTION_LINE_ITEM'];
  const transactionDataModel = filterAttributeDomainsFromDataModel(dataModel, ...publishDomains);
  const attributeMetadata = getAttributeMetadataFromDataModel(transactionDataModel).map((a) => ({
    ...a,
    displayName: `${a.domain === 'TRANSACTION' ? 'Transaction' : 'Transaction line item'}: ${
      a.displayName
    }`,
  }));
  const domainIds = transactionDataModel.domains.map((domain) => domain.id);
  const { setValue, watch } = useFormContext<UpdateDataSource>();
  const { fields, append } = useFieldArray;
  const [watchedAttributeMappings, watchedProperties] = watch(['attributeMappings', 'properties']);
  const [previousMappings, setPreviousMappings] = useState<
    | {
        attributeMappings?: DataSourceAttributeMapping[];
        properties?: DataSourceProperties;
      }
    | undefined
  >();

  const { data: externalDataModelType } = useExternalDataTypeModel(source.id, {
    domain: dataType === 'transactionSourceEntityName' ? 'TRANSACTION' : 'TRANSACTION_LINE_ITEM',
  });

  const handleChange = (e: any) => {
    const attribute: any = `properties.${dataType}`;
    setValue(attribute, e.target.value);
  };
  const initialSelectedDataObjectType =
    watchedProperties?.[dataType as keyof DataSourceProperties] || '';

  return (
    <SectionContainer
      header={
        <HStack justify="space-between">
          <Text fontSize="md" lineHeight={5} fontWeight="bold" width="full">
            Publish transactions
          </Text>
          <HStack spacing={1}>
            <FormLabel m={0}>{isPublishTransactions ? 'Enabled' : 'Disabled'}</FormLabel>
            <Switch
              isChecked={isPublishTransactions}
              onChange={() => {
                if (isPublishTransactions) {
                  setPreviousMappings({
                    attributeMappings: [...watchedAttributeMappings],
                    properties: { ...watchedProperties },
                  });
                  const filtered = watchedAttributeMappings.filter(
                    (mapping) => !mapping.domainType?.startsWith('TRANSACTION')
                  );
                  setValue('attributeMappings', filtered);
                  setValue(`properties.transactionSourceEntityName`, null);
                  setValue(`properties.transactionLineItemSourceEntityName`, null);
                } else if (previousMappings) {
                  setValue('attributeMappings', previousMappings.attributeMappings ?? []);
                  setValue(
                    `properties.transactionSourceEntityName`,
                    previousMappings.properties?.transactionSourceEntityName
                  );
                  setValue(
                    `properties.transactionLineItemSourceEntityName`,
                    previousMappings.properties?.transactionLineItemSourceEntityName
                  );
                }
                setPublishTransactions(!isPublishTransactions);
              }}
            />
          </HStack>
        </HStack>
      }
    >
      {isPublishTransactions ? (
        <>
          <HStack pb={6} spacing="76px">
            <FormControl {...inputStyles}>
              <FormLabel>Data type</FormLabel>
              <Select
                value={dataType}
                onChange={(event) => {
                  const { value } = event.target;
                  setDataType(value);
                  const fieldToClear =
                    value === 'transactionSourceEntityName'
                      ? 'transactionLineItemSourceEntityName'
                      : 'transactionSourceEntityName';
                  setValue(`properties.${fieldToClear}`, null);
                }}
              >
                <option value="transactionSourceEntityName">Transactions</option>
                <option value="transactionLineItemSourceEntityName">Transaction line items</option>
              </Select>
            </FormControl>
            <FormControl {...inputStyles}>
              <FormLabel>Object type</FormLabel>
              <Select
                value={initialSelectedDataObjectType}
                placeholder="Select one"
                onChange={handleChange}
              >
                {externalDataModelType
                  ?.sort((a: any, b: any) => a.label.localeCompare(b.label))
                  .map((a: any) => (
                    <option key={a.name} value={a.name}>
                      {a.label}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </HStack>
          <SectionContainer title="Field level mappings" titleFontSize="sm">
            <Stack>
              <AttributeRowHeader syncDirection="OUT" layout="OUTBOUND" showSyncHelp={false} />
              {fields.map((item, index) =>
                domainIds.includes(item.domainType!) ? (
                  <AttributeRow
                    sourceAttributes={[]}
                    syncDirection="OUT"
                    item={item}
                    index={index}
                    key={`${dataType}_${item.id}`}
                    attributeMetadata={attributeMetadata}
                    useFieldArray={useFieldArray}
                    layout="OUTBOUND_PUBLISH_TX"
                    externalDataModel={externalDataModelType}
                  />
                ) : null
              )}
              <Box>
                <Button
                  variant="ghost"
                  rightIcon={<Icon iconImage={IconImage.add} />}
                  onClick={() => {
                    append({
                      mappingType: 'SINGLE_SOURCE_ATTRIBUTE',
                      domainType: 'TRANSACTION',
                      canonicalAttribute: '',
                      sourceAttributes: [{ value: '' }],
                    });
                  }}
                >
                  Add a new mapping
                </Button>
              </Box>
            </Stack>
          </SectionContainer>
        </>
      ) : (
        <Text>This feature is currently disabled.</Text>
      )}
    </SectionContainer>
  );
}
