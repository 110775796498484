import { Table } from '@tanstack/react-table';
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

export interface DataExplorerContext<T> {
  table: Table<T>;
  filterDrawerDisclosure: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
  };
  editMode: boolean;
  setEditMode: (mode: boolean) => void;
}

const Context = createContext<DataExplorerContext<any>>({
  table: null as unknown as Table<any>,
} as any);

export function useDataExplorer<T>(): DataExplorerContext<T> {
  return useContext(Context);
}

interface DataExplorerProviderProps<T> {
  table: Table<T>;
  filterDrawerDisclosure: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
  };
  children: ReactNode;
}
export function DataExplorerProvider({
  table,
  filterDrawerDisclosure,
  children,
}: DataExplorerProviderProps<any>) {
  const [editMode, setEditMode] = useState<boolean>(false);

  const value = useMemo(
    () => ({
      table,
      filterDrawerDisclosure,
      editMode,
      setEditMode,
    }),
    [table, filterDrawerDisclosure, editMode]
  );
  return <Context.Provider value={value}>{children}</Context.Provider>;
}
