import * as Sentry from '@sentry/react';
import { AxiosRequestConfig } from 'axios';
import flagsmith from 'flagsmith';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import config from './config';
import { addInterceptor, setBaseUrl } from './lib/api-client/ApiClient';
import { SignInResult } from './lib/api-client/authentication/model/SignInResult';
import Mixpanel from './lib/mixpanel/Mixpanel';
// import { reactRouterV6Instrumentation } from './lib/sentry/SentryReactRouterV6RouterInstrumentation/SentryReactRouterV6RouterInstrumentation';
import AuthenticationService from './lib/services/AuthenticationService';

let isTokenRefreshing = false;
let sessionTokenPromise: Promise<SignInResult | void> = Promise.resolve();

function setupTokenInterceptor() {
  function onSignInResult(axiosRequestConfig: AxiosRequestConfig, result: SignInResult | void) {
    if (result && result.result === 'success' && result.token && axiosRequestConfig.headers) {
      const { headers } = axiosRequestConfig;
      headers.Authorization = `Bearer ${result.token}`;
    }

    return axiosRequestConfig;
  }

  addInterceptor((axiosRequestConfig: AxiosRequestConfig) => {
    if (!AuthenticationService.isSessionExpired()) {
      return axiosRequestConfig;
    }

    if (isTokenRefreshing) {
      return sessionTokenPromise.then((result) => onSignInResult(axiosRequestConfig, result));
    }

    isTokenRefreshing = true;
    sessionTokenPromise = AuthenticationService.verifySession();

    return sessionTokenPromise
      .then((result) => onSignInResult(axiosRequestConfig, result))
      .catch(() => axiosRequestConfig)
      .finally(() => {
        isTokenRefreshing = false;
      });
  });
}

function setupSentry() {
  if (config.get().SENTRY_DSN) {
    let tracingOrigins: Array<string | RegExp> = [/^\//];
    if (config.get().SENTRY_TRACING_ORIGINS) {
      tracingOrigins = [...tracingOrigins, ...config.get().SENTRY_TRACING_ORIGINS!];
    }
    Sentry.init({
      dsn: config.get().SENTRY_DSN,
      release: process.env.REACT_APP_SENTRY_RELEASE,
      environment: config.get().APP_ENV,
      integrations: [
        new Sentry.BrowserTracing({
          idleTimeout: config.get().SENTRY_IDLE_TIMEOUT_MS,
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
          tracingOrigins,
        }),
      ],
      tracesSampleRate: config.get().SENTRY_TRACE_SAMPLE_RATE || 0,
    });
  }
}

function setupFlagsmith() {
  const flagsmithEnvId = config.get().FLAGSMITH_ENV_ID;
  if (flagsmithEnvId) {
    return flagsmith.init({
      environmentID: flagsmithEnvId,
      enableAnalytics: true,
      angularHttpClient: undefined, // type oddity
    });
  }

  return Promise.resolve();
}

function setupMixpanel() {
  const mixpanelToken = config.get().MIXPANEL_TOKEN;
  if (mixpanelToken && !config.get().MIXPANEL_DISABLE) {
    Mixpanel.init(mixpanelToken);
  }
}

/**
 * Run code prior to the React application loading
 */
// eslint-disable-next-line import/prefer-default-export
export async function bootstrap() {
  setBaseUrl(config.get().BASE_API_URL);
  setupSentry();
  setupMixpanel();
  setupTokenInterceptor();
  await setupFlagsmith();
}
