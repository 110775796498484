import { useToken } from '@chakra-ui/react';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { useDataSources } from '../../../lib/api-client/sources/SourceData';

interface Context {
  getColorForSource(sourceId: string): string;
}

const SourceColorContext = createContext<Context | null>(null);

const colors = ['#43D6BA', '#20B4C4', '#007FA7', '#005874', '#092747', '#46326A', '#8E3075'];

export function SourceColorContextProvider({ children }: PropsWithChildren) {
  const { data } = useDataSources();
  const [gray400] = useToken('colors', ['gray.400']);

  const value = useMemo(() => {
    const sources = data?.content ?? [];
    const sourceColorMap: Record<string, string> = {};

    sources
      .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
      .forEach((source, index) => {
        sourceColorMap[source.id] = colors[index % colors.length];
      });

    return {
      getColorForSource: (sourceId: string) => sourceColorMap[sourceId] ?? gray400,
    };
  }, [data?.content, gray400]);

  return <SourceColorContext.Provider value={value}>{children}</SourceColorContext.Provider>;
}

export function useSourceColor(): Context {
  const context = useContext(SourceColorContext);

  if (context == null) {
    throw new Error('SourceColorContext is null. Have you wrapped SourceColorContextProvider?');
  }

  return context;
}
