import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBoolean,
  useDisclosure,
} from '@chakra-ui/react';
import { useXSWR } from '@hazae41/xswr';
import { isAxiosError } from 'axios';
import React, { useCallback } from 'react';
import { useToast } from '../../../../hooks/use-toast';
import { downloadData } from '../../../../lib/api-client/ApiClient';
import ReportExecutionClient from '../../../../lib/api-client/reports/ReportExecutionClient';
import {
  getReport,
  startReportExecutionSchema,
} from '../../../../lib/api-client/reports/ReportExecutionData';
import { StartReportExecution } from '../../../../lib/api-client/reports/report.model';

interface ExportCsvButtonProps {
  reportParams: StartReportExecution;
}

export default function ExportCsvButton({ reportParams }: ExportCsvButtonProps) {
  const modal = useDisclosure();
  const [isLoading, setLoading] = useBoolean();
  const { make } = useXSWR();
  const toast = useToast();

  const exportData = useCallback(
    async (includeSecondaryPii = false) => {
      setLoading.on();
      toast({
        title: 'CSV is being prepared for export.',
        description: 'Hold tight. This may take a few min.',
      });

      const params = { ...reportParams };
      if (includeSecondaryPii) {
        if (!params.options) {
          params.options = [];
        }
        params.options.push({ optionName: 'includeSecondaryPii' });
      }

      const startReportExecution = await make(startReportExecutionSchema(reportParams)).fetch();
      if (startReportExecution) {
        const { data: reportExecution = { reportId: '' } } = startReportExecution;

        await new Promise<void>((resolve, reject) => {
          const intervalId = setInterval(async () => {
            const state = await make(getReport(reportExecution.reportId, { pageSize: 10 })).fetch();
            if (isAxiosError(state?.error) && state?.error.response?.status !== 425) {
              reject();

              toast({
                title: 'There was a problem exporting your CSV.',
                status: 'error',
              });
              clearInterval(intervalId);
            }

            if (state?.data) {
              await ReportExecutionClient.exportReportData(reportExecution.reportId).then((res) =>
                downloadData(res, `${reportParams.reportType}_export_${new Date().getTime()}.csv`)
              );
              resolve();

              toast({
                title: 'CSV has been exported.',
                status: 'success',
              });
              clearInterval(intervalId);
            }
          }, 5000);
        });
      }

      setLoading.off();
    },
    [make, reportParams, toast, setLoading]
  );

  return (
    <>
      <Button
        variant="outline"
        onClick={modal.onOpen}
        isLoading={isLoading}
        loadingText="Exporting..."
      >
        Export CSV
      </Button>
      <Modal isOpen={modal.isOpen} onClose={modal.onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Select data for export
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            You can choose to export all data on record for this source system, which will include
            supplemental values ingested for a data field. Alternatively, you can choose to export
            only the primary data that is displayed in the data table.
          </ModalBody>
          <ModalFooter justifyContent="flex-start">
            <HStack spacing={4}>
              <Button
                onClick={async () => {
                  modal.onClose();
                  await exportData(true);
                }}
              >
                Export all data
              </Button>
              <Button
                variant="outline"
                onClick={async () => {
                  modal.onClose();
                  await exportData();
                }}
              >
                Export only primary data
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
