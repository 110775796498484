import { Tag, TagLabel, TagProps, Tooltip } from '@chakra-ui/react';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import { formatPercentage } from '../../../../lib/utils/number-utils';

interface DataFluctuationChipProps {
  colorScheme?: string;
  color?: string;
  value: number;
  timePeriod?: string;
}

export default function DataFluctuationChip({
  colorScheme,
  value,
  timePeriod = '24 hours',
  ...rest
}: DataFluctuationChipProps & TagProps) {
  let icon: IconImage | undefined;
  let message: string;
  let formattedValue = formatPercentage(Math.abs(value), 1);

  if (value > 0) {
    icon = IconImage.arrowUp;
    if (value < 0.001) {
      formattedValue = '<0.1%';
    }
    message = `${formattedValue} increase in the last ${timePeriod}`;
  } else if (value < 0) {
    icon = IconImage.arrowDown;
    if (value > -0.001) {
      formattedValue = '<0.1%';
    }
    message = `${formattedValue} decrease in the last ${timePeriod}`;
  } else {
    message = `No change in the last ${timePeriod}`;
    formattedValue = 'no change';
  }

  return (
    <Tooltip hasArrow placement="top" label={message}>
      <Tag
        data-testid="DataFluctuationChip"
        color="gray.800"
        bgColor="white"
        border="1px"
        borderRadius="6px"
        borderColor={colorScheme}
        rounded="full"
        fontSize="xs"
        minH={5}
        px={1.5}
        flexShrink="0"
        {...rest}
      >
        {icon && (
          <Icon data-testid={`DataFluctuationChip_Icon_${icon}`} boxSize={4} iconImage={icon} />
        )}
        <TagLabel fontWeight="400" lineHeight={1}>
          {formattedValue}
        </TagLabel>
      </Tag>
    </Tooltip>
  );
}
