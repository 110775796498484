import { Tag, TagLabel, TagProps, Tooltip } from '@chakra-ui/react';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import { formatPercentage } from '../../../../lib/utils/number-utils';

interface DataFluctuationChipProps {
  colorScheme?: string;
  color?: string;
  value: number;
}

export default function DataFluctuationChip({
  colorScheme,
  color = 'white',
  value,

  ...rest
}: DataFluctuationChipProps & TagProps) {
  let icon: IconImage | undefined;
  let message: string;
  let formattedValue = formatPercentage(value, 1);

  if (value > 0) {
    icon = IconImage.arrowUp;
    if (value < 0.001) {
      formattedValue = '<0.1%';
    }
    message = 'Trending up compared to the last 6 months';
  } else if (value < 0) {
    icon = IconImage.arrowDown;
    if (value > -0.001) {
      formattedValue = '<0.1%';
    }
    message = 'Trending down compared to the last 6 months';
  } else {
    message = `No change compared to the last 6 months`;
    formattedValue = 'no change';
  }

  return (
    <Tooltip hasArrow placement="top" w="210px" label={message}>
      <Tag
        colorScheme={colorScheme}
        color={color}
        rounded="full"
        fontSize="xs"
        minH={5}
        px={1.5}
        {...rest}
      >
        {icon && <Icon boxSize={4} iconImage={icon} />}
        <TagLabel fontWeight="semibold" lineHeight={1}>
          {formattedValue}
        </TagLabel>
      </Tag>
    </Tooltip>
  );
}
