import { Box, Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { ReactComponent as CyborgNoConnection } from '../../../assets/images/cyborg-no-connection.svg';

interface ErrorDetailsProps {
  children: ReactNode;
}

function ErrorContainer({ children }: ErrorDetailsProps) {
  return (
    <Flex data-testid="ErrorContainer" alignItems="center">
      <Flex alignItems="center">
        <Box mr="44px" w="386px">
          {children}
        </Box>
        <Box>
          <CyborgNoConnection />
        </Box>
      </Flex>
    </Flex>
  );
}
export default ErrorContainer;
