/**
 * Check status code of an error response
 *
 * @param err error object
 * @param status status code
 */
export function isHttpError(err: any, status: number) {
  return err && err.response ? err.response.status === status : false;
}

/**
 * Error has a code field
 * @param err
 */
export function hasErrorCode(err: unknown): err is { code: string } {
  return !!err && !!(err as { code: string }).code;
}
