import { Box, Button, HStack, PinInput, PinInputField, useBoolean, VStack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../context/AuthenticationContext';
import Alert from '../../../core/Alert/Alert';
import FormLogoHeader from '../FormLogoHeader/FormLogoHeader';

export default function TwoFactorVerificationForm() {
  const { handleSubmit, register } = useForm();
  const navigate = useNavigate();
  const [error, setError] = useBoolean(false);
  const { sendMFACode } = useAuth();

  const onSubmit = async (data: any) => {
    const code = ['field1', 'field2', 'field3', 'field4', 'field5', 'field6']
      .map((field) => data[field] || '')
      .join('');
    try {
      setError.off();
      await sendMFACode(code);
      navigate('/', { replace: true });
    } catch (err) {
      setError.on();
    }
  };

  return (
    <>
      {error && (
        <Box pos="absolute" w="inherit" mt="-110px">
          <Alert
            status="error"
            title="There was a problem verifying your access."
            description="Please double check that your information is entered correctly and try again."
          />
        </Box>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormLogoHeader
          message="Enter your verification code"
          subMessage="Code is generated in the app you selected when enabling two-factor authentication."
        />
        <Box paddingX="170px" paddingBottom="40px">
          <VStack spacing={10}>
            <HStack>
              <PinInput autoFocus placeholder="" otp>
                <PinInputField {...register('field1')} />
                <PinInputField {...register('field2')} />
                <PinInputField {...register('field3')} />
                <PinInputField {...register('field4')} />
                <PinInputField {...register('field5')} />
                <PinInputField {...register('field6')} />
              </PinInput>
            </HStack>
            <Button size="lg" w="200px" type="submit">
              Verify
            </Button>
          </VStack>
        </Box>
      </form>
    </>
  );
}
