import { differenceInSeconds } from 'date-fns';
import { useEffect, useState } from 'react';
import { useSampleData } from '../../../lib/api-client/sources/SourceData';
import { TestIngestionData } from '../../../lib/api-client/sources/sources.model';

// eslint-disable-next-line import/prefer-default-export
export function useProcessTestIngestion(sourceId: string, sampleId: string) {
  const [finalSampleData, setFinalSampleData] = useState<ReturnType<typeof useSampleData>>();
  const [ingestionDetails, setIngestionDetails] = useState({
    isEnabled: true,
    initialLoadCompletedAt: -1,
    records: [] as TestIngestionData['records'],
  });

  const sampleData = useSampleData(sourceId, sampleId, 5000, ingestionDetails.isEnabled);

  useEffect(() => {
    if (
      ingestionDetails.initialLoadCompletedAt < 0 &&
      sampleData.data?.summary.status !== 'running'
    ) {
      setIngestionDetails((prev) => ({
        ...prev,
        initialLoadCompletedAt: Date.now(),
      }));
    }

    const isPollingFinished =
      ingestionDetails.initialLoadCompletedAt > 0 &&
      differenceInSeconds(Date.now(), ingestionDetails.initialLoadCompletedAt) > 130;
    const isFailed = sampleData.data?.summary.status === 'failed' || sampleData.error != null;

    if (ingestionDetails.isEnabled && (isPollingFinished || isFailed)) {
      setFinalSampleData(sampleData);
      setIngestionDetails((prev) => ({
        ...prev,
        isEnabled: false,
      }));
    }

    if (!isPollingFinished) {
      const existingRecordIds = ingestionDetails.records.map((record) => record.recordId);
      const recordsToAppend = (sampleData.data?.records ?? []).filter(
        (record) => !existingRecordIds.includes(record.recordId)
      );

      if (recordsToAppend.length > 0) {
        setIngestionDetails((prev) => ({
          ...prev,
          records: [...prev.records, ...recordsToAppend],
        }));
      }
    }
  }, [
    ingestionDetails.records,
    ingestionDetails.initialLoadCompletedAt,
    ingestionDetails.isEnabled,
    sampleData,
  ]);

  const dataToReturn = finalSampleData ?? sampleData;
  const isError = dataToReturn.error != null;
  return {
    isError,
    testData: {
      status: dataToReturn.data?.summary.status ?? 'running',
      records: ingestionDetails.records,
      warnings: dataToReturn.data?.warnings ?? [],
    },
    isComplete: !ingestionDetails.isEnabled || isError,
  };
}
