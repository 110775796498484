import { Tag, TagLabel, TagLeftIcon, Tooltip } from '@chakra-ui/react';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import { ISODateString } from '../../../../lib/model/common';
import { formatRelativeDateStringStrict } from '../../../../lib/utils/date-time-utils';

interface DataRefreshFluctuationChipProps {
  value: ISODateString;
}

export default function DataRefreshFluctuationChip({ value }: DataRefreshFluctuationChipProps) {
  return (
    <Tooltip
      hasArrow
      placement="top"
      label={`Last refreshed ${formatRelativeDateStringStrict(value)}`}
    >
      <Tag
        rounded="full"
        size="sm"
        color="white"
        position="relative"
        bgColor="white"
        px="1"
        py="1"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="8"
      >
        <TagLeftIcon mr={1}>
          <Icon color="gray.600" iconImage={IconImage.sync} />
        </TagLeftIcon>
        <TagLabel color="gray.600">{formatRelativeDateStringStrict(value)}</TagLabel>
      </Tag>
    </Tooltip>
  );
}
