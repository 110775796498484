import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import AuthenticationService from '../../../../../lib/services/AuthenticationService';
import Alert from '../../../../core/Alert/Alert';

interface VerifyCodeModalProps {
  enableCode?: boolean;
  onSuccess: (result: 'enabled' | 'disabled') => void;
  isOpen: boolean;
  onClose: () => void;
}

function VerifyCodeModal({ enableCode = true, onSuccess, isOpen, onClose }: VerifyCodeModalProps) {
  const { handleSubmit, register } = useForm();
  const [error, setError] = useBoolean(false);

  const onSubmit = async (data: any) => {
    try {
      setError.off();
      const code = ['field1', 'field2', 'field3', 'field4', 'field5', 'field6']
        .map((field) => data[field] || '')
        .join('');
      await AuthenticationService.verifySoftwareToken(code);
      if (enableCode) {
        await AuthenticationService.enableSoftwareTokenMfa();
      } else {
        await AuthenticationService.disableSoftwareTokenMfa();
      }
      onSuccess(enableCode ? 'enabled' : 'disabled');
      onClose();
    } catch (err) {
      setError.on();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)} data-testid="VerifyCodeModal">
          <ModalHeader>
            {enableCode ? 'Enable two-factor authentication' : 'Disable two-factor authentication'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb="8">
              {enableCode
                ? 'Enter the verification code generated by your authentication app of choice.'
                : 'Enter the verification code generated by your authentication app of choice to confirm that you would like to disable two-factor authentication.'}
            </Text>
            <HStack>
              <PinInput placeholder="" autoFocus>
                <PinInputField {...register('field1')} />
                <PinInputField {...register('field2')} />
                <PinInputField {...register('field3')} />
                <PinInputField {...register('field4')} />
                <PinInputField {...register('field5')} />
                <PinInputField {...register('field6')} />
              </PinInput>
            </HStack>
          </ModalBody>

          <ModalFooter justifyContent="left" mt="2">
            <Button colorScheme="blue" type="submit">
              {enableCode ? 'Verify' : 'Disable'}
            </Button>
            {error && (
              <Alert status="error" ml={6} description="There was a problem verifying your code." />
            )}
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export default VerifyCodeModal;
