import { Box, chakra, FormControl, FormLabel, HStack, Switch, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Can } from '../../../../context/AuthorizationContext';
import { formatNumber } from '../../../../lib/utils/number-utils';
import Icon from '../../../core/Icon/Icon';
import { IconImage } from '../../../core/Icon/IconConfig';
import { useDataExplorer } from './DataExplorerContext';

interface DataExplorerTableHeaderProps {
  hideEditMode: boolean;
  icon?: {
    label: string;
    icon?: IconImage;
  };
}
export default function DataExplorerTableHeader({
  hideEditMode,
  icon = { label: 'data records' },
}: DataExplorerTableHeaderProps) {
  const { table, setEditMode: setSwitchMode, editMode } = useDataExplorer();
  const totalItems = (table.options.meta as any).totalItems ?? 0;
  const [isEditMode, setEditMode] = useState(false);
  useEffect(() => {
    (table.options.meta as any).isEditMode = isEditMode;
    table.options.enableRowSelection = !isEditMode;
  }, [isEditMode, table.options]);

  return (
    <>
      <HStack py={2} justify="space-between" w="full">
        <HStack>
          {icon?.icon && <Icon boxSize={5} iconImage={icon.icon} color="action" />}
          <chakra.span fontSize="sm" fontWeight="semibold">
            {formatNumber(totalItems)} {icon.label}
          </chakra.span>
        </HStack>
        {!hideEditMode && (
          <Box>
            <Can I="update" an="SourceDataRecord">
              <FormControl>
                <HStack fontSize="sm">
                  <FormLabel fontWeight="normal" m={0}>
                    Flag edited fields
                  </FormLabel>
                  <Switch
                    colorScheme="successScheme"
                    onChange={() => {
                      setEditMode(!isEditMode);
                      setSwitchMode(!editMode);
                    }}
                  />
                </HStack>
              </FormControl>
            </Can>
          </Box>
        )}
      </HStack>
      {isEditMode && (
        <HStack mb={4} py={3} px={4} backgroundColor="success" color="white" rounded="lg">
          <Icon iconImage={IconImage.edit} />
          <Text>
            When edit mode is turned on, click on a row in the table below to edit a data record.
          </Text>
        </HStack>
      )}
    </>
  );
}
