import { Box, Button, Flex, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { Link, To } from 'react-router-dom';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import { useCurrentDataSource } from '../../sources/context/CurrentDataSourceContext';
import CompletenessCheckmark from '../shared/CompletenessCheckmark/CompletenessCheckmark';
import { useTestIngestionResult } from './TestIngestionResultContext';

interface DataMappingSetupSectionProps {
  completeLabel?: string;
  isDisabled: boolean;
}

export default function DataMappingSetupSection({
  completeLabel = '',
  isDisabled: isDisabledProp,
}: DataMappingSetupSectionProps) {
  const { dataSource: source } = useCurrentDataSource();
  const { testIngestionResult } = useTestIngestionResult();

  const isLoading = testIngestionResult.isTestIngestionRunning;
  const isComplete = source.attributeMappings.length > 0;
  const isDisabled = isDisabledProp || isLoading || testIngestionResult.isTestIngestionFailure;

  const to: To = { pathname: isDisabled ? '' : `/sources/${source.id}/mappings` };

  if (testIngestionResult.sampleId) {
    to.search = `?sampleId=${testIngestionResult.sampleId}`;
  }

  return (
    <SectionContainer
      variant="box"
      title="Data mappings"
      header={<CompletenessCheckmark label={completeLabel} isComplete={isComplete} />}
      display="flex"
      flexDirection="column"
    >
      <Flex h="full" flexDirection="column" justifyContent="space-between">
        <Stack alignItems="start" mb={4}>
          <Text>
            Map source attributes from this source system to harpin {`AI's`} standard data fields
            and customize data normalizations as needed.
          </Text>
        </Stack>
        <Box>
          <Button
            variant="solid"
            as={Link}
            to={to}
            state={{ returnTo: 'connectionDetailsPage' }}
            isDisabled={isDisabled || isLoading}
            loadingText="Processing data..."
            isLoading={isLoading}
          >
            Configure data mappings
          </Button>
        </Box>
      </Flex>
    </SectionContainer>
  );
}
