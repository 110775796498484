import { VStack, Text, Button, HStack } from '@chakra-ui/react';
import { Link, To } from 'react-router-dom';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';

interface ProfileDataReportCardProps {
  label: string;
  iconImage: IconImage;
  content: string;
  toDefaultReport: To;
  toCustomizeReport: To;
}

export default function ProfileDataReportCard({
  label,
  iconImage,
  content,
  toDefaultReport,
  toCustomizeReport,
}: ProfileDataReportCardProps) {
  return (
    <SectionContainer variant="box" py="4" px="4">
      <VStack spacing={3} w="full">
        <Icon boxSize="8" iconImage={iconImage} />
        <Text fontSize="sm" fontWeight="700">
          {label}
        </Text>
        <VStack spacing={0}>
          <Text fontSize="sm" fontWeight="400" textAlign="center">
            {content}
          </Text>
        </VStack>
        <HStack mt={2} w="full">
          <Button as={Link} to={toDefaultReport}>
            See default report
          </Button>
          <Button as={Link} to={toCustomizeReport}>
            Customize report
          </Button>
        </HStack>
      </VStack>
    </SectionContainer>
  );
}
