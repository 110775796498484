import ApiClient from '../ApiClient';

const ReportExecutionClient = {
  async exportReportData(reportId: string) {
    return ApiClient.get(`/report-executions/${reportId}`, {
      headers: {
        accept: 'text/csv',
      },
      responseType: 'blob',
    }).then((res) => res.data);
  },
};

export default ReportExecutionClient;
