import { Nullable } from '../../model/common';
import { isDefined } from '../../utils/utils';
import {
  AttributeDomainType,
  AttributeMetadataWithNormalization,
  DataModel,
  PrimaryDomainType,
} from './data-model.model';

export function mapPrimaryDomainToAttributeDomain(
  dataModel: DataModel
): Record<PrimaryDomainType, AttributeDomainType[]> {
  return dataModel.domains.reduce((prev, current) => {
    const updated: Record<PrimaryDomainType, AttributeDomainType[]> = { ...prev };
    const primaryDomain: Nullable<PrimaryDomainType> = current.primaryDomain
      ? (current.id as PrimaryDomainType)
      : current.parentDomain;

    if (primaryDomain) {
      if (primaryDomain in prev) {
        updated[primaryDomain].push(current.id);
      } else if (current.id === 'IDENTITY') {
        updated[primaryDomain] = [current.id];
      } else {
        updated[primaryDomain] = ['IDENTITY', current.id];
      }
    }

    return updated;
  }, {} as Record<PrimaryDomainType, AttributeDomainType[]>);
}

/**
 *
 * @param dataModel
 * @param domainTypes
 */
export function filterAttributeDomainsFromDataModel(
  dataModel: DataModel,
  ...domainTypes: AttributeDomainType[]
): DataModel {
  return {
    ...dataModel,
    domains: dataModel.domains.filter((domain) => domainTypes && domainTypes.includes(domain.id)),
  };
}

export function filterPrimaryDomainFromDataModel(
  dataModel: DataModel,
  primaryDomain: PrimaryDomainType
): DataModel {
  const attributeDomains = mapPrimaryDomainToAttributeDomain(dataModel)[primaryDomain];
  return filterAttributeDomainsFromDataModel(dataModel, ...attributeDomains);
}

export function getAttributeMetadataFromDataModel(
  dataModel: DataModel
): AttributeMetadataWithNormalization[] {
  return dataModel.domains
    .map((domain) =>
      domain.attributes
        .map((attribute) => ({
          ...attribute,
          domain: domain.id,
          normalizationMetadata:
            attribute.normalizations
              ?.map(
                (attrNormalization) =>
                  dataModel.normalizations.find((n) => n.id === attrNormalization)!
              )
              .filter(isDefined) ?? undefined,
        }))
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
    )
    .reduce(
      (previous, current) => [...previous, ...current],
      [] as AttributeMetadataWithNormalization[]
    );
}
