import { Text } from '@chakra-ui/react';
import SectionContainer from '../../../../../components/core/SectionContainer/SectionContainer';
import { Can } from '../../../../../context/AuthorizationContext';
import ManageSourceConfigAttributesButton from './ManageSourceConfigAttributesButton';

export default function ManageSourceConfigAttributesSection() {
  return (
    <Can I="update" a="DataSource">
      <SectionContainer title="Connection configuration" variant="box" titleFontSize="sm">
        <Text fontSize="sm" mb={8}>
          This connects harpin AI to the data source for seamless data ingestion and processing.
        </Text>
        <ManageSourceConfigAttributesButton />
      </SectionContainer>
    </Can>
  );
}
