import { Button, Flex, HStack } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';
import { Can } from '../../../../context/AuthorizationContext';
import { useCurrentDataSource } from '../../../sources/context/CurrentDataSourceContext';
import EnableIngestionButton from './EnableIngestionButton';

function IngestionStatusSection() {
  const { dataSource, sourceSystemType } = useCurrentDataSource();
  const { id: sourceId, sourceSystem } = dataSource;
  const enableTestIngestion = sourceSystem !== 'customIntegration';

  return (
    <Can I="update" a="DataSource">
      <SectionContainer
        title="Ingestion status"
        variant="box"
        data-testid="IngestionStatusSection"
        w="full"
        titleFontSize="sm"
        display="flex"
        flexDirection="column"
        minH="0"
      >
        <Flex minH="0" h="full" direction="column" justify="space-between">
          <EnableIngestionButton
            sourceId={sourceId}
            status={dataSource.enabled ? 'enabled' : 'disabled'}
          />

          <HStack mt="3">
            {!dataSource.enabled && enableTestIngestion && (
              <Button w="full" as={Link} to={`/sources/${sourceId}/test-ingestion`}>
                Run test ingestion
              </Button>
            )}
            {sourceSystemType.supportedFeatures.webhooks && (
              <Button w="full" as={Link} to={`/sources/${sourceId}/web-hooks`}>
                Manage webhooks
              </Button>
            )}
          </HStack>
        </Flex>
      </SectionContainer>
    </Can>
  );
}
export default IngestionStatusSection;
