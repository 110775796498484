import React from 'react';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import ConsumerProfileExplorer from '../components/ConsumerProfileExplorer/ConsumerProfileExplorer';

export default function ConsumerProfileExplorerPage() {
  return (
    <PageLayout
      pageViewEvent={{ page: 'Consumer Profile Explorer Page' }}
      header={
        <Header
          title="Explore all consumer profiles"
          icon={<Icon iconImage={IconImage.searchData} />}
        />
      }
      w="full"
      h="full"
      display="flex"
      flexFlow="column"
    >
      <ConsumerProfileExplorer />
    </PageLayout>
  );
}
