import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import ErrorContainer from '../../../components/core/ErrorContainer/ErrorContainer';

function NotFoundPage() {
  return (
    <Flex data-testid="NotFoundPage" justifyContent="center" alignItems="center" height="100vh">
      <ErrorContainer>
        <Box>
          <Text fontSize="3xl" lineHeight="9" fontWeight="bold">
            Something went wrong
          </Text>
          <Text mt="2" fontSize="md" lineHeight="6">
            The page you are looking for can’t be found.
          </Text>
          <Button mt="6" as={Link} to="/" replace>
            Back to dashboard
          </Button>
        </Box>
      </ErrorContainer>
    </Flex>
  );
}
export default NotFoundPage;
