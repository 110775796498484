import { Button, HStack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import { formatNumber } from '../../../lib/utils/number-utils';

function SuccessIcon({ success }: { success: boolean }) {
  if (!success) {
    return <Icon iconImage={IconImage.unsuccessful} color="error" />;
  }

  return <Icon iconImage={IconImage.success} color="success" />;
}

interface RecentIngestionErrorsProps {
  sourceId: string;
  errorCount: number;
}
export default function RecentIngestionErrors({
  errorCount,
  sourceId,
}: RecentIngestionErrorsProps) {
  const isError = errorCount > 0;

  let borders = {};
  if (isError) {
    borders = {
      borderWidth: '2px',
      borderColor: 'error',
    };
  }
  return (
    <SectionContainer variant="box" {...borders}>
      <HStack justifyContent="space-between">
        <HStack>
          <SuccessIcon success={!isError} />
          <Text>{formatNumber(errorCount)} ingestion errors detected in the last 7 days</Text>
        </HStack>
        <Button
          fontWeight="semibold"
          textColor={isError ? 'error' : 'action'}
          as={Link}
          to={`/sources/${sourceId}/ingest-error`}
          variant="link"
        >
          Review error log
        </Button>
      </HStack>
    </SectionContainer>
  );
}
