import {
  Button,
  ButtonGroup,
  chakra,
  Flex,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useBoolean,
  useClipboard,
} from '@chakra-ui/react';
import { ReactNode, useEffect, useState } from 'react';
import { useToast } from '../../../../hooks/use-toast';
import Mixpanel from '../../../../lib/mixpanel/Mixpanel';
import { formatNumber } from '../../../../lib/utils/number-utils';
import { isUndefined } from '../../../../lib/utils/utils';
import Icon from '../../../core/Icon/Icon';
import { IconImage } from '../../../core/Icon/IconConfig';
import { useDataExplorer } from './DataExplorerContext';

interface DataExplorerTableFooterProps {
  shareUrl: string;
  onExport?: () => void;
  actions?: ReactNode;
}

function trackShareURL(properties: { Data: any }) {
  Mixpanel.track('Share View', properties);
}
export default function DataExplorerTableFooter({
  shareUrl,
  onExport,
  actions,
}: DataExplorerTableFooterProps) {
  const { table } = useDataExplorer();
  const totalItems = (table.options.meta as any).totalItems ?? 0;
  const { pagination } = table.getState();
  const { onCopy, setValue } = useClipboard(shareUrl);
  const toast = useToast();
  const pageStart = formatNumber(
    totalItems === 0 ? 0 : pagination.pageIndex * pagination.pageSize + 1
  );
  const pageEnd = formatNumber(
    Math.min((pagination.pageIndex + 1) * pagination.pageSize, totalItems)
  );
  const [inputPageNumber, setInputPageNumber] = useState<number | undefined>(
    pagination.pageIndex + 1
  );
  const [isInputPageChanged, setIsInputPageChanged] = useBoolean();
  const [isExporting, setIsExporting] = useBoolean();

  useEffect(() => {
    setValue(shareUrl);
    setInputPageNumber(pagination.pageIndex + 1);
    if (inputPageNumber && inputPageNumber > table.getPageCount() && !(table.getPageCount() < 1)) {
      table.setPageIndex(table.getPageCount());
      setInputPageNumber(table.getPageCount());
    }
  }, [shareUrl, setValue, pagination.pageIndex, inputPageNumber, table]);

  const { readonlyPagination } = table.options.meta as any;

  return (
    <Stack
      w="full"
      zIndex={2}
      boxShadow="0px -1px 3px rgba(0, 0, 0, 0.1), 0px -1px 2px rgba(0, 0, 0, 0.06)"
      spacing={0}
    >
      {actions}
      <Flex w="full" justify="space-between" alignItems="center">
        <HStack
          flexGrow="1"
          flexBasis="0"
          ps={3}
          fontSize="xs"
          fontWeight="semibold"
          color="gray.600"
        >
          <chakra.span>
            Showing {pageStart} - {pageEnd} {totalItems ? `of ${formatNumber(totalItems)}` : ''}
          </chakra.span>
        </HStack>
        <ButtonGroup variant="ghost">
          {!readonlyPagination && (
            <IconButton
              aria-label="Go to page 1"
              icon={<Icon iconImage={IconImage.chevronBackBookend} />}
              disabled={!table.getCanPreviousPage()}
              onClick={() => table.setPageIndex(0)}
            />
          )}
          <IconButton
            aria-label="Go to previous page"
            icon={<Icon iconImage={IconImage.chevronBack} />}
            disabled={!table.getCanPreviousPage()}
            onClick={table.previousPage}
          />
          <HStack fontSize="xs" fontWeight="semibold" spacing={1}>
            <chakra.span>Page</chakra.span>
            {readonlyPagination ? (
              <chakra.span>{inputPageNumber}</chakra.span>
            ) : (
              <InputGroup>
                <Input
                  size="sm"
                  minW={10}
                  h={6}
                  value={inputPageNumber}
                  type="number"
                  min={1}
                  max={table.getPageCount()}
                  onChange={(e) => {
                    const isNumber = !Number.isNaN(e.target.valueAsNumber);
                    if (isNumber) {
                      if (
                        !isUndefined(table.getPageCount()) &&
                        e.target.valueAsNumber <= table.getPageCount()
                      ) {
                        setInputPageNumber(e.target.valueAsNumber);
                      } else {
                        setInputPageNumber(table.getPageCount());
                      }

                      if (pagination.pageIndex + 1 !== e.target.valueAsNumber) {
                        setIsInputPageChanged.on();
                      } else {
                        setIsInputPageChanged.off();
                      }
                    } else {
                      setInputPageNumber(undefined);
                    }
                  }}
                />
                {isInputPageChanged && (
                  <InputRightElement h={6}>
                    <Button
                      size="xs"
                      variant="ghost"
                      onClick={() =>
                        !isUndefined(inputPageNumber) && table.setPageIndex(inputPageNumber - 1)
                      }
                    >
                      Go
                    </Button>
                  </InputRightElement>
                )}
              </InputGroup>
            )}
            <Text whiteSpace="nowrap">of {formatNumber(table.getPageCount())}</Text>
          </HStack>
          <IconButton
            aria-label="Go to next page"
            icon={<Icon iconImage={IconImage.chevronForward} />}
            disabled={!table.getCanNextPage()}
            onClick={table.nextPage}
          />
          {!readonlyPagination && (
            <IconButton
              aria-label="Go to last page"
              icon={<Icon iconImage={IconImage.chevronForwardBookend} />}
              disabled={!table.getCanNextPage()}
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            />
          )}
        </ButtonGroup>
        <ButtonGroup
          variant="ghost"
          justifyContent="end"
          flexGrow="1"
          flexBasis="0"
          spacing={3}
          pe={3}
          py={1}
        >
          {onExport != null && (
            <Button
              fontSize="xs"
              leftIcon={<Icon iconImage={IconImage.download} boxSize={4} />}
              isLoading={isExporting}
              loadingText="Exporting..."
              onClick={() => {
                if (onExport) {
                  setIsExporting.on();
                  try {
                    onExport();
                  } finally {
                    setIsExporting.off();
                  }
                }
              }}
            >
              Export CSV
            </Button>
          )}
          <Button
            fontSize="xs"
            leftIcon={<Icon iconImage={IconImage.copyLink} boxSize={4} />}
            onClick={() => {
              onCopy();
              trackShareURL({ Data: shareUrl });
              toast({
                title: 'URL has been copied.',
                status: 'info',
              });
            }}
          >
            Share view
          </Button>
        </ButtonGroup>
      </Flex>
    </Stack>
  );
}
