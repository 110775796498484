import { HStack, Text } from '@chakra-ui/react';
import React from 'react';
import Icon from '../../../../components/core/Icon/Icon';
import { IconImage } from '../../../../components/core/Icon/IconConfig';

export default function CompletenessCheckmark({
  isComplete,
  label = '',
}: {
  isComplete: boolean;
  label?: string;
}) {
  return isComplete ? (
    <HStack mb={4}>
      <Icon
        color="white"
        backgroundColor="success"
        borderRadius="50%"
        borderColor="success"
        borderWidth="thick"
        boxSize="26px"
        iconImage={IconImage.checkmark}
      />
      <Text color="gray.600" fontWeight="semibold">
        {label}Complete
      </Text>
    </HStack>
  ) : (
    <HStack mb={4}>
      <Icon
        color="white"
        backgroundColor="gray.600"
        borderRadius="50%"
        borderColor="gray.600"
        borderWidth="thick"
        boxSize="26px"
        iconImage={IconImage.cancel}
      />
      <Text color="gray.600" fontWeight="semibold">
        {label}Incomplete
      </Text>
    </HStack>
  );
}
