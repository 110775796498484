import { Box, Button, chakra, Text, Tooltip, Tag, TagLabel } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { formatNumber } from '../../../lib/utils/number-utils';
import Icon from '../Icon/Icon';
import { IconImage } from '../Icon/IconConfig';

export default function MetricCard({
  value,
  label,
  tooltip,
  button,
  id,
  badge,
  badgeValue,
  label2,
}: {
  value: number;
  label: string;
  tooltip?: boolean;
  button?: boolean;
  id?: string;
  badgeValue?: number;
  badge?: boolean;
  label2?: string;
}) {
  return (
    <Box>
      <Text fontSize="2xl" fontWeight="bold">
        {formatNumber(value)}
      </Text>
      <Text>
        {label}{' '}
        {tooltip && (
          <Tooltip
            label="Data record events include new data records or updates to existing data records.  "
            placement="top"
          >
            <chakra.span>
              <Icon iconImage={IconImage.error} color="actionDark" boxSize="4" />
            </chakra.span>
          </Tooltip>
        )}
      </Text>
      {button && (
        <Button
          variant="outline"
          pl={2}
          pr={2}
          w="186px"
          mt={4}
          fontSize="13"
          as={Link}
          to={`/sources/${id}/ingest-error`}
        >
          {' '}
          Review ingestion error log
        </Button>
      )}
      {badge && (
        <Tag rounded="full" fontSize="xs" minH={5} px={3} py={1.5} mt={4} bgColor="gray.200">
          <TagLabel fontWeight="bold" lineHeight={1}>
            {formatNumber(badgeValue ?? 0)}{' '}
            <chakra.span fontWeight="400">in the {label2}</chakra.span>
          </TagLabel>
        </Tag>
      )}
    </Box>
  );
}
