import { Heading, HStack, Stack } from '@chakra-ui/react';
import { IconImage } from '../../../../components/core/Icon/IconConfig';
import DataSourceIcon from '../../../../components/features/connections/DataSourceIcon/DataSourceIcon';
import { useCurrentDataSource } from '../../../sources/context/CurrentDataSourceContext';
import ConnectedSinceTag from './ConnectedSinceTag';
import ConnectionConfigChip, {
  DomainTypeConnectionConfigChip,
  SyncDirectionConnectionConfigChip,
} from './ConnectionConfigChip';
import EditSourceButton from './EditSourceButton';

export default function SourceDetails() {
  const { dataSource, refetchDataSource } = useCurrentDataSource();
  return (
    <Stack direction="row" justify="space-between">
      <HStack>
        <DataSourceIcon sourceSystem={dataSource.sourceSystem} boxSize={12} />
        <Stack>
          <Heading as="h3" fontSize="xl" fontWeight="bold">
            {dataSource.name}
          </Heading>
          <HStack>
            <ConnectedSinceTag connectedSince={dataSource.createdAt} />
            <ConnectionConfigChip label={dataSource.category} icon={IconImage.database} />
            <DomainTypeConnectionConfigChip domainType={dataSource.domainType} />
            <SyncDirectionConnectionConfigChip syncDirection={dataSource.syncDirection} />
          </HStack>
        </Stack>
      </HStack>
      <EditSourceButton source={dataSource} onSuccess={() => refetchDataSource()} />
    </Stack>
  );
}
