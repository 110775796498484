import { Box, BoxProps, Flex, Spinner } from '@chakra-ui/react';
import { ReactNode, useEffect } from 'react';
import Mixpanel from '../../../../lib/mixpanel/Mixpanel';
import SystemErrorDetails from '../../errors/SystemErrorDetails/SystemErrorDetails';

interface PageLayoutProps extends BoxProps {
  header?: JSX.Element;
  pageViewEvent?: { page: string; pageId?: string };
  children: ReactNode;
  loading?: boolean;
  initializationError?: boolean;
  'data-testid'?: string;
}

/*
 * Opting to create a layout component with common padding and other
 * characteristics.
 *
 * Sources:
 * https://github.com/chakra-ui/chakra-ui/issues/2456
 */
function PageLayout({
  header,
  pageViewEvent,
  children,
  loading,
  initializationError = false,
  'data-testid': dataTestId = 'PageLayout',
  ...rest
}: PageLayoutProps) {
  useEffect(() => {
    if (pageViewEvent) {
      const trackPageView = () => {
        Mixpanel.track('Page View', {
          page_name: pageViewEvent?.page,
          identifier: pageViewEvent?.pageId,
        });
      };
      trackPageView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let content = (
    <Box data-testid={dataTestId} p="pagePadding" w="pageWidth" {...rest}>
      {children}
    </Box>
  );

  if (loading) {
    content = (
      <Flex
        w="full"
        flex="1"
        justifyContent="center"
        alignItems="center"
        data-testid="PageLayout_loading"
      >
        <Spinner
          mt="180px"
          thickness="4px"
          size="xl"
          color="action"
          emptyColor="gray.200"
          speed="0.65s"
        />
      </Flex>
    );
  }
  if (initializationError) {
    content = (
      <Flex h="full" justifyContent="center" alignItems="stretch">
        <SystemErrorDetails />
      </Flex>
    );
  }

  return (
    <>
      <Box position="sticky" top={0} zIndex="10">
        {header}
      </Box>
      {content}
    </>
  );
}
export default PageLayout;
