import { Flex, Text } from '@chakra-ui/react';
import SectionContainer from '../../../../../components/core/SectionContainer/SectionContainer';
import ManageSalesforceConfigurationButton from './ManageSalesforceConfigurationButton';

export default function ManageSalesforceConfigurationSection() {
  return (
    <SectionContainer
      title="Connection configuration"
      variant="box"
      w="full"
      titleFontSize="sm"
      display="flex"
      flexDirection="column"
      minH="0"
    >
      <Flex minH="0" h="full" direction="column" justify="space-between">
        <Text mb={8}>
          This connects harpin AI to the data source for seamless data ingestion and processing.
        </Text>
        <ManageSalesforceConfigurationButton />
      </Flex>
    </SectionContainer>
  );
}
