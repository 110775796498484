import { Tag, TagLabel, TagProps } from '@chakra-ui/react';
import { SourceIssuePriority } from '../../../lib/api-client/sources/model/SourceIssuePriority';

const colors: any = {
  high: {
    bgColor: 'error',
    color: 'white',
  },
  medium: {
    bgColor: 'warning',
    color: 'white',
  },
};
export default function SourceIssuePriorityTag({
  value,
}: { value: SourceIssuePriority } & TagProps) {
  return (
    <Tag
      rounded="full"
      fontWeight="semibold"
      fontSize="xs"
      w={16}
      minH={5}
      justifyContent="center"
      {...colors[value]}
    >
      <TagLabel textTransform="capitalize" lineHeight={1}>
        {value}
      </TagLabel>
    </Tag>
  );
}
