import { Button, Flex } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';
import { useCurrentDataSource } from '../../../sources/context/CurrentDataSourceContext';
import ExportStatusButton from './ExportStatusButton';

export default function ExportStatusSection() {
  const { dataSource: source, exportStatus } = useCurrentDataSource();

  let border;
  if (exportStatus?.exportState === 'DEGRADED') {
    border = { border: '1px solid', color: 'warning' };
  } else if (exportStatus?.exportState === 'FAILED') {
    border = { border: '1px solid', color: 'error' };
  }

  return (
    <SectionContainer
      title="Publish status"
      variant="box"
      w="full"
      titleFontSize="sm"
      display="flex"
      flexDirection="column"
      minH="0"
      border={border?.border}
      borderColor={border?.color}
    >
      <Flex minH="0" h="full" direction="column" justify="space-between">
        <ExportStatusButton status={source.properties?.exportMode} sourceId={source.id} />
        <Button mt={3} w="full" as={Link} to={`/sources/${source.id}/merge-rules`}>
          Manage merge rules
        </Button>
      </Flex>
    </SectionContainer>
  );
}
