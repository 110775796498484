/* eslint-disable react/no-array-index-key */
import {
  Box,
  Flex,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import consola from 'consola';
import { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useLocation, useParams } from 'react-router-dom';
import Alert from '../../../components/core/Alert/Alert';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import PurchaseDetailsCard from '../../../components/core/PurchaseDetails/PurchaseDetailsCard';
import ProfileLocation from '../../../components/features/profile/ProfileLocation/ProfileLocation';
import ProfilePageActivityAllTrackerData from '../../../components/features/profile/ProfilePageActivityAllTrackerData/ProfilePageActivityAllTrackerData';
import ProfilePageTransactionInsights from '../../../components/features/profile/ProfilePageInteractionInsights/ProfilePageInteractionSights';
import ProfilePageLifetimeValue from '../../../components/features/profile/ProfilePageLifetimeValue/ProfilePageLifetimeValue';
import PartialErrorDetails from '../../../components/shared/errors/PartialErrorDetails/PartialErrorDetails';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import { useInfiniteScroll } from '../../../context/InfiniteScrollContext';
import CustomerValueClient from '../../../lib/api-client/customer-value/CustomerValueClient';
import { CustomerValue } from '../../../lib/api-client/customer-value/model/CustomerValue';
import IdentityClient from '../../../lib/api-client/identity/IdentityClient';
import { Identity } from '../../../lib/api-client/identity/model/Identity';
import ProfileClient from '../../../lib/api-client/profile/ProfileClient';
import { Profile } from '../../../lib/api-client/profile/model/Profile';
import { Transaction } from '../../../lib/api-client/profile/model/Transaction';
import { ProfilePageLinkState } from '../ProfilePage/ProfilePage';
import ProfileFeedbackButton from '../components/ProfileFeedbackButton/ProfileFeedbackButton';
import ProfileHeader from '../components/ProfileHeader/ProfileHeader';
import ProfilePageLoyaltyData from '../components/ProfilePageLoyaltyData/ProfilePageLoyaltyData';
import ProfilePageLoyaltyDataIcon from '../components/ProfilePageLoyaltyData/ProfilePageLoyaltyDataIcon';

interface DataType {
  selectedTabIndex: number;
  keyValue?: string;
}

function ProfilePageActivityAllPurchaseData() {
  const { id } = useParams() as { id: string };
  const location = useLocation();
  const state = location.state as ProfilePageLinkState;
  const [identity, setIdentity] = useState<Identity | undefined>(state?.identity);
  const [isLoading, setLoading] = useBoolean(true);
  const [isFetching, setFetching] = useState(false);
  const [initializationError, setInitializationError] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [totalEventElements, setTotalEventElements] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const tabIndex = location?.state as DataType;
  const [keyValue, setkeyValue] = useState(tabIndex?.keyValue);
  const { scrollRef } = useInfiniteScroll();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [customerValue, setCustomerValue] = useState<CustomerValue>();
  const [profile, setProfile] = useState<Profile>();
  const [activeTab, setActiveTab] = useState(tabIndex?.selectedTabIndex);

  useEffect(() => {
    IdentityClient.getIdentityByPin(id)
      .then(setIdentity)
      .catch(() => setInitializationError(true))
      .finally(setLoading.off);
  }, [id, setLoading.off]);

  const fetchTxs = useCallback(async () => {
    if ((isFetching && hasMore) || error || initializationError) {
      return;
    }
    setFetching(true);
    const getCustomerValueByPin = CustomerValueClient.getCustomerValueByPin(id);
    try {
      const result = await ProfileClient.getTransactionsByPin(
        id,
        ['startTimestamp,desc'],
        page,
        10
      );
      const getProfileByPin = ProfileClient.getProfileByPin(id).then((p) => {
        if (!p) {
          return p;
        }
        return { ...p };
      });
      setTransactions((txs) => txs.concat(result.content));
      setTotalElements(result.totalElements);
      setPage(page + 1);
      setHasMore(!result.last);
      setCustomerValue(await getCustomerValueByPin);
      setProfile(await getProfileByPin);
    } catch (err) {
      if (page === 0) {
        setInitializationError(true);
      } else {
        setError(true);
      }
    } finally {
      setFetching(false);
    }
  }, [isFetching, hasMore, error, initializationError, id, page]);

  // eslint-disable-next-line consistent-return
  const handleClick = async (key?: string | any) => {
    setkeyValue(key);
    try {
      const getEventsByPin = await ProfileClient.getEventsByPin(id, key);
      const { totalElements: totalElementsValue } = getEventsByPin;
      return totalElementsValue;
    } catch (err) {
      consola.error(err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const totalElementsValue = await handleClick();
      setTotalEventElements(totalElementsValue as number);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const profileTabData = profile && Object.keys(profile.eventTypeCounts);
  const totalRecords = totalEventElements + totalElements;
  const showLoyalty =
    identity?.sources?.find((s) => s.source.domainType === 'LOYALTY_PROGRAM') != null;

  let loyaltyProgram;
  if (profile && profile.loyaltyPrograms.length > 0) {
    [loyaltyProgram] = profile.loyaltyPrograms;
  }

  const handleTabChange = (index: any) => {
    setActiveTab(index);
  };

  const content = (
    <InfiniteScroll
      loadMore={fetchTxs}
      hasMore={hasMore}
      getScrollParent={() => scrollRef}
      useWindow={false}
    >
      {transactions.length < 1 && !isFetching && (
        <Alert
          status="warning"
          variant="subtle"
          description="There is no purchase data on record for this consumer."
        />
      )}
      <HStack alignItems="start" spacing="6">
        <VStack spacing="5" alignItems="start" width="710px">
          {transactions.length > 0 &&
            transactions.map((tx, index) => (
              <PurchaseDetailsCard
                key={index}
                amount={tx.amount}
                currency={tx.currency}
                description={tx.description}
                locationName={tx.locationName}
                sourceId={tx.sourceId}
                sourceRecordId={tx.sourceRecordId}
                startTimestamp={tx.startTimestamp}
                orderId={tx.orderId}
                subtotal={tx.subtotal}
                taxes={tx.taxes}
                discounts={tx.discounts}
                gratuity={tx.gratuity}
                otherFees={tx.otherFees}
                shippingFees={tx.shippingFees}
                paymentType={tx.paymentType}
                reward={tx.reward}
                recordId={tx.recordId}
              />
            ))}
        </VStack>
        <VStack alignItems="start" spacing={4}>
          <Box>
            {customerValue && profile && (
              <ProfilePageLifetimeValue customerValue={customerValue} profile={profile} />
            )}
          </Box>
          {profile && <ProfilePageTransactionInsights profile={profile} />}

          {profile && profile?.transactionLocations.length > 0 && (
            <ProfileLocation profileLocationList={profile} />
          )}
        </VStack>
      </HStack>
      {error && <PartialErrorDetails />}
    </InfiniteScroll>
  );

  return (
    <PageLayout
      pageViewEvent={{ page: 'Profile Page Activity All Purchase Data', pageId: id }}
      header={
        <Header
          icon={<Icon iconImage={IconImage.searchUser} />}
          title="All activity"
          back={{
            label: 'Back to consumer profile',
            to: `/profiles/${id}`,
          }}
        />
      }
      initializationError={initializationError}
      loading={isLoading}
    >
      {identity && (
        <HStack
          data-testid="ProfilePageActivityAllPurchaseData"
          pb={6}
          alignItems="start"
          justifyContent="space-between"
        >
          <ProfileHeader identity={identity} />
          <ProfileFeedbackButton pin={identity.pin} />
        </HStack>
      )}
      <Tabs
        variant="line"
        colorScheme="#005874"
        mb={6}
        onChange={handleTabChange}
        index={profileTabData ? activeTab : 0}
      >
        <TabList>
          <HStack w="full" justifyContent="space-between">
            <Flex>
              <Tab>Transactions ({profile?.transactionCount ?? 0})</Tab>
              {profileTabData?.map((key, index) => (
                <Tab key={index} onClick={() => handleClick(key)}>
                  {key} ({profile?.eventTypeCounts[key] ?? 0})
                </Tab>
              ))}
              {showLoyalty && (
                <Tab>
                  Loyalty
                  <ProfilePageLoyaltyDataIcon loyaltyProgram={loyaltyProgram} boxSize={4} ml={1} />
                </Tab>
              )}
            </Flex>
            <Text fontSize="xs" fontWeight="semibold" color="gray.600">
              {totalRecords} total on record
            </Text>
          </HStack>
        </TabList>
        <TabPanels pt={6}>
          <TabPanel p={0}>{content}</TabPanel>
          {profileTabData?.map((key) => (
            <TabPanel p={0} key={key}>
              <HStack alignItems="start" spacing={4}>
                <ProfilePageActivityAllTrackerData keyvalue={keyValue || tabIndex?.keyValue} />
              </HStack>
            </TabPanel>
          ))}
          {showLoyalty && profile && (
            <TabPanel p={0}>
              {profile.loyaltyPrograms.map((loyaltyProgramItem, index) => (
                <ProfilePageLoyaltyData key={index} loyaltyProgram={loyaltyProgramItem} />
              ))}
              {profile && profile.loyaltyPrograms.length === 0 && (
                <HStack
                  w="700px"
                  border="1px solid"
                  borderRadius="6px"
                  borderColor="gray.100"
                  p={3}
                >
                  <Icon iconImage={IconImage.unsuccessful} color="error" />
                  <Text>No loyalty membership on record</Text>
                </HStack>
              )}
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </PageLayout>
  );
}
export default ProfilePageActivityAllPurchaseData;
