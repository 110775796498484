import ApiClient from '../ApiClient';

const IssueReportExecutionClient = {
  async exportIssueReportData(sourceId: string, reportId: string) {
    return ApiClient.get(`/source-issues/${sourceId}/reports/${reportId}`, {
      headers: {
        accept: 'text/csv',
      },
      responseType: 'blob',
    }).then((res) => res.data);
  },
};

export default IssueReportExecutionClient;
