import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import IngestionHistoryChartPerSource from '../../../components/features/connections/IngestionHistoryChart/IngestionHistoryChartPerSource';
import SimpleBarGraph from '../../../components/shared/visualizations/SimpleBarGraph/SimpleBarGraph';
import { formatNumber } from '../../../lib/utils/number-utils';
import DataQualityScoreFluctuationChip from '../components/DataFluctuationChip/DataQualityScoreFluctuationChip';

function ToolTipContent({ payload, isLastInterval }: any) {
  const exportEvent = payload?.[0]?.value;
  return (
    <VStack alignItems="start" spacing={0}>
      <Text fontWeight="bold">{exportEvent} new data records events </Text>
      <Text>exported during this time window</Text>

      {isLastInterval && (
        <Text mt="4">
          Note that this time window is in progress and does not yet represent a complete data point
        </Text>
      )}
    </VStack>
  );
}

interface IngestionEventsActivityProps {
  timeseriesIngestionData: any;
  sourceId: string;
  sourceName: string;
  metrics: any;
  recordEventsLastWeek: number;
  totalRecordsEvents: number;
}
export default function IngestionEventsActivity({
  sourceId,
  timeseriesIngestionData,
  recordEventsLastWeek,
  totalRecordsEvents,
  sourceName,
  metrics,
}: IngestionEventsActivityProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <SectionContainer variant="box">
      <SimpleGrid columns={2}>
        <Stack>
          <Text as="span" fontWeight="bold">
            Ingestion events
          </Text>
          <HStack>
            <Text fontWeight="bold" fontSize="2xl">
              {formatNumber(totalRecordsEvents ?? 0)}
            </Text>
            <DataQualityScoreFluctuationChip timePeriod=" 7 days" value={recordEventsLastWeek} />
          </HStack>
          <Text>total data record ingestion events in the last 7 days</Text>
          <Button
            fontWeight="semibold"
            textColor="action"
            variant="link"
            alignSelf="start"
            onClick={onOpen}
          >
            Explore all ingestion activity
          </Button>
        </Stack>
        <Drawer isOpen={isOpen} size="xl" placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent pr={2}>
            <DrawerCloseButton />
            <DrawerHeader>Ingestion activity for {sourceName} </DrawerHeader>
            <DrawerBody>
              <IngestionHistoryChartPerSource
                chartWidth="102%"
                chartHeight={330}
                sourceId={sourceId}
                recordEventsIngested={metrics?.recordEventsIngested?.total}
                recordsDeleted={metrics?.recordEventsDeleted?.total}
                error={metrics?.recordEventsIngestedLastWeek?.errors}
                hideMetricsCards
              />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <SimpleBarGraph
          data={timeseriesIngestionData ?? []}
          xDataKey="label"
          yDataKey="value"
          highlightLastInterval
          tooltipContent={<ToolTipContent />}
          height={140}
        />
      </SimpleGrid>
    </SectionContainer>
  );
}
