/* eslint-disable no-nested-ternary */
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import IdentityClient from '../../../../lib/api-client/identity/IdentityClient';
import {
  IdentityDataEmailAddressesKeys,
  IdentityDataNamesKeys,
} from '../../../../lib/api-client/identity/model/Identity';
import { UpdateIdentityRecord } from '../../../../lib/api-client/identity/model/UpdateIdentityRecord';
import { formatRelativeDateString } from '../../../../lib/utils/date-time-utils';
import { formatPhoneNumber } from '../../../../lib/utils/format-utils';
import Icon from '../../../core/Icon/Icon';
import { IconImage } from '../../../core/Icon/IconConfig';

interface EditRecordModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: any;
  onRowValueChanged?: (data: any) => void;
  hideVersionButton?: boolean;
}

export default function EditRecordModal({
  data = {},
  isOpen,
  onClose,
  onRowValueChanged,
  hideVersionButton,
}: EditRecordModalProps) {
  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting },
  } = useForm<UpdateIdentityRecord>();

  useEffect(() => {
    if (isOpen) {
      reset(data);
    }
  }, [data, isOpen, reset]);

  const fieldLabel: { label: string; key?: any }[] = [
    {
      label: 'Full name',
      key: 'names',
    },
    {
      label: 'Email address',
      key: 'emailAddresses',
    },
    {
      label: 'Mobile phone number',
      key: 'mobilePhone',
    },
    {
      label: 'Home phone number',
      key: 'homePhone',
    },
    {
      label: 'Work phone number',
      key: 'workPhone',
    },
    {
      label: 'Street address',
      key: 'streetAddress',
    },
    {
      label: 'City',
      key: 'city',
    },
    {
      label: 'State',
      key: 'governingDistrict',
    },
    {
      label: 'ZIP code',
      key: 'zip4',
    },
    {
      label: 'Country',
      key: 'country',
    },
    {
      label: 'Date of birth',
      key: 'dateOfBirth',
    },
  ];

  const onSubmit: SubmitHandler<any> = async (formData) => {
    const updated = await IdentityClient.updateIdentityRecord(
      data.identityRecord.sourceId,
      data.identityRecord.sourceRecordId,
      {
        ...formData,
      }
    );
    if (onRowValueChanged) {
      if (!updated.identityRecord.pin) {
        // Keep old pin if response doesn't include a pin
        // We may want to handle this differently in the future
        updated.identityRecord.pin = data.identityRecord.pin;
      }

      onRowValueChanged(updated);
    }
    onClose();
  };
  const navigate = useNavigate();

  const formatEmailAddressField = (emails: IdentityDataEmailAddressesKeys[]) =>
    emails && emails.length > 0 ? (
      emails?.map((email: IdentityDataEmailAddressesKeys, index: number) => (
        <FormControl>
          <FormLabel pt={2} fontSize="xs" fontWeight="semibold" textColor="black">
            {`Email address ${index + 1}`}
          </FormLabel>
          <InputGroup>
            <InputRightElement>
              {email?.emailAddressModified && <Icon iconImage={IconImage.edit} />}
            </InputRightElement>
            <Input
              defaultValue={email.emailAddressModified ?? email.emailAddressRaw}
              {...register(`emailAddresses[${index}].emailAddress` as any)}
            />
          </InputGroup>
        </FormControl>
      ))
    ) : (
      <FormControl>
        <FormLabel pt={2} fontSize="xs" fontWeight="semibold" textColor="black">
          Email address
        </FormLabel>
        <Input {...register(`emailAddress`)} />
      </FormControl>
    );

  const formatNamesField = (names: IdentityDataNamesKeys[]) =>
    names && names.length > 0 ? (
      names.map((item: IdentityDataNamesKeys, index: number) => (
        <>
          <FormControl>
            <FormLabel pt={2} fontSize="xs" fontWeight="semibold" textColor="black">
              {`First Name ${index + 1}`}
            </FormLabel>
            <InputGroup>
              <InputRightElement>
                {item?.firstNameModified && <Icon iconImage={IconImage.edit} />}
              </InputRightElement>
              <Input
                defaultValue={item?.firstNameModified ?? item?.firstNameRaw}
                {...register(`names[${index}].firstName` as any)}
              />
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel fontWeight="semibold" pt={2} fontSize="xs" textColor="black">
              {`Last Name ${index + 1}`}
            </FormLabel>
            <InputGroup>
              <InputRightElement>
                {item?.lastNameModified && <Icon iconImage={IconImage.edit} />}
              </InputRightElement>
              <Input
                defaultValue={item?.lastNameModified ?? item?.lastNameRaw}
                {...register(`names[${index}].lastName` as any)}
              />
            </InputGroup>
          </FormControl>
        </>
      ))
    ) : (
      <HStack>
        <FormControl w="256px">
          <FormLabel pt={2} fontSize="xs" fontWeight="semibold" textColor="black">
            First Name
          </FormLabel>
          <Input {...register(`firstName`)} />
          <FormLabel pt={2} fontSize="xs" fontWeight="semibold" textColor="black">
            Last Name
          </FormLabel>
          <Input {...register(`lastName`)} />
        </FormControl>
      </HStack>
    );
  const renderField = (item: any) => {
    if (item.key === 'names' || item.key === 'emailAddresses') {
      return null;
    }
    return (
      <FormControl key={item.key}>
        <FormLabel pt={2} fontSize="xs" fontWeight="semibold" textColor="black">
          {item.label}
        </FormLabel>
        <InputGroup>
          <InputRightElement>
            {data.identityRecord[item.key].modified && <Icon iconImage={IconImage.edit} />}
          </InputRightElement>
          <Input
            defaultValue={
              item.key === 'homePhone'
                ? formatPhoneNumber(data.identityRecord[item.key].normalized)
                : item.key === 'mobilePhone'
                ? formatPhoneNumber(data.identityRecord[item.key].normalized)
                : item.key === 'workPhone'
                ? formatPhoneNumber(data.identityRecord[item.key].normalized)
                : data.identityRecord[item.key].modified
                ? data.identityRecord[item.key].modified
                : data.identityRecord[item.key].raw
            }
            {...register(item.key)}
          />
        </InputGroup>
      </FormControl>
    );
  };

  return (
    <Modal
      isCentered
      onClose={onClose}
      isOpen={isOpen}
      size="lg"
      closeOnOverlayClick={!isSubmitting}
    >
      <ModalOverlay />
      {data.identityRecord && (
        <ModalContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>Edit data record</ModalHeader>
            <ModalCloseButton />
            <HStack pl="6" spacing={4}>
              <HStack>
                <Text size="md" fontWeight="semibold" fontSize="lg">
                  PIN:
                </Text>
                <Text textColor="action">{data.identityRecord.pin}</Text>
              </HStack>
              <HStack>
                <Text size="md" fontWeight="semibold" fontSize="lg">
                  Source system ID:
                </Text>
                <Text>{data.identityRecord.sourceRecordId}</Text>
              </HStack>
            </HStack>
            <ModalBody>
              <HStack
                mt="4"
                w="full"
                h="50"
                p="2"
                pr="4"
                pl="4"
                bgColor="actionDark"
                spacing="2"
                textColor="white"
                justify="space-between"
              >
                <HStack spacing={2}>
                  <Icon iconImage={IconImage.edit} />
                  {data.identityRecord.lastModifiedBy || data.identityRecord.lastModifiedAt ? (
                    <Text maxW="560px">
                      This data record was last edited{' '}
                      {data.identityRecord.lastModifiedBy
                        ? `by ${data.identityRecord.lastModifiedBy}`
                        : ''}
                      {data.identityRecord.lastModifiedAt
                        ? ` at ${formatRelativeDateString(data.identityRecord.lastModifiedAt)}`
                        : ''}
                    </Text>
                  ) : (
                    <Text maxW="560px">
                      This version represents an update ingested from the source system on{' '}
                      {data.identityRecord.lastUpdated
                        ? `${formatRelativeDateString(data.identityRecord.lastUpdated)}`
                        : ''}
                    </Text>
                  )}
                </HStack>
                {!hideVersionButton && (
                  <Button
                    _hover={{ color: 'white' }}
                    variant="ghost"
                    textColor="white"
                    rightIcon={<Icon iconImage={IconImage.chevronForward} boxSize={6} />}
                    onClick={() => {
                      navigate(
                        `/sources/${data.identityRecord.sourceId}/records/${data.identityRecord.sourceRecordId}`
                      );
                    }}
                  >
                    version history
                  </Button>
                )}
              </HStack>
              <SimpleGrid columns={2} spacing={4} w="530px">
                {fieldLabel.map((item) =>
                  item.key === 'names'
                    ? formatNamesField(data?.identityRecord[item.key])
                    : item.key === 'emailAddresses'
                    ? formatEmailAddressField(data.identityRecord[item.key])
                    : ''
                )}
              </SimpleGrid>
              <SimpleGrid columns={3} spacing={4} mt={4}>
                {fieldLabel.map(renderField)}
              </SimpleGrid>
            </ModalBody>
            <ModalFooter justifyContent="start">
              <HStack spacing={5}>
                <Button type="submit" isLoading={isSubmitting}>
                  Save edits
                </Button>
                <Button variant="ghost" onClick={onClose} isLoading={isSubmitting}>
                  Cancel
                </Button>
              </HStack>
            </ModalFooter>
          </form>
        </ModalContent>
      )}
    </Modal>
  );
}
