import SourceMetricsClient from '../api-client/source-metrics/SourceMetricsClient';
import {
  DataSourceTimeSeriesMetrics,
  SystemTimeSeriesMetrics,
  TimeSeriesQueryResponse,
} from '../api-client/source-metrics/model/TimeSeriesQueryResponse';
import { TimeSeriesAggregate, TimeSeriesIntervalName } from '../model/common';
import { BasicTimeSeriesData } from '../model/visualizations/BasicTimeSeriesData';
import { LabelWithMetric } from '../model/visualizations/LabelWithMetric';
import { formatDateString } from '../utils/date-time-utils';

const labelConfig: { [key: string]: (date: string) => string } = {
  minute: (dateStr: string) => formatDateString(dateStr, 'h:mm a'),
  hour: (dateStr: string) => formatDateString(dateStr, 'h:mm a'),
  day: (dateStr: string) => formatDateString(dateStr, 'MMM d'),
  week: (dateStr: string) => formatDateString(dateStr, 'MMM d'),
  month: (dateStr: string) => formatDateString(dateStr, 'LLL'),
  year: (dateStr: string) => formatDateString(dateStr, 'y'),
};

function mapResponse<T>(
  response: TimeSeriesQueryResponse,
  m: T[],
  intervalName: TimeSeriesIntervalName,
  aggregateSummary?: TimeSeriesAggregate[]
) {
  if (response.series.length < 1) {
    return {
      data: [],
    };
  }

  const config = labelConfig[intervalName] || labelConfig.day;
  const values: LabelWithMetric[] = [];

  for (let i = 0; i < response.series[0].dataPoints.length; i++) {
    const metric: any = {};

    for (let j = 0; j < response.series.length; j++) {
      metric[response.series[j].metric] = response.series[j].dataPoints[i];
    }

    values.push({
      label: config(response.series[0].dataPoints[i].startDate),
      metric,
    });
  }

  const timeSeriesData: BasicTimeSeriesData = {
    data: values,
  };

  if (aggregateSummary) {
    const referenceValue: any = {};
    for (let i = 0; i < response.series.length; i++) {
      const { summaryData } = response.series[i];
      if (summaryData) {
        for (let j = 0; j < summaryData.length; j++) {
          if (!referenceValue[response.series[i].metric]) {
            referenceValue[response.series[i].metric] = {};
          }
          referenceValue[response.series[i].metric][summaryData[j].aggregate] =
            summaryData[j].value;
        }
      }
    }
    timeSeriesData.referenceValue = referenceValue;
  }

  return timeSeriesData;
}

/**
 * Transform time series metrics to visualization friendly format. T
 *
 * NOTE: This may only need to be a utility method instead a class we wrap for all time series APIs.
 */
const DataSourceTimeSeriesMetricsService = {
  async getSystemTimeSeries(
    metric: SystemTimeSeriesMetrics[],
    startDate: Date,
    endDate: Date,
    timezone: string,
    intervalName: TimeSeriesIntervalName,
    aggregate: TimeSeriesAggregate,
    aggregateSummary?: TimeSeriesAggregate[]
  ): Promise<BasicTimeSeriesData> {
    const response = await SourceMetricsClient.getSystemTimeSeriesData(
      metric,
      startDate,
      endDate,
      timezone,
      intervalName,
      aggregate,
      aggregateSummary
    );

    return mapResponse<SystemTimeSeriesMetrics>(response, metric, intervalName, aggregateSummary);
  },
  async getTimeSeries(
    sourceId: string,
    metric: DataSourceTimeSeriesMetrics[],
    startDate: Date,
    endDate: Date,
    timezone: string,
    intervalName: TimeSeriesIntervalName,
    aggregate: TimeSeriesAggregate,
    aggregateSummary?: TimeSeriesAggregate[]
  ): Promise<BasicTimeSeriesData> {
    const response = await SourceMetricsClient.getTimeSeriesData(
      sourceId,
      metric,
      startDate,
      endDate,
      timezone,
      intervalName,
      aggregate,
      aggregateSummary
    );

    return mapResponse(response, metric, intervalName, aggregateSummary);
  },
};

export default DataSourceTimeSeriesMetricsService;
