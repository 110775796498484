import { HStack, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import SectionContainer from '../../../../components/core/SectionContainer/SectionContainer';
import { useCurrentDataSource } from '../../../sources/context/CurrentDataSourceContext';
import CompletenessCheckmark from '../../shared/CompletenessCheckmark/CompletenessCheckmark';
import MicrosoftApplicationAccessInstructionsButton from './MicrosoftApplicationAccessInstructionsButton';
import MicrosoftTestAccessButton from './MicrosoftTestAccessButton';

interface MicrosoftDynamicsApplicationAccessSectionProps {
  onComplete: () => void;
  onError: () => void;
}

export default function MicrosoftDynamicsApplicationAccessSection({
  onComplete,
  onError,
}: MicrosoftDynamicsApplicationAccessSectionProps) {
  const { dataSource: source } = useCurrentDataSource();
  const isComplete = !!source.properties?.microsoftExportTest;

  return (
    <SectionContainer
      variant="box"
      title="Application access"
      borderRadius="6px"
      header={<CompletenessCheckmark label="Step 3: " isComplete={isComplete} />}
    >
      <Stack spacing={3}>
        <Text>
          Provide access to this source system by creating an application user for harpin AI using
          the instructions below, then test to confirm it.
        </Text>
        <HStack>
          <MicrosoftApplicationAccessInstructionsButton
            variant={isComplete ? 'outline' : 'solid'}
            sourceId={source.id}
            onSuccess={() => {
              // setTestAccessResult('success');
              // setApplicationAccessComplete(true);
              onComplete();
            }}
            onError={() => {
              // setTestAccessResult('error');
              onError();
            }}
          />
          <MicrosoftTestAccessButton
            variant={isComplete ? 'outline' : 'solid'}
            sourceId={source.id}
            onSuccess={() => {
              // setTestAccessResult('success');
              // setApplicationAccessComplete(true);
              onComplete();
            }}
            onError={() => {
              // setTestAccessResult('error');
              onError();
            }}
          />
        </HStack>
      </Stack>
    </SectionContainer>
  );
}
