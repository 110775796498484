import { Box, Button, Text, VStack } from '@chakra-ui/react';
import { useFlags } from 'flagsmith/react';
import React from 'react';
import { Link } from 'react-router-dom';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import {
  useGetSourceSystemConfigAttributes,
  useGetSourceSystemType,
} from '../../../lib/api-client/sources/SourceData';
import { FeatureFlags } from '../../../lib/model/FeatureFlags';
import { useCurrentDataSource } from '../../sources/context/CurrentDataSourceContext';
import ManageSourceConfigAttributesButton from '../SourceConnectionManagementPage/ConnectionConfiguration/ManageSourceConfigrationAttributesSection/ManageSourceConfigAttributesButton';
import CompletenessCheckmark from '../shared/CompletenessCheckmark/CompletenessCheckmark';

interface SourceConfigAttributesSectionProps {
  completeLabel?: string;
}

export default function SourceConfigAttributesSection({
  completeLabel = '',
}: SourceConfigAttributesSectionProps) {
  const { dataSource: source } = useCurrentDataSource();
  const { data: sourceSystemTypeData } = useGetSourceSystemType(
    source.sourceSystem ?? '',
    source.sourceSystem != null
  );
  const { data: configAttributes } = useGetSourceSystemConfigAttributes(source.id);
  const flags = useFlags([FeatureFlags.SHOW_CONNECTION_CONFIGURATION_PAGE]);

  const isNativeConfiguration = (sourceSystemTypeData?.configAttributes?.length ?? 0) > 0;
  if (!isNativeConfiguration) {
    return null;
  }

  const isConfigured = configAttributes?.length === sourceSystemTypeData?.configAttributes?.length;

  return (
    <SectionContainer
      variant="box"
      title="Security credentials"
      borderRadius="6px"
      header={<CompletenessCheckmark label={completeLabel} isComplete={isConfigured} />}
      data-testid="SecurityCredentialsSetup"
    >
      <VStack alignItems="start" spacing={3}>
        <Text>
          Allow harpin AI to start ingesting and processing data from this source system by adding
          the security credentials.
        </Text>

        {flags.show_connection_configuration_page.enabled ? (
          <Button as={Link} to={`/sources/${source.id}/manage/configuration`}>
            Manage configuration
          </Button>
        ) : (
          <Box>
            <ManageSourceConfigAttributesButton />
          </Box>
        )}
      </VStack>
    </SectionContainer>
  );
}
