import {
  Button,
  ButtonProps,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import SourceClient from '../../../../lib/api-client/sources/SourceClient';
import { openMicrosoftDynamicsConsentWindow } from '../../../../lib/utils/iframe-utils';

interface MicrosoftTenantIdButtonProps {
  sourceId: string;
  microsoftTenantId?: Nullable<string>;
  onSuccess: () => void;
}
export default function MicrosoftTenantIdButton({
  sourceId,
  microsoftTenantId = '',
  onSuccess,
  ...props
}: MicrosoftTenantIdButtonProps & ButtonProps) {
  const [tenantId, setTenantId] = useState(microsoftTenantId);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const onSubmit = async () => {
    if (tenantId) {
      await openMicrosoftDynamicsConsentWindow(tenantId, async () => {
        await SourceClient.updateDataSourceProperties(sourceId, { microsoftTenantId: tenantId });
        onSuccess();
        onClose();
      });
    }
  };

  return (
    <>
      <Button onClick={onOpen} {...props}>
        Grant consent
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Provide tenant ID
            <ModalCloseButton top={3.5} />
          </ModalHeader>
          <ModalBody>
            <Stack spacing={3}>
              <Text>
                Once you submit the tenant ID, you will receive a prompt from Microsoft to grant
                consent to harpin AI to access it.
              </Text>
              <FormControl>
                <FormLabel>Microsoft tenant ID</FormLabel>
                <Input onChange={(e) => setTenantId(e.target.value)} value={tenantId ?? ''} />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter justifyContent="start">
            <Button onClick={onSubmit}>Submit tenant ID</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
