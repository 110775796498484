import { Box, HStack, SimpleGrid, StackProps } from '@chakra-ui/react';
import {
  defaultPercentageColorStrategyCssVar,
  varColorWrapper,
} from '../../../../lib/utils/color-utils';
import { formatPercentage } from '../../../../lib/utils/number-utils';
import { isDefined, isUndefined } from '../../../../lib/utils/utils';
import SimpleStat from '../../../core/data/SimpleStat/SimpleStat';
import SingleRadialBarChart from '../../../core/data/SingleRadialBarChart/SingleRadialBarChart';

interface SingleRadialBarChartVisualizationProps extends StackProps {
  primaryMetric: {
    value: number;
    percentage?: number;
    label?: string;
  };
  secondaryMetric?: {
    value: number;
    label?: string;
    percentage?: number;
    variant?: string;
  };
  variant?: string;
}

function SingleRadialBarChartVisualization({
  primaryMetric,
  secondaryMetric,
  variant = 'dynamic',
  ...rest
}: SingleRadialBarChartVisualizationProps) {
  const color = primaryMetric.percentage
    ? defaultPercentageColorStrategyCssVar(primaryMetric.percentage, variant !== 'dynamic')
    : varColorWrapper('gray-200');

  return (
    <HStack {...rest}>
      <SimpleGrid w="full" columns={1} spacingY={4}>
        <SimpleStat
          variant={variant}
          label={primaryMetric.label}
          value={primaryMetric.value}
          badge={
            isDefined(primaryMetric.percentage)
              ? {
                  value: primaryMetric.percentage!,
                  formattedValue: formatPercentage(primaryMetric.percentage, 1, 'closePrecision'),
                }
              : undefined
          }
        />
        {secondaryMetric && (
          <SimpleStat
            variant="solid"
            label={secondaryMetric.label}
            value={secondaryMetric.value}
            badge={
              !isUndefined(secondaryMetric.percentage)
                ? {
                    value: secondaryMetric.percentage,
                    formattedValue: formatPercentage(
                      secondaryMetric.percentage,
                      1,
                      'closePrecision'
                    ),
                  }
                : undefined
            }
          />
        )}
      </SimpleGrid>

      <Box py={1.5}>
        <SingleRadialBarChart
          height={120}
          width={120}
          chartColor={color}
          value={primaryMetric.percentage ?? Number.NaN}
        />
      </Box>
    </HStack>
  );
}
export default SingleRadialBarChartVisualization;
