import { Box, Text, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import SectionContainer from '../../../components/core/SectionContainer/SectionContainer';
import TrayConfigurationButton from '../../../components/features/connections/TrayConfigurationButton/TrayConfigurationButton';
import { DataSource } from '../../../lib/api-client/sources/model/DataSource';
import CompletenessCheckmark from '../shared/CompletenessCheckmark/CompletenessCheckmark';

interface ExternalConfigurationSectionProps {
  source: Pick<DataSource, 'id' | 'configured'>;
  onComplete: () => void;
  completeLabel?: string;
  onError: () => void;
}

export default function ExternalConfigurationSection({
  source,
  completeLabel = '',
  onComplete,
  onError,
}: ExternalConfigurationSectionProps) {
  const [isConfigured, setConfigured] = useState(source.configured);

  return (
    <SectionContainer
      variant="box"
      title="Security credentials"
      borderRadius="6px"
      header={<CompletenessCheckmark label={completeLabel} isComplete={isConfigured} />}
      data-testid="SecurityCredentialsSetup"
    >
      <VStack alignItems="start" spacing={3}>
        <Text>
          Allow harpin AI to start ingesting and processing data from this source system by adding
          the security credentials.
        </Text>
        <Box>
          <TrayConfigurationButton
            hasCredentials={isConfigured}
            sourceId={source.id}
            onSuccess={async () => {
              setConfigured(true);
              onComplete();
            }}
            onError={() => {
              onError();
            }}
          />
        </Box>
      </VStack>
    </SectionContainer>
  );
}
