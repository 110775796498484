import {
  Box,
  Button,
  chakra,
  Flex,
  HStack,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import consola from 'consola';
import React, { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import Alert from '../../../components/core/Alert/Alert';
import Header from '../../../components/core/Header/Header';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import PageLayout from '../../../components/shared/layouts/PageLayout/PageLayout';
import UnsavedChangesModal from '../../../components/shared/modals/UnsavedChangesModal/UnsavedChangesModal';
import { useToast } from '../../../hooks/use-toast';
import { useDataModel } from '../../../lib/api-client/data-model/DataModelData';
import { mapPrimaryDomainToAttributeDomain } from '../../../lib/api-client/data-model/data-model.utils';
import SourceClient from '../../../lib/api-client/sources/SourceClient';
import { useGetDataSource } from '../../../lib/api-client/sources/SourceData';
import { UpdateDataSource } from '../../../lib/api-client/sources/model/UpdateDataSource';
import { filterAttributeMappings } from '../../../lib/api-client/sources/sources.utils';
import { isHttpError } from '../../../lib/utils/error-utils';
import { isDefined, isUndefined } from '../../../lib/utils/utils';
import ConnectionManagementHeader from '../components/ConnectionManagementHeader/ConnectionManagementHeader';
import { useCurrentDataSource } from '../context/CurrentDataSourceContext';
import {
  attributeMappingSchema,
  ensureRequiredMappingsForSource,
  flattenAttributes,
  showAttributeOrderToast,
  UpdateDataSourceForm,
} from './DataMappingPage.utils';
import CoreMappingsTab from './components/CoreMappingsTab/CoreMappingsTab';
import DuplicateMappingsButton from './components/DuplicateMappingsButton/DuplicateMappingsButton';
import PublishMappingsTab from './components/PublishMappingsTab/PublishMappingsTab';

const formSchema = yup.object().shape({
  attributeMappings: attributeMappingSchema,
});

export default function DataMappingPage() {
  const { id = '' } = useParams();
  const [queryParams] = useSearchParams();
  const sampleId = queryParams.get('sampleId');
  const navigate = useNavigate();
  const [warnOnNavigation, setWarnOnNavigation] = useState(true);
  const [error, setError] = useState(false);
  const [isCoreValid, setIsCoreValid] = useState(true);
  const [isPublishValid, setIsPublishValid] = useState(true);
  const { dataSource: fallbackSource } = useCurrentDataSource();
  const {
    data: source,
    error: sourceError,
    refetch,
  } = useGetDataSource(
    id,
    sampleId
      ? { sampleId, includeRecommendedAttributeMappings: true, includeSourceAttributes: true }
      : { includeSourceAttributes: true }
  );
  const { data: dataModel, error: dataModelError } = useDataModel();
  const isLoading = !isDefined(source) || !isDefined(dataModel);
  const isInitializationError = !!(sourceError || dataModelError);
  const hasOutbound = ['BOTH', 'OUT'].includes(source?.syncDirection ?? '');
  const returnTo = `/sources/${id}/manage`;
  const [duplicateSuccess, setDuplicateSuccess] = useState(false);
  const [duplicateError, setDuplicateError] = useState(false);

  const form = useForm<UpdateDataSourceForm>({
    resolver: yupResolver(formSchema),
    mode: 'onBlur',
  });

  const fieldArray = useFieldArray({
    control: form.control,
    name: 'attributeMappings',
  });

  const toast = useToast();

  useEffect(() => {
    if (!(source && dataModel)) {
      return;
    }

    const attributeMappings = ensureRequiredMappingsForSource(source, dataModel);

    if (
      !isLoading &&
      sampleId &&
      attributeMappings.find((a) => (a.sourceAttributes.length ?? 0) > 1)
    ) {
      showAttributeOrderToast(toast);
    }

    form.reset({
      domainType: source.domainType,
      name: source.name,
      category: source.category,
      syncDirection: source.syncDirection,
      properties: {
        transactionSourceEntityName: source.properties?.transactionSourceEntityName,
        transactionLineItemSourceEntityName: source.properties?.transactionLineItemSourceEntityName,
      },
      attributeMappings,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, dataModel, source, sampleId, isLoading]);

  const onSubmit: SubmitHandler<UpdateDataSourceForm> = async ({
    properties,
    attributeMappings,
    ...rest
  }) => {
    if (isUndefined(dataModel)) {
      setError(true);
      consola.error('data model is undefined');
      return;
    }

    try {
      setWarnOnNavigation(false);
      const updatedSource: UpdateDataSource = { ...rest, attributeMappings: [] };
      const domainTypes = mapPrimaryDomainToAttributeDomain(dataModel)[updatedSource.domainType];

      // FIXME - SEV-3275 - one day this assumes the identity primary data type
      if (updatedSource.syncDirection !== 'IN') {
        domainTypes.push('EXPORT', 'TRANSACTION', 'TRANSACTION_LINE_ITEM');
      }

      const filteredMappings = filterAttributeMappings(attributeMappings, ...domainTypes);
      updatedSource.attributeMappings = flattenAttributes(filteredMappings);

      await SourceClient.updateDataSource(id, updatedSource);
      if (properties) {
        await SourceClient.updateDataSourceProperties(id, properties);
      }
      navigate(returnTo);
    } catch (err) {
      setError(true);
    } finally {
      setWarnOnNavigation(true);
    }
  };

  if (sourceError && isHttpError(sourceError, 404)) {
    return <Navigate to="/404" replace />;
  }

  return (
    <PageLayout
      data-testid="DataMappingPage"
      pageViewEvent={{ page: 'Data Mappings Page', pageId: id }}
      initializationError={isInitializationError}
      header={
        <Header
          title={`${fallbackSource?.name} data mappings`}
          icon={<Icon iconImage={IconImage.databaseSettings} />}
          back={{
            label: 'Back to manage connection',
            to: {
              pathname: returnTo,
            },
          }}
        />
      }
    >
      {sampleId && isLoading && (
        <HStack spacing="3">
          <Spinner thickness="3px" speed="3s" emptyColor="gray.200" color="blue.500" size="lg" />
          <VStack alignItems="start" spacing="1" w="1020px">
            <chakra.span fontWeight="bold" fontSize="20px">
              One moment while our AI engine generates data mapping recommendations...
            </chakra.span>
            <chakra.span>
              Our AI engine maps the harpin AI data fields to the source system attributes using the
              sample set of data records ingested. Please remain on this screen to avoid disruption.
              If data mappings don’t load within a few minutes, try refreshing.
            </chakra.span>
          </VStack>
        </HStack>
      )}
      {error && (
        <Alert
          status="error"
          title="There was an error saving your data mappings."
          description="Please double check your mappings and try again."
          onClose={() => setError(false)}
          closeable
          mb={10}
        />
      )}
      <UnsavedChangesModal
        message="Are you sure you want to leave before saving your data mapping configurations?"
        warnOnNavigation={warnOnNavigation && form.formState.isDirty}
      />
      {!isLoading && (
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Flex justifyContent="space-between">
              <ConnectionManagementHeader source={source} />
              <Button type="submit" isLoading={form.formState.isSubmitting}>
                Save data mappings
              </Button>
            </Flex>
            {duplicateSuccess && (
              <Alert
                status="success"
                description={`The mappings configuration for ${fallbackSource.name} has been successfully applied below.`}
                closeable
                mb={6}
                onClose={() => setDuplicateSuccess(false)}
              />
            )}
            {duplicateError && (
              <Alert
                status="error"
                description="We were unable to apply the mappings configuration due to an error. Please try again."
                closeable
                mb={6}
                onClose={() => setDuplicateError(false)}
              />
            )}
            {sampleId && (
              <Alert
                status="success"
                description="We’ve leveraged a sample of data records from this source system to expedite the configuration with AI-driven data mapping recommendations. Simply review the mappings below and make any necessary adjustments."
                closeable
                mb={6}
              />
            )}
            <Tabs variant="line" colorScheme="actionScheme">
              {hasOutbound ? (
                <TabList>
                  <HStack justify="space-between" w="full">
                    <HStack>
                      <Tab>
                        {!isCoreValid && source.syncDirection === 'BOTH' && (
                          <Icon iconImage={IconImage.warning} color="warning" boxSize={4} mr={1} />
                        )}
                        Core mappings
                      </Tab>
                      {hasOutbound && (
                        <Tab>
                          {!isPublishValid && source.syncDirection === 'BOTH' && (
                            <Icon
                              iconImage={IconImage.warning}
                              color="warning"
                              boxSize={4}
                              mr={1}
                            />
                          )}
                          Additional publish mappings
                        </Tab>
                      )}
                    </HStack>
                    <DuplicateMappingsButton
                      onSuccess={async () => {
                        setDuplicateSuccess(true);
                        setDuplicateError(false);
                        await refetch();
                      }}
                      onError={() => {
                        setDuplicateSuccess(false);
                        setDuplicateError(true);
                      }}
                    />
                  </HStack>
                </TabList>
              ) : (
                <HStack
                  justify="space-between"
                  borderBottom="1px solid"
                  borderColor="gray.300"
                  fontWeight="semibold"
                  pb={1}
                >
                  <Box>Field level mappings</Box>
                  <DuplicateMappingsButton
                    onSuccess={async () => {
                      setDuplicateSuccess(true);
                      setDuplicateError(false);
                      await refetch();
                    }}
                    onError={() => {
                      setDuplicateSuccess(false);
                      setDuplicateError(true);
                    }}
                  />
                </HStack>
              )}
              <TabPanels>
                <TabPanel p={0} pt={4}>
                  <CoreMappingsTab
                    source={source}
                    dataModel={dataModel}
                    useFieldArray={fieldArray}
                    onValidate={(valid) => {
                      setIsCoreValid(valid);
                    }}
                  />
                </TabPanel>
                {hasOutbound && (
                  <TabPanel p={0} pt={4}>
                    <PublishMappingsTab
                      source={source}
                      dataModel={dataModel}
                      useFieldArray={fieldArray}
                      onValidate={setIsPublishValid}
                    />
                  </TabPanel>
                )}
              </TabPanels>
            </Tabs>
          </form>
        </FormProvider>
      )}
    </PageLayout>
  );
}
