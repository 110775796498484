const TooltipStyles = {
  baseStyle: {
    bg: 'gray.800',
    '--popper-arrow-bg': 'var(--chakra-colors-gray-800)',
    borderRadius: '6px',
    textAlign: 'left',
    px: '8px',
    py: '8px',
  },
  sizes: {
    lg: {
      maxW: '400px',
    },
  },
};
export default TooltipStyles;
