import { Box, Flex } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Alert from '../../components/core/Alert/Alert';
import AccountRouterForm from '../../components/features/authentication/AccountRouterForm/AccountRouterForm';
import LoginForm from '../../components/features/authentication/LoginForm/LoginForm';
import { AuthenticationConfiguration } from '../../lib/api-client/authentication/model/AuthenticationConfiguration';

export default function SigninPage() {
  const { state } = useLocation() as { state?: { isTimeout?: boolean } };
  const [accountEmail, setAccountEmail] = useState('');
  const [error, setError] = useState(false);
  const [authenticationConfiguration, setAuthConfiguration] =
    useState<AuthenticationConfiguration | null>();

  return (
    <Flex
      height="100vh"
      width="100vw"
      backgroundColor="gray.100"
      alignItems="center"
      justifyContent="center"
    >
      <Box>
        {state?.isTimeout && (
          <Box w="740px" mb="40px">
            <Alert
              status="error"
              title="Your session has expired due to inactivity."
              description="Please sign in again to continue to use the tool."
              closeable
            />
          </Box>
        )}
        {error && (
          <Box w="740px" mb="40px">
            <Alert
              status="error"
              title="A system error occurred."
              description="Please try again or come back later. We apologize for the inconvenience."
              closeable
              onClose={() => setError(false)}
            />
          </Box>
        )}
        <Box
          h="fit-content"
          w="740px"
          backgroundColor="white"
          textAlign="center"
          borderWidth="1px"
          borderRadius="6px"
          borderColor="gray.200"
        >
          {authenticationConfiguration?.useNative ? (
            <LoginForm
              accountEmail={accountEmail}
              authenticationConfiguration={authenticationConfiguration}
              onBack={() => {
                setError(false);
                setAuthConfiguration(null);
              }}
              onSuccess={() => {
                setError(false);
              }}
              onError={() => setError(true)}
            />
          ) : (
            <AccountRouterForm
              onSuccess={(authConfig, emailAddress) => {
                setAccountEmail(emailAddress);
                setAuthConfiguration(authConfig);
                setError(false);
              }}
              onError={() => setError(true)}
            />
          )}
        </Box>
      </Box>
    </Flex>
  );
}
