import { DataSourceAttributeMultiValueMapping } from '../../../pages/sources/DataMappingPage/DataMappingPage.utils';
import { DataSourceDomainTypes } from './model/DataSourceDomainTypes';

/**
 * Filter source attribute mappings based on the provided types.
 *
 * @param attributeMappings attribute mappings
 * @param domainTypes domain types
 */
// eslint-disable-next-line import/prefer-default-export
export function filterAttributeMappings(
  attributeMappings: DataSourceAttributeMultiValueMapping[],
  ...domainTypes: DataSourceDomainTypes[]
): DataSourceAttributeMultiValueMapping[] {
  return attributeMappings.filter(
    (mapping) => mapping.domainType && domainTypes.includes(mapping.domainType)
  );
}
