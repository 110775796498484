import {
  Button,
  chakra,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormLabel,
  HStack,
  Select,
  Stack,
} from '@chakra-ui/react';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useDataExplorer } from '../../../../components/shared/data-explorer/DataExplorer/DataExplorerContext';
import Drawer from '../../../../components/shared/modals/Drawer/Drawer';
import {
  recordCategoryToLabel,
  SourceIdentityRecordCategory,
} from '../../../../lib/api-client/identity/model/SourceIdentityRecordCategory';
import {
  recordGroupToLabel,
  SourceIdentityRecordGroup,
} from '../../../../lib/api-client/identity/model/SourceIdentityRecordGroup';
import {
  AttributeSearchOperation,
  IdentityRecordSearchAttribute,
  SourceIdentityRecordOptInAttribute,
} from '../../../../lib/api-client/identity/model/SourceIdentityRecordSearch';
import Mixpanel from '../../../../lib/mixpanel/Mixpanel';
import { isDefined } from '../../../../lib/utils/utils';
import {
  ADDRESS_SCORE_RANGES,
  EMAIL_SCORE_RANGES,
  NAME_SCORE_RANGES,
  RECORD_SCORE_RANGES,
  VALIDITY_RANGES,
} from '../../../data-explorer/components/SourceSystemRecordExplorer/description-utils';
import AttributeCompletenessFilter from './AttributeCompletenessFilter';
import AttributeScoreFilter from './AttributeScoreFilter';
import {
  describeFilters,
  formToPageUrlParams,
  ScoreFormFilter,
  SourceSystemRecordFilterForm,
  SourceSystemRecordFilterFormChangeEvent,
  transformCompletenessFilterToAttributeSearchOperation,
  transformCompletenessFilterToSearchOperation,
  transformFilterToAttributeSearchOperation,
  transformFilterToSearchOperation,
  urlParamsToForm,
} from './source-system-filter-utils';

function trackDORAFilter(properties: { SelectedFilter: any }) {
  Mixpanel.track('DORA selected filters', properties);
}

const recordGroupToCategoryMapping: Record<string, SourceIdentityRecordCategory[]> = {
  nameIssues: [
    SourceIdentityRecordCategory.NameWithBadCharacters,
    SourceIdentityRecordCategory.FirstNameLastNameEqual,
    SourceIdentityRecordCategory.NameUnrecognized,
    SourceIdentityRecordCategory.MissingNameDerivable,
    SourceIdentityRecordCategory.MultipleNames,
    SourceIdentityRecordCategory.InitialsOnly,
    SourceIdentityRecordCategory.nameIsRepeated,
  ],
  emailIssues: [
    SourceIdentityRecordCategory.EmailDomainMisspelled,
    SourceIdentityRecordCategory.EmailInvalid,
    SourceIdentityRecordCategory.EmailOnly,
  ],
  phoneIssues: [SourceIdentityRecordCategory.PhoneInvalid],
  nonConsumer: [
    SourceIdentityRecordCategory.PlaceholderData,
    SourceIdentityRecordCategory.TestData,
    SourceIdentityRecordCategory.SpamData,
    SourceIdentityRecordCategory.MaliciousData,
  ],
  dateOfBirthIssues: [SourceIdentityRecordCategory.DateOfBirthInvalid],
  business: [SourceIdentityRecordCategory.Business],
  household: [SourceIdentityRecordCategory.Household],
  addressIssues: [SourceIdentityRecordCategory.AddressInvalid],
};

const recordCategoryToGroupMapping: Record<SourceIdentityRecordCategory, string> = {} as any;
Object.entries(recordGroupToCategoryMapping).forEach(([key, value]) => {
  value.forEach((v) => {
    recordCategoryToGroupMapping[v] = key;
  });
  // the group is also a valid record category
  recordCategoryToGroupMapping[key as SourceIdentityRecordCategory] = key;
});

interface SourceSystemRecordExplorerDrawerProps {
  onFilterChange: (filters: SourceSystemRecordFilterFormChangeEvent) => void;
}
const defaultObject = {
  recordCategory: 'all',
  recordCompleteness: 'all',
  pinned: 'all',
  duplicates: 'all',
  modifications: 'all',
  recordScore: {
    range: 'all',
    operator: 'all',
    value1: 'all',
    value2: undefined,
  },
  firstName: 'all',
  firstNameScore: {
    range: 'all',
    operator: 'all',
    value1: 'all',
    value2: undefined,
  },
  lastName: 'all',
  lastNameScore: {
    range: 'all',
    operator: 'all',
    value1: 'all',
    value2: undefined,
  },
  email: 'all',
  emailScore: {
    range: 'all',
    operator: 'all',
    value1: 'all',
    value2: undefined,
  },
  phone: 'all',
  phoneScore: {
    range: 'all',
    operator: 'all',
    value1: 'all',
    value2: undefined,
  },
  dobScore: {
    range: 'all',
    operator: 'all',
    value1: 'all',
    value2: undefined,
  },
  address: 'all',
  addressScore: {
    range: 'all',
    operator: 'all',
    value1: 'all',
    value2: undefined,
  },
  optIn: {
    optInEmail: '',
    optInPhone: '',
    optInText: '',
  },
};

export default function SourceSystemRecordExplorerFilterDrawer({
  onFilterChange,
}: SourceSystemRecordExplorerDrawerProps) {
  const {
    filterDrawerDisclosure: { isOpen, onClose },
  } = useDataExplorer();
  const queryParams = new URLSearchParams(window.location.search.replace('?', ''));
  const form = useForm<SourceSystemRecordFilterForm>({
    defaultValues: {
      ...urlParamsToForm(queryParams),
    },
  });
  const recordCategory = queryParams.get('recordCategory') ?? 'all';
  const [recordGroup, setRecordGroup] = useState<string>(
    recordCategoryToGroupMapping[recordCategory as SourceIdentityRecordCategory] ?? 'all'
  );
  const onSubmit: SubmitHandler<SourceSystemRecordFilterForm> = (data) => {
    const attributeScores = [
      [IdentityRecordSearchAttribute.FirstName, data.firstNameScore],
      [IdentityRecordSearchAttribute.LastName, data.lastNameScore],
      [IdentityRecordSearchAttribute.Phone, data.phoneScore],
      [IdentityRecordSearchAttribute.EmailAddress, data.emailScore],
      [IdentityRecordSearchAttribute.DateOfBirth, data.dobScore],
      [IdentityRecordSearchAttribute.Address, data.addressScore],
    ]
      .map(([attribute, filter]) =>
        transformFilterToAttributeSearchOperation(
          attribute as IdentityRecordSearchAttribute,
          filter as ScoreFormFilter
        )
      )
      .filter((v) => isDefined(v)) as AttributeSearchOperation[];

    const attributeCompleteness = [
      [IdentityRecordSearchAttribute.FirstName, data.firstName],
      [IdentityRecordSearchAttribute.LastName, data.lastName],
      [IdentityRecordSearchAttribute.Phone, data.phone],
      [IdentityRecordSearchAttribute.EmailAddress, data.email],
    ]
      .map(([attribute, filter]: any) =>
        transformCompletenessFilterToAttributeSearchOperation(attribute, filter)
      )
      .filter((v) => isDefined(v)) as AttributeSearchOperation[];

    const optInAttributes = Object.entries(data.optIn)
      .reduce<SourceIdentityRecordOptInAttribute[]>(
        (prev, [key, value]) => [
          ...prev,
          { attribute: key as any, value: value ? Boolean(value) : undefined },
        ],
        []
      )
      .filter((item) => item.value != null);

    window.history.replaceState(null, '', formToPageUrlParams(data));
    onFilterChange({
      pageUrl: formToPageUrlParams(data),
      descriptions: describeFilters(data),
      data: {
        modifications: data.modifications !== 'all' ? data.modifications === 'true' : undefined,
        recordCategories:
          data.recordCategory === 'all' ? [] : (data.recordCategory?.split(',') as any),
        recordScore: transformFilterToSearchOperation(data.recordScore),
        recordCompleteness: transformCompletenessFilterToSearchOperation(data.recordCompleteness),
        pinned:
          isDefined(data.pinned) && data.pinned !== 'all' ? data.pinned === 'pinned' : undefined,
        duplicates: data.duplicates === 'duplicate' || undefined,
        attributeCompleteness,
        attributeScores,
        optInAttributes,
      },
    });
    trackDORAFilter({
      SelectedFilter: data,
    });
    onClose();
  };
  useEffect(() => {
    form.reset({
      ...urlParamsToForm(queryParams),
    });
    form.handleSubmit(onSubmit)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isButtonDisabled, setButtonDisabled] = useState(true);
  useEffect(() => {
    const areObjectsEqual = isEqual(defaultObject, form.getValues());
    setButtonDisabled(areObjectsEqual);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultObject, form.getValues()]);

  return (
    <FormProvider {...form}>
      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerBody>
          <chakra.form onSubmit={form.handleSubmit(onSubmit)}>
            <Stack spacing={6} pb={10}>
              <Stack spacing={6}>
                <chakra.span fontWeight="bold" fontSize="xl">
                  Filter data records
                </chakra.span>
                <Stack spacing={4} w="full">
                  <AttributeScoreFilter
                    label="Record score"
                    fieldName="recordScore"
                    options={RECORD_SCORE_RANGES}
                  />
                  <AttributeCompletenessFilter
                    label="Record completeness"
                    fieldName="recordCompleteness"
                  />
                  <FormControl>
                    <FormLabel>Record pinnability</FormLabel>
                    <Select {...form.register('pinned')}>
                      <option value="all">All</option>
                      <option value="pinned">Pinned</option>
                      <option value="unpinned">Not pinned</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Record duplication</FormLabel>
                    <Select {...form.register('duplicates')}>
                      <option value="all">All</option>
                      <option value="duplicate">Duplicates only</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Quality issue category</FormLabel>
                    <Select
                      mb={4}
                      onChange={(e) => {
                        setRecordGroup(e.target.value);
                        form.setValue('recordCategory', e.target.value);
                      }}
                      defaultValue={recordGroup}
                    >
                      <option value="all">All</option>
                      {Object.values(SourceIdentityRecordGroup)
                        .filter((v) => v !== SourceIdentityRecordGroup.Duplicates)
                        .sort((a, b) => a.localeCompare(b))
                        .map((group) => (
                          <option value={group} key={group}>
                            {recordGroupToLabel(group)}
                          </option>
                        ))}
                    </Select>
                    <Select {...form.register('recordCategory')} disabled={recordGroup === 'all'}>
                      <option value={recordGroup}>All</option>
                      {recordGroupToCategoryMapping[recordGroup]
                        ?.sort((a, b) =>
                          recordCategoryToLabel(a).localeCompare(recordCategoryToLabel(b))
                        )
                        .map((category) => (
                          <option key={`category-${category}`} value={category}>
                            {recordCategoryToLabel(category)}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Record modification</FormLabel>
                    <Select {...form.register('modifications')}>
                      <option value="all">All</option>
                      <option value="true">Edited</option>
                      <option value="false">Not edited</option>
                    </Select>
                  </FormControl>
                </Stack>
              </Stack>
              <Stack spacing={6}>
                <chakra.span fontWeight="bold" fontSize="xl">
                  Filter data fields
                </chakra.span>
                <Stack spacing={4} w="full">
                  <AttributeScoreFilter
                    label="First name score"
                    fieldName="firstNameScore"
                    options={NAME_SCORE_RANGES}
                  />
                  <AttributeCompletenessFilter
                    label="First name completeness"
                    fieldName="firstName"
                  />
                  <AttributeScoreFilter
                    label="Last name score"
                    fieldName="lastNameScore"
                    options={NAME_SCORE_RANGES}
                  />
                  <AttributeCompletenessFilter
                    label="Last name completeness"
                    fieldName="lastName"
                  />
                  <AttributeScoreFilter
                    label="Email address score"
                    fieldName="emailScore"
                    options={EMAIL_SCORE_RANGES}
                  />
                  <AttributeCompletenessFilter
                    label="Email address completeness"
                    fieldName="email"
                  />
                  <AttributeScoreFilter
                    label="Address score"
                    fieldName="addressScore"
                    options={ADDRESS_SCORE_RANGES}
                  />

                  <AttributeScoreFilter
                    label="Phone number score"
                    fieldName="phoneScore"
                    options={VALIDITY_RANGES}
                  />
                  <AttributeCompletenessFilter
                    label="Phone number completeness"
                    fieldName="phone"
                  />
                  <AttributeScoreFilter
                    label="Date of birth score"
                    fieldName="dobScore"
                    options={VALIDITY_RANGES}
                  />
                  <FormControl>
                    <FormLabel>Opt-in Email</FormLabel>
                    <Select placeholder="Select one" {...form.register('optIn.optInEmail')}>
                      <option value="true">Opted-in</option>
                      <option value="false">Opted-out</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Opt-in Text</FormLabel>
                    <Select placeholder="Select one" {...form.register('optIn.optInText')}>
                      <option value="true">Opted-in</option>
                      <option value="false">Opted-out</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Opt-in Phone</FormLabel>
                    <Select placeholder="Select one" {...form.register('optIn.optInPhone')}>
                      <option value="true">Opted-in</option>
                      <option value="false">Opted-out</option>
                    </Select>
                  </FormControl>
                </Stack>
              </Stack>
            </Stack>
          </chakra.form>
        </DrawerBody>
        <DrawerFooter borderTop="1px solid" borderColor="gray.100">
          <HStack w="full">
            <Button
              onClick={() => form.handleSubmit(onSubmit)()}
              onSubmit={() => form.handleSubmit(onSubmit)()}
              w="full"
            >
              Apply filters
            </Button>
            <Button
              w="full"
              variant="outline"
              colorScheme="actionScheme"
              disabled={isButtonDisabled}
              onClick={() => {
                form.reset({
                  recordScore: {
                    range: 'all',
                  },
                  recordCategory: 'all',
                  recordCompleteness: 'all',
                  pinned: 'all',
                  duplicates: 'all',
                  modifications: 'all',
                  firstName: 'all',
                  firstNameScore: {
                    range: 'all',
                  },
                  lastName: 'all',
                  lastNameScore: {
                    range: 'all',
                  },
                  email: 'all',
                  emailScore: {
                    range: 'all',
                  },
                  phone: 'all',
                  phoneScore: {
                    range: 'all',
                  },
                  dobScore: {
                    range: 'all',
                  },
                  addressScore: {
                    range: 'all',
                  },
                  optIn: {
                    optInEmail: '',
                    optInText: '',
                    optInPhone: '',
                  },
                });
                setButtonDisabled(true);
              }}
            >
              Reset filters
            </Button>
          </HStack>
        </DrawerFooter>
      </Drawer>
    </FormProvider>
  );
}
