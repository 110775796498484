import * as Sentry from '@sentry/react';
import { Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Location, Outlet, useLocation } from 'react-router-dom';
import { useInfiniteScroll } from '../../../../context/InfiniteScrollContext';
import SystemErrorPage from '../../errors/SystemErrorPage/SystemErrorPage';
import Sidenav from '../../panels/Sidenav/Sidenav';

function ErrorPage({ resetError }: { resetError(): void }) {
  const location = useLocation();
  const [errorLocation, setErrorLocation] = useState<Location>();

  useEffect(() => {
    if (!errorLocation) {
      setErrorLocation(location);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (errorLocation && location.pathname !== errorLocation?.pathname) {
      resetError();
    }
  }, [location.pathname, errorLocation, resetError]);

  return <SystemErrorPage />;
}

function renderFallback({ resetError }: { resetError(): void }) {
  return <ErrorPage resetError={resetError} />;
}

export default function Layout() {
  const { setScrollRef } = useInfiniteScroll();

  return (
    <Flex height="100vh">
      <Sidenav />
      <Flex flexDirection="column" overflowY="auto" width="100%" ref={(ref) => setScrollRef(ref)}>
        <Sentry.ErrorBoundary fallback={renderFallback}>
          <Outlet />
        </Sentry.ErrorBoundary>
      </Flex>
    </Flex>
  );
}
