/* eslint-disable react/jsx-no-useless-fragment */

/* eslint-disable no-nested-ternary */
import {
  Box,
  BoxProps,
  Button,
  chakra,
  Divider,
  FormControl,
  Grid,
  GridItem,
  HStack,
  Select,
  SimpleGrid,
  Stack,
  useBoolean,
  useDisclosure,
} from '@chakra-ui/react';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import Icon from '../../../components/core/Icon/Icon';
import { IconImage } from '../../../components/core/Icon/IconConfig';
import EditRecordModal from '../../../components/shared/data-explorer/EditRecordModal/EditRecordModal';
import { Can } from '../../../context/AuthorizationContext';
import { useQuery } from '../../../hooks/use-query';
import {
  useGetIdentityByPin,
  useGetIdentityRecordsByPin,
} from '../../../lib/api-client/identity/IdentityData';
import {
  Attributes,
  Identity,
  IdentityAttributesWithComponentKeys,
  IdentityData,
  IdentityDataEmailAddressesKeys,
  IdentityDataKeys,
  IdentityDataNamesKeys,
  IdentityRecord,
} from '../../../lib/api-client/identity/model/Identity';
import Mixpanel from '../../../lib/mixpanel/Mixpanel';
import { getIdentityAttributeScoreLabel } from '../../../lib/utils/scoring-utils';
import { isUndefined } from '../../../lib/utils/utils';
import ProfileAuthenticityBadge from '../components/ProfileAuthenticityBadge/ProfileAuthenticityBadge';
import ProfileDataRecordScoreBadge from '../components/ProfileDataRecordScoreBadge/ProfileDataRecordScoreBadge';

const ATTRIBUTE_LABELS: Partial<Record<IdentityAttributesWithComponentKeys, string>> = {
  name: 'Full Name',
  names: 'Name',
  firstName: 'First name',
  middleName: 'Middle name',
  lastName: 'Last name',
  nameSuffix: 'Name suffix',
  emailAddresses: 'Email address',
  mobilePhone: 'Mobile phone',
  homePhone: 'Home phone',
  workPhone: 'Work phone',
  city: 'City',
  country: 'Country',
  countryCode: 'Country code',
  governingDistrict: 'Governing district',
  localMunicipality: 'Local municipality',
  postalCode: 'Postal code',
  address: 'Mailing address',
  dateOfBirth: 'Date of birth',
  ipAddress: 'IP Address',
  gender: 'Gender',
  ccLast4: 'CC Last 4',
  linkingKey1: 'Linking key 1',
  linkingKey2: 'Linking key 2',
  linkingKey3: 'Linking key 3',
  optInEmail: 'Opt in email',
  optInPhone: 'Opt in phone',
  optInText: 'Opt in text',
};

const ATTRIBUTE_ORDER: Array<IdentityAttributesWithComponentKeys> = [
  'name',
  'names',
  'firstName',
  'middleName',
  'lastName',
  'nameSuffix',
  'emailAddresses',
  'mobilePhone',
  'homePhone',
  'workPhone',
  'city',
  'country',
  'countryCode',
  'governingDistrict',
  'localMunicipality',
  'postalCode',
  'address',
  'dateOfBirth',
  'ipAddress',
  'gender',
  'ccLast4',
  'linkingKey1',
  'linkingKey2',
  'linkingKey3',
  'optInEmail',
  'optInPhone',
  'optInText',
];

type TransformedIdentityRecord = IdentityRecord & {
  identityRecord: IdentityData & { name: string | undefined; address: string | undefined };
};

function toFullName(data: IdentityData) {
  return (
    [
      data.firstName?.modified ?? data.firstName?.raw ?? data.firstName?.normalized,
      data.middleName?.modified ?? data.middleName?.raw ?? data.middleName?.normalized,
      data.lastName?.modified ?? data.lastName?.raw ?? data.lastName?.normalized,
    ]
      .filter((v) => !!v)
      .join(' ') || undefined
  );
}

function toAddress(data: IdentityData) {
  return (
    [
      data.streetAddress?.modified ?? data.streetAddress?.raw ?? data.streetAddress?.normalized,
      data.localMunicipality?.modified ??
        data.localMunicipality?.raw ??
        data.localMunicipality?.normalized,
      data.city?.modified ?? data.city?.raw ?? data.city?.normalized,
      data.governingDistrict?.modified ??
        data.governingDistrict?.raw ??
        data.governingDistrict?.normalized,
      data.postalCode?.modified ?? data.postalCode?.raw ?? data.postalCode?.normalized,
      data.countryCode?.modified ?? data.countryCode?.raw ?? data.countryCode?.normalized,
    ]
      .filter((v) => !!v)
      .join(' ') || undefined
  );
}

function hydrateFullNameAndAddress(record: IdentityRecord) {
  return {
    ...record,
    identityRecord: {
      ...record.identityRecord,
      name: toFullName(record.identityRecord),
      address: toAddress(record.identityRecord),
    },
  };
}

export function formatKeyName(lastName: string) {
  const words = lastName.split(/(?=[A-Z])/);
  const formattedName = words.map((word: string, index: number) => {
    if (index === 0) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
    return word;
  });

  return formattedName.join(' ');
}
type IdentityRecordTableRowProps = {
  attribute: IdentityAttributesWithComponentKeys;
  value?:
    | IdentityDataNamesKeys
    | IdentityDataEmailAddressesKeys
    | IdentityDataKeys
    | string
    | boolean;
  score?: any;
  showEmpty?: boolean;
  highlight?: boolean;
  attributeHighlight?: string;
  filteredValue?: any;
} & BoxProps;

const rowProps: BoxProps = {
  py: 2.5,
  px: 3,
};

function IdentityRecordTableRow({
  attribute,
  value,
  score,
  showEmpty = false,
  highlight = false,
  attributeHighlight,
  filteredValue,
}: IdentityRecordTableRowProps) {
  let v;
  if (value != null) {
    if (typeof value === 'object' && 'raw' in value) {
      v = value.modified ?? value.raw;
    } else v = `${value}`;
  }

  if (
    attribute === 'dateOfBirth' ||
    attribute === 'mobilePhone' ||
    attribute === 'workPhone' ||
    attribute === 'homePhone'
  ) {
    v = typeof value === 'object' && 'normalized' in value ? value.normalized : null;
  }

  if (attribute === 'emailAddresses') {
    if (Array.isArray(value)) {
      v = value.map((x, index) => ({
        emailAddress: x.emailAddress,
        key: index,
      }));

      v = showEmpty ? v : v.filter((x) => x.emailAddress !== null);
    } else {
      v = null;
    }
  }

  if (attribute === 'names') {
    v = Array.isArray(value)
      ? value.map((x) => {
          const obj: any = {};

          if (x.firstName != null || showEmpty) {
            obj.firstName = x.firstNameModified ?? x.firstNameRaw;
          }

          if (x.middleName != null || showEmpty) {
            obj.middleName = x.middleNameModified ?? x.middleNameRaw;
          }

          if (x.lastName != null || showEmpty) {
            obj.lastName = x.lastNameModified ?? x.lastNameRaw;
          }

          return obj;
        })
      : null;
  }

  if (attribute === 'firstName' || attribute === 'middleName' || attribute === 'lastName') {
    return null;
  }

  if (v == null && !showEmpty) {
    return null;
  }

  const props: BoxProps = {
    bgColor: highlight ? 'highlightHover' : undefined,
  };

  const highlightAttributeRow = (item: any, key: string | undefined) => {
    const isHighlighted =
      (filteredValue && item === filteredValue) || key === attributeHighlight || highlight;

    return isHighlighted ? 'highlightHover' : '';
  };

  const showScoreBadge =
    score != null || ((attribute === 'emailAddresses' || attribute === 'address') && value);

  return (
    <>
      {Array.isArray(v) ? (
        v?.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            <GridItem colSpan={3}>
              <Divider color="gray.300" w="full" />
            </GridItem>
            {Object.keys(item).length > 0 &&
              Object.keys(item).map(
                (key: any, idx) =>
                  key !== 'key' && (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={idx}>
                      <Box
                        bgColor={highlightAttributeRow(item[key as keyof typeof item], key)}
                        key={key}
                        {...rowProps}
                      >
                        {formatKeyName(key)}
                      </Box>
                      <Box
                        bgColor={highlightAttributeRow(item[key as keyof typeof item], key)}
                        {...rowProps}
                        color={item[key as keyof typeof item] == null ? 'gray.400' : undefined}
                      >
                        {item[key as keyof typeof item] ?? 'Not on record'}
                      </Box>
                      <Box
                        bgColor={highlightAttributeRow(item[key as keyof typeof item], key)}
                        {...rowProps}
                        color="gray.400"
                      >
                        {score[index][`${key}Score`] ||
                        score[index][`${key}Score`] === 0 ||
                        score[index][`${key}Score`] === null ? (
                          <ProfileDataRecordScoreBadge
                            score={score[index][`${key}Score`]}
                            label={getIdentityAttributeScoreLabel(score[index][`${key}Score`], key)}
                          />
                        ) : (
                          'Not applicable'
                        )}
                      </Box>
                    </Fragment>
                  )
              )}
          </Fragment>
        ))
      ) : (
        <>
          <GridItem colSpan={3}>
            <Divider color="gray.300" w="full" />
          </GridItem>
          <Box {...rowProps} {...props}>
            {ATTRIBUTE_LABELS[attribute]}
          </Box>
          <Box {...rowProps} {...props} color={v == null ? 'gray.400' : undefined}>
            {v ?? 'Not on record'}
          </Box>
          <Box {...rowProps} {...props} color={score == null ? 'gray.400' : undefined}>
            {showScoreBadge ? (
              <ProfileDataRecordScoreBadge
                score={score}
                label={getIdentityAttributeScoreLabel(score, attribute)}
              />
            ) : (
              'Not applicable'
            )}
          </Box>
        </>
      )}
    </>
  );
}

type IdentityRecordTableProps = {
  identity: Identity;
  record: IdentityRecord & {
    identityRecord: IdentityData & { name: string | undefined; address: string | undefined };
  };
  attributeHighlight?: keyof IdentityData | 'name' | 'address';
  hideDataFields?: boolean;
  filteredValue?: any;
};

const headerProps: BoxProps = {
  ...rowProps,
  bgColor: 'gray.100',
  color: 'gray.600',
  textTransform: 'uppercase',
  fontSize: 'xs',
  fontWeight: 'bold',
};

export function IdentityRecordTable({
  identity,
  record,
  attributeHighlight,
  hideDataFields,
  filteredValue,
}: IdentityRecordTableProps) {
  const editRecordDisclosure = useDisclosure();
  const [showEmpty, setShowEmpty] = useBoolean();
  const sourceName =
    identity.sources?.find((source) => source.source.id === record.identityRecord.sourceId)?.source
      .name ?? '';
  return (
    <Stack alignItems="start" w="full">
      <HStack justifyContent="space-between" w="full">
        {!hideDataFields && (
          <>
            <HStack>
              <chakra.span fontWeight="bold">
                {sourceName} {record.identityRecord.sourceRecordId}
              </chakra.span>
              <ProfileAuthenticityBadge
                variant="outline"
                tag={{ tagKey: '', tagScore: record.scores.recordScore }}
                labels={['Accurate record', 'Questionable record', 'Inaccurate record']}
              />
            </HStack>
            <HStack spacing={4}>
              <Can I="update" an="SourceDataRecord">
                <Button
                  variant="link"
                  size="sm"
                  leftIcon={<Icon boxSize={4} iconImage={IconImage.editData} />}
                  onClick={editRecordDisclosure.onOpen}
                >
                  Edit record
                </Button>
              </Can>
              <EditRecordModal
                isOpen={editRecordDisclosure.isOpen}
                onClose={editRecordDisclosure.onClose}
                data={record}
                hideVersionButton
              />
              <Button
                variant="link"
                size="sm"
                leftIcon={<Icon boxSize={4} color="action" iconImage={IconImage.versionHistory} />}
                as={Link}
                to={`/sources/${record.identityRecord.sourceId}/records/${record.identityRecord.sourceRecordId}`}
              >
                Version history
              </Button>
            </HStack>
          </>
        )}
      </HStack>
      <Grid templateColumns="256px 1fr 256px" fontSize="sm" bgColor="white" w="full">
        <Box {...headerProps}>Data field</Box>
        <Box {...headerProps}>Field value</Box>
        <Box {...headerProps}>Field score</Box>
        {ATTRIBUTE_ORDER.map((attribute) => (
          <IdentityRecordTableRow
            key={attribute}
            attribute={attribute}
            value={
              record.identityRecord[attribute as keyof IdentityData] as
                | IdentityDataEmailAddressesKeys
                | IdentityDataKeys
                | string
                | boolean
            }
            score={
              attribute === 'names'
                ? record.identityRecord.names?.map((name) => ({
                    firstNameScore: name.firstNameScore,
                    lastNameScore: name.lastNameScore,
                  }))
                : attribute === 'emailAddresses'
                ? record.identityRecord.emailAddresses?.map((email) => ({
                    emailAddressScore: email.emailAddressScore,
                  }))
                : record.scores.attributeScores[attribute as keyof Attributes]
            }
            showEmpty={showEmpty}
            highlight={attributeHighlight === attribute}
            attributeHighlight={attributeHighlight}
            filteredValue={filteredValue}
          />
        ))}
      </Grid>

      {!hideDataFields && (
        <Button
          variant="ghost"
          onClick={setShowEmpty.toggle}
          rightIcon={
            <Icon
              iconImage={showEmpty ? IconImage.accordionUp : IconImage.accordionDown}
              boxSize={4}
            />
          }
        >
          {showEmpty ? 'Hide empty data fields' : 'Show empty data fields'}
        </Button>
      )}
    </Stack>
  );
}

type OnFilterChanged =
  | {
      sourceId: string;
      attribute?: keyof IdentityData;
    }
  | {
      sourceId: string;
      attribute?: keyof IdentityData;
      value: string;
    };

type RecordFiltersProps = {
  identity: Identity;
  records: TransformedIdentityRecord[];
  onFilterChanged: (event: OnFilterChanged) => void;
  recordCount: number;
};

function RecordFilters({ identity, records, onFilterChanged, recordCount }: RecordFiltersProps) {
  const location = useLocation();
  let defaultLocationStateFilter: LocationStateFilter = location.state
    ? (location.state as LocationStateFilter)
    : {
        filters: {
          componentAttribute: '',
          value: '',
        },
      };

  const query = useQuery();
  const recordIdParam = query.get('recordId');
  if (recordIdParam) {
    defaultLocationStateFilter = {
      filters: {
        componentAttribute: 'sourceRecordId',
        value: recordIdParam,
      },
    };
  }

  const [sourceId, setSourceId] = useState(query.get('sourceId') ?? '');
  const [locationStateFilter, setLocationStateFilter] = useState(defaultLocationStateFilter);
  const [selectedValuesList, setSelectedValuesList] = useState<string[]>([]);
  const identitySources =
    identity.sources
      ?.filter(({ identitySourceDetails }) => identitySourceDetails.dataPresent)
      .map(({ source }) => source)
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())) ?? [];

  const fieldValues = useMemo(() => {
    const valueLists: Partial<Record<keyof IdentityData, Set<string>>> = {};
    return records
      .filter((record) => !sourceId || record.identityRecord.sourceId === sourceId)
      .reduce((prev, curr) => {
        Object.entries(curr.identityRecord)
          .filter(([key]: any) => ATTRIBUTE_ORDER.includes(key) || key === 'sourceRecordId')
          .forEach(([key, value]: [any, IdentityDataKeys | string]) => {
            let v;
            if (typeof value === 'string') {
              v = value;
            } else {
              v = value?.modified ?? value?.raw ?? value?.normalized;
            }

            if (value && Array.isArray(value) && value.length >= 0) {
              v = value.map((item) => item.emailAddressModified ?? item.emailAddressRaw) ?? null;
            } else if (typeof value === 'string') {
              v = value;
            } else {
              v = value?.modified ?? value?.raw ?? value?.normalized;
            }

            if (key in prev) {
              if (v) {
                prev[key as keyof IdentityData]!.add(v.toString());
              }
            } else if (v) {
              // eslint-disable-next-line no-param-reassign
              prev[key as keyof IdentityData] = new Set([v.toString()]);
            }
          });
        return prev;
      }, valueLists);
  }, [records, sourceId]);

  useEffect(() => {
    if (
      locationStateFilter.filters.componentAttribute &&
      fieldValues[locationStateFilter.filters.componentAttribute as keyof IdentityData]
    ) {
      const valuesList = Array.from(
        fieldValues[locationStateFilter.filters.componentAttribute as keyof IdentityData]!
      );
      setSelectedValuesList(valuesList);
    } else {
      setSelectedValuesList([]);
    }
  }, [locationStateFilter.filters.componentAttribute, fieldValues]);

  useEffect(() => {
    onFilterChanged({
      sourceId,
      attribute: locationStateFilter.filters.componentAttribute as keyof IdentityData,
      value: locationStateFilter.filters.value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SimpleGrid columns={3} gap={5}>
      <FormControl>
        <Select
          value={sourceId}
          onChange={(e) => {
            const newSourceId = e.target.value;
            setSourceId(newSourceId);
            onFilterChanged({
              sourceId: newSourceId,
              attribute:
                (locationStateFilter.filters.componentAttribute as keyof IdentityData) ?? undefined,
            });
            Mixpanel.track('Source System Changed');
          }}
        >
          <option value="">All source systems</option>
          {identitySources.map((source) => (
            <option key={source.id} value={source.id}>
              {source.name}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <Select
          value={locationStateFilter.filters.componentAttribute}
          onChange={(e) => {
            const newAttribute = e.target.value;
            setLocationStateFilter((prev) => ({
              ...prev,
              filters: {
                ...prev.filters,
                componentAttribute: newAttribute,
              },
            }));
            onFilterChanged({
              sourceId,
              attribute: newAttribute as keyof IdentityData,
            });
            Mixpanel.track('Data Field Changed', { field: newAttribute });
          }}
        >
          <option value="">All data fields</option>
          {ATTRIBUTE_ORDER.map(
            (attribute) =>
              attribute !== 'names' && (
                <option key={attribute} value={attribute}>
                  {ATTRIBUTE_LABELS[attribute]}
                </option>
              )
          )}
          <option value="sourceRecordId">Record ID</option>
        </Select>
      </FormControl>
      <FormControl>
        <Select
          value={locationStateFilter.filters.value}
          disabled={!locationStateFilter.filters.componentAttribute}
          onChange={(e) => {
            const newValue = e.target.value;
            setLocationStateFilter((prev) => ({
              ...prev,
              filters: {
                ...prev.filters,
                value: newValue,
              },
            }));
            onFilterChanged({
              sourceId,
              attribute: locationStateFilter.filters.componentAttribute as keyof IdentityData,
              value: newValue,
            });
            Mixpanel.track('Field Value Changed');
          }}
        >
          <option value="">All field values </option>
          {selectedValuesList.map((x, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <option key={idx}>{x}</option>
          ))}
        </Select>
      </FormControl>
      <GridItem colSpan={3} mt={-4}>
        <chakra.span fontSize="small" fontWeight="600" color="#4A5568">
          {recordCount} data records
        </chakra.span>
      </GridItem>
    </SimpleGrid>
  );
}

type LocationStateFilter = {
  filters: {
    componentAttribute: string;
    value: string;
  };
};

export default function ProfileDataRecordsPage() {
  const { id = '' } = useParams();
  const { data: identity } = useGetIdentityByPin(id);
  const { data: records } = useGetIdentityRecordsByPin(id);
  const [filteredRecords, setFilteredRecords] = useState<TransformedIdentityRecord[]>([]);
  const [filteredValue, setFilteredValue] = useState<any>();
  const [attributeHighlight, setAttributeHighlight] = useState<
    keyof IdentityData | 'name' | 'names' | 'emailAddresses' | 'address' | undefined
  >();

  const transformedRecords = useMemo(
    () => records?.content.map(hydrateFullNameAndAddress),
    [records]
  );

  const onFilterChanged = useCallback(
    (evt: OnFilterChanged) => {
      if (!transformedRecords) {
        return;
      }

      let filtered = transformedRecords;
      if (evt.sourceId) {
        filtered = filtered.filter((record) => record.identityRecord.sourceId === evt.sourceId);
      }

      const hasAttributeFilter = 'attribute' in evt && !!evt.attribute;
      if (hasAttributeFilter) {
        filtered = filtered.filter(
          (record) =>
            evt.attribute === 'sourceRecordId' ||
            (evt.attribute && !!record.identityRecord[evt.attribute])
        );
      }

      const hasValueFilter = 'value' in evt && !!evt.value;
      setFilteredValue(hasValueFilter && evt.value);

      if (hasAttributeFilter && hasValueFilter) {
        filtered = filtered.filter((record) => {
          if (!evt.value || !evt.attribute) {
            return false;
          }

          const value = record.identityRecord[evt.attribute];
          if (isUndefined(value)) {
            return false;
          }

          if (evt.attribute === 'sourceRecordId') {
            return record.identityRecord.sourceRecordId === evt.value;
          }

          if (typeof value === 'string' || typeof value === 'boolean') {
            return `${value}` === evt.value;
          }

          if (Array.isArray(value)) {
            return value.some((item) => {
              if (typeof item === 'object' && 'emailAddress' in item) {
                return item.emailAddressModified ?? item.emailAddressRaw === evt.value;
              }
              return false;
            });
          }

          if ('modified' in value) {
            return (
              value.modified === evt.value ||
              (value.modified == null && value.raw === evt.value) ||
              (value.raw == null && value.normalized === evt.value)
            );
          }

          return false;
        });
      }

      setFilteredRecords(filtered);

      setAttributeHighlight(hasAttributeFilter ? evt.attribute : undefined);
    },
    [transformedRecords]
  );

  return (
    <>
      <Box bg="white" data-testid="ProfileDataRecordsPage">
        <Stack>
          {identity && transformedRecords && (
            <RecordFilters
              identity={identity}
              onFilterChanged={onFilterChanged}
              records={transformedRecords}
              recordCount={filteredRecords.length}
            />
          )}
        </Stack>
      </Box>
      <Box mt={4}>
        <Stack spacing={7}>
          {identity &&
            filteredRecords.map((record) => (
              <IdentityRecordTable
                identity={identity}
                key={record.identityRecord.recordId}
                record={record}
                attributeHighlight={attributeHighlight}
                filteredValue={filteredValue}
              />
            ))}
        </Stack>
      </Box>
    </>
  );
}
