import {
  chakra,
  Circle,
  CircularProgress,
  CircularProgressProps,
  HStack,
  SquareProps,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { UploadStatus } from '../../../../lib/api-client/sources/model/Upload';

const config: Record<UploadStatus | 'default', any> = {
  created: {
    icon: (props: SquareProps) => <Circle bg="gray.400" {...props} />,
    label: 'Created',
  },
  uploadCompleted: {
    icon: (props: SquareProps) => <Circle bg="gray.400" {...props} />,
    label: 'Upload completed',
  },
  analysisRunning: {
    icon: (props: SquareProps) => <Circle bg="success" {...props} />,
    label: 'Analyzing data',
  },
  analysisCompleted: {
    icon: (props: SquareProps) => <Circle opacity={0.5} bg="success" {...props} />,
    label: 'Analysis complete',
  },
  testImportRequested: {
    icon: (props: SquareProps) => <Circle opacity={0.5} bg="success" {...props} />,
    label: 'Test ingestion requested',
  },
  testImportRunning: {
    icon: (props: CircularProgressProps) => (
      <CircularProgress bg="success" isIndeterminate {...props} />
    ),
    label: 'Ingesting test data',
  },
  testImportCompleted: {
    icon: (props: SquareProps) => <Circle opacity={0.5} bg="success" {...props} />,
    label: 'Test ingestion completed',
  },
  importRequested: {
    icon: (props: SquareProps) => <Circle opacity={0.5} bg="success" {...props} />,
    label: 'Ingestion requested',
  },
  importRunning: {
    icon: (props: CircularProgressProps) => (
      <CircularProgress bg="success" isIndeterminate {...props} />
    ),
    label: 'Ingesting data',
  },
  importCompleted: {
    icon: (props: SquareProps) => <Circle bg="success" {...props} />,
    label: 'Ingestion complete',
  },
  failed: {
    icon: (props: SquareProps) => <Circle bg="error" {...props} />,
    label: 'Ingestion failed',
  },
  default: {
    icon: (props: SquareProps) => <Circle bg="gray.400" {...props} />,
    label: '',
  },
};

interface FlatFileUploadIndicatorProps {
  status: UploadStatus;
  size?: 'md';
}

export default function FlatFileUploadIndicator({ status, size }: FlatFileUploadIndicatorProps) {
  const styles = useMultiStyleConfig('FlatFileUploadIndicator', { size });

  const statusConfig = config[status] || { ...config.default, label: status };
  const icon = statusConfig.icon(styles.icon);

  return (
    <HStack spacing={1}>
      {icon}
      <chakra.span __css={styles.label}>{statusConfig.label}</chakra.span>
    </HStack>
  );
}
